// Third-party libraries
import React, { useContext } from "react";
import AddShoppingCartSharpIcon from "@mui/icons-material/AddShoppingCartSharp";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Stack,
  IconButton,
} from "@mui/material";

// Local modules
import { MeleeSearchContext } from "../../utils/stateHandlers/contexts";
import { storage } from "../../utils";
import formatting from "../../utils/formatHelpers";
import { functions } from "../../utils";
import {
  CanadaIcon,
  RecycleIcon,
  LabGrownIcon,
  MinedIcon,
  BeakerIcon,
  SignatureIcon,
} from "../Icons";
import Round from "../../assets/images/shapes/round.svg";
import { useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";

const meleeCtw = [
  { display: "0.01", match: "0.005" },
  { display: "0.015", match: "0.007" },
  { display: "0.015", match: "0.0075" },
  { display: "0.02", match: "0.01" },
  { display: "0.03", match: "0.015" },
  { display: "0.04", match: "0.02" },
  { display: "0.05", match: "0.025" },
  { display: "0.06", match: "0.03" },
  { display: "0.07", match: "0.035" },
  { display: "0.08", match: "0.04" },
  { display: "1/10 (0.10)", match: "0.05" },
  { display: "1/8 (0.11-0.13)", match: "0.06" },
  { display: "1/7 (0.13-0.15)", match: "0.07" },
  { display: "1/6 (0.15-0.18)", match: "0.08" },
  { display: "1/5 (0.18-0.22)", match: "0.10" },
  { display: "1/4 (0.23-0.28)", match: "0.12" },
  { display: "1/3 (0.28-0.34)", match: "0.15" },
  { display: "3/8 (0.36-0.44)", match: "0.20" },
  { display: "1/2 (0.46-0.56)", match: "0.25" },
  { display: "2/3 (0.59-0.68)", match: "0.33" },
  { display: "3/4 (0.70-0.79)", match: "0.37" },
  { display: "1 (0.96-1.10)", match: "0.50" },
];

export default function MeleeDiamondTable(props) {
  const theme = useTheme();
  const screenBreakpoint = useMediaQuery(theme.breakpoints.down("md"));
  const { meleeState, meleeSearchDispatch } = useContext(MeleeSearchContext);
  const {
    tableHeaders,
    diamonds,
    searchQuery,
    mobileTableHeaders,
    matchedPairs,
  } = meleeState;
  const loggedIn = storage.getStorageItem("authToken") ? true : false;

  const rows = diamonds;

  const dataFormat = (format, diamondField) => (
    <>
      {formatting[format]
        ? formatting[format](diamondField)
        : format === "price2"
        ? "$" + parseFloat(diamondField).toFixed(2)
        : format === "price0"
        ? "$" + parseFloat(diamondField).toFixed(0)
        : format === "num2"
        ? parseFloat(diamondField).toFixed(2)
        : format === "percent2"
        ? parseFloat(diamondField).toFixed(2) + "%"
        : format === "percent1"
        ? parseFloat(diamondField).toFixed(1) + "%"
        : format === "percent0"
        ? parseFloat(diamondField).toFixed(0) + "%"
        : format === "images"
        ? "<img src='" + diamondField + "'/>"
        : diamondField}
    </>
  );

  const collectionFormat = (collection) => (
    <>
      {collection === "Harmony Lab Grown" ? (
        <Typography variant="cellFont">
          <BeakerIcon /> Lab Grown
        </Typography>
      ) : collection === "Harmony" ? (
        <Typography variant="cellFont">
          <RecycleIcon /> Harmony Recycled
        </Typography>
      ) : collection === "Signature" ? (
        <Typography variant="cellFont">
          <SignatureIcon /> Signature
        </Typography>
      ) : collection === "Canadian Origin" ? (
        <Typography variant="cellFont">
          <CanadaIcon /> Canadian Origin
        </Typography>
      ) : (
        collection
      )}
    </>
  );

  const type = (collection) => (
    <>{collection === "Harmony Lab Grown" ? <LabGrownIcon /> : <MinedIcon />}</>
  );

  function updateSearchQuery(header) {
    const sortField = searchQuery.sort.field;
    const sortDirection = searchQuery.sort.direction;
    if (sortField === header && sortDirection === "asc") {
      meleeSearchDispatch({
        type: "updateSort",
        payload: {
          sortField: header,
          sortDirection: "desc",
        },
      });
    } else if (sortField === header && sortDirection === "desc") {
      meleeSearchDispatch({
        type: "updateSort",
        payload: {
          sortField: header,
          sortDirection: "asc",
        },
      });
    } else if (sortField !== header) {
      meleeSearchDispatch({
        type: "updateSort",
        payload: {
          sortField: header,
          sortDirection: "asc",
        },
      });
    }
  }

  function meleeShapeColumnMobile(diamond) {
    return (
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="center"
        sx={{ paddingLeft: "0.5rem" }}
      >
        {collectionFormat(diamond.Collection)}
        {functions.getDiamondShape(diamond.Shape)}
        {matchedPairs ? functions.getDiamondShape(diamond.Shape) : ""}
      </Stack>
    );
  }

  function meleeColorClarityColumnMobile(diamond) {
    return (
      <Typography variant="cellFont">
        {diamond.Color} / {diamond.Clarity}
      </Typography>
    );
  }

  function getTotalCaratWeight(caratRange) {
    const splitRange = caratRange.split(" ")[0];
    const totalCaratWeight = meleeCtw.find(function (ctw) {
      return ctw.match === splitRange;
    });
    return totalCaratWeight ? totalCaratWeight.display : "";
  }

  const DiamondHeaders = (headers) => (
    <TableHead>
      <TableRow>
        {!screenBreakpoint ? (
          <TableCell align="center">
            <Typography variant="headerFont">Type</Typography>
          </TableCell>
        ) : null}
        <TableCell align="center">
          <Typography variant="headerFont">Shape</Typography>
        </TableCell>
        {matchedPairs && (
          <TableCell align="center">
            <Typography variant="headerFont">CTW </Typography>
          </TableCell>
        )}
        {headers.map((header) => (
          <TableCell
            key={header.results_order}
            align="center"
            className="pointer"
            onClick={() => updateSearchQuery(header.name)}
          >
            <Typography variant="headerFont">
              {screenBreakpoint
                ? header.label === "Color"
                  ? "Color \n Clarity"
                  : header.label === "Shape"
                  ? ""
                  : header.label
                : header.label === "Shape"
                ? ""
                : header.label}
            </Typography>
            {searchQuery.sort.field === header.name && (
              <>
                {searchQuery.sort.direction === "asc" ? (
                  <ArrowDropUpIcon sx={{ verticalAlign: "middle" }} />
                ) : (
                  <ArrowDropDownIcon sx={{ verticalAlign: "middle" }} />
                )}
              </>
            )}
          </TableCell>
        ))}
        {matchedPairs && (
          <TableCell align="center">
            <Typography variant="headerFont">Qty</Typography>
          </TableCell>
        )}
        <TableCell padding="checkbox"></TableCell>
      </TableRow>
    </TableHead>
  );

  const DiamondRows = (headers) => (
    <TableBody>
      {rows.map((diamond) => (
        <TableRow key={diamond.id}>
          {!screenBreakpoint ? (
            <TableCell align="center">
              <Typography variant="cellFont">
                {type(diamond.Collection)}
              </Typography>
            </TableCell>
          ) : null}

          <TableCell align="center">
            <Typography variant="cellFont">
              {matchedPairs ? (
                <>
                  <img src={Round} alt="round" className="diamond-image" />
                  <img src={Round} alt="round" className="diamond-image" />
                </>
              ) : (
                functions.getDiamondShape(diamond.Shape)
              )}
            </Typography>
          </TableCell>

          {matchedPairs && (
            <TableCell align="center">
              <Typography variant="cellFont">
                {getTotalCaratWeight(diamond.CaratRange)}
              </Typography>
            </TableCell>
          )}
          {headers.map((header, index) => (
            <TableCell align="center" padding="none" key={header.name}>
              {screenBreakpoint ? (
                <Typography variant="cellFont">
                  {header.name === "Shape"
                    ? meleeShapeColumnMobile(diamond)
                    : header.name === "Color"
                    ? meleeColorClarityColumnMobile(diamond)
                    : header.name === "PricePerCarat" && !loggedIn
                    ? "-"
                    : header.name === "EstimatedPrice" && !loggedIn
                    ? "-"
                    : header.name === "Collection"
                    ? collectionFormat(diamond.Collection)
                    : dataFormat(header.format, diamond[header.name])}
                </Typography>
              ) : (
                <Typography variant="cellFont">
                  {header.name === "Shape"
                    ? ""
                    : header.name === "PricePerCarat" && !loggedIn
                    ? "-"
                    : header.name === "EstimatedPrice" && !loggedIn
                    ? "-"
                    : header.name === "Collection"
                    ? collectionFormat(diamond.Collection)
                    : dataFormat(header.format, diamond[header.name])}
                </Typography>
              )}
            </TableCell>
          ))}
          {matchedPairs && (
            <TableCell align="center">
              <Typography variant="cellFont">2</Typography>
            </TableCell>
          )}
          <TableCell align="center" padding="none">
            <IconButton
              color="primary"
              onClick={(e) => {
                meleeSearchDispatch({
                  type: "setOpenCartPreview",
                  payload: true,
                });
                meleeSearchDispatch({
                  type: "setCartPreview",
                  payload: diamond,
                });
              }}
            >
              <AddShoppingCartSharpIcon sx={{ verticalAlign: "middle" }} />
            </IconButton>
          </TableCell>
        </TableRow>
      ))}
    </TableBody>
  );

  if (diamonds.length === 0) {
    return (
      <Typography variant="h6" sx={{ margin: "4rem" }}>
        No diamonds matched your search criteria.
      </Typography>
    );
  }

  if (tableHeaders) {
    const tableHeader = screenBreakpoint ? mobileTableHeaders : tableHeaders;
    return (
      <>
        <Table size="small">
          {DiamondHeaders(tableHeader)}
          {DiamondRows(tableHeader)}
        </Table>
      </>
    );
  }
}
