// Third-party libraries
import React from "react";
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from "@mui/material";

export default function NewsletterSignupModal(props) {
  const handleClose = () => {
    props.close(false);
  };

  function submit() {
    //api.post("https://hooverandstrong.us2.list-manage.com/subscribe/post?u=a84676575358f6c6855acaba5&amp;id=6d5969c4ea")
  }

  return (
    <Dialog open={props.open} onClose={handleClose}>
      <DialogTitle>Subscribe to our Newsletter!</DialogTitle>
      <DialogContent>
        <DialogContentText>
          To subscribe to our newsletter, please enter your email address here.
          <TextField
            autoFocus
            name="EMAIL"
            id="name"
            type="email"
            fullWidth
            color="primary"
            size="small"
            sx={{ marginTop: "2rem" }}
            label={<Typography variant="navText">Email Address</Typography>}
          />
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={handleClose}>
          Cancel
        </Button>
        <Button variant="contained" color="success" onClick={() => submit()}>
          Subscribe
        </Button>
      </DialogActions>
    </Dialog>
  );
}
