// Built-in modules
// Third-party libraries
import React, { useContext } from "react";
import {
  PDFDownloadLink,
  Page,
  Text,
  View,
  Document,
  StyleSheet,
} from "@react-pdf/renderer";
import FileDownloadTwoToneIcon from "@mui/icons-material/FileDownloadTwoTone";
import Button from "@mui/material/Button";
// Local modules
import formatting from "../../utils/formatHelpers";
import { storage } from "../../utils";
import { DiamondSearchContext } from "../../utils/stateHandlers/contexts";

const styles = StyleSheet.create({
  page: {
    fontWeight: "unset",
  },
  section: {
    fontSize: 10,
    margin: 5,
    display: "flex",
    flexDirection: "row",
  },
  tableSection: {
    marginRight: 5,
    borderRight: "1px solid grey",
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap",
  },
  tableHeader: {
    fontFamily: "Helvetica-Bold",
    marginBottom: 5,
  },
  diamonds: {
    marginBottom: 5,
  },
  title: {
    margin: 10,
    padding: 10,
    alignItems: "center",
    fontSize: "20px",
    display: "flex",
    flexDirection: "row",
  },
});

export default function SearchResultsPdf(props) {
  const { diamondState } = useContext(DiamondSearchContext);
  const { selected, tableHeaders } = diamondState;
  const loggedIn = storage.getStorageItem("authToken");
  let currentDate = new Date();
  let cDay = currentDate.getDate();
  let cMonth = currentDate.getMonth() + 1;
  let cYear = currentDate.getFullYear();
  let date = `${cMonth}-${cDay}-${cYear}`;

  const dataFormat = (format, diamondField) => (
    <>
      {formatting[format]
        ? formatting[format](diamondField)
        : format === "price2"
        ? "$" + parseFloat(diamondField).toFixed(2)
        : format === "price0"
        ? "$" + parseFloat(diamondField).toFixed(0)
        : format === "num2"
        ? parseFloat(diamondField).toFixed(2)
        : format === "percent2"
        ? parseFloat(diamondField).toFixed(2) + "%"
        : format === "percent1"
        ? parseFloat(diamondField).toFixed(1) + "%"
        : format === "percent0"
        ? parseFloat(diamondField).toFixed(0) + "%"
        : diamondField}
    </>
  );

  const MyDoc = () => (
    <Document>
      <Page size="A4" orientation="landscape" style={styles.page}>
        <View style={styles.title}>
          <Text>Selected Diamonds</Text>
        </View>
        <View style={styles.section}>
          {tableHeaders.map((header, index) => (
            <View key={index} style={styles.tableSection}>
              <Text style={styles.tableHeader}>{header.label}</Text>
              {selected.map((diamond) => (
                <Text key={diamond.HooverStockId} style={styles.diamonds}>
                  {header.name === "HooverPrice" && !loggedIn
                    ? "-"
                    : header.name === "CalcPricePerCarat" && !loggedIn
                    ? "-"
                    : dataFormat(header.format, diamond[header.name])}
                </Text>
              ))}
            </View>
          ))}
        </View>
      </Page>
    </Document>
  );

  return (
    <Button
      startIcon={<FileDownloadTwoToneIcon />}
      className={`${selected && selected.length > 0 ? "" : "invisible"}`}
      variant="contained"
      color="secondary"
      disableElevation
      sx={{
        borderRadius: "50px",
        fontSize: "1.2rem",
        lineHeight: "1.2rem",
        letterSpacing: "0.05rem",
        textTransform: "initial",
        width: "unset",
      }}
    >
      <PDFDownloadLink
        document={<MyDoc />}
        fileName={`Selected Diamonds ${date}.pdf`}
      >
        {({ blob, url, loading, error }) =>
          loading ? "Loading..." : "Print Selected"
        }
      </PDFDownloadLink>
    </Button>
  );
}
