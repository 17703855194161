import sortOrder from "../constants/sortOrder";

function sortFields(fields) {
  const newSortedFields = fields.map((field) => {
    return { ...field, SortOrder: sortOrder[field.name] };
  });

  return newSortedFields.sort((a, b) => a.SortOrder - b.SortOrder);
}

function updateFields(fields, productFields, productDispatch) {
  const newFields = [];
  productFields.forEach(function (field) {
    newFields.push(
      field.name === "material" ||
        field.name === "length" ||
        field.name === "width" ||
        field.name === "thickness" ||
        field.name === "outer_diameter" ||
        field.name === "inner_diameter"
        ? field
        : null
    );
  });
  fields.forEach(function (field) {
    newFields.push(
      field.name === "quantity"
        ? field
        : field.name === "dimension"
        ? field
        : field.name === "quantity.pieces"
        ? field
        : field.name === "quantity.length"
        ? field
        : field.name === "carat_size"
        ? field
        : field.name === "finger_size"
        ? field
        : null
    );
  });
  const filteredFields = Array.from(
    new Set(newFields.filter((field) => field !== null))
  );
  const sortedFields = sortFields(filteredFields);
  productDispatch({
    type: "setNewFields",
    payload: sortedFields,
  });
}

export default updateFields;
