// Third-party libraries
import React, { useEffect, useState } from "react";
import { Route, Switch, Redirect, useLocation } from "react-router-dom";
import { QueryParamProvider } from "use-query-params";
import { Toaster } from "react-hot-toast";
import { StyledEngineProvider, ThemeProvider } from "@mui/material/styles";

// Local modules
import { storage } from "./utils";
import { AppContext } from "./utils/stateHandlers/contexts";
import baseMuiTheme from "./assets/styles/theme";

//Components
import Layout from "./components/Layout/Layout";
import { Home, Product, Products } from "./components/pages";
import CareersHome from "./components/pages/careers/CareersHome";
import CertDiamondSearchLanding from "./components/diamonds/CertDiamondSearchLanding";
import BlankDiamondDetails from "./components/diamonds/BlankDiamondDetails";
import Login from "./components/user/Login";
import Articles from "./components/pages/articles/Articles";
import SingleArticle from "./components/pages/articles/SingleArticle";
import Faq from "./components/pages/static/Faq";
import StaticLanding from "./components/pages/static/StaticLanding";
import StaticSubLanding from "./components/pages/static/StaticSubLanding";
import UpdatePassword from "./components/user/UpdatePassword";
import LinksPage from "./components/pages/static/LinksPage";
import MeleeDiamondSearchLanding from "./components/diamonds/MeleeDiamondSearchLanding";
import CertDiamondDetails from "./components/diamonds/CertDiamondDetails";
import UserProfile from "./components/user/UserProfile";
import Cart from "./components/user/Cart";
import ForgotPassword from "./components/user/ForgotPassword";
import AccordionStaticLanding from "./components/pages/static/AccordionStaticLanding";
import AuctionsPage from "./components/diamonds/components/auction/AuctionsPage";
import StonePurchaseService from "./components/diamonds/components/StonePurchaseService";
import CastingCalculator from "./components/pages/forms/CastingCalculator";
import RefiningShippingForm from "./components/pages/forms/RefiningShippingForm";
import RefiningCalculator from "./components/pages/forms/RefiningCalculator";
import FDJLanding from "./components/diamonds/components/FDJLanding";
import Checkout from "./components/user/Checkout";
import AuctionSignUp from "./components/diamonds/components/auction/AuctionSignUp";
import AuctionSignUp2 from "./components/diamonds/components/auction/AuctionSignUp2";
import AuctionSignUp3 from "./components/diamonds/components/auction/AuctionSignUp3";
import CreateAccount from "./components/user/CreateAccount";
import ContactUs from "./components/pages/forms/ContactUs";
import SitewideSearch from "./components/pages/SitewideSearch";
import AuctionHomePage from "./components/diamonds/components/auction/AuctionHomePage";
import BarHome from "./components/products/BuildARing/BarHome";
import DiamondServicesPage from "./components/diamonds/components/DiamondServicesPage";
import Groups from "./components/products/productDetails/Groups";
import ChainOfCustodyJotform from "./components/pages/forms/ChainOfCustodyJotform";
import StoneSettingSubmissionForm from "./components/pages/forms/StoneSettingSubmissionForm";
import CZFrostingForm from "./components/pages/forms/CZFrostingForm";
import Net30Form from "./components/pages/forms/Net30Form";
import MarketingForm from "./components/pages/forms/MarketingForm";

export default function App() {
  const nowTime = () => new Date().getTime();
  const tokenFromStorage = storage.getStorageItem("authToken");
  const parsedUser = tokenFromStorage
    ? JSON.parse(storage.getStorageItem("currentUser"))
    : null;
  const cartIdFromStorage = storage.getStorageItem("cartId");
  const [authToken, setAuthToken] = useState(tokenFromStorage);
  const [currentUser, setCurrentUser] = useState(parsedUser);
  const [showFooter, setShowFooter] = useState(true);
  const [showHeader, setShowHeader] = useState(true);
  const [showCustomMetalMarkets, setShowCustomMetalMarkets] = useState(false);
  const [showMetalMarkets, setShowMetalMarkets] = useState(false);
  const [showNotificationBanner, setShowNotificationBanner] = useState(false);
  const location = useLocation();

  //ReactGA.initialize(constants.GOOGLE_ANALYTICS_MEASUREMENT_ID);

  function isAuthenticated() {
    return authToken !== null;
  }

  function signOut() {
    storage.removeStorageItem("authToken");
    storage.removeStorageItem("currentUser");
    storage.removeStorageItem("sessionExpiry");
    storage.clearSessionStorage();
    setAuthToken(null);
    setCurrentUser(parsedUser);
  }

  const user = {
    currentUser: currentUser,
    signIn: (newUser, token) => {
      if (token) {
        storage.setStorageItem("authToken", token);
        setAuthToken(token);
      }
      storage.setStorageItem("currentUser", JSON.stringify(newUser.Login));
      cartIdFromStorage
        ? storage.setStorageItem("cartId", cartIdFromStorage)
        : storage.setStorageItem("cartId", newUser.CartId);
      storage.setStorageItem(
        "sessionExpiry",
        JSON.stringify(nowTime() + 60480000)
      );
      setCurrentUser(newUser);
    },
    signOut: signOut,
  };

  const AuthRoute = ({ component: Component, ...extraProps }) => {
    const currentLocation = useLocation();
    return (
      <Route
        {...extraProps}
        render={(props) => {
          const combinedProps = Object.assign(props, extraProps);
          if (authToken === null) {
            //setPreviousPage(props.location);
            return (
              <Redirect
                to={{
                  pathname: "/login",
                  state: { sendTo: currentLocation.pathname },
                }}
              />
            );
          }
          return <Component {...combinedProps} />;
        }}
      />
    );
  };

  const LoginRoute = ({ component: Component, ...extraProps }) => {
    return (
      <Route
        {...extraProps}
        render={(props) => {
          const combinedProps = Object.assign(props, extraProps);
          if (isAuthenticated()) {
            //I want this redirect to return the the previous page. React-router history?
            const sendTo = props.location.state
              ? props.location.state.sendTo
              : null;
            const mtoConfiguratorProduct = props.location.state
              ? props.location.state.mtoConfiguratorProduct
              : false;
            if (sendTo) {
              return (
                <Redirect
                  to={{
                    pathname: `${sendTo}`,
                    state: { mtoConfiguratorProduct: mtoConfiguratorProduct },
                  }}
                />
              );
            }
            return <Redirect to="/" />;
          }
          if (
            (combinedProps.path.startsWith("/update-password") ||
              combinedProps.path.startsWith("/forgot-password")) &&
            Component
          ) {
            return <Component {...combinedProps} />;
          }
          return <Login {...combinedProps} />;
        }}
      />
    );
  };

  const ProtectedRoute = ({ component: Component, ...extraProps }) => {
    return (
      <Route
        {...extraProps}
        render={(props) => {
          const combinedProps = Object.assign(props, extraProps);
          if (authToken === null) {
          }
          return <Component {...combinedProps} />;
        }}
      />
    );
  };

  useEffect(() => {
    if (storage.getStorageItem("sessionExpiry")) {
      const expiry = JSON.parse(storage.getStorageItem("sessionExpiry"));
      if (nowTime() > expiry) {
        user.signOut();
      } else {
        storage.setStorageItem(
          "sessionExpiry",
          JSON.stringify(nowTime() + 60480000)
        );
      }
    } else {
      user.signOut();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    window.gtag("event", "page_view", {
      env: process.env.NODE_ENV,
      page_path: location.pathname + location.search + location.hash,
      page_search: location.search,
      page_hash: location.hash,
    });
  }, [location]);

  return (
    <AppContext.Provider
      value={{
        showFooter,
        setShowFooter,
        showHeader,
        setShowHeader,
        showCustomMetalMarkets,
        setShowCustomMetalMarkets,
        user,
        showMetalMarkets,
        setShowMetalMarkets,
        authToken,
        showNotificationBanner,
        setShowNotificationBanner,
      }}
    >
      <QueryParamProvider ReactRouterRoute={Route}>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={baseMuiTheme}>
            <Toaster
              toastOptions={{
                duration: 5000,
                success: {
                  style: {
                    background: "#7fa04d",
                    color: "#fff",
                  },
                  iconTheme: {
                    primary: "#fff",
                    secondary: "#7fa04d",
                  },
                },
                error: {
                  style: {
                    background: "#954738",
                    color: "#fff",
                  },
                  iconTheme: {
                    primary: "#fff",
                    secondary: "#954738",
                  },
                },
              }}
            />
            <Layout loggedIn={tokenFromStorage}>
              <Switch>
                {/* Authentication and Password Routes */}
                <LoginRoute exact path="/login" component={Login} />
                <Route
                  exact
                  path="/update-password"
                  component={UpdatePassword}
                />
                <Route
                  exact
                  path="/forgot-password"
                  component={ForgotPassword}
                />

                {/* Routes that do not need a log in. Information, body copy pages, articles, etc. */}
                <Route path="/" exact component={Home} />
                <Route
                  path="/search/:SearchTerm/:SearchTermPart2/:SearchTermPart3"
                  exact
                  component={SitewideSearch}
                />
                <Route
                  path="/search/:SearchTerm/:SearchTermPart2"
                  exact
                  component={SitewideSearch}
                />
                <Route
                  path="/search/:SearchTerm"
                  exact
                  component={SitewideSearch}
                />
                <Route path="/create-account" exact component={CreateAccount} />
                <Route exact path="/careers" component={CareersHome} />
                <Route
                  exact
                  path="/myauction-log-in"
                  component={AuctionsPage}
                />
                <Route
                  exact
                  path="/auction-signup2"
                  component={AuctionSignUp2}
                />
                <Route
                  exact
                  path="/auction-signup3"
                  component={AuctionSignUp3}
                />
                <Route
                  exact
                  path="/harmony-auction-signup"
                  component={AuctionSignUp}
                />
                <Route
                  exact
                  path="/harmony-diamond-auctions/:Tab"
                  component={AuctionHomePage}
                />
                <Route
                  exact
                  path="/harmony-diamond-auctions"
                  component={AuctionHomePage}
                />
                <Route
                  exact
                  path="/stone-purchasing-service"
                  component={StonePurchaseService}
                />
                <Route
                  exact
                  path="/diamond-services"
                  component={DiamondServicesPage}
                />
                <Route
                  exact
                  path="/finished-diamond-jewelry"
                  component={FDJLanding}
                />
                <Route
                  exact
                  path="/landing/:PageType"
                  component={StaticLanding}
                />
                <Route
                  exact
                  path="/accordion-landing/:PageType"
                  component={AccordionStaticLanding}
                />
                <Route
                  exact
                  path="/sub-landing/:PageType"
                  component={StaticSubLanding}
                />
                <Route exact path="/links/:PageType" component={LinksPage} />
                <Route exact path="/contact" component={ContactUs} />
                <Route
                  exact
                  path="/refining-calculator"
                  component={RefiningCalculator}
                />
                <Route
                  exact
                  path="/refining-shipping-form"
                  component={RefiningShippingForm}
                />
                <Route
                  exact
                  path="/chain-of-custody-form"
                  component={ChainOfCustodyJotform}
                />
                <Route
                  exact
                  path="/stone-setting-submission-form"
                  component={StoneSettingSubmissionForm}
                />
                <Route
                  exact
                  path="/lg-screening-cz-frosting-form"
                  component={CZFrostingForm}
                />
                <Route exact path="/faq/:PageType" component={Faq} />
                <Route
                  exact
                  path="/technicalarticles/:ArticleId"
                  component={SingleArticle}
                />
                <Route exact path="/technicalarticles" component={Articles} />
                <Route
                  exact
                  path="/blankdiamond/:HooverStockId"
                  component={BlankDiamondDetails}
                />
                <Route exact path="/products/:Category" component={Products} />
                <Route exact path="/marketing" component={MarketingForm} />

                {/* These Routes are different - you can see the page without being logged in but cannot see any prices or add to cart. */}
                <ProtectedRoute
                  exact
                  path="/diamonds/:MinedOrLab"
                  component={CertDiamondSearchLanding}
                />
                <ProtectedRoute
                  exact
                  path="/diamonds/"
                  component={CertDiamondSearchLanding}
                />
                <ProtectedRoute
                  exact
                  path="/melee-diamonds/"
                  component={MeleeDiamondSearchLanding}
                />
                <ProtectedRoute
                  exact
                  path="/diamond/:HooverStockId"
                  component={CertDiamondDetails}
                />
                <ProtectedRoute
                  path="/product/:ProductStyleCode"
                  component={Product}
                />
                <ProtectedRoute
                  exact
                  path="/ring/build/:StartingProduct"
                  component={BarHome}
                />
                <ProtectedRoute exact path="/ring/build" component={BarHome} />
                <ProtectedRoute
                  exact
                  path="/group/:GroupName"
                  component={Groups}
                />

                {/* You must be logged in to the site to see these pages. */}
                <AuthRoute
                  exact
                  path="/casting-calculator"
                  component={CastingCalculator}
                />
                <AuthRoute exact path="/account" component={UserProfile} />
                <AuthRoute exact path="/cart" component={Cart} />
                <AuthRoute exact path="/checkout" component={Checkout} />
                <AuthRoute
                  path="/net30-application"
                  exact
                  component={Net30Form}
                />
              </Switch>
            </Layout>
          </ThemeProvider>
        </StyledEngineProvider>
      </QueryParamProvider>
    </AppContext.Provider>
  );
}
