import React from "react";

const PearProfile = (props) => {
  const viewBoxWidth = 85.7;
  const viewBoxHeight = 86.1;

  const lineColor = "#4a4a4a";
  const strokeWidth2 = "0.5";

  const x = (percentX) => percentX * viewBoxWidth;
  const y = (percentY) => percentY * viewBoxHeight;

  const table = Number(props.diamond.Table).toFixed(0);
  const depthPercent = Number(props.diamond.Depth).toFixed(1);
  const girdle = props.diamond.Girdle || "";
  const girdleSpaceIndex = girdle.indexOf(" ");
  const girdle1 =
    girdleSpaceIndex > 0 ? girdle.slice(0, girdleSpaceIndex) : girdle;
  const girdle2 = girdleSpaceIndex > 0 ? girdle.slice(girdleSpaceIndex) : "";

  const diagramStrokeWidth = "0.01";
  const diagramFontSize = 0.046;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox={"0 0 " + viewBoxWidth + " " + viewBoxHeight}
      width={props.width}
      height={props.height}
      preserveAspectRatio={props.preserveAspectRatio}
    >
      <polyline
        fill="none"
        stroke={props.color}
        strokeWidth={props.stroke_width}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        points="83.6,34.5 57.4,26.6 13,26.6 9.9,29 2.5,34.2 7.6,34.9 11,34.5 13.1,35 16.9,35 19.4,34.3 
				21.8,35.1 26.9,35.1 29.8,34.4 31.9,35 42.6,35.1 45.2,34.5 47.3,35 56.7,35.1 59.4,34.4 62.2,35.1 70.1,35.2 72.6,34.2 
				75.1,35.1 83.6,34.7 			"
      />
      <polyline
        fill="none"
        stroke={props.color}
        strokeWidth={props.stroke_width}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        points="5.4,34.1 9.9,29 17.7,26.9 			"
      />
      <polyline
        fill="none"
        stroke={props.color}
        strokeWidth={props.stroke_width}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        points="2.4,36.8 29.4,61.3 83.7,36.7 			"
      />
      <line
        fill="none"
        stroke={props.color}
        strokeWidth={props.stroke_width}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        x1="5.3"
        y1="36.9"
        x2="21.4"
        y2="53.9"
      />
      <polyline
        fill="none"
        stroke={props.color}
        strokeWidth={props.stroke_width}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        points="21.6,54.1 10.8,37.1 26.9,54.6 			"
      />
      <polyline
        fill="none"
        stroke={props.color}
        strokeWidth={props.stroke_width}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        points="17.5,36.7 19.6,37.2 21.8,36.7 27.1,36.8 29.9,37.3 33,36.6 41.9,36.5 45.2,37.4 47.9,36.9 
				56.7,36.8 59.8,37.4 62.5,36.7 70.2,36.8 72.7,37.3 75.7,36.4 83.7,36.7 			"
      />
      <polyline
        fill="none"
        stroke={props.color}
        strokeWidth={props.stroke_width}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        points="17.5,36.7 13.4,36.8 10.8,37.1 8,36.5 4.9,36.9 2.4,36.5 			"
      />
      <line
        fill="none"
        stroke={props.color}
        strokeWidth={props.stroke_width}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        x1="10.1"
        y1="29.2"
        x2="10.7"
        y2="34.3"
      />
      <polyline
        fill="none"
        stroke={props.color}
        strokeWidth={props.stroke_width}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        points="18,26.8 21.5,29.6 29.9,26.6 41.5,29.3 46.8,26.8 64.3,29 			"
      />
      <polyline
        fill="none"
        stroke={props.color}
        strokeWidth={props.stroke_width}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        points="10.7,34.3 21.5,29.6 29.8,34.2 41.5,29.3 59.4,34 64.5,29.2 72.6,34.2 			"
      />
      <path
        fill="none"
        stroke={props.color}
        strokeWidth={props.stroke_width}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="M83.6,34.5c0,0.3,0.1,2.2,0.1,2.2"
      />
      <line
        fill="none"
        stroke={props.color}
        strokeWidth={props.stroke_width}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        x1="19.8"
        y1="37.4"
        x2="29.5"
        y2="61.2"
      />
      <polyline
        fill="none"
        stroke={props.color}
        strokeWidth={props.stroke_width}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        points="26.9,54.6 29.9,37.3 33.6,54.6 			"
      />
      <line
        fill="none"
        stroke={props.color}
        strokeWidth={props.stroke_width}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        x1="45.2"
        y1="37.5"
        x2="29.8"
        y2="61"
      />
      <polyline
        fill="none"
        stroke={props.color}
        strokeWidth={props.stroke_width}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        points="34,54.9 59.5,37.9 41.5,55.6 			"
      />
      <line
        fill="none"
        stroke={props.color}
        strokeWidth={props.stroke_width}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        x1="21.5"
        y1="29.6"
        x2="19.5"
        y2="34.1"
      />
      <path
        fill="none"
        stroke={props.color}
        strokeWidth={props.stroke_width}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="M41.5,29.3c0,0.2,4,5.3,4,5.3"
      />
      <path
        fill="none"
        stroke={props.color}
        strokeWidth={props.stroke_width}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="M72.7,37.3c-0.2,0.2-31.2,18.2-31.2,18.2"
      />

      {props.show_diagram && (
        <>
          {/* Table */}
          <line
            x1={x(0.15)}
            x2={x(0.7)}
            y1={y(0.28)}
            y2={y(0.28)}
            stroke={lineColor}
            strokeWidth={strokeWidth2}
          ></line>
          <line
            x1={x(0.15)}
            x2={x(0.15)}
            y1={y(0.26)}
            y2={y(0.3)}
            stroke={lineColor}
            strokeWidth={strokeWidth2}
          ></line>
          <line
            x1={x(0.7)}
            x2={x(0.7)}
            y1={y(0.26)}
            y2={y(0.3)}
            stroke={lineColor}
            strokeWidth={strokeWidth2}
          ></line>

          <text
            x={x(0.3)}
            y={y(0.25)}
            fontSize={y(diagramFontSize)}
            stroke={lineColor}
            strokeWidth={diagramStrokeWidth}
          >
            Table: {table}%
          </text>

          {/* Left Depth */}
          <line
            x1={x(0.06)}
            x2={x(0.06)}
            y1={y(0.31)}
            y2={y(0.72)}
            stroke={lineColor}
            strokeWidth={strokeWidth2}
          ></line>
          <line
            x1={x(0.04)}
            x2={x(0.08)}
            y1={y(0.31)}
            y2={y(0.31)}
            stroke={lineColor}
            strokeWidth={strokeWidth2}
          ></line>
          <line
            x1={x(0.04)}
            x2={x(0.08)}
            y1={y(0.72)}
            y2={y(0.72)}
            stroke={lineColor}
            strokeWidth={strokeWidth2}
          ></line>

          <rect
            x={x(0.005)}
            y={y(0.43)}
            width={x(0.22)}
            height={y(0.135)}
            rx="0.8"
            fill="white"
          ></rect>
          <text
            x={x(0.03)}
            y={y(0.475)}
            fontSize={y(diagramFontSize)}
            stroke={lineColor}
            strokeWidth={diagramStrokeWidth}
          >
            Depth:
          </text>
          <text
            x={x(0.02)}
            y={y(0.542)}
            fontSize={y(diagramFontSize)}
            stroke={lineColor}
            strokeWidth={diagramStrokeWidth}
          >
            {props.diamond.Measurement3}
          </text>

          {/* Right Depth */}
          <line
            x1={x(0.94)}
            x2={x(0.94)}
            y1={y(0.31)}
            y2={y(0.72)}
            stroke={lineColor}
            strokeWidth={strokeWidth2}
          ></line>
          <line
            x1={x(0.92)}
            x2={x(0.96)}
            y1={y(0.31)}
            y2={y(0.31)}
            stroke={lineColor}
            strokeWidth={strokeWidth2}
          ></line>
          <line
            x1={x(0.92)}
            x2={x(0.96)}
            y1={y(0.72)}
            y2={y(0.72)}
            stroke={lineColor}
            strokeWidth={strokeWidth2}
          ></line>

          <rect
            x={x(0.8)}
            y={y(0.43)}
            width={x(0.19)}
            height={y(0.135)}
            rx="0.8"
            fill="white"
          ></rect>
          <text
            x={x(0.81)}
            y={y(0.475)}
            fontSize={y(diagramFontSize)}
            stroke={lineColor}
            strokeWidth={diagramStrokeWidth}
          >
            Depth:
          </text>
          <text
            x={x(0.81)}
            y={y(0.542)}
            fontSize={y(diagramFontSize)}
            stroke={lineColor}
            strokeWidth={diagramStrokeWidth}
          >
            {depthPercent}%
          </text>

          {/* Girdle */}
          {girdle1.length > 0 && (
            <>
              <circle
                cx={x(0.5)}
                cy={y(0.42)}
                r={y(0.03)}
                fillOpacity="0"
                stroke={lineColor}
                strokeWidth={strokeWidth2}
              />
              <line
                x1={x(0.5)}
                x2={x(0.502)}
                y1={y(0.45)}
                y2={y(0.79)}
                stroke={lineColor}
                strokeWidth={strokeWidth2}
              ></line>
              <line
                x1={x(0.502)}
                x2={x(0.54)}
                y1={y(0.79)}
                y2={y(0.79)}
                stroke={lineColor}
                strokeWidth={strokeWidth2}
              ></line>

              <text
                x={x(0.55)}
                y={y(0.8)}
                fontSize={y(diagramFontSize)}
                stroke={lineColor}
                strokeWidth={diagramStrokeWidth}
              >
                Girdle: {girdle1}
              </text>
              <text
                x={x(0.55)}
                y={y(0.86)}
                fontSize={y(diagramFontSize)}
                stroke={lineColor}
                strokeWidth={diagramStrokeWidth}
              >
                {girdle2}
              </text>
            </>
          )}

          {/* Culet */}
          {props.Culet && (
            <>
              <circle
                cx={x(0.345)}
                cy={y(0.71)}
                r={y(0.03)}
                fillOpacity="0"
                stroke={lineColor}
                strokeWidth={strokeWidth2}
              />
              <line
                x1={x(0.345)}
                x2={x(0.345)}
                y1={y(0.74)}
                y2={y(0.8)}
                stroke={lineColor}
                strokeWidth={strokeWidth2}
              ></line>
              <line
                x1={x(0.345)}
                x2={x(0.32)}
                y1={y(0.8)}
                y2={y(0.8)}
                stroke={lineColor}
                strokeWidth={strokeWidth2}
              ></line>

              <text
                x={x(0.0)}
                y={y(0.84)}
                fontSize={y(diagramFontSize)}
                stroke={lineColor}
                strokeWidth={diagramStrokeWidth}
              >
                Culet: {props.diamond.Culet}
              </text>
            </>
          )}
        </>
      )}
    </svg>
  );
};

export default PearProfile;
