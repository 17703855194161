// Third-party libraries
import React, { useEffect, useReducer } from "react";
import { useForm } from "react-hook-form";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";
import remarkGfm from "remark-gfm";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Button,
  TextField,
  Select,
  FormControl,
  MenuItem,
  FormHelperText,
  Container,
  Stack,
  Typography,
  Backdrop,
  CircularProgress,
  Grid,
  Divider,
} from "@mui/material";

// Local modules
import formatting from "../../../utils/formatHelpers";
import { FormContext } from "../../../utils/stateHandlers/contexts";
import { formReducer } from "../../../utils/stateHandlers/reducers";
import states from "../../../utils/stateHandlers/initialStates";
import { api } from "../../../utils";
import { Default, Mobile } from "../../../utils/deviceHelpers";

const schema = yup.object().shape({
  weight: yup.string().required("Please Enter in a Weight"),
  weight_unit: yup
    .string()
    .test(
      "weight-unit-not-selected",
      "Please Select the Units",
      (value) => value !== "None"
    )
    .required("Please Select the Units"),
  source_material: yup
    .string()
    .test(
      "source-material-not-selected",
      "Please Select a Material Option",
      (value) => value !== "None"
    )
    .required("Please Select a Material Option"),
  destination_material: yup
    .string()
    .test(
      "destination-material-not-selected",
      "Please Select a Material Option",
      (value) => value !== "None"
    )
    .required("Please Select a Material Option"),
  quantity: yup.string().required("Please Enter in the Number of Pieces"),
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

export default function CastingCalculator(props) {
  const [formState, dispatch] = useReducer(
    formReducer,
    states.initialFormState
  );
  const { isLoaded, fields, formtext, formtitle, message, submitted } =
    formState;
  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const InputField = (field) => (
    <Stack sx={{ margin: "1rem" }}>
      <Typography variant="navText">{field.label}</Typography>
      <TextField
        {...register(field.name)}
        error={errors[field.name] ? true : false}
        helperText={errors[field.name] ? errors[field.name].message : ""}
        color="primary"
        size="small"
        sx={{
          width: "100%",
          margin: "unset",
        }}
      />
    </Stack>
  );

  const SelectField = (field) => (
    <Stack>
      <Typography variant="navText">{field.label}</Typography>
      <FormControl
        {...register(field.name)}
        error={errors[field.name] ? true : false}
      >
        <Select
          key={field.name}
          defaultValue="None"
          size="small"
          MenuProps={{
            PaperProps: {
              sx: {
                border: "1px solid #939598",
                borderRadius: "0",
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
              },
            },
          }}
          onChange={(e) =>
            setValue(field.name, e.target.value, { shouldValidate: true })
          }
        >
          <MenuItem value="None">
            <em>Select...</em>
          </MenuItem>
          {field.values &&
            field.values.map((option) => (
              <MenuItem value={option.value} key={option.value}>
                {option.label}
              </MenuItem>
            ))}
        </Select>
        <FormHelperText>
          {errors[field.name] ? errors[field.name].message : ""}
        </FormHelperText>
      </FormControl>
    </Stack>
  );

  const InputSelectField = (field) => (
    <Grid container key={field.name}>
      <Grid item xs={8}>
        <Stack>
          <Typography variant="navText">{field.label}</Typography>
          <TextField
            {...register(field.name)}
            error={errors[field.name] ? true : false}
            helperText={errors[field.name] ? errors[field.name].message : ""}
            color="primary"
            size="small"
            sx={{
              width: "100%",
              margin: "unset",
            }}
          />
        </Stack>
      </Grid>
      <Grid item xs={1} />
      <Grid item xs={3}>
        <Stack>
          <Typography variant="navText">{field.label}</Typography>
          <FormControl
            {...register(`${field.name}_unit`)}
            error={errors[`${field.name}_unit`] ? true : false}
          >
            <Select
              key={`${field.name}_unit`}
              defaultValue="None"
              size="small"
              MenuProps={{
                PaperProps: {
                  sx: {
                    border: "1px solid #939598",
                    borderRadius: "0",
                    maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                  },
                },
              }}
              onChange={(e) =>
                setValue(`${field.name}_unit`, e.target.value, {
                  shouldValidate: true,
                })
              }
            >
              <MenuItem value="None">
                <em>Select...</em>
              </MenuItem>
              {field.units &&
                field.units.map((option) => (
                  <MenuItem value={option.value} key={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
            </Select>
            <FormHelperText>
              {errors[`${field.name}_unit`]
                ? errors[`${field.name}_unit`].message
                : ""}
            </FormHelperText>
          </FormControl>
        </Stack>
      </Grid>
    </Grid>
  );

  function submitForm(data) {
    api.post(`forms/casting/submit`, data).then((response) => {
      dispatch({ type: "submitForm", payload: response.data });
    });
  }

  function clearForm() {
    dispatch({ type: "clearForm", payload: false });
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    api.fetchStrapi(`/forms/2?populate=*`).then((response) => {
      dispatch({
        type: "setFormCopy",
        payload: {
          formtitle: response.data.data.attributes.formtitle,
          formtext: response.data.data.attributes.formtext,
        },
      });
    });

    api.fetch(`forms/casting/fields`).then((response) => {
      dispatch({ type: "setFields", payload: response.data.fields });
      dispatch({ type: "setAddressFields", payload: [] });
      response.data.fields.forEach(function (field) {
        dispatch({
          type: "updateFormInfo",
          field: field.name,
          payload: field.default,
        });
      });
      dispatch({ type: "setIsLoaded", payload: true });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FormContext.Provider value={{ formState, dispatch }}>
      {!isLoaded ? (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={true}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : (
        <Container maxWidth="lg" className="careers-text">
          <Mobile>
            <Stack spacing={1} sx={{ marginBottom: "3rem" }}>
              <Typography textAlign="center" variant="articleTitle">
                {formtitle}
              </Typography>
              <Typography
                variant="articleText"
                sx={{ marginBottom: "1rem", paddingBottom: "2rem" }}
              >
                <ReactMarkdown
                  children={formtext}
                  remarkPlugins={[remarkGfm]}
                />
              </Typography>
              {!submitted ? (
                <form onSubmit={handleSubmit(submitForm)}>
                  <Stack spacing={3}>
                    {fields &&
                      fields.map((field) => (
                        <React.Fragment key={field.name}>
                          {field.template === "field_with_units"
                            ? InputSelectField(field)
                            : field.template === "dropdown"
                            ? SelectField(field)
                            : field.template === "text"
                            ? InputField(field)
                            : field.template === "number"
                            ? InputField(field)
                            : ""}
                        </React.Fragment>
                      ))}
                    <Button variant="contained" type="submit">
                      Submit
                    </Button>
                  </Stack>
                </form>
              ) : (
                <Stack spacing={3}>
                  {message && (
                    <>
                      <Typography>
                        Per Unit Weight: {message.unit_weight}{" "}
                        {message.destination_weight_unit}
                      </Typography>
                      <Typography>
                        Per Unit Material:{" "}
                        {formatting["price2"](message.unit_material)}
                      </Typography>
                      <Typography>
                        Per Unit Labor:{" "}
                        {formatting["price2"](message.unit_labor)}
                      </Typography>
                      <Typography>
                        Per Unit Price:{" "}
                        {formatting["price2"](message.unit_price)}
                      </Typography>
                      <Divider />
                      <Typography>
                        Total Weight: {message.total_weight}
                      </Typography>
                      <Typography>
                        Total Material:{" "}
                        {formatting["price2"](message.total_material)}
                      </Typography>
                      <Typography>
                        Total Labor: {formatting["price2"](message.total_labor)}
                      </Typography>
                      <Typography>
                        Total Price: {formatting["price2"](message.total)}
                      </Typography>
                      <Button variant="contained" onClick={() => clearForm()}>
                        Clear Quote
                      </Button>
                    </>
                  )}
                </Stack>
              )}
            </Stack>
          </Mobile>
          <Default>
            <Stack alignItems="center" justifyContent="center">
              <Typography variant="articleTitle">{formtitle}</Typography>
              <Grid container sx={{ marginTop: "2rem" }} spacing={4}>
                <Grid item xs={4} sx={{ paddingRight: "2rem" }}>
                  <Typography
                    variant="articleText"
                    sx={{ marginBottom: "1rem" }}
                  >
                    <ReactMarkdown
                      children={formtext}
                      remarkPlugins={[remarkGfm]}
                    />
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  {!submitted ? (
                    <form onSubmit={handleSubmit(submitForm)}>
                      <Stack spacing={3}>
                        {fields &&
                          fields.map((field) => (
                            <React.Fragment key={field.name}>
                              {field.template === "field_with_units"
                                ? InputSelectField(field)
                                : field.template === "dropdown"
                                ? SelectField(field)
                                : field.template === "text"
                                ? InputField(field)
                                : field.template === "number"
                                ? InputField(field)
                                : ""}
                            </React.Fragment>
                          ))}
                        <Button variant="contained" type="submit">
                          Submit
                        </Button>
                      </Stack>
                    </form>
                  ) : (
                    <Stack spacing={3}>
                      {message && (
                        <>
                          <Typography>
                            Per Unit Weight: {message.unit_weight}{" "}
                            {message.destination_weight_unit}
                          </Typography>
                          <Typography>
                            Per Unit Material:{" "}
                            {formatting["price2"](message.unit_material)}
                          </Typography>
                          <Typography>
                            Per Unit Labor:{" "}
                            {formatting["price2"](message.unit_labor)}
                          </Typography>
                          <Typography>
                            Per Unit Price:{" "}
                            {formatting["price2"](message.unit_price)}
                          </Typography>
                          <Divider />
                          <Typography>
                            Total Weight: {message.total_weight}
                          </Typography>
                          <Typography>
                            Total Material:{" "}
                            {formatting["price2"](message.total_material)}
                          </Typography>
                          <Typography>
                            Total Labor:{" "}
                            {formatting["price2"](message.total_labor)}
                          </Typography>
                          <Typography>
                            Total Price: {formatting["price2"](message.total)}
                          </Typography>
                          <Button
                            variant="contained"
                            onClick={() => clearForm()}
                          >
                            Clear Quote
                          </Button>
                        </>
                      )}
                    </Stack>
                  )}
                </Grid>
              </Grid>
            </Stack>
          </Default>
        </Container>
      )}
    </FormContext.Provider>
  );
}
