// Built-in modules
import qs from "qs";

// Third-party libraries
import React, { useEffect, useState } from "react";
import { Link as DomLink } from "react-router-dom";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";
import remarkGfm from "remark-gfm";
import {
  Container,
  Typography,
  Stack,
  Card,
  CardMedia,
  Backdrop,
  CircularProgress,
} from "@mui/material";

// Local modules
import api from "../../../utils/api";
import { constants } from "../../../utils";
import LandingFooter from "../../pages/static/LandingFooter";

export default function FDJLanding(props) {
  const [isLoaded, setIsLoaded] = useState(false);
  const [body, setBody] = useState();
  const [icons, setIcons] = useState();
  const [image1, setImage1] = useState();
  const [image2, setImage2] = useState();
  const [image3, setImage3] = useState();
  const [image4, setImage4] = useState();

  const frontImageUrl = constants.CMS_FRONT_IMAGE_URL;

  useEffect(() => {
    window.scrollTo(0, 0);
    const query = qs.stringify({
      populate: {
        body: {
          populate: "*",
        },
        image1: {
          populate: "*",
        },
        image2: {
          populate: "*",
        },
        image3: {
          populate: "*",
        },
        image4: {
          populate: "*",
        },
        icons: {
          populate: "*",
        },
      },
    });
    api
      .fetchStrapi(`/finished-diamond-jewelry-landing?${query}`)
      .then((response) => {
        setBody(response.data.data.attributes.body);
        setIcons(response.data.data.attributes.icons);
        setImage1(response.data.data.attributes.image1.data.attributes);
        setImage2(response.data.data.attributes.image2.data.attributes);
        setImage3(response.data.data.attributes.image3.data.attributes);
        setImage4(response.data.data.attributes.image4.data.attributes);
        setIsLoaded(true);
      });
  }, []);

  return (
    <>
      {!isLoaded ? (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={true}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : (
        <Container maxWidth="xl" className="careers-text">
          <Stack alignItems="center" justifyContent="center" spacing={3}>
            <Typography
              variant="articleTitle"
              align="center"
              sx={{ marginBottom: "2rem" }}
            >
              Finished Diamond Jewelry
            </Typography>
            <Typography
              variant="staticText"
              align="center"
              sx={{ paddingLeft: "3rem", paddingRight: "3rem" }}
            >
              <ReactMarkdown remarkPlugins={[remarkGfm]}>{body}</ReactMarkdown>
            </Typography>
            <Stack direction="row" spacing={2}>
              <DomLink
                to={{
                  pathname:
                    "https://outlet.hooverandstrong.com/collections/finished-lab-grown-diamond-jewelry",
                }}
                target="_blank"
                style={{ width: "25%" }}
              >
                <Card className="pointer">
                  <CardMedia
                    component="img"
                    image={`${frontImageUrl}${image1.url}`}
                  />
                  <Typography variant="fdj" className="center">
                    {image1.caption}
                  </Typography>
                </Card>
              </DomLink>
              <DomLink
                to={{
                  pathname:
                    "https://outlet.hooverandstrong.com/collections/finished-lab-grown-diamond-jewelry",
                }}
                target="_blank"
                style={{ width: "25%" }}
              >
                <Card className="pointer">
                  <CardMedia
                    component="img"
                    image={`${frontImageUrl}${image2.url}`}
                  />
                  <Typography variant="fdj" className="center">
                    {image2.caption}
                  </Typography>
                </Card>
              </DomLink>
              <DomLink
                to={{
                  pathname:
                    "https://outlet.hooverandstrong.com/collections/finished-lab-grown-diamond-jewelry",
                }}
                target="_blank"
                style={{ width: "25%" }}
              >
                <Card className="pointer">
                  <CardMedia
                    component="img"
                    image={`${frontImageUrl}${image3.url}`}
                    sx={{}}
                  />
                  <Typography variant="fdj" className="center">
                    {image3.caption}
                  </Typography>
                </Card>
              </DomLink>
              <DomLink
                to={{
                  pathname:
                    "https://outlet.hooverandstrong.com/collections/finished-lab-grown-diamond-jewelry",
                }}
                target="_blank"
                style={{ width: "25%" }}
              >
                <Card className="pointer">
                  <CardMedia
                    component="img"
                    image={`${frontImageUrl}${image4.url}`}
                  />
                  <Typography variant="fdj" className="center">
                    {image4.caption}
                  </Typography>
                </Card>
              </DomLink>
            </Stack>
            <Typography variant="greenRectangle" align="center">
              We are committed to providing our customers with the best
              products, the best technical resources and the best customer
              service in the jewelry industry. You can always count on fast
              production and quick delivery from Hoover & Strong.
            </Typography>
            <LandingFooter icons={icons} />
          </Stack>
        </Container>
      )}
    </>
  );
}
