// Third-party libraries
import React, { useEffect, useReducer } from "react";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";
import remarkGfm from "remark-gfm";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Grid,
  Container,
  Stack,
  Typography,
  Backdrop,
  CircularProgress,
  Button,
  TextField,
  Select,
  FormControl,
  MenuItem,
  FormHelperText,
  Divider,
} from "@mui/material";

// Local modules
import { FormContext } from "../../../utils/stateHandlers/contexts";
import { formReducer } from "../../../utils/stateHandlers/reducers";
import states from "../../../utils/stateHandlers/initialStates";
import { api, storage } from "../../../utils";
import RefiningEstimatorPreview from "./RefiningEstimatorPreview";

const schema = yup.object().shape({
  weight: yup.string().required("Please Enter in a Weight"),
  weight_unit: yup
    .string()
    .test(
      "weight-unit-not-selected",
      "Please Select the Units",
      (value) => value !== "None"
    )
    .required("Please Select the Correct Units"),
  lot_code: yup
    .string()
    .test(
      "metal-not-selected",
      "Please Select a Metal Option",
      (value) => value !== "None"
    )
    .required("Please Select a Metal Option"),
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

export default function RefiningCalculator(props) {
  const [formState, dispatch] = useReducer(
    formReducer,
    states.initialFormState
  );
  const { isLoaded, fields, formtext, formtitle, estimates, quoteId } =
    formState;
  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const SelectField = (field) => (
    <Stack sx={{ margin: "1rem" }}>
      <Typography variant="navText">{field.label}</Typography>
      <FormControl
        {...register(field.name)}
        error={errors[field.name] ? true : false}
      >
        <Select
          key={field.name}
          size="small"
          defaultValue="None"
          MenuProps={{
            PaperProps: {
              sx: {
                border: "1px solid #939598",
                borderRadius: "0",
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
              },
            },
          }}
          onChange={(e) =>
            setValue(field.name, e.target.value, { shouldValidate: true })
          }
        >
          <MenuItem value="None">
            <em>Select...</em>
          </MenuItem>
          {field.values &&
            field.values.map((option) => (
              <MenuItem value={option.value} key={option.value}>
                {option.label}
              </MenuItem>
            ))}
        </Select>
        <FormHelperText>
          {errors[field.name] ? errors[field.name].message : ""}
        </FormHelperText>
      </FormControl>
    </Stack>
  );

  const InputSelectField = (field) => (
    <Grid container key={field.name}>
      <Grid item xs={8}>
        <Stack>
          <Typography variant="navText">Scrap Weight</Typography>
          <TextField
            {...register(field.name)}
            error={errors[field.name] ? true : false}
            helperText={errors[field.name] ? errors[field.name].message : ""}
            color="primary"
            size="small"
            sx={{
              width: "100%",
              margin: "unset",
            }}
          />
        </Stack>
      </Grid>
      <Grid item xs={1} />
      <Grid item xs={3}>
        <Stack>
          <Typography variant="navText">Unit of Weight</Typography>
          <FormControl
            {...register(`${field.name}_unit`)}
            error={errors[`${field.name}_unit`] ? true : false}
          >
            <Select
              key={`${field.name}_unit`}
              size="small"
              defaultValue="None"
              MenuProps={{
                PaperProps: {
                  sx: {
                    border: "1px solid #939598",
                    borderRadius: "0",
                    maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                  },
                },
              }}
              onChange={(e) =>
                setValue(`${field.name}_unit`, e.target.value, {
                  shouldValidate: true,
                })
              }
            >
              <MenuItem value="None">
                <em>Select...</em>
              </MenuItem>
              {field.units &&
                field.units.map((option) => (
                  <MenuItem value={option.value} key={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
            </Select>
            <FormHelperText>
              {errors[`${field.name}_unit`]
                ? errors[`${field.name}_unit`].message
                : ""}
            </FormHelperText>
          </FormControl>
        </Stack>
      </Grid>
    </Grid>
  );

  function submitForm(data) {
    const submitData = {
      weight: data.weight,
      weight_unit: data.weight_unit,
      lot_code: data.lot_code,
    };
    const withQuote = {
      ...submitData,
      quote_id: quoteId,
    };
    api
      .post(`forms/refining/submit`, quoteId !== "0" ? withQuote : submitData)
      .then((response) => {
        storage.setStorageItem(
          "refining_calculator_quote_id",
          response.data.quote.id
        );
        dispatch({
          type: "submitRefiningEstimate",
          payload: {
            estimates: response.data.quote.items,
            quoteId: response.data.quote.id,
          },
        });
      });
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    api.fetchStrapi(`/forms/3?populate=*`).then((response) => {
      dispatch({
        type: "setFormCopy",
        payload: {
          formtitle: response.data.data.attributes.formtitle,
          formtext: response.data.data.attributes.formtext,
        },
      });
    });

    api.fetch(`forms/refining/fields`).then((response) => {
      const storedQuoteId = storage.getStorageItem(
        "refining_calculator_quote_id"
      );
      dispatch({
        type: "setQuoteId",
        payload: storedQuoteId ? JSON.parse(storedQuoteId) : "0",
      });
      dispatch({ type: "setFields", payload: response.data.fields });
      dispatch({ type: "setAddressFields", payload: [] });
      response.data.fields.forEach(function (field) {
        dispatch({
          type: "updateFormInfo",
          field: field.name,
          payload: field.default,
        });
      });
      dispatch({ type: "setIsLoaded", payload: true });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FormContext.Provider value={{ formState, dispatch }}>
      {!isLoaded ? (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={true}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : (
        <Container maxWidth="md" className="careers-text">
          <form onSubmit={handleSubmit(submitForm)}>
            <Stack spacing={3} sx={{ marginBottom: "3rem" }}>
              <Typography variant="articleTitle">{formtitle}</Typography>
              <Typography
                variant="articleText"
                sx={{ marginBottom: "1rem", marginTop: "2rem" }}
              >
                <ReactMarkdown
                  children={formtext}
                  remarkPlugins={[remarkGfm]}
                />
              </Typography>
              {fields &&
                fields.map((field) => (
                  <React.Fragment key={field.name}>
                    {field.name !== "margin" && (
                      <>
                        {field.template === "field_with_units"
                          ? InputSelectField(field)
                          : field.template === "dropdown"
                          ? SelectField(field)
                          : ""}
                      </>
                    )}
                  </React.Fragment>
                ))}
              <Button variant="contained" type="submit">
                Submit
              </Button>
              <Divider flexItem sx={{ marginTop: "3rem" }} />
              {estimates.length > 0 && <RefiningEstimatorPreview />}
            </Stack>
          </form>
        </Container>
      )}
    </FormContext.Provider>
  );
}
