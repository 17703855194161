// Third-party libraries
import React, { useContext, useState } from "react";
import toast from "react-hot-toast";
import { Link as DomLink } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import HelpIcon from "@mui/icons-material/Help";
import DeleteForeverSharpIcon from "@mui/icons-material/DeleteForeverSharp";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Stack,
  Grid,
  Tooltip,
  Button,
  Typography,
  Checkbox,
  FormControlLabel,
  TextField,
} from "@mui/material";

// Local modules
import { storage, api } from "../../utils";
import {
  DiamondSearchContext,
  CartContext,
} from "../../utils/stateHandlers/contexts";
import formatting from "../../utils/formatHelpers";
import DiamondIconStrip from "./controls/DiamondIconStrip";
import Modal from "./controls/Modal";
import CartModal from "../user/CartModal";

export default function CertDiamondAddToCartPreview(props) {
  const { diamondState, dispatch } = useContext(DiamondSearchContext);
  const { diamonds, selected, cartPreview, openCartPreview } = diamondState;
  const { cartDispatch } = useContext(CartContext);
  const [specialInstructions, setSpecialInstructions] = useState("");
  const [showOFCHelp, setShowOFCHelp] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [terms, setTerms] = useState(false);
  const [openCartmodal, setOpenCartModal] = useState(false);
  const [errorMessages] = useState([]);

  const grandTotal = cartPreview.reduce(
    (total, diamond) => total + Number(diamond.HooverPrice),
    0
  );

  function markConditionalSale(diamond) {
    const diamondForCS = cartPreview.find((d) => {
      return diamond.HooverStockId === d.HooverStockId;
    });

    if (diamondForCS.conditionalSale === false) {
      diamondForCS.conditionalSale = true;
      dispatch({
        type: "setSelected",
        payload: [...cartPreview, diamondForCS].filter(
          (d, index, arr) =>
            arr.findIndex((item) => item.HooverStockId === d.HooverStockId) ===
            index
        ),
      });
      dispatch({
        type: "setCartPreview",
        payload: [...cartPreview, diamondForCS].filter(
          (d, index, arr) =>
            arr.findIndex((item) => item.HooverStockId === d.HooverStockId) ===
            index
        ),
      });
    } else {
      diamondForCS.conditionalSale = false;
      dispatch({
        type: "setSelected",
        payload: [...cartPreview, diamondForCS].filter(
          (d, index, arr) =>
            arr.findIndex((item) => item.HooverStockId === d.HooverStockId) ===
            index
        ),
      });
      dispatch({
        type: "setCartPreview",
        payload: [...cartPreview, diamondForCS].filter(
          (d, index, arr) =>
            arr.findIndex((item) => item.HooverStockId === d.HooverStockId) ===
            index
        ),
      });
    }
  }

  function removeItem(diamond) {
    diamond.Checked = false;
    dispatch({
      type: "setSelected",
      payload: selected.filter(
        (d) => d.HooverStockId !== diamond.HooverStockId
      ),
    });
    dispatch({
      type: "setCartPreview",
      payload: selected.filter(
        (d) => d.HooverStockId !== diamond.HooverStockId
      ),
    });
  }

  function addToCartApiCall() {
    cartPreview.forEach(function (diamond) {
      const addToCartBody = {
        cart_id:
          storage.getStorageItem("cartId") === "null" ||
          storage.getStorageItem("cartId") === "undefined"
            ? localStorage.removeItem("cartId")
            : storage.getStorageItem("cartId"),
        partcode: {
          material: "D",
          product: diamond.Shape,
          dimension: `${diamond.Color}${diamond.Clarity}`,
          finger_size: parseInt(diamond.HooverStockId),
          quantity: "1",
          quantity_unit: "EA",
          carat_size: diamond.Carat,
          custom_dimension: {
            sale: diamond.conditionalSale ? 0 : 1,
            terms: 1,
          },
          message: diamond.conditionalSale
            ? `Conditional Sale. ${specialInstructions}`
            : specialInstructions,
        },
      };
      api
        .post("cart/add", addToCartBody)
        .then((res) => {
          if (res.isAxiosError) {
            const errorMessage = res.response.data.errors.map(function (error) {
              return error;
            });
            toast.error(
              `There was an error processing your request. ${errorMessage.join(
                ", "
              )}`
            );
          } else {
            cartDispatch({
              type: "updateCart",
              payload: {
                cart: res.data.items,
                cartCount: res.data.count,
              },
            });
            storage.setStorageItem("cartId", res.data.cart_id);
          }
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    });
    return true;
  }

  function addToCart() {
    setOpenCartModal(false);
    if (!terms) {
      setShowAlert(true);
    } else {
      const addEachDiamondToCart = addToCartApiCall();
      if (addEachDiamondToCart) {
        dispatch({
          type: "setSelected",
          payload: [],
        });
        dispatch({
          type: "setCartPreview",
          payload: [],
        });
        setTerms(false);
        setOpenCartModal(true);
        storage.setSessionStorageItem("hsSelectedData", JSON.stringify([]));
        diamonds.forEach((d) => {
          if (d.Checked) {
            d.Checked = false;
          }
        });
      }
    }
  }

  return (
    <>
      <Dialog
        open={openCartPreview}
        onClose={() => {
          dispatch({ type: "setShowCartSuccess", payload: false });
          dispatch({ type: "setOpenCartPreview", payload: false });
        }}
        onBackdropClick={() => {
          dispatch({ type: "setShowCartSuccess", payload: false });
          dispatch({ type: "setOpenCartPreview", payload: false });
        }}
        maxWidth="md"
        fullWidth={true}
      >
        <DialogTitle>
          <IconButton
            aria-label="close"
            onClick={() => {
              dispatch({
                type: "setOpenCartPreview",
                payload: false,
              });
            }}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Typography variant="articleTitle">Cart Preview</Typography>
          <Typography variant="subtitle1">
            If you are requesting any of these diamonds on memo, please make
            sure that you select the "Order for Conditional Sale" checkbox for
            each diamond you would like on memo.
          </Typography>
          <Table size="small" sx={{ marginTop: "20px" }}>
            <TableHead>
              <TableRow>
                <TableCell
                  sx={{ position: "unset", fontSize: "1.2rem" }}
                ></TableCell>
                <TableCell
                  sx={{ position: "unset", fontSize: "1.2rem" }}
                ></TableCell>
                <TableCell sx={{ position: "unset", fontSize: "1.2rem" }}>
                  Price P/C
                </TableCell>
                <TableCell sx={{ position: "unset", fontSize: "1.2rem" }}>
                  Total Price
                </TableCell>
                <TableCell
                  className="text-align-center"
                  sx={{
                    position: "unset",
                    fontSize: "1.4rem",
                    lineHeight: "1.6rem",
                    fontWeight: "800",
                    fontStyle: "italic",
                    color: "#954738",
                  }}
                >
                  Order for <br /> Conditional Sale <br />
                  <HelpIcon
                    onClick={() => {
                      setShowOFCHelp(true);
                    }}
                    className="help-icon question-mark"
                  />
                  <Modal
                    title="Loose Diamond Memo Policy:"
                    onClose={() => setShowOFCHelp(false)}
                    show={showOFCHelp}
                  />
                </TableCell>
                <TableCell
                  sx={{ position: "unset", fontSize: "1.2rem" }}
                ></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {cartPreview &&
                cartPreview.map((x) => {
                  return (
                    <TableRow key={x.HooverStockId}>
                      <TableCell className="font-size-regular">
                        <Typography variant="cellFont">
                          {`SKU: ${x.HooverStockId} ${
                            x.MinedOrLab === "M"
                              ? "Mined Diamond"
                              : "Lab-Grown Diamond"
                          }`}
                        </Typography>
                        <br />
                        <Typography variant="cellFont">
                          {`${x.Carat}ct ${x.Shape} ${x.Color} ${x.Clarity} ${x.HooverCertification}`}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <DiamondIconStrip diamond={x} show_text={true} />
                      </TableCell>
                      <TableCell className="text-align-right font-size-regular">
                        {formatting["price2"](x.CalcPricePerCarat || 0)}
                      </TableCell>
                      <TableCell className="text-align-right font-size-regular">
                        {formatting["price2"](x.HooverPrice)}
                      </TableCell>
                      <TableCell className="text-align-center">
                        <Checkbox
                          checked={x.conditionalSale}
                          onChange={() => markConditionalSale(x)}
                        />
                      </TableCell>
                      <TableCell>
                        <Tooltip title="Remove" placement="top-start">
                          <IconButton
                            component="span"
                            onClick={() => removeItem(x)}
                          >
                            <DeleteForeverSharpIcon
                              fontSize="large"
                              sx={{ verticalAlign: "middle", color: "black" }}
                            />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
          <Stack spacing={2}>
            <Grid container />
            <Grid container>
              <Grid item xs={4} />
              <Grid item xs={4}>
                <Typography variant="h3">Grand Total: </Typography>
              </Grid>
              <Grid item xs={1}>
                <Typography variant="h3" className="float-right">
                  {formatting["price2"](grandTotal)}
                </Typography>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={4} />
              <Grid item xs={7}>
                <FormControlLabel
                  label={
                    <Typography variant="cellFont">
                      I have read and agree to the{" "}
                      <DomLink
                        to={{ pathname: "/links/Company-Policies" }}
                        target="_blank"
                        rel="noreferrer"
                        className="underline"
                      >
                        Terms & Conditions
                      </DomLink>{" "}
                      *
                    </Typography>
                  }
                  control={
                    <Checkbox
                      checked={terms}
                      onChange={() => setTerms(terms ? false : true)}
                    />
                  }
                />
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={4} />
              <Grid item xs={7}>
                <TextField
                  label="Special Instructions"
                  multiline
                  fullWidth
                  rows={3}
                  value={specialInstructions}
                  onChange={(e) => setSpecialInstructions(e.target.value)}
                />
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={4} />
              <Grid item xs={7}>
                <Button
                  className="float-right"
                  variant="contained"
                  color="success"
                  onClick={() => addToCart()}
                >
                  Add to Cart
                </Button>
              </Grid>
            </Grid>
          </Stack>
        </DialogContent>
      </Dialog>
      <CartModal
        cartMessage="Your Diamond order has been successfully added to your cart!"
        errors={errorMessages}
        open={openCartmodal}
        close={setOpenCartModal}
        product={true}
      />
      <Dialog
        open={showAlert}
        onClose={() => setShowAlert(false)}
        onBackdropClick={() => setShowAlert(false)}
      >
        <DialogTitle sx={{ fontSize: "1.6rem", fontWeight: "bold" }}>
          Terms & Conditions
          <IconButton
            aria-label="close"
            onClick={() => setShowAlert(false)}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Typography gutterBottom sx={{ fontSize: "1.4rem" }}>
              Please accept the terms and conditions.
            </Typography>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
}
