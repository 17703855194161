// Third-party libraries
import React, { useEffect } from "react";
import JotformEmbed from "react-jotform-embed";
import { Container, Typography } from "@mui/material";

// Local modules
import { Mobile, Default } from "../../../../utils/deviceHelpers";

export default function AuctionSignUp2(props) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Container maxWidth="xl">
      <Mobile>
        <JotformEmbed src="https://form.jotform.com/210112088192446" />
      </Mobile>
      <Default>
        <Typography variant="articleTitle" className="center">
          Harmony Auction Signup
        </Typography>
        <Typography variant="h6" className="center">
          Part 2 - Not Yet A H&S Customer
        </Typography>
        <JotformEmbed src="https://form.jotform.com/210112088192446" />
      </Default>
    </Container>
  );
}
