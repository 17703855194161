// Third-party libraries
import React from "react";
import { Link as DomLink } from "react-router-dom";
import OpenInNewSharpIcon from "@mui/icons-material/OpenInNewSharp";
import {
  Card,
  CardContent,
  CardMedia,
  Button,
  Stack,
  Typography,
} from "@mui/material";

// Local modules
import StringConstants from "../../../utils/constants";
import formatting from "../../../utils/formatHelpers";
import Shape from "../shapes/Shape.jsx";

export default function RecentlyViewedDiamondLink(props) {
  return (
    <Card className="rv-link">
      <CardContent className="rv-container">
        <CardMedia className="center-diamond" sx={{ marginBottom: "1rem" }}>
          {props.rv.ImageLink ? (
            <img alt="diamond" className="rv-image" src={props.rv.ImageLink} />
          ) : (
            <Shape
              type={props.rv.Shape}
              color="darkgrey"
              width={100}
              show_diagram={false}
              diamond={props.rv}
              strokeWidthFactor="0.01"
            />
          )}
        </CardMedia>
        <Stack spacing={1}>
          <Typography variant="body3">
            {props.rv.Carat} {props.rv.Shape} {props.rv.Color}{" "}
            {props.rv.Clarity} {props.rv.HooverCertification} Certified
          </Typography>
          <Typography variant="body3">
            {props.rv.MinedOrLab === StringConstants.DIAMOND_MINED_OR_LAB_LAB
              ? StringConstants.DIAMOND_MINED_OR_LAB_LAB_DESC
              : StringConstants.DIAMOND_MINED_OR_LAB_MINED_DESC}{" "}
            Diamond
          </Typography>

          <Typography variant="h6">
            {formatting["price2"](props.rv.HooverPrice)}
          </Typography>
        </Stack>

        <DomLink to={"/diamond/" + props.rv.HooverStockId} target="_blank">
          <Button
            variant="outlined"
            color="dark"
            sx={{ width: "100%" }}
            endIcon={<OpenInNewSharpIcon />}
          >
            View
          </Button>
        </DomLink>
      </CardContent>
    </Card>
  );
}
