import React from "react";

const OldMiner = (props) => {
  const viewBoxWidth = 87;
  const viewBoxHeight = 86;

  const lengthXPrcnt = 0.22;
  const lengthYPrcnt = 0.63;

  const widthXPrcnt = 0.56;
  const widthYPrcnt = 0.23;

  const lengthX = lengthXPrcnt * viewBoxWidth;
  const lengthY = lengthYPrcnt * viewBoxHeight;

  const widthX = widthXPrcnt * viewBoxWidth;
  const widthY = widthYPrcnt * viewBoxHeight;

  const lineColor = "#4a4a4a";

  const st0Style = {
    display: "none",
  };

  const st1Style = {
    fill: props.color,
    stroke: props.color,
    strokeWidth: 2,
  };

  const st2Style = {
    fill: "none",
    stroke: props.color,
    strokeWidth: 2,
  };

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox={"-1.696 -0.4 " + viewBoxWidth + " " + viewBoxHeight}
      width={props.width}
      height={props.height}
      preserveAspectRatio={props.preserveAspectRatio}
    >
      <g id="Layer_3" style={st0Style}></g>
      <g id="Layer_4" style={st0Style}>
        <circle style={st1Style} cx="42.8" cy="43.2" r="40.7" />
      </g>
      <g id="Layer_2">
        <g>
          <line style={st2Style} x1="8.3" y1="8.5" x2="42.8" y2="25.4" />
          <line style={st2Style} x1="78.1" y1="9" x2="43" y2="25.5" />
          <line style={st2Style} x1="57.4" y1="29.2" x2="81.7" y2="19.4" />
          <line style={st2Style} x1="28.8" y1="29.2" x2="4.5" y2="18.1" />
          <line style={st2Style} x1="76.6" y1="78.1" x2="60.6" y2="43.7" />
          <line style={st2Style} x1="25.5" y1="43.2" x2="6.8" y2="77.5" />
          <line style={st2Style} x1="57.2" y1="29.2" x2="60.6" y2="43.9" />
          <line style={st2Style} x1="28.8" y1="29.4" x2="25.2" y2="43.5" />
          <line style={st2Style} x1="43.2" y1="25.4" x2="28.8" y2="29" />
          <line style={st2Style} x1="57.2" y1="29.5" x2="43.2" y2="25.4" />
          <line style={st2Style} x1="67.5" y1="5.4" x2="57.4" y2="29.2" />
          <line style={st2Style} x1="19.2" y1="4.6" x2="29" y2="28.6" />
          <line style={st2Style} x1="43.2" y1="1.9" x2="23.5" y2="15.4" />
          <line style={st2Style} x1="63.2" y1="15.7" x2="43.4" y2="1.9" />
          <line style={st2Style} x1="78.3" y1="9.4" x2="60.6" y2="43.5" />
          <line style={st2Style} x1="56.1" y1="57.5" x2="67.2" y2="81.2" />
          <line style={st2Style} x1="80.5" y1="68.1" x2="56.3" y2="57.7" />
          <line style={st2Style} x1="71.4" y1="23.7" x2="84.6" y2="44.3" />
          <line style={st2Style} x1="71" y1="63.7" x2="84.8" y2="43.7" />
          <line style={st2Style} x1="56.5" y1="57.5" x2="61" y2="42.8" />
          <line style={st2Style} x1="25.4" y1="43.7" x2="28.6" y2="57.5" />
          <line style={st2Style} x1="17.9" y1="81" x2="28.5" y2="57" />
          <line style={st2Style} x1="4.3" y1="67" x2="28.8" y2="57.2" />
          <line style={st2Style} x1="7.4" y1="77.5" x2="42.1" y2="61" />
          <line style={st2Style} x1="28.5" y1="57" x2="42.8" y2="61.4" />
          <line style={st2Style} x1="56.6" y1="57.4" x2="42.6" y2="61.4" />
          <line style={st2Style} x1="62.5" y1="71" x2="43" y2="61.4" />
          <line style={st2Style} x1="42.6" y1="84.8" x2="62.5" y2="71.7" />
          <line style={st2Style} x1="21.9" y1="71" x2="42.5" y2="84.8" />
          <line style={st2Style} x1="0.8" y1="43" x2="13.9" y2="63" />
          <line style={st2Style} x1="14.6" y1="22.5" x2="1.2" y2="42.6" />
          <line style={st2Style} x1="25.4" y1="43.9" x2="7.9" y2="8.5" />
        </g>
        <polygon
          style={st2Style}
          points="8.3,8.5 19.5,4.6 43.4,1.5 67.9,5.2 77.9,8.8 81.9,19 84.8,43.4 81,68.1 76.6,78.3 67.5,81.4 
		42.1,84.8 17.9,81 7.5,77.5 4.1,66.8 0.8,42.6 4.8,18.1 	"
        />
      </g>
      {props.show_diagram && (
        <>
          <line
            x1="30"
            y1="0"
            x2="30"
            y2="85"
            stroke={lineColor}
            strokeWidth=".5"
          ></line>
          <line
            x1="28"
            y1="0"
            x2="32"
            y2="0"
            stroke={lineColor}
            strokeWidth=".5"
          ></line>
          <line
            x1="28"
            y1="85"
            x2="32"
            y2="85"
            stroke={lineColor}
            strokeWidth=".5"
          ></line>
          <rect
            x={lengthX}
            y={lengthY}
            width="20"
            height="11"
            rx="0.8"
            fill="white"
          ></rect>
          <text
            x={lengthX + 2}
            y={lengthY + 4}
            fontSize=".4rem"
            stroke={lineColor}
            strokeWidth="0.01"
          >
            Length:
          </text>
          <text
            x={lengthX + 1}
            y={lengthY + 10}
            fontSize=".4rem"
            stroke={lineColor}
            strokeWidth="0.01"
          >
            {props.diamond.Measurement1}
          </text>

          <line
            x1="2"
            y1="25"
            x2="90"
            y2="25"
            stroke={lineColor}
            strokeWidth=".5"
          ></line>
          <line
            x1="2"
            y1="23"
            x2="2"
            y2="27"
            stroke={lineColor}
            strokeWidth=".5"
          ></line>
          <line
            x1="90"
            y1="23"
            x2="90"
            y2="27"
            stroke={lineColor}
            strokeWidth=".5"
          ></line>

          <rect
            x={widthX}
            y={widthY}
            width="19"
            height="11"
            rx="0.8"
            fill="white"
          ></rect>
          <text
            x={widthX + 2}
            y={widthY + 4}
            fontSize=".4rem"
            stroke={lineColor}
            strokeWidth="0.01"
          >
            Width:
          </text>
          <text
            x={widthX + 1}
            y={widthY + 10}
            fontSize=".4rem"
            stroke={lineColor}
            strokeWidth="0.01"
          >
            {props.diamond.Measurement2}
          </text>
        </>
      )}
    </svg>
  );
};

export default OldMiner;
