// Third-party libraries
import React, { useContext, useState } from "react";
import { Link as DomLink, useLocation } from "react-router-dom";
import toast from "react-hot-toast";
import * as yup from "yup";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Stack,
  Alert,
  Skeleton,
  Tooltip,
  Button,
} from "@mui/material";

// Local modules
import {
  AppContext,
  CartContext,
  BuildARingContext,
} from "../../../utils/stateHandlers/contexts";
import { api, storage } from "../../../utils";
import formatting from "../../../utils/formatHelpers";
import CartModal from "../../user/CartModal";
import LoadingButton from "@mui/lab/LoadingButton";

const schema = yup.object().shape({
  quantity: yup.string().required("Quantity is Required"),
  setting_material: yup
    .string()
    .test(
      "carat_size-not-selected",
      "Setting Material has not been selected.",
      (value) => value !== "None"
    )
    .required("Setting Material is Required"),
  shank_material: yup
    .string()
    .test(
      "carat_size-not-selected",
      "Shank Material has not been selected.",
      (value) => value !== "None"
    )
    .required("Shank Material is Required"),
  carat_size: yup
    .string()
    .test(
      "carat_size-not-selected",
      "Carat Size has not been selected.",
      (value) => value !== "None"
    )
    .nullable(),
  finger_size: yup
    .string()
    .test(
      "finger_size-not-selected",
      "Ring Size has not been selected.",
      (value) => value !== "None"
    )
    .nullable(),
});

export default function BarPrice(props) {
  const { barState } = useContext(BuildARingContext);
  const { showCustomMetalMarkets, setShowCustomMetalMarkets, authToken } =
    useContext(AppContext);
  const { cartDispatch } = useContext(CartContext);
  const [openCartmodal, setOpenCartModal] = useState(false);
  const [errorMessages, setErrorMessages] = useState([]);
  const { priceLoaded, setting, shank, ring, message, messageType, quantity } =
    barState;
  const sendTo = useLocation();

  function findBreakNumber(value) {
    const breakNumber =
      value < 10
        ? 10
        : value < 50
        ? 50
        : value < 100
        ? 100
        : value < 500
        ? 500
        : 0;
    return breakNumber;
  }

  function roundToTenth(number) {
    return Math.round(number * 10) / 10;
  }

  function createPriceBreaksTable() {
    const someMath = findBreakNumber(quantity) - quantity;
    return (
      <Typography
        sx={{
          fontFamily: "Verdana",
          textAlign: "center",
          fontSize: "1.4rem",
          fontWeight: "800",
          padding: "1rem",
          marginBottom: ".5rem",
          marginTop: ".5rem",
          backgroundColor: "#cfdde9",
        }}
      >
        Save $$$ - Add {roundToTenth(someMath)} more to reach the next quantity
        price break.
      </Typography>
    );
  }

  function resetToActualMarkets() {
    api.fetch(`metalbase/reset`).then((res) => {
      setShowCustomMetalMarkets(false);
    });
  }

  function addToCart() {
    setErrorMessages([]);
    const settingTreatmentsList = setting.settingTreatments.map(function (
      treatment
    ) {
      return treatment.value;
    });
    const shankTreatmentsList = shank.shankTreatments.map(function (treatment) {
      return treatment.value;
    });
    const treatmentsString = [
      ...settingTreatmentsList,
      ...shankTreatmentsList,
    ].join(" ");
    const addToCartBody = {
      cart_id:
        storage.getStorageItem("cartId") === "null" ||
        storage.getStorageItem("cartId") === "undefined"
          ? localStorage.removeItem("cartId")
          : storage.getStorageItem("cartId"),
      partcode: {
        karat: ring.partcode.material,
        product: ring.partcode.product,
        dimension: ring.partcode.dimension,
        finger_size: ring.partcode.finger_size,
        quantity: ring.partcode.quantity,
        quantity_unit: ring.partcode.quantity_unit,
        carat_size: ring.partcode.carat_size,
        custom_dimension: {
          sale: 1,
          terms: 1,
        },
        message: ring.partcode.message,
        treatments: treatmentsString,
      },
    };
    schema
      .validate({
        quantity: quantity,
        setting_material: setting.settingInfo.material,
        shank_material: shank.shankInfo.material,
        carat_size: setting.settingInfo.carat_size,
        finger_size: shank.shankInfo.finger_size,
      })
      .then((valid) => {
        api
          .post("cart/add", addToCartBody)
          .then((res) => {
            if (res.isAxiosError) {
              const errorMessage = [];
              res.response.data.errors.forEach(function (error) {
                errorMessage.push(error);
              });
              toast.error(
                `There was an error processing your request. ${errorMessage.toString()}`
              );
            } else {
              setOpenCartModal(true);
              cartDispatch({
                type: "updateCart",
                payload: {
                  cart: res.data.items,
                  cartCount: res.data.count,
                },
              });
              storage.setStorageItem("cartId", res.data.cart_id);
            }
          })
          .catch((error) => {
            console.error("Error:", error);
          });
      })
      .catch((err) => {
        setErrorMessages(err.errors);
        setOpenCartModal(true);
      });
  }

  return (
    <>
      <Stack
        spacing={1}
        sx={{ textAlign: "right", marginBottom: "4.5rem", marginTop: "4rem" }}
      >
        <Typography variant="h4" sx={{ textAlign: "right" }}>
          {message ? (
            <Alert variant="filled" severity={messageType}>
              {message}
            </Alert>
          ) : (
            <Stack alignItems="end">
              {priceLoaded && authToken ? (
                `${formatting["price2"](ring.price)}`
              ) : authToken ? (
                <Skeleton
                  variant="rectangular"
                  width="10rem"
                  height="3rem"
                  className="float-right"
                />
              ) : (
                <DomLink
                  to={{
                    pathname: "/login",
                    state: { sendTo: sendTo.pathname },
                  }}
                >
                  <Button
                    variant="contained"
                    color="error"
                    size="small"
                    sx={{ marginTop: "unset" }}
                  >
                    Login For pricing
                  </Button>
                </DomLink>
              )}
              {authToken && (
                <Tooltip
                  title="Prices and weights shown are estimates and subject to change. Most product is sold by weight and billed at daily market prices. Orders will be priced at the metal markets on the date of receipt, if order is shipped in the same day; or the markets of the day after the order is received for out of stock product. Market price is the second London fix plus a surcharge."
                  placement="left"
                >
                  <Typography
                    sx={{ paddingTop: "1rem", textDecoration: "underline" }}
                  >
                    Estimated Price
                  </Typography>
                </Tooltip>
              )}
            </Stack>
          )}
        </Typography>
        {authToken && (
          <>
            {!showCustomMetalMarkets ? (
              <Typography variant="subtitle1">Using Actual Markets</Typography>
            ) : (
              <div>
                <Typography variant="subtitle1">
                  Currently Using Custom Markets
                </Typography>
                <Typography variant="subtitle1">
                  If you place an order, we will NOT hold customized markets
                </Typography>
              </div>
            )}
            {!showCustomMetalMarkets ? (
              <Typography
                onClick={() => setShowCustomMetalMarkets(true)}
                sx={{ color: "#337ab7", textDecoration: "underline" }}
              >
                Set to custom metal markets
              </Typography>
            ) : (
              <Typography
                onClick={() => resetToActualMarkets()}
                sx={{ color: "#337ab7", textDecoration: "underline" }}
              >
                Reset to daily metal markets
              </Typography>
            )}
          </>
        )}
      </Stack>
      {authToken && (
        <Stack spacing={1} sx={{ textAlign: "right", marginBottom: "4rem" }}>
          <Typography
            variant="h5"
            sx={{ color: "#337ab7", textTransform: "uppercase" }}
          >
            {ring.mto === true ? "MADE TO ORDER" : ""}
          </Typography>
          <Typography>
            Estimated ship date <strong>{ring.estimatedShipDate}</strong> if
            ordered by 5:00pm EST
          </Typography>
          <LoadingButton
            variant="contained"
            color="success"
            sx={{ width: "100%" }}
            onClick={() => addToCart()}
            loading={!priceLoaded}
            loadingIndicator="Loading Price…"
            disabled={ring.price === 0 ? true : message ? true : false}
          >
            Add to Cart
          </LoadingButton>
        </Stack>
      )}
      {ring.pricebreaks && ring.pricebreaks.length > 0 && authToken && (
        <>
          <Stack spacing={1}>
            <div>{createPriceBreaksTable()}</div>
          </Stack>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>
                  <Typography sx={{ fontWeight: "800" }}>Quantity</Typography>
                </TableCell>
                <TableCell>
                  <Typography sx={{ fontWeight: "800" }}>
                    Price Per Unit
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {ring.pricebreaks &&
                ring.pricebreaks.map((pb) => (
                  <TableRow key={pb.label}>
                    <TableCell>
                      <Typography>{pb.label}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>
                        {priceLoaded ? (
                          `${formatting["price2"](pb.total)}`
                        ) : (
                          <Skeleton
                            variant="text"
                            width="10rem"
                            className="float-right"
                          />
                        )}
                      </Typography>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </>
      )}
      <CartModal
        errors={errorMessages}
        open={openCartmodal}
        close={setOpenCartModal}
        product={true}
      />
    </>
  );
}
