export const CMS_FRONT_IMAGE_URL = "https://cms.hooverandstrong.com";

export const DIAMOND_SOURCE_CONFLICT_FREE = "All Others";
export const DIAMOND_SOURCE_CONFLICT_FREE_DISTINCT = "Conflict Free";
export const DIAMOND_SOURCE_CANADIAN = "Harmony Canadian";
export const DIAMOND_SOURCE_RECYCLED = "Harmony Recycled";

export const DIAMOND_MINED_OR_LAB_LAB = "L";
export const DIAMOND_MINED_OR_LAB_MINED = "M";
export const DIAMOND_MINED_OR_LAB_LAB_DESC = "Lab Grown";
export const DIAMOND_MINED_OR_LAB_MINED_DESC = "Mined";

export const FIELD_NAME_MINED_OR_LAB = "MinedOrLab";

export const SIZE_SMALL = "small";
export const SIZE_MEDIUM = "medium";
export const SIZE_LARGE = "large";

export const hiddenColumns = [
  "ImageVideoAvailable",
  "VideoLink",
  "ImageLink",
  "AsGrown",
  "ShapeCode",
  "ScsGrower",
  "ShipsSameDay",
  "FancyColor",
  "FancyColorIntensity",
  "FancyColorOvertone",
];

export const showFancyColorColumns = [
  "ImageVideoAvailable",
  "VideoLink",
  "ImageLink",
  "AsGrown",
  "ShapeCode",
  "ScsGrower",
  "ShipsSameDay",
  "Color",
  "FancyColorOvertone",
  "CalcPercentOffRap",
];

export const hiddenMobile = [
  "DiamondSource",
  "CalcPricePerCarat",
  "ImageVideoAvailable",
  "VideoLink",
  "ImageLink",
  "HooverStockId",
  "CalcPercentOffRap",
  "Measurements",
  "FluorescenceStrength",
  "Polish",
  "Symmetry",
  "HooverCertification",
  "Depth",
  "Table",
];

export const hiddenMelee = ["Shape"];

export const hiddenMeleeMobile = ["PricePerCarat", "Collection", "Clarity"];

export const hiddenTablet = [
  ...(hiddenColumns ?? []),
  ...(hiddenMobile ?? []),
  "CalcPercentOffRap",
  "Measurements",
  "FluorescenceStrength",
  "Polish",
  "Symmetry",
  "HooverCertification",
  "Depth",
  "Table",
];

export const hiddenForSidePanel = [
  ...(hiddenColumns ?? []),
  "Table",
  "Depth",
  "Polish",
  "Symmetry",
  "FluorescenceStrength",
];

export const hiddenForFcSidePanel = [
  ...(showFancyColorColumns ?? []),
  "Table",
  "Depth",
  "Polish",
  "Symmetry",
  "FluorescenceStrength",
];

export const Net30ApplicantFields = [
  "ShippingName",
  "TradeName",
  "Phone",
  "Fax",
];

export const Net30ApplicantAddressFields = [
  "ShippingStreet1",
  "ShippingStreet2",
  "ShippingCity",
  "ShippingState",
  "ShippingPostalCode",
  "ShippingCountry",
];

export const Net30BillingAddressFields = [
  "BillingStreet",
  "BillingStreet2",
  "BillingCity",
  "BillingState",
  "BillingPostalCode",
  "BillingCountry",
];

export const Net30BusinessInfoFields = [
  "EmailAddress",
  "Website",
  "BusinessType",
  "State",
  "YearIncorporated",
  "EstimatedSales",
  "TermsRequested",
  "JbtNumber",
  "Officers",
];

export const Net30BankingInfoFields = [
  "BankingBank",
  "BankingContact",
  "BankingPhone",
];

export const Net30BankAddressFields = [
  "BankingStreet",
  "BankingStreet2",
  "BankingCity",
  "BankingState",
  "BankingPostalCode",
  "BankingPostalCode",
  "BankingCountry",
];

export const Net30GeneralFields = [
  "Aml",
  "References",
  "Signature",
  "Title",
  "Date",
  "Guarantor",
];

export const Net30VastFields = ["Vast"];

export const statesOptions = [
  {
    label: "Alabama",
    value: "AL",
  },
  {
    label: "Alaska",
    value: "AK",
  },
  {
    label: "American Samoa",
    value: "AS",
  },
  {
    label: "Arizona",
    value: "AZ",
  },
  {
    label: "Arkansas",
    value: "AR",
  },
  {
    label: "California",
    value: "CA",
  },
  {
    label: "Colorado",
    value: "CO",
  },
  {
    label: "Connecticut",
    value: "CT",
  },
  {
    label: "Delaware",
    value: "DE",
  },
  {
    label: "District Of Columbia",
    value: "DC",
  },
  {
    label: "Federated States Of Micronesia",
    value: "FM",
  },
  {
    label: "Florida",
    value: "FL",
  },
  {
    label: "Georgia",
    value: "GA",
  },
  {
    label: "Guam",
    value: "GU",
  },
  {
    label: "Hawaii",
    value: "HI",
  },
  {
    label: "Idaho",
    value: "ID",
  },
  {
    label: "Illinois",
    value: "IL",
  },
  {
    label: "Indiana",
    value: "IN",
  },
  {
    label: "Iowa",
    value: "IA",
  },
  {
    label: "Kansas",
    value: "KS",
  },
  {
    label: "Kentucky",
    value: "KY",
  },
  {
    label: "Louisiana",
    value: "LA",
  },
  {
    label: "Maine",
    value: "ME",
  },
  {
    label: "Marshall Islands",
    value: "MH",
  },
  {
    label: "Maryland",
    value: "MD",
  },
  {
    label: "Massachusetts",
    value: "MA",
  },
  {
    label: "Michigan",
    value: "MI",
  },
  {
    label: "Minnesota",
    value: "MN",
  },
  {
    label: "Mississippi",
    value: "MS",
  },
  {
    label: "Missouri",
    value: "MO",
  },
  {
    label: "Montana",
    value: "MT",
  },
  {
    label: "Nebraska",
    value: "NE",
  },
  {
    label: "Nevada",
    value: "NV",
  },
  {
    label: "New Hampshire",
    value: "NH",
  },
  {
    label: "New Jersey",
    value: "NJ",
  },
  {
    label: "New Mexico",
    value: "NM",
  },
  {
    label: "New York",
    value: "NY",
  },
  {
    label: "North Carolina",
    value: "NC",
  },
  {
    label: "North Dakota",
    value: "ND",
  },
  {
    label: "Northern Mariana Islands",
    value: "MP",
  },
  {
    label: "Ohio",
    value: "OH",
  },
  {
    label: "Oklahoma",
    value: "OK",
  },
  {
    label: "Oregon",
    value: "OR",
  },
  {
    label: "Palau",
    value: "PW",
  },
  {
    label: "Pennsylvania",
    value: "PA",
  },
  {
    label: "Puerto Rico",
    value: "PR",
  },
  {
    label: "Rhode Island",
    value: "RI",
  },
  {
    label: "South Carolina",
    value: "SC",
  },
  {
    label: "South Dakota",
    value: "SD",
  },
  {
    label: "Tennessee",
    value: "TN",
  },
  {
    label: "Texas",
    value: "TX",
  },
  {
    label: "Utah",
    value: "UT",
  },
  {
    label: "Vermont",
    value: "VT",
  },
  {
    label: "Virgin Islands",
    value: "VI",
  },
  {
    label: "Virginia",
    value: "VA",
  },
  {
    label: "Washington",
    value: "WA",
  },
  {
    label: "West Virginia",
    value: "WV",
  },
  {
    label: "Wisconsin",
    value: "WI",
  },
  {
    label: "Wyoming",
    value: "WY",
  },
];

//Static Landing Page IDs
export const HARMONY_ID = 1;
export const REFINING_ID = 2;
export const CONTRACT_CASTING_ID = 3;
export const FINDINGS_ID = 4;
export const BANDS_ID = 5;
export const BRIDAL_ID = 6;
export const FINISHED_JEWELRY_ID = 7;
export const DIAMONDS_ID = 8;
export const REFINING_FOR_DENTISTS = 9;
export const REFINING_FOR_PRIVATE_INDIVIDUALS = 10;
export const MILL = 11;

//Accordion Landing Page IDs
export const CASTING = 1;

//Static Sub Landing Page IDs
export const HARMONY_RECYCLED_PRECIOUS_METALS_ID = 1;
export const LAB_GROWN_DIAMONDS_ID = 2;
export const HARMONY_RECYCLED_DIAMONDS_ID = 3;
export const ARTISANAL_METALS = 4;
export const OUR_GREEN_REFINERY_ID = 5;
export const HARMONY_DIAMOND_AUCTIONS = 6;
export const THE_HOOVER_AND_STRONG_EXPERTS = 7;
export const RESPONSIBILITY_STATEMENTS_AND_POLICIES = 8;
export const OUR_HISTORY = 9;
export const VOLUME_DISCOUNT_PROGRAMS = 10;
export const SHIPPING_OPTIONS = 11;
export const PAYMENT_INFORMATION = 12;
export const DIAMOND_SERVICES = 13;
export const DIAMOND_SOURCES = 14;
export const FINISHING_AND_MILGRAIN_OPTIONS = 15;
export const CUSTOMER_SERVICE_TEAM = 16;
export const WHAT_WE_REFINE = 17;
export const STONE_REMOVAL = 18;
export const FINDING_YOUR_METAL_SCRAP = 19;
export const MAXIMIZE_YOUR_RETURN = 20;
export const BUYING_JEWELRY_SCRAP = 21;
export const FREE_SWEEPS_BOX = 23;
export const PREPARING_YOUR_SHIPMENT = 24;
export const ANALYTICAL_SERVICES = 25;
export const WHAT_YOU_NEED_TO_KNOW = 26;
export const REFINING_SETTLEMENT_OPTIONS = 27;
export const IDENTIFYING_YOUR_SCRAP = 28;
export const PAYMENT_OPTIONS = 29;
export const HARMONY_JEWELERS = 30;
export const DIAMOND_EDUCATION = 31;
export const CHARITIES_AND_SPONSERSHIPS = 33;
export const GEMSTONES = 35;
export const SAPPHIRES = 36;
export const TOURMALINES = 37;
export const AFRICAN_TREASURES = 38;
export const RECYCLED_GEMSTONES = 39;

//Static Links Page IDs
export const ARTICLES_AND_PRESS_RELEASES = 1;
export const PRESS_RELEASE_2015_10_19 = 2;
export const HOLIDAY_AND_SHUTDOWN_DATES = 6;
export const PRICE_INCREASE_NOTICE_2_7_22 = 7;
export const PRIVACY_POLICY = 8;
export const DIAMOND_CONVERSION_CHARTS = 9;
export const SCS_CERTIFICATION = 10;
export const LEGAL_INFORMATION = 12;
export const COMPANY_POLICIES = 13;
export const NON_RECYCLED_ITEMS = 14;
export const MACHINE_SET_INSIDE_OUT_EARRINGS = 15;
export const REQUIRED_FORMS = 16;
export const STONE_POLICY_AND_ORDER_INFORMATION = 17;
export const HOW_THE_METAL_MARKET_PRICES_ARE_SET = 18;
export const OLD_AND_NEW_STYLE_NAME_REFERENCE_LIST = 19;
export const FORMS_AND_CATALOGS = 20;
export const VIRGINIA_RESIDENTS = 21;
export const REFINING_PAYMENT_TERMS = 22;
export const LASER_ENGRAVING = 23;
export const BREAKAGE_AND_LOSS_POLICY = 24;
export const DIAMOND_INFO_AND_FORMS = 25;
export const RESPONSIBLE_BUSINESS_PRACTICES_POLICY = 26;
export const ENVIRONMENTAL_POLICY = 27;
export const COMPLIANCE_AND_RESPONSIBILITY = 28;
export const PROVENANCE_MANAGEMENT_STATEMENT = 29;
export const HUMAN_RIGHTS_POLICY = 30;
export const METAL_PURCHASES_POLICY = 31;
export const GRIEVANCE_POLICY = 32;
export const ANTI_MONEY_LAUNDERING_STATEMENT = 33;
export const MATERIAL_SPECIFICATIONS_SHEETS = 34;
export const SAFETY_DATA_SHEETS = 35;
export const HOOVER_AND_STRONG_METALS_CHART = 36;
export const GLOSSARY = 37;
export const FAQ_FOR_PRIVATE_INDIVIDUALS = 38;
export const HOOVER_AND_STRONG_MILESTONES = 39;
export const PRESS_RELEASE_2015_08_05 = 40;
export const PRESS_RELEASE_2015_08_04 = 41;
export const PRESS_RELEASE_2015_03_09 = 42;
export const PRESS_RELEASE_2014_10_23 = 43;
export const PRESS_RELEASE_2013_09_04 = 44;
export const PRESS_RELEASE_2010_10_25 = 45;
export const PRESS_RELEASE_2010_09_27 = 46;
export const PRESS_RELEASE_2010_01_13 = 47;
export const PRESS_RELEASE_2009_08_04 = 48;
export const ARIZONA_JEWELERS = 49;
export const CATALOGS_AND_BROCHURES = 53;
export const PROMO_DETAILS = 54;

export const GOOGLE_ANALYTICS_MEASUREMENT_ID = "G-WEN2YWMMFW";

export default Object.freeze({
  CMS_FRONT_IMAGE_URL,
  DIAMOND_SOURCE_CONFLICT_FREE,
  DIAMOND_SOURCE_CONFLICT_FREE_DISTINCT,
  DIAMOND_SOURCE_CANADIAN,
  DIAMOND_SOURCE_RECYCLED,
  DIAMOND_MINED_OR_LAB_LAB,
  DIAMOND_MINED_OR_LAB_MINED,
  DIAMOND_MINED_OR_LAB_LAB_DESC,
  DIAMOND_MINED_OR_LAB_MINED_DESC,
  SIZE_SMALL,
  SIZE_MEDIUM,
  SIZE_LARGE,
  FIELD_NAME_MINED_OR_LAB,
  hiddenColumns,
  hiddenMobile,
  hiddenMeleeMobile,
  hiddenForSidePanel,
  hiddenTablet,
  HARMONY_ID,
  REFINING_ID,
  CONTRACT_CASTING_ID,
  FINDINGS_ID,
  BANDS_ID,
  BRIDAL_ID,
  FINISHED_JEWELRY_ID,
  DIAMONDS_ID,
  HARMONY_RECYCLED_PRECIOUS_METALS_ID,
  LAB_GROWN_DIAMONDS_ID,
  HARMONY_RECYCLED_DIAMONDS_ID,
  ARTISANAL_METALS,
  GOOGLE_ANALYTICS_MEASUREMENT_ID,
  OUR_GREEN_REFINERY_ID,
  HARMONY_DIAMOND_AUCTIONS,
  THE_HOOVER_AND_STRONG_EXPERTS,
  RESPONSIBILITY_STATEMENTS_AND_POLICIES,
  OUR_HISTORY,
  VOLUME_DISCOUNT_PROGRAMS,
  SHIPPING_OPTIONS,
  PAYMENT_INFORMATION,
  DIAMOND_SERVICES,
  DIAMOND_SOURCES,
  ARTICLES_AND_PRESS_RELEASES,
  PRESS_RELEASE_2015_10_19,
  HOLIDAY_AND_SHUTDOWN_DATES,
  PRICE_INCREASE_NOTICE_2_7_22,
  PRIVACY_POLICY,
  DIAMOND_INFO_AND_FORMS,
  SCS_CERTIFICATION,
  HOOVER_AND_STRONG_METALS_CHART,
  REFINING_FOR_DENTISTS,
  REFINING_FOR_PRIVATE_INDIVIDUALS,
  FINISHING_AND_MILGRAIN_OPTIONS,
  CUSTOMER_SERVICE_TEAM,
  WHAT_WE_REFINE,
  STONE_REMOVAL,
  FINDING_YOUR_METAL_SCRAP,
  MAXIMIZE_YOUR_RETURN,
  BUYING_JEWELRY_SCRAP,
  LEGAL_INFORMATION,
  COMPANY_POLICIES,
  NON_RECYCLED_ITEMS,
  MACHINE_SET_INSIDE_OUT_EARRINGS,
  REQUIRED_FORMS,
  STONE_POLICY_AND_ORDER_INFORMATION,
  HOW_THE_METAL_MARKET_PRICES_ARE_SET,
  OLD_AND_NEW_STYLE_NAME_REFERENCE_LIST,
  FORMS_AND_CATALOGS,
  VIRGINIA_RESIDENTS,
  REFINING_PAYMENT_TERMS,
  LASER_ENGRAVING,
  BREAKAGE_AND_LOSS_POLICY,
  FREE_SWEEPS_BOX,
  PREPARING_YOUR_SHIPMENT,
  ANALYTICAL_SERVICES,
  WHAT_YOU_NEED_TO_KNOW,
  CASTING,
  RESPONSIBLE_BUSINESS_PRACTICES_POLICY,
  ENVIRONMENTAL_POLICY,
  COMPLIANCE_AND_RESPONSIBILITY,
  PROVENANCE_MANAGEMENT_STATEMENT,
  HUMAN_RIGHTS_POLICY,
  METAL_PURCHASES_POLICY,
  GRIEVANCE_POLICY,
  ANTI_MONEY_LAUNDERING_STATEMENT,
  MATERIAL_SPECIFICATIONS_SHEETS,
  SAFETY_DATA_SHEETS,
  DIAMOND_CONVERSION_CHARTS,
  REFINING_SETTLEMENT_OPTIONS,
  IDENTIFYING_YOUR_SCRAP,
  PAYMENT_OPTIONS,
  HARMONY_JEWELERS,
  MILL,
  GLOSSARY,
  FAQ_FOR_PRIVATE_INDIVIDUALS,
  HOOVER_AND_STRONG_MILESTONES,
  PRESS_RELEASE_2015_08_05,
  PRESS_RELEASE_2015_08_04,
  PRESS_RELEASE_2015_03_09,
  PRESS_RELEASE_2014_10_23,
  PRESS_RELEASE_2013_09_04,
  PRESS_RELEASE_2010_10_25,
  PRESS_RELEASE_2010_09_27,
  PRESS_RELEASE_2010_01_13,
  PRESS_RELEASE_2009_08_04,
  DIAMOND_EDUCATION,
  statesOptions,
  ARIZONA_JEWELERS,
  hiddenMelee,
  Net30ApplicantFields,
  Net30ApplicantAddressFields,
  Net30BillingAddressFields,
  Net30BankingInfoFields,
  Net30BankAddressFields,
  Net30BusinessInfoFields,
  Net30GeneralFields,
  Net30VastFields,
  showFancyColorColumns,
  CATALOGS_AND_BROCHURES,
  PROMO_DETAILS,
  CHARITIES_AND_SPONSERSHIPS,
  hiddenForFcSidePanel,
  GEMSTONES,
  SAPPHIRES,
  TOURMALINES,
  RECYCLED_GEMSTONES,
  AFRICAN_TREASURES,
});
