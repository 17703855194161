import React from "react";

const Marquise = (props) => {
  const viewBoxWidth = 96;
  const viewBoxHeight = 86;

  const lengthXPrcnt = 0.38;
  const lengthYPrcnt = 0.15;

  const widthXPrcnt = 0.5;
  const widthYPrcnt = 0.43;

  const lengthX = lengthXPrcnt * viewBoxWidth;
  const lengthY = lengthYPrcnt * viewBoxHeight;

  const widthX = widthXPrcnt * viewBoxWidth;
  const widthY = widthYPrcnt * viewBoxHeight;

  const x = (percentX) => percentX * viewBoxWidth;
  const y = (percentY) => percentY * viewBoxHeight;

  const lineColor = "#4a4a4a";

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox={"-1.696 0 " + viewBoxWidth + " " + viewBoxHeight}
      width={props.width}
      height={props.height}
      preserveAspectRatio={props.preserveAspectRatio}
    >
      <path
        display="none"
        fill={props.color}
        stroke={props.color}
        strokeWidth={props.stroke_width}
        d="M88.435,42.362c0-30.048-30.633-50.655-58.517-39.104
	C19.597,7.535,11.32,15.796,7.012,26.165c0,0,0,0,0,0c-4.276,10.312-4.284,22.064,0,32.394
	c11.525,27.795,47.695,35.061,69.03,13.725C83.908,64.417,88.435,53.762,88.435,42.362z M17.609,72.362
	c11.466-0.627,14.271-0.766,15.933-0.855l-3.697,8.92C25.306,78.483,21.196,75.774,17.609,72.362z M16.121,70.872
	c-3.412-3.584-6.123-7.695-8.07-12.24c4.982-2.062,2.567-1.062,8.943-3.703C16.576,62.563,16.534,63.317,16.121,70.872z
	 M87.272,42.362c-4.619,4.136-7.754,6.945-12.408,11.115c-1.266-2.332-4.145-7.633-6.035-11.115c3.02-5.56,2.78-5.121,6.035-11.113
	C82.281,37.892,82.537,38.121,87.272,42.362z M61.081,25.875c-4.855-2.011-9.027-3.739-13.883-5.75l10.492-5.697L61.081,25.875z
	 M61.448,27.034c5.383,12.996,3.995,9.645,6.349,15.328c-2.354,5.683-0.966,2.332-6.349,15.328
	c-12.996,5.383-9.646,3.996-15.328,6.35c-5.684-2.354-2.332-0.965-15.329-6.35c-5.383-12.994-3.995-9.645-6.349-15.328
	c2.354-5.683,0.966-2.332,6.349-15.328c8.938-3.702,7.068-2.928,15.329-6.349C54.354,24.096,52.484,23.322,61.448,27.034z
	 M61.081,58.85l-3.392,11.449L47.198,64.6C52.456,62.422,56.144,60.895,61.081,58.85z M23.882,41.284l-5.701-10.492l11.451-3.392
	C27.597,32.317,26.065,36.013,23.882,41.284z M29.633,57.325l-11.451-3.393l5.701-10.492C26.053,48.68,27.574,52.356,29.633,57.325z
	 M34.55,14.428l10.491,5.698c-8.184,3.389-5.372,2.225-13.883,5.75C35.329,11.781,33.978,16.376,34.55,14.428z M34.55,70.297
	c-0.531-1.811-0.035-0.1-3.392-11.447c8.515,3.525,5.372,2.225,13.883,5.75C38.662,68.065,40.98,66.805,34.55,70.297z
	 M74.053,53.932l-11.446,3.393c2.012-4.855,3.739-9.027,5.75-13.883C70.353,47.116,71.816,49.813,74.053,53.932z M68.356,41.284
	c-0.811-1.957-4.555-10.997-5.75-13.883c2.904,0.86,8.534,2.528,11.446,3.391C70.63,37.098,70.49,37.354,68.356,41.284z
	 M74.304,29.896l-12.125-3.593l-3.593-12.125l16.634-0.916C74.769,21.461,74.804,20.803,74.304,29.896z M23.41,42.362l-6.039,11.115
	L4.967,42.362l12.404-11.113L23.41,42.362z M30.061,58.42c2.774,9.375,0.832,2.812,3.589,12.131l-16.631,0.91l0.912-16.633
	L30.061,58.42z M46.12,65.073l11.113,6.039L46.12,83.516L35.001,71.112L46.12,65.073z M62.179,58.42
	c3.084-0.912,9.049-2.68,12.125-3.592c0.492,8.943,0.475,8.627,0.916,16.633l-16.635-0.91L62.179,58.42z M75.241,29.797
	l0.877-15.944c3.41,3.588,6.119,7.7,8.065,12.24L75.241,29.797z M57.233,13.618c-5.919,3.214-5.33,2.895-11.113,6.035
	c-18.021-9.789-7.854-4.334-11.111-6.048c3.76-4.194,6.653-7.423,11.111-12.396C51.495,7.212,48.4,3.756,57.233,13.618z
	 M33.65,14.174l-3.589,12.13l-12.13,3.593L17.02,13.263C22.89,13.584,27.343,13.828,33.65,14.174z M16.994,29.797
	c-6.362-2.635-3.965-1.642-8.943-3.705c1.947-4.543,4.658-8.654,8.07-12.24C16.535,21.414,16.576,22.163,16.994,29.797z
	 M34.409,71.844c4.673,5.213,8.739,9.748,10.659,11.891c-4.959-0.123-9.783-1.115-14.363-2.953L34.409,71.844z M75.241,54.928
	l8.942,3.703c-1.946,4.541-4.655,8.652-8.065,12.24L75.241,54.928z M84.54,57.774c-3.314-1.373-5.585-2.314-8.943-3.705
	c4.059-3.635,4.549-4.074,11.895-10.654C87.368,48.374,86.378,53.198,84.54,57.774z M75.597,30.656
	c3.357-1.391,5.628-2.331,8.943-3.704c1.838,4.575,2.828,9.398,2.951,14.358C83.886,38.08,79.149,33.838,75.597,30.656z
	 M58.685,13.241c0.614-1.48,2.249-5.428,3.705-8.942c4.541,1.941,8.652,4.65,12.24,8.065L58.685,13.241z M61.53,3.942
	c-1.373,3.314-2.313,5.585-3.705,8.943C54.19,8.827,53.751,8.337,47.172,0.99C52.132,1.113,56.955,2.104,61.53,3.942z
	 M34.409,12.881l-3.705-8.939c4.58-1.838,9.404-2.829,14.363-2.952L34.409,12.881z M29.845,4.299l3.705,8.938
	c-4-0.219-11.847-0.649-15.94-0.874C21.196,8.951,25.306,6.242,29.845,4.299z M7.695,26.952l8.943,3.705
	c-5.51,4.937-6.174,5.532-11.891,10.653C4.87,36.344,5.859,31.521,7.695,26.952z M16.638,54.069l-8.943,3.705
	c-1.835-4.57-2.825-9.393-2.947-14.359C9.707,47.858,10.88,48.909,16.638,54.069z M57.826,71.844l3.704,8.938
	c-4.575,1.84-9.398,2.83-14.358,2.953C56.743,73.051,54.763,75.262,57.826,71.844z M62.39,80.426l-3.705-8.938
	c3.345,0.182,10.906,0.598,15.945,0.873C71.042,75.776,66.931,78.485,62.39,80.426z"
      />
      <path
        display="none"
        fill={props.color}
        stroke={props.color}
        strokeWidth={props.stroke_width}
        d="M88.746,0.465c0-0.011-0.004-0.021-0.005-0.031C88.74,0.413,88.732,0.35,88.732,0.35
	c-0.018-0.067-0.082-0.174-0.122-0.214c-0.007-0.007-0.017-0.011-0.024-0.018c-0.104-0.092-0.194-0.108-0.272-0.113
	C88.029-0.015,4.402,0.037,4.449,0.022C4.375,0.044,4.321,0.08,4.276,0.119c-0.278,0.246-0.158,84.03-0.154,84.077
	c0.001,0.021,0.009,0.084,0.009,0.084c0.012,0.047,0.054,0.133,0.105,0.19c0.219,0.248,84.124,0.153,84.178,0.138
	C88.926,84.454,88.746,87.725,88.746,0.465z M76.841,77.387l-6.483-11.146l11.147,6.482c1.392,2.549,4.127,7.56,5.609,10.274
	C84.393,81.512,79.377,78.773,76.841,77.387z M69.588,64.717l3.703-20.366l7.793,27.052L69.588,64.717z M73.291,40.279
	l-3.703-20.366l11.496-6.686L73.291,40.279z M68.834,19.159l-20.366-3.703L75.52,7.664C69.834,17.439,71.318,14.887,68.834,19.159z
	 M19.573,40.279L11.78,13.227l11.495,6.686L19.573,40.279z M19.573,44.351l3.703,20.366L11.78,71.403L19.573,44.351z M24.029,19.159
	L17.344,7.664l27.052,7.792L24.029,19.159z M11.478,15.537l7.714,26.778l-7.714,26.778V15.537z M46.432,68.598
	c-6.484-1.178-15.315-2.783-22.24-4.043l-4.043-22.24l4.043-22.24c6.925-1.259,15.755-2.864,22.24-4.043
	c6.484,1.179,15.316,2.785,22.24,4.043l4.044,22.24l-4.044,22.24C61.747,65.815,52.916,67.42,46.432,68.598z M68.834,65.471
	c3.611,6.211,2.8,4.813,6.686,11.495l-27.052-7.792L68.834,65.471z M81.386,69.093l-7.714-26.778l7.714-26.778V69.093z
	 M81.505,11.906l-11.147,6.483l6.483-11.147l10.273-5.61C85.629,4.354,82.89,9.369,81.505,11.906z M10.548,12.362v59.906
	L5.047,82.343V2.287L10.548,12.362z M17.344,76.966l6.686-11.495l20.366,3.703C31.317,72.941,25.001,74.761,17.344,76.966z
	 M82.315,72.268V12.362l5.501-10.075v80.056L82.315,72.268z M46.432,15.075c-4.936-1.422-19.001-5.474-26.778-7.713H73.21
	L46.432,15.075z M16.022,7.242c2.499,4.297,5.308,9.126,6.483,11.147l-11.147-6.483C9.967,9.358,7.231,4.347,5.749,1.632
	C9.272,3.556,12.423,5.277,16.022,7.242z M5.749,82.998c1.486-2.722,4.225-7.737,5.609-10.274l11.147-6.482
	c-1.9,3.268-3.589,6.172-6.483,11.146C13.98,78.503,8.16,81.681,5.749,82.998z M46.432,69.555
	c5.679,1.636,20.422,5.883,26.778,7.714H19.653C29.729,74.367,36.592,72.389,46.432,69.555z M86.46,0.93
	c-3.523,1.924-6.552,3.577-10.075,5.501H16.478C11.87,3.915,14.104,5.135,6.403,0.93H86.46z M16.478,78.199h59.907L86.459,83.7
	H6.403C12.551,80.343,12.874,80.167,16.478,78.199z"
      />
      <path
        display="none"
        fill={props.color}
        stroke={props.color}
        strokeWidth={props.stroke_width}
        d="M91.736,27.658c0.937-11.534-5.308-21-14.303-25.254c-10.972-5.19-24.391-1.821-31.513,8.609
	C38.833,0.612,25.383-2.803,14.401,2.41H14.4v0c-4.182,1.973-7.74,5.067-10.288,8.95c0,0,0,0,0,0.001
	c-3.147,4.777-4.498,10.246-4.017,16.253c-0.008,0.047-0.002,0.091,0.01,0.141C1.37,45.068,14.896,63.381,29.146,74.282
	c0.001,0,0.002,0.001,0.002,0.001c5.551,4.253,11.141,7.401,16.61,9.356c0.106,0.038,0.206,0.036,0.31,0.002
	c0.004-0.001,0.009,0,0.013-0.002C67.273,76.03,90.087,50.254,91.73,27.752C91.738,27.719,91.738,27.689,91.736,27.658z
	 M29.275,51.406c4.094,2.192,11.962,6.406,15.888,8.508l-6.547,7.46C34.372,60.119,36.456,63.68,29.275,51.406z M45.92,59.233
	c-3.491-1.869-11.087-5.937-17.52-9.382c-1.93-5.887-3.146-9.599-5.689-17.354c0.792-1.522,4.953-9.521,6.347-12.199l16.857-2.311
	l16.862,2.311c0.272,0.526,6.055,11.638,6.347,12.2l-5.69,17.354C62.532,50.335,46.811,58.756,45.92,59.233z M69.873,33.281
	l8.167,6.942l-13.328,8.804C65.411,46.892,68.646,37.027,69.873,33.281z M63.796,20.185l11.749-2.713l-5.946,13.867L63.796,20.185z
	 M28.038,20.185l-5.802,11.154L16.29,17.471L28.038,20.185z M27.123,49.027l-13.329-8.804l8.167-6.942L27.123,49.027z M53.22,67.374
	l-6.543-7.46l15.883-8.508C59.312,56.958,57.664,59.776,53.22,67.374z M76.704,3.571L75.9,16.406l-12.372,2.858
	c-0.329-5.2-0.312-4.902-0.634-9.991L76.704,3.571z M62.572,19.304l-15.098-2.069l14.489-7.511L62.572,19.304z M29.871,9.724
	l14.485,7.511l-15.094,2.069C29.485,15.789,29.784,11.084,29.871,9.724z M15.275,17.533c2.44,5.69,5.231,12.198,6.34,14.786
	l-8.587,7.3L1.303,27.737L15.275,17.533z M18.038,62.511l-4.338-21.205l13.949,9.214c0.472,0.807,9.492,16.226,9.9,16.923
	C33.124,66.326,23.016,63.77,18.038,62.511z M54.286,67.444c0.408-0.697,9.427-16.115,9.899-16.923l13.949-9.214l-4.338,21.205
	C68.821,63.769,58.71,66.326,54.286,67.444z M78.807,39.619l-8.588-7.3c1.123-2.62,3.979-9.276,6.341-14.786
	c1.923,1.404,10.775,7.869,13.972,10.204C74.464,44.02,80.005,38.403,78.807,39.619z M29.801,8.247l-1.219-7.04
	c3.193,0.453,6.202,1.521,8.964,3.182C36.68,4.821,35.492,5.418,29.801,8.247z M28.94,9.273c-0.072,1.137-0.53,8.343-0.635,9.991
	l-12.372-2.858L15.13,3.576C18.655,5.03,25.419,7.821,28.94,9.273z M45.92,82.105c-7.566-15.367-6.012-12.179-6.819-13.834
	l6.819-7.769l6.813,7.769C52.221,69.322,52.195,69.348,45.92,82.105z M85.921,44.695l-6.35-4.489
	c15.528-15.737,8.053-8.131,11.087-11.222C90.113,33.976,88.524,39.252,85.921,44.695z M90.839,26.776l-13.55-9.895
	c2.58-1.217,7.383-3.481,9.858-4.648C89.902,16.582,91.125,21.591,90.839,26.776z M86.615,11.428l-9.732,4.587l0.78-12.433
	C81.253,5.411,84.329,8.106,86.615,11.428z M76.016,2.82L63.014,8.188c0.629-3.631,0.611-3.521,1.229-7.102
	C68.26,0.67,72.299,1.268,76.016,2.82z M62.033,8.247c-5.071-2.521-7.046-3.51-7.745-3.857c2.736-1.64,5.808-2.73,8.964-3.182
	C62.691,4.447,62.966,2.863,62.033,8.247z M53.332,4.991l8.006,3.979c-3.798,1.969-11.137,5.773-14.944,7.747l0.004-4.711
	C48.196,9.238,50.587,6.819,53.332,4.991z M45.441,12.005l-0.005,4.712L30.497,8.97l8.006-3.979
	C41.258,6.823,43.651,9.242,45.441,12.005z M27.59,1.086l1.229,7.102c-4.412-1.82-9.194-3.792-13.003-5.364
	C19.525,1.268,23.567,0.67,27.59,1.086z M14.172,3.586c0.471,7.517,0.308,4.909,0.778,12.428l-9.731-4.587
	C7.512,8.102,10.588,5.408,14.172,3.586z M4.686,12.233l9.859,4.648c-6.629,4.84-12.917,9.413-13.549,9.887
	C0.71,21.425,1.949,16.541,4.686,12.233z M12.263,40.206l-6.349,4.489c-2.606-5.449-4.197-10.729-4.739-15.726
	C5.336,33.186,8.246,36.135,12.263,40.206z M6.338,45.566c4.8-3.394,3.363-2.378,6.339-4.481l4.119,20.131
	C12.577,56.261,8.985,50.888,6.338,45.566z M19.045,63.752l18.291,4.625l-7.87,4.944C25.781,70.478,22.282,67.265,19.045,63.752z
	 M30.279,73.941c2.955-1.856,1.104-0.695,8.057-5.061c2.076,4.293-4.283-8.712,6.623,13.44
	C40.168,80.462,35.129,77.585,30.279,73.941z M46.881,82.319c8.645-17.573,5.804-11.754,6.618-13.438l8.057,5.061
	C56.731,77.571,51.694,80.447,46.881,82.319z M62.367,73.322l-7.869-4.944l18.29-4.625C69.552,67.265,66.054,70.478,62.367,73.322z
	 M79.157,41.084c2.956,2.09,1.644,1.162,6.339,4.481c-2.646,5.322-6.238,10.695-10.457,15.649L79.157,41.084z"
      />
      <path
        fill={props.color}
        stroke={props.color}
        strokeWidth={props.stroke_width}
        d="M67.109,30.888C64.381,18.393,57.199,7.522,47.306,0.07c-0.002,0-0.002,0-0.003-0.001
	c0,0,0-0.001-0.001-0.001c-0.001-0.001-0.037-0.021-0.061-0.032c-0.012-0.005-0.024-0.014-0.037-0.018
	c-0.01-0.002-0.019-0.002-0.029-0.005c-0.006-0.001-0.011-0.005-0.017-0.006c-0.001,0-0.002-0.001-0.004-0.002
	c-0.009-0.001-0.017,0.002-0.026,0.001C47.118,0.004,47.107,0,47.096,0s-0.022,0.004-0.034,0.005
	c-0.009,0.001-0.017-0.002-0.025-0.001c0,0-0.021,0.008-0.022,0.008c-0.009,0.002-0.019,0.002-0.027,0.004
	c-0.013,0.004-0.025,0.013-0.037,0.019c-0.023,0.011-0.061,0.031-0.062,0.032c-9.307,6.81-16.953,17.895-19.782,30.709
	c-0.026,0.035-0.04,0.079-0.034,0.146c-1.604,7.369-1.603,15.158,0.006,22.527c0.002,0.018,0.006,0.033,0.012,0.052
	c2.729,12.441,9.863,23.305,19.795,30.787c0,0,0.001,0,0.002,0.001c0.001,0,0.001,0,0.001,0c0.001,0.001,0.002,0.001,0.003,0.002
	c0.015,0.012,0.048,0.025,0.057,0.028c0.003,0.003,0.036,0.019,0.038,0.021c0.014,0.004,0.015,0.001,0.029,0.004
	c0.007,0.002,0.013,0.007,0.021,0.008c0.037,0.008,0.081,0.008,0.118,0c0.007-0.001,0.014-0.006,0.02-0.008
	c0.009-0.001,0.019-0.001,0.027-0.004c0.002,0,0.085-0.041,0.097-0.049c0-0.001,0.002-0.001,0.003-0.002c0.001,0,0.001,0,0.001,0
	c9.701-7.277,17.013-18.099,19.797-30.784c0.007-0.02,0.009-0.036,0.012-0.056C67.354,51.751,69.705,42.765,67.109,30.888z
	 M61.348,66.963l-6.135-4.296c12.96-9.904,9.505-7.248,10.995-8.393C65.151,58.705,63.52,62.967,61.348,66.963z M27.982,54.275
	c1.607,1.235-1.285-0.993,10.996,8.393l-6.136,4.296C30.671,62.967,29.039,58.705,27.982,54.275z M32.313,42.18
	c4.467-8.307,2.514-4.677,6.907-12.843v25.686C34.827,46.856,36.781,50.487,32.313,42.18z M61.879,42.18
	c-4.468,8.306-2.516,4.676-6.908,12.842V29.336C59.363,37.503,57.411,33.871,61.879,42.18z M39.917,28.092l7.178-7.51l7.178,7.51
	v28.175l-7.178,7.511l-7.178-7.511V28.092z M54.273,21.962v5.121l-6.146-6.43C51.616,21.396,50.51,21.161,54.273,21.962z
	 M46.062,20.653l-6.146,6.43v-5.121C43.683,21.16,42.577,21.396,46.062,20.653z M39.22,56.495v5.479l-11.377-8.693l4.12-10.28
	L39.22,56.495z M39.917,62.397v-5.122l6.146,6.43C42.575,62.962,43.682,63.198,39.917,62.397z M48.128,63.705l6.146-6.43v5.122
	C50.51,63.199,51.614,62.962,48.128,63.705z M54.971,56.495l7.257-13.494l4.12,10.28l-11.377,8.693V56.495z M66.347,31.08
	l-4.119,10.277l-7.257-13.494v-5.479C69.029,33.129,64.445,29.63,66.347,31.08z M54.087,21.209
	c-2.328-0.495-4.078-0.868-6.643-1.415V2.385C49.536,8.314,50.844,12.02,54.087,21.209z M46.747,2.385v17.409
	c-3.993,0.85-4.261,0.908-6.643,1.415C43.347,12.02,44.654,8.314,46.747,2.385z M39.22,22.384v5.479l-7.257,13.494l-4.12-10.279
	C35.742,25.042,33.463,26.784,39.22,22.384z M40.104,63.149c2.327,0.496,4.078,0.869,6.643,1.415v17.409
	C44.654,76.044,43.347,72.34,40.104,63.149z M47.444,81.973V64.564c3.993-0.851,4.26-0.907,6.643-1.415
	C50.844,72.34,49.536,76.044,47.444,81.973z M66.661,52.188L62.65,42.18l4.013-10.013C67.943,38.74,67.942,45.614,66.661,52.188z
	 M55.213,21.691l6.135-4.295c2.179,4.007,3.812,8.274,4.867,12.704L55.213,21.691z M61.01,16.782c-2.286,1.6-0.706,0.494-6.21,4.349
	L47.817,1.346C53.148,5.557,57.688,10.868,61.01,16.782z M46.374,1.346l-6.981,19.786c-5.927-4.149-4.013-2.81-6.211-4.349
	C36.502,10.868,41.043,5.557,46.374,1.346z M32.842,17.396l6.136,4.295L27.976,30.1C29.03,25.67,30.665,21.403,32.842,17.396z
	 M27.526,32.178c0.512,1.272,1.622,4.03,4.014,10.001l-4.01,10.008C26.25,45.619,26.248,38.748,27.526,32.178z M33.182,67.577
	c2.285-1.602,0.705-0.494,6.211-4.349l6.981,19.785C41.042,78.8,36.501,73.492,33.182,67.577z M47.817,83.013L54.8,63.228
	c5.926,4.148,4.011,2.808,6.21,4.349C57.688,73.492,53.148,78.8,47.817,83.013z"
      />
      <path
        display="none"
        fill={props.color}
        stroke={props.color}
        strokeWidth={props.stroke_width}
        d="M88.456,70.973c0.046-0.137,0.042-57.193-0.013-57.325C88.391,13.521,89.268,14.404,75,0.149
	c-0.072-0.071-0.209-0.136-0.328-0.136c-59.52,0-57.115-0.072-57.328,0.13C16.739,0.717,3.906,13.585,3.981,13.505
	c-0.076,0.08-0.129,0.209-0.129,0.318c0,59.447-0.072,57.109,0.129,57.322c0.453,0.478,13.318,13.325,13.366,13.37
	c0.084,0.079,0.212,0.127,0.315,0.127c59.566,0,57.111,0.07,57.324-0.127c0.084-0.078,13.252-13.247,13.367-13.372
	C88.402,71.093,88.441,71.02,88.456,70.973z M10.85,65.816l-6.068,4.133V14.703l6.068,4.133V65.816z M80.554,65.182l-5.427-3.694
	V23.177c0.001-0.001,4.184-2.86,5.427-3.708V65.182z M26.689,70.093l-8.294-8.29l5.376-3.659c0.576,0.576,5.909,5.908,6.58,6.579
	C29.055,66.624,28.082,68.051,26.689,70.093z M74.191,60.849l-5.221-3.552V27.355l5.227-3.557c0,17.951-0.01,27.413-0.015,32.395
	C74.18,58.639,74.18,60.036,74.191,60.849z M73.936,22.851c-2.077,1.414-4.395,2.991-5.373,3.657
	c-0.576-0.576-5.908-5.908-6.58-6.579l3.662-5.37L73.936,22.851z M61.136,19.52H31.198c-1.612-2.365-0.812-1.191-3.56-5.222h37.057
	C61.973,18.293,62.762,17.134,61.136,19.52z M18.151,56.295c-0.005-4.954-0.014-14.424-0.015-32.497l5.227,3.557v29.942l-5.22,3.552
	C18.155,60.045,18.153,58.675,18.151,56.295z M23.771,26.508c-0.978-0.666-3.3-2.246-5.366-3.652
	c2.433-2.386-4.66,4.648,8.284-8.297l3.662,5.37C29.68,20.6,24.348,25.932,23.771,26.508z M17.207,23.177V61.48
	c-1.838,1.203,3.581-2.433-5.427,3.702V19.47C20.836,25.646,15.379,21.978,17.207,23.177z M31.145,64.202
	c-2.396-2.396-4.456-4.455-6.852-6.852V27.301c2.396-2.396,4.455-4.455,6.852-6.852h30.044c2.396,2.396,4.455,4.455,6.852,6.852
	V57.35c-2.396,2.396-4.455,4.455-6.852,6.852H31.145z M61.982,64.723c0.672-0.671,6.004-6.003,6.58-6.579l5.367,3.652
	c-1.979,1.949,3.266-3.249-8.285,8.297C64.42,68.296,63.102,66.365,61.982,64.723z M74.728,22.327l-8.55-8.55l3.791-5.574
	l10.324,10.319C76.324,21.228,75.548,21.767,74.728,22.327z M65.33,13.368H27.004l-3.692-5.426h45.709L65.33,13.368z M26.157,13.777
	l-8.543,8.543l-5.573-3.798L22.365,8.203L26.157,13.777z M31.198,65.131h29.938c1.612,2.365,0.812,1.191,3.56,5.223H27.638
	C30.362,66.358,29.572,67.517,31.198,65.131z M81.484,18.836l6.068-4.133v55.246l-6.068-4.133V18.836z M69.654,7.011H22.679
	l-4.137-6.068h55.249L69.654,7.011z M12.041,66.129l5.566-3.789c0.454,0.471,1.416,1.405,8.549,8.535
	c-1.131,1.663-0.578,0.851-3.792,5.574C18.692,72.779,15.948,70.035,12.041,66.129z M27.004,71.284H65.33l3.691,5.427H23.312
	L27.004,71.284z M69.969,76.45l-3.791-5.574c4.202-4.201,6.406-6.394,7.555-7.537c0.475-0.473,0.787-0.783,0.994-0.998l5.566,3.79
	C76.682,69.74,73.58,72.84,69.969,76.45z M87.291,13.755l-6.216,4.233c-6.232-6.229-4.091-4.089-10.573-10.568l4.238-6.217
	C78.393,4.853,79.676,6.138,87.291,13.755z M17.594,1.204c0.719,1.055,2.587,3.794,4.238,6.217
	C15.6,13.65,17.741,11.51,11.259,17.989l-6.216-4.233L17.594,1.204z M11.259,66.663c6.488,6.485,4.09,4.089,10.573,10.569
	l-4.238,6.221L5.042,70.896L11.259,66.663z M18.542,83.713c1.545-2.269,2.626-3.854,4.137-6.073h46.975
	c1.487,2.184,2.577,3.783,4.138,6.073H18.542z M74.739,83.453l-4.237-6.221c6.488-6.485,4.09-4.089,10.573-10.569l6.216,4.233
	C85.348,72.84,76.834,81.357,74.739,83.453z"
      />
      <path
        display="none"
        fill={props.color}
        stroke={props.color}
        strokeWidth={props.stroke_width}
        d="M67.271,73.848c2.773-4.117,4.24-8.922,4.24-13.904c0-4.555-0.286-8.945-0.851-13.053
	c0.012-0.067,0.014-0.117-0.026-0.184c-1.171-8.384-3.556-15.991-7.071-23.055c-0.009-0.025-0.019-0.048-0.035-0.07
	c-3.418-6.852-8.452-14.384-16.658-23.164c0,0-0.064-0.053-0.071-0.057c-0.005-0.003-0.007-0.008-0.013-0.011
	c-0.018-0.011-0.104-0.042-0.131-0.046c-0.012-0.002-0.023-0.002-0.035-0.002c-0.009-0.001-0.018-0.004-0.028-0.004
	s-0.019,0.003-0.027,0.004c-0.012,0-0.024,0-0.036,0.002c-0.021,0.003-0.096,0.028-0.121,0.042c-0.003,0.001-0.006,0.001-0.01,0.004
	c-0.005,0.002-0.008,0.008-0.013,0.011c-0.006,0.004-0.071,0.057-0.071,0.057C39.783,7.405,33.94,14.986,29.64,23.612
	c-0.007,0.012-0.014,0.023-0.019,0.037c-5.686,11.426-7.95,23.272-7.95,36.295c0,4.982,1.467,9.788,4.241,13.905
	c0.015,0.064,0.046,0.099,0.092,0.136c2.371,3.468,5.604,6.305,9.358,8.204h0.001c11.184,5.657,24.779,2.094,31.815-8.204
	C67.224,73.948,67.255,73.914,67.271,73.848z M56.056,12.72c2.461,3.44,4.544,6.827,6.339,10.311
	c-1.64-0.685-2.614-1.08-9.198-3.827C54.275,16.755,54.999,15.116,56.056,12.72z M62.799,24.035L60.966,38.72
	c-2.951-1.721-4.912-2.866-7.65-4.463c-0.123-7.483-0.112-6.873-0.233-14.275C56.734,21.506,59.104,22.494,62.799,24.035z
	 M66.454,73.339l-10.875,0.219l2.16-8c1.853-1.661,3.501-3.137,6.345-5.685C64.907,64.549,65.71,69.107,66.454,73.339z
	 M40.856,20.395c3.128,3.544,2.046,2.319,5.24,5.938c-1.351,1.738-4.104,5.276-5.453,7.01L40.856,20.395z M64.314,58.309
	c-2.477-4.415-2.398-4.273-4.896-8.728l2.091-9.546l8.29,6.925C66.326,54.147,67.438,51.847,64.314,58.309z M59.271,50.876
	l4.587,8.177l-5.978,5.354L59.271,50.876z M58.626,49.696c-0.429,4.178-1.212,11.795-1.584,15.42l-10.451,5.907L36.14,65.116
	c-0.372-3.63-1.158-11.271-1.584-15.42l6.022-15.026l6.013-7.731l6.014,7.731L58.626,49.696z M33.912,50.876l1.39,13.531
	l-5.977-5.354L33.912,50.876z M36.387,66.129l9.232,5.219l-7.25,2.125L36.387,66.129z M60.84,39.529l-1.932,8.821l-5.204-12.984
	C58.343,38.072,56.454,36.972,60.84,39.529z M52.326,20.395l0.212,12.948c-1.35-1.734-4.102-5.272-5.453-7.01
	C50.29,22.703,49.197,23.941,52.326,20.395z M56.796,66.129l-1.982,7.344l-7.25-2.125L56.796,66.129z M46.591,25.742
	c-0.487-0.552-5.015-5.683-5.67-6.425l5.67-17.409l5.671,17.409C51.606,20.059,47.079,25.189,46.591,25.742z M39.477,35.366
	L34.275,48.35l-1.933-8.821C36.446,37.134,35.268,37.823,39.477,35.366z M31.672,40.033c0.618,2.82,1.336,6.1,2.092,9.548
	l-4.897,8.728l-5.482-11.365C28.151,42.969,26.079,44.698,31.672,40.033z M29.099,59.874c2.839,2.544,4.488,4.021,6.344,5.685
	l2.16,8l-10.876-0.219C29.782,55.996,28.442,63.6,29.099,59.874z M64.804,59.573l5.942,0.709c-0.06,4.41-1.306,8.667-3.621,12.392
	C66.888,71.336,66.534,69.403,64.804,59.573z M64.914,58.818l5.145-10.646c0.442,3.601,0.674,7.406,0.69,11.342
	C68.861,59.29,66.553,59.013,64.914,58.818z M61.967,39.424l5.779-2.833c0.849,3.013,1.521,6.141,2.001,9.314
	C67.745,44.242,66.097,42.873,61.967,39.424z M30.383,24.035l9.718-4.053c-0.122,7.404-0.112,6.794-0.235,14.275
	c-2.737,1.597-4.705,2.745-7.65,4.463C31.821,35.549,30.65,26.174,30.383,24.035z M25.437,36.592l5.778,2.832l-7.781,6.487
	C23.915,42.732,24.587,39.604,25.437,36.592z M23.124,48.171c1.497,3.09,3.654,7.557,5.144,10.646l-5.834,0.696
	C22.449,55.581,22.681,51.775,23.124,48.171z M28.378,59.573c-1.692,9.613-2.134,12.044-2.321,13.101
	c-2.315-3.725-3.561-7.981-3.622-12.392L28.378,59.573z M38.757,74.155l7.833-2.298c2.733,0.802,4.897,1.438,7.833,2.298
	c-0.946,1.175-6.54,8.119-7.833,9.721C44.246,80.966,41.103,77.066,38.757,74.155z M54.938,74.755l2.247,6.898
	c-3.078,1.506-6.364,2.323-9.787,2.436C58.693,70.076,51.989,78.438,54.938,74.755z M61.737,38.688
	c1.099-8.796,0.733-5.871,1.693-13.566c1.667,3.491,3.013,7.015,4.102,10.727L61.737,38.688z M52.744,18.34L47.492,2.216
	c3.039,3.329,5.682,6.545,8.046,9.788L52.744,18.34z M45.69,2.215L40.438,18.34l-2.794-6.337C40.007,8.763,42.65,5.547,45.69,2.215z
	 M37.126,12.72c1.057,2.396,1.78,4.036,2.859,6.484l-9.205,3.84C32.579,19.554,34.664,16.162,37.126,12.72z M29.744,25.135
	c0.257,2.07,0.324,2.527,1.7,13.553l-5.794-2.84C26.736,32.143,28.081,28.624,29.744,25.135z M27.02,74.108l10.565,0.212
	l-2.274,6.984C32.037,79.572,29.19,77.099,27.02,74.108z M35.997,81.654l2.247-6.898c0.837,1.046,1.705,2.093,7.54,9.334
	C42.362,83.977,39.075,83.16,35.997,81.654z M57.872,81.304l-2.274-6.984l10.564-0.212C63.992,77.099,61.145,79.572,57.872,81.304z"
      />
      <path
        display="none"
        fill={props.color}
        stroke={props.color}
        strokeWidth={props.stroke_width}
        d="M68.048,12.443c-11.882-16.589-31.22-16.59-43.105,0
	C9.644,33.796,14.909,69.844,34.803,81.36h0c7.448,4.31,15.922,4.318,23.383,0C78.133,69.817,83.298,33.721,68.048,12.443z
	 M24.914,70.68c-2.396-3.573-4.3-7.655-5.667-12.155l6.289-3.637C25.464,56.703,25.061,66.962,24.914,70.68z M19.009,57.718
	c-1.3-4.54-2.006-9.322-2.104-14.24l8.452,10.568C21.514,56.269,21.088,56.515,19.009,57.718z M68.076,13.917
	c2.396,3.571,4.3,7.653,5.667,12.156l-6.27,3.626C67.582,27.309,67.354,32.22,68.076,13.917z M58.378,57.291l4.075-13.73
	l4.038,10.378C61.332,56.07,63.625,55.122,58.378,57.291z M61.975,42.3c-1.353,4.56-2.498,8.414-4.578,15.423l-10.901,6.303
	l-10.902-6.303c-1.119-3.771-2.478-8.352-4.577-15.425c1.327-4.469,3.726-12.555,4.577-15.424c3.778-2.184,7.028-4.062,10.902-6.302
	c3.822,2.209,7.081,4.093,10.901,6.302C58.267,29.807,60.653,37.848,61.975,42.3z M30.538,41.038l-4.036-10.372
	c3.307-1.343-5.991,2.47,8.111-3.36L30.538,41.038z M47.232,20.053l7.494-5.68c0.953,4.598-1.748-8.252,2.426,11.415L47.232,20.053z
	 M45.758,20.053l-9.919,5.734l2.422-11.417L45.758,20.053z M25.85,31.244l4.302,11.054L25.85,53.353l-8.841-11.055L25.85,31.244z
	 M38.261,70.227L35.838,58.81l9.919,5.734L38.261,70.227z M47.232,64.544l9.919-5.734l-2.422,11.417L47.232,64.544z M62.453,41.038
	c-0.903-3.04-0.244-0.824-4.075-13.732c10.303,4.257,7.062,2.935,8.111,3.359C64.397,36.04,64.53,35.701,62.453,41.038z
	 M66.633,29.834l-8.536-3.528c-0.016-0.074-2.587-12.196-2.604-12.278l11.798-0.904L66.633,29.834z M46.495,19.586l-7.953-6.027
	l7.953-12.39l7.945,12.379C51.78,15.494,59.64,9.622,46.495,19.586z M26.499,53.938c2.882-7.407,1.943-4.992,4.039-10.378
	c0.566,1.909,3.526,11.884,4.075,13.731C30.393,55.546,30.742,55.691,26.499,53.938z M46.495,65.011l7.953,6.028l-7.953,12.389
	L38.542,71.04L46.495,65.011z M67.142,31.244l8.84,11.054l-8.841,11.055L62.839,42.3C66.047,34.055,65.177,36.29,67.142,31.244z
	 M54.858,12.684l-7.606-11.85c3.538,0.124,6.98,1.114,10.247,2.95C56.85,5.971,55.541,10.383,54.858,12.684z M38.132,12.684
	l-2.641-8.899c3.267-1.836,6.708-2.827,10.248-2.95L38.132,12.684z M37.498,14.027c-0.123,0.576-2.495,11.759-2.605,12.278
	l-8.535,3.528l-0.657-16.71C32.924,13.677,30.315,13.477,37.498,14.027z M25.518,29.699l-6.271-3.626
	c1.367-4.502,3.271-8.584,5.667-12.156C25.677,33.375,25.43,27.75,25.518,29.699z M19.009,26.88c2.086,1.206,2.508,1.45,6.348,3.67
	l-8.452,10.568C17.003,36.2,17.709,31.418,19.009,26.88z M25.701,71.473c0.227-5.76,0.517-13.151,0.657-16.709
	c5.796,2.396,4.344,1.795,8.535,3.527c0.11,0.519,2.482,11.7,2.605,12.279C30.312,71.12,32.923,70.92,25.701,71.473z M38.132,71.913
	l7.607,11.851c-3.54-0.124-6.982-1.116-10.248-2.951L38.132,71.913z M54.858,71.913l2.641,8.899
	c-3.266,1.837-6.707,2.827-10.247,2.951L54.858,71.913z M55.492,70.571c0.23-1.092,2.312-10.896,2.604-12.279l8.535-3.527
	l0.658,16.709C60.076,70.92,62.69,71.122,55.492,70.571z M67.455,54.888l6.288,3.637c-1.366,4.502-3.271,8.584-5.667,12.155
	C67.951,67.488,67.671,60.404,67.455,54.888z M67.633,30.55c3.844-2.222,4.271-2.468,6.35-3.67c1.299,4.538,2.006,9.32,2.104,14.239
	C75.096,39.879,69.365,32.715,67.633,30.55z M66.953,12.329c-2.896,0.222-8.508,0.652-11.396,0.874l2.669-8.994
	C61.465,6.17,64.396,8.898,66.953,12.329z M34.765,4.209l2.669,8.994c-5.831-0.447-5.622-0.431-11.396-0.874
	C28.596,8.897,31.527,6.17,34.765,4.209z M26.038,72.268c5.769-0.443,5.553-0.426,11.396-0.874l-2.669,8.992
	C31.528,78.426,28.596,75.699,26.038,72.268z M58.226,80.386l-2.669-8.992c5.886,0.452,5.482,0.421,11.396,0.874
	C64.395,75.699,61.464,78.426,58.226,80.386z M73.982,57.718c-2.075-1.2-0.675-0.39-6.35-3.672
	c8.953-11.194,7.523-9.407,8.453-10.568C75.988,48.396,75.281,53.18,73.982,57.718z"
      />
      <path
        display="none"
        fill={props.color}
        stroke={props.color}
        strokeWidth={props.stroke_width}
        d="M88.606,36.568L88.606,36.568c-0.35-5.357-1.203-10.79-2.537-16.147
	c-1.469-5.893-3.994-12.7-4.312-13.226c-0.041-0.069-0.089-0.117-0.158-0.158c-0.029-0.018-0.062-0.035-0.064-0.035
	C68.585,1.822,54.947-0.747,40.747,0.19c-9.023,0.587-16.196,2.55-16.149,2.538c-6.014,1.499-12.881,4.035-13.271,4.34
	c-0.056,0.044-0.11,0.114-0.139,0.179c-0.894,2.014-2.981,7.953-4.282,13.173C4.609,29.61,3.76,38.885,4.364,48.05h0
	c0.665,10.196,3.089,19.965,6.816,29.306c0.082,0.205,0.307,0.279,0.259,0.26c22.685,9.084,46.11,9.597,70.095,0
	c0.205-0.082,0.279-0.306,0.26-0.259C87.007,64.307,89.538,50.702,88.606,36.568z M80.971,42.309
	c1.714-1.229,6.225-4.46,6.759-4.843c0.191,3.318,0.191,6.369,0,9.686C85.753,45.736,83.631,44.215,80.971,42.309z M12,42.309
	l-6.759,4.843c-0.184-3.25-0.184-6.435,0-9.685L12,42.309z M67.894,80.868l-20.38-5.093l10.622-8.468H70.46
	C69.19,74.018,69.737,71.128,67.894,80.868z M13.393,42.309c3.629-4.557,4.459-5.599,8.092-10.16v20.32
	C17.852,47.907,17.022,46.867,13.393,42.309z M22.416,53.606V31.012c6.401-6.401,3.461-3.461,12.774-12.771h22.59
	c12.053,12.048,9.484,9.48,12.775,12.771v22.594c-2.754,2.754-0.428,0.429-12.775,12.771H35.19
	C25.655,56.846,28.297,59.488,22.416,53.606z M71.485,52.469v-20.32c3.633,4.562,4.463,5.603,8.092,10.16
	C75.948,46.866,75.118,47.908,71.485,52.469z M71.485,30.656v-12.32l13.561,2.562L79.95,41.283L71.485,30.656z M70.556,29.697
	c-2.424-2.424-0.383-0.383-11.457-11.456h11.457V29.697z M46.487,9.214l10.154,8.095H36.33C41.574,13.13,41.241,13.396,46.487,9.214
	z M36.33,67.309h20.312l-10.154,8.095C41.242,71.223,41.575,71.489,36.33,67.309z M70.556,54.921v11.456H59.099
	C70.464,55.013,68.519,56.958,70.556,54.921z M71.485,53.961l8.465-10.626l5.096,20.384l-13.561,2.562V53.961z M70.46,17.311H58.136
	L47.514,8.843l20.38-5.093C69.731,13.458,69.21,10.704,70.46,17.311z M22.415,29.697V18.241h11.458
	C25.755,26.358,28.303,23.81,22.415,29.697z M22.415,54.921c5.414,5.412,2.827,2.825,11.458,11.456H22.415V54.921z M45.461,8.843
	l-10.627,8.468H22.511c1.27-6.711,0.723-3.821,2.566-13.562C31.333,5.313,37.897,6.953,45.461,8.843z M11.785,8.264l9.214,9.218
	c-5.042,0.953-10.786,2.039-13.011,2.459C8.978,16.098,10.253,12.176,11.785,8.264z M7.926,20.899l13.559-2.562v12.32l-8.464,10.626
	C11.709,36.036,13.814,44.455,7.926,20.899z M13.021,43.335l8.464,10.626v12.32L7.926,63.719
	C12.854,44.002,12.386,45.874,13.021,43.335z M20.999,67.136l-9.214,9.218c-1.532-3.912-2.807-7.833-3.797-11.677
	C10.261,65.107,16.254,66.239,20.999,67.136z M25.077,80.868c-1.837-9.707-1.315-6.953-2.566-13.561h12.324l10.627,8.468
	C37.897,77.665,31.331,79.306,25.077,80.868z M80.931,41.194l4.676-18.7c1.051,4.616,1.74,9.274,2.055,13.878L80.931,41.194z
	 M71.972,17.482l9.219-9.218c1.52,3.879,2.795,7.801,3.797,11.677L71.972,17.482z M80.533,7.606l-9.22,9.219L68.852,3.809
	C72.728,4.807,76.65,6.083,80.533,7.606z M47.604,7.862c1.248-1.742,3.605-5.03,4.821-6.726c4.625,0.315,9.283,1.005,13.877,2.053
	L47.604,7.862z M51.33,1.067l-4.843,6.755l-4.842-6.755C44.958,0.877,48.009,0.877,51.33,1.067z M40.55,1.136
	c0.654,0.912,3.675,5.125,4.822,6.726C35.238,5.33,36.056,5.535,26.669,3.189C31.264,2.14,35.924,1.451,40.55,1.136z M21.656,16.825
	l-9.215-9.219c3.878-1.523,7.8-2.798,11.677-3.797L21.656,16.825z M7.366,22.507c0.031,0.124,2.448,9.778,4.674,18.687L5.31,36.372
	C5.624,31.767,6.314,27.113,7.366,22.507z M12.041,43.424C9.167,54.92,7.821,60.293,7.366,62.111
	c-1.052-4.605-1.743-9.259-2.057-13.864L12.041,43.424z M12.441,77.012l9.215-9.219l2.462,13.016
	C20.241,79.81,16.319,78.536,12.441,77.012z M45.373,76.756c-1.148,1.602-4.172,5.819-4.822,6.727
	c-4.626-0.315-9.286-1.005-13.881-2.054C36.057,79.083,35.237,79.289,45.373,76.756z M41.645,83.551l4.842-6.755l4.843,6.755
	C48.011,83.742,44.96,83.743,41.645,83.551z M52.425,83.483c-1.221-1.704-3.595-5.016-4.821-6.727l18.698,4.673
	C61.708,82.478,57.05,83.167,52.425,83.483z M71.313,67.793c3.381,3.381,5.748,5.746,9.22,9.219
	c-3.882,1.523-7.806,2.799-11.682,3.797L71.313,67.793z M81.19,76.354c-5.434-5.434-3.637-3.636-9.219-9.218l13.016-2.459
	C83.985,68.553,82.71,72.475,81.19,76.354z M85.606,62.125l-4.676-18.701c1.125,0.806,4.09,2.931,6.73,4.822
	C87.347,52.85,86.657,57.508,85.606,62.125z"
      />
      <path
        display="none"
        fill={props.color}
        stroke={props.color}
        strokeWidth={props.stroke_width}
        d="M74.366,42.263c0-31.299-0.028-29.584-0.099-29.701C74.245,12.489,62.414,0.127,62.373,0.094
	c-0.064-0.05-0.132-0.076-0.204-0.082C61.842-0.016,29.791,0.015,29.78,0.014c-0.125-0.016-0.259,0.04-0.33,0.117
	C29.27,0.326,17.61,12.519,17.59,12.585c-0.147,0.29-0.039,59.083-0.046,59.123c-0.014,0.089,0.013,0.174,0.056,0.25
	c0.016,0.051,11.812,12.393,11.849,12.431c0.193,0.214-1.643,0.109,28.179,0.145c1.901,0.002,3.915,0.011,4.44-0.029
	c0.009,0.001,0.015,0.007,0.023,0.008c0.077,0.01,0.148-0.008,0.22-0.044c0.06-0.02,0.082-0.042,0.11-0.073
	c0.36-0.392,11.825-12.361,11.848-12.433C74.344,71.836,74.366,73.755,74.366,42.263z M59.683,79.232
	c-1.235-2.086-0.938-1.584-1.401-2.364l8.234-8.62l2.422,1.293C60.502,78.374,62.238,76.556,59.683,79.232z M21.908,69.205
	l-3.596,1.919c-0.035-5.903-0.035-51.818,0-57.722l3.596,1.919V69.205z M55.908,11.006l7.159,7.502v47.509l-7.159,7.502h-19.94
	l-7.164-7.502V18.508l7.16-7.502H55.908z M28.337,66.656l6.976,7.306l-1.31,2.21l-7.934-8.306
	C27.446,67.131,26.795,67.479,28.337,66.656z M25.823,17.411l2.202,1.175v47.353l-2.202,1.176V17.411z M28.337,17.869l-2.268-1.21
	l7.934-8.305l1.308,2.207C31.212,14.856,32.815,13.176,28.337,17.869z M66.05,67.115l-2.203-1.176V18.586l2.203-1.175V67.115z
	 M57.869,8.354l7.934,8.305l-2.269,1.21c-3.823-4.006-2.417-2.533-6.973-7.308C57.779,8.504,57.632,8.753,57.869,8.354z
	 M22.688,15.736l2.356,1.258v50.536l-2.356,1.258V15.736z M56.562,73.964c4.558-4.777,3.149-3.301,6.973-7.309l2.269,1.21
	l-7.934,8.305C57.618,75.749,57.781,76.023,56.562,73.964z M69.186,68.789l-2.357-1.258V16.995l2.357-1.258V68.789z M68.938,14.985
	l-2.422,1.293l-8.234-8.62C58.672,7,58.379,7.494,59.683,5.293C62.436,8.176,61.204,6.887,68.938,14.985z M55.853,10.227H36.019
	c-0.896-1.511-0.604-1.02-1.259-2.124h22.353C56.29,9.489,56.57,9.017,55.853,10.227z M32.189,5.293
	c1.235,2.085,0.938,1.583,1.401,2.365l-8.234,8.62l-2.422-1.293C32.305,5.172,32.089,5.397,32.189,5.293z M22.934,69.541
	l2.422-1.293l8.234,8.62c-0.39,0.657-0.097,0.163-1.401,2.364C31.935,78.965,33.65,80.762,22.934,69.541z M36.027,74.301
	c0.791,0.031,2.394-0.003,19.825-0.003c0.717,1.208,0.438,0.738,1.26,2.124H34.761C35.75,74.757,35.842,74.614,36.027,74.301z
	 M69.964,15.32l3.598-1.919c0.032,5.902,0.032,51.817,0,57.722l-3.598-1.919V15.32z M57.573,7.324H34.298l-1.352-2.282h25.98
	L57.573,7.324z M34.298,77.202h23.275l1.353,2.281h-25.98L34.298,77.202z M69.702,14.577c-0.078,0.042,0.715,0.828-9.606-9.979
	c1.113-1.873,1.563-2.625,2.119-3.551l11.091,11.607L69.702,14.577z M61.454,0.786c-2.141,3.603-2.046,3.444-2.066,3.477H32.483
	l-2.075-3.494C36.351,0.758,28.564,0.785,61.454,0.786z M29.663,1.041l2.112,3.557L22.22,14.604c-0.394-0.21-3.311-1.767-3.654-1.95
	L29.663,1.041z M22.22,69.921l9.555,10.007c-0.945,1.59-1.463,2.457-2.118,3.55L18.566,71.872
	C18.91,71.688,21.826,70.131,22.22,69.921z M30.418,83.739c2.819-4.745,1.271-2.14,2.065-3.477h26.904l2.076,3.494
	C55.654,83.774,68.358,83.741,30.418,83.739z M62.209,83.484l-2.113-3.556l9.556-10.007c0.088,0.047,3.592,1.916,3.654,1.95
	L62.209,83.484z"
      />
      <path
        display="none"
        fill={props.color}
        stroke={props.color}
        strokeWidth={props.stroke_width}
        d="M78.9,7.86c-0.001-0.044,0.003-0.058-0.023-0.129c-0.093-0.261-7.818-7.819-7.575-7.627
	C71.114-0.045,73.53,0.01,22.268,0.01c-0.276,0,0.023-0.198-7.739,7.564c0,0.001-0.022,0.028-0.022,0.029
	C14.485,7.63,14.46,7.664,14.44,7.709c-0.075,0.178-0.018,68.896,0,68.938c0.042,0.1,7.504,7.602,7.672,7.668
	c0.133,0.052,48.966,0.051,49.099-0.008c0.129-0.057,7.598-7.535,7.65-7.649C78.937,76.497,78.902,7.902,78.9,7.86z M23.101,83.248
	l7.607-14.454l14.839,8.034L23.101,83.248z M45.547,7.53l-14.839,8.034L23.101,1.109L45.547,7.53z M71.511,40.557l-8.22-24.213
	l14.604-7.686C76.026,17.991,73.44,30.914,71.511,40.557z M30.112,65.666l-7.974-23.487l7.974-23.487V65.666z M30.955,16.56h31.398
	v51.237H30.955V16.56z M63.197,18.691l7.974,23.487l-7.974,23.487V18.691z M61.111,15.716H32.198l14.459-7.829
	C49.628,9.497,54.536,12.155,61.111,15.716z M30.019,16.344l-8.22,24.213c-1.926-9.622-4.504-22.504-6.383-31.899L30.019,16.344z
	 M15.25,12.129l6.014,30.049L15.25,72.229V12.129z M21.798,43.8l8.22,24.213l-14.604,7.686C17.291,66.326,19.838,53.595,21.798,43.8
	z M46.657,76.468l-14.459-7.827h28.913C55.692,71.576,47.97,75.758,46.657,76.468z M63.291,68.013l8.22-24.213
	c1.961,9.795,4.508,22.525,6.384,31.898L63.291,68.013z M78.06,72.229l-6.013-30.051l6.013-30.049V72.229z M62.601,15.564
	L47.767,7.53l22.442-6.42C69.626,2.216,64.54,11.88,62.601,15.564z M70.209,83.248l-22.442-6.42l14.834-8.034
	C65.071,73.486,67.989,79.031,70.209,83.248z M63.781,15.132c3.687-7.005,2.377-4.517,7.366-13.998l6.631,6.631L63.781,15.132z
	 M46.657,6.97L25.276,0.854h42.758C54.495,4.728,60.475,3.017,46.657,6.97z M22.161,1.135c4.823,9.163,3.514,6.677,7.367,13.998
	L15.531,7.766L22.161,1.135z M29.528,69.225c-4.776,9.074-2.779,5.28-7.367,13.997l-6.63-6.631L29.528,69.225z M25.276,83.502
	l21.381-6.115c13.817,3.953,7.833,2.241,21.377,6.115H25.276z M71.147,83.222c-4.607-8.755-2.611-4.963-7.366-13.997l13.997,7.366
	L71.147,83.222z"
      />
      {props.show_diagram && (
        <>
          <line
            x1={x(0.491)}
            y1={y(-0.01)}
            x2={x(0.491)}
            y2={y(0.994)}
            stroke={lineColor}
            strokeWidth=".5"
          ></line>
          <line
            x1={x(0.471)}
            y1={y(-0.01)}
            x2={x(0.511)}
            y2={y(-0.01)}
            stroke={lineColor}
            strokeWidth=".5"
          ></line>
          <line
            x1={x(0.471)}
            y1={y(0.994)}
            x2={x(0.511)}
            y2={y(0.994)}
            stroke={lineColor}
            strokeWidth=".5"
          ></line>
          <rect
            x={lengthX}
            y={lengthY}
            width="20"
            height="11"
            rx="0.8"
            fill="white"
          ></rect>
          <text
            x={lengthX + 2}
            y={lengthY + 4}
            fontSize=".4rem"
            stroke={lineColor}
            strokeWidth="0.01"
          >
            Length:
          </text>
          <text
            x={lengthX + 1}
            y={lengthY + 10}
            fontSize=".4rem"
            stroke={lineColor}
            strokeWidth="0.01"
          >
            {props.diamond.Measurement1}
          </text>

          <line
            x1={x(0.26)}
            y1={y(0.5)}
            x2={x(0.72)}
            y2={y(0.5)}
            stroke={lineColor}
            strokeWidth=".5"
          ></line>
          <line
            x1={x(0.26)}
            y1={y(0.48)}
            x2={x(0.26)}
            y2={y(0.52)}
            stroke={lineColor}
            strokeWidth=".5"
          ></line>
          <line
            x1={x(0.72)}
            y1={y(0.48)}
            x2={x(0.72)}
            y2={y(0.52)}
            stroke={lineColor}
            strokeWidth=".5"
          ></line>

          <rect
            x={widthX}
            y={widthY}
            width="19"
            height="11"
            rx="0.8"
            fill="white"
          ></rect>
          <text
            x={widthX + 2}
            y={widthY + 4}
            fontSize=".4rem"
            stroke={lineColor}
            strokeWidth="0.01"
          >
            Width:
          </text>
          <text
            x={widthX + 1}
            y={widthY + 10}
            fontSize=".4rem"
            stroke={lineColor}
            strokeWidth="0.01"
          >
            {props.diamond.Measurement2}
          </text>
        </>
      )}
    </svg>
  );
};

export default Marquise;
