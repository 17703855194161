// Third-party libraries
import React from "react";
import { Link as DomLink } from "react-router-dom";
import { Box, Card, CardMedia, Typography } from "@mui/material";
// Local modules
import Calculators from "../../../assets/images/footerIcons/icon-Calculators.svg";
import CatalogsBrochures from "../../../assets/images/footerIcons/icon-Catalogs-Brochures.svg";
import Certifications from "../../../assets/images/footerIcons/icon-certifications.svg";
import CreateAccount from "../../../assets/images/footerIcons/icon-create-account-lg.svg";
import Faq from "../../../assets/images/footerIcons/icon-faq-question.svg";
import FinishOptions from "../../../assets/images/footerIcons/icon-finish-options.svg";
import Forms from "../../../assets/images/footerIcons/icon-Forms.svg";
import FreeShipping from "../../../assets/images/footerIcons/icon-FreeShipping.svg";
import HarmonyFairmined from "../../../assets/images/footerIcons/icon-harmony-fairmined2.svg";
import LaserEngraving from "../../../assets/images/footerIcons/icon-laser-engraving.svg";
import MilgrainOptions from "../../../assets/images/footerIcons/icon-milgrain-options.svg";
import PreparingShipment from "../../../assets/images/footerIcons/icon-PreparingShipment.svg";
import Settlement from "../../../assets/images/footerIcons/icon-settlement.svg";
import StonePolicy from "../../../assets/images/footerIcons/icon-stone-policy.svg";
import SweepsBox from "../../../assets/images/footerIcons/icon-SweepsBox.svg";
import TechnicalArticles from "../../../assets/images/footerIcons/icon-TechnicalArticles.svg";
import WebsiteHelp from "../../../assets/images/footerIcons/icon-website-help.svg";
import AnalyticalServices from "../../../assets/images/footerIcons/icon-analytical-services.gif";

export default function LandingFooter(props) {
  const icons = props.icons;
  function iconImage(type) {
    switch (type) {
      case "analyticalservices":
        return AnalyticalServices;
      case "refiningcalculator":
        return Calculators;
      case "castingcalculator":
        return Calculators;
      case "catalogsbrochures":
        return CatalogsBrochures;
      case "certifications":
        return Certifications;
      case "createaccount":
        return CreateAccount;
      case "faq":
        return Faq;
      case "finishoptions":
        return FinishOptions;
      case "forms":
        return Forms;
      case "freeshipping":
        return FreeShipping;
      case "harmony":
        return HarmonyFairmined;
      case "laserengraving":
        return LaserEngraving;
      case "milgrainoptions":
        return MilgrainOptions;
      case "preparingshipment":
        return PreparingShipment;
      case "settlement":
        return Settlement;
      case "stonepolicy":
        return StonePolicy;
      case "sweepsbox":
        return SweepsBox;
      case "technicalarticles":
        return TechnicalArticles;
      case "websitehelp":
        return WebsiteHelp;
      default:
        break;
    }
  }

  function iconText(type) {
    switch (type) {
      case "analyticalservices":
        return "Analytical Services";
      case "refiningcalculator":
        return "Calculator";
      case "castingcalculator":
        return "Calculator";
      case "catalogsbrochures":
        return "Catalogs and Brochures";
      case "certifications":
        return "Certifications";
      case "createaccount":
        return "Create an Account";
      case "faq":
        return "Frequently Asked Questions";
      case "finishoptions":
        return "Finish Options";
      case "forms":
        return "Forms";
      case "freeshipping":
        return "Free Shipping Label";
      case "harmony":
        return "Harmony and Fairmined";
      case "laserengraving":
        return "Laser Engraving";
      case "milgrainoptions":
        return "Milgrain Options";
      case "preparingshipment":
        return "Preparing Your Shipment";
      case "settlement":
        return "Settlement";
      case "stonepolicy":
        return "Stone Policy";
      case "sweepsbox":
        return "Sweeps Box";
      case "technicalarticles":
        return "Technical Articles";
      case "websitehelp":
        return "Website Help";
      default:
        break;
    }
  }

  function iconURL(type) {
    switch (type) {
      case "analyticalservices":
        return "/sub-landing/Analytical-Services";
      case "refiningcalculator":
        return "/refining-calculator";
      case "castingcalculator":
        return "/casting-calculator";
      case "catalogsbrochures":
        return "/links/Catalogs-And-Brochures";
      case "certifications":
        return "/sub-landing/Responsibility-Statements-And-Policies";
      case "createaccount":
        return "/create-account";
      case "faq":
        return "/faq/Frequently-Asked-Questions";
      case "finishoptions":
        return "/sub-landing/Finishing-And-Milgrain-Options";
      case "forms":
        return "/links/Forms";
      case "freeshipping":
        return "/refining-shipping-form";
      case "harmony":
        return "/landing/Harmony";
      case "laserengraving":
        return "/links/Laser-Engraving";
      case "milgrainoptions":
        return "/sub-landing/Finishing-And-Milgrain-Options";
      case "preparingshipment":
        return "/sub-landing/Preparing-Your-Shipment";
      case "settlement":
        return "/sub-landing/Refining-Settlement-Options";
      case "stonepolicy":
        return "/links/Stone-Policy-And-Order-Information";
      case "sweepsbox":
        return "/sub-landing/Free-Sweeps-Box";
      case "technicalarticles":
        return "/technicalarticles";
      case "websitehelp":
        return "/faq/Website-Help";
      default:
        break;
    }
  }

  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: {
          sm: "repeat(4, 1fr)",
          md: "repeat(4, 1fr)",
          lg: "repeat(4, 1fr)",
          xl: "repeat(4, 1fr)",
        },
        width: "100%",
      }}
    >
      {icons.map((icon) => (
        <DomLink
          to={{
            pathname: iconURL(icon),
          }}
          key={icon}
        >
          <Card
            className="pointer"
            align="center"
            sx={{ marginBottom: "2rem" }}
          >
            <CardMedia
              component="img"
              image={iconImage(icon)}
              sx={{ width: "15rem" }}
            />
            <Typography
              variant="h6"
              align="center"
              className="center"
              sx={{ textTransform: "uppercase" }}
            >
              {iconText(icon)}
            </Typography>
          </Card>
        </DomLink>
      ))}
    </Box>
  );
}
