import React from "react";

const CushionProfile = (props) => {
  const viewBoxWidth = 85.7;
  const viewBoxHeight = 86.1;

  const lineColor = "#4a4a4a";
  const strokeWidth2 = "0.5";

  const x = (percentX) => percentX * viewBoxWidth;
  const y = (percentY) => percentY * viewBoxHeight;

  const table = Number(props.diamond.Table).toFixed(0);
  const depthPercent = Number(props.diamond.Depth).toFixed(1);
  const girdle = props.diamond.Girdle || "";
  const girdleSpaceIndex = girdle.indexOf(" ");
  const girdle1 =
    girdleSpaceIndex > 0 ? girdle.slice(0, girdleSpaceIndex) : girdle;
  const girdle2 = girdleSpaceIndex > 0 ? girdle.slice(girdleSpaceIndex) : "";

  const diagramStrokeWidth = "0.01";
  const diagramFontSize = 0.046;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox={"0 0 " + viewBoxWidth + " " + viewBoxHeight}
      width={props.width}
      height={props.height}
      preserveAspectRatio={props.preserveAspectRatio}
    >
      <line
        fill="none"
        stroke={props.color}
        strokeWidth={props.stroke_width}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        x1="43.1"
        y1="18.7"
        x2="43"
        y2="71.3"
      />
      <g>
        <polyline
          fill="none"
          stroke={props.color}
          strokeWidth={props.stroke_width}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          points="43.1,29.8 69.1,31.8 84.2,31.5 68.5,18.4 43.1,18.4 				"
        />
        <polyline
          fill="none"
          stroke={props.color}
          strokeWidth={props.stroke_width}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          points="43.1,34.8 52.4,34.2 68.9,33.1 84.1,33.2 				"
        />
        <polyline
          fill="none"
          stroke={props.color}
          strokeWidth={props.stroke_width}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          points="43.1,18.4 54.1,25.5 60.1,18.6 69.9,25.3 68.6,18.5 				"
        />
        <polyline
          fill="none"
          stroke={props.color}
          strokeWidth={props.stroke_width}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          points="52.3,34.6 45,60.6 68.8,33.4 54.9,60.4 78.9,33.4 				"
        />
        <path
          fill="none"
          stroke={props.color}
          strokeWidth={props.stroke_width}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="M45,60.6l-1.8,10.7c0,0,40.6-38,40.9-38.2"
        />
        <polyline
          fill="none"
          stroke={props.color}
          strokeWidth={props.stroke_width}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          points="49.9,30.3 54.1,25.5 68.6,31.6 				"
        />
        <polyline
          fill="none"
          stroke={props.color}
          strokeWidth={props.stroke_width}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          points="78.8,31.6 69.9,25.3 69.1,31.8 				"
        />
        <line
          fill="none"
          stroke={props.color}
          strokeWidth={props.stroke_width}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          x1="84.2"
          y1="31.5"
          x2="84.1"
          y2="33.2"
        />
      </g>
      <g>
        <polyline
          fill="none"
          stroke={props.color}
          strokeWidth={props.stroke_width}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          points="43,29.8 17,31.8 1.9,31.5 17.7,18.4 43,18.4 				"
        />
        <polyline
          fill="none"
          stroke={props.color}
          strokeWidth={props.stroke_width}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          points="43,34.8 33.7,34.2 17.3,33.1 2,33.2 				"
        />
        <polyline
          fill="none"
          stroke={props.color}
          strokeWidth={props.stroke_width}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          points="43,18.4 32,25.5 26,18.6 16.2,25.3 17.6,18.5 				"
        />
        <polyline
          fill="none"
          stroke={props.color}
          strokeWidth={props.stroke_width}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          points="33.8,34.6 41.1,60.6 17.4,33.4 31.3,60.3 7.2,33.4 				"
        />
        <path
          fill="none"
          stroke={props.color}
          strokeWidth={props.stroke_width}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="M41.1,60.6l1.8,10.7c0,0-40.6-38-40.9-38.2"
        />
        <polyline
          fill="none"
          stroke={props.color}
          strokeWidth={props.stroke_width}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          points="36.3,30.3 32,25.5 17.6,31.6 				"
        />
        <polyline
          fill="none"
          stroke={props.color}
          strokeWidth={props.stroke_width}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          points="7.3,31.6 16.2,25.3 17,31.8 				"
        />
        <line
          fill="none"
          stroke={props.color}
          strokeWidth={props.stroke_width}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          x1="1.9"
          y1="31.5"
          x2="2"
          y2="33.2"
        />
      </g>

      {props.show_diagram && (
        <>
          <line
            x1={x(0.19)}
            x2={x(0.815)}
            y1={y(0.18)}
            y2={y(0.18)}
            stroke={lineColor}
            strokeWidth={strokeWidth2}
          ></line>
          <line
            x1={x(0.19)}
            x2={x(0.19)}
            y1={y(0.16)}
            y2={y(0.2)}
            stroke={lineColor}
            strokeWidth={strokeWidth2}
          ></line>
          <line
            x1={x(0.815)}
            x2={x(0.815)}
            y1={y(0.16)}
            y2={y(0.2)}
            stroke={lineColor}
            strokeWidth={strokeWidth2}
          ></line>
          <line
            x1={x(0.06)}
            y1={y(0.21)}
            x2={x(0.06)}
            y2={y(0.835)}
            stroke={lineColor}
            strokeWidth={strokeWidth2}
          ></line>
          <line
            x1={x(0.04)}
            y1={y(0.21)}
            x2={x(0.08)}
            y2={y(0.21)}
            stroke={lineColor}
            strokeWidth={strokeWidth2}
          ></line>
          <line
            x1={x(0.04)}
            y1={y(0.835)}
            x2={x(0.08)}
            y2={y(0.835)}
            stroke={lineColor}
            strokeWidth={strokeWidth2}
          ></line>
          <line
            x1={x(0.94)}
            y1={y(0.21)}
            x2={x(0.94)}
            y2={y(0.835)}
            stroke={lineColor}
            strokeWidth={strokeWidth2}
          ></line>
          <line
            x1={x(0.92)}
            y1={y(0.21)}
            x2={x(0.96)}
            y2={y(0.21)}
            stroke={lineColor}
            strokeWidth={strokeWidth2}
          ></line>
          <line
            x1={x(0.92)}
            y1={y(0.835)}
            x2={x(0.96)}
            y2={y(0.835)}
            stroke={lineColor}
            strokeWidth={strokeWidth2}
          ></line>
          <circle
            cx={x(0.5)}
            cy={y(0.82)}
            r={y(0.03)}
            fillOpacity="0"
            stroke={lineColor}
            strokeWidth={strokeWidth2}
          />
          <line
            x1={x(0.5)}
            y1={y(0.85)}
            x2={x(0.502)}
            y2={y(0.91)}
            stroke={lineColor}
            strokeWidth={strokeWidth2}
          ></line>
          <line
            x1={x(0.502)}
            y1={y(0.91)}
            x2={x(0.54)}
            y2={y(0.91)}
            stroke={lineColor}
            strokeWidth={strokeWidth2}
          ></line>

          {props.diamond.girdle && (
            <>
              <circle
                cx={x(0.4)}
                cy={y(0.38)}
                r={y(0.03)}
                fillOpacity="0"
                stroke={lineColor}
                strokeWidth={strokeWidth2}
              />
              <line
                x1={x(0.4)}
                y1={y(0.41)}
                x2={x(0.4)}
                y2={y(0.91)}
                stroke={lineColor}
                strokeWidth={strokeWidth2}
              ></line>
              <line
                x1={x(0.4)}
                y1={y(0.91)}
                x2={x(0.38)}
                y2={y(0.91)}
                stroke={lineColor}
                strokeWidth={strokeWidth2}
              ></line>
              <text
                x={x(0.0)}
                y={y(0.9)}
                fontSize={y(diagramFontSize)}
                stroke={lineColor}
                strokeWidth={diagramStrokeWidth}
              >
                Girdle: {girdle1}
              </text>
              <text
                x={x(0.0)}
                y={y(0.96)}
                fontSize={y(diagramFontSize)}
                stroke={lineColor}
                strokeWidth={diagramStrokeWidth}
              >
                {girdle2}
              </text>
            </>
          )}

          <rect
            x={x(0.005)}
            y={y(0.6)}
            width={x(0.22)}
            height={y(0.135)}
            rx="0.8"
            fill="white"
          ></rect>
          <text
            x={x(0.03)}
            y={y(0.645)}
            fontSize={y(diagramFontSize)}
            stroke={lineColor}
            strokeWidth={diagramStrokeWidth}
          >
            Depth:
          </text>
          <text
            x={x(0.02)}
            y={y(0.7)}
            fontSize={y(diagramFontSize)}
            stroke={lineColor}
            strokeWidth={diagramStrokeWidth}
          >
            {props.diamond.Measurement3}
          </text>

          <rect
            x={x(0.8)}
            y={y(0.6)}
            width={x(0.19)}
            height={y(0.135)}
            rx="0.8"
            fill="white"
          ></rect>
          <text
            x={x(0.81)}
            y={y(0.645)}
            fontSize={y(diagramFontSize)}
            stroke={lineColor}
            strokeWidth={diagramStrokeWidth}
          >
            Depth:
          </text>
          <text
            x={x(0.81)}
            y={y(0.7)}
            fontSize={y(diagramFontSize)}
            stroke={lineColor}
            strokeWidth={diagramStrokeWidth}
          >
            {depthPercent}%
          </text>
          <text
            x={x(0.37)}
            y={y(0.16)}
            fontSize={y(diagramFontSize)}
            stroke={lineColor}
            strokeWidth={diagramStrokeWidth}
          >
            Table: {table}%
          </text>
          <text
            x={x(0.55)}
            y={y(0.92)}
            fontSize={y(diagramFontSize)}
            stroke={lineColor}
            strokeWidth={diagramStrokeWidth}
          >
            Culet: {props.diamond.Culet}
          </text>
        </>
      )}
    </svg>
  );
};

export default CushionProfile;
