const getProductCodeStrapiId = (productCode) => {
  const productCodeToStrapiId = {
    GR: 1,
    SD: 2,
    SR: 47,
    SDC: 48,
    SDB: 2,
    SDW: 46,
    FS: 4,
    ALL: 5,
    DSK: 37,
    AE: 38,
    BUL: 36,
    ML: 39,
    FLX: 40,
    SRB: 42,
    CIR: 43,
    CIRCLES: 43,
    WRB: 49,
    WAS: 45,
    RW: 3,
    SRW: 6,
    SWF: 7,
    SWH: 8,
    SHR: 9,
    LDW: 10,
    SWS: 11,
    SWO: 12,
    SWE: 13,
    STW: 14,
    DHR: 15,
    SWT: 16,
    SWP: 17,
    CHW: 18,
    BZS: 19,
    BZW: 20,
    BSC: 21,
    BSR: 22,
    CZY: 23,
    WSQ: 24,
    LW: 25,
    SWD: 26,
    SWR: 27,
    TBL: 28,
    TBM: 29,
    TBH: 30,
    TBX: 31,
    TSL: 32,
    TSM: 33,
    TSH: 34,
    TSX: 35,
  };

  return productCodeToStrapiId[productCode] || 1;
};

export default getProductCodeStrapiId;
