// Third-party libraries
import React, { useState } from "react";
import toast from "react-hot-toast";
import {
  Container,
  Card,
  CardHeader,
  CardContent,
  Button,
  Typography,
  TextField,
  Stack,
  Divider,
} from "@mui/material";

// Local modules
import { api } from "../../utils";

export default function ForgotPassword(props) {
  const [email, setEmail] = useState();
  const [submitted, setSubmitted] = useState(false);

  function forgotPassword() {
    api.post("account/forgotPassword", { username: email }).then((response) => {
      if (response.isAxiosError) {
        const errorMessage = [];
        response.data.errors.forEach(function (error) {
          errorMessage.push(error);
        });
        toast.error(
          `There was an error processing your request. ${errorMessage.toString()}`
        );
      } else {
        setSubmitted(true);
      }
    });
  }

  return (
    <Container maxWidth="sm">
      <Card>
        <CardHeader
          title={
            <Typography variant="articleTitle">Forgot Password?</Typography>
          }
        />
        {!submitted ? (
          <>
            <CardContent>
              <Stack spacing={3}>
                <Typography variant="articleText">
                  Please enter the email address associated with your account to
                  be sent a reset password link.
                </Typography>
                <Divider />
                <TextField
                  required
                  color="primary"
                  type="email"
                  onChange={(e) => setEmail(e.target.value)}
                  label={
                    <Typography variant="navText">Account Email</Typography>
                  }
                />
                <Button
                  size="small"
                  color="success"
                  variant="contained"
                  onClick={() => forgotPassword()}
                >
                  Get Link
                </Button>
              </Stack>
            </CardContent>
          </>
        ) : (
          <>
            <CardContent>
              <Stack spacing={2}>
                <Typography variant="articleText">
                  If the email you submitted is in our system, you will receive
                  a reset password link sent to the email address in the next
                  few minutes. The link expires after 24 hours.
                </Typography>
                <Divider flexItem />
                <Typography variant="articleText">
                  If you have not recieved an email from our system, please
                  check your SPAM folder before using "Forgot Password" again.
                </Typography>
                <Typography variant="articleText">- OR -</Typography>
                <Typography variant="articleText">
                  Please email info@hooverandstrong.com with the email address
                  associated with your account to reset your password, or call
                  Information toll-free: 1-800-759-9997 or 804-794-3700.
                </Typography>
              </Stack>
            </CardContent>
          </>
        )}
      </Card>
    </Container>
  );
}
