import { Stack, Skeleton, Grid } from "@mui/material";
import { Mobile, Default } from "../../../utils/deviceHelpers";
function LoadingIndicator(props) {
  const page = props.page;
  return (
    <>
      {page === "Article" ? (
        <div id="ghost-element">
          <Mobile>
            <Grid container>
              <Grid item xs={12}>
                <Stack spacing={1}>
                  <div>
                    <Skeleton
                      variant="rectangular"
                      width={"100%"}
                      height={250}
                    />
                    <Skeleton variant="text" height={50} />
                    <Skeleton variant="text" height={50} />
                  </div>
                  <div>
                    <Skeleton
                      variant="rectangular"
                      width={"100%"}
                      height={350}
                    />
                    <Skeleton variant="text" height={50} />
                    <Skeleton variant="text" height={50} />
                  </div>
                  <div>
                    <Skeleton
                      variant="rectangular"
                      width={"100%"}
                      height={350}
                    />
                    <Skeleton variant="text" height={50} />
                    <Skeleton variant="text" height={50} />
                  </div>
                </Stack>
              </Grid>
            </Grid>
          </Mobile>
          <Default>
            <Grid container>
              <Grid item xs={12}>
                <Stack spacing={1}>
                  <div>
                    <Skeleton
                      variant="rectangular"
                      width={"100%"}
                      height={175}
                    />
                  </div>
                  <div>
                    <Skeleton
                      variant="rectangular"
                      width={"100%"}
                      height={175}
                    />
                  </div>
                  <div>
                    <Skeleton
                      variant="rectangular"
                      width={"100%"}
                      height={175}
                    />
                  </div>
                  <div>
                    <Skeleton
                      variant="rectangular"
                      width={"100%"}
                      height={175}
                    />
                  </div>
                  <div>
                    <Skeleton
                      variant="rectangular"
                      width={"100%"}
                      height={175}
                    />
                  </div>
                </Stack>
              </Grid>
            </Grid>
          </Default>
        </div>
      ) : (
        <div id="ghost-element">
          <Default>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <Skeleton variant="rectangular" height={200} />
                <Skeleton variant="text" />
                <Skeleton variant="text" />
                <Skeleton variant="text" />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <Skeleton variant="rectangular" height={200} />
                <Skeleton variant="text" />
                <Skeleton variant="text" />
                <Skeleton variant="text" />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <Skeleton variant="rectangular" height={200} />
                <Skeleton variant="text" />
                <Skeleton variant="text" />
                <Skeleton variant="text" />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <Skeleton variant="rectangular" height={200} />
                <Skeleton variant="text" />
                <Skeleton variant="text" />
                <Skeleton variant="text" />
              </Grid>
            </Grid>
          </Default>
        </div>
      )}
    </>
  );
}

export default LoadingIndicator;
