// Third-party libraries
import React, { useState, useEffect } from "react";
import { Slider, Typography } from "@mui/material/";

// Local modules
import { formatNumber, strip } from "../../../../utils/formatHelpers";

export default function Range({ filter, resetFilters, updateRange, color }) {
  const format = (n) => formatNumber(n, filter.format);

  const rangeStepParameter = filter.name === "HooverPrice" ? 100 : 0.1;

  const [initialValueChanged, setInitialValueChanged] = useState(false);

  let floorCeilInterval = 1;
  switch (filter.name) {
    case "HooverPrice":
      floorCeilInterval = 100;
      break;
    case "Carat":
    case "LWRatio":
      floorCeilInterval = 0.25;
      break;
    case "Table":
    case "Depth":
      floorCeilInterval = 0.5;
      break;
    default:
      break;
  }

  const rangeMin =
    Math.floor(filter.range.min / floorCeilInterval) * floorCeilInterval;
  const rangeMax =
    Math.ceil(filter.range.max / floorCeilInterval) * floorCeilInterval;

  //Filter min max...
  const [minFilterNum, setMinFilterNum] = useState(rangeMin);
  const [maxFilterNum, setMaxFilterNum] = useState(rangeMax);

  //Slider min max...
  const [minSliderNum, setMinSliderNum] = useState(rangeMin);
  const [maxSliderNum, setMaxSliderNum] = useState(rangeMax);

  //Text box string values...
  const [boxMinNum, setMinBoxNum] = useState(format(rangeMin));
  const [boxMaxNum, setMaxBoxNum] = useState(format(rangeMax));

  const setBoxMin = (e) => {
    setMinBoxNum(e.target.value);
  };
  const setBoxMax = (e) => {
    setMaxBoxNum(e.target.value);
  };

  const handleSliderMovement = (e, data) => {
    setMinSliderNum(data[0]);
    setMaxSliderNum(data[1]);
    setMinBoxNum(format(data[0]));
    setMaxBoxNum(format(data[1]));
  };

  const handleTextBoxBlur = () => {
    setMinSliderNum(strip(boxMinNum));
    setMaxSliderNum(strip(boxMaxNum));
    setMinBoxNum(format(boxMinNum));
    setMaxBoxNum(format(boxMaxNum));
    setMinFilterNum(strip(boxMinNum));
    setMaxFilterNum(strip(boxMaxNum));
  };

  const handleSliderBlur = () => {
    setMinFilterNum(minSliderNum);
    setMaxFilterNum(maxSliderNum);
  };

  useEffect(() => {
    if (rangeMin !== minFilterNum || rangeMax !== maxFilterNum)
      setInitialValueChanged(true);
    if (
      rangeMin !== minFilterNum ||
      rangeMax !== maxFilterNum ||
      initialValueChanged
    ) {
      updateRange(filter.name, { min: minFilterNum, max: maxFilterNum });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [minFilterNum, maxFilterNum]);

  //Reset
  useEffect(() => {
    setMinFilterNum(rangeMin);
    setMaxFilterNum(rangeMax);
    setMinBoxNum(format(rangeMin));
    setMaxBoxNum(format(rangeMax));
    setMinSliderNum(rangeMin);
    setMaxSliderNum(rangeMax);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetFilters]);

  return (
    <>
      <Slider
        value={[Number(minSliderNum), Number(maxSliderNum)]}
        onChange={handleSliderMovement}
        onChangeCommitted={handleSliderBlur}
        valueLabelDisplay="off"
        min={rangeMin}
        max={rangeMax}
        step={rangeStepParameter}
        color={color}
      />
      <Typography variant="body3">
        <input
          name="minNumber"
          type="text"
          className="input-text-field-left"
          value={boxMinNum}
          onChange={setBoxMin}
          onBlur={handleTextBoxBlur}
          onKeyPress={(e) => {
            if (e.key === "Enter") handleTextBoxBlur();
          }}
        />
      </Typography>
      <Typography variant="body3" className="float-right">
        <input
          name="maxNumber"
          type="text"
          className="input-text-field-right"
          value={boxMaxNum}
          onChange={setBoxMax}
          onBlur={handleTextBoxBlur}
          onKeyPress={(e) => {
            if (e.key === "Enter") handleTextBoxBlur();
          }}
        />
      </Typography>
    </>
  );
}
