// Third-party libraries
import React from "react";
import { Link as DomLink } from "react-router-dom";
import Stack from "@mui/material/Stack";
import HelpIcon from "@mui/icons-material/Help";

// Local modules
import StringConstants from "../../../utils/constants";
import {
  USFlagIcon,
  ASIcon,
  SCSIcon,
  ConflictFreeIcon,
  CanadaIcon,
  RecycleIcon,
} from "../../Icons";

export default function DiamondIconStrip(props) {
  const [diamond, show_text] = [props.diamond, props.show_text];
  const size = StringConstants.SIZE_MEDIUM;

  return (
    <Stack
      direction="row"
      spacing={1}
      justifyContent="center"
      alignItems="center"
      sx={{ paddingLeft: "0.5rem" }}
    >
      {(diamond.DiamondSource === "Harmony US Lab Grown" ||
        diamond.USGrown === "Y") && (
        <USFlagIcon size={size} show_text={show_text} />
      )}
      {diamond.AsGrown === "Y" && <ASIcon size={size} show_text={show_text} />}
      {diamond.ScsGrower === "Y" && (
        <SCSIcon size={size} show_text={show_text} />
      )}
      {diamond.DiamondSource ===
        StringConstants.DIAMOND_SOURCE_CONFLICT_FREE && (
        <ConflictFreeIcon size={size} show_text={show_text} />
      )}
      {diamond.DiamondSource ===
        StringConstants.DIAMOND_SOURCE_CONFLICT_FREE_DISTINCT && (
        <ConflictFreeIcon size={size} show_text={show_text} />
      )}
      {diamond.DiamondSource === StringConstants.DIAMOND_SOURCE_CANADIAN && (
        <CanadaIcon size={size} show_text={show_text} />
      )}
      {diamond.DiamondSource === StringConstants.DIAMOND_SOURCE_RECYCLED && (
        <RecycleIcon size={size} show_text={show_text} />
      )}
      <DomLink
        to={{ pathname: "/sub-landing/Diamond-Sources" }}
        target="_blank"
        rel="noreferrer"
      >
        <HelpIcon className="help-icon question-mark" />
      </DomLink>
    </Stack>
  );
}
