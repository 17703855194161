const Keywords = [
  "Alloys",
  "Casting",
  "Consumer",
  "Diamonds and Gems",
  "Earrings",
  "Eco Friendly Jewelry",
  "Findings",
  "Jewelry Making",
  "Metals",
  "Refining",
  "Repair and Soldering",
  "Ring Solutions",
  "Stone Setting",
  "Textures and Finishing",
];

export default Keywords;
