// Built-in modules
import moment from "moment";

// Third-party libraries
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";
import remarkGfm from "remark-gfm";
import { Container, Typography, Stack } from "@mui/material";

// Local modules
import { constants } from "../../../utils";
import api from "../../../utils/api";
import PrintArticle from "./PrintArticle";

export default function SingleArticle(props) {
  const [article, setArticle] = useState();
  const { ArticleId } = useParams();

  useEffect(() => {
    window.scrollTo(0, 0);
    api
      .fetchStrapi(`/technical-articles/${ArticleId}?populate=*`)
      .then((response) => {
        setArticle(response.data.data);
      });
  }, [ArticleId]);

  return (
    <Container maxWidth="md" className="careers-text">
      <Stack spacing={1}>
        {article && article.attributes && (
          <>
            <Typography
              variant="articleTitle"
              align="center"
              sx={{ marginBottom: "2rem" }}
            >
              {article.attributes.title}
            </Typography>
            {article.attributes.articlebanner.data != null && (
              <img
                src={`${constants.CMS_FRONT_IMAGE_URL}${article.attributes.articlebanner.data.attributes.url}`}
                alt="article banner"
              />
            )}
            <Typography variant="articleSubTitle" align="center">
              {`${article.attributes.author} | ${moment(
                article.attributes.date
              ).format("MMM Do YYYY")}`}
            </Typography>
            <div>
              <PrintArticle className="float-right" article={article} />
            </div>
            <Typography variant="h5" align="center">
              {article.attributes.subtitle}
            </Typography>
            <Typography variant="articleText" sx={{ marginTop: "2rem" }}>
              <ReactMarkdown
                children={article.attributes.article}
                remarkPlugins={[remarkGfm]}
              />
            </Typography>
          </>
        )}
      </Stack>
    </Container>
  );
}
