// Third-party libraries
import React from "react";
import { Link as DomLink } from "react-router-dom";
import HomeSharpIcon from "@mui/icons-material/HomeSharp";
import Breadcrumbs from "@mui/material/Breadcrumbs";
// Local modules
import logo from "../../../assets/images/HS-LogoOnly.png";
import useMediaQueryUtils from "../../../utils/mediaQueryUtils";

export default function CareersBanner(props) {
  const isMdScreen = useMediaQueryUtils("md");
  return (
    <div className="careers-banner">
      <img src={logo} alt="Hoover and Strong - The Gold Standard" />
      {!isMdScreen ? (
        <span className="careers-hiring">
          WE ARE HIRING. <strong>APPLY NOW!</strong>
        </span>
      ) : null}

      <Breadcrumbs className="careers-breadcrumbs">
        <DomLink
          to={{
            pathname: "/",
          }}
        >
          <HomeSharpIcon
            sx={{ mr: 0.5, verticalAlign: "middle", paddingBottom: "1px" }}
            fontSize="inherit"
          />
          HOME
        </DomLink>
        <span>
          <strong>CAREERS</strong>
        </span>
      </Breadcrumbs>
    </div>
  );
}
