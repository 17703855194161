// Third-party libraries
import React from "react";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  Grid,
} from "@mui/material";

// Local modules
import FillerImage from "../../../assets/images/Filler-Harmony-8-1-22.gif";

export default function ProductSeries(props) {
  const tableData = props.data;

  return (
    <>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12}>
          <Typography variant="h2" sx={{ marginTop: "2rem" }}>
            Standard Product Series
          </Typography>
        </Grid>
        {tableData ? (
          <>
            <Grid item xs={12} md={6}>
              <Table size="small" padding="none">
                <TableHead>
                  <TableRow>
                    {tableData &&
                      Object.values(tableData.header).map((header, i) => (
                        <TableCell key={i++}>
                          <Typography>{header}</Typography>
                        </TableCell>
                      ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tableData &&
                    tableData.lines.map((header, i) => (
                      <TableRow key={i++}>
                        {Object.values(header).map((seriesData) => (
                          <TableCell key={i++}>
                            <Typography>{seriesData}</Typography>
                          </TableCell>
                        ))}
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </Grid>
            <Grid item xs={12} md={6} justifyContent="center">
              <img
                src={FillerImage}
                alt="filler for small table"
                width={"100%"}
              />
            </Grid>
          </>
        ) : (
          <Grid item xs={12} md={12} justifyContent="center">
            <img
              src={FillerImage}
              alt="filler for small table"
              width={"100%"}
            />
          </Grid>
        )}
      </Grid>
    </>
  );
}
