// Built-in modules
import qs from "qs";

// Third-party libraries
import React, { useContext, useReducer, useEffect, useState } from "react";
import { Link as DomLink } from "react-router-dom";
import toast from "react-hot-toast";
import {
  Backdrop,
  CircularProgress,
  Container,
  Grid,
  Typography,
  Stack,
  Select,
  MenuItem,
  Divider,
} from "@mui/material";

// Local modules
import {
  AppContext,
  ProductsContext,
} from "../../../utils/stateHandlers/contexts";
import states from "../../../utils/stateHandlers/initialStates";
import { productReducer } from "../../../utils/stateHandlers/reducers";
import { storage } from "../../../utils";
import api from "../../../utils/api";
import { functions, constants } from "../../../utils";
import SolderImage from "../../../assets/images/SOLDER_GENERIC_XLG.png";
import DetailTable from "../components/DetailTable";
import Material from "../components/productFields/Material";
import Dimension from "../components/productFields/Dimension";
import Quantity from "../components/productFields/Quantity";
import TreatmentField from "../components/TreatmentField";
import SpecialInstructions from "../components/SpecialInstructions";
import ProductGalleryViewer from "../components/ProductGalleryViewer";
import TechnicalArticles from "../components/TechnicalArticles";
import CustomerTestimonials from "../components/CustomerTestimonials";
import Price from "../components/Price";
import filterAds from "../../../utils/helperFunction";
import useMediaQueryUtils from "../../../utils/mediaQueryUtils";
import StrapiChart from "./StrapiChart";
import MetalColumns from "./MetalColumns";
import RelatedItems from "./RelatedItems";
import RecentlyView from "./RecentlyView";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

export default function Groups(props) {
  const isMdScreen = useMediaQueryUtils("md");
  const { authToken } = useContext(AppContext);
  const [productState, productDispatch] = useReducer(
    productReducer,
    states.initialProductState
  );
  const {
    pageLoaded,
    icons,
    productFields,
    productDetails,
    productTreatments,
    images,
    productInfo,
    relatedProducts,
    metalColumns,
    strapiChart,
    TopText,
    BottomText,
    ChartSizeSmall,
    advertisement,
  } = productState;
  const [styleLoaded, setStyleLoaded] = useState(false);
  const [formsLoaded, setFormsLoaded] = useState(false);
  const [groupForm, setGroupForm] = useState(null);
  const [groupForms, setGroupForms] = useState();
  const [groupMetal, setGroupMetal] = useState(null);
  const [groupMetals, setGroupMetals] = useState();
  const groupName = props.match.params.GroupName;
  const frontImageUrl = constants.CMS_FRONT_IMAGE_URL;
  const productGroup = "";

  function getGroupInformation() {
    productDispatch({ type: "setValidationErrors", payload: [] });
    api.fetch(`quickorder/group?style=${groupName}`).then((response) => {
      if (response.isAxiosError) {
        const errorMessage = [];
        response.data.errors.forEach(function (error) {
          errorMessage.push(error);
        });
        toast.error(
          `There was an error processing your request. ${errorMessage.toString()}`
        );
      } else {
        const metalField = response.data.fields.find(function (field) {
          return field.name === "metal";
        });
        setGroupMetals(metalField.values);
        productDispatch({ type: "setPageLoaded", payload: true });
      }
    });
  }

  function makeInitialQueryString(productDefaults) {
    const query = qs.stringify({
      style: groupName,
      metal: groupMetal,
      form: groupForm,
      product: productDefaults.product,
      dimension:
        groupMetal === "Flux" && groupForm === "FIRESCOFF"
          ? "FIRESCOFF"
          : productDefaults.dimension,
      quantity: productDefaults.quantity,
      quantity_unit: productDefaults.quantity_unit,
      material: groupMetal === "Flux" ? "MS" : productDefaults.material,
      carat_size: productDefaults.carat_size,
      message: productDefaults.message,
      finger_size: productDefaults.finger_size,
      treatments: "",
    });
    return query;
  }

  function getInitialPrice(productDefaults) {
    const query = makeInitialQueryString(productDefaults);
    api.fetch(`quickorder/price?${query}`).then((response) => {
      if (response.isAxiosError) {
        const errorMessage = [];

        response.data.errors.forEach(function (error) {
          errorMessage.push(error);
        });

        toast.error(
          `There was an error processing your request. ${errorMessage.toString()}`
        );
        productDispatch({
          type: "setMessage",
          payload: {
            message: Object.values(response.data.errors),
            type: "error",
          },
        });
        productDispatch({ type: "setPriceLoaded", payload: true });
      } else {
        productDispatch({
          type: "setMessage",
          payload: { message: null, type: null },
        });
        productDispatch({
          type: "setPrice",
          payload: response.data,
        });
        productDispatch({ type: "setPriceLoaded", payload: true });
        getStrapiInfo();
        setStyleLoaded(true);
      }
    });
  }

  function createProductDefaults(product) {
    setStyleLoaded(false);
    var productDefaults = {};
    productDefaults = {
      ...productDefaults,
      product: product.product,
      description: product.description,
      treatments: "",
    };
    productDispatch({
      type: "setStyle",
      payload: product,
    });
    productDispatch({
      type: "setProductDefaults",
      field: "product",
      payload: product.product,
    });
    productDispatch({
      type: "setProductDefaults",
      field: "description",
      payload: product.description,
    });
    productDispatch({
      type: "setProductDefaults",
      field: "treatments",
      payload: "",
    });
    if (groupMetal === "Flux") {
      productDefaults = {
        ...productDefaults,
        material: "MS",
        dimension: groupForm === "FIRESCOFF" ? "FIRESCOFF" : "",
      };
      productDispatch({
        type: "setProductDefaults",
        field: "material",
        payload: "MS",
      });
      productDispatch({
        type: "setProductDefaults",
        field: "dimension",
        payload: groupForm === "FIRESCOFF" ? "FIRESCOFF" : "",
      });
    }
    product.fields.forEach(function (field) {
      if (field.template === "field_with_units") {
        productDispatch({
          type: "setProductDefaults",
          field: field.name,
          payload: field.default === 0 ? 1 : field.default,
        });
        productDispatch({
          type: "setProductDefaults",
          field: `${field.name}_unit`,
          payload: field.default_unit,
        });
        productDefaults = {
          ...productDefaults,
          [field.name]: field.default,
          [`${field.name}_unit`]: field.default_unit,
        };
      } else {
        productDispatch({
          type: "setProductDefaults",
          field: field.name,
          payload: field.default,
        });
        productDefaults = {
          ...productDefaults,
          [field.name]: field.default,
        };
      }
    });
    if (authToken) {
      getInitialPrice(productDefaults);
    }
  }

  function setTreatmentsString() {
    const treatmentsList = (productInfo.treatments || []).map(function (
      treatment
    ) {
      return treatment.value;
    });
    return treatmentsList.join(" ");
  }

  function makeQueryString() {
    const infoToStringify = {
      style: groupName,
      metal: groupMetal,
      form: groupForm,
      product: productInfo.product,
      dimension:
        groupMetal === "Flux" && groupForm === "FIRESCOFF"
          ? "FIRESCOFF"
          : productInfo.dimension,
      quantity: productInfo.quantity,
      quantity_unit: productInfo.quantity_unit,
      material: groupMetal === "Flux" ? "MS" : productInfo.material,
      carat_size: productInfo.carat_size,
      message: productInfo.message,
      finger_size: productInfo.finger_size,
      treatments: setTreatmentsString(),
    };
    const arrayOfValues = Object.values(infoToStringify);
    const searchingForNone = arrayOfValues.includes("None");
    if (!searchingForNone) {
      const query = qs.stringify(infoToStringify);
      return query;
    } else {
      productDispatch({ type: "setPriceLoaded", payload: true });
      return false;
    }
  }

  function getUpdatedPrice(newQueryString) {
    api.fetch(`quickorder/price?${newQueryString}`).then((response) => {
      if (response.isAxiosError) {
        const errorMessage = [];
        response.data.errors.forEach(function (error) {
          errorMessage.push(error);
        });
        toast.error(
          `There was an error processing your request. ${errorMessage.toString()}`
        );
        productDispatch({
          type: "setMessage",
          payload: {
            message: Object.values(response.data.errors),
            type: "error",
          },
        });
        productDispatch({ type: "setPriceLoaded", payload: true });
      } else {
        updateFields(response.data.fields);
        productDispatch({
          type: "setNewTreatmentFields",
          payload: response.data.treatments,
        });
        productDispatch({ type: "updatePrice", payload: response.data });
        productDispatch({
          type: "setMessage",
          payload: { message: null, type: null },
        });
        // Quick fix for prices that are returning 1029000 when changing materials
        if (response.data.price !== 1029000) {
          productDispatch({ type: "setPriceLoaded", payload: true });
        }
      }
    });
  }

  function updateFields(fields) {
    const newFields = [];
    productFields.forEach((field) => {
      newFields.push(field.name === "material" ? field : null);
    });
    fields.forEach(function (field) {
      newFields.push(
        field.name === "quantity"
          ? field
          : field.name === "dimension"
          ? field
          : null
      );
    });
    const filteredFields = Array.from(
      new Set(newFields.filter((field) => field !== null))
    );
    const sortedFields = sortFields(filteredFields);
    productDispatch({
      type: "setNewFields",
      payload: sortedFields,
    });
  }

  function sortFields(fields) {
    const newSortedFields = fields.map((field) => ({
      ...field,
      SortOrder:
        field.name === "material" ? 1 : field.name === "dimension" ? 2 : 100,
    }));
    return newSortedFields.sort((a, b) => a.SortOrder - b.SortOrder);
  }

  function getStrapiInfo() {
    const strapiId = 2;
    const query = qs.stringify({
      populate: {
        left: {
          populate: "*",
        },
        middle: {
          populate: "*",
        },
        right: {
          populate: "*",
        },
        chart: {
          populate: "*",
        },
      },
    });
    api
      .fetchStrapi(`/product-details-charts/${strapiId}?${query}`)
      .then((response) => {
        productDispatch({
          type: "setStrapi",
          payload: {
            left: response.data.data.attributes.left,
            middle: response.data.data.attributes.middle,
            right: response.data.data.attributes.right,
            chart: response.data.data.attributes.chart,
            ChartSizeSmall: response.data.data.attributes.ChartSizeSmall,
            TopText: response.data.data.attributes.TopText,
            BottomText: response.data.data.attributes.BottomText,
          },
        });
      });
  }

  useEffect(() => {
    productDispatch({ type: "resetProductPage", payload: "" });
    getGroupInformation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.match.params.GroupName]);

  //advertisements from Strapi
  useEffect(() => {
    const query = qs.stringify({
      populate: {
        One: {
          populate: "*",
        },
        Two: {
          populate: "*",
        },
        Three: {
          populate: "*",
        },
        Four: {
          populate: "*",
        },
        Five: {
          populate: "*",
        },
      },
    });
    api.fetchStrapi(`/detail-page-ad?${query}`).then((response) => {
      const ads = response.data.data.attributes;
      const randomAd = filterAds(ads);

      productDispatch({
        type: "setPageAd",
        payload: randomAd,
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.match.params.ProductStyleCode]);

  useEffect(() => {
    if (groupMetal !== "None" && groupMetal !== null) {
      productDispatch({ type: "setPriceLoaded", payload: false });
      productDispatch({ type: "resetProductPage", payload: "" });
      setFormsLoaded(false);
      setGroupForm(null);
      api
        .fetch(`quickorder/group?style=${groupName}&metal=${groupMetal}`)
        .then((response) => {
          if (response.isAxiosError) {
            const errorMessage = [];
            response.data.errors.forEach(function (error) {
              errorMessage.push(error);
            });
            toast.error(
              `There was an error processing your request. ${errorMessage.toString()}`
            );
          } else {
            productDispatch({
              type: "setMessage",
              payload: { message: null, type: null },
            });
            const formField = response.data.fields.find(function (field) {
              return field.name === "form";
            });
            setGroupForms(formField.values);
            setFormsLoaded(true);
          }
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupMetal]);

  useEffect(() => {
    if (groupForm !== "None" && groupForm !== null) {
      productDispatch({ type: "resetProductPage", payload: "" });
      productDispatch({ type: "setPriceLoaded", payload: false });
      api
        .fetch(
          `quickorder/group?style=${groupName}&metal=${groupMetal}&form=${groupForm}`
        )
        .then((response) => {
          if (response.isAxiosError) {
            const errorMessage = [];
            response.data.errors.forEach(function (error) {
              errorMessage.push(error);
            });
            toast.error(
              `There was an error processing your request. ${errorMessage.toString()}`
            );
          } else {
            createProductDefaults(response.data);
            functions.storeRecentlyViewed(response.data);
          }
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupForm]);

  //update price
  useEffect(() => {
    if (authToken && pageLoaded && styleLoaded && productInfo.quantity > 0) {
      productDispatch({ type: "setPriceLoaded", payload: false });
      const newQueryString = makeQueryString();
      newQueryString !== false && getUpdatedPrice(newQueryString);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productInfo]);

  return (
    <ProductsContext.Provider value={{ productState, productDispatch }}>
      {!pageLoaded ? (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={true}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : (
        <Container maxWidth="xl">
          {isMdScreen ? (
            <Stack spacing={3}>
              <Typography variant="h4" marginTop="4rem" textAlign={"center"}>
                {groupName.toUpperCase()}
              </Typography>
              <Divider />
            </Stack>
          ) : null}
          <Grid container spacing={6} sx={{ marginBottom: "1rem" }}>
            <Grid item xs={12} md={5}>
              <Stack>
                {groupMetal && groupForm && images && images.length > 0 ? (
                  <ProductGalleryViewer images={images} />
                ) : (
                  <img
                    src={SolderImage}
                    alt="Selected Product"
                    className="selected-product"
                  />
                )}
                {groupMetal && groupForm && (
                  <DetailTable data={productDetails} />
                )}
              </Stack>
            </Grid>
            <Grid item xs={12} md={4}>
              <Stack spacing={1} sx={{ marginBottom: ".5rem" }}>
                {!isMdScreen ? (
                  <Typography variant="h4" marginTop="4rem">
                    {groupName.toUpperCase()}
                  </Typography>
                ) : null}
                <Typography>
                  Select a metal to start placing your order!
                </Typography>
                <Stack direction="row" spacing={1}>
                  {groupMetal &&
                    groupForm &&
                    icons &&
                    icons.map((icons, key) => (
                      <Typography key={icons} className="icons">
                        {functions.getIcons(icons)}
                      </Typography>
                    ))}
                </Stack>
              </Stack>
              <Stack spacing={1}>
                <Stack>
                  <Typography
                    sx={{
                      fontWeight: "700",
                      paddingBottom: ".3rem",
                    }}
                  >
                    {`${groupName.toUpperCase()} Metal`}
                  </Typography>
                  <Select
                    value={groupMetal ? groupMetal : "None"}
                    size="small"
                    sx={{ width: "100%" }}
                    onChange={(e) => {
                      setGroupMetal(e.target.value);
                    }}
                    MenuProps={{
                      PaperProps: {
                        sx: {
                          border: "1px solid #939598",
                          borderRadius: "0",
                          maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                        },
                      },
                    }}
                  >
                    <MenuItem value="None">
                      <em>Select...</em>
                    </MenuItem>
                    {groupMetals &&
                      groupMetals.map((metal) => (
                        <MenuItem key={metal.value} value={metal.value}>
                          {metal.label}
                        </MenuItem>
                      ))}
                  </Select>
                </Stack>
                {groupMetal && (
                  <Stack>
                    <Typography
                      sx={{ fontWeight: "700", paddingBottom: ".3rem" }}
                    >
                      {`${groupName.toUpperCase()} Form`}
                    </Typography>
                    <Select
                      value={groupForm ? groupForm : "None"}
                      size="small"
                      sx={{ width: "100%" }}
                      disabled={!formsLoaded}
                      onChange={(e) => {
                        setGroupForm(e.target.value);
                      }}
                      MenuProps={{
                        PaperProps: {
                          sx: {
                            border: "1px solid #939598",
                            borderRadius: "0",
                            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                          },
                        },
                      }}
                    >
                      <MenuItem value="None">
                        <em>Select...</em>
                      </MenuItem>
                      {groupForms &&
                        groupForms.map((form) => (
                          <MenuItem key={form.value} value={form.value}>
                            {form.label}
                          </MenuItem>
                        ))}
                    </Select>
                  </Stack>
                )}
                {groupMetal && groupForm && productFields && (
                  <Divider flexItem sx={{ paddingTop: "1rem" }} />
                )}
                {groupMetal &&
                  groupForm &&
                  productFields &&
                  productFields.map((field) => {
                    if (field.name === "material" && groupMetal !== "Flux") {
                      return (
                        <Material
                          field={field}
                          key={field.name}
                          isMill={props.isMill}
                        />
                      );
                    } else if (
                      field.name === "dimension" &&
                      groupMetal !== "Flux"
                    ) {
                      return (
                        <Dimension
                          field={field}
                          key={field.name}
                          isMill={props.isMill}
                        />
                      );
                    } else if (field.name === "quantity") {
                      return (
                        <Quantity
                          field={field}
                          key={field.name}
                          isMill={props.isMill}
                        />
                      );
                    } else {
                      return "";
                    }
                  })}
                {groupMetal && groupForm && productFields && (
                  <Divider flexItem sx={{ paddingTop: "1rem" }} />
                )}
                {groupMetal &&
                  groupForm &&
                  productTreatments &&
                  productTreatments.map((treat) => {
                    return <TreatmentField field={treat} key={treat.label} />;
                  })}
                {groupMetal && groupForm && productFields && (
                  <SpecialInstructions />
                )}
              </Stack>
            </Grid>
            <Grid item xs={12} md={3}>
              {groupMetal && groupForm && (
                <Price ProductGroup={productGroup} isMill={true} />
              )}
              {advertisement && (
                <DomLink
                  to={{
                    pathname: `${advertisement.alternativeText}`,
                    state: {
                      recentlyViewed: JSON.parse(
                        storage.getSessionStorageItem(
                          "hsRecentlyViewedProducts"
                        )
                      ),
                    },
                  }}
                >
                  <img
                    style={{ marginTop: "4rem" }}
                    src={`${frontImageUrl}${advertisement.url}`}
                    width="100%"
                    alt="advertisement"
                  />
                </DomLink>
              )}
            </Grid>
          </Grid>
          {groupMetal && groupForm && (
            <>
              <RelatedItems relatedProducts={relatedProducts} />
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <TechnicalArticles productGroup={productGroup} />
                </Grid>
                <Grid item xs={12} md={6}>
                  <CustomerTestimonials />
                </Grid>
              </Grid>
              <RecentlyView />
            </>
          )}
          {metalColumns && <MetalColumns metalColumns={metalColumns} />}
          {strapiChart && (
            <StrapiChart
              strapiChart={strapiChart}
              TopText={TopText}
              BottomText={BottomText}
              ChartSizeSmall={ChartSizeSmall}
            />
          )}
        </Container>
      )}
    </ProductsContext.Provider>
  );
}
