import React from "react";
import { Link as DomLink } from "react-router-dom";
import { constants } from "../../../../../utils";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";
import remarkGfm from "remark-gfm";
import { Button, Divider, Box, Stack, Typography, Grid } from "@mui/material";
import { customStackSx, boxGridSx } from "../../styles/AuctionHouseSx";
import ChipButton from "../../styles/ChipButton";
import useMediaQueryUtils from "../../../../../utils/mediaQueryUtils";

const strapiURL = constants.CMS_FRONT_IMAGE_URL;

function General(props) {
  const isMdScreen = useMediaQueryUtils("md");
  const {
    UpcomingAuctions,
    MainTop,
    HowItWorksImage,
    AuctionVideos,
    MainSellText,
    MainBuyText,
    MyAuctionPlugIn,
    setTab,
  } = props;

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Grid container spacing={5} marginBottom={"3rem"}>
            <Grid item xs={12} md={6} marginTop={"3rem"}>
              <Stack
                sx={{
                  padding: "2rem",
                  width: "100%",
                  border: "3px solid #D2B48C",
                }}
                spacing={1}
              >
                <Typography variant="diamondTabFont" className="center">
                  UPCOMING AUCTIONS
                </Typography>
                <Grid container>
                  <Grid item xs={12}>
                    <Typography variant="staticText">
                      <ReactMarkdown remarkGfm={[remarkGfm]}>
                        {UpcomingAuctions.body}
                      </ReactMarkdown>
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Stack
                      spacing={2}
                      alignItems="center"
                      justifyContent="center"
                      marginTop={"3rem"}
                    >
                      <DomLink
                        to={{
                          pathname: "/harmony-auction-signup",
                        }}
                        className="center"
                      >
                        <Button
                          color="success"
                          variant="contained"
                          size="small"
                        >
                          Sign-Up <br /> (it's free)
                        </Button>
                      </DomLink>
                      <Stack direction="row" spacing={1}>
                        <Typography sx={{ color: "#7fa04d" }}>
                          Already Participating?{" "}
                        </Typography>
                        <DomLink
                          to={{
                            pathname: "/myauction-log-in",
                          }}
                          className="center"
                        >
                          <Typography
                            sx={{
                              color: "#7fa04d",
                              textDecoration: "underline",
                            }}
                          >
                            Login
                          </Typography>
                        </DomLink>
                      </Stack>
                    </Stack>
                  </Grid>
                </Grid>
              </Stack>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="staticText" sx={{ width: "100%" }}>
                <ReactMarkdown remarkGfm={[remarkGfm]}>
                  {MainTop.body}
                </ReactMarkdown>
              </Typography>
            </Grid>
          </Grid>
          <Divider flexItem />
        </Grid>
        <Grid item xs={12} marginTop={"3rem"} marginBottom={"3rem"}>
          {HowItWorksImage && (
            <Stack
              sx={{ width: "100%" }}
              alignItems="center"
              spacing={2}
              marginBottom={"4rem"}
            >
              <Typography variant="articleTitle">How It Works</Typography>
              <img
                src={`${strapiURL}${HowItWorksImage.url}`}
                width="100%"
                alt="banner"
              />
            </Stack>
          )}
          <Divider flexItem />
        </Grid>
        <Grid item xs={12}>
          {AuctionVideos && (
            <Grid container spacing={2}>
              <Grid item xs={12} md={6} marginTop={"3rem"}>
                <Stack direction={"column"} spacing={2}>
                  <Typography variant="articleTitle" textAlign="center">
                    {AuctionVideos.title}
                  </Typography>
                  <Typography variant="staticText">
                    <ReactMarkdown remarkGfm={[remarkGfm]}>
                      {AuctionVideos.body}
                    </ReactMarkdown>
                  </Typography>
                </Stack>
              </Grid>
              <Grid item xs={12} md={6}>
                <video
                  className="float-right"
                  controls
                  width={isMdScreen ? "100%" : "85%"}
                  height={isMdScreen ? "100%" : "85%"}
                  poster={`${strapiURL}${AuctionVideos.content.data[1].attributes.url}`}
                >
                  <source
                    src={`${strapiURL}${AuctionVideos.content.data[0].attributes.url}`}
                    type="video/mp4"
                  />
                  Your browser does not support the video tag.
                </video>
              </Grid>
            </Grid>
          )}
          <Divider flexItem />
        </Grid>
        <Grid item xs={12} marginTop={"3rem"} marginBottom={"3rem"}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6} marginTop={"3rem"} marginBottom={"3rem"}>
              {MainSellText && (
                <Stack
                  sx={{
                    ...customStackSx,
                    spacing: 2,
                  }}
                >
                  <Typography variant="articleTitle">
                    {MainSellText.title}
                  </Typography>

                  <Typography variant="staticText">
                    <ReactMarkdown remarkGfm={[remarkGfm]}>
                      {MainSellText.body}
                    </ReactMarkdown>
                  </Typography>

                  <ChipButton
                    label="Learn More"
                    tabValue="Sell"
                    setTab={setTab}
                    learnMore={true}
                  />
                </Stack>
              )}
            </Grid>
            <Grid item xs={12} md={6} marginTop={"3rem"} marginBottom={"3rem"}>
              {MainBuyText && (
                <Stack
                  sx={{
                    ...customStackSx,
                    spacing: 2,
                  }}
                >
                  <Typography variant="articleTitle">
                    {MainBuyText.title}
                  </Typography>
                  <Typography variant="staticText">
                    <Box sx={{ justifyContent: "center" }}>
                      <ReactMarkdown remarkGfm={[remarkGfm]}>
                        {MainBuyText.body}
                      </ReactMarkdown>
                    </Box>
                  </Typography>
                  <ChipButton
                    label="Learn More"
                    tabValue="Buy"
                    setTab={setTab}
                    learnMore={true}
                  />
                </Stack>
              )}
            </Grid>
          </Grid>
          <Divider flexItem />
        </Grid>
        <Grid item xs={12}>
          {MyAuctionPlugIn && (
            <Stack
              sx={{
                padding: "3rem",
                width: "100%",
                border: "3px solid #7fa04d",
              }}
              spacing={2}
              alignItems="center"
              justifyContent="center"
            >
              <Typography variant="articleTitle">
                {MyAuctionPlugIn.title}
              </Typography>
              <Box sx={boxGridSx}>
                <Stack
                  spacing={1}
                  alignItems="center"
                  justifyContent="center"
                  marginBottom={"3rem"}
                >
                  <Typography variant="staticText">
                    <ReactMarkdown remarkGfm={[remarkGfm]}>
                      {MyAuctionPlugIn.body}
                    </ReactMarkdown>
                  </Typography>
                  <ChipButton
                    label="Learn More"
                    tabValue="myAuction"
                    setTab={setTab}
                    learnMore={true}
                  />
                </Stack>
                <img
                  width="100%"
                  src={`${strapiURL}${MyAuctionPlugIn.image.data.attributes.url}`}
                  alt="banner"
                />
              </Box>
            </Stack>
          )}
        </Grid>
      </Grid>
    </>
  );
}

export default General;
