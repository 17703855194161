// Third-party libraries
import React from "react";
import { Typography } from "@mui/material";

// Local modules
import HorizontalScrollList from "../components/HorizontalScrollList";
function RelatedItems(props) {
  return (
    <>
      <Typography variant="h2" sx={{ marginTop: "2rem" }}>
        Related Items
      </Typography>
      {props.relatedProducts && (
        <HorizontalScrollList
          loadedStyles={props.relatedProducts}
          related={true}
        />
      )}
    </>
  );
}

export default RelatedItems;
