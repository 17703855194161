// Third-party libraries
import React, { useContext, useState } from "react";
import Creatable from "react-select/creatable";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import InfoIcon from "@mui/icons-material/InfoOutlined";
import { Select, Typography, Stack, MenuItem } from "@mui/material";

// Local modules
import { ProductsContext } from "../../../../utils/stateHandlers/contexts";
import Modal from "../../../diamonds/controls/Modal";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

export default function Material(props) {
  const { productState, productDispatch } = useContext(ProductsContext);
  const { productInfo, style, priceLoaded, bubbles } = productState;
  const [showHelp, setShowHelp] = useState(false);
  const [helpTitle, setHelpTitle] = useState("");
  const [helpText, setHelpText] = useState("");
  const field = props.field;

  function defaultSelectValue(field) {
    if (field.default) {
      const defaultValue = field.values.find(function (v) {
        return v.value === field.default;
      });
      return defaultValue;
    } else {
      return field.values[0];
    }
  }

  function materialOptionsFormatting(options) {
    // Standard, MTO, Fairmined - Standard, Fairmined - MTO
    const standardOptions = [];
    const mtoOptions = [];
    const fairminedStandardOptions = [];
    const fairminedMtoOptions = [];

    options.forEach(function (option) {
      return option.category === "Standard"
        ? standardOptions.push(option)
        : option.category === "MTO"
        ? mtoOptions.push(option)
        : option.category === "Fairmined - Standard"
        ? fairminedStandardOptions.push(option)
        : option.category === "Fairmined - MTO"
        ? fairminedMtoOptions.push(option)
        : "";
    });

    return [
      { label: "Standard", options: standardOptions },
      { label: "MTO", options: mtoOptions },
      { label: "Fairmined - Standard", options: fairminedStandardOptions },
      { label: "Fairmined - MTO", options: fairminedMtoOptions },
    ];
  }

  return (
    <Stack>
      <Typography sx={{ fontWeight: "700", paddingBottom: ".3rem" }}>
        {field.label}
        {props.isMill && style === "FS" && (
          <InfoIcon
            sx={{ marginLeft: "0.5rem" }}
            className="pointer"
            color="primary"
            onClick={() => {
              setShowHelp(true);
              setHelpTitle("Flat Sheet Material");
              setHelpText(
                "Gold supplied in sheets up to 6 inches wide. Sheets are mill finish. Minimum gold order: 1 x 1/2 inch or 25 x 13MM, maximum gold order is 6 x 36 inch or 152 x 914mm. Sterling silver and TruSilver® sheets supplied in even gauges only up to 6 x 36 inch. Odd gauges available with longer lead times. One dimension must be either 3, 6 or 12 inches. If not, scrapping fee applies. Sterling silver also available in 12 inches wide by 36 inches in gauges 16, 18, 20 and 22. All sheets are shear cut. For all gold, platinum and palladium products, all sizes between 8 and 30 gauge are stock items. If not ordering sheet in a standard gauge, please order thickness dimension in thousandths of an inch. Charge for multiple cuts of 10 or more of silver sheet is $.45/piece."
              );
            }}
          />
        )}
        {style === "RW" &&
          bubbles &&
          bubbles.map((bubble, index) => (
            <React.Fragment key={index}>
              {bubble.bubblefield === "material" && (
                <InfoIcon
                  sx={{ marginLeft: "0.5rem" }}
                  className="pointer"
                  color="primary"
                  onClick={() => {
                    setShowHelp(true);
                    setHelpTitle(bubble.bubbletitle);
                    setHelpText(bubble.bubbletext);
                  }}
                />
              )}
            </React.Fragment>
          ))}
      </Typography>
      {style === "RW" ? (
        <Creatable
          key={field.name}
          defaultValue={defaultSelectValue(field)}
          isDisabled={!priceLoaded}
          onChange={(e) => {
            productDispatch({
              type: "updateFields",
              field: "material",
              payload: e.value,
            });
            productDispatch({ type: "setClear", payload: true });
          }}
          options={
            style === "RW"
              ? materialOptionsFormatting(field.values)
              : field.values
          }
          theme={(theme) => ({
            ...theme,
            borderRadius: 0,
            colors: {
              ...theme.colors,
              primary25: "#cfdde9",
              primary: "#124760",
            },
          })}
        />
      ) : (
        <Select
          value={
            productInfo.material === undefined ? "None" : productInfo.material
          }
          size="small"
          sx={{ width: "100%" }}
          disabled={!priceLoaded || field.values.length === 1}
          onChange={(e) => {
            productDispatch({
              type: "updateFields",
              field: "material",
              payload: e.target.value,
            });
            productDispatch({ type: "setClear", payload: true });
          }}
          MenuProps={{
            PaperProps: {
              sx: {
                border: "1px solid #939598",
                borderRadius: "0",
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
              },
            },
          }}
        >
          <MenuItem value="None">
            <em>Select...</em>
          </MenuItem>
          {field.values &&
            field.values.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
        </Select>
      )}

      <Modal
        title={helpTitle}
        onClose={() => setShowHelp(false)}
        show={showHelp}
      >
        <Typography variant="staticText">
          <ReactMarkdown children={helpText} remarkPlugins={[remarkGfm]} />
        </Typography>
      </Modal>
    </Stack>
  );
}
