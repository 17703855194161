// Third-party libraries
import React, { useEffect, useReducer } from "react";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";
import { useForm } from "react-hook-form";
import remarkGfm from "remark-gfm";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  TextField,
  Select,
  FormControl,
  MenuItem,
  FormHelperText,
  Container,
  Stack,
  Typography,
  Backdrop,
  CircularProgress,
  Grid,
  Button,
} from "@mui/material";

// Local modules
import { FormContext } from "../../../utils/stateHandlers/contexts";
import { formReducer } from "../../../utils/stateHandlers/reducers";
import states from "../../../utils/stateHandlers/initialStates";
import { api } from "../../../utils";
import { Default, Mobile } from "../../../utils/deviceHelpers";

const schema = yup.object().shape({
  category: yup
    .string()
    .test(
      "category-not-selected",
      "Please Select the Category",
      (value) => value !== "None"
    )
    .required("Please Select the Category"),
  company_name: yup.string().required("Please Enter Your Company Name"),
  first_name: yup.string().required("Please Enter Your First Name"),
  last_name: yup.string().required("Please Enter Your Last Name"),
  email: yup.string().required("Please Enter Your Email"),
  phone: yup.string().required("Please Enter Your Phone Number"),
  message: yup.string().required("Please Type A Message"),
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

export default function ContactUs(props) {
  const [formState, dispatch] = useReducer(
    formReducer,
    states.initialFormState
  );
  const { isLoaded, fields, formtext, formtitle, message, submitted } =
    formState;
  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const InputField = (field) => (
    <Stack>
      <Typography variant="navText">{field.label}</Typography>
      <TextField
        {...register(field.name)}
        error={errors[field.name] ? true : false}
        helperText={errors[field.name] ? errors[field.name].message : ""}
        color="primary"
        size="small"
        sx={{
          width: "100%",
          margin: "unset",
        }}
      />
    </Stack>
  );

  const InputAreaField = (field) => (
    <Stack>
      <Typography variant="navText">{field.label}</Typography>
      <TextField
        {...register(field.name)}
        error={errors[field.name] ? true : false}
        helperText={errors[field.name] ? errors[field.name].message : ""}
        multiline
        fullWidth
        rows={3}
        sx={{
          width: "100%",
          margin: "unset",
        }}
      />
    </Stack>
  );

  const SelectField = (field) => (
    <Stack>
      <Typography variant="navText">{field.label}</Typography>
      <FormControl
        {...register(field.name)}
        error={errors[field.name] ? true : false}
      >
        <Select
          key={field.name}
          defaultValue="None"
          size="small"
          MenuProps={{
            PaperProps: {
              sx: {
                border: "1px solid #939598",
                borderRadius: "0",
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
              },
            },
          }}
          onChange={(e) =>
            setValue(field.name, e.target.value, { shouldValidate: true })
          }
        >
          <MenuItem value="None">
            <em>Select...</em>
          </MenuItem>
          {field.values.map((option) => (
            <MenuItem value={option.value} key={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
        <FormHelperText>
          {errors[field.name] ? errors[field.name].message : ""}
        </FormHelperText>
      </FormControl>
    </Stack>
  );

  function submitForm(data) {
    api.post(`forms/contact/submit`, data).then((response) => {
      dispatch({ type: "submitForm", payload: response.data.message });
    });
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    api.fetchStrapi(`/forms/1?populate=*`).then((response) => {
      dispatch({
        type: "setFormCopy",
        payload: {
          formtitle: response.data.data.attributes.formtitle,
          formtext: response.data.data.attributes.formtext,
        },
      });
    });

    api.fetch(`forms/contact/fields`).then((response) => {
      dispatch({ type: "setFields", payload: response.data.fields });
      dispatch({ type: "setAddressFields", payload: [] });
      response.data.fields.forEach(function (field) {
        dispatch({
          type: "updateFormInfo",
          field: field.name,
          payload: field.default,
        });
      });
      dispatch({ type: "setIsLoaded", payload: true });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FormContext.Provider value={{ formState, dispatch }}>
      {!isLoaded ? (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={true}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : (
        <Container maxWidth="lg" className="careers-text">
          <Mobile>
            <Stack spacing={1} sx={{ marginBottom: "3rem" }}>
              <Typography variant="articleTitle">{formtitle}</Typography>
              <Typography variant="articleText" sx={{ paddingBottom: "1rem" }}>
                <ReactMarkdown
                  children={formtext}
                  remarkPlugins={[remarkGfm]}
                />
              </Typography>
              {!submitted ? (
                <form onSubmit={handleSubmit(submitForm)}>
                  <Stack spacing={3}>
                    {fields &&
                      fields.map((field) => (
                        <React.Fragment key={field.name}>
                          {field.template === "text"
                            ? InputField(field)
                            : field.template === "phone"
                            ? InputField(field)
                            : field.template === "number"
                            ? InputField(field)
                            : field.template === "textarea"
                            ? InputAreaField(field)
                            : field.template === "dropdown"
                            ? SelectField(field)
                            : ""}
                        </React.Fragment>
                      ))}
                    <Button variant="contained" type="submit">
                      Submit
                    </Button>
                  </Stack>
                </form>
              ) : (
                <Stack>
                  <Typography
                    variant="articleText"
                    className="center"
                    sx={{
                      backgroundColor: "#7fa04d",
                      height: "15rem",
                      fontSize: "2rem",
                      color: "white",
                      margin: "3rem",
                    }}
                  >
                    {message}
                  </Typography>
                </Stack>
              )}
            </Stack>
          </Mobile>
          <Default>
            <Stack alignItems="center" justifyContent="center">
              <Typography variant="articleTitle">{formtitle}</Typography>
              <Grid container sx={{ marginTop: "2rem" }} spacing={4}>
                <Grid item xs={4} sx={{ paddingRight: "2rem" }}>
                  <Typography
                    variant="articleText"
                    sx={{ marginBottom: "1rem" }}
                  >
                    <ReactMarkdown
                      children={formtext}
                      remarkPlugins={[remarkGfm]}
                    />
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  {!submitted ? (
                    <form onSubmit={handleSubmit(submitForm)}>
                      <Stack spacing={3}>
                        {fields &&
                          fields.map((field) => (
                            <React.Fragment key={field.name}>
                              {field.template === "text"
                                ? InputField(field)
                                : field.template === "phone"
                                ? InputField(field)
                                : field.template === "number"
                                ? InputField(field)
                                : field.template === "textarea"
                                ? InputAreaField(field)
                                : field.template === "dropdown"
                                ? SelectField(field)
                                : ""}
                            </React.Fragment>
                          ))}
                        <Button variant="contained" type="submit">
                          Submit
                        </Button>
                      </Stack>
                    </form>
                  ) : (
                    <Stack>
                      <Typography
                        variant="articleText"
                        className="center"
                        sx={{
                          backgroundColor: "#7fa04d",
                          height: "15rem",
                          fontSize: "2rem",
                          color: "white",
                          margin: "3rem",
                        }}
                      >
                        {message}
                      </Typography>
                    </Stack>
                  )}
                </Grid>
              </Grid>
            </Stack>
          </Default>
        </Container>
      )}
    </FormContext.Provider>
  );
}
