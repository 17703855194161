// Third-party libraries
import React, { useContext } from "react";
import { Typography, TextField, Stack } from "@mui/material";

// Local modules
import { ProductsContext } from "../../../utils/stateHandlers/contexts";

export default function SpecialInstructions() {
  const { productDispatch } = useContext(ProductsContext);
  return (
    <Stack>
      <Typography sx={{ fontWeight: "700", paddingBottom: ".3rem" }}>
        Special Instructions
      </Typography>
      <TextField
        color="primary"
        variant="outlined"
        size="small"
        sx={{
          width: "100%",
          margin: "unset",
        }}
        onChange={(e) => {
          productDispatch({
            type: "setSpecialInstructions",
            payload: e.target.value,
          });
        }}
        inputProps={{
          style: {
            fontSize: 15,
            paddingBottom: 100,
          },
        }}
      />
    </Stack>
  );
}
