// Third-party libraries
import React, { useEffect, useReducer, useRef } from "react";
import toast from "react-hot-toast";
import { useForm } from "react-hook-form";
import ReCAPTCHA from "react-google-recaptcha";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";
import remarkGfm from "remark-gfm";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import DoneIcon from "@mui/icons-material/Done";
import {
  Container,
  Backdrop,
  CircularProgress,
  Typography,
  TextField,
  Select,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  FormHelperText,
  FormGroup,
  Divider,
  Stack,
  MenuItem,
  Button,
  Checkbox,
  Box,
  Paper,
  Chip,
} from "@mui/material";

// Local modules
import { CreateAccountContext } from "../../utils/stateHandlers/contexts";
import states from "../../utils/stateHandlers/initialStates";
import { createAccountReducer } from "../../utils/stateHandlers/reducers";
import { api } from "../../utils";

const sxBox = {
  display: "grid",
  gridTemplateColumns: {
    sm: "repeat(1, 1fr)",
    md: "repeat(2, 1fr)",
    lg: "repeat(2, 1fr)",
    xl: "repeat(2, 1fr)",
  },
};

const schema = yup.object().shape({
  CompanyName: yup.string().required("Company Name is Required"),
  Principal: yup
    .string()
    .required("Please enter the name of the President, CEO or Partners."),
  PrincipalTitle: yup.string().required("Please specify their title."),
  BusinessType: yup
    .string()
    .nullable()
    .required("Business Structure is Required"),
  Telephone: yup.string().required("Phone Number is Required"),
  Fax: yup.string(),
  BillStreet1: yup.string().required("Billing Address is Required"),
  BillStreet2: yup.string(),
  BillCity: yup.string().required("Billing City is Required"),
  BillState: yup.string().required("Billing State is Required"),
  BillZip: yup.string().required("Billing Zip Code is Required"),
  BillCountryCode: yup.string().required("Billing Country is Required"),
  SameAsBillingAddress: yup.bool(),
  ShipStreet1: yup.string().required("Shipping Address is Required"),
  // .when("SameAsBillingAddress", {
  //   is: false,
  //   then: yup.string().required("Shipping Address is Required"),
  // }),
  ShipStreet2: yup.string(),
  ShipCity: yup.string().required("Shipping City is Required"),
  ShipState: yup.string().required("Shipping State is Required"),
  ShipZip: yup.string().required("Shipping Zip Code is Required"),
  ShipCountryCode: yup.string().required("Shipping Country is Required"),
  Name: yup.string().required("Contact Name is Required"),
  Title: yup.string().required("Please specify your title."),
  Email: yup.string().required("Contact Email is Required"),
  Website: yup.string(),
  TaxId: yup.string(),
  Social: yup.string(),
  Aml: yup.string().required("AML Program is Required"),
  ComplianceOfficer: yup.string(),
  Bank: yup.string(),
  AccountNumber: yup.string(),
  Reference: yup.string(),
  Terms: yup.bool().oneOf([true], "Terms are Required"),
  BypassRecaptcha: yup.string(),
  UploadFilename: yup.string(),
  //"g-recaptcha-response": yup.string().required()
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

export default function CreateAccount(props) {
  const [createAccountState, createAccountDispatch] = useReducer(
    createAccountReducer,
    states.initialCreateAccountState
  );
  const {
    pageLoaded,
    formInfo,
    formtext,
    formtitle,
    formFields,
    accountCreated,
    reCAPTCHA,
  } = createAccountState;
  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const captchaRef = useRef(null);

  const InputField = (field) => (
    <Stack sx={{ margin: "1rem" }}>
      <Typography
        sx={{
          fontWeight: "700",
          paddingBottom: ".3rem",
        }}
      >
        {field.label}
      </Typography>
      <TextField
        {...register(field.name)}
        error={errors[field.name] ? true : false}
        helperText={errors[field.name] ? errors[field.name].message : ""}
        value={Object.values(formInfo).find(
          (formField) => formField === field.name
        )}
        color="primary"
        size="small"
        sx={{
          width: "100%",
          margin: "unset",
        }}
      />
    </Stack>
  );

  const InputAreaField = (field) => {
    <TextField
      {...register(field.name)}
      error={errors[field.name] ? true : false}
      helperText={errors[field.name] ? errors[field.name].message : ""}
      sx={{ margin: "1rem" }}
      label="Special Instructions"
      multiline
      fullWidth
      rows={3}
      value={Object.values(formInfo).find(
        (formField) => formField === field.name
      )}
    />;
  };

  const SelectField = (field) => (
    <Stack sx={{ margin: "1rem" }}>
      <Typography
        sx={{ fontWeight: "700", paddingBottom: ".3rem", color: "black" }}
      >
        {field.label === "" ? "Title" : field.label}
      </Typography>
      <FormControl
        {...register(field.name)}
        error={errors[field.name] ? true : false}
      >
        <Select
          key={field.name}
          defaultValue={field.default}
          size="small"
          MenuProps={{
            PaperProps: {
              sx: {
                border: "1px solid #939598",
                borderRadius: "0",
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
              },
            },
          }}
          onChange={(e) =>
            setValue(field.name, e.target.value, { shouldValidate: true })
          }
        >
          {field.values.map((option) => (
            <MenuItem value={option.value} key={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
        <FormHelperText>
          {errors[field.name] ? errors[field.name].message : ""}
        </FormHelperText>
      </FormControl>
    </Stack>
  );

  const RadioButtonField = (field) => (
    <FormControl
      sx={{ margin: "1rem" }}
      {...register(field.name)}
      error={errors[field.name] ? true : false}
      onChange={(e) =>
        setValue(field.name, e.target.value, { shouldValidate: true })
      }
    >
      <FormLabel>
        <Typography
          sx={{ fontWeight: "700", paddingBottom: ".3rem", color: "black" }}
        >
          {field.label} *
        </Typography>
      </FormLabel>
      <RadioGroup
        sx={{
          paddingLeft: "2rem",
          border: errors[field.name] ? "1px solid #954738" : "",
        }}
      >
        {field.values &&
          field.values.map((option) => (
            <FormControlLabel
              key={option.value}
              value={option.value}
              control={<Radio />}
              label={<Typography>{option.label}</Typography>}
            />
          ))}
      </RadioGroup>
      <FormHelperText>
        {errors[field.name] ? errors[field.name].message : ""}
      </FormHelperText>
    </FormControl>
  );

  const CheckboxField = (field) => (
    <FormControl
      {...register(field.name)}
      error={errors[field.name] ? true : false}
      onChange={(e) => {
        setValue(field.name, e.target.checked, {
          shouldValidate: true,
        });
      }}
    >
      <FormGroup>
        <FormControlLabel
          sx={{
            margin: "1rem",
            border: errors[field.name] ? "1px solid #954738" : "",
          }}
          label={<Typography variant="cellFont">{field.label}</Typography>}
          control={<Checkbox />}
        />
      </FormGroup>
      <FormHelperText>
        {errors[field.name] ? errors[field.name].message : ""}
      </FormHelperText>
    </FormControl>
  );

  function sortFormFields(fields) {
    const companyFields = [];
    const billingAddress = [];
    const shippingAddress = [];
    const businessContactFields = [];
    const amlFields = [];
    const otherFields = [];
    fields.forEach(function (field) {
      if (
        field.name === "CompanyName" ||
        field.name === "Principal" ||
        field.name === "PrincipalTitle" ||
        field.name === "BusinessType" ||
        field.name === "Telephone" ||
        field.name === "Fax"
      ) {
        companyFields.push(field);
      } else if (
        field.name === "BillStreet1" ||
        field.name === "BillStreet2" ||
        field.name === "BillCity" ||
        field.name === "BillState" ||
        field.name === "BillZip" ||
        field.name === "BillCountryCode"
      ) {
        billingAddress.push(field);
      } else if (
        field.name === "ShipStreet1" ||
        field.name === "ShipStreet2" ||
        field.name === "ShipCity" ||
        field.name === "ShipState" ||
        field.name === "ShipZip" ||
        field.name === "ShipCountryCode"
      ) {
        shippingAddress.push(field);
      } else if (
        field.name === "Name" ||
        field.name === "Title" ||
        field.name === "Email" ||
        field.name === "Website"
      ) {
        businessContactFields.push(field);
      } else if (
        field.name === "TaxId" ||
        field.name === "Social" ||
        field.name === "Aml" ||
        field.name === "ComplianceOfficer"
      ) {
        amlFields.push(field);
      } else {
        otherFields.push(field);
      }
    });
    const sortedFields = {
      companyFields: companyFields,
      billingAddress: billingAddress,
      shippingAddress: shippingAddress,
      businessContactFields: businessContactFields,
      amlFields: amlFields,
      otherFields: otherFields,
    };
    return sortedFields;
  }

  const uploadBusinessLicense = (event) => {
    event.preventDefault();
    const formData = new FormData();
    var businessLicenseUpload = document.querySelector("#file");
    formData.append("BusinessLicense", businessLicenseUpload.files[0]);
    api.upload("createaccount/upload", formData).then((response) => {
      if (response.isAxiosError) {
        const errorMessage = [];
        response.data.errors.forEach(function (error) {
          errorMessage.push(error);
        });
        toast.error(
          `There was an error processing your request. ${errorMessage.toString()}`
        );
      } else {
        createAccountDispatch({
          type: "setFormInfo",
          field: "UploadFilename",
          payload: response.data.UploadFilename,
        });
      }
    });
  };

  const submitNewAccount = (data) => {
    const token = captchaRef.current.getValue();
    const formData = {
      ...data,
      BypassRecaptcha: true,
      UploadFilename: formInfo.UploadFilename,
      "g-recaptcha-response": token,
    };
    api.post("createaccount/create", formData).then((response) => {
      if (response.isAxiosError) {
        const errorMessage = [];
        response.data.errors.forEach(function (error) {
          errorMessage.push(error);
        });
        toast.error(
          `There was an error processing your request. ${errorMessage.toString()}`
        );
      } else {
        createAccountDispatch({
          type: "setAccountCreated",
          payload: true,
        });
      }
    });
  };

  useEffect(() => {
    api.fetch("/createaccount/fields").then((response) => {
      if (response.isAxiosError) {
        const errorMessage = [];
        response.data.errors.forEach(function (error) {
          errorMessage.push(error);
        });
        toast.error(
          `There was an error processing your request. ${errorMessage.toString()}`
        );
      } else {
        response.data.fields.forEach(function (field) {
          createAccountDispatch({
            type: "setFormInfo",
            field: field.name,
            payload: field.default,
          });
        });
        const sorted = sortFormFields(response.data.fields);
        createAccountDispatch({
          type: "setFormFields",
          payload: sorted,
        });
        createAccountDispatch({
          type: "setRecaptcha",
          payload: response.data.reCAPTCHA,
        });
        createAccountDispatch({ type: "setPageLoaded", payload: true });
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    api.fetchStrapi(`/forms/4?populate=*`).then((response) => {
      createAccountDispatch({
        type: "setFormCopy",
        payload: {
          formtitle: response.data.data.attributes.formtitle,
          formtext: response.data.data.attributes.formtext,
        },
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <CreateAccountContext.Provider
      value={{ createAccountState, createAccountDispatch }}
    >
      {!pageLoaded ? (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={true}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : (
        <Container maxWidth="xl">
          {/* <Mobile>
            
          </Mobile> */}
          {/* <Default> */}
          {!accountCreated ? (
            <form onSubmit={handleSubmit(submitNewAccount)}>
              <Stack spacing={3} sx={{ marginBottom: "3rem" }}>
                <Typography variant="articleTitle">{formtitle}</Typography>
                <Typography variant="staticText" sx={{ paddingBottom: "1rem" }}>
                  <ReactMarkdown
                    children={formtext}
                    remarkPlugins={[remarkGfm]}
                  />
                </Typography>
                <Typography variant="h6">Company Information</Typography>
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: {
                      sm: "repeat(1, 1fr)",
                      md: "repeat(3, 1fr)",
                      lg: "repeat(3, 1fr)",
                      xl: "repeat(3, 1fr)",
                    },
                  }}
                >
                  {formFields.companyFields &&
                    formFields.companyFields.map((field) => (
                      <React.Fragment key={field.name}>
                        {field.template === "text"
                          ? InputField(field)
                          : field.template === "phone"
                          ? InputField(field)
                          : field.template === "dropdown"
                          ? SelectField(field)
                          : field.template === "radio"
                          ? RadioButtonField(field)
                          : field.template === "textarea"
                          ? InputAreaField(field)
                          : CheckboxField(field)}
                      </React.Fragment>
                    ))}
                </Box>
                <Divider flexItem />
                <Typography variant="h6">Billing Address *</Typography>
                <Box sx={sxBox}>
                  {formFields.billingAddress &&
                    formFields.billingAddress.map((field) => (
                      <React.Fragment key={field.name}>
                        {field.template === "text"
                          ? InputField(field)
                          : field.template === "phone"
                          ? InputField(field)
                          : field.template === "dropdown"
                          ? SelectField(field)
                          : field.template === "radio"
                          ? RadioButtonField(field)
                          : field.template === "textarea"
                          ? InputAreaField(field)
                          : CheckboxField(field)}
                      </React.Fragment>
                    ))}
                </Box>
                <Divider flexItem />
                <Stack direction="row" alignItems="center" spacing={2}>
                  <Typography variant="h6">Shipping Address *</Typography>
                  {/* <FormControl {...register("SameAsBillingAddress")}>
                    <FormGroup>
                      <FormControlLabel
                        sx={{
                          margin: "1rem",
                        }}
                        label={
                          <Typography variant="cellFont">
                            Same as billling address
                          </Typography>
                        }
                        control={<Checkbox />}
                      />
                    </FormGroup>
                  </FormControl> */}
                </Stack>
                <Box sx={sxBox}>
                  {formFields.shippingAddress &&
                    formFields.shippingAddress.map((field) => (
                      <React.Fragment key={field.name}>
                        {field.template === "text"
                          ? InputField(field)
                          : field.template === "phone"
                          ? InputField(field)
                          : field.template === "dropdown"
                          ? SelectField(field)
                          : field.template === "radio"
                          ? RadioButtonField(field)
                          : field.template === "textarea"
                          ? InputAreaField(field)
                          : CheckboxField(field)}
                      </React.Fragment>
                    ))}
                </Box>
                <Divider flexItem />
                <Typography variant="h6">
                  Business Contact Information
                </Typography>
                <Box sx={sxBox}>
                  {formFields.businessContactFields &&
                    formFields.businessContactFields.map((field) => (
                      <React.Fragment key={field.name}>
                        {field.template === "text"
                          ? InputField(field)
                          : field.template === "phone"
                          ? InputField(field)
                          : field.template === "dropdown"
                          ? SelectField(field)
                          : field.template === "radio"
                          ? RadioButtonField(field)
                          : field.template === "textarea"
                          ? InputAreaField(field)
                          : CheckboxField(field)}
                      </React.Fragment>
                    ))}
                </Box>
                <Divider flexItem />
                <Typography variant="h6">
                  Anti-Money Laundering Information
                </Typography>
                <Typography variant="staticText">
                  In order to comply with our legal obligations under the USA
                  Patriot Act and Anti Money Laundering laws we ask you to
                  provide the information below. For your protection and ours,
                  we encourage all of our customers and suppliers to adopt and
                  implement anti-corruption measures.
                </Typography>
                <Typography variant="subtitle1">
                  You must provide either your Federal Tax ID or your Social
                  Security Number. We do not require both.
                </Typography>
                <Box sx={sxBox}>
                  {formFields.amlFields &&
                    formFields.amlFields.map((field) => (
                      <React.Fragment key={field.name}>
                        {field.template === "text"
                          ? InputField(field)
                          : field.template === "phone"
                          ? InputField(field)
                          : field.template === "dropdown"
                          ? SelectField(field)
                          : field.template === "radio"
                          ? RadioButtonField(field)
                          : field.template === "textarea"
                          ? InputAreaField(field)
                          : CheckboxField(field)}
                      </React.Fragment>
                    ))}
                </Box>
                <Divider flexItem />
                <Typography variant="h6">Other Information</Typography>
                <Typography variant="staticText">
                  The material we supply for refining is free of conflict
                  materials. It is not sourced from entities in unfair or
                  deceptive purchasing practices or sourced from e-waste
                  recycling facilities that do not have an environmental policy.
                </Typography>
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: {
                      sm: "repeat(1, 1fr)",
                      md: "repeat(1, 1fr)",
                      lg: "repeat(1, 1fr)",
                      xl: "repeat(1, 1fr)",
                    },
                  }}
                >
                  {formFields.otherFields &&
                    formFields.otherFields.map((field) => (
                      <React.Fragment key={field.name}>
                        {field.template === "text"
                          ? InputField(field)
                          : field.template === "phone"
                          ? InputField(field)
                          : field.template === "dropdown"
                          ? SelectField(field)
                          : field.template === "radio"
                          ? RadioButtonField(field)
                          : field.template === "textarea"
                          ? InputAreaField(field)
                          : field.template === "checkbox"
                          ? CheckboxField(field)
                          : ""}
                      </React.Fragment>
                    ))}
                  <Typography>
                    To complete the application process, you MUST provide a copy
                    of your business license. You may upload a copy here, email
                    it to info@hooverandstrong.com, or fax it to 800-616-9997.
                  </Typography>
                  <Typography variant="subtitle1" sx={{ marginTop: "1rem" }}>
                    Failure to submit your business license within two (2)
                    business days may result in suspension of your account.
                  </Typography>
                  <Paper
                    variant="outlined"
                    sx={{ padding: "2rem", width: "50%", margin: "1rem" }}
                  >
                    <Typography variant="h6">
                      Upload Business License Here <Divider flexItem />{" "}
                      {formInfo.UploadFilename ? (
                        <Chip
                          icon={<DoneIcon />}
                          label="Uploaded"
                          color="success"
                          className="float-right"
                        />
                      ) : (
                        ""
                      )}
                    </Typography>
                    <input
                      color="primary"
                      variant="contained"
                      type="file"
                      id="file"
                      onChange={uploadBusinessLicense}
                    />
                  </Paper>
                </Box>
                <ReCAPTCHA sitekey={reCAPTCHA.site_key} ref={captchaRef} />
                <Button color="success" variant="contained" type="submit">
                  Create Account
                </Button>
              </Stack>
            </form>
          ) : (
            <Paper variant="outlined" sx={{ padding: "2rem" }}>
              <Stack spacing={2}>
                <Typography variant="h6">
                  Your Account is Being Set Up
                </Typography>
                <Typography>
                  You should receive an email in a few minutes at the address
                  you just provided. Please click the link in that message to
                  confirm your email address. When you do so, you will be logged
                  into your account, and will be prompted to set up a password.
                </Typography>
                <Typography>
                  If you do not receive the email, please let us know. We are
                  here to help you via phone Monday-Friday 9AM - 5:30PM EDT.
                  Please call 800-759-9997 toll-free. Outside the U.S. and
                  Canada, call 001.804.794.1118.
                </Typography>
                <Typography>
                  Or email us ANYTIME at info@hooverandstrong.com. It is our
                  goal to respond to your email within one business day.
                </Typography>
              </Stack>
            </Paper>
          )}
          {/* </Default> */}
        </Container>
      )}
    </CreateAccountContext.Provider>
  );
}
