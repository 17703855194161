// Built-in modules
// Third-party libraries
import React, { useContext, useEffect, useState } from "react";
import { Link as DomLink } from "react-router-dom";
import toast from "react-hot-toast";
import CloseIcon from "@mui/icons-material/Close";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  IconButton,
  Stack,
  Button,
  Typography,
  TextField,
  Checkbox,
  FormControlLabel,
  Switch,
} from "@mui/material";
import { styled } from "@mui/material/styles";

// Local modules
import {
  MeleeSearchContext,
  CartContext,
} from "../../utils/stateHandlers/contexts";
import { storage, api } from "../../utils";
import formatting from "../../utils/formatHelpers";
import CartModal from "../user/CartModal";

const CustomSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 34,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(9px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "& + .MuiSwitch-track": {
      opacity: 1,
      backgroundColor: "#e0b749",
    },
    "&.Mui-checked": {
      transform: "translateX(12px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: "#4280aa",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 16 / 2,
    opacity: 1,
    boxSizing: "border-box",
  },
}));

const meleeCtw = [
  { display: "0.01", match: "0.005" },
  { display: "0.015", match: "0.007" },
  { display: "0.015", match: "0.0075" },
  { display: "0.02", match: "0.01" },
  { display: "0.03", match: "0.015" },
  { display: "0.04", match: "0.02" },
  { display: "0.05", match: "0.025" },
  { display: "0.06", match: "0.03" },
  { display: "0.07", match: "0.035" },
  { display: "0.08", match: "0.04" },
  { display: "1/10 (0.10)", match: "0.05" },
  { display: "1/8 (0.11-0.13)", match: "0.06" },
  { display: "1/7 (0.13-0.15)", match: "0.07" },
  { display: "1/6 (0.15-0.18)", match: "0.08" },
  { display: "1/5 (0.18-0.22)", match: "0.10" },
  { display: "1/4 (0.23-0.28)", match: "0.12" },
  { display: "1/3 (0.28-0.34)", match: "0.15" },
  { display: "3/8 (0.36-0.44)", match: "0.20" },
  { display: "1/2 (0.46-0.56)", match: "0.25" },
  { display: "2/3 (0.59-0.68)", match: "0.33" },
  { display: "3/4 (0.70-0.79)", match: "0.37" },
  { display: "1 (0.96-1.10)", match: "0.50" },
];

export default function MeleeAddToCartPreview(props) {
  const { meleeState, meleeSearchDispatch } = useContext(MeleeSearchContext);
  const { cartPreview, openCartPreview, matchedPairs } = meleeState;
  const { cartDispatch } = useContext(CartContext);
  const [specialInstructions, setSpecialInstructions] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [terms, setTerms] = useState(false);
  const [partcode, setPartcode] = useState();
  const [quantityUnit, setQuantityUnit] = useState("ea");
  const [each, setEach] = useState("");
  const [carat, setCarat] = useState("");
  const [openCartmodal, setOpenCartModal] = useState(false);
  const [errorMessages, setErrorMessages] = useState([]);

  function getTotalCaratWeight(caratRange) {
    const splitRange = caratRange.split(" ")[0];
    const totalCaratWeight = meleeCtw.find(function (ctw) {
      return ctw.match === splitRange;
    });
    return totalCaratWeight ? totalCaratWeight.display : "";
  }

  function updateEstimatedPrice(price) {
    let singlePrice = Number(formatting["strip"](price));
    let newPrice = singlePrice * each;
    return formatting["price2"](newPrice);
  }

  function updatePairEstimatedPrice(price) {
    let singlePrice = Number(formatting["strip"](price)) / 2;
    let newPrice = singlePrice * each;
    return formatting["price2"](newPrice);
  }

  function addToCart() {
    setErrorMessages([]);
    if (matchedPairs) {
      const errors = [];
      const integerQty = parseInt(each);
      if (integerQty % 2 !== 0) {
        errors.push("Quantity MUST be a multiple of 2.");
        setErrorMessages(errors);
        return;
      }
    }
    setOpenCartModal(false);
    if (terms) {
      const addToCartBody = {
        cart_id:
          storage.getStorageItem("cartId") === "null" ||
          storage.getStorageItem("cartId") === "undefined"
            ? localStorage.removeItem("cartId")
            : storage.getStorageItem("cartId"),
        partcode: {
          material: partcode.material,
          product: partcode.product,
          dimension: partcode.dimension,
          finger_size: "",
          quantity: each,
          quantity_unit: "EA",
          carat_size: partcode.carat_size,
          Quantity: {
            ea: each.toString(),
            ct: carat,
          },
          quantity_unit_provided: quantityUnit,
          message: matchedPairs
            ? `Matched Pairs. Number of Pairs: ${
                each / 2
              }. ${specialInstructions}`
            : specialInstructions,
        },
      };
      api
        .post("cart/add", addToCartBody)
        .then((res) => {
          if (res.isAxiosError) {
            const errorMessage = [];
            res.response.data.errors.forEach(function (error) {
              errorMessage.push(error);
            });
            toast.error(
              `There was an error processing your request. ${errorMessage.toString()}`
            );
          } else {
            cartDispatch({
              type: "updateCart",
              payload: {
                cart: res.data.items,
                cartCount: res.data.count,
              },
            });
            storage.setStorageItem("cartId", res.data.cart_id);
            setOpenCartModal(true);
            setTerms(false);
          }
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    } else {
      setShowAlert(true);
    }
  }

  function convert(number) {
    if (quantityUnit === "ea") {
      const convertBody = {
        id: cartPreview.id,
        quantity: number,
        unit: "EA",
      };
      api.post("melee/convert", convertBody).then((res) => {
        setCarat(res.data.quantity);
      });
    } else {
      const convertBody = {
        id: cartPreview.id,
        quantity: number,
        unit: "CT",
      };
      api.post("melee/convert", convertBody).then((res) => {
        setEach(res.data.quantity);
      });
    }
  }

  useEffect(() => {
    api.fetch(`melee/form/${cartPreview.id}`).then((res) => {
      const eachField = res.data.fields.find(function (field) {
        return field.name === "Quantity.ea";
      });
      const caratField = res.data.fields.find(function (field) {
        return field.name === "Quantity.ct";
      });
      setEach(matchedPairs ? 2 : eachField.default);
      setCarat(caratField.default);
      setPartcode(res.data.partcode);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cartPreview]);

  return (
    <>
      <Dialog
        open={openCartPreview}
        onClose={() => {
          meleeSearchDispatch({ type: "setOpenCartPreview", payload: false });
          meleeSearchDispatch({ type: "setCartPreview", payload: {} });
        }}
        onBackdropClick={() => {
          meleeSearchDispatch({ type: "setOpenCartPreview", payload: false });
          meleeSearchDispatch({ type: "setCartPreview", payload: {} });
        }}
        maxWidth="sm"
        fullWidth={true}
      >
        <DialogTitle sx={{ backgroundColor: "#cfdde9", fontSize: "1.8rem" }}>
          {cartPreview.Collection} | {cartPreview.Shape} | {cartPreview.Color}{" "}
          {cartPreview.Clarity}
          <IconButton
            aria-label="close"
            onClick={() => {
              meleeSearchDispatch({
                type: "setOpenCartPreview",
                payload: false,
              });
              meleeSearchDispatch({
                type: "setCartPreview",
                payload: {},
              });
            }}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Stack spacing={3} sx={{ marginTop: "1rem" }}>
            {matchedPairs && (
              <Typography align="center" variant="subtitle1">
                You are ordering Matched Pairs.
              </Typography>
            )}
            {matchedPairs && (
              <Typography>
                <strong>CTW:</strong>{" "}
                {getTotalCaratWeight(cartPreview.CaratRange)}
              </Typography>
            )}
            <Typography>
              <strong>Carat Range:</strong> {cartPreview.CaratRange}
            </Typography>
            <Typography>
              <strong>MM Range:</strong> {cartPreview.MmRange}
            </Typography>
            {matchedPairs ? (
              <>
                <Typography>
                  Matched pair quantities are only available in multiples of 2.
                  Please enter in the amount that you would like to purchase:
                </Typography>
                <Stack direction="row">
                  <Typography>Quantity (EA)</Typography>
                  <TextField
                    color="primary"
                    type="number"
                    size="small"
                    sx={{
                      width: "100%",
                      margin: "unset",
                    }}
                    value={each}
                    onChange={(e) => {
                      setEach(e.target.value);
                      convert(e.target.value);
                    }}
                  />
                </Stack>
                <Stack spacing={1}>
                  {errorMessages.length === 0 && (
                    <Typography variant="subtitle2">
                      Quantity must be a multiple of two (2).
                    </Typography>
                  )}
                  {errorMessages.map((error) => (
                    <Typography key={error} variant="subtitle1">
                      {error}
                    </Typography>
                  ))}
                </Stack>
              </>
            ) : (
              <>
                <Typography>
                  Please enter either the amount each or the carat total weight
                  that you would like to purchase:
                </Typography>
                <Stack
                  direction="row"
                  spacing={1}
                  alignItems="center"
                  justifyContent="center"
                >
                  <Typography>Each</Typography>
                  <CustomSwitch
                    value={quantityUnit}
                    onChange={(event) =>
                      setQuantityUnit(event.target.checked ? "ct" : "ea")
                    }
                  />
                  <Typography>Carat Weight</Typography>
                </Stack>
                <Stack direction="row">
                  <Typography>Quantity (EA)</Typography>
                  <TextField
                    disabled={quantityUnit === "ea" ? false : true}
                    color="primary"
                    type="number"
                    size="small"
                    sx={{
                      width: "100%",
                      margin: "unset",
                    }}
                    value={each}
                    onChange={(e) => {
                      setEach(e.target.value);
                      convert(e.target.value);
                    }}
                  />
                </Stack>
                <Stack direction="row">
                  <Typography>Quantity (CT)</Typography>
                  <TextField
                    disabled={quantityUnit === "ct" ? false : true}
                    color="primary"
                    type="number"
                    size="small"
                    sx={{
                      width: "100%",
                      margin: "unset",
                    }}
                    value={carat}
                    onChange={(e) => {
                      setCarat(e.target.value);
                      convert(e.target.value);
                    }}
                  />
                </Stack>
              </>
            )}
            <Typography variant="subtitle2">
              Every effort is made to provide stones matching in size and
              quality. If a specific mm size is required, please provide the
              0.10mm range in the Special Instructions box below (i.e.
              1.90-2.00mm). Order will be billed according to actual carat
              weight provided.
            </Typography>
            {matchedPairs && (
              <Typography>
                <strong>Number of Pairs:</strong> {each / 2}
              </Typography>
            )}
            <Typography>
              <strong>Estimated Price:</strong>{" "}
              {matchedPairs
                ? updatePairEstimatedPrice(cartPreview.EstimatedPrice)
                : updateEstimatedPrice(cartPreview.EstimatedPrice)}
            </Typography>
            <FormControlLabel
              label={
                <Typography variant="cellFont">
                  I have read and agree to the{" "}
                  <DomLink
                    to={{ pathname: "/links/Company-Policies" }}
                    target="_blank"
                    rel="noreferrer"
                    className="underline"
                  >
                    Terms & Conditions
                  </DomLink>{" "}
                  *
                </Typography>
              }
              control={
                <Checkbox
                  checked={terms}
                  onChange={() => setTerms(terms ? false : true)}
                />
              }
            />
            <TextField
              label="Special Instructions"
              multiline
              fullWidth
              rows={3}
              value={specialInstructions}
              onChange={(e) => setSpecialInstructions(e.target.value)}
            />
          </Stack>
          <Button
            className="float-right"
            variant="contained"
            color="success"
            onClick={() => addToCart()}
          >
            Add to Cart
          </Button>
        </DialogContent>
      </Dialog>
      <CartModal
        cartMessage="Your Melee order has been successfully added to your cart!"
        errors={errorMessages}
        open={openCartmodal}
        close={setOpenCartModal}
        product={true}
      />
      <Dialog
        open={showAlert}
        onClose={() => setShowAlert(false)}
        onBackdropClick={() => setShowAlert(false)}
      >
        <DialogTitle sx={{ fontSize: "1.6rem", fontWeight: "bold" }}>
          {`Terms & Conditions`}
          <IconButton
            aria-label="close"
            onClick={() => setShowAlert(false)}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Typography gutterBottom sx={{ fontSize: "1.4rem" }}>
              Please accept the terms and conditions.
            </Typography>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
}
