import React from "react";
import { Link as DomLink } from "react-router-dom";
import { functions } from "../../../utils";
import { Typography, Grid, Stack } from "@mui/material";
import formatting from "../../../utils/formatHelpers";
import placeHolder from "../../../assets/images/placeHolder.png";
import { storage } from "../../../utils";
function ProductResults(props) {
  const { searchResults, productPage, searchTerm } = props;
  const category = props.category;
  function storeRecentlyViewed(style) {
    const previous = JSON.parse(
      storage.getSessionStorageItem("hsRecentlyViewedProducts")
    );
    if (previous !== null) {
      const viewedArray = [...previous, style];
      const uniqueViewArray = viewedArray.filter((item, index, array) => {
        return index === array.findIndex((obj) => obj.style === item.style);
      });
      storage.setSessionStorageItem(
        "hsRecentlyViewedProducts",
        JSON.stringify(uniqueViewArray)
      );
    } else if (previous === null) {
      storage.setSessionStorageItem(
        "hsRecentlyViewedProducts",
        JSON.stringify([style])
      );
    }
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Stack direction={"row"} spacing={2}>
          {productPage && productPage.length > 0 ? (
            <>
              <Typography>Try these results:</Typography>
              {productPage.map((result, index) => (
                <DomLink to={result.url} key={index}>
                  <Typography variant="h3">{result.title}</Typography>
                </DomLink>
              ))}
            </>
          ) : null}
        </Stack>
      </Grid>
      {searchResults && searchResults.length > 0 ? (
        searchResults.map((result, index) => (
          <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
            <div
              className="all-tiles"
              key={result.title}
              onClick={() => storeRecentlyViewed(result.style)}
            >
              {result.style && (
                <DomLink
                  to={{
                    pathname: `/product/${result.title}`,
                    state: {
                      isMill:
                        category === "Solder" ||
                        category === "Wire" ||
                        category === "Tubing"
                          ? true
                          : false,
                      showBAR:
                        category === "All Settings" || category === "All Shanks"
                          ? true
                          : false,
                      productGroup: category,
                      mtoConfiguratorProduct:
                        result.style.mtoConfiguratorProduct,
                      recentlyViewed: JSON.parse(
                        storage.getSessionStorageItem(
                          "hsRecentlyViewedProducts"
                        )
                      ),
                    },
                  }}
                >
                  {result.style.images.length === 0 ? (
                    <img
                      className="mainImg"
                      readOnly={true}
                      src={placeHolder}
                      alt=""
                      onError={(e) => {
                        e.target.src = placeHolder;
                      }}
                    />
                  ) : (
                    <img
                      className="mainImg"
                      readOnly={true}
                      src={result.style.images[0]}
                      alt=""
                      onError={(e) => {
                        e.target.src = placeHolder;
                      }}
                    />
                  )}
                  <br />
                  <Stack>
                    <Typography
                      className="style-text"
                      variant="body2"
                      sx={{ fontSize: "1.6rem", fontWeight: "800" }}
                    >
                      {result.style.style}
                    </Typography>
                    <Typography className="style-text">
                      {formatting["decodeHtml"](result.style.description)}
                    </Typography>
                    <Stack className="icon-div" spacing={1} direction="row">
                      {result.style.icons.length === 0 ? (
                        <Typography className="icons" />
                      ) : (
                        result.style.icons.map((icons, key) => {
                          return (
                            <Typography key={icons} className="icons">
                              {functions.getIcons(icons)}
                            </Typography>
                          );
                        })
                      )}
                    </Stack>
                  </Stack>
                </DomLink>
              )}
            </div>
          </Grid>
        ))
      ) : productPage.length <= 0 ? (
        <Typography sx={{ margin: "12px" }}>
          No results found for: "{searchTerm}" - Try another search
        </Typography>
      ) : null}
    </Grid>
  );
}

export default ProductResults;
