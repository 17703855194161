import React from "react";
import { Chip, Typography, styled } from "@mui/material";

const StyledChip = styled(Chip)(({ theme }) => ({
  width: "90%",
  padding: "1rem",
  "&:hover": {
    backgroundColor: theme.palette.primary.dark,
  },
}));

function ChipButton({ label, tabValue, selectedTab, setTab, learnMore }) {
  return (
    <StyledChip
      label={<Typography variant="diamondTabFont">{label}</Typography>}
      variant={selectedTab === tabValue || learnMore ? "filled" : "outlined"}
      color="primary"
      onClick={() => {
        setTab(tabValue);
        window.scrollTo(0, 0);
      }}
    />
  );
}

export default ChipButton;
