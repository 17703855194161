// Third-party libraries
import React, { useContext, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Link as DomLink } from "react-router-dom";
import parse from "html-react-parser";
import HelpIcon from "@mui/icons-material/Help";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import CameraAlt from "@mui/icons-material/CameraAlt";
import LocalShippingTwoToneIcon from "@mui/icons-material/LocalShippingTwoTone";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Chip,
  Button,
  Stack,
  Typography,
  Divider,
  FormControlLabel,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Grid,
} from "@mui/material";

// Local modules
import ShapeFilter from "./controls/filters/ShapeFilter";
import MinedOrLab from "./MinedOrLab";
import { Checkboxes, Range, RangeNominal } from "./controls/filters";
import CustomSwitch from "./styles/CustomSwitch";
import { DiamondSearchContext } from "../../utils/stateHandlers/contexts";
import FancyColorButton from "./FancyColorButton";

function AccordionDiamondFilter(props) {
  const { diamondState, dispatch } = useContext(DiamondSearchContext);
  const { showFancyColorFilter, fancyColorFilters } = diamondState;
  // eslint-disable-next-line
  const [showContactModal, setShowContactModal] = useState(false);
  const sendTo = useLocation();

  const {
    switchToFancyColors,
    updateNominalRange,
    valueForColor,
    resetFilters,
    setLocalFcIntensity,
  } = props;

  const DiamondColors = () => (
    <Grid>
      <Grid item xs={12}>
        <FancyColorButton
          color={"White"}
          switchToFancyColors={switchToFancyColors}
        />
      </Grid>
      <Grid item xs={12} textAlign={"center"}>
        <Typography
          sx={{
            alignContent: "center",
            marginLeft: "2rem",
            marginRight: "2rem",
            fontWeight: "bold",
          }}
        >
          OR
        </Typography>
      </Grid>
      <Grid item xs={2}>
        <FancyColorButton
          color={"Yellow"}
          switchToFancyColors={switchToFancyColors}
        />
      </Grid>
      <Grid item xs={2}>
        <FancyColorButton
          color={"Pink"}
          switchToFancyColors={switchToFancyColors}
        />
      </Grid>
      <Grid item xs={2}>
        <FancyColorButton
          color={"Blue"}
          switchToFancyColors={switchToFancyColors}
        />
      </Grid>
      <Grid item xs={2}>
        <FancyColorButton
          color={"Green"}
          switchToFancyColors={switchToFancyColors}
        />
      </Grid>
      <Grid item xs={2}>
        <FancyColorButton
          color={"Brown"}
          switchToFancyColors={switchToFancyColors}
        />
      </Grid>
    </Grid>
  );

  useEffect(() => {
    setLocalFcIntensity(["None"]);
    dispatch({
      type: "setShowFancyColorFilter",
      payload: false,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetFilters]);

  return (
    <>
      <Stack spacing={1} alignItems="center">
        <Typography>
          Need help finding the perfect diamond? &nbsp;
          <span
            className="contact-us pointer"
            onClick={() => setShowContactModal(true)}
          >
            Contact Us
          </span>
        </Typography>
        <MinedOrLab
          valueForColor={props.valueForColor}
          clearFilters={props.clearFilters}
        />
        {diamondState.filters &&
          diamondState.filters.map((filter) => (
            <Accordion
              key={filter.name}
              square={true}
              disableGutters={true}
              sx={{ width: "100%", minHeight: "unset", paddingRight: "1rem" }}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography
                  sx={{
                    textTransform: "uppercase",
                  }}
                >
                  {filter.label}
                  {filter.label === "Source" || filter.label === "Features" ? (
                    <DomLink
                      to={{
                        pathname: "/sub-landing/Diamond-Sources",
                      }}
                      target="_blank"
                    >
                      <HelpIcon className="help-icon question-mark" />
                    </DomLink>
                  ) : (
                    <>
                      {filter.label !== "Price" && (
                        <HelpIcon
                          className="help-icon question-mark"
                          onClick={() =>
                            dispatch({
                              type: "setOpenHelpText",
                              payload: {
                                open: true,
                                label: filter.label,
                                text: parse(filter.help_text),
                              },
                            })
                          }
                        />
                      )}
                    </>
                  )}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                {filter.type === "options" && filter.label === "Shape" ? (
                  <ShapeFilter />
                ) : filter.type === "options" && filter.label !== "Shape" ? (
                  <Checkboxes
                    search={diamondState.searchQuery}
                    filter={filter}
                    resetFilters={diamondState.resetFilters}
                    updateCheckedBoxes={props.updateCheckedBoxes}
                  />
                ) : filter.type === "enum" && filter.name === "Color" ? (
                  <>
                    <DiamondColors />
                    {showFancyColorFilter ? (
                      <RangeNominal
                        filter={fancyColorFilters[1]}
                        resetFilters={resetFilters}
                        updateNominalRange={updateNominalRange}
                        color={valueForColor === "M" ? "primary" : "success"}
                      />
                    ) : (
                      <RangeNominal
                        filter={filter}
                        resetFilters={resetFilters}
                        updateNominalRange={updateNominalRange}
                        color={valueForColor === "M" ? "primary" : "success"}
                      />
                    )}
                  </>
                ) : filter.type === "enum" ? (
                  <>
                    <RangeNominal
                      filter={filter}
                      resetFilters={resetFilters}
                      updateNominalRange={updateNominalRange}
                      color={valueForColor === "M" ? "primary" : "success"}
                    />
                    {filter.name === "Color" && (
                      <FormControlLabel
                        sx={{
                          marginTop: "1rem",
                          paddingTop: ".5rem",
                        }}
                        control={
                          <CustomSwitch
                            id="ShowFancyColorFilter"
                            type="checkbox"
                            sx={{ marginLeft: "1rem" }}
                            checked={showFancyColorFilter}
                            onChange={(e) =>
                              switchToFancyColors(e.target.value)
                            }
                          />
                        }
                        label={
                          <Typography
                            variant="fancyFilterFont"
                            sx={{
                              paddingLeft: "1rem",
                              textTransform: "capitalize",
                            }}
                          >
                            FANCY
                          </Typography>
                        }
                      />
                    )}
                  </>
                ) : filter.type === "numeric" &&
                  filter.name !== "HooverPrice" ? (
                  <Range
                    filter={filter}
                    resetFilters={diamondState.resetFilters}
                    updateRange={props.updateRange}
                    color={props.valueForColor === "M" ? "primary" : "success"}
                  />
                ) : filter.name === "HooverPrice" && !props.loggedIn ? (
                  <DomLink
                    to={{
                      pathname: "/login",
                      state: { sendTo: sendTo.pathname },
                    }}
                  >
                    <Button variant="contained" color="error">
                      Login For pricing
                    </Button>
                  </DomLink>
                ) : filter.name === "HooverPrice" && props.loggedIn ? (
                  <Range
                    filter={filter}
                    resetFilters={diamondState.resetFilters}
                    updateRange={props.updateRange}
                    color={props.valueForColor === "M" ? "primary" : "success"}
                  />
                ) : null}
              </AccordionDetails>
            </Accordion>
          ))}
        {diamondState.showAdvancedFilters && (
          <>
            {diamondState.advancedFilters &&
              diamondState.advancedFilters.map((filter) => (
                <Accordion
                  square={true}
                  disableGutters={true}
                  sx={{
                    width: "100%",
                    minHeight: "unset",
                    paddingRight: "1rem",
                  }}
                >
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography
                      sx={{
                        textTransform: "uppercase",
                      }}
                    >
                      {filter.label}
                      {filter.label === "Source" ||
                      filter.label === "Features" ? (
                        <DomLink
                          to={{
                            pathname: "/sub-landing/Diamond-Sources",
                          }}
                          target="_blank"
                        >
                          <HelpIcon className="help-icon question-mark" />
                        </DomLink>
                      ) : (
                        <>
                          {filter.label !== "Price" && (
                            <HelpIcon
                              className="help-icon question-mark"
                              onClick={() =>
                                dispatch({
                                  type: "setOpenHelpText",
                                  payload: {
                                    open: true,
                                    label: filter.label,
                                    text: parse(filter.help_text),
                                  },
                                })
                              }
                            />
                          )}
                        </>
                      )}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    {filter.type === "options" && filter.label === "Shape" ? (
                      <ShapeFilter />
                    ) : filter.type === "options" &&
                      filter.label !== "Shape" ? (
                      <Checkboxes
                        search={diamondState.searchQuery}
                        filter={filter}
                        resetFilters={diamondState.resetFilters}
                        updateCheckedBoxes={props.updateCheckedBoxes}
                      />
                    ) : filter.type === "enum" ? (
                      <RangeNominal
                        filter={filter}
                        resetFilters={diamondState.resetFilters}
                        updateNominalRange={props.updateNominalRange}
                        color={
                          props.valueForColor === "M" ? "primary" : "success"
                        }
                      />
                    ) : filter.type === "numeric" ? (
                      <Range
                        filter={filter}
                        resetFilters={diamondState.resetFilters}
                        updateRange={props.updateRange}
                        color={
                          props.valueForColor === "M" ? "primary" : "success"
                        }
                      />
                    ) : null}
                  </AccordionDetails>
                </Accordion>
              ))}
          </>
        )}
        <Stack spacing={1} sx={{ paddingBottom: "2rem" }}>
          <FormControlLabel
            control={
              <CustomSwitch
                id="ImageVideoAvailable"
                type="checkbox"
                sx={{ marginLeft: "1rem" }}
                onChange={(e) =>
                  props.updateCheckedBoxes("ImageVideoAvailable", {
                    name: "ImageVideoAvailable",
                    checked: e.target.checked ? "Y" : "N",
                  })
                }
              />
            }
            label={
              <Typography
                variant="body3"
                sx={{ fontWeight: "800", marginLeft: "2rem" }}
              >
                Image/Video Available <CameraAlt color="primary" />
              </Typography>
            }
          />
          {diamondState.searchQuery.fields.find((f) => f.name === "MinedOrLab")
            .values[0].name === "L" && (
            <FormControlLabel
              control={
                <CustomSwitch
                  id="ShipsSameDay"
                  type="checkbox"
                  onClick={(e) =>
                    props.updateCheckedBoxes("ShipsSameDay", {
                      name: "ShipsSameDay",
                      checked: e.target.checked ? "Y" : "N",
                    })
                  }
                />
              }
              label={
                <Typography variant="subtitle1" sx={{ marginLeft: "2rem" }}>
                  Ships Same Day <LocalShippingTwoToneIcon color="error" />
                </Typography>
              }
            />
          )}
        </Stack>
      </Stack>
      <Divider flexItem sx={{ marginBottom: "2rem" }}>
        {!diamondState.showAdvancedFilters ? (
          <Chip
            color={props.valueForColor === "M" ? "primary" : "success"}
            label="Show More Filters"
            icon={<ArrowDropDownIcon />}
            onClick={() =>
              dispatch({ type: "showAdvancedFilters", payload: true })
            }
          />
        ) : (
          <Chip
            color={props.valueForColor === "M" ? "primary" : "success"}
            label="Show Less Filters"
            icon={<ArrowDropUpIcon />}
            onClick={() =>
              dispatch({ type: "showAdvancedFilters", payload: false })
            }
          />
        )}
      </Divider>
    </>
  );
}

export default AccordionDiamondFilter;
