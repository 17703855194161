// Third-party libraries
import React from "react";
import { Link as DomLink, useLocation } from "react-router-dom";
import Button from "@mui/material/Button";

export default function GALinkButton(props) {
  const location = useLocation();

  return (
    <DomLink
      to={{
        pathname: props.pathTo,
        state: props.state,
      }}
      onClick={() => {
        window.gtag("event", props.gaEventName, {
          env: process.env.NODE_ENV,
          page_path: location.pathname + location.search + location.hash,
          directing_to: props.pathTo,
        });
      }}
    >
      <Button
        variant={props.variant}
        color={props.color}
        size={props.size}
        sx={props.styleInfo}
      >
        {props.buttonText}
      </Button>
    </DomLink>
  );
}
