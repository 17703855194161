// Third-party libraries
import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
  Typography,
} from "@mui/material";

// Local modules
import GALinkButton from "../../utils/GAComponents/GALinkButton";
import GAButton from "../../utils/GAComponents/GAButton";

export default function CartModal(props) {
  const handleClose = () => {
    props.close(false);
  };

  return (
    <Dialog open={props.open} onClose={handleClose}>
      <>
        {props.errors && props.errors.length > 0 ? (
          <>
            <DialogTitle>Cannot Add to Cart</DialogTitle>
            <DialogContent>
              <DialogContentText>
                {props.errors &&
                  props.errors.map((message) => (
                    <Typography key={message} variant="subtitle1">
                      {message}
                    </Typography>
                  ))}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Stack direction="row" spacing={2}>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={handleClose}
                >
                  Close
                </Button>
              </Stack>
            </DialogActions>
          </>
        ) : (
          <>
            <DialogTitle>Add to Cart Successful!</DialogTitle>
            <DialogContent>
              <DialogContentText>
                {props.cartMessage
                  ? props.cartMessage
                  : "This item has successfully been added to your cart."}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Stack direction="row" spacing={2}>
                <GAButton
                  loadingButton={false}
                  variant="contained"
                  color="primary"
                  size="small"
                  onClickFunction={handleClose}
                  buttonText="Continue Shopping"
                  gaEventName="continue_shopping_button"
                />
                <GALinkButton
                  pathTo="/cart"
                  color="primary"
                  variant="contained"
                  buttonText="Go To Cart"
                  gaEventName="go_to_cart_button"
                />
              </Stack>
            </DialogActions>
          </>
        )}
      </>
    </Dialog>
  );
}
