// Third-party libraries
import React, { useContext, useState, useEffect } from "react";
import { Stack, Typography, TextField } from "@mui/material";

// Local modules
import { ProductsContext } from "../../../../utils/stateHandlers/contexts";

export default function QuantityPieces(props) {
  const { productState, productDispatch } = useContext(ProductsContext);
  const { priceLoaded } = productState;
  const [localInput, setLocalInput] = useState(props.field.default);
  const field = props.field;

  const handleInputChange = (e) => {
    setLocalInput(e.target.value);
  };

  useEffect(() => {
    if (localInput !== props.field.default) {
      const timeoutId = setTimeout(
        () =>
          productDispatch({
            type: "updateFields",
            field: field.name,
            payload: localInput,
          }),
        1000
      );
      return () => clearTimeout(timeoutId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localInput]);

  return (
    <Stack>
      <Typography
        sx={{
          fontWeight: "700",
          paddingBottom: ".3rem",
        }}
      >
        Pieces
      </Typography>
      <TextField
        color="primary"
        disabled={!priceLoaded}
        onChange={(e) => {
          handleInputChange(e);
        }}
        size="small"
        sx={{
          width: "100%",
          margin: "unset",
        }}
        value={localInput}
      />
    </Stack>
  );
}
