// Third-party libraries
import React, { useEffect, useReducer } from "react";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";
import remarkGfm from "remark-gfm";
import JotformEmbed from "react-jotform-embed";
import { Container, Typography, Stack } from "@mui/material";

// Local modules
import { FormContext } from "../../../utils/stateHandlers/contexts";
import { formReducer } from "../../../utils/stateHandlers/reducers";
import states from "../../../utils/stateHandlers/initialStates";
import { api } from "../../../utils";
import { Mobile, Default } from "../../../utils/deviceHelpers";

export default function CZFrostingForm(props) {
  const [formState, dispatch] = useReducer(
    formReducer,
    states.initialFormState
  );
  const { formtext, formtitle } = formState;

  useEffect(() => {
    window.scrollTo(0, 0);
    api.fetchStrapi(`/forms/8?populate=*`).then((response) => {
      dispatch({
        type: "setFormCopy",
        payload: {
          formtitle: response.data.data.attributes.formtitle,
          formtext: response.data.data.attributes.formtext,
        },
      });
    });
  }, []);

  return (
    <FormContext.Provider value={{ formState, dispatch }}>
      <Container maxWidth="xl" className="careers-text">
        <Mobile>
          <Typography
            textAlign="center"
            className="center"
            variant="articleTitle"
          >
            {formtitle}
          </Typography>
          <Typography variant="staticText" align="center">
            <ReactMarkdown children={formtext} remarkPlugins={[remarkGfm]} />
          </Typography>
          <JotformEmbed src="https://form.jotform.com/230371708073149" />
        </Mobile>
        <Default>
          <Stack spacing={2}>
            <Typography
              textAlign="center"
              variant="articleTitle"
              className="center"
            >
              {formtitle}
            </Typography>
            <Typography variant="staticText" align="center">
              <ReactMarkdown children={formtext} remarkPlugins={[remarkGfm]} />
            </Typography>
            <JotformEmbed src="https://form.jotform.com/230371708073149" />
          </Stack>
        </Default>
      </Container>
    </FormContext.Provider>
  );
}
