// Third-party libraries
import React, { useContext } from "react";
import Select from "react-select";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Box,
  Grid,
  Stack,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Divider,
  Chip,
} from "@mui/material";

// Local modules
import ShapeFilter from "./MeleeShapeFilter";
import {
  CanadaIcon,
  RecycleIcon,
  LabGrownIcon,
  MinedIcon,
  BeakerIcon,
  SignatureIcon,
} from "../Icons";
import { MeleeSearchContext } from "../../utils/stateHandlers/contexts";

function AccordionMeleeFilter(props) {
  const { meleeState, meleeSearchDispatch } = useContext(MeleeSearchContext);
  const { filters, searchQuery, collections, matchedPairs } = meleeState;

  function updateFilters(filterName, filterValues) {
    const filterToUpdate = searchQuery.fields.find(function (field) {
      return field.name === filterName;
    });
    if (filterToUpdate) {
      filterToUpdate.values =
        filterName !== "Quality" ? [filterValues] : filterValues;
      meleeSearchDispatch({
        type: "updateSearchQuery",
        payload: searchQuery.fields,
      });
    } else {
      const fields = [
        ...searchQuery.fields,
        {
          name: filterName,
          values: filterName !== "Quality" ? [filterValues] : filterValues,
        },
      ];
      meleeSearchDispatch({ type: "updateSearchQuery", payload: fields });
    }
  }

  return (
    <Stack spacing={1} alignItems="center">
      {filters &&
        filters.map((filter, index) => (
          <React.Fragment key={index}>
            {filter.name === "ShapeCode" ? (
              <Accordion
                square={true}
                disableGutters={true}
                sx={{
                  width: "100%",
                  minHeight: "unset",
                  paddingRight: "1rem",
                }}
              >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography>{filter.label}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {filter.type === "options" && filter.label === "Shape" ? (
                    <ShapeFilter updateFilters={updateFilters} />
                  ) : null}
                </AccordionDetails>
              </Accordion>
            ) : filter.name === "CaratRange" ? (
              <Accordion
                square={true}
                disableGutters={true}
                sx={{
                  width: "100%",
                  minHeight: "unset",
                  paddingRight: "1rem",
                }}
              >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography>{filter.label}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Select
                    key={filter.name}
                    value={props.caratSelect}
                    onChange={(e) => {
                      props.setCaratSelect(e);
                      props.updateDropdownFilters(filter.name, e);
                    }}
                    options={filter.values}
                    //isClearable
                    theme={(theme) => ({
                      ...theme,
                      borderRadius: 0,
                      colors: {
                        ...theme.colors,
                        primary25: "#cfdde9",
                        primary: "#124760",
                      },
                    })}
                  />
                </AccordionDetails>
              </Accordion>
            ) : filter.name === "MmRange" ? (
              <Accordion
                square={true}
                disableGutters={true}
                sx={{
                  width: "100%",
                  minHeight: "unset",
                  paddingRight: "1rem",
                }}
              >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography>{filter.label}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Select
                    key={filter.name}
                    value={props.mmSelect}
                    onChange={(e) => {
                      props.setMmSelect(e);
                      props.updateDropdownFilters(filter.name, e);
                    }}
                    options={filter.values}
                    //isClearable
                    theme={(theme) => ({
                      ...theme,
                      borderRadius: 0,
                      colors: {
                        ...theme.colors,
                        primary25: "#cfdde9",
                        primary: "#124760",
                      },
                    })}
                  />
                </AccordionDetails>
              </Accordion>
            ) : null}
          </React.Fragment>
        ))}
      <Accordion
        square={true}
        disableGutters={true}
        sx={{
          width: "100%",
          minHeight: "unset",
          paddingRight: "1rem",
        }}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>Collection</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Divider flexItem sx={{ marginBottom: "2rem" }}>
            <Chip
              label={<Typography variant="h6">Mined Diamonds</Typography>}
              icon={<MinedIcon size="medium" />}
              sx={{ backgroundColor: "unset" }}
            />
          </Divider>
          <Stack spacing={1}>
            <Grid container>
              <Grid item xs={6}>
                <Typography variant="headerFont" sx={{ lineHeight: "1.8rem" }}>
                  <SignatureIcon /> Signature Melee
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: {
                      sm: "repeat(1, 1fr)",
                      md: "repeat(1, 1fr)",
                    },
                  }}
                >
                  {collections.Signature &&
                    collections.Signature.map((signature) => (
                      <Chip
                        key={signature.label}
                        label={
                          <Typography variant="cellFont">
                            {signature.label}
                          </Typography>
                        }
                        sx={{
                          backgroundColor: "unset",
                          marginBottom: "0.5rem",
                        }}
                        onClick={() => props.props.clickButton(signature)}
                        color="primary"
                        variant={
                          props.buttons.find(
                            (button) => button === signature.value
                          )
                            ? "filled"
                            : "outlined"
                        }
                      />
                    ))}
                </Box>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={6}>
                <Typography variant="headerFont" sx={{ lineHeight: "1.8rem" }}>
                  <CanadaIcon /> Canadian Melee
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: {
                      sm: "repeat(1, 1fr)",
                      md: "repeat(1, 1fr)",
                    },
                  }}
                >
                  {collections.Canadian &&
                    collections.Canadian.map((canadian) => (
                      <Chip
                        key={canadian.label}
                        label={
                          <Typography variant="cellFont">
                            {canadian.label}
                          </Typography>
                        }
                        sx={{
                          backgroundColor: "unset",
                          marginBottom: "0.5rem",
                        }}
                        onClick={() => props.clickButton(canadian)}
                        color="primary"
                        variant={
                          props.buttons.find(
                            (button) => button === canadian.value
                          )
                            ? "filled"
                            : "outlined"
                        }
                      />
                    ))}
                </Box>
              </Grid>
            </Grid>
            {!matchedPairs && (
              <Grid container>
                <Grid item xs={6}>
                  <Typography
                    variant="headerFont"
                    sx={{ width: "100%", lineHeight: "1.8rem" }}
                  >
                    <RecycleIcon /> Harmony Melee
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Box
                    sx={{
                      display: "grid",
                      gridTemplateColumns: {
                        sm: "repeat(1, 1fr)",
                        md: "repeat(1, 1fr)",
                      },
                    }}
                  >
                    {collections.Harmony &&
                      collections.Harmony.map((harmony) => (
                        <Chip
                          key={harmony.label}
                          label={
                            <Typography variant="cellFont">
                              {harmony.label}
                            </Typography>
                          }
                          sx={{
                            backgroundColor: "unset",
                            marginBottom: "0.5rem",
                          }}
                          onClick={() => props.clickButton(harmony)}
                          color="primary"
                          variant={
                            props.buttons.find(
                              (button) => button === harmony.value
                            )
                              ? "filled"
                              : "outlined"
                          }
                        />
                      ))}
                  </Box>
                </Grid>
              </Grid>
            )}
          </Stack>
          <Divider flexItem sx={{ marginBottom: "2rem" }}>
            <Chip
              label={<Typography variant="h6">Lab-Grown Diamonds</Typography>}
              icon={<LabGrownIcon size="medium" />}
              sx={{ backgroundColor: "unset" }}
            />
          </Divider>
          <Grid container>
            <Grid item xs={6}>
              <Typography
                variant="headerFont"
                sx={{ width: "100%", lineHeight: "1.8rem" }}
              >
                <BeakerIcon /> Lab-Grown Melee
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: {
                    sm: "repeat(1, 1fr)",
                    md: "repeat(1, 1fr)",
                  },
                }}
              >
                {collections.Lab &&
                  collections.Lab.map((lab) => (
                    <Chip
                      key={lab.label}
                      label={
                        <Typography variant="cellFont">{lab.label}</Typography>
                      }
                      sx={{
                        backgroundColor: "unset",
                        marginBottom: "0.5rem",
                      }}
                      onClick={() => props.clickButton(lab)}
                      color="primary"
                      variant={
                        props.buttons.find((button) => button === lab.value)
                          ? "filled"
                          : "outlined"
                      }
                    />
                  ))}
              </Box>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </Stack>
  );
}

export default AccordionMeleeFilter;
