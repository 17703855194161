// Third-party libraries
import React, { useEffect, useState } from "react";
import {
  Typography,
  Button,
  Stack,
  TextField,
  InputAdornment,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";

// Local modules
import { api } from "../../utils";

//TODO: move theme to themes file when I get it created
//TODO: refactor input fields
export default function CustomMetalMarkets(props) {
  const theme = createTheme({
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            justifySelf: "center",
            cursor: "pointer",
            borderRadius: "0px",
            fontSize: "1.4rem",
          },
        },
      },
      MuiDialogTitle: {
        styleOverrides: {
          root: {
            fontFamily: "trajan-pro",
            fontSize: "2.8rem",
          },
        },
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            backgroundColor: "#9c3c2c",
            color: "white",
            paddingBottom: "2rem",
            borderRadius: 0,
          },
        },
      },
      MuiTextField: {
        styleOverrides: {
          root: {
            margin: ".5rem",
            width: "30%",
          },
        },
      },
      MuiInput: {
        styleOverrides: {
          root: {
            fontSize: "1.4rem",
            backgroundColor: "white",
            left: "4rem",
          },
        },
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            fontSize: "1.8rem",
            color: "white",
            fontFamily: "Verdana",
            left: "-4.1rem",
            top: "2rem",
          },
        },
      },
      MuiTypography: {
        styleOverrides: {
          root: {
            fontSize: "1.4rem",
            margin: "1rem",
            textAlign: "center",
            fontFamily: "Verdana",
          },
        },
      },
    },
  });

  const [open, setOpen] = useState(false);
  const [gold, setGold] = useState("0.00");
  const [silver, setSilver] = useState("0.00");
  const [platinum, setPlatinum] = useState("0.00");
  const [palladium, setPalladium] = useState("0.00");
  const [iridium, setIridium] = useState("0.00");
  const [ruthenium, setRuthenium] = useState("0.00");

  function saveCustomMarkets() {
    const apiCustomMarkets = {
      gold: gold,
      silver: silver,
      platinum: platinum,
      palladium: palladium,
      iridium: iridium,
      ruthenium: ruthenium,
    };
    api.post(`metalbase/update`, apiCustomMarkets).then((res) => {
      setOpen(false);
    });
  }

  function resetToActualMarkets() {
    api.fetch(`metalbase/reset`).then((res) => {
      props.setShowCustomMetalMarkets(false);
      setOpen(false);
    });
  }

  useEffect(() => {
    setOpen(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="center">
      <ThemeProvider theme={theme}>
        <Button
          disableRipple={true}
          className="custom-market-button"
          onClick={() => setOpen(true)}
        >
          Using Custom Metal Markets
        </Button>
        <Dialog open={open} onClose={() => setOpen(false)}>
          <DialogTitle>Set Custom Metal Markets</DialogTitle>
          <DialogContent>
            <Typography>Use this form to set custom metal markets.</Typography>
            <br />
            <Typography>
              All pricing is for estimates only. Orders are billed on date of
              receipt if stock item, or date after receipt for production.
            </Typography>
            <Stack className="center">
              <TextField
                label="Gold"
                variant="standard"
                value={gold}
                onChange={(e) => setGold(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
              />
              <TextField
                label="Silver"
                variant="standard"
                value={silver}
                onChange={(e) => setSilver(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
              />
              <TextField
                label="Platinum"
                variant="standard"
                value={platinum}
                onChange={(e) => setPlatinum(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
              />
              <TextField
                label="Palladium"
                variant="standard"
                value={palladium}
                onChange={(e) => setPalladium(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
              />
              <TextField
                label="Iridium"
                variant="standard"
                value={iridium}
                onChange={(e) => setIridium(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
              />
              <TextField
                label="Ruthenium"
                variant="standard"
                value={ruthenium}
                onChange={(e) => setRuthenium(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
              />
            </Stack>
          </DialogContent>
          <DialogActions className="center">
            <Stack direction="row" spacing={1}>
              <Button
                variant="contained"
                color="success"
                size="small"
                onClick={() => saveCustomMarkets()}
              >
                Save These Markets
              </Button>
              <Button
                variant="contained"
                color="primary"
                size="small"
                onClick={() => resetToActualMarkets()}
              >
                Reset to Daily Markets
              </Button>
            </Stack>
          </DialogActions>
        </Dialog>
      </ThemeProvider>
    </div>
  );
}
