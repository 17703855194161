// Third-party libraries
import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  Typography,
  Stack,
} from "@mui/material";

export default function CustomerTestimonials(props) {
  const [openAddTestimonial, setOpenAddTestimonial] = useState(false);
  //const [testimonials, setTestimonials] = useState([]);

  function handleSubmit() {
    //does stuff
  }

  return (
    <>
      <Stack>
        <Typography variant="h2">
          Customer Testimonials
          <span
            //href="http://technical-articles.hooverandstrong.com/wordpress/"
            //target="new"
            className="header-link"
          >
            Submit A Testimonial
          </span>
        </Typography>
        {/* Change here when testimonials are ready-- dont forget to delete the current styling */}
        <Typography sx={{ marginLeft: "1rem" }}>Coming Soon!</Typography>
      </Stack>
      <Dialog
        open={openAddTestimonial}
        onClose={() => setOpenAddTestimonial(false)}
      >
        <DialogTitle>Submit A Testimonial About This Product</DialogTitle>
        <DialogContent>
          <DialogContentText>
            To subscribe to this website, please enter your email address here.
            We will send updates occasionally.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSubmit}>Submit</Button>
          <Button onClick={() => setOpenAddTestimonial(false)}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
