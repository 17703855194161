// Third-party libraries
import React, { useEffect, useReducer, useState } from "react";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";
import remarkGfm from "remark-gfm";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import toast from "react-hot-toast";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import {
  Container,
  Typography,
  Stack,
  TextField,
  Select,
  FormControl,
  MenuItem,
  FormHelperText,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormLabel,
  FormGroup,
  Checkbox,
  Box,
  Button,
  Paper,
  Backdrop,
  CircularProgress,
  Divider,
  IconButton,
} from "@mui/material";

// Local modules
import { api, constants } from "../../../utils";
import { formReducer } from "../../../utils/stateHandlers/reducers";
import states from "../../../utils/stateHandlers/initialStates";
import { FormContext } from "../../../utils/stateHandlers/contexts";

const sxBox = {
  display: "grid",
  gridTemplateColumns: {
    sm: "repeat(1, 1fr)",
    md: "repeat(2, 1fr)",
    lg: "repeat(2, 1fr)",
    xl: "repeat(2, 1fr)",
  },
};

const schema = yup.object().shape({
  ShippingName: yup.string().required("Please Enter Your Legal Business Name"),
  TradeName: yup.string().required("Please Enter Your Trade Name"),
  Phone: yup.string().required("Please Enter Your Business Phone Number"),
  ShippingStreet1: yup.string().required("Please Enter Your Shipping Street"),
  ShippingCity: yup.string().required("Please Enter Your Shipping City"),
  ShippingState: yup
    .string()
    .test(
      "ship-state-not-selected",
      "Please Select the Shipping State",
      (value) => value !== "None"
    )
    .required("Please Enter Your Shipping State"),
  ShippingPostalCode: yup
    .string()
    .required("Please Enter Your Shipping Zip Code"),
  ShippingCountry: yup.string().required("Please Enter Your Shipping Country"),
  EmailAddress: yup.string().required("Please Enter Your Email Address"),
  Website: yup.string().required("Please Enter Your Business's Website"),
  BusinessType: yup
    .string()
    .test(
      "category-not-selected",
      "Please Select the Business Type",
      (value) => value !== "None"
    )
    .required("Please Select Your Business Type"),
  State: yup
    .string()
    .test(
      "state-of-incorporation-not-selected",
      "Please Select the State of Incorporation",
      (value) => value !== "None"
    )
    .required("Please Select the State of Incorporation"),
  YearIncorporated: yup
    .string()
    .required("Please Enter the Number of Years You Have Been in Business"),
  EstimatedSales: yup
    .string()
    .required("Please Enter The Estimated Annual Sales for Your Business"),
  TermsRequested: yup
    .string()
    .test(
      "terms-not-selected",
      "Please Select the Terms Being Requested",
      (value) => value !== "None"
    )
    .required("Please Select the Terms Being Requested"),
  JbtNumber: yup.string().required("Please Enter Your JBT Number"),
  BankingBank: yup.string().required("Please Enter the Name of Your Bank"),
  BankingContact: yup.string().required("Please Enter Your Banking Contact"),
  BankingPhone: yup.string().required("Please Enter Your Bank's Phone Number"),
  BankingStreet: yup
    .string()
    .required("Please Enter Your Bank's Street Address"),
  BankingCity: yup.string().required("Please Enter Your Bank's City"),
  BankingState: yup
    .string()
    .test(
      "banking-state-not-selected",
      "Please Select the State",
      (value) => value !== "None"
    )
    .required("Please Select the State"),
  BankingPostalCode: yup.string().required("Please Enter Your Bank's Zip Code"),
  BankingCountry: yup.string().required("Please Enter Your Bank's Country"),
  Aml: yup.string().required("Please Select Your AML Status"),
  Signature: yup
    .string()
    .required("Please Enter Your Name as an Electronic Signature"),
  Title: yup.string().required("Please Enter Your Title"),
  Date: yup.string().required("Please Select a Date"),
  Guarantor: yup.string().required("Please Enter the Name of Your Guarantor"),
  //Officers: yup.array().min(1),
  //References: yup.array().min(1),
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

export default function Net30Form(props) {
  const [formState, dispatch] = useReducer(formReducer, states);
  const {
    isLoaded,
    fields,
    formtext,
    formtitle,
    submitted,
    officers,
    references,
    officerFieldValues,
    referenceFieldValues,
    marketingFields,
  } = formState;
  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const [startDate, setStartDate] = useState(new Date());
  const [stateForTaxForm, setStateForTaxForm] = useState("");

  const DateField = (field) => (
    <Stack sx={{ margin: "1rem" }}>
      <Typography sx={{ fontWeight: "700", paddingBottom: ".3rem" }}>
        {field.label}
      </Typography>
      <span style={{ border: "1px solid #939598", padding: "0.3rem" }}>
        <DatePicker
          {...register(field.name)}
          error={errors[field.name] ? true : false}
          helperText={errors[field.name] ? errors[field.name].message : ""}
          selected={startDate}
          onChange={(date) => setStartDate(date)}
        />
      </span>
    </Stack>
  );

  const InputField = (field) => (
    <Stack sx={{ margin: "1rem" }}>
      <Typography sx={{ fontWeight: "700", paddingBottom: ".3rem" }}>
        {field.label}
      </Typography>
      <TextField
        {...register(field.name)}
        error={errors[field.name] ? true : false}
        helperText={errors[field.name] ? errors[field.name].message : ""}
        color="primary"
        size="small"
        defaultValue={field.default ? field.default : ""}
        sx={{
          width: "100%",
          margin: "unset",
        }}
      />
    </Stack>
  );

  const InputAreaField = (field) => (
    <Stack>
      <Typography sx={{ fontWeight: "700", paddingBottom: ".3rem" }}>
        {field.label}
      </Typography>
      <TextField
        {...register(field.name)}
        error={errors[field.name] ? true : false}
        helperText={errors[field.name] ? errors[field.name].message : ""}
        multiline
        fullWidth
        defaultValue={field.default ? field.default : ""}
        rows={3}
        sx={{
          width: "100%",
          margin: "unset",
        }}
      />
    </Stack>
  );

  const SelectField = (field) => (
    <Stack sx={{ margin: "1rem" }}>
      <Typography sx={{ fontWeight: "700", paddingBottom: ".3rem" }}>
        {field.label}
      </Typography>
      <FormControl
        {...register(field.name)}
        error={errors[field.name] ? true : false}
      >
        <Select
          key={field.name}
          defaultValue={field.default ? field.default : "None"}
          size="small"
          MenuProps={{
            PaperProps: {
              sx: {
                border: "1px solid #939598",
                borderRadius: "0",
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
              },
            },
          }}
          onChange={(e) => {
            setValue(field.name, e.target.value, { shouldValidate: true });
            field.name === "ShippingState" &&
              setStateForTaxForm(e.target.value);
          }}
        >
          <MenuItem value="None">
            <em>Select...</em>
          </MenuItem>
          {field.values.map((option) => (
            <MenuItem value={option.value} key={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
        <FormHelperText>
          {errors[field.name] ? errors[field.name].message : ""}
        </FormHelperText>
      </FormControl>
    </Stack>
  );

  const RadioButtonField = (field) => (
    <FormControl
      sx={{ margin: "1rem" }}
      {...register(field.name)}
      error={errors[field.name] ? true : false}
      onChange={(e) =>
        setValue(field.name, e.target.value, { shouldValidate: true })
      }
    >
      <FormLabel>
        <Typography
          sx={{ fontWeight: "700", paddingBottom: ".3rem", color: "black" }}
        >
          {field.label} *
        </Typography>
      </FormLabel>
      <RadioGroup
        sx={{
          paddingLeft: "2rem",
          border: errors[field.name] ? "1px solid #954738" : "",
        }}
      >
        {field.values &&
          field.values.map((option) => (
            <FormControlLabel
              key={option.value}
              value={option.value}
              control={<Radio />}
              label={<Typography>{option.label}</Typography>}
            />
          ))}
      </RadioGroup>
      <FormHelperText>
        {errors[field.name] ? errors[field.name].message : ""}
      </FormHelperText>
    </FormControl>
  );

  const CheckboxField = (field) => (
    <FormControl
      {...register(field.name)}
      error={errors[field.name] ? true : false}
      onChange={(e) => {
        setValue(field.name, e.target.checked, {
          shouldValidate: true,
        });
      }}
    >
      <FormGroup>
        <FormControlLabel
          sx={{
            margin: "1rem",
            border: errors[field.name] ? "1px solid #954738" : "",
          }}
          label={<Typography variant="cellFont">{field.label}</Typography>}
          control={<Checkbox />}
        />
      </FormGroup>
      <FormHelperText>
        {errors[field.name] ? errors[field.name].message : ""}
      </FormHelperText>
    </FormControl>
  );

  function addAuthBuyerField() {
    let largestIdField = officers.reduce((maxObj, currentObj) => {
      return currentObj.id > maxObj.id ? currentObj : maxObj;
    }, officers[0]);
    let newFieldSet = {
      id: largestIdField.id + 1,
      fields: largestIdField.fields,
      name: largestIdField.name,
      label: largestIdField.label,
    };
    dispatch({
      type: "setOfficerFields",
      payload: [...officers, newFieldSet],
    });
  }

  function removeAuthBuyersField(id) {
    const newSet = officers.filter(function (abf) {
      return abf.id !== id;
    });
    dispatch({
      type: "setOfficerFields",
      payload: [newSet],
    });
  }

  const authorizedBuyersMultiField = (field) => (
    <>
      <Typography variant="h6">
        {field.label}{" "}
        <IconButton
          onClick={() => addAuthBuyerField()}
          color="success"
          sx={{
            width: "unset",
          }}
        >
          <AddIcon />
        </IconButton>
      </Typography>
      {officers &&
        officers.map((abf) => (
          <Stack direction="row" alignItems="center" key={abf.id}>
            <IconButton
              onClick={() => removeAuthBuyersField(abf.id)}
              color="primary"
              sx={{
                width: "unset",
              }}
            >
              <CloseIcon />
            </IconButton>
            {abf.fields &&
              abf.fields.map((field) => (
                <Stack sx={{ margin: "1rem" }} key={`${field.name}${abf.id}`}>
                  <Typography
                    sx={{ fontWeight: "700", paddingBottom: ".3rem" }}
                  >
                    {field.label}
                  </Typography>
                  <TextField
                    {...register(`${field.name}${abf.id}`)}
                    error={errors[`${field.name}${abf.id}`] ? true : false}
                    helperText={
                      errors[`${field.name}${abf.id}`]
                        ? errors[`${field.name}${abf.id}`].message
                        : ""
                    }
                    onChange={(e) =>
                      dispatch({
                        type: "setOfficerFieldValues",
                        field: `${field.name}${abf.id}`,
                        payload: e.target.value,
                      })
                    }
                    color="primary"
                    size="small"
                    sx={{
                      width: "100%",
                      margin: "unset",
                    }}
                  />
                </Stack>
              ))}
          </Stack>
        ))}
    </>
  );

  function addTradeReferenceField() {
    let largestIdField = references.reduce((maxObj, currentObj) => {
      return currentObj.id > maxObj.id ? currentObj : maxObj;
    });
    let newTradeReferenceField = {
      id: largestIdField.id + 1,
      fields: largestIdField.fields,
      name: largestIdField.name,
      label: largestIdField.label,
    };
    dispatch({
      type: "setReferenceFields",
      payload: [...references, newTradeReferenceField],
    });
  }

  function removeTradeRefField(id) {
    const newSet = references.filter(function (tbf) {
      return tbf.id !== id;
    });
    dispatch({
      type: "setReferenceFields",
      payload: newSet,
    });
  }

  const tradeReferenceMultiField = () => (
    <>
      <Typography variant="h6">
        Trade References{" "}
        <IconButton
          onClick={() => addTradeReferenceField()}
          color="success"
          sx={{
            width: "unset",
          }}
        >
          <AddIcon />
        </IconButton>
      </Typography>
      {references &&
        references.map((trf) => (
          <Stack direction="row" alignItems="center" key={trf.id}>
            <IconButton
              onClick={() => removeTradeRefField(trf.id)}
              color="primary"
              sx={{
                width: "unset",
              }}
            >
              <CloseIcon />
            </IconButton>
            {trf.fields &&
              trf.fields.map((field) => (
                <>
                  {field.template === "text" ? (
                    <Stack sx={{ margin: "1rem" }}>
                      <Typography
                        sx={{ fontWeight: "700", paddingBottom: ".3rem" }}
                      >
                        {field.label}
                      </Typography>
                      <TextField
                        {...register(`${field.name}${trf.id}`)}
                        error={errors[`${field.name}${trf.id}`] ? true : false}
                        helperText={
                          errors[`${field.name}${trf.id}`]
                            ? errors[`${field.name}${trf.id}`].message
                            : ""
                        }
                        onChange={(e) =>
                          dispatch({
                            type: "setReferenceFieldValues",
                            field: `${field.name}${trf.id}`,
                            payload: e.target.value,
                          })
                        }
                        color="primary"
                        size="small"
                        defaultValue={field.default ? field.default : ""}
                        sx={{
                          width: "100%",
                          margin: "unset",
                        }}
                      />
                    </Stack>
                  ) : field.template === "dropdown" ? (
                    <Stack sx={{ margin: "1rem" }}>
                      <Typography
                        sx={{ fontWeight: "700", paddingBottom: ".3rem" }}
                      >
                        {field.label}
                      </Typography>
                      <FormControl
                        {...register(`${field.name}${trf.id}`)}
                        error={errors[`${field.name}${trf.id}`] ? true : false}
                      >
                        <Select
                          key={`${field.name}${trf.id}`}
                          defaultValue={field.default ? field.default : "None"}
                          size="small"
                          MenuProps={{
                            PaperProps: {
                              sx: {
                                border: "1px solid #939598",
                                borderRadius: "0",
                                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                              },
                            },
                          }}
                          onChange={(e) => {
                            setValue(`${field.name}${trf.id}`, e.target.value, {
                              shouldValidate: true,
                            });
                            dispatch({
                              type: "setReferenceFieldValues",
                              field: `${field.name}${trf.id}`,
                              payload: e.target.value,
                            });
                          }}
                        >
                          <MenuItem value="None">
                            <em>Select...</em>
                          </MenuItem>
                          {field.values.map((option) => (
                            <MenuItem value={option.value} key={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </Select>
                        <FormHelperText>
                          {errors[`${field.name}${trf.id}`]
                            ? errors[`${field.name}${trf.id}`].message
                            : ""}
                        </FormHelperText>
                      </FormControl>
                    </Stack>
                  ) : (
                    ""
                  )}
                </>
              ))}
          </Stack>
        ))}
    </>
  );

  const AddressBlock = (addressFields) => {
    return (
      <Stack>
        {addressFields.map((addressField) => (
          <>
            {(addressField.name.endsWith("Street") ||
              addressField.name.endsWith("Street1") ||
              addressField.name.endsWith("Street2")) &&
              InputField(addressField)}
          </>
        ))}
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: {
              sm: "repeat(1, 1fr)",
              md: "repeat(2, 1fr)",
              lg: "repeat(2, 1fr)",
              xl: "repeat(2, 1fr)",
            },
          }}
        >
          {addressFields.map((addressField) => (
            <React.Fragment key={addressField.name}>
              {!(
                addressField.name.endsWith("Street") ||
                addressField.name.endsWith("Street1") ||
                addressField.name.endsWith("Street2")
              ) &&
                (addressField.template === "text"
                  ? InputField(addressField)
                  : addressField.template === "dropdown"
                  ? SelectField(addressField)
                  : CheckboxField(addressField))}
            </React.Fragment>
          ))}
        </Box>
      </Stack>
    );
  };

  const submitApplication = (data) => {
    let officerObjects = [];
    officers.forEach(function (officer) {
      let newOfficer = {
        Name: officerFieldValues?.[`Name${officer.id}`],
        Phone: officerFieldValues?.[`Phone${officer.id}`],
        Email: officerFieldValues?.[`Email${officer.id}`],
      };
      officerObjects.push(newOfficer);
    });
    let referenceObjects = [];
    references.forEach(function (reference) {
      let newReference = {
        Account: referenceFieldValues?.[`Account${reference.id}`],
        Company: referenceFieldValues?.[`Company${reference.id}`],
      };
      referenceObjects.push(newReference);
    });
    const formData = {
      ...data,
      Officers: officerObjects,
      References: referenceObjects,
    };
    api.post("forms/credit/submit", formData).then((response) => {
      if (response.isAxiosError) {
        const errorMessage = [];
        response.data.forEach(function (error) {
          let newMessage = `${error.field}: ${error.message}`;
          errorMessage.push(newMessage);
        });
        toast.error(
          `There was an error processing your request. ${errorMessage.toString()}`
        );
      } else {
        dispatch({ type: "submitForm", payload: response.data.message });
      }
    });
  };

  function sortFields(fields) {
    const applicantFields = [];
    const applicantAddressFields = [];
    const billingAddressFields = [];
    const businessFields = [];
    const bankFields = [];
    const bankAddressFields = [];
    const generalFields = [];
    const vastFields = [];
    fields.forEach(function (field) {
      if (constants.Net30ApplicantFields.includes(field.name)) {
        applicantFields.push(field);
      } else if (constants.Net30ApplicantAddressFields.includes(field.name)) {
        applicantAddressFields.push(field);
      } else if (constants.Net30BillingAddressFields.includes(field.name)) {
        billingAddressFields.push(field);
      } else if (constants.Net30BusinessInfoFields.includes(field.name)) {
        businessFields.push(field);
      } else if (constants.Net30BankingInfoFields.includes(field.name)) {
        bankFields.push(field);
      } else if (constants.Net30BankAddressFields.includes(field.name)) {
        bankAddressFields.push(field);
      } else if (constants.Net30GeneralFields.includes(field.name)) {
        generalFields.push(field);
      } else if (constants.Net30VastFields.includes(field.name)) {
        vastFields.push(field);
      }
    });
    const newlySortedFields = {
      applicantFields: applicantFields,
      applicantAddressFields: applicantAddressFields,
      billingAddressFields: billingAddressFields,
      businessFields: businessFields,
      bankFields: bankFields,
      bankAddressFields: bankAddressFields,
      generalFields: generalFields,
      vastFields: vastFields,
    };
    const shippingStateDefault = fields.find(function (field) {
      return field.name === "ShippingState";
    });
    setStateForTaxForm(
      shippingStateDefault ? shippingStateDefault.default : ""
    );
    return newlySortedFields;
  }

  useEffect(() => {
    api.fetch(`forms/credit/fields`).then((response) => {
      if (response.isAxiosError) {
        const errorMessage = [];
        response.data.errors.forEach(function (error) {
          errorMessage.push(error);
        });
        toast.error(
          `There was an error processing your request. ${errorMessage.toString()}`
        );
      } else {
        let formInfoPromise = new Promise((resolve, reject) => {
          resolve(
            response.data.fields.forEach(function (field) {
              dispatch({
                type: "updateFormInfo",
                field: field.name,
                payload: field.default,
              });
            })
          );
        });
        formInfoPromise
          .then(() => {
            const sortedFields = sortFields(response.data.fields);
            const officerFields = response.data.fields.find(function (field) {
              return field.name === "Officers";
            });
            const officerFieldObject = {
              id: 1,
              fields: officerFields.fields,
              name: officerFields.name,
              label: officerFields.label,
            };
            const referenceFields = response.data.fields.find(function (field) {
              return field.name === "References";
            });
            const tradeReferenceFieldObject = {
              id: 1,
              fields: referenceFields.fields,
              name: referenceFields.name,
              label: referenceFields.label,
            };
            dispatch({ type: "setFields", payload: sortedFields });
            dispatch({
              type: "setOfficerFields",
              payload: [officerFieldObject],
            });
            dispatch({
              type: "setReferenceFields",
              payload: [tradeReferenceFieldObject],
            });
          })
          // .then(() => {
          //   api.fetch("forms/marketing/fields").then((response) => {
          //     dispatch({
          //       type: "setMarketingFields",
          //       payload: response.data.fields,
          //     });
          //   });
          // })
          .then(() => {
            dispatch({ type: "setIsLoaded", payload: true });
          });
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // useEffect(() => {
  //   api.fetch("forms/market/fields").then((response) => {
  //   });
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  useEffect(() => {
    api.fetchStrapi(`/forms/10?populate=*`).then((response) => {
      dispatch({
        type: "setFormCopy",
        payload: {
          formtitle: response.data.data.attributes.formtitle,
          formtext: response.data.data.attributes.formtext,
        },
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FormContext.Provider value={{ formState, dispatch }}>
      {!isLoaded ? (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={true}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : (
        <Container>
          {!submitted ? (
            <form onSubmit={handleSubmit(submitApplication)}>
              <Stack spacing={3} sx={{ marginBottom: "3rem" }}>
                <Typography variant="articleTitle">{formtitle}</Typography>
                <Typography variant="staticText" sx={{ paddingBottom: "1rem" }}>
                  <ReactMarkdown
                    children={formtext}
                    remarkPlugins={[remarkGfm]}
                  />
                </Typography>
                <Typography variant="h6">Applicant Information</Typography>
                <Box sx={sxBox}>
                  {fields.applicantFields &&
                    fields.applicantFields.map((field) => (
                      <React.Fragment key={field.name}>
                        {field.template === "text"
                          ? InputField(field)
                          : field.template === "Phone" ||
                            field.template === "Fax"
                          ? InputField(field)
                          : field.template === "dropdown"
                          ? SelectField(field)
                          : field.template === "radio"
                          ? RadioButtonField(field)
                          : field.template === "textarea"
                          ? InputAreaField(field)
                          : field.template === "date"
                          ? DateField(field)
                          : CheckboxField(field)}
                      </React.Fragment>
                    ))}
                </Box>
                <Typography variant="h6">Shipping / Billing Address</Typography>
                <Box sx={sxBox}>
                  {AddressBlock(fields.applicantAddressFields)}
                  {AddressBlock(fields.billingAddressFields)}
                </Box>
                <Typography variant="h6">
                  Annual Purchasing Information
                </Typography>
                <Typography>
                  We offer volume discounts based on annual purchases. Your
                  answers will help us help you get maximum discounts.
                </Typography>
                <Box sx={sxBox}>
                  {marketingFields &&
                    marketingFields.map((field) => (
                      <React.Fragment key={field.name}>
                        {field.template === "text"
                          ? InputField(field)
                          : field.template === "Phone" ||
                            field.template === "Fax"
                          ? InputField(field)
                          : field.template === "dropdown"
                          ? SelectField(field)
                          : field.template === "radio"
                          ? RadioButtonField(field)
                          : field.template === "textarea"
                          ? InputAreaField(field)
                          : field.template === "date"
                          ? DateField(field)
                          : field.template === "hidden"
                          ? ""
                          : CheckboxField(field)}
                      </React.Fragment>
                    ))}
                </Box>
                <Divider flexItem />
                <Typography variant="h6">Business Information</Typography>
                <Box sx={sxBox}>
                  {fields.businessFields &&
                    fields.businessFields.map((field) => (
                      <React.Fragment key={field.name}>
                        {field.name !== "Officers" &&
                          (field.template === "text"
                            ? InputField(field)
                            : field.template === "Phone" ||
                              field.template === "Fax"
                            ? InputField(field)
                            : field.template === "dropdown"
                            ? SelectField(field)
                            : field.template === "radio"
                            ? RadioButtonField(field)
                            : field.template === "textarea"
                            ? InputAreaField(field)
                            : field.template === "date"
                            ? DateField(field)
                            : CheckboxField(field))}
                      </React.Fragment>
                    ))}
                </Box>
                {fields.businessFields &&
                  fields.businessFields.map((field) => (
                    <React.Fragment key={field.name}>
                      {field.name === "Officers" &&
                        authorizedBuyersMultiField(field)}
                    </React.Fragment>
                  ))}
                <Divider flexItem />
                <Typography variant="h6">Banking Information</Typography>
                <Box sx={sxBox}>
                  {fields.bankFields &&
                    fields.bankFields.map((field) => (
                      <React.Fragment key={field.name}>
                        {field.template === "text"
                          ? InputField(field)
                          : field.template === "Phone" ||
                            field.template === "Fax"
                          ? InputField(field)
                          : field.template === "dropdown"
                          ? SelectField(field)
                          : field.template === "radio"
                          ? RadioButtonField(field)
                          : field.template === "textarea"
                          ? InputAreaField(field)
                          : field.template === "date"
                          ? DateField(field)
                          : CheckboxField(field)}
                      </React.Fragment>
                    ))}
                </Box>
                <Box sx={sxBox}>{AddressBlock(fields.bankAddressFields)}</Box>
                <Divider flexItem />
                <Typography variant="h6">General Information</Typography>
                {fields.generalFields &&
                  fields.generalFields.map((field) => (
                    <React.Fragment key={field.name}>
                      {field.name === "Aml" && RadioButtonField(field)}
                    </React.Fragment>
                  ))}
                <Box sx={sxBox}>
                  {fields.generalFields &&
                    fields.generalFields.map((field) => (
                      <React.Fragment key={field.name}>
                        {field.name !== "References" &&
                          field.name !== "Aml" &&
                          (field.template === "text"
                            ? InputField(field)
                            : field.template === "Phone" ||
                              field.template === "Fax"
                            ? InputField(field)
                            : field.template === "dropdown"
                            ? SelectField(field)
                            : field.template === "radio"
                            ? RadioButtonField(field)
                            : field.template === "textarea"
                            ? InputAreaField(field)
                            : field.template === "date"
                            ? DateField(field)
                            : CheckboxField(field))}
                      </React.Fragment>
                    ))}
                </Box>
                {references && tradeReferenceMultiField()}
                {stateForTaxForm === "VA" &&
                  fields.vastFields &&
                  fields.vastFields.map((vField) => (
                    <>
                      <Divider flexItem />
                      <Typography variant="h6">{vField.label}*</Typography>
                      <Typography variant="subtitle2">
                        * Only required for business in Virginia.
                      </Typography>
                      <Box
                        sx={{
                          display: "grid",
                          gridTemplateColumns: {
                            sm: "repeat(1, 1fr)",
                            md: "repeat(1, 1fr)",
                            lg: "repeat(1, 1fr)",
                            xl: "repeat(1, 1fr)",
                          },
                        }}
                      >
                        {vField.fields &&
                          vField.fields.map((field) => (
                            <React.Fragment key={field.name}>
                              {field.name !== "References" &&
                                (field.template === "text"
                                  ? InputField(field)
                                  : field.template === "Phone" ||
                                    field.template === "Fax"
                                  ? InputField(field)
                                  : field.template === "dropdown"
                                  ? SelectField(field)
                                  : field.template === "radio"
                                  ? RadioButtonField(field)
                                  : field.template === "textarea"
                                  ? InputAreaField(field)
                                  : field.template === "date"
                                  ? DateField(field)
                                  : CheckboxField(field))}
                            </React.Fragment>
                          ))}
                      </Box>
                    </>
                  ))}
                <Button
                  color="success"
                  variant="contained"
                  type="submit"
                  sx={{ width: "25%" }}
                >
                  Submit Credit Application
                </Button>
              </Stack>
            </form>
          ) : (
            <Paper variant="outlined" sx={{ padding: "2rem" }}>
              <Stack spacing={2}>
                <Typography variant="h6">
                  Your Application is Getting Sent to our Financial Coordinator.
                </Typography>
                <Typography>
                  We are here to help you via phone Monday-Friday 9AM - 5:30PM
                  EDT. Please call 800-759-9997 toll-free. Outside the U.S. and
                  Canada, call 001.804.794.1118.
                </Typography>
                <Typography>
                  Or email us ANYTIME at info@hooverandstrong.com. It is our
                  goal to respond to your email within one business day.
                </Typography>
              </Stack>
            </Paper>
          )}
        </Container>
      )}
    </FormContext.Provider>
  );
}
