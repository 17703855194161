import format from "format-number";

const currencySymbol = "$";
const percentSymbol = "%";
const thousandsSeparator = ",";
//const copyright = "&copy;";
//const copyrightSymbol = "©";
const reg = "&reg;";
const regSymbol = "®";
//const ampersand = "&amp;";
//const ampersandSymbol = "&";

const stripRegex = new RegExp(
  `[${currencySymbol}${thousandsSeparator}${percentSymbol}]`,
  "g"
);
export function strip(value) {
  return value.toString().replace(stripRegex, "");
}

//for now just does &reg; - will add more characters later
const decodeHtmlCharCodes = new RegExp(`${reg}`, "g");
export function decodeHtml(string) {
  return string.replace(decodeHtmlCharCodes, regSymbol);
}

const removeSpaceChar = new RegExp("&(nbsp|amp|quot|lt|gt);", "g");
export function removeSpace(string) {
  return string.replace(removeSpaceChar, " ");
}

const price2Formatter = format({
  prefix: currencySymbol,
  integerSeparator: thousandsSeparator,
  round: 2,
  padRight: 2,
});
export const price2 = (value) => {
  return price2Formatter(strip(value));
};

const price0Formatter = format({
  prefix: currencySymbol,
  integerSeparator: thousandsSeparator,
  round: 0,
  padRight: 0,
});
export const price0 = (value) => {
  return price0Formatter(strip(value));
};

const percent0Formatter = format({
  suffix: percentSymbol,
  integerSeparator: thousandsSeparator,
  round: 0,
  padRight: 0,
});
export const percent0 = (value) => {
  return percent0Formatter(strip(value));
};

const percent1Formatter = format({
  suffix: percentSymbol,
  integerSeparator: thousandsSeparator,
  round: 1,
  padRight: 1,
});
export const percent1 = (value) => {
  return percent1Formatter(strip(value));
};

const percent2Formatter = format({
  suffix: percentSymbol,
  integerSeparator: thousandsSeparator,
  round: 2,
  padRight: 2,
});
export const percent2 = (value) => {
  return percent2Formatter(strip(value));
};

const num1Formatter = format({
  integerSeparator: thousandsSeparator,
  round: 1,
  padRight: 1,
});
export const num1 = (value) => {
  return num1Formatter(strip(value));
};

const num0Formatter = format({
  integerSeparator: thousandsSeparator,
  round: 0,
  padRight: 0,
});
export const num0 = (value) => {
  return num0Formatter(strip(value));
};

const num2Formatter = format({
  integerSeparator: thousandsSeparator,
  round: 2,
  padRight: 2,
});
export const num2 = (value) => {
  return num2Formatter(strip(value));
};

const formatting = Object.freeze({
  strip,
  decodeHtml,
  removeSpace,
  price2,
  price0,
  percent0,
  percent1,
  percent2,
  num0,
  num1,
  num2,
});

export const formatNumber = (num, format) => {
  if (formatting[format]) return formatting[format](num);
  else return num;
};

export default formatting;
