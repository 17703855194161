import React from "react";
import { Button, Divider, Box, Stack, Typography, Grid } from "@mui/material";
import { Link as DomLink } from "react-router-dom";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";
import remarkGfm from "remark-gfm";
import {
  stackPageSx,
  boxGridSx,
  stackBtnSx,
} from "../../styles/AuctionHouseSx";
import useMediaQueryUtils from "../../../../../utils/mediaQueryUtils";
import { constants } from "../../../../../utils";

const strapiURL = constants.CMS_FRONT_IMAGE_URL;

function Buy(props) {
  const isMdScreen = useMediaQueryUtils("md");
  const {
    BuyImageTextFAQ,
    BuyImageTextMiddle,
    BuyImageTextTop,
    UpcomingAuctions,
  } = props;
  return (
    <>
      <Grid container spacing={1}>
        {BuyImageTextTop && (
          <>
            <Grid item xs={12} md={6} marginTop={"3rem"}>
              <div>
                {isMdScreen ? (
                  <img
                    width={isMdScreen ? "100%" : "90%"}
                    src={`${strapiURL}${BuyImageTextTop.image.data.attributes.url}`}
                    alt="banner"
                  />
                ) : null}
              </div>
              <Stack spacing={1}>
                <Typography variant="h3">{BuyImageTextTop.title}</Typography>
                <Typography variant="staticText">
                  <ReactMarkdown remarkGfm={[remarkGfm]}>
                    {BuyImageTextTop.body}
                  </ReactMarkdown>
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={12} md={6} marginTop={"3rem"}>
              {!isMdScreen ? (
                <img
                  width={isMdScreen ? "100%" : "90%"}
                  src={`${strapiURL}${BuyImageTextTop.image.data.attributes.url}`}
                  alt="banner"
                />
              ) : null}
            </Grid>
          </>
        )}
        <Grid item xs={12}>
          <Divider flexItem />
          {BuyImageTextMiddle && (
            <Stack sx={stackPageSx}>
              <Box sx={boxGridSx}>
                <div>
                  <img
                    width={"100%"}
                    src={`${strapiURL}${BuyImageTextMiddle.image.data.attributes.url}`}
                    alt="banner"
                  />
                </div>
                <Stack spacing={1}>
                  <Typography variant="h3">
                    {BuyImageTextMiddle.title}
                  </Typography>
                  <Typography variant="staticText">
                    <ReactMarkdown remarkGfm={[remarkGfm]}>
                      {BuyImageTextMiddle.body}
                    </ReactMarkdown>
                  </Typography>
                  <Stack sx={stackBtnSx}>
                    <DomLink
                      to={{
                        pathname: "/harmony-auction-signup",
                      }}
                      className="center"
                    >
                      <Button color="success" variant="contained" size="small">
                        Open a Buyer Account
                      </Button>
                    </DomLink>
                    <Stack direction="column" spacing={1}>
                      <Typography
                        sx={{
                          color: "#7fa04d",
                          margin: "10px",
                          textAlign: "center",
                        }}
                      >
                        Already Participating?{" "}
                        {
                          <DomLink
                            to={{
                              pathname: "/myauction-log-in",
                            }}
                            className="center"
                          >
                            <Typography
                              sx={{
                                color: "#7fa04d",
                                textDecoration: "underline",
                              }}
                            >
                              Login
                            </Typography>
                          </DomLink>
                        }
                      </Typography>
                    </Stack>
                  </Stack>
                </Stack>
              </Box>
            </Stack>
          )}
          <Divider flexItem />
        </Grid>
        <Grid item xs={12}>
          {BuyImageTextFAQ && (
            <Stack sx={stackPageSx}>
              <Grid
                container
                spacing={3}
                direction={isMdScreen ? "column-reverse" : undefined}
              >
                <Grid item xs={12} md={6}>
                  <Typography variant="h3">{BuyImageTextFAQ.title}</Typography>
                  <Typography variant="staticText">
                    <ReactMarkdown remarkGfm={[remarkGfm]}>
                      {BuyImageTextFAQ.body}
                    </ReactMarkdown>
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <img
                    width={"100%"}
                    src={`${strapiURL}${BuyImageTextFAQ.image.data.attributes.url}`}
                    alt="banner"
                  />
                  <Stack
                    sx={{
                      padding: "2rem",
                      border: "3px solid #D2B48C",
                    }}
                    spacing={2}
                  >
                    <Typography variant="diamondTabFont" className="center">
                      UPCOMING AUCTIONS
                    </Typography>
                    <Box
                      sx={{
                        display: "grid",
                        gridTemplateColumns: {
                          sm: "repeat(1, 1fr)",
                          md: "repeat(2, 1fr)",
                          lg: "repeat(2, 1fr)",
                          xl: "repeat(2, 1fr)",
                        },
                      }}
                    >
                      <Typography variant="staticText">
                        <ReactMarkdown remarkGfm={[remarkGfm]}>
                          {UpcomingAuctions.body}
                        </ReactMarkdown>
                      </Typography>
                    </Box>
                  </Stack>
                </Grid>
              </Grid>
            </Stack>
          )}
        </Grid>
      </Grid>
    </>
  );
}

export default Buy;
