const sortOrderMap = {
  material: 1,
  dimension: 2,
  carat_size: 3,
  finger_size: 4,
  quantity: 5,
  outer_diameter: 10,
  inner_diameter: 11,
  "quantity.pieces": 12,
  "quantity.length": 13,
  length: 20,
  width: 21,
  thickness: 22,
};
const sortOrder = (name) => {
  if (sortOrderMap[name]) {
    return sortOrderMap[name];
  }
  return 100;
};

export default sortOrder;
