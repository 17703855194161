import { Link as DomLink } from "react-router-dom";
import { constants } from "../../../../utils";
import { storage } from "../../../../utils";
import { Card, CardMedia, Typography } from "@mui/material";
const frontImageUrl = constants.CMS_FRONT_IMAGE_URL;

const ThreeImagesCard = (image, pdf) => (
  <>
    {pdf === true ? (
      <a href={`${image.alternativeText}`} target="_blank" rel="noreferrer">
        <Card className="pointer" sx={{ backgroundColor: "#e3e3e3" }}>
          <CardMedia component="img" image={`${frontImageUrl}${image.url}`} />
          <Typography variant="imageCaption" className="center">
            {image.caption}
          </Typography>
        </Card>
      </a>
    ) : (
      <DomLink
        to={{
          pathname: `${image.alternativeText}`,
          state: {
            recentlyViewed: JSON.parse(
              storage.getSessionStorageItem("hsRecentlyViewedProducts")
            ),
          },
        }}
      >
        <Card className="pointer" sx={{ backgroundColor: "#e3e3e3" }}>
          <CardMedia component="img" image={`${frontImageUrl}${image.url}`} />
          <Typography variant="imageCaption" className="center">
            {image.caption}
          </Typography>
        </Card>
      </DomLink>
    )}
  </>
);

export default ThreeImagesCard;
