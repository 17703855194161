import React from "react";

const AsscherProfile = (props) => {
  const viewBoxWidth = 85.7;
  const viewBoxHeight = 86.1;

  const lineColor = "#4a4a4a";
  const strokeWidth2 = "0.5";

  const x = (percentX) => percentX * viewBoxWidth;
  const y = (percentY) => percentY * viewBoxHeight;

  const table = Number(props.diamond.Table).toFixed(0);
  const depthPercent = Number(props.diamond.Depth).toFixed(1);
  const girdle = props.diamond.Girdle || "";
  const girdleSpaceIndex = girdle.indexOf(" ");
  const girdle1 =
    girdleSpaceIndex > 0 ? girdle.slice(0, girdleSpaceIndex) : girdle;
  const girdle2 = girdleSpaceIndex > 0 ? girdle.slice(girdleSpaceIndex) : "";

  const diagramStrokeWidth = "0.01";
  const diagramFontSize = 0.046;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox={"0 0 " + viewBoxWidth + " " + viewBoxHeight}
      width={props.width}
      height={props.height}
      preserveAspectRatio={props.preserveAspectRatio}
    >
      <line
        fill="none"
        stroke={props.color}
        strokeWidth={props.stroke_width}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        x1="11.4"
        y1="27.3"
        x2="74.4"
        y2="27.3"
      />
      <line
        fill="none"
        stroke={props.color}
        strokeWidth={props.stroke_width}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        x1="8.2"
        y1="28.8"
        x2="77.5"
        y2="28.8"
      />
      <line
        fill="none"
        stroke={props.color}
        strokeWidth={props.stroke_width}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        x1="2.1"
        y1="33.4"
        x2="83.6"
        y2="33.4"
      />
      <line
        fill="none"
        stroke={props.color}
        strokeWidth={props.stroke_width}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        x1="2"
        y1="34.8"
        x2="83.5"
        y2="34.8"
      />
      <line
        fill="none"
        stroke={props.color}
        strokeWidth={props.stroke_width}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        x1="15.2"
        y1="47.8"
        x2="70.9"
        y2="47.8"
      />
      <line
        fill="none"
        stroke={props.color}
        strokeWidth={props.stroke_width}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        x1="23.1"
        y1="55.4"
        x2="62.9"
        y2="55.4"
      />
      <line
        fill="none"
        stroke={props.color}
        strokeWidth={props.stroke_width}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        x1="27.2"
        y1="60.7"
        x2="58.7"
        y2="60.7"
      />
      <line
        fill="none"
        stroke={props.color}
        strokeWidth={props.stroke_width}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        x1="26"
        y1="57.9"
        x2="60.3"
        y2="57.9"
      />
      <polyline
        fill="none"
        stroke={props.color}
        strokeWidth={props.stroke_width}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        points="11.5,27.3 8.3,28.7 2.2,33.1 2.1,35.1 25.9,58.1 27.1,60.6 			"
      />
      <polyline
        fill="none"
        stroke={props.color}
        strokeWidth={props.stroke_width}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        points="73.9,27.2 77.5,28.8 83.8,33.2 83.9,35.2 60,58.2 58.9,60.7 			"
      />
      <polyline
        fill="none"
        stroke={props.color}
        strokeWidth={props.stroke_width}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        points="18.7,27.5 18,29 15,33.3 15.1,35.1 20.9,47.8 26.4,55.2 28,57.8 28.7,60.5 			"
      />
      <polyline
        fill="none"
        stroke={props.color}
        strokeWidth={props.stroke_width}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        points="67.4,27.5 68,29 71,33.3 70.9,35.1 65.1,47.8 59.6,55.2 58,57.8 57.4,60.5 			"
      />
      {props.show_diagram && (
        <>
          <line
            x1={x(0.1)}
            x2={x(0.9)}
            y1={y(0.28)}
            y2={y(0.28)}
            stroke={lineColor}
            strokeWidth={strokeWidth2}
          ></line>
          <line
            x1={x(0.1)}
            x2={x(0.1)}
            y1={y(0.26)}
            y2={y(0.3)}
            stroke={lineColor}
            strokeWidth={strokeWidth2}
          ></line>
          <line
            x1={x(0.9)}
            x2={x(0.9)}
            y1={y(0.26)}
            y2={y(0.3)}
            stroke={lineColor}
            strokeWidth={strokeWidth2}
          ></line>
          <line
            x1={x(0.06)}
            y1={y(0.3)}
            x2={x(0.06)}
            y2={y(0.73)}
            stroke={lineColor}
            strokeWidth={strokeWidth2}
          ></line>
          <line
            x1={x(0.04)}
            y1={y(0.3)}
            x2={x(0.08)}
            y2={y(0.3)}
            stroke={lineColor}
            strokeWidth={strokeWidth2}
          ></line>
          <line
            x1={x(0.04)}
            y1={y(0.73)}
            x2={x(0.08)}
            y2={y(0.73)}
            stroke={lineColor}
            strokeWidth={strokeWidth2}
          ></line>
          <line
            x1={x(0.94)}
            y1={y(0.3)}
            x2={x(0.94)}
            y2={y(0.73)}
            stroke={lineColor}
            strokeWidth={strokeWidth2}
          ></line>
          <line
            x1={x(0.92)}
            y1={y(0.3)}
            x2={x(0.96)}
            y2={y(0.3)}
            stroke={lineColor}
            strokeWidth={strokeWidth2}
          ></line>
          <line
            x1={x(0.92)}
            y1={y(0.73)}
            x2={x(0.96)}
            y2={y(0.73)}
            stroke={lineColor}
            strokeWidth={strokeWidth2}
          ></line>
          <circle
            cx={x(0.5)}
            cy={y(0.7)}
            r={y(0.03)}
            fillOpacity="0"
            stroke={lineColor}
            strokeWidth={strokeWidth2}
          />
          <line
            x1={x(0.5)}
            y1={y(0.73)}
            x2={x(0.502)}
            y2={y(0.79)}
            stroke={lineColor}
            strokeWidth={strokeWidth2}
          ></line>
          <line
            x1={x(0.502)}
            y1={y(0.79)}
            x2={x(0.54)}
            y2={y(0.79)}
            stroke={lineColor}
            strokeWidth={strokeWidth2}
          ></line>
          <circle
            cx={x(0.4)}
            cy={y(0.395)}
            r={y(0.03)}
            fillOpacity="0"
            stroke={lineColor}
            strokeWidth={strokeWidth2}
          />
          <line
            x1={x(0.4)}
            y1={y(0.425)}
            x2={x(0.4)}
            y2={y(0.83)}
            stroke={lineColor}
            strokeWidth={strokeWidth2}
          ></line>
          <line
            x1={x(0.4)}
            y1={y(0.83)}
            x2={x(0.38)}
            y2={y(0.83)}
            stroke={lineColor}
            strokeWidth={strokeWidth2}
          ></line>
          <rect
            x={x(0.005)}
            y={y(0.43)}
            width={x(0.22)}
            height={y(0.135)}
            rx="0.8"
            fill="white"
          ></rect>
          <text
            x={x(0.03)}
            y={y(0.475)}
            fontSize={y(diagramFontSize)}
            stroke={lineColor}
            strokeWidth={diagramStrokeWidth}
          >
            Depth:
          </text>
          <text
            x={x(0.02)}
            y={y(0.542)}
            fontSize={y(diagramFontSize)}
            stroke={lineColor}
            strokeWidth={diagramStrokeWidth}
          >
            {props.diamond.Measurement3}
          </text>

          <rect
            x={x(0.8)}
            y={y(0.43)}
            width={x(0.19)}
            height={y(0.135)}
            rx="0.8"
            fill="white"
          ></rect>
          <text
            x={x(0.81)}
            y={y(0.475)}
            fontSize={y(diagramFontSize)}
            stroke={lineColor}
            strokeWidth={diagramStrokeWidth}
          >
            Depth:
          </text>
          <text
            x={x(0.81)}
            y={y(0.542)}
            fontSize={y(diagramFontSize)}
            stroke={lineColor}
            strokeWidth={diagramStrokeWidth}
          >
            {depthPercent}%
          </text>
          <text
            x={x(0.37)}
            y={y(0.26)}
            fontSize={y(diagramFontSize)}
            stroke={lineColor}
            strokeWidth={diagramStrokeWidth}
          >
            Table: {table}%
          </text>
          <text
            x={x(0.0)}
            y={y(0.82)}
            fontSize={y(diagramFontSize)}
            stroke={lineColor}
            strokeWidth={diagramStrokeWidth}
          >
            Girdle: {girdle1}
          </text>
          <text
            x={x(0.0)}
            y={y(0.88)}
            fontSize={y(diagramFontSize)}
            stroke={lineColor}
            strokeWidth={diagramStrokeWidth}
          >
            {girdle2}
          </text>
          <text
            x={x(0.55)}
            y={y(0.8)}
            fontSize={y(diagramFontSize)}
            stroke={lineColor}
            strokeWidth={diagramStrokeWidth}
          >
            Culet: {props.diamond.Culet}
          </text>
        </>
      )}
    </svg>
  );
};

export default AsscherProfile;
