import React, { useContext, useEffect, useState } from "react";
import { Button } from "@mui/material";
import { DiamondSearchContext } from "../../utils/stateHandlers/contexts";

function FancyColorButton(props) {
  const { diamondState, dispatch } = useContext(DiamondSearchContext);
  const { searchQuery } = diamondState;
  const { color, switchToFancyColors } = props;
  const [isWhite, setIsWhite] = useState(true);

  function isFcActive(colorName) {
    const selectedColorValue = searchQuery.fields.find(
      (field) => field.name === "FancyColor"
    );

    if (selectedColorValue) {
      const bool = selectedColorValue.values.some(
        (value) => value.name === colorName
      );
      return bool;
    }
  }

  function selectionFilter(filterName, valueName) {
    const searchField = searchQuery.fields.find(function (field) {
      return field.name === filterName;
    });
    if (searchField) {
      const valueFound = searchField.values.find(function (value) {
        return value.name === valueName;
      });
      if (valueFound) {
        const removeSelected = searchField.values.filter(function (value) {
          return value.name !== valueName;
        });
        searchField.values = removeSelected;
        if (searchField.values.length === 0) {
          dispatch({
            type: "setShowFancyColorFilter",
            payload: false,
          });
        }
        dispatch({ type: "updateSearchQuery", payload: searchQuery.fields });
      } else {
        const newSelectedValues = [...searchField.values, { name: valueName }];
        searchField.values = newSelectedValues;
        dispatch({ type: "updateSearchQuery", payload: searchQuery.fields });
      }
    } else {
      const newSearchFields = [
        ...searchQuery.fields,
        {
          name: filterName,
          values: [
            {
              name: valueName,
            },
          ],
        },
      ];
      dispatch({ type: "updateSearchQuery", payload: newSearchFields });
    }
  }

  useEffect(() => {
    function isWhiteActive() {
      const colorValues = searchQuery.fields.find(
        (field) => field.name === "Color"
      )?.values;
      if (colorValues) {
        const allEqual = colorValues.every((value) =>
          ["D", "E", "F", "G", "H", "I", "J", "K"].includes(value.name)
        );
        setIsWhite(allEqual);
      }
    }

    isWhiteActive();
  }, [searchQuery]);

  return (
    <Button
      onClick={() => {
        if (color !== "White") {
          switchToFancyColors(color);
          selectionFilter("FancyColor", color);
        } else {
          switchToFancyColors(color);
        }
      }}
      disableElevation
      variant="contained"
      color={`diamond${color}`}
      sx={{
        width: "100%",
        textTransform: "capitalize",
        height: "3rem",
        border: "1px solid gray",
        lineHeight: 1,
        ...(isFcActive(color)
          ? {
              border: "2px solid black",
              color: "black",
            }
          : isWhite && color === "White"
          ? {
              border: "2px solid black",
              color: "black",
            }
          : null),
      }}
    >
      {color}
    </Button>
  );
}

export default FancyColorButton;
