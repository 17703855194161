function gaSelectItem(info) {
  const newItem = {
    item_id: info.item.id,
    item_name: info.item.product,
    item_brand: info.item.dimension,
    item_category: info.item.quantity_unit,
    price: info.item.invoices.reduce(
      (total, invoice) => total + Number(invoice.unit_price),
      0
    ),

    quantity: Number(info.item.quantity),
  };
  window.gtag("event", "select_item", {
    env: process.env.NODE_ENV,
    item_list_id: info.item_list_id,
    item_list_name: info.item_list_name,
    items: [newItem],
  });
}

function gaViewItem(info) {
  const newItem = {
    item_id: info.item.id,
    item_name: info.item.product,
    item_brand: info.item.dimension,
    item_category: info.item.quantity_unit,
    price: info.item.invoices.reduce(
      (total, invoice) => total + Number(invoice.unit_price),
      0
    ),

    quantity: Number(info.item.quantity),
  };
  window.gtag("event", "view_item", {
    env: process.env.NODE_ENV,
    currency: "USD",
    value: info.price,
    items: [newItem],
  });
}

function gaAddToCart(info) {
  const newItem = {
    item_id: info.item.id,
    item_name: info.item.product,
    item_brand: info.item.dimension,
    item_category: info.item.quantity_unit,
    price: (info.item.invoices || []).reduce(
      (total, invoice) => total + Number(invoice.unit_price),
      0
    ),

    quantity: Number(info.item.quantity),
  };
  window.gtag("event", "add_to_cart", {
    env: process.env.NODE_ENV,
    currency: "USD",
    value: info.price,
    items: [newItem],
  });
}

function gaViewCart(info) {
  const mappedItems = info.items.map((item) => {
    const totalPrice = item.invoices.reduce(
      (total, invoice) => total + Number(invoice.unit_price),
      0
    );
    return {
      item_id: item.id,
      item_name: item.product,
      item_brand: item.dimension,
      item_category: item.quantity_unit,
      price: totalPrice,
      quantity: Number(item.quantity),
    };
  });

  window.gtag("event", "view_cart", {
    env: process.env.NODE_ENV,
    currency: "USD",
    value: info.subtotal,
    items: mappedItems,
  });
}

function gaRemoveFromCart(info) {
  const newItem = {
    item_id: info.id,
    item_name: info.product,
    item_brand: info.dimension,
    item_category: info.quantity_unit,
    price: info.invoices.reduce(
      (total, invoice) => total + Number(invoice.unit_price),
      0
    ),
    quantity: Number(info.quantity),
  };
  window.gtag("event", "remove_from_cart", {
    env: process.env.NODE_ENV,
    currency: "USD",
    value: info.invoices.reduce(
      (total, invoice) => total + Number(invoice.unit_price),
      0
    ),
    items: [newItem],
  });
}

function gaBeginCheckout(info) {
  const mappedItems = info.items.map((item) => {
    const price = item.invoices.reduce(
      (total, invoice) => total + Number(invoice.unit_price),
      0
    );
    return {
      item_id: item.id,
      item_name: item.product,
      item_brand: item.dimension,
      item_category: item.quantity_unit,
      price: price,
      quantity: Number(item.quantity),
    };
  });

  window.gtag("event", "begin_checkout", {
    env: process.env.NODE_ENV,
    currency: "USD",
    value: info.total,
    items: mappedItems,
  });
}

function gaPurchase(info) {
  const mappedItems = info.items.map((item) => {
    const price = item.invoices.reduce(
      (total, invoice) => total + Number(invoice.unit_price),
      0
    );
    return {
      item_id: item.id,
      item_name: item.product,
      item_brand: item.dimension,
      item_category: item.quantity_unit,
      price: price,
      quantity: Number(item.quantity),
    };
  });

  window.gtag("event", "purchase", {
    env: process.env.NODE_ENV,
    transaction_id: info.transaction_id,
    tax: info.tax,
    shipping: info.shipping,
    currency: "USD",
    value: info.total,
    items: mappedItems,
  });
}

const gaFunctions = {
  gaSelectItem: gaSelectItem,
  gaViewItem: gaViewItem,
  gaAddToCart: gaAddToCart,
  gaViewCart: gaViewCart,
  gaRemoveFromCart: gaRemoveFromCart,
  gaBeginCheckout: gaBeginCheckout,
  gaPurchase: gaPurchase,
};

export default gaFunctions;
