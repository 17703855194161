// Third-party libraries
import React from "react";
import { Grid, Typography, Divider } from "@mui/material";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";

// Local modules

function MetalColumns(props) {
  return (
    <>
      <Divider flexItem />
      <Grid container justifyContent="center">
        <Grid item xs={4}>
          <Typography variant="staticText">
            <ReactMarkdown remarkPlugins={[remarkGfm]}>
              {props.metalColumns.left}
            </ReactMarkdown>
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="staticText">
            <ReactMarkdown remarkPlugins={[remarkGfm]}>
              {props.metalColumns.middle}
            </ReactMarkdown>
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="staticText">
            <ReactMarkdown remarkPlugins={[remarkGfm]}>
              {props.metalColumns.right}
            </ReactMarkdown>
          </Typography>
        </Grid>
      </Grid>
    </>
  );
}

export default MetalColumns;
