import React from "react";
import Asscher from "./Asscher.jsx";
import AsscherProfile from "./AsscherProfile.jsx";
import Cushion from "./Cushion.jsx";
import CushionProfile from "./CushionProfile.jsx";
import Emerald from "./Emerald.jsx";
import EmeraldProfile from "./EmeraldProfile.jsx";
import Heart from "./Heart.jsx";
import HeartProfile from "./HeartProfile.jsx";
import Marquise from "./Marquise.jsx";
import MarquiseProfile from "./MarquiseProfile.jsx";
import OldEuro from "./OldEuro.jsx";
import OldEuroProfile from "./OldEuroProfile.jsx";
import OldMiner from "./OldMiner.jsx";
import Oval from "./Oval.jsx";
import OvalProfile from "./OvalProfile.jsx";
import Pear from "./Pear.jsx";
import PearProfile from "./PearProfile.jsx";
import Princess from "./Princess.jsx";
import PrincessProfile from "./PrincessProfile.jsx";
import Radiant from "./Radiant.jsx";
import RadiantProfile from "./RadiantProfile.jsx";
import Rose from "./Rose.jsx";
import RoseProfile from "./RoseProfile.jsx";
import Round from "./Round.jsx";
import RoundProfile from "./RoundProfile.jsx";
import StraightBaguette from "./StraightBaguette.jsx";
import Trillion from "./Trillion.jsx";
import TrillionProfile from "./TrillionProfile.jsx";

const ShapeCollection = {
  Asscher,
  AsscherProfile,
  Cushion,
  CushionProfile,
  Emerald,
  EmeraldProfile,
  Heart,
  HeartProfile,
  Marquise,
  MarquiseProfile,
  OldEuro,
  OldEuroProfile,
  OldMiner,
  Oval,
  OvalProfile,
  Pear,
  PearProfile,
  Princess,
  PrincessProfile,
  Radiant,
  RadiantProfile,
  Rose,
  RoseProfile,
  Round,
  RoundProfile,
  StraightBaguette,
  Trillion,
  TrillionProfile,
};

const Shape = (props) => {
  let shape = props.type;
  if (shape === "Old Miner" || shape === "Old Euro") {
    shape = shape.replace(" ", "");
  }
  return (
    <>{shape in ShapeCollection && ShapeCollection[shape]({ ...props })}</>
  );
};

export default Shape;
