import React from "react";
import { Typography, Stack } from "@mui/material";
import formatting from "../../../../utils/formatHelpers";
import { functions } from "../../../../utils";

function ProductTitle({ style, productInfo, icons, location }) {
  return (
    <>
      <Typography variant="h4">{style}</Typography>
      {productInfo && (
        <Typography>
          {productInfo.description &&
            formatting["decodeHtml"](productInfo.description)}
        </Typography>
      )}
      <Stack direction="row" spacing={1} justifyContent={location}>
        {icons &&
          icons.map((icon, key) => (
            <Typography key={icon} className="icons">
              {functions.getIcons(icon)}
            </Typography>
          ))}
      </Stack>
    </>
  );
}

export default ProductTitle;
