// Built-in modules
import qs from "qs";

// Third-party libraries
import React, { useEffect, useState } from "react";
import { Link as DomLink } from "react-router-dom";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";
import remarkGfm from "remark-gfm";
import {
  Container,
  Typography,
  Stack,
  Divider,
  Link,
  Backdrop,
  CircularProgress,
} from "@mui/material";

// Local modules
import { Mobile, Default } from "../../../utils/deviceHelpers";
import api from "../../../utils/api";
import { constants } from "../../../utils";
import LandingFooter from "../../pages/static/LandingFooter";
import {
  StyledImageButton,
  StyledImageSrc,
  StyledImage,
  StyledImageBackdrop,
  StyledStackRow,
  StyledStackCenter,
  StyledStackContent,
} from "./styles/DiamondServicesStyles";
import ButtonLabel from "./styles/ButtonLabel";

export default function DiamondServicesPage(props) {
  const [isLoaded, setIsLoaded] = useState(false);
  const [Banner, setBanner] = useState();
  const [MeleePurchasing, setMeleePurchasing] = useState();
  const [StoneRemoval, setStoneRemoval] = useState();
  const [StoneFrosting, setStoneFrosting] = useState();
  const [StoneScreening, setStoneScreening] = useState();
  const [StoneSetting, setStoneSetting] = useState();
  const [GreenRectangle, setGreenRectangle] = useState();
  const [FooterIcons, setFooterIcons] = useState();
  const frontImageUrl = constants.CMS_FRONT_IMAGE_URL;

  useEffect(() => {
    //window.scrollTo(0, 0);
    const query = qs.stringify({
      populate: {
        Banner: {
          populate: "*",
        },
        MeleePurchasing: {
          populate: "*",
        },
        StoneRemoval: {
          populate: "*",
        },
        StoneFrosting: {
          populate: "*",
        },
        StoneScreening: {
          populate: "*",
        },
        StoneSetting: {
          populate: "*",
        },
        GreenRectangle: {
          populate: "*",
        },
        FooterIcons: {
          populate: "*",
        },
      },
    });
    api.fetchStrapi(`/diamond-services-page?${query}`).then((response) => {
      setBanner(response.data.data.attributes.Banner.data.attributes);
      setMeleePurchasing(response.data.data.attributes.MeleePurchasing);
      setStoneRemoval(response.data.data.attributes.StoneRemoval);
      setStoneFrosting(response.data.data.attributes.StoneFrosting);
      setStoneScreening(response.data.data.attributes.StoneScreening);
      setStoneSetting(response.data.data.attributes.StoneSetting);
      setGreenRectangle(response.data.data.attributes.GreenRectangle);
      setFooterIcons(response.data.data.attributes.FooterIcons);
      setIsLoaded(true);
    });
  }, []);

  return (
    <>
      {!isLoaded ? (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={true}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : (
        <Container maxWidth="xl" className="careers-text">
          <Mobile>
            <StyledStackCenter>
              {Banner && (
                <img
                  src={`${frontImageUrl}${Banner.url}`}
                  width="100%"
                  alt="banner"
                />
              )}
              <Divider flexItem />
              <Typography variant="articleTitle">Diamond Services</Typography>
              <StyledStackCenter>
                {MeleePurchasing && (
                  <StyledStackContent>
                    <Stack spacing={2}>
                      <Typography
                        variant="diamondTabFont"
                        sx={{ fontWeight: "800" }}
                      >
                        {MeleePurchasing.title}
                      </Typography>
                      <Typography variant="staticText">
                        <ReactMarkdown remarkGfm={[remarkGfm]}>
                          {MeleePurchasing.body}
                        </ReactMarkdown>
                      </Typography>
                    </Stack>
                    <DomLink
                      to={{
                        pathname: "/stone-purchasing-service",
                      }}
                      className="center"
                    >
                      <ButtonLabel label={"Learn More"} />
                    </DomLink>
                  </StyledStackContent>
                )}
                {MeleePurchasing && (
                  <iframe
                    width="100%"
                    height="315px"
                    src="https://www.youtube.com/embed/TsrTeE-0imQ"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  ></iframe>
                )}
              </StyledStackCenter>
              <Divider flexItem />
              <Stack spacing={3} alignItems="center" sx={{ margin: "5rem" }}>
                {StoneRemoval && (
                  <img
                    src={`${frontImageUrl}${StoneRemoval.image.data.attributes.url}`}
                    width="100%"
                    alt="banner"
                  />
                )}
                {StoneRemoval && (
                  <StyledStackContent>
                    <Stack spacing={2}>
                      <Typography
                        variant="diamondTabFont"
                        sx={{ fontWeight: "800" }}
                      >
                        {StoneRemoval.title}
                      </Typography>
                      <Typography variant="staticText">
                        <ReactMarkdown remarkGfm={[remarkGfm]}>
                          {StoneRemoval.body}
                        </ReactMarkdown>
                      </Typography>
                    </Stack>
                    <Link
                      href="https://cms.hooverandstrong.com/uploads/Refining_Chain_Custody_Form2_24_23_4f71403791.pdf"
                      className="center"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <ButtonLabel label={"Submit Stone Removal Lot"} />
                    </Link>
                  </StyledStackContent>
                )}
              </Stack>
              <Divider flexItem />
              <StyledStackCenter>
                {StoneFrosting && (
                  <img
                    src={`${frontImageUrl}${StoneFrosting.image.data.attributes.url}`}
                    width="100%"
                    alt="banner"
                  />
                )}
                {StoneFrosting && (
                  <StyledStackContent>
                    <Stack spacing={2}>
                      <Typography
                        variant="diamondTabFont"
                        sx={{ fontWeight: "800" }}
                      >
                        {StoneFrosting.title}
                      </Typography>
                      <Typography variant="staticText">
                        <ReactMarkdown remarkGfm={[remarkGfm]}>
                          {StoneFrosting.body}
                        </ReactMarkdown>
                      </Typography>
                    </Stack>
                    <DomLink
                      to={{
                        pathname: "/lg-screening-cz-frosting-form",
                      }}
                      className="center"
                    >
                      <ButtonLabel label={"Submit Stones For Frosting"} />
                    </DomLink>
                  </StyledStackContent>
                )}
              </StyledStackCenter>
              <Divider flexItem />
              <StyledStackCenter>
                {StoneScreening && (
                  <StyledStackContent>
                    <Stack spacing={2}>
                      <Typography
                        variant="diamondTabFont"
                        sx={{ fontWeight: "800" }}
                      >
                        {StoneScreening.title}
                      </Typography>
                      <Typography variant="staticText">
                        <ReactMarkdown remarkGfm={[remarkGfm]}>
                          {StoneScreening.body}
                        </ReactMarkdown>
                      </Typography>
                    </Stack>
                    <DomLink
                      to={{
                        pathname: "/lg-screening-cz-frosting-form",
                      }}
                      className="center"
                    >
                      <ButtonLabel label={"Submit Stones For Screening"} />
                    </DomLink>
                  </StyledStackContent>
                )}
              </StyledStackCenter>
              <Divider flexItem />
              <StyledStackCenter>
                {StoneSetting && (
                  <img
                    src={`${frontImageUrl}${StoneSetting.image.data.attributes.url}`}
                    width="100%"
                    alt="banner"
                  />
                )}
                {StoneSetting && (
                  <StyledStackContent>
                    <Stack spacing={2}>
                      <Typography
                        variant="diamondTabFont"
                        sx={{ fontWeight: "800" }}
                      >
                        {StoneSetting.title}
                      </Typography>
                      <Typography variant="staticText">
                        <ReactMarkdown remarkGfm={[remarkGfm]}>
                          {StoneSetting.body}
                        </ReactMarkdown>
                      </Typography>
                    </Stack>
                    <DomLink
                      to={{
                        pathname: "/melee-diamonds",
                      }}
                      className="center"
                    >
                      <ButtonLabel label={"Search Our Melee Selection"} />
                    </DomLink>
                    <DomLink
                      to={{
                        pathname: "/stone-setting-submission-form",
                      }}
                      className="center"
                    >
                      <ButtonLabel label={"Submit Stones For Setting"} />
                    </DomLink>
                  </StyledStackContent>
                )}
              </StyledStackCenter>
              <Divider flexItem />
              <Stack spacing={1} alignItems="center" justifyContent="center">
                <Typography variant="articleTitle">Questions?</Typography>
                <DomLink
                  to={{
                    pathname: "/Frequently-Asked-Questions",
                  }}
                  className="center"
                >
                  <Typography
                    variant="diamondTabFont"
                    sx={{
                      textDecoration: "underline",
                    }}
                  >
                    CLICK HERE
                  </Typography>
                </DomLink>
                <Typography variant="diamondTabFont">
                  for our frequently asked questions or contact one of our
                  specialists:
                </Typography>
                <Typography variant="diamondTabFont">
                  auction@hooverandstrong.com
                </Typography>
                <Typography variant="diamondTabFont">
                  1-800-759-9997 ext. 485
                </Typography>
              </Stack>
              <Divider flexItem />
              <Typography variant="mobileGreenRectangle" align="center">
                {GreenRectangle.text}
              </Typography>
              <LandingFooter icons={FooterIcons} />
            </StyledStackCenter>
          </Mobile>
          <Default>
            <StyledStackCenter>
              {Banner && (
                <img
                  src={`${frontImageUrl}${Banner.url}`}
                  width="100%"
                  alt="banner"
                />
              )}
              <Typography variant="articleTitle">Diamond Services</Typography>
              <StyledStackRow>
                {MeleePurchasing && (
                  <StyledStackContent>
                    <Stack spacing={2}>
                      <Typography
                        variant="diamondTabFont"
                        sx={{ fontWeight: "800" }}
                      >
                        {MeleePurchasing.title}
                      </Typography>
                      <Typography variant="staticText">
                        <ReactMarkdown remarkGfm={[remarkGfm]}>
                          {MeleePurchasing.body}
                        </ReactMarkdown>
                      </Typography>
                    </Stack>
                    <DomLink
                      to={{
                        pathname: "/stone-purchasing-service",
                      }}
                      className="center"
                    >
                      <ButtonLabel label={"Learn More"} />
                    </DomLink>
                  </StyledStackContent>
                )}
                {MeleePurchasing && (
                  <iframe
                    width="100%"
                    height="315"
                    src="https://www.youtube.com/embed/TsrTeE-0imQ"
                    title="YouTube video player"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  ></iframe>
                )}
              </StyledStackRow>
              <Divider flexItem />
              <StyledImageButton
                sx={{
                  width: "100%",
                  padding: "30rem",
                }}
              >
                <StyledImageSrc
                  sx={{
                    backgroundImage: `url(${frontImageUrl}${StoneRemoval.image.data.attributes.url})`,
                  }}
                />
                <StyledImageBackdrop className="MuiImageBackdrop-root" />
                <StyledImage>
                  <Stack
                    direction="row"
                    spacing={3}
                    alignItems="center"
                    sx={{ margin: "5rem" }}
                  >
                    {StoneRemoval && (
                      <Stack
                        spacing={2}
                        alignItems="center"
                        justifyContent="center"
                      >
                        <Stack spacing={2}>
                          <Typography
                            variant="diamondTabFont"
                            sx={{ fontWeight: "800" }}
                          >
                            {StoneRemoval.title}
                          </Typography>
                          <Typography variant="staticText">
                            <ReactMarkdown remarkGfm={[remarkGfm]}>
                              {StoneRemoval.body}
                            </ReactMarkdown>
                          </Typography>
                        </Stack>
                        <Link
                          href="https://cms.hooverandstrong.com/uploads/Refining_Chain_Custody_Form2_24_23_4f71403791.pdf"
                          className="center"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <ButtonLabel label={"Submit Stone Removal Lot"} />
                        </Link>
                      </Stack>
                    )}
                  </Stack>
                </StyledImage>
              </StyledImageButton>
              <Divider flexItem />
              <StyledStackRow>
                {StoneFrosting && (
                  <img
                    src={`${frontImageUrl}${StoneFrosting.image.data.attributes.url}`}
                    width="100%"
                    alt="banner"
                  />
                )}
                {StoneFrosting && (
                  <StyledStackContent>
                    <Stack spacing={2}>
                      <Typography
                        variant="diamondTabFont"
                        sx={{ fontWeight: "800" }}
                      >
                        {StoneFrosting.title}
                      </Typography>
                      <Typography variant="staticText">
                        <ReactMarkdown remarkGfm={[remarkGfm]}>
                          {StoneFrosting.body}
                        </ReactMarkdown>
                      </Typography>
                    </Stack>
                    <DomLink
                      to={{
                        pathname: "/lg-screening-cz-frosting-form",
                      }}
                      className="center"
                    >
                      <ButtonLabel label={"Submit Stones For Frosting"} />
                    </DomLink>
                  </StyledStackContent>
                )}
              </StyledStackRow>
              <Divider flexItem />
              <StyledStackRow>
                {StoneScreening && (
                  <StyledStackContent>
                    <Stack spacing={2}>
                      <Typography
                        variant="diamondTabFont"
                        sx={{ fontWeight: "800" }}
                      >
                        {StoneScreening.title}
                      </Typography>
                      <Typography variant="staticText">
                        <ReactMarkdown remarkGfm={[remarkGfm]}>
                          {StoneScreening.body}
                        </ReactMarkdown>
                      </Typography>
                    </Stack>
                    <DomLink
                      to={{
                        pathname: "/lg-screening-cz-frosting-form",
                      }}
                      className="center"
                    >
                      <ButtonLabel label={"Submit Stones For Screening"} />
                    </DomLink>
                  </StyledStackContent>
                )}
              </StyledStackRow>
              <Divider flexItem />
              <StyledStackRow>
                {StoneSetting && (
                  <img
                    src={`${frontImageUrl}${StoneSetting.image.data.attributes.url}`}
                    width="100%"
                    alt="banner"
                  />
                )}
                {StoneSetting && (
                  <StyledStackContent>
                    <Stack spacing={2}>
                      <Typography
                        variant="diamondTabFont"
                        sx={{ fontWeight: "800" }}
                      >
                        {StoneSetting.title}
                      </Typography>
                      <Typography variant="staticText">
                        <ReactMarkdown remarkGfm={[remarkGfm]}>
                          {StoneSetting.body}
                        </ReactMarkdown>
                      </Typography>
                    </Stack>
                    <DomLink
                      to={{
                        pathname: "/melee-diamonds",
                      }}
                      className="center"
                    >
                      <ButtonLabel label={"Search Our Melee Selection"} />
                    </DomLink>
                    <DomLink
                      to={{
                        pathname: "/stone-setting-submission-form",
                      }}
                      className="center"
                    >
                      <ButtonLabel label={"Submit Stones For Setting"} />
                    </DomLink>
                  </StyledStackContent>
                )}
              </StyledStackRow>
              <Divider flexItem />
              <Stack spacing={1} alignItems="center" justifyContent="center">
                <Typography variant="articleTitle">Questions?</Typography>
                <Stack direction="row" spacing={1}>
                  <DomLink
                    to={{
                      pathname: "/Frequently-Asked-Questions",
                    }}
                    className="center"
                  >
                    <Typography
                      variant="diamondTabFont"
                      sx={{
                        textDecoration: "underline",
                      }}
                    >
                      CLICK HERE
                    </Typography>
                  </DomLink>
                  <Typography variant="diamondTabFont">
                    for our frequently asked questions or contact one of our
                    specialists:
                  </Typography>
                </Stack>
                <Typography variant="diamondTabFont">
                  auction@hooverandstrong.com
                </Typography>
                <Typography variant="diamondTabFont">
                  1-800-759-9997 ext. 485
                </Typography>
              </Stack>
              <Divider flexItem />
              <Typography variant="greenRectangle" align="center">
                {GreenRectangle.text}
              </Typography>
              <LandingFooter icons={FooterIcons} />
            </StyledStackCenter>
          </Default>
        </Container>
      )}
    </>
  );
}
