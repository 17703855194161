// Third-party libraries
import React from "react";
import { Stack, Typography, TextField } from "@mui/material";

// Not sure if we need this?
import "react-datepicker/dist/react-datepicker.css";

export default function InputField({ register, name, errors, field, ...rest }) {
  return (
    <Stack sx={{ margin: "1rem" }}>
      <Typography sx={{ fontWeight: "700", paddingBottom: ".3rem" }}>
        {field.label}
      </Typography>
      <TextField
        {...register(field.name)}
        {...rest}
        error={errors[field.name] ? true : false}
        helperText={errors[field.name] ? errors[field.name].message : ""}
        color="primary"
        size="small"
        defaultValue={field.default ? field.default : ""}
        sx={{
          width: "100%",
          margin: "unset",
        }}
      />
    </Stack>
  );
}
