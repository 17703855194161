// Third-party libraries
import React, { useEffect, useReducer } from "react";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";
import remarkGfm from "remark-gfm";
import toast from "react-hot-toast";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import {
  Container,
  Stack,
  Typography,
  Backdrop,
  CircularProgress,
  Button,
  TextField,
  FormControl,
  Select,
  MenuItem,
  FormHelperText,
} from "@mui/material";

// Local modules
import { Default, Mobile } from "../../../utils/deviceHelpers";
import { FormContext } from "../../../utils/stateHandlers/contexts";
import { formReducer } from "../../../utils/stateHandlers/reducers";
import states from "../../../utils/stateHandlers/initialStates";
import { api } from "../../../utils";

const schema = yup.object().shape({
  Version: yup.string(),
  ContactName: yup.string().required("Contact Name is Required"),
  CompanyName: yup.string().required("Company Name is Required"),
  Email: yup.string().required("Email is Required"),
  Phone: yup.string().required("Phone Number is Required"),
  Street1: yup.string().required("Address is Required"),
  Street2: yup.string(),
  City: yup.string().required("City is Required"),
  Region: yup
    .string()
    .test(
      "state-not-selected",
      "State is Required",
      (value) => value !== "None"
    )
    .required("State is Required"),
  PostalCode: yup.string().required("Zip Code is Required"),
  CountryCode: yup.string(),
  PackageType: yup
    .string()
    .test(
      "package-type-not-selected",
      "Package Type is Required",
      (value) => value !== "None"
    )
    .required("Package Type is Required"),
  DropoffType: yup
    .string()
    .test(
      "dropoff-option-not-selected",
      "Dropoff Option is Required",
      (value) => value !== "None"
    )
    .required("Dropoff Option is Required"),
  Weight: yup.string().required("Weight of Package is Required"),
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

export default function RefiningShippingForm(props) {
  const [shippingState, dispatch] = useReducer(
    formReducer,
    states.initialFormState
  );
  const { isLoaded, fields, formtext, formtitle, formInfo, addressFields } =
    shippingState;
  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const InputField = (field) => (
    <Stack sx={{ margin: "1rem" }}>
      <Typography variant="navText">
        {field.name === "Street1"
          ? "Address"
          : field.name === "Street2"
          ? "Street 2"
          : field.name === "PostalCode"
          ? "Zip Code"
          : field.label}
      </Typography>
      <TextField
        {...register(field.name)}
        error={errors[field.name] ? true : false}
        helperText={errors[field.name] ? errors[field.name].message : ""}
        value={Object.values(formInfo).find(function (formField) {
          return formField === field.name;
        })}
        color="primary"
        size="small"
        sx={{
          width: "100%",
          margin: "unset",
        }}
      />
    </Stack>
  );

  const SelectField = (field) => (
    <Stack sx={{ margin: "1rem" }}>
      <Typography variant="navText">
        {field.label === "Region" ? "State" : field.label}
      </Typography>
      <FormControl
        {...register(field.name)}
        error={errors[field.name] ? true : false}
      >
        <Select
          key={field.name}
          size="small"
          defaultValue="None"
          MenuProps={{
            PaperProps: {
              sx: {
                border: "1px solid #939598",
                borderRadius: "0",
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
              },
            },
          }}
          onChange={(e) =>
            setValue(field.name, e.target.value, { shouldValidate: true })
          }
        >
          <MenuItem value="None">
            <em>Select...</em>
          </MenuItem>
          {field.values &&
            field.values &&
            field.values.map((option) => (
              <MenuItem value={option.value} key={option.value}>
                {option.label}
              </MenuItem>
            ))}
        </Select>
        <FormHelperText>
          {errors[field.name] ? errors[field.name].message : ""}
        </FormHelperText>
      </FormControl>
    </Stack>
  );

  function submitForm(data) {
    const formThings = {
      Version: "",
      ContactName: data.ContactName,
      CompanyName: data.CompanyName,
      Email: data.Email,
      Phone: data.Phone,
      Address: {
        Street1: data.Street1,
        Street2: data.Street2,
        City: data.City,
        Region: data.Region,
        PostalCode: data.PostalCode,
        CountryCode: "US",
      },
      PackageType: data.PackageType,
      DropoffType: data.DropoffType,
      Weight: data.Weight,
    };
    api.pdfDownload(`forms/shipping/submit`, formThings).then(async (res) => {
      if (res.status !== 200) {
        const parsedErrors = JSON.parse(await res.response.data.text());
        const errorMessage = [];
        for (const errorKey in parsedErrors.errors) {
          errorMessage.push(parsedErrors.errors[errorKey]);
        }
        toast.error(
          `There was an error processing your request. ${errorMessage.toString()}`
        );
      } else {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "shipping-label.pdf"); //or any other extension
        document.body.appendChild(link);
        link.click();
      }
    });
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    api.fetchStrapi(`/forms/5?populate=*`).then((response) => {
      dispatch({
        type: "setFormCopy",
        payload: {
          formtitle: response.data.data.attributes.formtitle,
          formtext: response.data.data.attributes.formtext,
        },
      });
    });

    api.fetch(`forms/shipping/fields`).then((response) => {
      const fields = response.data.fields.flat();
      const removeBlankLabels = fields.filter(function (field) {
        return field.label !== "";
      });
      const removeAddress = removeBlankLabels.filter(function (field) {
        return field.label !== "Address";
      });
      const addressFields = fields.filter(function (field) {
        return field.label === "" || field.label === "Address";
      });
      const fixAddressFieldNames = addressFields.map(function (af) {
        const newName = af.name.split(".", 2);
        return {
          ...af,
          name: newName[1],
          label: newName[1],
        };
      });
      dispatch({ type: "setFields", payload: removeAddress });
      dispatch({ type: "setAddressFields", payload: fixAddressFieldNames });
      fields.forEach(function (field) {
        dispatch({
          type: "updateFormInfo",
          field: field.name,
          payload: field.default,
        });
      });
      dispatch({ type: "setIsLoaded", payload: true });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FormContext.Provider value={{ shippingState, dispatch }}>
      {!isLoaded ? (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={true}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : (
        <Container maxWidth="md" className="careers-text">
          <Mobile>
            <form onSubmit={handleSubmit(submitForm)}>
              <Stack spacing={3} sx={{ marginBottom: "3rem" }}>
                <Typography variant="articleTitle" className="center">
                  {formtitle}
                </Typography>
                <Typography variant="articleText" sx={{ marginBottom: "1rem" }}>
                  <ReactMarkdown
                    children={formtext}
                    remarkPlugins={[remarkGfm]}
                  />
                </Typography>
                <Typography variant="h6">Company Information:</Typography>
                {fields &&
                  fields.map((field) => (
                    <React.Fragment key={field.name}>
                      {field.template === "text"
                        ? InputField(field)
                        : field.template === "phone"
                        ? InputField(field)
                        : field.template === "number"
                        ? InputField(field)
                        : field.template === "dropdown"
                        ? SelectField(field)
                        : ""}
                    </React.Fragment>
                  ))}
                <Typography variant="h6">Return Shipping Address:</Typography>
                {addressFields &&
                  addressFields.map((field) => (
                    <React.Fragment key={field.name}>
                      {field.template === "text"
                        ? InputField(field)
                        : field.template === "phone"
                        ? InputField(field)
                        : field.template === "dropdown"
                        ? SelectField(field)
                        : ""}
                    </React.Fragment>
                  ))}
                <Button
                  variant="contained"
                  type="submit"
                  endIcon={<FileDownloadIcon />}
                >
                  Download
                </Button>
              </Stack>
            </form>
          </Mobile>
          <Default>
            <form onSubmit={handleSubmit(submitForm)}>
              <Stack spacing={3}>
                <Typography variant="articleTitle" className="center">
                  {formtitle}
                </Typography>
                <Typography variant="articleText" sx={{ marginBottom: "1rem" }}>
                  <ReactMarkdown
                    children={formtext}
                    remarkPlugins={[remarkGfm]}
                  />
                </Typography>

                <Stack direction="row" spacing={2}>
                  <Stack width="100%" spacing={2}>
                    <Typography variant="h6">Company Information:</Typography>
                    {fields &&
                      fields.map((field) => (
                        <React.Fragment key={field.name}>
                          {field.template === "text"
                            ? InputField(field)
                            : field.template === "phone"
                            ? InputField(field)
                            : field.template === "number"
                            ? InputField(field)
                            : field.template === "dropdown"
                            ? SelectField(field)
                            : ""}
                        </React.Fragment>
                      ))}
                  </Stack>
                  <Stack width="100%" spacing={2}>
                    <Typography variant="h6">
                      Return Shipping Address:
                    </Typography>
                    {addressFields &&
                      addressFields.map((field) => (
                        <React.Fragment key={field.name}>
                          {field.template === "text"
                            ? InputField(field)
                            : field.template === "phone"
                            ? InputField(field)
                            : field.template === "dropdown"
                            ? SelectField(field)
                            : ""}
                        </React.Fragment>
                      ))}
                  </Stack>
                </Stack>
                <Button
                  variant="contained"
                  type="submit"
                  endIcon={<FileDownloadIcon />}
                >
                  Download
                </Button>
              </Stack>
            </form>
          </Default>
        </Container>
      )}
    </FormContext.Provider>
  );
}
