// Third-party libraries
import React from "react";
import { Stack, Divider, Typography } from "@mui/material";

// Local modules
import { Mobile, Default } from "../../../utils/deviceHelpers";

export default function CareersFooter(props) {
  return (
    <div className="careers-footer">
      <Stack spacing={2}>
        <Typography variant="articleTitle">
          We Are Always On The Lookout For Great Talent!
        </Typography>
        <p>
          We update our careers site weekly. We would love for you to apply for
          a posted position or internship with Hoover and Strong. All applicants
          must complete the online application.
        </p>
        <p>
          We cannot accept resumes without the online application. When
          applying, please include your salary requirements.
        </p>
        <p>
          <strong>
            Applicants have rights under these Federal Employment Laws:
          </strong>
        </p>
        <br />
        <Mobile>
          <Stack
            direction="row"
            divider={
              <Divider
                orientation="vertical"
                flexItem
                sx={{ borderColor: "black" }}
              />
            }
            spacing={2}
            justifyContent="center"
            sx={{ paddingBottom: "8rem" }}
          >
            <a
              href="http://api2.hooverandstrong.com/media/pdfs/FMLA-EmployeeRightsPoster.pdf"
              target="new"
            >
              <strong>Family and Medical Leave Act </strong>
            </a>
            <a
              href="http://api2.hooverandstrong.com/media/pdfs/EEOLawPoster.pdf"
              target="new"
            >
              <strong>Equal Employment Opportunity </strong>
            </a>
            <a
              href="http://api2.hooverandstrong.com/media/pdfs/EPPA-PolygraphProtectionActPoster.pdf"
              target="new"
            >
              <strong>Employee Polygraph Protection Act</strong>
            </a>
          </Stack>
        </Mobile>
        <Default>
          <Stack
            direction="row"
            divider={
              <Divider
                orientation="vertical"
                flexItem
                sx={{ borderColor: "black" }}
              />
            }
            spacing={2}
            justifyContent="center"
          >
            <a
              href="http://api2.hooverandstrong.com/media/pdfs/FMLA-EmployeeRightsPoster.pdf"
              target="new"
            >
              <strong>Family and Medical Leave Act </strong>
            </a>
            <a
              href="http://api2.hooverandstrong.com/media/pdfs/EEOLawPoster.pdf"
              target="new"
            >
              <strong>Equal Employment Opportunity </strong>
            </a>
            <a
              href="http://api2.hooverandstrong.com/media/pdfs/EPPA-PolygraphProtectionActPoster.pdf"
              target="new"
            >
              <strong>Employee Polygraph Protection Act</strong>
            </a>
          </Stack>
        </Default>
      </Stack>
    </div>
  );
}
