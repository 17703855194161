import React from "react";

const RoundProfile = (props) => {
  const viewBoxWidth = 2960;
  const viewBoxHeight = 1856;

  const lineColor = "#4a4a4a";
  const strokeWidth1 = props.strokeWidthFactor * viewBoxWidth;
  const strokeWidth2 = "12";

  const x = (percentX) => percentX * viewBoxWidth;
  const y = (percentY) => percentY * viewBoxHeight;

  const table = Number(props.diamond.Table).toFixed(0);
  const depthPercent = Number(props.diamond.Depth).toFixed(1);
  const girdle = props.diamond.Girdle || "";
  const girdleSpaceIndex = girdle.indexOf(" ");
  const girdle1 =
    girdleSpaceIndex > 0 ? girdle.slice(0, girdleSpaceIndex) : girdle;
  const girdle2 = girdleSpaceIndex > 0 ? girdle.slice(girdleSpaceIndex) : "";

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox={"0 0 " + viewBoxWidth + " " + viewBoxHeight}
      width={props.width}
      height={props.height}
      preserveAspectRatio={props.preserveAspectRatio}
    >
      <g>
        <g>
          <polyline
            fill="none"
            stroke={props.color}
            strokeWidth={strokeWidth1}
            strokeLinejoin="round"
            strokeMiterlimit={strokeWidth2}
            points="
			63.417,545.146 169.958,551.37 217.903,527.398 270.643,548.973 438.449,548.973 503.175,513.014 608.653,548.973 
			865.157,548.973 992.211,508.22 1095.292,548.973 1406.934,548.973 1488,517.809 1066.526,186.99 1488,59.698 716.459,59.698 
			41.231,561.638 1488,1795.537 1006.595,613.698 865.157,575.342 613.447,575.342 524.75,613.698 431.257,572.945 273.04,572.945 
			220.3,616.095 155.575,572.945 54.49,572.945 		"
          />
          <polyline
            fill="none"
            stroke={props.color}
            strokeWidth={strokeWidth1}
            strokeLinejoin="round"
            strokeMiterlimit={strokeWidth2}
            points="
			1006.595,613.698 1095.604,578.541 1406.108,578.541 1488,608 1324.215,1393.449 524.75,613.698 1068.35,1437.632 		"
          />

          <line
            fill="none"
            stroke={props.color}
            strokeWidth={strokeWidth1}
            strokeLinejoin="round"
            strokeMiterlimit={strokeWidth2}
            x1="990.565"
            y1="1319.734"
            x2="220.3"
            y2="616.095"
          />
          <polyline
            fill="none"
            stroke={props.color}
            strokeWidth={strokeWidth1}
            strokeLinejoin="round"
            strokeMiterlimit={strokeWidth2}
            points="
			992.211,508.22 1066.526,186.99 929.276,59.698 547.6,185.222 217.903,527.398 		"
          />
          <polyline
            fill="none"
            stroke={props.color}
            strokeWidth={strokeWidth1}
            strokeLinejoin="round"
            strokeMiterlimit={strokeWidth2}
            points="
			547.6,185.222 503.175,513.014 1066.526,186.99 		"
          />
        </g>
        <g>
          <polyline
            fill="none"
            stroke={props.color}
            strokeWidth={strokeWidth1}
            strokeLinejoin="round"
            strokeMiterlimit={strokeWidth2}
            points="
			2912.584,545.146 2806.042,551.37 2758.098,527.398 2705.358,548.973 2537.552,548.973 2472.826,513.014 2367.348,548.973 
			2110.843,548.973 1983.789,508.22 1880.708,548.973 1569.066,548.973 1488,517.809 1909.475,186.99 1488,59.698 2259.541,59.698 
			2934.77,561.638 1488,1795.537 1969.406,613.698 2110.843,575.342 2362.553,575.342 2451.251,613.698 2544.743,572.945 
			2702.961,572.945 2755.7,616.095 2820.426,572.945 2921.511,572.945 		"
          />
          <polyline
            fill="none"
            stroke={props.color}
            strokeWidth={strokeWidth1}
            strokeLinejoin="round"
            strokeMiterlimit={strokeWidth2}
            points="
			1969.406,613.698 1880.396,578.541 1569.893,578.541 1488,608 1651.785,1393.449 2451.251,613.698 1907.65,1437.632 		"
          />

          <line
            fill="none"
            stroke={props.color}
            strokeWidth={strokeWidth1}
            strokeLinejoin="round"
            strokeMiterlimit={strokeWidth2}
            x1="1985.436"
            y1="1319.734"
            x2="2755.7"
            y2="616.095"
          />
          <polyline
            fill="none"
            stroke={props.color}
            strokeWidth={strokeWidth1}
            strokeLinejoin="round"
            strokeMiterlimit={strokeWidth2}
            points="
			1983.789,508.22 1909.475,186.99 2046.725,59.698 2428.401,185.222 2758.098,527.398 		"
          />
          <polyline
            fill="none"
            stroke={props.color}
            strokeWidth={strokeWidth1}
            strokeLinejoin="round"
            strokeMiterlimit={strokeWidth2}
            points="
			2428.401,185.222 2472.826,513.014 1909.475,186.99 		"
          />
        </g>
      </g>

      {props.show_diagram && (
        <>
          {" "}
          <line
            x1={x(0.237)}
            x2={x(0.768)}
            y1={y(-0.07)}
            y2={y(-0.07)}
            stroke={lineColor}
            strokeWidth={strokeWidth2}
          ></line>
          <line
            x1={x(0.237)}
            x2={x(0.237)}
            y1={y(-0.1)}
            y2={y(-0.04)}
            stroke={lineColor}
            strokeWidth={strokeWidth2}
          ></line>
          <line
            x1={x(0.768)}
            x2={x(0.768)}
            y1={y(-0.1)}
            y2={y(-0.04)}
            stroke={lineColor}
            strokeWidth={strokeWidth2}
          ></line>
          <line
            x1={x(0.1)}
            y1={y(0.02)}
            x2={x(0.1)}
            y2={y(0.97)}
            stroke={lineColor}
            strokeWidth={strokeWidth2}
          ></line>
          <line
            x1={x(0.08)}
            y1={y(0.02)}
            x2={x(0.12)}
            y2={y(0.02)}
            stroke={lineColor}
            strokeWidth={strokeWidth2}
          ></line>
          <line
            x1={x(0.08)}
            y1={y(0.97)}
            x2={x(0.12)}
            y2={y(0.97)}
            stroke={lineColor}
            strokeWidth={strokeWidth2}
          ></line>
          <line
            x1={x(0.9)}
            y1={y(0.02)}
            x2={x(0.9)}
            y2={y(0.97)}
            stroke={lineColor}
            strokeWidth={strokeWidth2}
          ></line>
          <line
            x1={x(0.88)}
            y1={y(0.02)}
            x2={x(0.92)}
            y2={y(0.02)}
            stroke={lineColor}
            strokeWidth={strokeWidth2}
          ></line>
          <line
            x1={x(0.88)}
            y1={y(0.97)}
            x2={x(0.92)}
            y2={y(0.97)}
            stroke={lineColor}
            strokeWidth={strokeWidth2}
          ></line>
          <circle
            cx={x(0.502)}
            cy={y(0.955)}
            r={y(0.05)}
            fillOpacity="0"
            stroke={lineColor}
            strokeWidth={strokeWidth2}
          />
          <line
            x1={x(0.502)}
            y1={y(1.005)}
            x2={x(0.502)}
            y2={y(1.12)}
            stroke={lineColor}
            strokeWidth={strokeWidth2}
          ></line>
          <line
            x1={x(0.502)}
            y1={y(1.12)}
            x2={x(0.54)}
            y2={y(1.12)}
            stroke={lineColor}
            strokeWidth={strokeWidth2}
          ></line>
          <circle
            cx={x(0.4)}
            cy={y(0.305)}
            r={y(0.05)}
            fillOpacity="0"
            stroke={lineColor}
            strokeWidth={strokeWidth2}
          />
          <line
            x1={x(0.4)}
            y1={y(0.36)}
            x2={x(0.4)}
            y2={y(1.12)}
            stroke={lineColor}
            strokeWidth={strokeWidth2}
          ></line>
          <line
            x1={x(0.4)}
            y1={y(1.12)}
            x2={x(0.38)}
            y2={y(1.12)}
            stroke={lineColor}
            strokeWidth={strokeWidth2}
          ></line>
          <rect
            x={x(0.0)}
            y={y(0.63)}
            width={x(0.22)}
            height={y(0.2)}
            rx="0.8"
            fill="white"
          ></rect>
          <text
            x={x(0.03)}
            y={y(0.71)}
            fontSize={y(0.068)}
            stroke={lineColor}
            strokeWidth="0.5"
          >
            Depth:
          </text>
          <text
            x={x(0.02)}
            y={y(0.8)}
            fontSize={y(0.068)}
            stroke={lineColor}
            strokeWidth="0.5"
          >
            {props.diamond.Measurement3}
          </text>
          <rect
            x={x(0.8)}
            y={y(0.63)}
            width={x(0.22)}
            height={y(0.2)}
            rx="0.8"
            fill="white"
          ></rect>
          <text
            x={x(0.83)}
            y={y(0.71)}
            fontSize={y(0.068)}
            stroke={lineColor}
            strokeWidth="0.5"
          >
            Depth:
          </text>
          <text
            x={x(0.83)}
            y={y(0.8)}
            fontSize={y(0.068)}
            stroke={lineColor}
            strokeWidth="0.5"
          >
            {depthPercent}%
          </text>
          <text
            x={x(0.4)}
            y={y(-0.1)}
            fontSize={y(0.068)}
            stroke={lineColor}
            strokeWidth="0.5"
          >
            Table: {table}%
          </text>
          <text
            x={x(0.0)}
            y={y(1.14)}
            fontSize={y(0.068)}
            stroke={lineColor}
            strokeWidth="0.5"
          >
            Girdle: {girdle1}
          </text>
          <text
            x={x(0.0)}
            y={y(1.23)}
            fontSize={y(0.068)}
            stroke={lineColor}
            strokeWidth="0.5"
          >
            {girdle2}
          </text>
          <text
            x={x(0.55)}
            y={y(1.13)}
            fontSize={y(0.068)}
            stroke={lineColor}
            strokeWidth="0.5"
          >
            Culet: {props.diamond.Culet}
          </text>
        </>
      )}
    </svg>
  );
};

export default RoundProfile;
