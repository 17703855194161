import React from "react";

const Rose = (props) => {
  const viewBoxWidth = 85.7;
  const viewBoxHeight = 86.1;

  const lengthXPrcnt = 0.2;
  const lengthYPrcnt = 0.6;

  const widthXPrcnt = 0.55;
  const widthYPrcnt = 0.25;

  const lengthX = lengthXPrcnt * viewBoxWidth;
  const lengthY = lengthYPrcnt * viewBoxHeight;

  const widthX = widthXPrcnt * viewBoxWidth;
  const widthY = widthYPrcnt * viewBoxHeight;

  const x = (percentX) => percentX * viewBoxWidth;
  const y = (percentY) => percentY * viewBoxHeight;

  const lineColor = "#4a4a4a";

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox={"0 0 " + viewBoxWidth + " " + viewBoxHeight}
      width={props.width}
      height={props.height}
      preserveAspectRatio={props.preserveAspectRatio}
    >
      <path
        fill={props.color}
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="M43.6,1.7L43.6,1.7C43.5,1.7,43.5,1.7,43.6,1.7c-0.1,0-0.1,0-0.1,0h-0.1L23.1,7.1l0,0l0,0l0,0c-0.1,0-0.1,0-0.1,0.1l0,0
		l0,0H23l0,0l0,0c0,0,0,0-0.1,0v0.1h-0.1l0,0l0,0l0,0l0,0h-0.1l0,0v0.1c0,0,0,0-0.1,0l0,0L7.9,22.2l0,0v0.1c0,0,0,0-0.1,0l0,0
		c0,0.1,0,0.1,0,0.1s0,0-0.1,0.1l0,0c0,0.1,0,0.1,0,0.1l-0.1,0.1l0,0l0,0c0,0.1,0,0.1,0,0.1L2.3,42.8H2.2v0.1l0,0l0,0l0,0
		c0,0,0,0,0,0.1l0,0l0,0c0,0,0,0,0,0.1c0,0,0,0,0,0.1l0,0l0,0c0,0,0,0,0,0.1l0,0l0,0c0,0.1,0,0.1,0,0.1l0,0v0.1l0,0l5.5,20.3l0,0
		v0.1l0,0c0,0.1,0.1,0.1,0.1,0.1l0,0c0,0.1,0,0.1,0.1,0.1l0,0v0.1c0,0,0,0,0.1,0c0,0,0,0,0,0.1l0,0l14.9,14.9l0,0l0,0l0,0
		c0.1,0,0.1,0,0.1,0l0,0v0.1l0,0c0,0,0,0,0.1,0l0,0l0,0l0,0v0.1c0.1,0,0.1,0,0.1,0l0,0l0,0l0,0c0,0,0,0,0.1,0l0,0l0,0l0,0
		C23,79,23,79,23,79l0,0l0,0l0,0l20.3,5.4l0,0l0.1,0.1l0,0c0.1,0,0.1,0,0.1,0c0.1,0,0.1,0,0.1,0l0,0c0,0,0,0,0.1,0c0,0,0,0,0.1-0.1
		l0,0l0,0l0,0L64.1,79l0,0l0,0l0,0l0,0c0.1,0,0.1,0,0.1-0.1l0,0l0,0c0.1,0,0.1,0,0.1,0l0,0l0,0c0,0,0,0,0.1,0l0,0c0,0,0,0,0-0.1l0,0
		c0.1,0,0.1,0,0.1,0l0,0l0,0l0,0l0,0c0.1,0,0.1,0,0.1-0.1l0,0l0,0l0,0l0,0h0.1l0,0l14.9-14.9l0,0c0-0.1,0-0.1,0.1-0.1l0,0
		c0-0.1,0-0.1,0-0.1l0,0c0.1-0.1,0.1-0.1,0.1-0.1l0,0c0-0.1,0-0.1,0-0.1l0,0c0.1-0.1,0.1-0.1,0.1-0.1v-0.1l0,0l5.4-20.3l0,0l0,0
		c0,0,0-0.1,0.1-0.1l0,0v-0.1l0,0c0,0,0,0,0-0.1l0,0l0,0l0,0l0,0c0-0.1,0-0.1,0-0.1l0,0c0,0,0,0,0-0.1l0,0l0,0l0,0v-0.1l0,0l0,0
		v-0.1l-5.5-20.3l0,0c0-0.1,0-0.1,0-0.1l0,0c0-0.1,0-0.1-0.1-0.1c0-0.1,0-0.1,0-0.1s0,0,0-0.1l0,0c0,0-0.1,0-0.1-0.1l0,0
		c0,0,0,0-0.1,0v-0.1l0,0L64.5,7.3l0,0l0,0l0,0l0,0c-0.1,0-0.1,0-0.1,0V7.2l0,0l0,0l0,0h-0.1l0,0V7.2l0,0c-0.1,0-0.1,0-0.1,0l0,0
		l0,0l0,0l0,0c0,0,0,0-0.1,0V7.1l0,0c0,0,0,0-0.1,0l0,0c0,0,0,0-0.1,0l0,0L43.8,1.7l0,0l0,0c-0.1-0.1-0.1-0.1-0.1-0.1l0,0
		C43.7,1.6,43.7,1.6,43.6,1.7C43.6,1.6,43.6,1.6,43.6,1.7L43.6,1.7L43.6,1.7z M42.9,3.4v15.1L25.1,8.1L42.9,3.4z M44.3,3.4l17.8,4.7
		L44.3,18.5V3.4z M24,9.1l18.1,10.6l-18,10.4L24,9.1z M63.2,9.1l-0.1,21L45,19.8L63.2,9.1z M22.5,9.6l0.1,20.6L9.6,22.5L22.5,9.6z
		 M64.6,9.6l13,13l-13.1,7.6L64.6,9.6z M42.9,21v20.8l-18-10.4L42.9,21z M44.3,21l18,10.4l-18,10.4V21z M78.3,23.8l4.7,17.8
		L65.2,31.4L78.3,23.8z M8.8,23.8l13.1,7.6L4.1,41.5L8.8,23.8z M22.7,32.6v20.8L4.4,43.1L22.7,32.6z M64.5,32.6l18.3,10.4L64.5,53.5
		V32.6z M24.1,32.7l18,10.4l-18,10.4V32.7z M63,32.7v20.8L45,43.1L63,32.7z M42.9,44.3v20.8l-18-10.4L42.9,44.3z M44.3,44.3l18,10.4
		l-18,10.4V44.3z M83.1,44.5l-4.7,17.8l-13.2-7.6L83.1,44.5z M4.1,44.5l17.8,10.1L8.8,62.2L4.1,44.5z M24.1,56l18,10.4L24,77
		L24.1,56z M63,56l0.1,21L45,66.4L63,56z M22.7,56l-0.1,20.5l-13-13L22.7,56z M64.5,56l13.1,7.6l-13,13L64.5,56z M42.9,67.6v15.2
		L25.1,78L42.9,67.6z M44.3,67.6L62.1,78l-17.8,4.8V67.6z"
      />
      {props.show_diagram && (
        <>
          <line
            x1={x(0.3)}
            y1={y(0.01)}
            x2={x(0.3)}
            y2={y(0.994)}
            stroke={lineColor}
            strokeWidth=".5"
          ></line>
          <line
            x1={x(0.28)}
            y1={y(0.01)}
            x2={x(0.32)}
            y2={y(0.01)}
            stroke={lineColor}
            strokeWidth=".5"
          ></line>
          <line
            x1={x(0.28)}
            y1={y(0.994)}
            x2={x(0.32)}
            y2={y(0.994)}
            stroke={lineColor}
            strokeWidth=".5"
          ></line>
          <rect
            x={lengthX}
            y={lengthY}
            width="20"
            height="11"
            rx="0.8"
            fill="white"
          ></rect>
          <text
            x={lengthX + 2}
            y={lengthY + 4}
            fontSize=".4rem"
            stroke={lineColor}
            strokeWidth="0.01"
          >
            Length:
          </text>
          <text
            x={lengthX + 1}
            y={lengthY + 10}
            fontSize=".4rem"
            stroke={lineColor}
            strokeWidth="0.01"
          >
            {props.diamond.Measurement1}
          </text>

          <line
            x1={x(0.02)}
            y1={y(0.3)}
            x2={x(0.99)}
            y2={y(0.3)}
            stroke={lineColor}
            strokeWidth=".5"
          ></line>
          <line
            x1={x(0.02)}
            y1={y(0.28)}
            x2={x(0.02)}
            y2={y(0.32)}
            stroke={lineColor}
            strokeWidth=".5"
          ></line>
          <line
            x1={x(0.99)}
            y1={y(0.28)}
            x2={x(0.99)}
            y2={y(0.32)}
            stroke={lineColor}
            strokeWidth=".5"
          ></line>

          <rect
            x={widthX}
            y={widthY}
            width="19"
            height="11"
            rx="0.8"
            fill="white"
          ></rect>
          <text
            x={widthX + 2}
            y={widthY + 4}
            fontSize=".4rem"
            stroke={lineColor}
            strokeWidth="0.01"
          >
            Width:
          </text>
          <text
            x={widthX + 1}
            y={widthY + 10}
            fontSize=".4rem"
            stroke={lineColor}
            strokeWidth="0.01"
          >
            {props.diamond.Measurement2}
          </text>
        </>
      )}
    </svg>
  );
};

export default Rose;
