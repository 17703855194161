// Third-party libraries
import React from "react";
import { Link as DomLink, useLocation } from "react-router-dom";
import Typography from "@mui/material/Typography";

export default function GALink(props) {
  const location = useLocation();

  return (
    <DomLink
      to={{
        pathname: props.pathTo,
        state: props.state,
      }}
      onClick={() => {
        window.gtag("event", props.gaEventName, {
          env: process.env.NODE_ENV,
          page_path: location.pathname + location.search + location.hash,
          directing_to: props.pathTo,
        });
      }}
    >
      <Typography
        variant={props.textVariant}
        color={props.textColor}
        underline={props.underline}
      >
        {props.linkIcon} {props.linkText}
      </Typography>
    </DomLink>
  );
}
