import React from "react";
import { Typography } from "@mui/material";

import HorizontalScrollList from "../components/HorizontalScrollList";

function CompatibleProduct(props) {
  return (
    <>
      <Typography variant="h2" sx={{ marginTop: "2rem" }}>
        Compatible Products
      </Typography>
      {props.compatibleProducts && (
        <HorizontalScrollList
          loadedStyles={props.compatibleProducts}
          compatible={true}
        />
      )}
    </>
  );
}

export default CompatibleProduct;
