const initialCartState = {
  cart: [],
  cartCount: 0,
  showCheckout: false,
  fields: [],
  order: null,
  totals: null,
  placedAt: "",
  shippingAddress: {},
  submitted: false,
  pageLoaded: false,
  fieldsReloaded: false,
  cartLoaded: true,
  specialInstructions: "",
  newAddress: {
    customer_key: "",
    name: "",
    street_1: "",
    street_2: "",
    city: "",
    state: "",
    zip_code: "",
    country_code: "US",
  },
  newCreditCard: {
    Name: "",
    Number: "",
    ExpirationMonth: "",
    ExpirationYear: "",
    Cvv: "",
  },
};

const initialCreateAccountState = {
  pageLoaded: false,
  formFilled: false,
  accountCreated: false,
  formFields: [],
  reCAPTCHA: {},
  formInfo: {},
  businessLicense: {},
  formtitle: "",
  formtext: "",
  formimage: "",
};

const initialFormState = {
  formtitle: "",
  formtext: "",
  formimage: null,
  diamondshippingtitle: "",
  diamondshippingtext: "",
  shippingstates: [],
  isLoaded: false,
  fields: [],
  formInfo: {},
  addressFields: [],
  submitted: false,
  message: "",
  estimates: [],
  quoteId: 0,
  officers: [],
  officerFieldValues: {},
  references: [],
  referenceFieldValues: {},
  marketingFields: [],
};

const initialProductSearchState = {
  fields: [],
};

const initialProductLandingState = {
  products: [],
  filters: [],
  searchQuery: initialProductSearchState,
  pageLoaded: false,
  itemsLoaded: false,
  showFilters: false,
  bestSellers: false,
  clearAll: false,
};

const initialProductState = {
  pageLoaded: false,
  priceLoaded: false,
  openLoginModal: false,
  message: "",
  messageType: "",
  style: "",
  productGroup: "",
  specialInstructions: "",
  productInfo: {},
  productFields: [],
  productTreatments: [],
  compatibleProducts: [],
  relatedProducts: [],
  icons: [],
  productSeries: {},
  price: null,
  productDetails: [],
  images: [],
  priceBreaks: [],
  daysToShip: "",
  estimatedShipDate: "",
  mto: false,
  bubbles: [],
  metalColumns: {
    left: "",
    middle: "",
    right: "",
  },
  TopText: "",
  BottomText: "",
  ChartSizeSmall: false,
  strapiChart: null,
  sizeType: "standard",
  charmDimension: "",
  circleDimension: "",
  clear: false,
  validationErrors: [],
  advertisement: false,
};

const initialBarState = {
  message: "",
  messageType: "",
  pageLoaded: false,
  priceLoaded: false,
  queryString: "",
  quantity: 1,
  quantity_unit: "EA",
  setting: {
    style: "",
    settingInfo: {
      description: "",
      dimension: "",
      caratSize: "",
      customer_key: null,
      errors: [],
      fingerSize: "",
      material: "",
      message: "",
      product: "",
      quantity: null,
      quantityUnit: "",
      treatments: "",
    },
    settingFields: [],
    settingTreatments: [],
    compatible_styles: [],
    related_items: [],
    icons: [],
    settingSeries: {},
  },
  shank: {
    style: "",
    shankInfo: {
      description: "",
      dimension: "",
      caratSize: "",
      customer_key: null,
      errors: [],
      fingerSize: "",
      material: "",
      message: "",
      product: "",
      quantity: null,
      quantityUnit: "",
      treatments: "",
    },
    shankFields: [],
    shankTreatments: [],
    compatible_styles: [],
    related_items: [],
    icons: [],
    shankSeries: {},
  },
  diamond: {},
  ring: {
    price: null,
    partcode: {},
    ringDetails: [],
    images: [],
    pricebreaks: [],
    daysToShip: "",
    estimatedShipDate: "",
    mto: false,
  },
};

const initialDiamondSearch = {
  fields: [
    {
      name: "MinedOrLab",
      values: [{ name: "M" }],
    },
    {
      name: "Color",
      values: [
        { name: "D" },
        { name: "E" },
        { name: "F" },
        { name: "G" },
        { name: "H" },
        { name: "I" },
        { name: "J" },
        { name: "K" },
      ],
    },
  ],
  sort: {
    field: "Carat",
    direction: "desc",
  },
  page: 1,
  size: 50,
};

const initialDiamondSearchState = {
  pageLoaded: false,
  resultsLoaded: false,
  showAdvancedFilters: false,
  openPanel: false,
  currentTab: "Results",
  openHelpText: false,
  showCartSuccess: false,
  showFancyColorFilter: false,
  helpTextLabel: "",
  helpText: "",
  filters: [],
  advancedFilters: [],
  fancyColorFilters: [],
  tableHeaders: [],
  fancyColorHeaders: [],
  mobileTableHeaders: [],
  tabletTableHeaders: [],
  sidePanelTableHeaders: [],
  fcSidePanelHeaders: [],
  diamonds: [],
  diamondCount: "",
  recentlyViewed: [],
  selected: [],
  selectedDiamond: {},
  cartPreview: [],
  openCartPreview: false,
  cartPreviewSingle: {},
  openCartPreviewSingle: false,
  searchQuery: initialDiamondSearch,
  checked: [],
};

const initialLabDiamondSearch = {
  fields: [
    {
      name: "MinedOrLab",
      values: [{ name: "L" }],
    },
    {
      name: "Color",
      values: [
        { name: "D" },
        { name: "E" },
        { name: "F" },
        { name: "G" },
        { name: "H" },
        { name: "I" },
        { name: "J" },
        { name: "K" },
      ],
    },
  ],
  sort: {
    field: "Carat",
    direction: "desc",
  },
  page: 1,
  size: 50,
};

const initialLabDiamondSearchState = {
  pageLoaded: false,
  resultsLoaded: false,
  showAdvancedFilters: false,
  openPanel: false,
  currentTab: "Results",
  openHelpText: false,
  showCartSuccess: false,
  helpTextLabel: "",
  helpText: "",
  filters: [],
  advancedFilters: [],
  tableHeaders: [],
  mobileTableHeaders: [],
  tabletTableHeaders: [],
  sidePanelTableHeaders: [],
  diamonds: [],
  diamondCount: "",
  recentlyViewed: [],
  selected: [],
  selectedDiamond: {},
  cartPreview: [],
  openCartPreview: false,
  cartPreviewSingle: {},
  openCartPreviewSingle: false,
  searchQuery: initialLabDiamondSearch,
  checked: [],
};

const initialMeleeSearch = {
  fields: [],
  sort: {
    field: "EstimatedPrice",
    direction: "asc",
  },
  page: 1,
  size: 50,
};

const initialMeleeSearchState = {
  pageLoaded: false,
  resultsLoaded: false,
  openHelpText: false,
  showCartSuccess: false,
  standard: true,
  helpTextLabel: "",
  helpText: "",
  filters: [],
  collections: {},
  tableHeaders: [],
  mobileTableHeaders: [],
  tabletTableHeaders: [],
  diamonds: [],
  diamondCount: "",
  openCartPreview: false,
  cartPreview: {},
  searchQuery: initialMeleeSearch,
  strapiBody: "",
  strapiImage: null,
  matchedPairs: false,
};

const initialBandsState = {
  pageLoaded: false,
  priceLoaded: false,
  message: "",
  messageType: "",
  style: "",
  productGroup: "",
  specialInstructions: "",
  productInfo: {},
  productFields: [],
  builder: [],
  complex: [],
  complex1: [],
  complex2: [],
  complex3: [],
  productTreatments: [],
  compatibleProducts: [],
  relatedProducts: [],
  icons: [],
  productSeries: {},
  price: null,
  productDetails: [],
  images: [],
  priceBreaks: [],
  daysToShip: "",
  estimatedShipDate: "",
  mto: false,
  metalColumns: {
    left: "",
    middle: "",
    right: "",
  },
  TopText: "",
  BottomText: "",
  ChartSizeSmall: false,
  strapiChart: null,
  sizeType: "standard",
  clear: false,
  validationErrors: [],
  advertisement: {},
};

const states = {
  initialCartState: initialCartState,
  initialCreateAccountState: initialCreateAccountState,
  initialFormState: initialFormState,
  initialProductLandingState: initialProductLandingState,
  initialProductState: initialProductState,
  initialBarState: initialBarState,
  initialDiamondSearchState: initialDiamondSearchState,
  initialLabDiamondSearchState: initialLabDiamondSearchState,
  initialMeleeSearchState: initialMeleeSearchState,
  initialBandsState: initialBandsState,
};

export default states;
