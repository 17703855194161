// Built-in modules
import qs from "qs";
import moment from "moment/moment";

// Third-party libraries
import React, { useEffect, useState, useContext } from "react";
import parse from "html-react-parser";
import { Link as DomLink } from "react-router-dom";
import toast from "react-hot-toast";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import RefreshTwoToneIcon from "@mui/icons-material/RefreshTwoTone";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import FileDownloadTwoToneIcon from "@mui/icons-material/FileDownloadTwoTone";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  Stack,
  Typography,
  Card,
  CardHeader,
  CardContent,
  Button,
  Select,
  MenuItem,
  TextField,
  Divider,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Chip,
  Grid,
  Skeleton,
} from "@mui/material";

// Local modules
import { storage } from "../../utils";
import { Mobile, Default } from "../../utils/deviceHelpers";
import { api } from "../../utils";
import formatting from "../../utils/formatHelpers";
import { CartContext } from "../../utils/stateHandlers/contexts";

export default function UserProfile(props) {
  const { cartDispatch } = useContext(CartContext);
  const newDate = new Date();
  const [startDate, setStartDate] = useState(
    new Date(newDate.setMonth(newDate.getMonth() - 3))
  );
  const [endDate, setEndDate] = useState(new Date());
  const [status, setStatus] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [fields, setFields] = useState([]);
  const [invoices, setInvoices] = useState([]);
  const [showHistory, setShowHistory] = useState(false);
  const [historyLoading, setHistoryLoading] = useState(true);
  const currentUser = JSON.parse(storage.getStorageItem("currentUser"));

  const DateRangeField = () => (
    <>
      <Mobile>
        <Stack spacing={2}>
          <Stack>
            <div>
              <Typography sx={{ fontWeight: "700", paddingBottom: ".3rem" }}>
                Start Date
              </Typography>
            </div>
            <span style={{ border: "1px solid #939598", padding: "0.3rem" }}>
              <DatePicker
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                selectsStart
                startDate={startDate}
                endDate={endDate}
              />
            </span>
          </Stack>
          <Stack>
            <div>
              <Typography sx={{ fontWeight: "700", paddingBottom: ".3rem" }}>
                End Date
              </Typography>
            </div>
            <span style={{ border: "1px solid #939598", padding: "0.3rem" }}>
              <DatePicker
                selected={endDate}
                onChange={(date) => setEndDate(date)}
                selectsEnd
                startDate={startDate}
                endDate={endDate}
                minDate={startDate}
              />
            </span>
          </Stack>
        </Stack>
      </Mobile>
      <Default>
        <Stack direction="row" spacing={2}>
          <Stack>
            <div>
              <Typography sx={{ fontWeight: "700", paddingBottom: ".3rem" }}>
                Start Date
              </Typography>
            </div>
            <span style={{ border: "1px solid #939598", padding: "0.3rem" }}>
              <DatePicker
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                selectsStart
                startDate={startDate}
                endDate={endDate}
              />
            </span>
          </Stack>
          <Stack>
            <div>
              <Typography sx={{ fontWeight: "700", paddingBottom: ".3rem" }}>
                End Date
              </Typography>
            </div>
            <span style={{ border: "1px solid #939598", padding: "0.3rem" }}>
              <DatePicker
                selected={endDate}
                onChange={(date) => setEndDate(date)}
                selectsEnd
                startDate={startDate}
                endDate={endDate}
                minDate={startDate}
              />
            </span>
          </Stack>
        </Stack>
      </Default>
    </>
  );

  const StatusField = (field) => (
    <Stack key={field.name}>
      <div>
        <Typography sx={{ fontWeight: "700", paddingBottom: ".3rem" }}>
          {field.label}
        </Typography>
      </div>
      <Select
        key={field.name}
        defaultValue={field.default}
        size="small"
        onChange={(e) => {
          setStatus(e.target.value);
        }}
        MenuProps={{
          PaperProps: {
            sx: {
              border: "1px solid #939598",
              borderRadius: "0",
            },
          },
        }}
      >
        {field.values.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </Stack>
  );

  const SearchField = () => (
    <Stack>
      <div>
        <Typography sx={{ fontWeight: "700", paddingBottom: ".3rem" }}>
          PO# Search
        </Typography>
      </div>
      <TextField
        color="primary"
        onChange={(e) => {
          setSearchTerm(e.target.value);
        }}
        size="small"
        sx={{
          width: "100%",
          margin: "unset",
        }}
        value={searchTerm}
      />
    </Stack>
  );

  function search() {
    setHistoryLoading(true);
    const query = {
      date_start: moment(startDate).format("YYYY/MM/DD"),
      date_end: moment(endDate).format("YYYY/MM/DD"),
      status: status,
      q: searchTerm,
    };
    api.post("history/search", query).then((response) => {
      setInvoices(response.data.results);
      setHistoryLoading(false);
    });
  }

  function Row(invoice) {
    const [open, setOpen] = useState(false);
    const [invoiceDetails, setInvoiceDetails] = useState({});
    const [openReorderModal, setOpenReordersModal] = useState(false);
    const [item, setItem] = useState(null);
    const [message, setMessage] = useState(null);
    const [todaysPrice, setTodaysPrice] = useState(null);
    const [loading, setLoading] = useState(true);

    function orderQuantity(quantity) {
      const quantitySplit = quantity.split(" ", 2);
      return {
        quantity: quantitySplit[0],
        quantity_unit: quantitySplit[1],
      };
    }

    function closeModal() {
      setOpenReordersModal(false);
      setItem(null);
      setMessage(null);
      setTodaysPrice(null);
    }

    function getInvoiceDetails(invoice_id, invoice_type) {
      api
        .fetch(`history/show/${invoice_id}/${invoice_type}`)
        .then((response) => {
          setInvoiceDetails(response.data);
        });
    }

    function getReorderPrice() {
      if (openReorderModal && item !== {}) {
        setLoading(true);
        const formatQuantity = orderQuantity(item.quantity);
        const newQueryString = qs.stringify({
          product: item.product,
          dimension: item.dimension,
          quantity: formatQuantity.quantity,
          quantity_unit: formatQuantity.quantity_unit,
          material: item.karat,
          carat_size: item.carat_size,
          finger_size: item.finger_size,
          treatments: item.treatments,
        });
        api.fetch(`quickorder/price?${newQueryString}`).then((res) => {
          if (res.isAxiosError) {
            const errorMessage = [];
            res.response.data.errors.forEach(function (error) {
              errorMessage.push(error);
            });
            toast.error(
              `There was an error processing your request. ${errorMessage.toString()}`
            );
          } else {
            if (res.data.price === 0) {
              setMessage(
                "Please reorder this item through the product screen by clicking the button below."
              );
              setLoading(false);
            } else {
              setTodaysPrice(res.data);
              setLoading(false);
            }
          }
        });
      }
    }

    function addReorderToCart() {
      const formatQuantity = orderQuantity(item.quantity);
      const addToCartBody = {
        source: "R",
        cart_id:
          storage.getStorageItem("cartId") === "null" ||
          storage.getStorageItem("cartId") === "undefined"
            ? localStorage.removeItem("cartId")
            : storage.getStorageItem("cartId"),
        partcode: {
          karat: item.karat,
          product: item.product,
          dimension: item.dimension,
          finger_size: item.finger_size,
          quantity: formatQuantity.quantity,
          quantity_unit: formatQuantity.quantity_unit,
          carat_size: item.carat_size,
          custom_dimension: {
            sale: 1,
            terms: 1,
          },
          message: "",
          treatments: item.treatments,
        },
      };
      api
        .post("cart/add", addToCartBody)
        .then((res) => {
          if (res.isAxiosError) {
            const errorMessage = [];
            res.response.data.errors.forEach(function (error) {
              errorMessage.push(error);
            });
            toast.error(
              `There was an error processing your request. ${errorMessage.toString()}`
            );
          } else {
            cartDispatch({
              type: "updateCart",
              payload: {
                cart: res.data.items,
                cartCount: res.data.count,
              },
            });
            storage.setStorageItem("cartId", res.data.cart_id);
            // setOpenReordersModal(false);
            // setItem({});
            //closeModal();
          }
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }

    useEffect(() => {
      if (openReorderModal) {
        getReorderPrice();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [openReorderModal]);

    return (
      <React.Fragment>
        <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
          <TableCell>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => {
                setOpen(!open);
                getInvoiceDetails(
                  invoice.row.invoice_id,
                  invoice.row.invoice_type
                );
              }}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
          <TableCell component="th" scope="row" align="center">
            <Typography>{invoice.row.invoice_no}</Typography>
          </TableCell>
          <TableCell align="center">
            <Typography>{invoice.row.customer_po}</Typography>
          </TableCell>
          <TableCell align="center">
            <Typography>
              {invoice.row.invoice_type === "Invoice" ? "Shipped" : "Pending"}
            </Typography>
          </TableCell>
          <TableCell align="center">
            <Typography>{invoice.row.date_received}</Typography>
          </TableCell>
          <TableCell align="center">
            <Typography>{invoice.row.date_invoiced}</Typography>
          </TableCell>
          <TableCell align="center">
            <Typography>{invoice.row.ship_via}</Typography>
          </TableCell>
          <TableCell align="center">
            <Typography>{invoice.row.terms}</Typography>
          </TableCell>
          <TableCell align="center">
            <Typography variant="headerFont">
              {formatting["price2"](invoice.row.order_total)}
            </Typography>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={9}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 1 }}>
                <Grid container>
                  <Grid item xs={6}>
                    <Typography variant="h6" gutterBottom component="div">
                      Invoice Details
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Chip
                      className="pointer float-right"
                      icon={<FileDownloadTwoToneIcon />}
                      label="Print/Download PDF"
                      color="primary"
                      variant="outlined"
                      onClick={() => downloadPdfInvoice(invoice)}
                      sx={{ paddingLeft: ".5rem" }}
                    />
                  </Grid>
                </Grid>
                <Table size="small" aria-label="purchases">
                  <TableHead>
                    <TableRow>
                      <TableCell></TableCell>
                      <TableCell>
                        <Typography variant="headerFont">
                          Specifications
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="headerFont">Material</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="headerFont">Quantity</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="headerFont">Weight</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="headerFont">Unit Rate</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="headerFont">
                          Material Rate
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="headerFont">Labor/Unit</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="headerFont">
                          Total Material
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="headerFont">
                          Total Labor
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="headerFont">Total</Typography>
                      </TableCell>
                      <TableCell />
                    </TableRow>
                  </TableHead>
                  {invoiceDetails && (
                    <TableBody>
                      {invoiceDetails.lines &&
                        invoiceDetails.lines.map((lineItem) => (
                          <TableRow key={lineItem.sequence}>
                            <TableCell>
                              <Typography variant="cellFont">
                                {lineItem.item_number}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography variant="cellFont">
                                {parse(lineItem.specification)}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography variant="cellFont">
                                {lineItem.material}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography variant="cellFont">
                                {formatting["removeSpace"](lineItem.quantity)}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography variant="cellFont">
                                {formatting["removeSpace"](lineItem.weight)}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography variant="cellFont">
                                {formatting["price2"](lineItem.unit_price)}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography variant="cellFont">
                                {formatting["price2"](lineItem.material_price)}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography variant="cellFont">
                                {formatting["price2"](lineItem.unit_labor)}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography variant="cellFont">
                                {formatting["price2"](lineItem.total_material)}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography variant="cellFont">
                                {formatting["price2"](lineItem.total_labor)}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography variant="cellFont">
                                {formatting["price2"](lineItem.total)}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              {lineItem.item_number && (
                                <Chip
                                  className="pointer"
                                  icon={
                                    <RefreshTwoToneIcon
                                      sx={{ verticalAlign: "middle" }}
                                    />
                                  }
                                  label="Reorder"
                                  color="primary"
                                  variant="outlined"
                                  sx={{ paddingLeft: ".5rem" }}
                                  onClick={() => {
                                    setOpenReordersModal(true);
                                    setItem(lineItem);
                                  }}
                                />
                              )}
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  )}
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
        <Dialog
          onClose={() => {
            closeModal();
          }}
          open={openReorderModal}
          fullWidth
          maxWidth="lg"
        >
          <DialogTitle>Reorder</DialogTitle>
          <DialogContent>
            <Table size="small" aria-label="purchases">
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell>
                    <Typography variant="headerFont">Specifications</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="headerFont">Material</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="headerFont">Quantity</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="headerFont">Weight</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="headerFont">Unit Rate</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="headerFont">Material Rate</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="headerFont">Labor/Unit</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="headerFont">Total Material</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="headerFont">Total Labor</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="headerFont">Total</Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {item && (
                  <>
                    <TableRow>
                      <TableCell>
                        <Typography variant="cellFont">
                          {item.item_number}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="cellFont">
                          {parse(item.specification)}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="cellFont">
                          {item.material}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="cellFont">
                          {formatting["removeSpace"](item.quantity)}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="cellFont">
                          {formatting["removeSpace"](item.weight)}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="cellFont">
                          {formatting["price2"](item.unit_price)}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="cellFont">
                          {formatting["price2"](item.material_price)}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="cellFont">
                          {formatting["price2"](item.unit_labor)}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="cellFont">
                          {formatting["price2"](item.total_material)}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="cellFont">
                          {formatting["price2"](item.total_labor)}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="cellFont">
                          {formatting["price2"](item.total)}
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell colSpan={10} align="right">
                        {message ? (
                          <Typography variant="subtitle1">{message}</Typography>
                        ) : (
                          <Typography variant="subtitle1">
                            Today's Price:{" "}
                          </Typography>
                        )}
                      </TableCell>
                      <TableCell>
                        <Typography variant="subtitle1">
                          {todaysPrice && message === null ? (
                            formatting["price2"](todaysPrice.price)
                          ) : message !== null ? (
                            ""
                          ) : (
                            <Skeleton variant="text" width="10rem" />
                          )}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </>
                )}
              </TableBody>
            </Table>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                closeModal();
              }}
              variant="contained"
              color="secondary"
            >
              Cancel
            </Button>
            {message ? (
              <LoadingButton
                variant="contained"
                color="primary"
                loading={loading}
                loadingIndicator="Loading Price…"
              >
                <DomLink to={{ pathname: `/product/${item.product}` }}>
                  Go To Order Screen
                </DomLink>
              </LoadingButton>
            ) : (
              <LoadingButton
                variant="contained"
                color="success"
                onClick={() => addReorderToCart()}
                loading={loading}
                loadingIndicator="Loading…"
              >
                Add to Cart
              </LoadingButton>
            )}
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }

  function downloadPdfInvoice(invoice) {
    if (invoice.row.invoice_type === "Invoice") {
      api
        .invoiceDownload(`history/download/${invoice.row.invoice_id}`)
        .then((response) => {
          if (response.isAxiosError) {
            const errorMessage = [];
            response.data.errors.forEach(function (error) {
              errorMessage.push(error);
            });
            toast.error(
              `There was an error processing your request. ${errorMessage.toString()}`
            );
          } else {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute(
              "download",
              `invoice ${invoice.row.invoice_no}.pdf`
            );
            document.body.appendChild(link);
            link.click();
          }
        });
    } else {
      api
        .invoiceDownload(
          `history/download/${invoice.row.invoice_id}/ORDER CONFIRMATION`
        )
        .then((response) => {
          if (response.isAxiosError) {
            const errorMessage = [];
            response.data.errors.forEach(function (error) {
              errorMessage.push(error);
            });
            toast.error(
              `There was an error processing your request. ${errorMessage.toString()}`
            );
          } else {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute(
              "download",
              `pending invoice ${invoice.row.invoice_no}.pdf`
            );
            document.body.appendChild(link);
            link.click();
          }
        });
    }
  }

  useEffect(() => {
    api.fetch("history/fields").then((response) => {
      setFields(response.data);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const initialDate = new Date();
    const start = new Date(initialDate.setMonth(initialDate.getMonth() - 3));
    setHistoryLoading(true);
    setStartDate(start);
    setEndDate(new Date());
    const query = {
      date_start: moment(start).format("YYYY/MM/DD"),
      date_end: moment(new Date()).format("YYYY/MM/DD"),
      status: status,
      q: searchTerm,
    };
    api.post("history/search", query).then((response) => {
      if (response && response.data) {
        setInvoices(response.data.results);
        setShowHistory(true);
      }
      setHistoryLoading(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Mobile>
        <Stack spacing={2} sx={{ marginBottom: "3rem" }}>
          <Card
            variant="outlined"
            sx={{ maxHeight: "30rem", minHeight: "30rem" }}
          >
            <CardContent>
              <Stack spacing={5} alignItems="center">
                {currentUser !== null ? (
                  <>
                    <Typography variant="h6">{`Hello ${currentUser.FirstName} ${currentUser.LastName}!`}</Typography>
                    <Typography>{`Email: ${currentUser.Email}`}</Typography>
                    <Typography>{`Phone #: ${currentUser.PhoneNumber}`}</Typography>
                    <DomLink
                      to={{
                        pathname: "/update-password",
                      }}
                    >
                      <Button variant="contained">Update Password</Button>
                    </DomLink>
                  </>
                ) : (
                  <Skeleton variant="text" width="10rem" />
                )}
              </Stack>
            </CardContent>
          </Card>
          <Card variant="outlined">
            <CardHeader
              sx={{ backgroundColor: "#cfdde9" }}
              title={
                <Typography variant="accordianTitle">
                  Default Addresses
                </Typography>
              }
            />
            <CardContent>
              <Stack direction="row" spacing={8}>
                <Stack spacing={2}>
                  <Typography variant="navText">Billing Address:</Typography>
                  {currentUser !== null ? (
                    <Stack>
                      <Typography>{currentUser.BillTo.Street1}</Typography>
                      <Typography>{currentUser.BillTo.Street2}</Typography>
                      <Typography>
                        {currentUser.BillTo.City}, {currentUser.BillTo.State}{" "}
                        {currentUser.BillTo.ZipCode}
                      </Typography>
                    </Stack>
                  ) : (
                    <Skeleton variant="text" width="10rem" />
                  )}
                </Stack>
                <Stack spacing={2}>
                  <Typography variant="navText">Shipping Address:</Typography>
                  {currentUser !== null ? (
                    <Stack>
                      <Typography>{currentUser.MainShipTo.Street1}</Typography>
                      <Typography>{currentUser.MainShipTo.Street2}</Typography>
                      <Typography>
                        {currentUser.MainShipTo.City},{" "}
                        {currentUser.MainShipTo.State}{" "}
                        {currentUser.MainShipTo.ZipCode}
                      </Typography>
                    </Stack>
                  ) : (
                    <Skeleton variant="text" width="10rem" />
                  )}
                </Stack>
              </Stack>
            </CardContent>
          </Card>
          {showHistory && (
            <Card variant="outlined">
              <CardHeader
                sx={{ backgroundColor: "#cfdde9" }}
                title={
                  <Typography variant="accordianTitle">
                    Order History
                  </Typography>
                }
              />
              <CardContent>
                <Stack spacing={2} justifyContent="center">
                  {DateRangeField()}
                  {fields &&
                    fields.map((field) => (
                      <>{field.name === "status" && StatusField(field)}</>
                    ))}
                  {SearchField()}
                  <Button onClick={() => search()} variant="contained">
                    Lookup Invoices
                  </Button>
                </Stack>
                <Divider
                  flexItem
                  sx={{ marginTop: "1rem", marginBottom: "1rem" }}
                />
                {invoices && invoices.length > 0 ? (
                  <TableContainer component={Paper}>
                    <Table aria-label="collapsible table">
                      <TableHead>
                        <TableRow>
                          <TableCell />
                          <TableCell align="center">
                            <Typography>Invoice #</Typography>
                          </TableCell>
                          <TableCell align="center">
                            <Typography>PO #</Typography>
                          </TableCell>
                          <TableCell align="center">
                            <Typography>Status</Typography>
                          </TableCell>
                          <TableCell align="center">
                            <Typography>Date Ordered</Typography>
                          </TableCell>
                          <TableCell align="center">
                            <Typography>Date Shipped</Typography>
                          </TableCell>
                          <TableCell align="center">
                            <Typography>Ship Via</Typography>
                          </TableCell>
                          <TableCell align="center">
                            <Typography>Terms</Typography>
                          </TableCell>
                          <TableCell align="center">
                            <Typography>Total</Typography>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {invoices.map((row) => (
                          <Row
                            key={`${row.invoice_id}+${row.order_total}`}
                            row={row}
                          />
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                ) : (
                  <Typography>
                    There are no invoices found. Please select different values
                    in the filters.
                  </Typography>
                )}
              </CardContent>
            </Card>
          )}
        </Stack>
      </Mobile>
      <Default>
        <Stack direction="row" spacing={2}>
          <Card
            variant="outlined"
            sx={{ width: "25%", maxHeight: "30rem", minHeight: "30rem" }}
          >
            <CardContent>
              {currentUser !== null ? (
                <Stack spacing={5} alignItems="center">
                  <Typography variant="h6">{`Hello ${currentUser.FirstName} ${currentUser.LastName}!`}</Typography>
                  <Typography>{`Email: ${currentUser.Email}`}</Typography>
                  <Typography>{`Phone #: ${currentUser.PhoneNumber}`}</Typography>
                  <DomLink
                    to={{
                      pathname: "/update-password",
                    }}
                  >
                    <Button variant="contained">Update Password</Button>
                  </DomLink>
                </Stack>
              ) : (
                <Skeleton variant="text" width="10rem" />
              )}
            </CardContent>
          </Card>
          <Stack sx={{ width: "75%" }} spacing={3}>
            <Card variant="outlined">
              <CardHeader
                sx={{ backgroundColor: "#cfdde9" }}
                title={
                  <Typography variant="accordianTitle">
                    Default Addresses
                  </Typography>
                }
              />
              <CardContent>
                <Stack direction="row" spacing={8}>
                  <Stack spacing={2}>
                    <Typography variant="navText">Billing Address:</Typography>
                    {currentUser !== null ? (
                      <Stack>
                        <Typography>{currentUser.BillTo.Street1}</Typography>
                        <Typography>{currentUser.BillTo.Street2}</Typography>
                        <Typography>
                          {currentUser.BillTo.City}, {currentUser.BillTo.State}{" "}
                          {currentUser.BillTo.ZipCode}
                        </Typography>
                      </Stack>
                    ) : (
                      <Skeleton variant="text" width="10rem" />
                    )}
                  </Stack>
                  <Stack spacing={2}>
                    <Typography variant="navText">Shipping Address:</Typography>
                    {currentUser !== null ? (
                      <Stack>
                        <Typography>
                          {currentUser.MainShipTo.Street1}
                        </Typography>
                        <Typography>
                          {currentUser.MainShipTo.Street2}
                        </Typography>
                        <Typography>
                          {currentUser.MainShipTo.City},{" "}
                          {currentUser.MainShipTo.State}{" "}
                          {currentUser.MainShipTo.ZipCode}
                        </Typography>
                      </Stack>
                    ) : (
                      <Skeleton variant="text" width="10rem" />
                    )}
                  </Stack>
                </Stack>
              </CardContent>
            </Card>
          </Stack>
        </Stack>
        {showHistory && (
          <Card variant="outlined" sx={{ marginTop: "2rem" }}>
            <CardHeader
              sx={{ backgroundColor: "#cfdde9" }}
              title={
                <Typography variant="accordianTitle">Order History</Typography>
              }
            />
            <CardContent>
              <Stack
                direction="row"
                spacing={2}
                justifyContent="center"
                alignItems="end"
              >
                {DateRangeField()}
                {fields &&
                  fields.map((field) => (
                    <>{field.name === "status" && StatusField(field)}</>
                  ))}
                {SearchField()}
                <Button onClick={() => search()} variant="contained">
                  Lookup Invoices
                </Button>
              </Stack>
              <Divider
                flexItem
                sx={{ marginTop: "1rem", marginBottom: "1rem" }}
              />
              {historyLoading ? (
                <Stack>
                  <Skeleton variant="text" sx={{ fontSize: "5rem" }} />
                  <Skeleton variant="text" sx={{ fontSize: "5rem" }} />
                  <Skeleton variant="text" sx={{ fontSize: "5rem" }} />
                  <Skeleton variant="text" sx={{ fontSize: "5rem" }} />
                </Stack>
              ) : (
                <>
                  {invoices && invoices.length > 0 ? (
                    <TableContainer component={Paper}>
                      <Table aria-label="collapsible table">
                        <TableHead>
                          <TableRow>
                            <TableCell>
                              <Typography>Click for Details</Typography>
                            </TableCell>
                            <TableCell align="center">
                              <Typography>Invoice #</Typography>
                            </TableCell>
                            <TableCell align="center">
                              <Typography>PO #</Typography>
                            </TableCell>
                            <TableCell align="center">
                              <Typography>Status</Typography>
                            </TableCell>
                            <TableCell align="center">
                              <Typography>Date Ordered</Typography>
                            </TableCell>
                            <TableCell align="center">
                              <Typography>Date Shipped</Typography>
                            </TableCell>
                            <TableCell align="center">
                              <Typography>Ship Via</Typography>
                            </TableCell>
                            <TableCell align="center">
                              <Typography>Terms</Typography>
                            </TableCell>
                            <TableCell align="center">
                              <Typography>Total</Typography>
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {invoices.map((row) => (
                            <Row
                              key={`${row.invoice_id}+${row.order_total}`}
                              row={row}
                            />
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  ) : (
                    <Typography>
                      There are no invoices found. Please select different
                      values in the filters.
                    </Typography>
                  )}
                </>
              )}
            </CardContent>
          </Card>
        )}
      </Default>
    </>
  );
}
