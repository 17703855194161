import { createTheme } from "@mui/material/styles";

const black = "#333";
const white = "#fff";
const lightBlue = "#cfdde9";
const hsLogoBlue = "#557388";
const hsDarkBlue = "#124760";
const lightGreen = "#b2c694";
const green = "#7fa04d";
const darkGreen = "#5b7023";
const lightRed = "#e7d0cc";
const mediumRed = "#c38a80";
const red = "#954738";
const lightYellow = "#ecd391";
const yellow = "#e0b749";
const darkGold = "#937d49";
const infoLightBlue = "#0492c2";
const babyBlue = "#4280aa";
const infoDarkBlue = "#355aa4";
const lightGray = "#f0f0f0";
const sortofLightGray = "#e3e3e3";
const gray = "#b2b2b2";
const darkGray = "#939598";
const lightPink = "#e5a0c6";
const pink = "#D380A7";
const lightBrown = "#b5651d";
const brown = "#8B4513";
const bodyFont = "Verdana";
const headerFont = "trajan-pro";

const baseMuiTheme = createTheme({
  palette: {
    type: "light",
    primary: {
      light: lightBlue,
      main: hsDarkBlue,
      dark: hsLogoBlue,
      contrastText: white,
    },
    secondary: {
      light: lightGray,
      main: sortofLightGray,
      dark: gray,
    },
    error: {
      light: lightRed,
      main: red,
      dark: mediumRed,
      contrastText: white,
    },
    warning: {
      light: lightYellow,
      main: yellow,
      dark: darkGold,
    },
    info: {
      light: infoLightBlue,
      main: babyBlue,
      dark: infoDarkBlue,
      contrastText: white,
    },
    success: {
      light: lightGreen,
      main: green,
      dark: darkGreen,
      contrastText: white,
    },
    dark: {
      main: black,
      contrastText: white,
    },
    pink: {
      main: pink,
      dark: lightPink,
      contrastText: white,
    },
    diamondWhite: {
      main: white,
      dark: lightGray,
      contrastText: black,
    },
    diamondGreen: {
      main: green,
      dark: lightGreen,
      contrastText: white,
    },
    diamondBlue: {
      main: infoLightBlue,
      dark: lightBlue,
      contrastText: white,
    },
    diamondYellow: {
      main: yellow,
      dark: lightYellow,
      contrastText: white,
    },
    diamondBrown: {
      main: brown,
      dark: lightBrown,
      contrastText: white,
    },
    diamondPink: {
      main: pink,
      dark: lightPink,
      contrastText: white,
    },
  },
  typography: {
    fontFamily: bodyFont,
    body1: {
      fontSize: "1.4rem",
      lineHeight: "1.6rem",
    },
    body2: {
      fontSize: "1.6rem",
      lineHeight: "1.8rem",
    },
    body3: {
      fontSize: "1.2rem",
      lineHeight: "1.2rem",
      letterSpacing: "0.05rem",
    },
    fancyFilterFont: {
      fontSize: "1.4rem",
      lineHeight: "1.2rem",
      letterSpacing: "0.05rem",
      color: pink,
      fontWeight: 700,
    },
    metalMarkets: {
      fontSize: "1.2rem",
      lineHeight: "1.4rem",
      letterSpacing: "0.05rem",
    },
    navText: {
      fontSize: "1.3rem",
      letterSpacing: "0.03rem",
      textTransform: "uppercase",
    },
    footerText: {
      fontSize: "1.2rem",
      lineHeight: "2.3rem",
      marginLeft: "1rem",
    },
    articleText: {
      lineBreak: "strict",
      whiteSpace: "pre-line",
    },
    staticText: {
      fontSize: "1.35rem",
      lineHeight: "2.3rem",
      marginLeft: "1rem",
      lineBreak: "strict",
      whiteSpace: "pre-line",
      listStyleType: "circle",
    },
    articleTitle: {
      fontFamily: headerFont,
      fontSize: "3.5rem",
      lineHeight: "3.7rem",
      fontWeight: "800",
      color: hsDarkBlue,
      textTransform: "lowercase",
    },
    articleSubTitle: {
      lineHeight: "2rem",
      padding: ".5rem",
      marginBottom: ".5rem",
      marginTop: ".5rem",
      backgroundColor: lightBlue,
    },
    greenRectangle: {
      lineHeight: "2rem",
      padding: "1rem",
      marginBottom: "1rem",
      marginTop: "1rem",
      paddingLeft: "8rem",
      paddingRight: "8rem",
      backgroundColor: green,
      color: white,
    },
    mobileGreenRectangle: {
      fontSize: "1.2rem",
      lineHeight: "1.4rem",
      letterSpacing: "0.03rem",
      padding: "1rem",
      marginBottom: "1rem",
      marginTop: "1rem",
      backgroundColor: green,
      color: white,
    },
    imageCaption: {
      lineHeight: "2rem",
      fontSize: "2rem",
      margin: "2rem",
      fontFamily: headerFont,
      color: hsDarkBlue,
    },
    fdj: {
      lineHeight: "2rem",
      fontSize: "1.9rem",
      margin: "1rem",
      fontFamily: headerFont,
      textTransform: "lowercase",
      color: babyBlue,
    },
    h1: {
      //header titles
      fontFamily: headerFont,
      fontSize: "3.5rem",
      lineHeight: "3.7rem",
      fontWeight: "800",
      float: "left",
      margin: "0",
      marginRight: "40rem",
      color: hsDarkBlue,
      textTransform: "lowercase",
    },
    h2: {
      //subheaders
      fontFamily: headerFont,
      fontSize: "2rem",
      padding: "1rem",
      marginBottom: ".5rem",
      marginTop: ".5rem",
      backgroundColor: lightBlue,
      textTransform: "lowercase",
    },
    accordianTitle: {
      fontFamily: headerFont,
      fontSize: "2rem",
      textTransform: "lowercase",
    },
    h3: {
      //smaller subheaders, blue color
      fontWeight: "bold",
      fontSize: "1.8rem",
      fontFamily: headerFont,
      color: hsDarkBlue,
      textTransform: "lowercase",
    },
    h4: {
      //Verdana titles/headers 18pt
      fontWeight: "800",
      fontSize: "2.4rem",
      lineHeight: "2.6rem",
    },
    h5: {
      //Verdana subheaders 16pt
      fontSize: "2.2rem",
      lineHeight: "2.4rem",
      fontWeight: "800",
    },
    h6: {
      //Verdana labels, 14pt
      fontSize: "1.6rem",
      lineHeight: "2.2rem",
      color: babyBlue,
    },
    diamondTabFont: {
      //Verdana labels, 14pt
      fontSize: "1.6rem",
      lineHeight: "2.2rem",
    },
    mediumHeader: {
      fontWeight: "bold",
      fontSize: "2.5rem",
      fontFamily: headerFont,
      color: hsDarkBlue,
      textTransform: "lowercase",
    },
    landingAccordianTitle: {
      //Verdana labels, 14pt
      fontSize: "1.6rem",
      lineHeight: "2.2rem",
      fontWeight: "700",
      color: hsDarkBlue,
    },
    subtitle1: {
      //red text - Metal Market Pricing
      fontSize: "1.4rem",
      lineHeight: "1.6rem",
      fontWeight: "800",
      fontStyle: "italic",
      color: red,
    },
    subtitle2: {
      //help text under fields
      fontSize: "1.2rem",
      lineHeight: "1.2rem",
      color: gray,
      fontStyle: "italic",
    },
    headerFont: {
      fontSize: "1.2rem",
      fontWeight: "800",
    },
    cellFont: {
      fontSize: "1.2rem",
    },
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: hsDarkBlue,
          color: black,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          justifySelf: "center",
          cursor: "pointer",
          marginTop: "0.5rem",
          borderRadius: "0px",
          fontSize: "1.4rem",
          width: "20rem",
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: "unset",
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        label: {
          fontSize: "1.2rem",
          lineHeight: "1.2rem",
          letterSpacing: "0.05rem",
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          marginBottom: "0.5rem",
          marginTop: "0.5rem",
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          fontFamily: headerFont,
          fontSize: "2.8rem",
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          fontSize: "1.4rem",
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontSize: "1.4rem",
          lineHeight: "1.6rem",
          fontWeight: "800",
          fontStyle: "italic",
          color: red,
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          zIndex: "unset",
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          borderRadius: 0,
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          boxShadow: "none",
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          verticalAlign: "middle",
          fontSize: "1.8rem",
        },
      },
    },
    MuiPagination: {
      styleOverrides: {
        ul: {
          justifyContent: "flex-end",
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        head: {
          backgroundColor: lightGray,
          borderColor: lightGray,
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          margin: ".5rem",
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        root: {
          fontSize: "1.4rem",
          backgroundColor: "white",
          left: "4rem",
        },
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          color: black,
          border: `1px solid ${gray}`,
        },
      },
    },
    MuiSlider: {
      styleOverrides: {
        track: {
          height: "8px",
        },
        thumb: {
          width: "18px",
          height: "18px",
        },
        rail: {
          height: "8px",
          color: darkGray,
        },
        marked: {
          marginBottom: "1px",
        },
        mark: {
          width: "2px",
          height: "8px",
        },
        markLabel: {
          display: "none",
        },
      },
    },
    MuiTabPanel: {
      styleOverrides: {
        root: {
          padding: "0",
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: "1.1rem",
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        primary: {
          fontSize: "1.4rem",
        },
      },
    },
  },
});

export default baseMuiTheme;
