// Built-in modules
import qs from "qs";

// Third-party libraries
import React, { useContext, useReducer, useEffect, useState } from "react";
import { Link as DomLink } from "react-router-dom";
import toast from "react-hot-toast";
import {
  Backdrop,
  CircularProgress,
  Container,
  Grid,
  Typography,
  Stack,
  Select,
  MenuItem,
  Divider,
  InputLabel,
} from "@mui/material";

// Local modules
import {
  AppContext,
  ProductsContext,
} from "../../../utils/stateHandlers/contexts";
import states from "../../../utils/stateHandlers/initialStates";
import { productReducer } from "../../../utils/stateHandlers/reducers";
import { storage } from "../../../utils";
import { functions, constants } from "../../../utils";
import api from "../../../utils/api";
import DetailTable from "../components/DetailTable";
import Quantity from "../components/productFields/Quantity";
import TreatmentField from "../components/TreatmentField";
import SpecialInstructions from "../components/SpecialInstructions";
import TechnicalArticles from "../components/TechnicalArticles";
import ProductGalleryViewer from "../components/ProductGalleryViewer";
import CustomerTestimonials from "../components/CustomerTestimonials";
import Price from "../components/Price";
import filterAds from "../../../utils/helperFunction";
import { StyledLabel } from "./styles/detailsSx";
import useMediaQueryUtils from "../../../utils/mediaQueryUtils";
import RecentlyView from "./RecentlyView";
import RelatedItems from "./RelatedItems";
import ProductTitle from "./styles/ProductTitle";
import StrapiChart from "./StrapiChart";
import getProductCodeStrapiId from "./const/Contants";

const image = "COINSBULLION_XLG.png";

const DefaultImage = `http://www.hooverandstrong.com/catalog_images/${image}`;

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

export default function CoinsBullion(props) {
  const isMdScreen = useMediaQueryUtils("md");
  const { authToken } = useContext(AppContext);
  const [productState, productDispatch] = useReducer(
    productReducer,
    states.initialProductState
  );
  const {
    pageLoaded,
    icons,
    productFields,
    productDetails,
    productTreatments,
    images,
    style,
    strapiChart,
    productInfo,
    relatedProducts,
    advertisement,
    ChartSizeSmall,
    TopText,
  } = productState;
  const [styleLoaded, setStyleLoaded] = useState(false);
  const [itemImage, setItemImages] = useState([DefaultImage]);
  const [coinBullion, setCoinBullion] = useState({
    material: "24Y",
    description: "1 oz Eagle ($50.00)",
  });
  const [fields, setFields] = useState();
  const groupName = props.match.params.ProductStyleCode;

  const frontImageUrl = constants.CMS_FRONT_IMAGE_URL;
  const productGroup = "";
  const material = fields && fields.find((field) => field.name === "material");
  const description =
    fields && fields.find((field) => field.name === "description");
  function serializeCoinBullion() {
    return qs.stringify(coinBullion);
  }

  function createProductDefaults(product) {
    setStyleLoaded(false);
    let productDefaults = {};
    productDefaults = {
      ...productDefaults,
      product: product.product,
      description: product.description,
    };
    productDispatch({
      type: "setStyle",
      fields: ["productFields", "style"],
      payload: {
        fields: product.fields,
        style: product.style,
      },
    });
    productDispatch({
      type: "setProductDefaults",
      field: "product",
      payload: product.product,
    });
    productDispatch({
      type: "setProductDefaults",
      field: "description",
      payload: product.description,
    });

    product.fields.forEach(function (field) {
      if (field.template === "field_with_units") {
        productDispatch({
          type: "setProductDefaults",
          field: field.name,
          payload: field.default === 0 ? 1 : field.default,
        });
        productDispatch({
          type: "setProductDefaults",
          field: `${field.name}_unit`,
          payload: field.default_unit,
        });
        productDefaults = {
          ...productDefaults,
          [field.name]: field.default,
          [`${field.name}_unit`]: field.default_unit,
        };
      } else {
        productDispatch({
          type: "setProductDefaults",
          field: field.name,
          payload: field.default,
        });
        productDefaults = {
          ...productDefaults,
          [field.name]: field.default,
        };
      }
    });
  }

  function makeQueryString() {
    const infoToStringify = {
      style: groupName,
      description: productInfo.description,
      product: productInfo.product,
      dimension: productInfo.dimension,
      quantity: productInfo.quantity,
      quantity_unit: productInfo.quantity_unit,
      material: productInfo.material,
      message: productInfo.message,
    };
    const arrayOfValues = Object.values(infoToStringify);
    const searchingForNone = arrayOfValues.includes("None");
    if (!searchingForNone) {
      const query = qs.stringify(infoToStringify);
      return query;
    } else {
      productDispatch({ type: "setPriceLoaded", payload: true });
      return false;
    }
  }
  /**
   * Gets the updated price when there is a new query and
   * dispatch those changes onto the reducers.
   * @param {string} newQueryString
   */
  function getUpdatedPrice(newQueryString) {
    api.fetch(`quickorder/price?${newQueryString}`).then((response) => {
      if (response.isAxiosError) {
        const errorMessage = [];
        response.data.errors.forEach(function (error) {
          errorMessage.push(error);
        });
        toast.error(
          `There was an error processing your request. ${errorMessage.toString()}`
        );
        productDispatch({
          type: "setMessage",
          payload: {
            message: Object.values(response.data.errors),
            type: "error",
          },
        });
        productDispatch({ type: "setPriceLoaded", payload: true });
      } else {
        updateFields(response.data.fields);
        productDispatch({ type: "updatePrice", payload: response.data });
        productDispatch({
          type: "setMessage",
          payload: { message: null, type: null },
        });
        productDispatch({ type: "setPriceLoaded", payload: true });
      }
    });
  }

  /**
   * Updates the fields that are displayed
   */
  function updateFields(fields) {
    const materialField = productFields.filter(
      (field) => field.name === "material"
    );

    const sizeField = fields.filter(
      (field) => field.name === "quantity" || field.name === "dimension"
    );
    const newFields = [...materialField, ...sizeField];

    const sortedFields = sortFields(newFields);
    productDispatch({
      type: "setNewFields",
      payload: sortedFields,
    });
  }

  /**
   * Sorts fields based on the SortOrder assigned to them
   * @param {array} fields
   * @returns Sorted fields
   */
  function sortFields(fields) {
    const newSortedFields = fields.map((field) => {
      const sortOrder =
        field.name === "material" ? 1 : field.name === "dimension" ? 2 : 100;

      return { ...field, SortOrder: sortOrder };
    });
    return newSortedFields.sort((a, b) => a.SortOrder - b.SortOrder);
  }

  //advertisements from Strapi
  useEffect(() => {
    const populate = {
      One: "*",
      Two: "*",
      Three: "*",
      Four: "*",
      Five: "*",
    };

    const query = qs.stringify({ populate });
    api.fetchStrapi(`/detail-page-ad?${query}`).then((response) => {
      const ads = response.data.data.attributes;
      const randomAd = filterAds(ads);

      productDispatch({
        type: "setPageAd",
        payload: randomAd,
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.match.params.ProductStyleCode]);

  useEffect(() => {
    if (coinBullion !== "" && coinBullion !== null) {
      productDispatch({ type: "setPriceLoaded", payload: false });
      api
        .fetch(`quickorder/group?style=${groupName}&${serializeCoinBullion()}`)
        .then((response) => {
          if (response.isAxiosError) {
            const errorMessage = [];
            response.data.errors.forEach(function (error) {
              errorMessage.push(error);
            });
            toast.error(
              `There was an error processing your request. ${errorMessage.toString()}`
            );
          } else {
            productDispatch({
              type: "setMessage",
              payload: { message: null, type: null },
            });
            productDispatch({ type: "setPageLoaded", payload: true });
            createProductDefaults(response.data);
            setFields(response.data.fields);
            functions.storeRecentlyViewed(response.data);
            if (coinBullion.material && coinBullion.description) {
              productDispatch({
                type: "setProductDefaults",
                field: "material",
                payload: coinBullion.material,
              });
              setStyleLoaded(true);
            }
          }
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [coinBullion]);

  //update price
  useEffect(() => {
    if (authToken && pageLoaded && styleLoaded && productInfo.quantity > 0) {
      productDispatch({ type: "setPriceLoaded", payload: false });
      const newQueryString = makeQueryString();
      newQueryString !== false && getUpdatedPrice(newQueryString);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productInfo.quantity, styleLoaded]);

  useEffect(() => {
    if (images) {
      setItemImages(images);
    }
  }, [images]);

  //strapi
  useEffect(() => {
    const strapiId = getProductCodeStrapiId("BUL");
    const query = qs.stringify({
      populate: {
        chart: "*",
      },
    });
    api
      .fetchStrapi(`/product-details-charts/${strapiId}?${query}`)
      .then((response) => {
        productDispatch({
          type: "setStrapi",
          payload: {
            chart: response.data.data.attributes.chart,
            ChartSizeSmall: response.data.data.attributes.ChartSizeSmall,
            TopText: response.data.data.attributes.TopText,
          },
        });
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ProductsContext.Provider value={{ productState, productDispatch }}>
      {!pageLoaded ? (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={true}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : (
        <Container maxWidth="xl">
          <Stack
            spacing={1}
            sx={{ marginBottom: ".5rem" }}
            textAlign={"center"}
          >
            {isMdScreen ? (
              <>
                <ProductTitle style={style} icons={icons} location={"center"} />
                <Divider />
              </>
            ) : null}
          </Stack>
          <Grid container spacing={6} sx={{ marginBottom: "1rem" }}>
            <Grid
              item
              xs={12}
              md={5}
              sx={{
                marginTop: "2rem",
              }}
            >
              <Stack>
                {itemImage && <ProductGalleryViewer images={itemImage} />}
                {coinBullion && <DetailTable data={productDetails} />}
              </Stack>
            </Grid>
            <Grid item xs={12} md={4}>
              <Stack
                spacing={1}
                sx={{ marginBottom: ".5rem", marginTop: "4rem" }}
              >
                {!isMdScreen ? (
                  <ProductTitle
                    style={style}
                    icons={icons}
                    location={"center"}
                  />
                ) : null}
                <Typography>
                  Select a coin or bullion description below to start placing
                  your order!
                </Typography>
                <Stack direction="row" spacing={1}>
                  {coinBullion &&
                    icons &&
                    icons.map((icons, key) => (
                      <Typography key={icons} className="icons">
                        {functions.getIcons(icons)}
                      </Typography>
                    ))}
                </Stack>
              </Stack>
              <Stack spacing={1}>
                <InputLabel id="materialLabel">
                  <StyledLabel>Material</StyledLabel>
                </InputLabel>
                <Select
                  labelId="materialLabel"
                  value={coinBullion.material}
                  size="small"
                  sx={{ width: "100%" }}
                  onChange={(e) => {
                    setCoinBullion({
                      material: e.target.value,
                      description: "",
                    });
                  }}
                  MenuProps={{
                    PaperProps: {
                      sx: {
                        border: "1px solid #939598",
                        borderRadius: "0",
                        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                      },
                    },
                  }}
                >
                  <MenuItem value="None">
                    <em>Select...</em>
                  </MenuItem>
                  {material?.values &&
                    material.values.map((d) => (
                      <MenuItem key={d.value} value={d.value}>
                        {d.label}
                      </MenuItem>
                    ))}
                </Select>
                {coinBullion.material && (
                  <>
                    <InputLabel id="descriptionLabel">
                      <StyledLabel>Description</StyledLabel>
                    </InputLabel>
                    <Select
                      labelId="descriptionLabel"
                      value={coinBullion.description}
                      size="small"
                      sx={{
                        width: "100%",
                      }}
                      MenuProps={{
                        PaperProps: {
                          style: {
                            maxHeight: 300,
                            borderColor: "grey",
                            borderStyle: "solid",
                            borderWidth: "1px",
                          },
                        },
                      }}
                      onChange={(e) => {
                        setCoinBullion({
                          ...coinBullion,
                          description: e.target.value,
                        });
                      }}
                    >
                      <MenuItem value="None">
                        <em>Select...</em>
                      </MenuItem>
                      {description?.values &&
                        description.values.map((d) => (
                          <MenuItem key={d.value} value={d.value}>
                            {d.label}
                          </MenuItem>
                        ))}
                    </Select>
                  </>
                )}
                {coinBullion &&
                  productFields &&
                  productFields.map((field) => {
                    if (field.name === "quantity") {
                      return (
                        <Quantity
                          field={field}
                          key={field.name}
                          isMill={props.isMill}
                        />
                      );
                    } else {
                      return "";
                    }
                  })}

                {coinBullion && productFields && (
                  <Divider flexItem sx={{ paddingTop: "1rem" }} />
                )}
                {coinBullion &&
                  productTreatments &&
                  productTreatments.map((treat) => {
                    return <TreatmentField field={treat} key={treat.label} />;
                  })}
                {coinBullion && productFields && <SpecialInstructions />}
              </Stack>
            </Grid>
            <Grid item xs={12} md={3}>
              {coinBullion && (
                <Price ProductGroup={productGroup} isMill={true} />
              )}
              {advertisement && (
                <DomLink
                  to={{
                    pathname: `${advertisement.alternativeText}`,
                    state: {
                      recentlyViewed: JSON.parse(
                        storage.getSessionStorageItem(
                          "hsRecentlyViewedProducts"
                        )
                      ),
                    },
                  }}
                >
                  <img
                    style={{ marginTop: "4rem" }}
                    src={`${frontImageUrl}${advertisement.url}`}
                    width="100%"
                    alt="advertisement"
                  />
                </DomLink>
              )}
            </Grid>
          </Grid>
          {coinBullion && (
            <>
              <RelatedItems relatedProducts={relatedProducts} />
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <TechnicalArticles productGroup={productGroup} />
                </Grid>
                <Grid item xs={12} md={6}>
                  <CustomerTestimonials />
                </Grid>
              </Grid>
              <RecentlyView />
              {strapiChart && (
                <StrapiChart
                  strapiChart={strapiChart}
                  TopText={TopText}
                  ChartSizeSmall={ChartSizeSmall}
                />
              )}
            </>
          )}
        </Container>
      )}
    </ProductsContext.Provider>
  );
}
