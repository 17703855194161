// Third-party libraries
import React, { useContext, useState, useEffect } from "react";
import {
  Select,
  Typography,
  Stack,
  MenuItem,
  FormControl,
  FormHelperText,
} from "@mui/material";

// Local modules
import { BuildARingContext } from "../../../utils/stateHandlers/contexts";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

export default function BarFields(props) {
  const { barState, barDispatch } = useContext(BuildARingContext);
  const { priceLoaded } = barState;
  const [localSelection, setLocalSelection] = useState(null);
  const field = props.field;
  const isShank = props.shank;

  function selectValue(value, fieldName) {
    if (isShank) {
      barDispatch({ type: "updateShank", field: fieldName, payload: value });
    } else {
      barDispatch({ type: "updateSetting", field: fieldName, payload: value });
    }
    setLocalSelection(value);
  }

  function selectDefaultIfProductUpdated() {
    const localInList = field.values.find(function (value) {
      return value.value === localSelection;
    });
    const defaultInList = field.values.find(function (value) {
      return value.value === field.default;
    });
    if (localSelection !== null) {
      if (!localInList && !defaultInList) {
        setLocalSelection(field.values[0].value);
        selectValue(field.values[0].value, field.name);
      } else if (!localInList && defaultInList) {
        setLocalSelection(field.default);
        selectValue(field.default, field.name);
      }
    } else {
      setLocalSelection(field.default);
      selectValue(field.default, field.name);
    }
  }

  useEffect(() => {
    selectDefaultIfProductUpdated();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.field]);

  return (
    <Stack key={field.name}>
      <Typography sx={{ fontWeight: "700", paddingBottom: ".3rem" }}>
        {field.label}
      </Typography>
      <FormControl error={localSelection === "None" ? true : false}>
        <Select
          key={field.name}
          value={
            localSelection === null
              ? field.default === ""
                ? "None"
                : field.default
              : localSelection
          }
          size="small"
          sx={{ width: "100%" }}
          disabled={!priceLoaded}
          onChange={(e) => {
            selectValue(e.target.value, field.name);
          }}
          MenuProps={{
            PaperProps: {
              sx: {
                border: "1px solid #939598",
                borderRadius: "0",
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
              },
            },
          }}
        >
          <MenuItem value="None">
            <em>Select...</em>
          </MenuItem>
          {field.values &&
            field.values.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
        </Select>
        <FormHelperText>
          {localSelection === "None" ? "Please Make A Selection" : ""}
        </FormHelperText>
      </FormControl>
    </Stack>
  );
}
