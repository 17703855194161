const ProductRoutes = [
  /* --- DIAMOND ---*/
  {
    title: "certified diamond",
    banner: "",
    url: "/Diamonds",
    keywords: ["certified diamond"],
  },
  {
    title: "melee",
    banner: "",
    url: "/melee-diamonds",
    keywords: ["melee diamond"],
  },
  /* --- FINDINGS ---*/
  {
    title: "settings",
    banner: "",
    url: "/products/All%20Settings",
    keywords: ["settings"],
  },
  {
    title: "earrings",
    banner: "",
    url: "/products/Earrings",
    keywords: ["earrings"],
  },
  {
    title: "earring and pendant components",
    banner: "",
    url: "/products/Earring%20and%20Pendant%20Components",
    keywords: ["earring and pendant components", "pendants"],
  },
  {
    title: "chain and chain components",
    banner: "",
    url: "/products/Chain%20and%20Chain%20Components",
    keywords: ["chain and chain components"],
  },
  {
    title: "alphabets",
    banner: "",
    url: "/products/Alphabets",
    keywords: ["alphabets"],
  },
  {
    title: "charms",
    banner: "",
    url: "/product/CHM",
    keywords: ["charms"],
  },
  {
    title: "ring blanks",
    banner: "",
    url: "/product/WRB",
    keywords: ["ring blanks"],
  },
  {
    title: "pendants",
    banner: "",
    url: "/products/Pendants",
    keywords: ["pendants"],
  },
  /* --- BRIDAL ---*/
  {
    title: "solitaires",
    banner: "",
    url: "/products/Solitaires",
    keywords: ["solitaires"],
  },
  {
    title: "build a ring",
    banner: "",
    url: "/ring/build",
    keywords: ["build a ring"],
  },
  {
    title: "engagement rings",
    banner: "",
    url: "/products/All%20Engagement%20Rings",
    keywords: ["engagement rings"],
  },
  /* --- FINISHED JEWELRY ---*/

  {
    title: "signet ring",
    banner: "",
    url: "/products/Signet%20Rings",
    keywords: ["signet rings"],
  },
  {
    title: "bracelets",
    banner: "",
    url: "/products/Bracelets",
    keywords: ["bracelets"],
  },
  {
    title: "fashion rings",
    banner: "",
    url: "/products/Bracelets",
    keywords: ["bracelets"],
  },
  {
    title: "men's fashion jewelry",
    banner: "",
    url: "/products/Men's%20Fashion%20Jewelry",
    keywords: [
      "mens fashion jewelry",
      "men's fashion jewelry",
      "fashion rings",
    ],
  },
  /* --- Shanks ---*/
  {
    title: "shanks",
    banner: "",
    url: "/products/All%20Shanks",
    keywords: ["shanks", "shz"],
  },
  /* --- Mill ---*/
  {
    title: "wire",
    banner: "",
    url: "/products/Wire",
    keywords: ["wire", "wires"],
  },
  {
    title: "tubing",
    banner: "",
    url: "/products/Tubing",
    keywords: ["tubing", "tubes"],
  },
  {
    title: "solder",
    banner: "",
    url: "/group/SOLDER",
    keywords: ["solder", "solders"],
  },
  {
    title: "Coins & Bullion",
    banner: "",
    url: "/product/COINS%20BULLION",
    keywords: ["Coins", "Bullion", "Coins and Bullion"],
  },
  /* --- Bands ---*/
  {
    title: "bands",
    banner: "",
    url: "/products/Bands",
    keywords: ["bands"],
  },
];

export default ProductRoutes;
