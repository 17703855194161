import React from "react";

const OvalProfile = (props) => {
  const viewBoxWidth = 85.7;
  const viewBoxHeight = 86.1;

  const lineColor = "#4a4a4a";
  const strokeWidth2 = "0.5";

  const x = (percentX) => percentX * viewBoxWidth;
  const y = (percentY) => percentY * viewBoxHeight;

  const table = Number(props.diamond.Table).toFixed(0);
  const depthPercent = Number(props.diamond.Depth).toFixed(1);
  const girdle = props.diamond.Girdle || "";
  const girdleSpaceIndex = girdle.indexOf(" ");
  const girdle1 =
    girdleSpaceIndex > 0 ? girdle.slice(0, girdleSpaceIndex) : girdle;
  const girdle2 = girdleSpaceIndex > 0 ? girdle.slice(girdleSpaceIndex) : "";

  const diagramStrokeWidth = "0.01";
  const diagramFontSize = 0.046;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox={"0 0 " + viewBoxWidth + " " + viewBoxHeight}
      width={props.width}
      height={props.height}
      preserveAspectRatio={props.preserveAspectRatio}
    >
      <g>
        <path
          fill="none"
          stroke={props.color}
          strokeWidth="0.75"
          strokeMiterlimit="10"
          d="M42.9,33"
        />
        <polyline
          fill="none"
          stroke={props.color}
          strokeWidth={props.stroke_width}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          points="42.9,33 69,33.2 84.5,40.4 43,40.7 			"
        />
        <polyline
          fill="none"
          stroke={props.color}
          strokeWidth={props.stroke_width}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          points="42.9,33 55.8,36.1 60.1,33.2 			"
        />
        <polyline
          fill="none"
          stroke={props.color}
          strokeWidth={props.stroke_width}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          points="43,40.7 55.8,36.1 70.7,40.4 74.4,35.9 			"
        />
        <line
          fill="none"
          stroke={props.color}
          strokeWidth={props.stroke_width}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          x1="55.8"
          y1="36.1"
          x2="59.6"
          y2="40.6"
        />
        <path
          fill="none"
          stroke={props.color}
          strokeWidth={props.stroke_width}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="M70.5,41.2c-0.1,0.2-16.1,15.9-16.1,15.9"
        />
        <path
          fill="none"
          stroke={props.color}
          strokeWidth={props.stroke_width}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="M70.4,41.2c-0.2,0.1-24.9,18.4-24.9,18.4l14-18.3"
        />
        <line
          fill="none"
          stroke={props.color}
          strokeWidth={props.stroke_width}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          x1="43"
          y1="41.2"
          x2="45.6"
          y2="59.8"
        />
        <path
          fill="none"
          stroke={props.color}
          strokeWidth={props.stroke_width}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="M45.6,59.8L43,63.3l41.4-22c0,0-41.2,0-41.4-0.1"
        />
      </g>
      <g>
        <line
          fill="none"
          stroke={props.color}
          strokeWidth="0.75"
          strokeMiterlimit="10"
          x1="1.3"
          y1="40.8"
          x2="42.9"
          y2="40.8"
        />
        <path
          fill="none"
          stroke={props.color}
          strokeWidth="0.75"
          strokeMiterlimit="10"
          d="M42.9,33"
        />
        <polyline
          fill="none"
          stroke={props.color}
          strokeWidth={props.stroke_width}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          points="42.9,33 16.8,33.2 1.4,40.4 42.8,40.7 			"
        />
        <polyline
          fill="none"
          stroke={props.color}
          strokeWidth={props.stroke_width}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          points="42.9,33 30.1,36.1 25.8,33.2 			"
        />
        <polyline
          fill="none"
          stroke={props.color}
          strokeWidth={props.stroke_width}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          points="42.8,40.7 30.1,36.1 15.2,40.4 11.5,35.9 			"
        />
        <line
          fill="none"
          stroke={props.color}
          strokeWidth={props.stroke_width}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          x1="30.1"
          y1="36.1"
          x2="26.2"
          y2="40.6"
        />
        <path
          fill="none"
          stroke={props.color}
          strokeWidth={props.stroke_width}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="M15.4,41.4c0.1,0.2,16,15.8,16,15.8"
        />
        <path
          fill="none"
          stroke={props.color}
          strokeWidth={props.stroke_width}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="M15.4,41.2c0.2,0.1,24.9,18.4,24.9,18.4l-14-18.3"
        />
        <line
          fill="none"
          stroke={props.color}
          strokeWidth={props.stroke_width}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          x1="42.8"
          y1="41.2"
          x2="40.3"
          y2="59.8"
        />
        <path
          fill="none"
          stroke={props.color}
          strokeWidth={props.stroke_width}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="M40.3,59.8l2.6,3.6l-41.4-22c0,0,41.2,0,41.4-0.1"
        />
      </g>
      <line
        fill="none"
        stroke={props.color}
        strokeWidth={props.stroke_width}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        x1="1.4"
        y1="40.4"
        x2="1.4"
        y2="41.3"
      />
      <line
        fill="none"
        stroke={props.color}
        strokeWidth={props.stroke_width}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        x1="84.5"
        y1="40.4"
        x2="84.5"
        y2="41.2"
      />
      {props.show_diagram && (
        <>
          {/* Table */}
          <line
            x1={x(0.2)}
            x2={x(0.8)}
            y1={y(0.35)}
            y2={y(0.35)}
            stroke={lineColor}
            strokeWidth={strokeWidth2}
          ></line>
          <line
            x1={x(0.2)}
            x2={x(0.2)}
            y1={y(0.33)}
            y2={y(0.37)}
            stroke={lineColor}
            strokeWidth={strokeWidth2}
          ></line>
          <line
            x1={x(0.8)}
            x2={x(0.8)}
            y1={y(0.37)}
            y2={y(0.33)}
            stroke={lineColor}
            strokeWidth={strokeWidth2}
          ></line>

          <text
            x={x(0.37)}
            y={y(0.32)}
            fontSize={y(diagramFontSize)}
            stroke={lineColor}
            strokeWidth={diagramStrokeWidth}
          >
            Table: {table}%
          </text>

          {/* Left Depth */}
          <line
            x1={x(0.06)}
            x2={x(0.06)}
            y1={y(0.35)}
            y2={y(0.7)}
            stroke={lineColor}
            strokeWidth={strokeWidth2}
          ></line>
          <line
            x1={x(0.04)}
            x2={x(0.08)}
            y1={y(0.35)}
            y2={y(0.35)}
            stroke={lineColor}
            strokeWidth={strokeWidth2}
          ></line>
          <line
            x1={x(0.04)}
            x2={x(0.08)}
            y1={y(0.7)}
            y2={y(0.7)}
            stroke={lineColor}
            strokeWidth={strokeWidth2}
          ></line>

          <rect
            x={x(0.005)}
            y={y(0.43)}
            width={x(0.22)}
            height={y(0.135)}
            rx="0.8"
            fill="white"
          ></rect>
          <text
            x={x(0.03)}
            y={y(0.475)}
            fontSize={y(diagramFontSize)}
            stroke={lineColor}
            strokeWidth={diagramStrokeWidth}
          >
            Depth:
          </text>
          <text
            x={x(0.02)}
            y={y(0.542)}
            fontSize={y(diagramFontSize)}
            stroke={lineColor}
            strokeWidth={diagramStrokeWidth}
          >
            {props.diamond.Measurement3}
          </text>

          {/* Right Depth */}
          <line
            x1={x(0.94)}
            x2={x(0.94)}
            y1={y(0.35)}
            y2={y(0.7)}
            stroke={lineColor}
            strokeWidth={strokeWidth2}
          ></line>
          <line
            x1={x(0.92)}
            x2={x(0.96)}
            y1={y(0.35)}
            y2={y(0.35)}
            stroke={lineColor}
            strokeWidth={strokeWidth2}
          ></line>
          <line
            x1={x(0.92)}
            x2={x(0.96)}
            y1={y(0.7)}
            y2={y(0.7)}
            stroke={lineColor}
            strokeWidth={strokeWidth2}
          ></line>

          <rect
            x={x(0.8)}
            y={y(0.43)}
            width={x(0.19)}
            height={y(0.135)}
            rx="0.8"
            fill="white"
          ></rect>
          <text
            x={x(0.81)}
            y={y(0.475)}
            fontSize={y(diagramFontSize)}
            stroke={lineColor}
            strokeWidth={diagramStrokeWidth}
          >
            Depth:
          </text>
          <text
            x={x(0.81)}
            y={y(0.542)}
            fontSize={y(diagramFontSize)}
            stroke={lineColor}
            strokeWidth={diagramStrokeWidth}
          >
            {depthPercent}%
          </text>

          {/* Culet */}
          {props.Culet > 0 && (
            <>
              <circle
                cx={x(0.5)}
                cy={y(0.72)}
                r={y(0.03)}
                fillOpacity="0"
                stroke={lineColor}
                strokeWidth={strokeWidth2}
              />
              <line
                x1={x(0.5)}
                x2={x(0.502)}
                y1={y(0.75)}
                y2={y(0.79)}
                stroke={lineColor}
                strokeWidth={strokeWidth2}
              ></line>
              <line
                x1={x(0.502)}
                x2={x(0.54)}
                y1={y(0.79)}
                y2={y(0.79)}
                stroke={lineColor}
                strokeWidth={strokeWidth2}
              ></line>

              <text
                x={x(0.55)}
                y={y(0.8)}
                fontSize={y(diagramFontSize)}
                stroke={lineColor}
                strokeWidth={diagramStrokeWidth}
              >
                Culet: {props.diamond.Culet}
              </text>
            </>
          )}

          {/* Girdle */}
          {girdle1.length > 0 && (
            <>
              <circle
                cx={x(0.4)}
                cy={y(0.48)}
                r={y(0.03)}
                fillOpacity="0"
                stroke={lineColor}
                strokeWidth={strokeWidth2}
              />
              <line
                x1={x(0.4)}
                x2={x(0.4)}
                y1={y(0.51)}
                y2={y(0.83)}
                stroke={lineColor}
                strokeWidth={strokeWidth2}
              ></line>
              <line
                x1={x(0.4)}
                x2={x(0.38)}
                y1={y(0.83)}
                y2={y(0.83)}
                stroke={lineColor}
                strokeWidth={strokeWidth2}
              ></line>

              <text
                x={x(0.0)}
                y={y(0.82)}
                fontSize={y(diagramFontSize)}
                stroke={lineColor}
                strokeWidth={diagramStrokeWidth}
              >
                Girdle: {girdle1}
              </text>
              <text
                x={x(0.0)}
                y={y(0.88)}
                fontSize={y(diagramFontSize)}
                stroke={lineColor}
                strokeWidth={diagramStrokeWidth}
              >
                {girdle2}
              </text>
            </>
          )}
        </>
      )}
    </svg>
  );
};

export default OvalProfile;
