// Built-in modules
import qs from "qs";

// Third-party libraries
import React, { useContext, useEffect, useReducer, useState } from "react";
import toast from "react-hot-toast";
import {
  Stack,
  Grid,
  Backdrop,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  Typography,
  Container,
  TextField,
  Chip,
  Skeleton,
  Divider,
} from "@mui/material";

// Local modules
import { api } from "../../../utils";
import { functions } from "../../../utils";
import {
  AppContext,
  BuildARingContext,
} from "../../../utils/stateHandlers/contexts";
import { barReducer } from "../../../utils/stateHandlers/reducers";
import states from "../../../utils/stateHandlers/initialStates";
import formatting from "../../../utils/formatHelpers";
import placeHolder from "../../../assets/images/BuildRing_Generic_XLG.jpg";
import BarPrice from "./BarPrice";
import BarFields from "./BarFields";
import BarTechnicalArticles from "./BarTechnicalArticles";
import BarTreatments from "./BarTreatments";
import ProductGalleryViewer from "../components/ProductGalleryViewer";
import BarDetailTable from "../components/barComponents/barDetailTable";
import CustomerTestimonials from "../components/CustomerTestimonials";
import ProductSeries from "../components/ProductSeries";
import useMediaQueryUtils from "../../../utils/mediaQueryUtils";
import RecentlyView from "../productDetails/RecentlyView";

export default function BarHome(props) {
  const isMdScreen = useMediaQueryUtils("md");
  const { authToken } = useContext(AppContext);
  const [barState, barDispatch] = useReducer(
    barReducer,
    states.initialBarState
  );
  const { pageLoaded, setting, shank, ring, quantity } = barState;
  const [loadingSetting, setLoadingSetting] = useState(false);
  const [settingModalOpen, setSettingModalOpen] = useState(false);
  const [loadingShank, setLoadingShank] = useState(false);
  const [shankModalOpen, setShankModalOpen] = useState(false);
  const [localInput, setLocalInput] = useState(1);

  const LoadingSkeleton = () => (
    <Stack direction="row" spacing={2}>
      <Skeleton variant="rectangular" width={90} height={90} />
      <Stack>
        <Skeleton variant="text" sx={{ fontSize: "2rem" }} width="25rem" />
        <Skeleton variant="text" sx={{ fontSize: "2rem" }} />
      </Stack>
      <Skeleton variant="rectangular" width={30} height={30} />
    </Stack>
  );

  const Setting = () => (
    <Stack spacing={1} sx={{ marginTop: "3rem" }}>
      <Stack
        direction="row"
        sx={{
          backgroundColor: "#cfdde9",
        }}
        alignItems="center"
      >
        <Typography variant="h2" sx={{ width: "100%" }}>
          Setting
        </Typography>
        <Chip
          label="Pick A New Setting"
          variant="outlined"
          className="float-right"
          color="primary"
          sx={{ marginRight: "1rem" }}
          onClick={() => setSettingModalOpen(true)}
        />
      </Stack>
      {loadingSetting ? (
        LoadingSkeleton()
      ) : (
        <Stack direction="row" spacing={2}>
          {setting.images && (
            <img
              src={setting.images[0]}
              className="detail-imgs"
              alt="Mini Product"
              onError={(e) => {
                e.target.src = placeHolder;
              }}
            />
          )}
          <Stack spacing={2}>
            <Typography>Current Setting: {setting.style}</Typography>
            <Typography>{`${formatting["decodeHtml"](
              setting.settingInfo.description
            )}`}</Typography>
          </Stack>
          <Stack direction="row" spacing={1}>
            {setting.icons &&
              setting.icons.map((icons, key) => (
                <Typography key={icons} className="icons">
                  {functions.getIcons(icons)}
                </Typography>
              ))}
          </Stack>
        </Stack>
      )}
      <Stack spacing={3} sx={{ marginTop: "2rem" }}>
        {setting &&
          setting.settingFields.map((field, index) => (
            <React.Fragment key={index}>
              {field.name !== "quantity" && (
                <BarFields field={field} shank={false} />
              )}
            </React.Fragment>
          ))}
        {setting &&
          setting.settingTreatments.map((treatment, index) => (
            <BarTreatments field={treatment} shank={false} key={index} />
          ))}
      </Stack>
    </Stack>
  );

  const Shank = () => (
    <Stack spacing={1} sx={{ marginTop: "3rem" }}>
      <Stack
        direction="row"
        sx={{
          backgroundColor: "#cfdde9",
        }}
        alignItems="center"
      >
        <Typography variant="h2" sx={{ width: "100%" }}>
          Shank
        </Typography>
        <Chip
          label="Pick A New Shank"
          variant="outlined"
          className="float-right"
          color="primary"
          sx={{ marginRight: "1rem" }}
          onClick={() => setShankModalOpen(true)}
        />
      </Stack>
      {loadingShank ? (
        LoadingSkeleton()
      ) : (
        <Stack direction="row" spacing={2}>
          {shank.images && (
            <img
              src={shank.images[0]}
              className="detail-imgs"
              alt="Mini Product"
              onError={(e) => {
                e.target.src = placeHolder;
              }}
            />
          )}
          <Stack spacing={2}>
            <Typography>Current Shank: {shank.style}</Typography>
            <Typography>{`${formatting["decodeHtml"](
              shank.shankInfo.description
            )}`}</Typography>
          </Stack>
          <Stack direction="row" spacing={1}>
            {shank.icons &&
              shank.icons.map((icons, key) => (
                <Typography key={icons} className="icons">
                  {functions.getIcons(icons)}
                </Typography>
              ))}
          </Stack>
        </Stack>
      )}

      <Stack spacing={3} sx={{ marginTop: "2rem" }}>
        {shank &&
          shank.shankFields.map((field, index) => (
            <React.Fragment key={index}>
              {field.name !== "quantity" && (
                <BarFields field={field} shank={true} />
              )}
            </React.Fragment>
          ))}
        {shank &&
          shank.shankTreatments.map((treatment, index) => (
            <BarTreatments field={treatment} shank={true} key={index} />
          ))}
      </Stack>
    </Stack>
  );

  function appendError(error) {
    const errorMessage = [];
    error.forEach((err) => errorMessage.push(err));
    return errorMessage;
  }

  function getInitialMatchingFields(initialStyle, initialMatchingStyle) {
    api.fetch(`quickorder/form?style=${initialMatchingStyle}`).then((res) => {
      if (res.isAxiosError) {
        const errorMessage = appendError(res.response.data.errors);
        toast.error(
          `There was an error processing your request. ${errorMessage.toString()}`
        );
      } else {
        if (res.data.product === "SH") {
          barDispatch({ type: "setShank", payload: res.data });
          makeInitialRingQueryString(initialStyle, res.data);
        } else {
          barDispatch({ type: "setSetting", payload: res.data });
          makeInitialRingQueryString(res.data, initialStyle);
        }
        barDispatch({ type: "setPageLoaded", payload: true });
      }
    });
  }

  function makeInitialRingQueryString(initialSetting, initialShank) {
    const infoToStringify = {
      quantity: 1,
      quantity_unit: "EA",
      setting: {
        product: initialSetting.item.product,
        dimension: initialSetting.item.dimension,
        carat_size: initialSetting.item.carat_size,
        finger_size: initialSetting.item.finger_size,
        material: initialSetting.item.material,
      },
      shank: {
        product: initialShank.item.product,
        dimension: initialShank.item.dimension,
        carat_size: initialShank.item.carat_size,
        finger_size: initialShank.item.finger_size,
        material: initialShank.item.material,
      },
    };
    const query = qs.stringify(infoToStringify);
    if (authToken) {
      getInitialRing(query);
    } else {
      barDispatch({ type: "setPriceLoaded", payload: true });
    }
  }

  function getInitialRing(initialQueryString) {
    api.fetch(`quickorder/ring?${initialQueryString}`).then((res) => {
      if (res.isAxiosError) {
        const errorMessage = appendError(res.response.data.errors);
        toast.error(
          `There was an error processing your request. ${errorMessage.toString()}`
        );
        barDispatch({
          type: "setMessage",
          payload: {
            message: errorMessage,
            type: "error",
          },
        });
        barDispatch({ type: "setPriceLoaded", payload: true });
      } else {
        barDispatch({ type: "setRing", payload: res.data });
        barDispatch({ type: "setPriceLoaded", payload: true });
      }
    });
  }

  function updateSetting(style) {
    setSettingModalOpen(false);
    setLoadingSetting(true);
    barDispatch({
      type: "setMessage",
      payload: { message: null, type: null },
    });
    api.fetch(`quickorder/form?style=${style}`).then((res) => {
      if (res.isAxiosError) {
        const errorMessage = appendError(res.response.data.errors);
        toast.error(
          `There was an error processing your request. ${errorMessage.toString()}`
        );
        barDispatch({
          type: "setMessage",
          payload: {
            message: errorMessage,
            type: "error",
          },
        });
        barDispatch({ type: "setPriceLoaded", payload: true });
        setLoadingSetting(false);
      } else {
        barDispatch({ type: "setSetting", payload: res.data });
        setLoadingSetting(false);
      }
    });
  }

  function updateShank(style) {
    setShankModalOpen(false);
    setLoadingShank(true);
    barDispatch({
      type: "setMessage",
      payload: { message: null, type: null },
    });
    api.fetch(`quickorder/form?style=${style}`).then((res) => {
      if (res.isAxiosError) {
        const errorMessage = appendError(res.response.data.errors);
        toast.error(
          `There was an error processing your request. ${errorMessage.toString()}`
        );
        barDispatch({
          type: "setMessage",
          payload: {
            message: errorMessage,
            type: "error",
          },
        });
        barDispatch({ type: "setPriceLoaded", payload: true });
        setLoadingShank(false);
      } else {
        barDispatch({ type: "setShank", payload: res.data });
        setLoadingShank(false);
      }
    });
  }

  function makeQueryString() {
    const infoToStringify = {
      quantity: quantity,
      quantity_unit: "EA",
      setting: {
        product: setting.settingInfo.product,
        dimension: setting.settingInfo.dimension,
        carat_size: setting.settingInfo.carat_size,
        finger_size: setting.settingInfo.finger_size,
        material: setting.settingInfo.material,
      },
      shank: {
        product: shank.shankInfo.product,
        dimension: shank.shankInfo.dimension,
        carat_size: shank.shankInfo.carat_size,
        finger_size: shank.shankInfo.finger_size,
        material: shank.shankInfo.material,
      },
    };
    const arrayOfValues = Object.values(infoToStringify);
    const searchingForNone = arrayOfValues.includes("None");
    if (!searchingForNone) {
      const query = qs.stringify(infoToStringify);
      return query;
    } else {
      barDispatch({ type: "setPriceLoaded", payload: true });
      return false;
    }
  }

  function updateRing() {
    barDispatch({ type: "setPriceLoaded", payload: false });
    const query = makeQueryString();
    if (query !== false) {
      api.fetch(`quickorder/ring?${query}`).then((res) => {
        if (res.isAxiosError) {
          const errorMessage = appendError(res.response.data.errors);
          toast.error(
            `There was an error processing your request. ${errorMessage.toString()}`
          );
          barDispatch({
            type: "setMessage",
            payload: {
              message: errorMessage,
              type: "error",
            },
          });
          barDispatch({ type: "setPriceLoaded", payload: true });
        } else {
          barDispatch({ type: "setRing", payload: res.data });
          barDispatch({
            type: "setMessage",
            payload: { message: null, type: null },
          });
          barDispatch({ type: "setPriceLoaded", payload: true });
        }
      });
    }
  }

  useEffect(() => {
    barDispatch({
      type: "setMessage",
      payload: { message: null, type: null },
    });
    if (props.match.params.StartingProduct) {
      api
        .fetch(`quickorder/form?style=${props.match.params.StartingProduct}`)
        .then((res) => {
          if (res.isAxiosError) {
            const errorMessage = appendError(res.response.data.errors);
            toast.error(
              `There was an error processing your request. ${errorMessage.toString()}`
            );
            barDispatch({
              type: "setMessage",
              payload: {
                message: errorMessage,
                type: "error",
              },
            });
            barDispatch({ type: "setPriceLoaded", payload: true });
          } else {
            getInitialMatchingFields(
              res.data,
              res.data.compatible_styles.styles[0].style
            );
            if (res.data.product === "SH") {
              barDispatch({ type: "setShank", payload: res.data });
            } else {
              barDispatch({ type: "setSetting", payload: res.data });
            }
          }
        });
    } else {
      api.fetch(`quickorder/form?style=SHZ40`).then((res) => {
        if (res.isAxiosError) {
          const errorMessage = appendError(res.response.data.errors);
          toast.error(
            `There was an error processing your request. ${errorMessage.toString()}`
          );
          barDispatch({
            type: "setMessage",
            payload: {
              message: errorMessage,
              type: "error",
            },
          });
          barDispatch({ type: "setPriceLoaded", payload: true });
        } else {
          getInitialMatchingFields(res.data, "S41");
          barDispatch({ type: "setShank", payload: res.data });
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (pageLoaded && quantity > 0) {
      if (authToken) {
        updateRing();
      } else {
        barDispatch({ type: "setPriceLoaded", payload: true });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shank.shankInfo, setting.settingInfo, quantity]);

  useEffect(() => {
    if (localInput !== quantity) {
      const timeoutId = setTimeout(
        () =>
          barDispatch({
            type: "updateQuantity",
            payload: localInput,
          }),
        1000
      );
      return () => clearTimeout(timeoutId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localInput]);

  return (
    <BuildARingContext.Provider value={{ barState, barDispatch }}>
      {!pageLoaded ? (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={true}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : (
        <Container maxWidth="xl">
          {isMdScreen ? (
            <Stack spacing={3}>
              <Typography
                variant="h4"
                textAlign={"center"}
                marginTop={"4rem"}
              >{`${shank.style}/${setting.style}`}</Typography>
              <Divider />
            </Stack>
          ) : null}
          <Grid container spacing={6}>
            <Grid item xs={12} md={5}>
              {ring && ring.images && (
                <>
                  {authToken ? (
                    <ProductGalleryViewer images={ring.images} baring={true} />
                  ) : (
                    <img
                      src={placeHolder}
                      alt="Not Logged In"
                      className="selected-product"
                    />
                  )}

                  <BarDetailTable data={ring.ringDetails} />
                </>
              )}
            </Grid>
            <Grid item xs={12} md={4}>
              {!isMdScreen ? (
                <Typography
                  variant="h4"
                  marginTop={"4rem"}
                >{`${shank.style}/${setting.style}`}</Typography>
              ) : null}
              <Grid item xs={12}>
                {setting && Setting()}
              </Grid>
              <Grid item xs={12}>
                {shank && Shank()}
              </Grid>

              <Stack sx={{ marginTop: "2rem" }} spacing={1}>
                <Typography variant="h2">How many would you like?</Typography>
                <Typography
                  sx={{
                    fontWeight: "700",
                    paddingBottom: ".3rem",
                  }}
                >
                  Quantity (EA)
                </Typography>
                <TextField
                  color="primary"
                  onChange={(e) => {
                    setLocalInput(e.target.value);
                  }}
                  type="number"
                  size="small"
                  sx={{
                    width: "100%",
                    margin: "unset",
                  }}
                  helperText={
                    <Typography variant="subtitle2">
                      Please enter your quantity
                    </Typography>
                  }
                  value={localInput}
                />
              </Stack>
              <Stack>
                <Typography
                  sx={{
                    fontWeight: "700",
                    paddingBottom: ".3rem",
                    marginTop: "2rem",
                  }}
                >
                  Special Instructions
                </Typography>
                <TextField
                  color="primary"
                  variant="outlined"
                  size="small"
                  sx={{
                    width: "100%",
                    margin: "unset",
                  }}
                  onChange={(e) => {
                    barDispatch({
                      type: "updateMessage",
                      payload: e.target.value,
                    });
                  }}
                  inputProps={{
                    style: {
                      fontSize: 15,
                      paddingBottom: 100,
                    },
                  }}
                />
              </Stack>
            </Grid>
            <Grid item xs={12} md={3}>
              <BarPrice />
            </Grid>
          </Grid>
          {ring.productSeries !== false && (
            <ProductSeries data={ring.productSeries} />
          )}
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <BarTechnicalArticles />
            </Grid>
            <Grid item xs={12} md={6}>
              <CustomerTestimonials />
            </Grid>
          </Grid>
          <RecentlyView />
          <Dialog
            open={settingModalOpen}
            maxWidth="md"
            onClose={() => setSettingModalOpen(false)}
          >
            <DialogTitle>Pick A Setting</DialogTitle>
            <DialogContent>
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: {
                    sm: "repeat(1, 1fr)",
                    md: "repeat(2, 1fr)",
                    lg: "repeat(3, 1fr)",
                    xl: "repeat(4, 1fr)",
                  },
                }}
              >
                {shank.compatible_styles &&
                  shank.compatible_styles.map((setting) => (
                    <Stack
                      onClick={() => updateSetting(setting.style)}
                      spacing={1}
                      className="pointer"
                      key={setting.style}
                    >
                      <img
                        className="related-items"
                        alt="Related Product"
                        src={setting.images[0]}
                        width="200"
                        height="200"
                      />
                      <Typography
                        className="style-text center"
                        sx={{ fontSize: "1.6rem", fontWeight: "800" }}
                      >{`${setting.style}`}</Typography>
                      <Typography
                        className="style-text center"
                        sx={{ textAlign: "center", fontSize: "1.2rem" }}
                      >
                        {formatting["decodeHtml"](setting.description)}
                      </Typography>
                      <Stack className="icon-div" spacing={1} direction="row">
                        {setting.icons.length === 0 ? (
                          <Typography className="icons" />
                        ) : (
                          setting.icons.map((icons, key) => {
                            return (
                              <Typography key={icons} className="icons">
                                {functions.getIcons(icons)}
                              </Typography>
                            );
                          })
                        )}
                      </Stack>
                    </Stack>
                  ))}
              </Box>
            </DialogContent>
          </Dialog>
          <Dialog
            open={shankModalOpen}
            maxWidth="md"
            onClose={() => setShankModalOpen(false)}
          >
            <DialogTitle>Pick A Shank</DialogTitle>
            <DialogContent>
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: {
                    sm: "repeat(1, 1fr)",
                    md: "repeat(2, 1fr)",
                    lg: "repeat(3, 1fr)",
                    xl: "repeat(4, 1fr)",
                  },
                }}
              >
                {setting.compatible_styles &&
                  setting.compatible_styles.map((shank) => (
                    <Stack
                      onClick={() => updateShank(shank.style)}
                      spacing={1}
                      className="pointer"
                      key={shank.style}
                    >
                      <img
                        className="related-items"
                        alt="Related Product"
                        src={shank.images[0]}
                        width="200"
                        height="200"
                      />
                      <Typography
                        className="style-text center"
                        sx={{ fontSize: "1.6rem", fontWeight: "800" }}
                      >{`${shank.style}`}</Typography>
                      <Typography
                        className="style-text center"
                        sx={{ textAlign: "center", fontSize: "1.2rem" }}
                      >
                        {formatting["decodeHtml"](shank.description)}
                      </Typography>
                      <Stack className="icon-div" spacing={1} direction="row">
                        {shank.icons.length === 0 ? (
                          <Typography className="icons" />
                        ) : (
                          shank.icons.map((icons, key) => {
                            return (
                              <Typography key={icons} className="icons">
                                {functions.getIcons(icons)}
                              </Typography>
                            );
                          })
                        )}
                      </Stack>
                    </Stack>
                  ))}
              </Box>
            </DialogContent>
          </Dialog>
        </Container>
      )}
    </BuildARingContext.Provider>
  );
}
