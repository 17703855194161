// Third-party libraries
import React, { useEffect, useState } from "react";
import {
  Container,
  Divider,
  Typography,
  Stack,
  Grid,
  Select,
  TextField,
  MenuItem,
} from "@mui/material";

// Local modules
import api from "../../../utils/api";
import TechnicalResults from "../search/TechnicalResults";
import LoadingIndicator from "../../products/productLanding/LoadingIndicator";
import Keywords from "./Keywords";
import useMediaQueryUtils from "../../../utils/mediaQueryUtils";

export default function Articles() {
  const [articles, setArticles] = useState();
  const [searchTerm, setSearchTerm] = useState();
  const [resultsLoaded, setResultsLoaded] = useState(false);
  const isMdScreen = useMediaQueryUtils("md");

  function filterArray(array, query) {
    const newArray = array
      .filter((content) => {
        if (query) {
          return (
            content.attributes.tag &&
            content.attributes.tag.some(
              (tag) => tag && tag.toLowerCase().includes(query.toLowerCase())
            )
          );
        }
        return true;
      })
      .map((obj) => {
        return {
          ...obj.attributes,
          id: obj.id,
        };
      });
    return newArray;
  }

  const handleKeywordClick = (selectedKeyword) => {
    setSearchTerm(selectedKeyword);
  };

  useEffect(() => {
    let apiUrl =
      "/technical-articles?populate=*&pagination[page]=1&pagination[pageSize]=100";

    const fetchData = async () => {
      try {
        const response = await api.fetchStrapi(apiUrl);
        const data = filterArray(response.data.data, searchTerm);

        const modifiedData = data.map((items) => {
          items["url"] = `/technicalarticles/${items.id}`;
          return items;
        });

        setArticles(modifiedData);
        setResultsLoaded(true);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [searchTerm]);

  return (
    <Container maxWidth="xl" className="careers-text">
      <Stack spacing={1}>
        <Typography variant="articleTitle" align="center" marginTop={"2rem"}>
          Technical Articles
        </Typography>
        <Divider sx={{ marginTop: "5rem", marginBottom: "2rem" }} />
        <Grid container spacing={2} sx={{ flexDirection: "row-reverse" }}>
          <Grid item xs={12} md={4} marginTop={"3rem"} sx={{ padding: 0 }}>
            <Stack spacing={2}>
              <Typography>
                <b>
                  Narrow your article results by clicking a specific topic
                  below:
                </b>
              </Typography>
              <Stack spacing={1}>
                {!isMdScreen ? (
                  Keywords.map((keyword, key) => (
                    <Typography
                      key={key}
                      onClick={() => handleKeywordClick(keyword)}
                      style={{
                        cursor: "pointer",
                        textDecoration: "underline",
                        fontWeight: "bold",
                      }}
                    >
                      {keyword}
                    </Typography>
                  ))
                ) : (
                  <Select onChange={(e) => handleKeywordClick(e.target.value)}>
                    {Keywords.map((keyword, key) => (
                      <MenuItem key={key} value={keyword}>
                        {keyword}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              </Stack>
            </Stack>
            <Stack
              spacing={2}
              sx={{ margin: "2rem 0 2rem 0 " }}
              onClick={() => setSearchTerm("")}
            >
              <Typography
                style={{
                  cursor: "pointer",
                  textDecoration: "underline",
                }}
              >
                Clear filter
              </Typography>
            </Stack>
            <Divider />
            <Stack spacing={2} sx={{ marginTop: "2rem" }}>
              <Typography>Search articles</Typography>
              <TextField
                variant="outlined"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </Stack>
          </Grid>
          <Grid item xs={12} md={8}>
            {!resultsLoaded ? (
              <LoadingIndicator page={"Article"} />
            ) : (
              <TechnicalResults
                strapiResults={articles}
                searchTerm={null}
                isSearch={false}
              />
            )}
          </Grid>
        </Grid>
      </Stack>
    </Container>
  );
}
