// Built-in modules
import moment from "moment";

// Third-party libraries
import React from "react";
import ReactDOMServer from "react-dom/server";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";
import remarkGfm from "remark-gfm";
import Html from "react-pdf-html";
import {
  PDFDownloadLink,
  Page,
  Text,
  Document,
  StyleSheet,
} from "@react-pdf/renderer";
import PrintTwoToneIcon from "@mui/icons-material/PrintTwoTone";
import Button from "@mui/material/Button";

const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },
  title: {
    fontSize: 20,
    margin: 5,
    textAlign: "center",
    fontFamily: "Helvetica-Bold",
  },
  author: {
    fontSize: 12,
    textAlign: "center",
    marginBottom: 40,
  },
  subtitleUpper: {
    textTransform: "capitalize",
    fontSize: 14,
    margin: 5,
    fontFamily: "Times-Roman",
    textAlign: "center",
  },
  subtitle: {
    fontSize: 14,
    margin: 5,
    fontFamily: "Times-Roman",
    textAlign: "center",
    color: "grey",
  },
  text: {
    marginTop: 12,
    marginBottom: 12,
    fontSize: 12,
    textAlign: "justify",
    fontFamily: "Times-Roman",
  },
  image: {
    marginVertical: 15,
    marginHorizontal: 100,
  },
  header: {
    fontSize: 12,
    marginBottom: 20,
    textAlign: "center",
    color: "grey",
  },
  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
});

export default function PrintArticle(props) {
  let currentDate = new Date();
  let cDay = currentDate.getDate();
  let cMonth = currentDate.getMonth() + 1;
  let cYear = currentDate.getFullYear();
  let date = `${cMonth}-${cDay}-${cYear}`;

  const element = (
    <html>
      <body>
        <div style={styles.title}>{props.article.attributes.title}</div>
        <div style={styles.subtitleUpper}>
          {props.article.attributes.subtitle}
        </div>
        <div style={styles.subtitle}>{`${
          props.article.attributes.author
        } | ${moment(props.article.attributes.date).format(
          "MMM Do YYYY"
        )}`}</div>
        <div style={styles.subtitle}>{`Pulled From Hoover & Strong on ${moment(
          date
        ).format("MMM Do YYYY")}`}</div>
        <div style={styles.text}>
          <ReactMarkdown
            components={{
              p: ({ node, ...props }) => <div style={styles.text} {...props} />,
            }}
            children={props.article.attributes.article}
            remarkPlugins={[remarkGfm]}
          />
        </div>
      </body>
    </html>
  );

  const html = ReactDOMServer.renderToStaticMarkup(element);

  const MyDoc = () => (
    <Document>
      <Page size="A4" style={styles.body}>
        <Html>{html}</Html>
        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
          fixed
        />
      </Page>
    </Document>
  );

  return (
    <Button
      startIcon={<PrintTwoToneIcon />}
      className="float-right"
      variant="contained"
      color="secondary"
      disableElevation
      sx={{
        borderRadius: "50px",
        fontSize: "1.2rem",
        lineHeight: "1.2rem",
        letterSpacing: "0.05rem",
        textTransform: "initial",
      }}
    >
      <PDFDownloadLink
        document={<MyDoc />}
        fileName={`${props.article.attributes.title}-H&S The Gold Standard-${date}.pdf`}
      >
        {({ blob, url, loading, error }) =>
          loading ? "Loading..." : "Download & Print"
        }
      </PDFDownloadLink>
    </Button>
  );
}
