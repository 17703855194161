// Third-party libraries
import React, { useContext } from "react";
import ClearIcon from "@mui/icons-material/Clear";
import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";

// Local modules
import { api } from "../../../utils";
import { FormContext } from "../../../utils/stateHandlers/contexts";
import formatting from "../../../utils/formatHelpers";
import { Mobile, Default } from "../../../utils/deviceHelpers";

export default function RefiningEstimatorPreview(props) {
  const { formState, dispatch } = useContext(FormContext);
  const { estimates, quoteId } = formState;

  function removeEstimate(id) {
    api
      .post("forms/refining/remove", { quote_id: quoteId, id: id })
      .then((res) => {
        const remainingEstimates = estimates.filter(function (estimate) {
          return estimate.id !== id;
        });
        dispatch({
          type: "submitRefiningEstimate",
          payload: {
            estimates: remainingEstimates,
            quoteId: quoteId,
          },
        });
      });
  }

  return (
    <>
      <Typography variant="h6" align="center">
        Your Estimates
      </Typography>
      <Table size="small">
        <TableHead>
          <TableRow>
            <Mobile>
              <TableCell>Metal (Wt)</TableCell>
            </Mobile>
            <Default>
              <TableCell>Weight</TableCell>
              <TableCell>Metal</TableCell>
            </Default>
            <TableCell>We'll Pay</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {estimates.map((estimate) => (
            <TableRow key={estimate.id}>
              <Mobile>
                <TableCell>
                  <Typography>
                    {estimate.invoice.Metal} ({estimate.invoice.Weight})
                  </Typography>
                </TableCell>
              </Mobile>
              <Default>
                <TableCell>
                  <Typography>{estimate.invoice.Weight}</Typography>
                </TableCell>
                <TableCell>
                  <Typography>{estimate.invoice.Metal}</Typography>
                </TableCell>
              </Default>
              <TableCell>
                <Typography>
                  {formatting["price2"](estimate.invoice.WePay)}
                </Typography>
              </TableCell>
              <TableCell>
                <ClearIcon
                  onClick={() => removeEstimate(estimate.id)}
                  className="pointer"
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </>
  );
}
