function cartReducer(state, action) {
  const p = action.payload;
  switch (action.type) {
    case "setCart":
      return {
        ...state,
        cart: p,
      };
    case "setCartLoaded":
      return {
        ...state,
        cartLoaded: p,
      };
    case "setPageLoaded":
      return {
        ...state,
        pageLoaded: p,
      };
    case "setCartCount":
      return {
        ...state,
        cartCount: p,
      };
    case "setFieldsReloaded":
      return {
        ...state,
        fieldsReloaded: p,
      };
    case "updateCart":
      return {
        ...state,
        cart: p.cart,
        cartCount: p.cartCount,
      };
    case "setShowCheckout":
      return {
        ...state,
        showCheckout: p,
      };
    case "setCheckout":
      return {
        ...state,
        fields: p.fields,
        order: p.order,
        totals: p.totals,
      };
    case "updateFields":
      return {
        ...state,
        order: {
          ...state.order,
          [action.field]: p,
        },
      };
    case "setSpecialInstructions":
      return {
        ...state,
        specialInstructions: p,
      };
    case "reloadFields":
      return {
        ...state,
        fields: p.updated_fields,
        totals: p.totals,
      };
    case "setShippingAddress":
      return {
        ...state,
        shippingAddress: p,
      };
    case "setNewAddress":
      return {
        ...state,
        newAddress: {
          ...state.newAddress,
          [action.field]: p,
        },
      };
    case "setNewCreditCard":
      return {
        ...state,
        newCreditCard: {
          ...state.newCreditCard,
          [action.field]: p,
        },
      };
    case "setOrderSubmitted":
      return {
        ...state,
        placedAt: p.placedAt,
        submitted: p.submitted,
      };
    default:
      return;
  }
}

function createAccountReducer(state, action) {
  const p = action.payload;
  switch (action.type) {
    case "setPageLoaded":
      return {
        ...state,
        pageLoaded: p,
      };
    case "setFormFilled":
      return {
        ...state,
        formFilled: p,
      };
    case "setFormInfo":
      return {
        ...state,
        formInfo: {
          ...state.formInfo,
          [action.field]: p,
        },
      };
    case "setFormCopy":
      return {
        ...state,
        formtitle: p.formtitle,
        formtext: p.formtext,
        formimage: p.formimage,
      };
    case "setFormFields":
      return {
        ...state,
        formFields: p,
      };
    case "setRecaptcha":
      return {
        ...state,
        reCAPTCHA: p,
      };
    case "setAccountCreated":
      return {
        ...state,
        accountCreated: p,
      };
    default:
      return;
  }
}

function formReducer(state, action) {
  const p = action.payload;
  switch (action.type) {
    case "setIsLoaded":
      return {
        ...state,
        isLoaded: p,
      };
    case "setIsFilled":
      return {
        ...state,
        isFilled: p,
      };
    case "setOfficerFields":
      return {
        ...state,
        officers: p,
      };
    case "setOfficerFieldValues":
      return {
        ...state,
        officerFieldValues: {
          ...state.officerFieldValues,
          [action.field]: p,
        },
      };
    case "setReferenceFields":
      return {
        ...state,
        references: p,
      };
    case "setReferenceFieldValues":
      return {
        ...state,
        referenceFieldValues: {
          ...state.referenceFieldValues,
          [action.field]: p,
        },
      };
    case "setFields":
      return {
        ...state,
        fields: p,
        submitted: false,
        message: "",
      };
    case "setMarketingFields":
      return {
        ...state,
        marketingFields: p,
      };
    case "setAddressFields":
      return {
        ...state,
        addressFields: p,
      };
    case "setFormCopy":
      return {
        ...state,
        formtitle: p.formtitle,
        formtext: p.formtext,
        formimage: p.formimage,
      };
    case "setDiamondShippingCopy":
      return {
        ...state,
        diamondshippingtitle: p.diamondshippingtitle,
        diamondshippingtext: p.diamondshippingtext,
      };
    case "updateFormInfo":
      return {
        ...state,
        formInfo: {
          ...state.formInfo,
          [action.field]: p,
        },
      };
    case "submitForm":
      return {
        ...state,
        submitted: true,
        message: p,
      };
    case "clearForm":
      return {
        ...state,
        submitted: false,
      };
    case "submitRefiningEstimate":
      return {
        ...state,
        estimates: p.estimates,
        quoteId: p.quoteId,
      };
    case "setQuoteId":
      return {
        ...state,
        quoteId: p,
      };
    default:
      return;
  }
}

function landingReducer(state, action) {
  const p = action.payload;
  switch (action.type) {
    case "setPageLoaded":
      return {
        ...state,
        pageLoaded: p,
      };
    case "setItemsLoaded":
      return {
        ...state,
        itemsLoaded: p,
      };
    case "setFilters":
      return {
        ...state,
        filters: p,
      };
    case "setClearAll":
      return {
        ...state,
        clearAll: p,
      };
    case "setShowFilters":
      return {
        ...state,
        showFilters: p,
      };
    case "showBestSellers":
      return {
        ...state,
        bestSellers: p,
      };
    case "setProducts":
      return {
        ...state,
        products: p,
      };
    case "updateSearchQuery":
      return {
        ...state,
        searchQuery: {
          ...state.searchQuery,
          fields: p,
        },
      };
    default:
      return;
  }
}

function barReducer(state, action) {
  const p = action.payload;
  switch (action.type) {
    case "setMessage":
      return {
        ...state,
        message: p.message,
        messageType: p.type,
      };
    case "setPageLoaded":
      return {
        ...state,
        pageLoaded: p,
      };
    case "setPriceLoaded":
      return {
        ...state,
        priceLoaded: p,
      };
    case "updateQueryString":
      return {
        ...state,
        queryString: p,
      };
    case "setSetting":
      return {
        ...state,
        setting: {
          ...state.setting,
          style: p.style,
          settingInfo: {
            description: p.description,
            dimension: p.dimension,
            carat_size: p.item.carat_size,
            customer_key: p.item.customer_key,
            errors: p.item.errors,
            finger_size: p.item.finger_size,
            material: p.item.material,
            message: p.item.message,
            product: p.item.product,
            quantity: p.item.quantity,
            quantity_unit: p.item.quantity_unit,
            treatments: p.item.treatments,
          },
          settingFields: p.fields,
          settingTreatments: p.treatments,
          compatible_styles: p.compatible_styles
            ? p.compatible_styles.styles
            : [],
          related_items: p.related_items,
          icons: p.icons,
          images: p.images,
          settingSeries: p.series,
        },
      };
    case "updateSetting":
      return {
        ...state,
        setting: {
          ...state.setting,
          settingInfo: {
            ...state.setting.settingInfo,
            [action.field]: p,
          },
        },
      };
    case "updateSettingTreatment":
      return {
        ...state,
        setting: {
          ...state.setting,
          settingInfo: {
            ...state.setting.settingInfo,
            treatments: p,
          },
        },
      };
    case "setShank":
      return {
        ...state,
        shank: {
          ...state.shank,
          style: p.style,
          shankInfo: {
            description: p.description,
            dimension: p.dimension,
            carat_size: p.item.carat_size,
            customer_key: p.item.customer_key,
            errors: p.item.errors,
            finger_size: p.item.finger_size,
            material: p.item.material,
            message: p.item.message,
            product: p.item.product,
            quantity: p.item.quantity,
            quantity_unit: p.item.quantity_unit,
            treatments: p.item.treatments,
          },
          shankFields: p.fields,
          shankTreatments: p.treatments,
          compatible_styles: p.compatible_styles
            ? p.compatible_styles.styles
            : [],
          related_items: p.related_items,
          icons: p.icons,
          images: p.images,
          shankSeries: p.series,
        },
      };
    case "updateShank":
      return {
        ...state,
        shank: {
          ...state.shank,
          shankInfo: {
            ...state.shank.shankInfo,
            [action.field]: p,
          },
        },
      };
    case "updateShankTreatment":
      return {
        ...state,
        shank: {
          ...state.shank,
          shankInfo: {
            ...state.shank.shankInfo,
            treatments: p,
          },
        },
      };
    case "setRing":
      return {
        ...state,
        ring: {
          ...state.ring,
          price: p.price,
          partcode: {
            ...state.ring.partcode,
            description: p.partcode.description,
            dimension: p.partcode.dimension,
            carat_size: p.partcode.carat_size,
            customer_key: p.partcode.customer_key,
            errors: p.partcode.errors,
            finger_size: p.partcode.finger_size,
            material: p.partcode.material,
            message: p.partcode.message,
            product: p.partcode.product,
            quantity: p.partcode.quantity,
            quantity_unit: p.partcode.quantity_unit,
            treatments: p.partcode.treatments,
          },
          ringDetails: p.details,
          images: p.images,
          pricebreaks: p.pricebreaks,
          daysToShip: p.ship_info.ship_days,
          estimatedShipDate: p.ship_info.estimated_date,
          mto: p.ship_info.code === "Made to Order" ? true : false,
        },
      };
    case "updateQuantity":
      return {
        ...state,
        quantity: p,
      };
    case "setSpecialInstructions":
      return {
        ...state,
        ring: {
          ...state.ring,
          partcode: {
            ...state.ring.partcode,
            message: p,
          },
        },
      };
    default:
      return;
  }
}

function diamondReducer(state, action) {
  const p = action.payload;
  switch (action.type) {
    case "pageLoaded":
      return {
        ...state,
        pageLoaded: p,
      };
    case "resultsLoaded":
      return {
        ...state,
        resultsLoaded: p,
      };
    case "setTab":
      return {
        ...state,
        currentTab: p,
      };
    case "setOpenHelpText":
      return {
        ...state,
        openHelpText: p.open,
        helpTextLabel: p.label,
        helpText: p.text,
      };
    case "setOpenPanel":
      return {
        ...state,
        openPanel: p,
      };
    case "setShowCartSuccess":
      return {
        ...state,
        showCartSuccess: p,
      };
    case "setOpenCartPreview":
      return {
        ...state,
        openCartPreview: p,
      };
    case "setOpenCartPreviewSingle":
      return {
        ...state,
        openCartPreviewSingle: p,
      };
    case "setShowFancyColorFilter":
      return {
        ...state,
        showFancyColorFilter: p,
      };
    case "showAdvancedFilters":
      return {
        ...state,
        showAdvancedFilters: p,
      };
    case "setFilters":
      return {
        ...state,
        filters: p.filters,
        advancedFilters: p.advancedFilters,
        fancyColorFilters: p.fancyColorFilters,
        tableHeaders: p.tableHeaders,
        fancyColorHeaders: p.fancyColorHeaders,
        fancyColorMobileHeaders: p.fancyColorMobileHeaders,
        mobileTableHeaders: p.mobileTableHeaders,
        tabletTableHeaders: p.tabletTableHeaders,
        sidePanelTableHeaders: p.sidePanelTableHeaders,
        fcSidePanelHeaders: p.fcSidePanelHeaders,
      };
    case "clearFilters":
      return {
        ...state,
        resetFilters: !state.resetFilters,
        // Removes fancy color from white diamond search
        searchQuery: {
          fields: [
            ...p,
            {
              name: "Color",
              values: [
                { name: "D" },
                { name: "E" },
                { name: "F" },
                { name: "G" },
                { name: "H" },
                { name: "I" },
                { name: "J" },
                { name: "K" },
              ],
            },
          ],
          sort: {
            field: "HooverPrice",
            direction: "asc",
          },
          page: 1,
          size: 50,
        },
      };

    case "updateSearchQuery":
      return {
        ...state,
        searchQuery: {
          ...state.searchQuery,
          fields: p,
        },
      };
    case "updatePage":
      return {
        ...state,
        searchQuery: {
          ...state.searchQuery,
          page: p,
        },
      };
    case "updateSize":
      return {
        ...state,
        searchQuery: {
          ...state.searchQuery,
          size: p,
        },
      };
    case "updateSort":
      return {
        ...state,
        searchQuery: {
          ...state.searchQuery,
          sort: {
            field: p.sortField,
            direction: p.sortDirection,
          },
        },
      };
    case "setChecked":
      return {
        ...state,
        checked: p,
      };
    case "diamonds":
      return {
        ...state,
        diamonds: p.diamonds,
        diamondCount: p.diamondCount,
      };
    case "setRecentlyViewed":
      return {
        ...state,
        recentlyViewed: p,
      };
    case "setSelected":
      return {
        ...state,
        selected: p,
      };
    case "setCartPreview":
      return {
        ...state,
        cartPreview: p,
      };
    case "setCartPreviewSingle":
      return {
        ...state,
        cartPreviewSingle: p,
      };
    case "setSelectedDiamond":
      return {
        ...state,
        selectedDiamond: p,
      };
    default:
      return;
  }
}

function meleeReducer(state, action) {
  const p = action.payload;
  switch (action.type) {
    case "pageLoaded":
      return {
        ...state,
        pageLoaded: p,
      };
    case "setMatchedPairsFlag":
      return {
        ...state,
        matchedPairs: p,
      };
    case "resultsLoaded":
      return {
        ...state,
        resultsLoaded: p,
      };
    case "setShowCartSuccess":
      return {
        ...state,
        showCartSuccess: p,
      };
    case "setOpenCartPreview":
      return {
        ...state,
        openCartPreview: p,
      };
    case "setStandardFlag":
      return {
        ...state,
        standard: p,
      };
    case "setFilters":
      return {
        ...state,
        filters: p,
      };
    case "setCollections":
      return {
        ...state,
        collections: p,
      };
    case "setCartPreview":
      return {
        ...state,
        cartPreview: p,
      };
    case "setTableHeaders":
      return {
        ...state,
        tableHeaders: p.tableHeaders,
        mobileTableHeaders: p.mobileTableHeaders,
        tabletTableHeaders: p.tabletTableHeaders,
      };
    case "setResults":
      return {
        ...state,
        diamonds: p.diamonds,
        diamondCount: p.diamondCount,
      };
    case "setStrapiInfo":
      return {
        ...state,
        strapiBody: p.body,
        strapiImage: p.image,
      };
    case "clearFilters":
      return {
        ...state,
        resetFilters: !state.resetFilters,
        searchQuery: {
          fields: p,
          sort: {
            field: "EstimatedPrice",
            direction: "asc",
          },
          page: 1,
          size: 50,
        },
      };
    case "matchedPairsQuery":
      return {
        ...state,
        searchQuery: p,
      };
    case "updateSearchQuery":
      return {
        ...state,
        searchQuery: {
          ...state.searchQuery,
          fields: p,
        },
      };
    case "updatePage":
      return {
        ...state,
        searchQuery: {
          ...state.searchQuery,
          page: p,
        },
      };
    case "updateSize":
      return {
        ...state,
        searchQuery: {
          ...state.searchQuery,
          size: p,
        },
      };
    case "updateSort":
      return {
        ...state,
        searchQuery: {
          ...state.searchQuery,
          sort: {
            field: p.sortField,
            direction: p.sortDirection,
          },
        },
      };
    default:
      return;
  }
}

function productReducer(state, action) {
  const p = action.payload;
  switch (action.type) {
    case "resetProductPage":
      return {
        ...state,
        productInfo: {},
        productFields: [],
        productTreatments: [],
        compatibleProducts: [],
        relatedProducts: [],
        icons: [],
        productSeries: {},
        price: null,
        productDetails: [],
        priceBreaks: [],
        daysToShip: "",
        estimatedShipDate: "",
        mto: false,
      };
    case "setPageLoaded":
      return {
        ...state,
        pageLoaded: p,
      };
    case "setOpenLoginModal":
      return {
        ...state,
        openLoginModal: p,
      };
    case "setProductGroup":
      return {
        ...state,
        productGroup: p,
      };
    case "setValidationErrors":
      return {
        ...state,
        validationErrors: p,
      };
    case "setPriceLoaded":
      return {
        ...state,
        priceLoaded: p,
      };
    case "setSizeType":
      return {
        ...state,
        sizeType: p,
      };
    case "setMessage":
      return {
        ...state,
        message: p.message,
        messageType: p.type,
      };
    case "setStrapi":
      return {
        ...state,
        metalColumns: {
          ...state.metalColumns,
          left: p.left,
          middle: p.middle,
          right: p.right,
        },
        TopText: p.TopText,
        BottomText: p.BottomText,
        ChartSizeSmall: p.ChartSizeSmall,
        strapiChart: p.chart,
      };
    case "setStrapiInfoBubbles":
      return {
        ...state,
        bubbles: p.bubbles,
      };
    case "setPageAd":
      return {
        ...state,
        advertisement: p,
      };
    case "setStyle":
      return {
        ...state,
        style: p.style,
        productFields: p.fields,
        productTreatments: p.treatments,
        compatibleProducts: p.compatible_styles
          ? p.compatible_styles.styles
          : [],
        relatedProducts: p.related_items ? p.related_items : p.related,
        icons: p.icons,
        productSeries: p.series,
        images: p.images,
      };
    case "setCharmDimension":
      return {
        ...state,
        charmDimension: p,
      };
    case "setCircleDimension":
      return {
        ...state,
        circleDimension: p,
      };
    case "setProductDefaults":
      return {
        ...state,
        productInfo: {
          ...state.productInfo,
          [action.field]: p,
        },
      };
    case "setPrice":
      return {
        ...state,
        price: p.price,
        productDetails: p.details,
        images: p.images,
        priceBreaks: p.pricebreaks,
        productTreatments: p.treatments,
        daysToShip: p.ship_info.ship_days ? p.ship_info.ship_days : "",
        estimatedShipDate: p.ship_info.estimated_date
          ? p.ship_info.estimated_date
          : "",
        mto: p.ship_info.code === "Made to Order" ? true : false,
      };
    case "setProductInfo":
      return {
        ...state,
        productInfo: p,
      };
    case "setSpecialInstructions":
      return {
        ...state,
        specialInstructions: p,
      };
    case "setNewFields":
      return {
        ...state,
        productFields: p,
      };
    case "setNewTreatmentFields":
      return {
        ...state,
        productTreatments: p,
      };
    case "setClear":
      return {
        ...state,
        clear: p,
      };
    case "updateFields":
      return {
        ...state,
        productInfo: {
          ...state.productInfo,
          [action.field]: p,
        },
      };
    case "updateTreatments":
      return {
        ...state,
        productInfo: {
          ...state.productInfo,
          treatments: p,
        },
      };
    case "updatePrice":
      return {
        ...state,
        price: p.price,
        productDetails: p.details,
        images: p.images,
        priceBreaks: p.pricebreaks,
        daysToShip: p.ship_info.ship_days,
        estimatedShipDate: p.ship_info.estimated_date,
        mto: p.ship_info.code === "Made To Order" ? true : false,
      };
    case "updateQueryString":
      return {
        ...state,
        queryString: p,
      };
    default:
      return;
  }
}

function bandsReducer(state, action) {
  const p = action.payload;
  switch (action.type) {
    case "setPageLoaded":
      return {
        ...state,
        pageLoaded: p,
      };
    case "setPriceLoaded":
      return {
        ...state,
        priceLoaded: p,
      };
    case "setValidationErrors":
      return {
        ...state,
        validationErrors: p,
      };
    case "setBandInfo":
      return {
        ...state,
        style: p.style,
        images: p.images,
        productFields: p.fields,
        productInfo: {
          ...state.productInfo,
          description: p.description,
          errors: p.item.errors,
          message: p.item.message,
        },
        productTreatments: p.treatments,
        compatibleProducts: p.compatible_styles
          ? p.compatible_styles.styles
          : [],
        relatedProducts: p.related_items,
        icons: p.icons,
        productSeries: p.series,
      };
    case "setBandFieldCategories":
      return {
        ...state,
        builder: p.builder,
        complex: p.complex,
        complex1: p.complex1,
        complex2: p.complex2,
        complex3: p.complex3,
      };
    case "setBandDefaults":
      return {
        ...state,
        productInfo: {
          ...state.productInfo,
          [action.field]: p,
        },
      };
    case "setPageAd":
      return {
        ...state,
        advertisement: p,
      };
    case "setPrice":
      return {
        ...state,
        price: p.price,
        productDetails: p.details,
        images: p.images,
        priceBreaks: p.pricebreaks,
        daysToShip: p.ship_info.ship_days,
        estimatedShipDate: p.ship_info.estimated_date,
        mto: p.ship_info.code === "Made To Order" ? true : false,
      };
    case "updateFields":
      return {
        ...state,
        productInfo: {
          ...state.productInfo,
          [action.field]: p,
        },
      };
    case "setSpecialInstructions":
      return {
        ...state,
        specialInstructions: p,
      };
    case "setClear":
      return {
        ...state,
        clear: p,
      };
    case "updatePrice":
      return {
        ...state,
        price: p.price,
        productDetails: p.details,
        images: p.images,
        priceBreaks: p.pricebreaks,
        daysToShip: p.ship_info.ship_days,
        estimatedShipDate: p.ship_info.estimated_date,
        mto: p.ship_info.code === "Made To Order" ? true : false,
      };
    default:
      return;
  }
}

export {
  cartReducer,
  createAccountReducer,
  formReducer,
  landingReducer,
  barReducer,
  diamondReducer,
  meleeReducer,
  productReducer,
  bandsReducer,
};
