// Third-party libraries
import React, { useContext, useEffect, useState } from "react";
import {
  Select,
  Stack,
  Typography,
  MenuItem,
  FormControl,
  FormHelperText,
  TextField,
  Grid,
} from "@mui/material";

// Local modules
import { ProductsContext } from "../../../../utils/stateHandlers/contexts";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

export default function CaratSize(props) {
  const { productState, productDispatch } = useContext(ProductsContext);
  const { clear, validationErrors, priceLoaded } = productState;
  const [localInput, setLocalInput] = useState(props.field.default);
  const [localUnit, setLocalUnit] = useState(null);
  const [localCaratSize, setLocalCaratSize] = useState(null);
  const field = props.field;

  const handleInputChange = (e) => {
    setLocalInput(e.target.value);
  };

  useEffect(() => {
    if (localInput !== props.field.default) {
      const timeoutId = setTimeout(
        () =>
          productDispatch({
            type: "updateFields",
            field: field.name,
            payload: localInput,
          }),
        1000
      );
      return () => clearTimeout(timeoutId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localInput]);

  function selectCaratSize(carat_size) {
    if (carat_size === "None") {
      productDispatch({
        type: "setValidationErrors",
        payload: [...validationErrors, "carat_size"],
      });
    } else {
      const remove = validationErrors.filter(function (error) {
        return error !== "carat_size";
      });
      productDispatch({ type: "setValidationErrors", payload: remove });
    }
    productDispatch({ type: "setClear", payload: false });
    setLocalCaratSize(carat_size);
    productDispatch({
      type: "updateFields",
      field: "carat_size",
      payload: carat_size,
    });
  }

  useEffect(() => {
    if (clear && field.template === "dropdown") {
      productDispatch({ type: "setClear", payload: false });
      if (!field.default) {
        productDispatch({
          type: "setValidationErrors",
          payload: [...validationErrors, "carat_size"],
        });
        setLocalCaratSize("None");
      }
      setLocalCaratSize(field.default ? field.default : "None");
    } else if (clear && !field.template) {
      const remove = validationErrors.filter((error) => error !== "carat_size");
      productDispatch({ type: "setValidationErrors", payload: remove });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clear]);

  useEffect(() => {
    // if (!clear && field.template === "dropdown") {
    //   setLocalCaratSize(field.default ? field.default : "None");
    // } else
    if (!clear && !field.template) {
      productDispatch({
        type: "setValidationErrors",
        payload: [...validationErrors, "carat_size"],
      });
      setLocalCaratSize("None");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [field]);

  return (
    <>
      {field.template === "dropdown" || !field.template ? (
        <Stack>
          <Typography sx={{ fontWeight: "700", paddingBottom: ".3rem" }}>
            {field.label}
          </Typography>
          <FormControl error={localCaratSize === "None" ? true : false}>
            <Select
              value={localCaratSize === null ? field.default : localCaratSize}
              size="small"
              sx={{ width: "100%" }}
              disabled={
                !priceLoaded || (field.values && field.values.length === 1)
              }
              onChange={(e) => {
                selectCaratSize(e.target.value);
              }}
              MenuProps={{
                PaperProps: {
                  sx: {
                    border: "1px solid #939598",
                    borderRadius: "0",
                    maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                  },
                },
              }}
            >
              <MenuItem value="None">
                <em>Select...</em>
              </MenuItem>
              {field.values &&
                field.values.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
            </Select>
            <FormHelperText>
              {localCaratSize === "None" ? "Please Make A Selection" : ""}
            </FormHelperText>
          </FormControl>
        </Stack>
      ) : (
        <Grid container key={field.name}>
          <Grid item xs={8}>
            <Stack>
              <Typography
                sx={{
                  fontWeight: "700",
                  paddingBottom: ".3rem",
                }}
              >
                Height
              </Typography>
              <TextField
                color="primary"
                disabled={!priceLoaded}
                onChange={(e) => {
                  handleInputChange(e);
                }}
                type="number"
                size="small"
                sx={{
                  width: "100%",
                  margin: "unset",
                }}
                value={localInput}
              />
            </Stack>
          </Grid>
          <Grid item xs={1} />
          <Grid item xs={3}>
            <Stack>
              <Typography
                sx={{
                  fontWeight: "700",
                  paddingBottom: ".3rem",
                }}
              >
                Units
              </Typography>
              <Select
                value={localUnit === null ? field.default_unit : localUnit}
                sx={{ width: "100%" }}
                size="small"
                disabled={
                  !priceLoaded || (field.units && field.units.length === 1)
                }
                onChange={(e) => {
                  productDispatch({
                    type: "updateFields",
                    field: "carat_size_unit",
                    payload: e.target.value,
                  });
                  setLocalUnit(e.target.value);
                }}
                MenuProps={{
                  PaperProps: {
                    sx: {
                      border: "1px solid #939598",
                      borderRadius: "0",
                      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                    },
                  },
                }}
              >
                <MenuItem value="None">
                  <em>Select...</em>
                </MenuItem>
                {field.units &&
                  field.units.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
              </Select>
            </Stack>
          </Grid>
        </Grid>
      )}
    </>
  );
}
