// Third-party libraries
import React, { useState, useContext } from "react";
import { Button, Grid } from "@mui/material";
import HelpIcon from "@mui/icons-material/Help";
import parse from "html-react-parser";

// Local modules
import { DiamondSearchContext } from "../../utils/stateHandlers/contexts";
import Modal from "./controls/Modal";

function MinedOrLab(props) {
  const [showHelp, setShowHelp] = useState(false);
  const { diamondState } = useContext(DiamondSearchContext);
  const { tableHeaders, filters } = diamondState;
  const helpText = tableHeaders.find(function (header) {
    return header.name === "MinedOrLab";
  });

  return (
    <Grid container>
      <Grid item className="mined-filter" sx={{ width: "100%" }}>
        <Button
          disableElevation
          variant="contained"
          color={props.valueForColor === "M" ? "primary" : "secondary"}
          onClick={() => {
            props.clearFilters({
              name: "MinedOrLab",
              values: [{ name: "M" }],
            });
          }}
        >
          Mined
        </Button>
        <Button
          disableElevation
          variant="contained"
          color={props.valueForColor === "L" ? "success" : "secondary"}
          onClick={() => {
            props.clearFilters({
              name: "MinedOrLab",
              values: [{ name: "L" }],
            });
          }}
        >
          Lab-Grown
        </Button>
        <HelpIcon
          sx={{ marginLeft: "10px" }}
          className="help-icon question-mark"
          onClick={() => setShowHelp(true)}
        />
      </Grid>
      <Modal
        title={"Mined Or Lab-Grown"}
        onClose={() => setShowHelp(false)}
        show={showHelp}
      >
        {filters.length > 0 ? parse(helpText.help_text) : ""}
      </Modal>
    </Grid>
  );
}

export default MinedOrLab;
