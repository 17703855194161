export const millProducts = [
  "GR",
  "SD",
  "SR",
  "SDC",
  "SDB",
  "SDW",
  "FLX",
  "SRB",
  "ALL",
  "FS",
  "BUL",
  "DSK",
  "ML",
  "AE",
  "RW",
  "SRW",
  "LW",
  "SWF",
  "SWH",
  "SHR",
  "LDW",
  "SWS",
  "SWO",
  "SWE",
  "STW",
  "DHR",
  "SWT",
  "SWP",
  "CHW",
  "BZS",
  "BZW",
  "BSC",
  "BSR",
  "SWD",
  "SWR",
  "WSQ",
  "CZY",
  "TBL",
  "TBM",
  "TBX",
  "TSL",
  "TSM",
  "TSH",
  "TSX",
  "TBH",
];

export function isBand(styleCode) {
  //if the first letter is W or A and not ALL, WAS, WRB, alphabets, or AE, then it is a band
  //Mokume Gane bands are regular product details not bands details
  if (styleCode.startsWith("W")) {
    return styleCode === "WAS"
      ? false
      : styleCode === "WRB"
      ? false
      : styleCode === "WSQ"
      ? false
      : styleCode === "WBMW1"
      ? false
      : styleCode === "WBMW12"
      ? false
      : styleCode === "WBMW15"
      ? false
      : styleCode === "WBMW16"
      ? false
      : styleCode === "WBMW17"
      ? false
      : styleCode === "WBMW2"
      ? false
      : styleCode === "WBMS1"
      ? false
      : styleCode === "WB3RR08"
      ? false
      : styleCode === "WBSR5/GHSI"
      ? false
      : styleCode === "WB3SS11"
      ? false
      : styleCode === "WB5S90/HI1"
      ? false
      : styleCode === "WBRR56"
      ? false
      : styleCode === "WBRR49/GHSI"
      ? false
      : styleCode === "WBRR38"
      ? false
      : styleCode === "WBRR38/HI1"
      ? false
      : styleCode === "WBSR8/GHSI"
      ? false
      : styleCode === "WBR171/HI1"
      ? false
      : styleCode === "WBR73A"
      ? false
      : styleCode === "WBR273A"
      ? false
      : styleCode === "WB5S90"
      ? false
      : true;
  } else if (styleCode.startsWith("A")) {
    return styleCode === "ALL"
      ? false
      : styleCode === "AE"
      ? false
      : styleCode === "ALB2"
      ? false
      : styleCode === "ALI1"
      ? false
      : styleCode === "ALB1"
      ? false
      : true;
  } else {
    return false;
  }
}

export default Object.freeze({
  millProducts,
  isBand,
});
