import React from "react";

const OldEuro = (props) => {
  const viewBoxWidth = 87;
  const viewBoxHeight = 86;

  const lengthXPrcnt = 0.22;
  const lengthYPrcnt = 0.63;

  const widthXPrcnt = 0.56;
  const widthYPrcnt = 0.23;

  const lengthX = lengthXPrcnt * viewBoxWidth;
  const lengthY = lengthYPrcnt * viewBoxHeight;

  const widthX = widthXPrcnt * viewBoxWidth;
  const widthY = widthYPrcnt * viewBoxHeight;

  const lineColor = "#4a4a4a";

  const st0Style = {
    display: "none",
  };

  const st1Style = {
    fill: props.color,
    stroke: props.color,
    strokeWidth: 2,
  };

  const st2Style = {
    fill: "none",
    stroke: props.color,
    strokeWidth: 2,
  };

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox={"-1.696 -0.4 " + viewBoxWidth + " " + viewBoxHeight}
      width={props.width}
      height={props.height}
      preserveAspectRatio={props.preserveAspectRatio}
    >
      <g id="Layer_3" style={st0Style}></g>
      <g id="Layer_4" style={st0Style}>
        <circle style={st1Style} cx="42.8" cy="43.2" r="40.7" />
      </g>
      <g id="Layer_2">
        <circle style={st2Style} cx="43" cy="42.8" r="41.5" />
        <polygon
          style={st2Style}
          points="43.2,17.9 48.6,28.8 60.3,26.1 56.6,37 67,43 56.5,48.3 59.4,60.1 48.1,56.3 41.9,66.8 35.7,56.1
    24.6,59.7 28.8,48.8 18.6,42.5 28.6,36.3 25.2,25 37.2,28.8"
        />
        <line style={st2Style} x1="28.5" y1="4.3" x2="43.2" y2="18.3" />
        <line style={st2Style} x1="60.1" y1="5.4" x2="43.7" y2="18.5" />
        <line style={st2Style} x1="60.5" y1="25.7" x2="59.9" y2="5.4" />
        <line style={st2Style} x1="73.2" y1="14.5" x2="60.3" y2="26.3" />
        <line style={st2Style} x1="81.7" y1="27.9" x2="59.9" y2="26.3" />
        <line style={st2Style} x1="67" y1="42.6" x2="81.2" y2="28.1" />
        <line style={st2Style} x1="84.5" y1="43" x2="66.8" y2="43" />
        <line style={st2Style} x1="81" y1="58.6" x2="66.6" y2="42.8" />
        <line style={st2Style} x1="59.5" y1="60.5" x2="81.5" y2="58.3" />
        <line style={st2Style} x1="71.9" y1="71.9" x2="59.9" y2="60.3" />
        <line style={st2Style} x1="58.6" y1="81" x2="59.2" y2="58.6" />
        <line style={st2Style} x1="41.9" y1="66.8" x2="59" y2="80.5" />
        <line style={st2Style} x1="41.9" y1="84.3" x2="42.3" y2="66.5" />
        <line style={st2Style} x1="25.9" y1="80.3" x2="42.1" y2="66.5" />
        <line style={st2Style} x1="24.8" y1="59.9" x2="26.3" y2="80.3" />
        <line style={st2Style} x1="12.5" y1="70.6" x2="25" y2="59.7" />
        <line style={st2Style} x1="4.1" y1="57.4" x2="24.8" y2="59.4" />
        <line style={st2Style} x1="18.6" y1="42.5" x2="4.6" y2="57.2" />
        <line style={st2Style} x1="1.7" y1="42.3" x2="19.2" y2="42.5" />
        <line style={st2Style} x1="5" y1="25.9" x2="18.8" y2="42.5" />
        <line style={st2Style} x1="25.2" y1="25.2" x2="5.2" y2="25.9" />
        <line style={st2Style} x1="13.5" y1="13.5" x2="25.7" y2="25.2" />
        <line style={st2Style} x1="28.8" y1="4.1" x2="25.5" y2="25.2" />
        <line style={st2Style} x1="43.5" y1="1.2" x2="43.7" y2="18.5" />
        <line style={st2Style} x1="37.4" y1="29" x2="49" y2="29" />
        <line style={st2Style} x1="35.5" y1="55.9" x2="48.8" y2="56.1" />
        <line style={st2Style} x1="56.5" y1="48.5" x2="48.6" y2="55.9" />
        <line style={st2Style} x1="56.5" y1="37" x2="56.6" y2="48.3" />
        <line style={st2Style} x1="48.6" y1="29.2" x2="56.6" y2="37.2" />
        <line style={st2Style} x1="37.2" y1="29" x2="28.1" y2="37" />
        <line style={st2Style} x1="28.8" y1="48.5" x2="29" y2="35.9" />
        <line style={st2Style} x1="35.7" y1="56.1" x2="28.3" y2="48.1" />
      </g>
      {props.show_diagram && (
        <>
          <line
            x1="30"
            y1="0"
            x2="30"
            y2="85"
            stroke={lineColor}
            strokeWidth=".5"
          ></line>
          <line
            x1="28"
            y1="0"
            x2="32"
            y2="0"
            stroke={lineColor}
            strokeWidth=".5"
          ></line>
          <line
            x1="28"
            y1="85"
            x2="32"
            y2="85"
            stroke={lineColor}
            strokeWidth=".5"
          ></line>
          <rect
            x={lengthX}
            y={lengthY}
            width="20"
            height="11"
            rx="0.8"
            fill="white"
          ></rect>
          <text
            x={lengthX + 2}
            y={lengthY + 4}
            fontSize=".4rem"
            stroke={lineColor}
            strokeWidth="0.01"
          >
            Length:
          </text>
          <text
            x={lengthX + 1}
            y={lengthY + 10}
            fontSize=".4rem"
            stroke={lineColor}
            strokeWidth="0.01"
          >
            {props.diamond.Measurement1}
          </text>

          <line
            x1="2"
            y1="25"
            x2="90"
            y2="25"
            stroke={lineColor}
            strokeWidth=".5"
          ></line>
          <line
            x1="2"
            y1="23"
            x2="2"
            y2="27"
            stroke={lineColor}
            strokeWidth=".5"
          ></line>
          <line
            x1="90"
            y1="23"
            x2="90"
            y2="27"
            stroke={lineColor}
            strokeWidth=".5"
          ></line>

          <rect
            x={widthX}
            y={widthY}
            width="19"
            height="11"
            rx="0.8"
            fill="white"
          ></rect>
          <text
            x={widthX + 2}
            y={widthY + 4}
            fontSize=".4rem"
            stroke={lineColor}
            strokeWidth="0.01"
          >
            Width:
          </text>
          <text
            x={widthX + 1}
            y={widthY + 10}
            fontSize=".4rem"
            stroke={lineColor}
            strokeWidth="0.01"
          >
            {props.diamond.Measurement2}
          </text>
        </>
      )}
    </svg>
  );
};

export default OldEuro;
