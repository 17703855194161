import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { Link as DomLink } from "react-router-dom";
import { constants } from "../../../../utils";
import { Button, Typography, Stack } from "@mui/material";
const frontImageUrl = constants.CMS_FRONT_IMAGE_URL;

const ButtonTextBlock = (block) => (
  <Stack
    spacing={3}
    sx={
      block.background === "Blue"
        ? {
            backgroundColor: "#cfdde9",
            width: "100%",
            paddingBottom: "2rem",
          }
        : {
            backgroundColor: "inherit",
            width: "100%",
            paddingBottom: "2rem",
          }
    }
  >
    {block.optionalbannerimage.data !== null && (
      <img
        alt={block.optionalbannerimage.data.attributes.caption}
        src={`${frontImageUrl}${block.optionalbannerimage.data.attributes.url}`}
      />
    )}
    <Typography
      variant="articleTitle"
      align="center"
      sx={{ paddingTop: "2rem" }}
    >
      {block.title}
    </Typography>
    <Typography
      variant="staticText"
      align="center"
      sx={{ paddingLeft: "6rem", paddingRight: "6rem" }}
    >
      <ReactMarkdown remarkPlugins={[remarkGfm]}>{block.body}</ReactMarkdown>
    </Typography>
    {block.pdf.data !== null ? (
      <a
        href={`${frontImageUrl}${block.pdf.data.attributes.url}`}
        download="test.pdf"
        target="_blank"
        className="center"
        rel="noreferrer"
      >
        <Button variant="contained">{block.button.text}</Button>
      </a>
    ) : (
      <DomLink
        to={{
          pathname: `${block.button.to}`,
        }}
        className="center"
      >
        <Button variant="contained">{block.button.text}</Button>
      </DomLink>
    )}
  </Stack>
);

export default ButtonTextBlock;
