import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import App from "./App";
import { ErrorBoundary } from "react-error-boundary";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import "./assets/styles/index.scss";

const rootElement = document.getElementById("root");

const goBack = () => {
  window.history.back();
};

const errorScreen = () => {
  return (
    <React.Fragment>
      <p>Something went wrong.</p>
      <p>
        Please use your browser's back button to return to the previous screen.
      </p>
      <Button onClick={goBack} className="button button-primary">
        Or Click Here
      </Button>
    </React.Fragment>
  );
};

const AppFallbackComponent = ({ componentStack, error }) => {
  return (
    <React.Fragment>
      <Container maxWidth="lg">
        <Box sx={{ height: "100vh", textAlign: "center" }}>{errorScreen}</Box>
      </Container>
    </React.Fragment>
  );
};

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <ErrorBoundary FallbackComponent={AppFallbackComponent}>
        <App />
      </ErrorBoundary>
    </Router>
  </React.StrictMode>,
  rootElement
);
