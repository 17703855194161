// Third-party libraries
import React, { useContext } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  Skeleton,
} from "@mui/material";

// Local modules
import {
  BandsContext,
  AppContext,
} from "../../../../utils/stateHandlers/contexts";
import CatalogPageButton from "../../shared/details/CatalogPageButton";

export default function BandsDetailsTable(props) {
  const { authToken } = useContext(AppContext);
  const { bandsState } = useContext(BandsContext);
  const { priceLoaded, productDetails } = bandsState;
  return (
    <>
      <Typography variant="h2" sx={{ width: "100%", marginTop: "2rem" }}>
        <div className="details-header">
          <span>Details</span>
          <CatalogPageButton productStyle={bandsState.style} />
        </div>
      </Typography>
      {authToken ? (
        <Table size="small" sx={{ width: "100%" }}>
          <TableBody>
            {priceLoaded ? (
              <>
                {productDetails &&
                  productDetails.map((data, index) => (
                    <TableRow key={index}>
                      <TableCell>
                        <Typography>{data.label}</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography>{data.value}</Typography>
                      </TableCell>
                    </TableRow>
                  ))}
              </>
            ) : (
              <>
                <TableRow>
                  <TableCell>
                    <Skeleton
                      variant="text"
                      width="10rem"
                      className="float-right"
                    />
                  </TableCell>
                  <TableCell>
                    <Skeleton
                      variant="text"
                      width="10rem"
                      className="float-right"
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <Skeleton
                      variant="text"
                      width="10rem"
                      className="float-right"
                    />
                  </TableCell>
                  <TableCell>
                    <Skeleton
                      variant="text"
                      width="10rem"
                      className="float-right"
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <Skeleton
                      variant="text"
                      width="10rem"
                      className="float-right"
                    />
                  </TableCell>
                  <TableCell>
                    <Skeleton
                      variant="text"
                      width="10rem"
                      className="float-right"
                    />
                  </TableCell>
                </TableRow>
              </>
            )}
          </TableBody>
        </Table>
      ) : (
        <Typography sx={{ margin: "1rem" }}>
          To see the details of a specific product, click the "Login For
          Pricing" button at the top right of the screen.
        </Typography>
      )}
    </>
  );
}
