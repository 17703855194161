// Third-party libraries
import React, { useContext, useState, useEffect } from "react";
import {
  Select,
  Stack,
  Typography,
  MenuItem,
  TextField,
  Grid,
} from "@mui/material";

// Local modules
import { ProductsContext } from "../../../../utils/stateHandlers/contexts";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

export default function Width(props) {
  const { productState, productDispatch } = useContext(ProductsContext);
  const { priceLoaded } = productState;
  const [localInput, setLocalInput] = useState(props.field.default);
  const [localUnit, setLocalUnit] = useState(null);
  const field = props.field;

  const handleInputChange = (e) => {
    setLocalInput(e.target.value);
  };

  useEffect(() => {
    if (localInput !== props.field.default) {
      const timeoutId = setTimeout(
        () =>
          productDispatch({
            type: "updateFields",
            field: "width",
            payload: localInput,
          }),
        1000
      );
      return () => clearTimeout(timeoutId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localInput]);

  return (
    <Grid container key={field.name}>
      <Grid item xs={8}>
        <Stack>
          <Typography
            sx={{
              fontWeight: "700",
              paddingBottom: ".3rem",
            }}
          >
            Width
          </Typography>
          <TextField
            color="primary"
            disabled={!priceLoaded}
            onChange={(e) => {
              handleInputChange(e);
            }}
            type="number"
            size="small"
            sx={{
              width: "100%",
              margin: "unset",
            }}
            value={localInput}
          />
        </Stack>
      </Grid>
      <Grid item xs={1} />
      <Grid item xs={3}>
        <Stack>
          <Typography
            sx={{
              fontWeight: "700",
              paddingBottom: ".3rem",
            }}
          >
            Units
          </Typography>
          <Select
            value={localUnit === null ? field.default_unit : localUnit}
            sx={{ width: "100%" }}
            size="small"
            disabled={!priceLoaded || field.units.length === 1}
            onChange={(e) => {
              productDispatch({
                type: "updateFields",
                field: "width_unit",
                payload: e.target.value,
              });
              setLocalUnit(e.target.value);
            }}
            MenuProps={{
              PaperProps: {
                sx: {
                  border: "1px solid #939598",
                  borderRadius: "0",
                  maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                },
              },
            }}
          >
            <MenuItem value="None">
              <em>Select...</em>
            </MenuItem>
            {field.units &&
              field.units.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
          </Select>
        </Stack>
      </Grid>
    </Grid>
  );
}
