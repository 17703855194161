// Third-party libraries
import React, { useContext } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  Skeleton,
} from "@mui/material";

// Local modules
import { BuildARingContext } from "../../../../utils/stateHandlers/contexts";

export default function BarDetailTable(props) {
  const { barState } = useContext(BuildARingContext);
  const { priceLoaded, ring } = barState;
  return (
    <>
      <Typography variant="h2" sx={{ marginTop: "2rem" }}>
        Details
      </Typography>
      <Table size="small">
        <TableBody>
          {priceLoaded ? (
            <>
              {ring &&
                ring.ringDetails.map((data) => (
                  <TableRow key={data.label}>
                    {Object.values(data).map((detailData) => (
                      <TableCell key={detailData}>
                        <Typography>{detailData}</Typography>
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
            </>
          ) : (
            <>
              <TableRow>
                <TableCell>
                  <Skeleton
                    variant="text"
                    width="10rem"
                    className="float-right"
                  />
                </TableCell>
                <TableCell>
                  <Skeleton
                    variant="text"
                    width="10rem"
                    className="float-right"
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Skeleton
                    variant="text"
                    width="10rem"
                    className="float-right"
                  />
                </TableCell>
                <TableCell>
                  <Skeleton
                    variant="text"
                    width="10rem"
                    className="float-right"
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Skeleton
                    variant="text"
                    width="10rem"
                    className="float-right"
                  />
                </TableCell>
                <TableCell>
                  <Skeleton
                    variant="text"
                    width="10rem"
                    className="float-right"
                  />
                </TableCell>
              </TableRow>
            </>
          )}
        </TableBody>
      </Table>
    </>
  );
}
