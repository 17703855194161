// Third-party libraries
import React, { useContext } from "react";
import { Link as DomLink } from "react-router-dom";
import { Stack, Paper, Typography, Grid } from "@mui/material";

// Local modules
import { ProductsContext } from "../../../utils/stateHandlers/contexts";

const shanks = {
  title1: "Shank Shapes",
  link1: "/technicalarticles/39",
  title2: "Adjusto Shanks",
  link2: "/technicalarticles/38",
  title3: "Fit for a Queen - Installing a princess head on a platinum shank",
  link3: "/technicalarticles/9",
};
const settings = {
  title1: "A Need for Speed-Hoover & Strong TruSeat® Settings",
  link1: "/technicalarticles/83",
  title2: "Under Pressure",
  link2: "/technicalarticles/1",
  title3: "Stress Cracking and Prong Failure",
  link3: "/technicalarticles/",
};
const earrings = {
  title1: "How To Bend Tubing For Hoop Earrings",
  link1: "/technicalarticles/",
  title2: "Stud Earrings - Setting Yourself Apart",
  link2: "/technicalarticles/",
  title3: "Garnet Bracelet & Cluster Earrings",
  link3: "/technicalarticles/",
};
const pendants = {
  title1: "Creating a Bezel for a Round Brilliant Diamond",
  link1: "/technicalarticles/",
  title2: "Setting Up For Savings",
  link2: "/technicalarticles/",
  title3: "Important Information About Working with Gold",
  link3: "/technicalarticles/",
};
const chain = {
  title1: "Under Pressure",
  link1: "/technicalarticles/1",
  title2: "Easing Into Green",
  link2: "/technicalarticles/",
  title3: "Making a Difference From Mine to Market",
  link3: "/technicalarticles/",
};
const washers = {
  title1: "Easing Into Green",
  link1: "/technicalarticles/",
  title2: "The Real Price of Gold",
  link2: "/technicalarticles/",
  title3: "Consumer Questions Answered - Recycled Precious Metals and Gems",
  link3: "/technicalarticles/",
};
const ringBlanks = {
  title1: "Top Ten Myths of Palladium",
  link1: "/technicalarticles/",
  title2: "Targeting Defects",
  link2: "/technicalarticles/",
  title3: "Gem Inlay Jewelry Piece Process",
  link3: "/technicalarticles/",
};
const bands = {
  title1: "Hoover Azure® Anniversary Bands",
  link1: "/technicalarticles/",
  title2: "Setting Hoover & Strong Anniversary Bands",
  link2: "/technicalarticles/",
  title3: "Poor Alternatives",
  link3: "/technicalarticles/",
};
const bridal = {
  title1: "Using TruSeat® System",
  link1: "/technicalarticles/",
  title2: "Ten Ways to Sell to a Green Consumer",
  link2: "/technicalarticles/",
  title3: "Palladium. The Other White Metal",
  link3: "/technicalarticles/",
};
const finishedJewelry = {
  title1: "Restoring a Hollow Bangle",
  link1: "/technicalarticles/",
  title2: "Sterling Silver Scrap Bracelet",
  link2: "/technicalarticles/",
  title3: "Model Making with Precision and Passion",
  link3: "/technicalarticles/",
};
const noCategory = {
  title1: "Important Information About Alloys",
  link1: "/technicalarticles/36",
  title2: "Advantage of Mill Products in Custom Fabrication",
  link2: "/technicalarticles/24",
  title3: "Solder Safety Precautions",
  link3: "/technicalarticles/33",
};

export default function TechnicalArticles(props) {
  const { productState } = useContext(ProductsContext);
  const { productGroup } = productState;

  const ArticlesList = (list) => (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Paper elevation={0} className="first-article">
          <DomLink
            to={{
              pathname: list.link1,
            }}
            target="_blank"
            className="article-title-text"
          >
            {list.title1}
          </DomLink>
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Paper elevation={0} className="second-article">
          <DomLink
            to={{
              pathname: list.link2,
            }}
            target="_blank"
            className="article-title-text"
          >
            {list.title2}
          </DomLink>
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Paper elevation={0} className="third-article">
          <DomLink
            to={{
              pathname: list.link3,
            }}
            target="_blank"
            className="article-title-text"
          >
            {list.title3}
          </DomLink>
        </Paper>
      </Grid>
    </Grid>
  );

  return (
    <>
      <Stack>
        <Typography variant="h2">
          Technical Articles
          <DomLink
            to={{
              pathname: "/technicalarticles",
            }}
            target="_blank"
            className="header-link"
          >
            Search All Technical Articles
          </DomLink>
        </Typography>
        {productGroup === "All Shanks"
          ? ArticlesList(shanks)
          : productGroup === "All Settings"
          ? ArticlesList(settings)
          : productGroup === "Earrings"
          ? ArticlesList(earrings)
          : productGroup === "Pendants"
          ? ArticlesList(pendants)
          : productGroup === "Chain"
          ? ArticlesList(chain)
          : productGroup === "Washers"
          ? ArticlesList(washers)
          : productGroup === "Ring Blanks"
          ? ArticlesList(ringBlanks)
          : productGroup === "Bands"
          ? ArticlesList(bands)
          : productGroup === "Bridal"
          ? ArticlesList(bridal)
          : productGroup === "Finished Jewelry"
          ? ArticlesList(finishedJewelry)
          : ArticlesList(noCategory)}
      </Stack>
    </>
  );
}
