// Third-party libraries
import React, { useContext, useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import InfoIcon from "@mui/icons-material/InfoOutlined";
import {
  Select,
  Stack,
  Typography,
  MenuItem,
  FormControl,
  FormHelperText,
  Grid,
  TextField,
  Radio,
  RadioGroup,
  FormControlLabel,
} from "@mui/material";
// Local modules
import { ProductsContext } from "../../../../utils/stateHandlers/contexts";
import Modal from "../../../diamonds/controls/Modal";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

export default function Dimension(props) {
  const { productState, productDispatch } = useContext(ProductsContext);
  const {
    clear,
    validationErrors,
    style,
    sizeType,
    priceLoaded,
    bubbles,
    productInfo,
  } = productState;
  const [localDimension, setLocalDimension] = useState("");
  const [showHelp, setShowHelp] = useState(false);
  const [helpTitle, setHelpTitle] = useState("");
  const [helpText, setHelpText] = useState();
  const field = props.field;
  const standard = (field.values || []).filter((option) => option.ga !== "");
  const standardOptions = sizeOptionsFormatting(standard);
  const mto = (field.values || []).filter((option) => option.ga === "");
  const mtoOptions = sizeOptionsFormatting(mto);
  function sizeOptionsFormatting(options) {
    const sortedOptions = options.slice().sort((a, b) => b.in - a.in);
    const sizeOptions = sortedOptions.map((option) => {
      const newLabel = `${option.in}in / ${`${option.ga}ga`} / ${option.mm}mm`;
      const mtoNewLabel = `${option.in}in / ${option.mm}mm`;
      return {
        label: sizeType === "standard" ? newLabel : mtoNewLabel,
        value: option.value,
      };
    });
    return sizeOptions;
  }

  function selectDimension(dimension) {
    if (dimension === "None") {
      productDispatch({
        type: "setValidationErrors",
        payload: [...validationErrors, "dimension"],
      });
    } else {
      const remove = validationErrors.filter((error) => error !== "dimension");
      productDispatch({ type: "setValidationErrors", payload: remove });
    }
    productDispatch({ type: "setClear", payload: false });
    setLocalDimension(dimension);
    if (style === "CHM" && field.name === "dimension") {
      productDispatch({
        type: "setCharmDimension",
        payload: dimension,
      });
    } else if (
      (style === "CIR" || style === "CIRCLES") &&
      field.name === "dimension"
    ) {
      productDispatch({
        type: "setCircleDimension",
        payload: dimension,
      });
    }
    productDispatch({
      type: "updateFields",
      field: "dimension",
      payload: dimension,
    });
  }

  useEffect(() => {
    if (clear && field.template === "dropdown") {
      productDispatch({ type: "setClear", payload: false });
      if (!field.default || props.isMill) {
        productDispatch({
          type: "setValidationErrors",
          payload: [...validationErrors, "dimension"],
        });
        setLocalDimension("None");
      } else {
        setLocalDimension(field.default ? field.default : "None");
      }
    }
    if (productInfo.product === "SD") {
      productDispatch({
        type: "updateFields",
        field: "dimension",
        payload: field.default,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productInfo.material, clear]);

  return (
    <>
      {field.template === "dropdown" ? (
        <Stack>
          <Typography sx={{ fontWeight: "700", paddingBottom: ".3rem" }}>
            {field.label}
            {style === "RW" &&
              bubbles &&
              bubbles.map((bubble, index) => (
                <React.Fragment key={index}>
                  {bubble.bubblefield === "dimension" && (
                    <InfoIcon
                      sx={{ marginLeft: "0.5rem" }}
                      className="pointer"
                      color="primary"
                      onClick={() => {
                        setShowHelp(true);
                        setHelpTitle(bubble.bubbletitle);
                        setHelpText(bubble.bubbletext);
                      }}
                    />
                  )}
                </React.Fragment>
              ))}
          </Typography>
          {style === "RW" ? (
            <>
              <FormControl>
                <RadioGroup
                  row
                  value={sizeType}
                  disabled={!priceLoaded}
                  onChange={(e) => {
                    setLocalDimension("None");
                    productDispatch({
                      type: "updateFields",
                      field: "dimension",
                      payload: "None",
                    });
                    productDispatch({
                      type: "setValidationErrors",
                      payload: [...validationErrors, "dimension"],
                    });
                    productDispatch({
                      type: "setSizeType",
                      payload: e.target.value,
                    });
                  }}
                >
                  <FormControlLabel
                    value="standard"
                    control={<Radio />}
                    label={<Typography>Standard</Typography>}
                  />
                  <FormControlLabel
                    value="mto"
                    control={<Radio />}
                    label={<Typography>Made To Order (MTO)</Typography>}
                  />
                </RadioGroup>
              </FormControl>
              <FormControl error={localDimension === "None" ? true : false}>
                <Select
                  value={localDimension === null ? ".032IN" : localDimension}
                  disabled={!priceLoaded}
                  size="small"
                  sx={{ width: "100%" }}
                  onChange={(e) => {
                    selectDimension(e.target.value);
                  }}
                  MenuProps={{
                    PaperProps: {
                      sx: {
                        border: "1px solid #939598",
                        borderRadius: "0",
                        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                      },
                    },
                  }}
                >
                  <MenuItem value="None">
                    <em>Select...</em>
                  </MenuItem>
                  {sizeType === "standard"
                    ? standardOptions.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))
                    : mtoOptions.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                </Select>
                <FormHelperText>
                  {localDimension === "None" ? "Please Make A Selection" : ""}
                </FormHelperText>
              </FormControl>
            </>
          ) : (
            <FormControl error={localDimension === "None" ? true : false}>
              <Select
                value={localDimension || field.default || undefined}
                size="small"
                sx={{ width: "100%" }}
                disabled={!priceLoaded}
                onChange={(e) => {
                  selectDimension(e.target.value);
                }}
                MenuProps={{
                  PaperProps: {
                    sx: {
                      border: "1px solid #939598",
                      borderRadius: "0",
                      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                    },
                  },
                }}
              >
                <MenuItem value="None">
                  <em>Select...</em>
                </MenuItem>
                {field.values &&
                  field.values.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
              </Select>
              <FormHelperText>
                {localDimension === "None" ? "Please Make A Selection" : ""}
              </FormHelperText>
            </FormControl>
          )}
        </Stack>
      ) : (
        <Grid container key={field.name}>
          <Grid item xs={8}>
            <Stack>
              <Typography
                sx={{
                  fontWeight: "700",
                  paddingBottom: ".3rem",
                }}
              >
                Width
              </Typography>
              <TextField
                color="primary"
                disabled={!priceLoaded}
                onChange={(e) => {
                  productDispatch({
                    type: "updateFields",
                    field: "dimension",
                    payload: e.target.value,
                  });
                }}
                type="number"
                size="small"
                sx={{
                  width: "100%",
                  margin: "unset",
                }}
                defaultValue={field.default}
              />
            </Stack>
          </Grid>
          <Grid item xs={1} />
          <Grid item xs={3}>
            <Stack>
              <Typography
                sx={{
                  fontWeight: "700",
                  paddingBottom: ".3rem",
                }}
              >
                Units
              </Typography>
              <Select
                defaultValue={field.default_unit ? field.default_unit : "None"}
                sx={{ width: "100%" }}
                disabled={!priceLoaded}
                size="small"
                onChange={(e) => {
                  productDispatch({
                    type: "updateFields",
                    field: "dimension_unit",
                    payload: e.target.value,
                  });
                }}
                MenuProps={{
                  PaperProps: {
                    sx: {
                      border: "1px solid #939598",
                      borderRadius: "0",
                      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                    },
                  },
                }}
              >
                <MenuItem value="None">
                  <em>Select...</em>
                </MenuItem>
                {field.units &&
                  field.units.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
              </Select>
            </Stack>
          </Grid>
        </Grid>
      )}
      <Modal
        title={helpTitle}
        onClose={() => setShowHelp(false)}
        show={showHelp}
      >
        <Typography variant="staticText">
          <ReactMarkdown children={helpText} remarkPlugins={[remarkGfm]} />
        </Typography>
      </Modal>
    </>
  );
}
