// Built-in modules
import qs from "qs";

// Third-party libraries
import React, { useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import {
  Container,
  Card,
  CardContent,
  Typography,
  Stack,
  Backdrop,
  CircularProgress,
  Grid,
} from "@mui/material";

// Local modules
import api from "../../../utils/api";
import LeftCopyBox from "../static/styles/LeftCopyBox";
import RightCopyBox from "../static/styles/RightCopyBox";

export default function StaticLanding(props) {
  const [isLoaded, setIsLoaded] = useState(false);
  const [jobListings, setJobListings] = useState();
  const [copyBoxes1, setCopyBoxes1] = useState();
  const [copyBoxes2, setCopyBoxes2] = useState();
  const [greenrectangle, setGreenRectangle] = useState();

  useEffect(() => {
    const query = qs.stringify({
      populate: {
        joblistings: {
          populate: "*",
        },
        copyboxes1: {
          populate: "*",
        },
        greenrectangle: {
          populate: "*",
        },
        copyboxes2: {
          populate: "*",
        },
      },
    });
    api.fetchStrapi(`/careers-page?${query}`).then((response) => {
      setJobListings(response.data.data.attributes.joblistings);
      setCopyBoxes1(response.data.data.attributes.copyboxes1);
      setGreenRectangle(response.data.data.attributes.greenrectangle);
      setCopyBoxes2(response.data.data.attributes.copyboxes2);
      setIsLoaded(true);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {!isLoaded ? (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={true}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : (
        <Container maxWidth="xl" className="careers-text">
          <Stack spacing={3} alignItems="center">
            <Card sx={{ backgroundColor: "#e3e3e3", width: "100%" }}>
              <Grid container alignItems="center">
                <Grid item xs={12} md={6}>
                  <CardContent>
                    <Stack spacing={3} sx={{ margin: "2rem" }}>
                      <Typography variant="articleTitle" align="center">
                        {jobListings.title}
                      </Typography>
                      <Typography variant="staticText" align="center">
                        <ReactMarkdown remarkGfm={[remarkGfm]}>
                          {jobListings.body}
                        </ReactMarkdown>
                      </Typography>
                      <Typography variant="articleTitle" align="center">
                        {jobListings.redtext}
                      </Typography>
                    </Stack>
                  </CardContent>
                </Grid>
                <Grid item xs={12} md={6}>
                  <CardContent>
                    <Stack spacing={3} sx={{ margin: "2rem" }}>
                      <Typography variant="articleTitle" align="center">
                        Open Positions
                      </Typography>
                      <Typography variant="staticText">
                        <ReactMarkdown remarkGfm={[remarkGfm]}>
                          {jobListings.jobpostings}
                        </ReactMarkdown>
                      </Typography>
                    </Stack>
                  </CardContent>
                </Grid>
              </Grid>
            </Card>
            {copyBoxes1.map((copyBox) => (
              <React.Fragment key={copyBox.id}>
                {copyBox.alignment === "left" ? (
                  <>{LeftCopyBox(copyBox)}</>
                ) : (
                  <>{RightCopyBox(copyBox)}</>
                )}
              </React.Fragment>
            ))}
            {greenrectangle && (
              <Typography
                variant="greenRectangle"
                align="center"
                sx={{ width: "100%" }}
              >
                {greenrectangle.text}
              </Typography>
            )}
            {copyBoxes2.map((copyBox) => (
              <React.Fragment key={copyBox.id}>
                {copyBox.alignment === "left" ? (
                  <>{LeftCopyBox(copyBox)}</>
                ) : (
                  <>{RightCopyBox(copyBox)}</>
                )}
              </React.Fragment>
            ))}
          </Stack>
        </Container>
      )}
    </>
  );
}
