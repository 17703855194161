import React from "react";
import { Grid } from "@mui/material";
import ChipButton from "../styles/ChipButton";

const tabData = [
  { label: "General", tabValue: "Main" },
  { label: "Sell Diamonds", tabValue: "Sell" },
  { label: "Buy Diamonds", tabValue: "Buy" },
  { label: "myAuction", tabValue: "myAuction" },
];

function AuctionChip({ tab, setTab }) {
  return (
    <Grid container spacing={2} sx={{ marginTop: "1rem" }}>
      {tabData.map((item) => (
        <Grid item xs={6} md={3} key={item.tabValue}>
          <ChipButton
            label={item.label}
            tabValue={item.tabValue}
            selectedTab={tab}
            setTab={setTab}
          />
        </Grid>
      ))}
    </Grid>
  );
}

export default AuctionChip;
