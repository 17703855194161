import React, { useState, useEffect } from "react";
import { Link as DomLink } from "react-router-dom";
import {
  Typography,
  Card,
  CardContent,
  CardHeader,
  Link,
  Stack,
  Pagination,
} from "@mui/material";

function TechnicalResults(props) {
  const { strapiResults, searchTerm, isSearch } = props;
  const articlePerPage = 5;
  const [currentPage, setCurrentPage] = useState(1);

  const handlePageChange = (e, newPage) => {
    setCurrentPage(newPage);

    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  const startIndex = (currentPage - 1) * articlePerPage + 1;
  const endIndex = Math.min(currentPage * articlePerPage, strapiResults.length);

  const paginatedResults = strapiResults.slice(
    (currentPage - 1) * articlePerPage,
    currentPage * articlePerPage
  );

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <div>
      {strapiResults.length ? (
        <>
          <Typography sx={{ mb: "2rem" }}>
            Showing {startIndex}-{endIndex} of {strapiResults.length} results
          </Typography>
          <Stack spacing={2}>
            {paginatedResults.map((result) => (
              <DomLink
                to={{ pathname: `${result.url}` }}
                target="_blank"
                key={result.id}
              >
                <Card sx={{ backgroundColor: "#f0f0f0", borderRadius: "4px" }}>
                  <CardHeader
                    title={
                      result.subtitle
                        ? `${result.title} - ${result.subtitle}`
                        : result.title
                    }
                    subheader={`${result.author} | ${result.date}`}
                    titleTypographyProps={{ sx: { mb: 1 } }}
                  />
                  <CardContent>
                    {result.article ? (
                      <>
                        <Typography>
                          {result.article.split(" ").slice(0, 40).join(" ")}...
                          <Link href={`${result.url}`}>Read More</Link>
                        </Typography>
                      </>
                    ) : null}
                  </CardContent>
                </Card>
              </DomLink>
            ))}
            <Pagination
              count={Math.ceil(strapiResults.length / articlePerPage)}
              page={currentPage}
              onChange={handlePageChange}
            />
          </Stack>
        </>
      ) : isSearch ? (
        <Typography sx={{ margin: "0.5rem" }}>
          No technical articles found for: "{searchTerm}" - Try another search
        </Typography>
      ) : null}
    </div>
  );
}

export default TechnicalResults;
