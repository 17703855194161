// Built-in modules
import qs from "qs";

// Third-party libraries
import React, { useReducer, useEffect, useState } from "react";
import { Link as DomLink, useLocation } from "react-router-dom";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import {
  Box,
  Button,
  Chip,
  Divider,
  Grid,
  Stack,
  Typography,
  Backdrop,
  Paper,
  CircularProgress,
  Container,
  Skeleton,
  MenuItem,
  Select as MUISelect,
  FormControlLabel,
  Switch,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";

// Local modules
import { MeleeSearchContext } from "../../utils/stateHandlers/contexts";
import states from "../../utils/stateHandlers/initialStates";
import { storage } from "../../utils";
import { meleeReducer } from "../../utils/stateHandlers/reducers";
import api from "../../utils/api";
import formatting from "../../utils/formatHelpers";
import Modal from "./controls/Modal";
import constants, {
  hiddenMelee,
  hiddenMeleeMobile,
  hiddenTablet,
} from "../../utils/constants";
import MeleeDiamondTable from "./MeleeDiamondTable";
import MeleeDiamondSearchFilters from "./MeleeDiamondSearchFilters";
import MeleeAddToCartPreview from "./MeleeAddToCartPreview";

const CustomSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 34,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(9px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "& + .MuiSwitch-track": {
      opacity: 1,
      backgroundColor: "#939598",
    },
    "&.Mui-checked": {
      transform: "translateX(12px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: "#7fa04d",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 16 / 2,
    opacity: 1,
    boxSizing: "border-box",
  },
}));

export default function MeleeDiamondSearchLanding(props) {
  const theme = useTheme();
  const screenBreakpoint = useMediaQuery(theme.breakpoints.down("md"));
  const [meleeState, meleeSearchDispatch] = useReducer(
    meleeReducer,
    states.initialMeleeSearchState
  );
  const {
    pageLoaded,
    searchQuery,
    resultsLoaded,
    diamondCount,
    standard,
    strapiBody,
    strapiImage,
    openCartPreview,
    matchedPairs,
  } = meleeState;
  const loggedIn = storage.getStorageItem("authToken") ? true : false;
  const [showContactModal, setShowContactModal] = useState(false);
  const sendTo = useLocation();

  const frontImageUrl = constants.CMS_FRONT_IMAGE_URL;

  const StandardOrUnique = () => (
    <Grid container>
      <Grid item className="mined-filter" sx={{ width: "100%" }}>
        <Button
          disableElevation
          variant="contained"
          color={standard === true ? "primary" : "secondary"}
          onClick={() => {
            meleeSearchDispatch({ type: "setStandardFlag", payload: true });
          }}
        >
          Standard
        </Button>
        <Button
          disableElevation
          variant="contained"
          color={standard === false ? "primary" : "secondary"}
          onClick={() => {
            meleeSearchDispatch({ type: "setStandardFlag", payload: false });
          }}
        >
          Unique Lots
        </Button>
      </Grid>
    </Grid>
  );

  function resetFilters() {
    meleeSearchDispatch({
      type: "clearFilters",
      payload: [],
    });
    meleeSearchDispatch({
      type: "setMatchedPairsFlag",
      payload: false,
    });
  }

  function nextPage() {
    const currentPage = searchQuery.page;
    meleeSearchDispatch({ type: "updatePage", payload: currentPage + 1 });
  }

  function prevPage() {
    const currentPage = searchQuery.page;
    meleeSearchDispatch({ type: "updatePage", payload: currentPage - 1 });
  }

  function formatCollectionsValues(resetCollections) {
    const formattedSignatureCollections = (
      resetCollections.Signature || []
    ).map((collection) => {
      return { value: collection, label: collection, selected: false };
    });
    const formattedCanadianCollections = (
      resetCollections["Canadian Origin"] || []
    ).map((collection) => {
      return { value: collection, label: collection, selected: false };
    });

    const formattedHarmonyCollections = (resetCollections.Harmony || []).map(
      (collection) => {
        return { value: collection, label: collection, selected: false };
      }
    );

    const formattedLabCollections = (
      resetCollections["Harmony Lab Grown"] || []
    ).map((collection) => {
      return { value: collection, label: collection, selected: false };
    });
    const formattedCollections = {
      Signature: formattedSignatureCollections,
      Canadian: formattedCanadianCollections,
      Harmony: formattedHarmonyCollections,
      Lab: formattedLabCollections,
    };
    return formattedCollections;
  }

  function showMatchedPairs() {
    if (matchedPairs) {
      meleeSearchDispatch({ type: "setMatchedPairsFlag", payload: false });
      resetFilters();
    } else {
      meleeSearchDispatch({ type: "setMatchedPairsFlag", payload: true });
      const newQuery = {
        fields: [{ name: "Shape", values: [{ name: "Round" }] }],
        page: 1,
        size: 150,
        sort: { field: "CaratRange", direction: "desc" },
      };
      meleeSearchDispatch({ type: "matchedPairsQuery", payload: newQuery });
    }
  }

  function formatMatchedPairsResults(results) {
    const formattedResults = results.map((result) => {
      let newPrice = Number(formatting["strip"](result.EstimatedPrice)) * 2;
      result.EstimatedPrice = formatting["price2"](newPrice);
      return result;
    });
    return formattedResults;
  }

  useEffect(() => {
    const query = qs.stringify({
      populate: {
        title: {
          populate: "*",
        },
        body: {
          populate: "*",
        },
        image: {
          populate: "*",
        },
      },
    });
    api.fetchStrapi(`/diamonds-unique-lots-page?${query}`).then((response) => {
      meleeSearchDispatch({
        type: "setStrapiInfo",
        payload: {
          body: response.data.data.attributes.body,
          image: response.data.data.attributes.image,
        },
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    api.fetch("melee/fields").then((response) => {
      const standard = response.data.filter(function (field) {
        return field.search_order && field.name !== "Quality";
      });
      const collection = response.data.find(function (field) {
        return field.name === "Collection";
      });
      const newCollections = formatCollectionsValues(collection.values);
      const headers = response.data.filter(function (field) {
        return field.results_order && !hiddenMelee.includes(field.name);
      });
      const sortedHeaders = [...headers].sort(
        (a, b) => a.results_order - b.results_order
      );
      const mobileHeaders = sortedHeaders.filter(function (header) {
        return !hiddenMeleeMobile.includes(header.name);
      });
      const tabletHeaders = sortedHeaders.filter(function (header) {
        return !hiddenTablet.includes(header.name);
      });
      meleeSearchDispatch({ type: "setFilters", payload: standard });
      meleeSearchDispatch({
        type: "setCollections",
        payload: newCollections,
      });
      meleeSearchDispatch({
        type: "setTableHeaders",
        payload: {
          tableHeaders: sortedHeaders,
          mobileTableHeaders: mobileHeaders,
          tabletTableHeaders: tabletHeaders,
        },
      });
      meleeSearchDispatch({ type: "pageLoaded", payload: true });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    meleeSearchDispatch({ type: "resultsLoaded", payload: false });
    api.post("melee/search", searchQuery).then((response) => {
      const collection = response.data.fields.find(function (field) {
        return field.name === "Collection";
      });
      const newCollections = formatCollectionsValues(collection.values);
      meleeSearchDispatch({
        type: "setCollections",
        payload: newCollections,
      });
      if (matchedPairs) {
        const filteredMatches = response.data.results.filter(function (melee) {
          return melee.Collection !== "Harmony";
        });
        const mappedMatches = formatMatchedPairsResults(filteredMatches);
        meleeSearchDispatch({
          type: "setResults",
          payload: {
            diamonds: mappedMatches,
            diamondCount: response.data.count,
          },
        });
      } else {
        meleeSearchDispatch({
          type: "setResults",
          payload: {
            diamonds: response.data.results,
            diamondCount: response.data.count,
          },
        });
      }

      meleeSearchDispatch({ type: "resultsLoaded", payload: true });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery]);

  return (
    <MeleeSearchContext.Provider value={{ meleeState, meleeSearchDispatch }}>
      {!pageLoaded ? (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={true}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : (
        <Stack spacing={1}>
          <Grid container direction="row" justifyContent="center">
            <Typography
              textAlign={screenBreakpoint ? "center" : ""}
              variant="h1"
              marginTop={"2rem"}
              sx={{ marginRight: "unset", marginBottom: "2rem" }}
            >
              Melee Diamond Search
            </Typography>
          </Grid>
          {StandardOrUnique()}

          <Grid container direction="row" justifyContent="center">
            <Typography>
              Need help finding the perfect diamond? &nbsp;
              <span
                className="contact-us pointer"
                onClick={() => setShowContactModal(true)}
              >
                Contact Us
              </span>
            </Typography>
          </Grid>
          {standard ? (
            <Stack spacing={2}>
              <Container maxWidth="xl">
                <MeleeDiamondSearchFilters />
                <FormControlLabel
                  sx={{ marginTop: "1rem" }}
                  control={
                    <CustomSwitch
                      id="ShowMatchedPairs"
                      type="checkbox"
                      sx={{ marginLeft: "1rem" }}
                      onChange={(e) => showMatchedPairs()}
                      checked={matchedPairs}
                    />
                  }
                  label={
                    <Typography
                      variant="subtitle1"
                      sx={{ fontWeight: "800", marginLeft: "2rem" }}
                    >
                      Show Matched Pairs
                    </Typography>
                  }
                />
              </Container>
              <Grid container>
                <Grid item xs={6}>
                  <Typography variant="h3">Results ({diamondCount})</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body3" className="float-right">
                    <Stack direction="row" spacing={2}>
                      <Chip
                        color="warning"
                        label="Reset Filters"
                        onClick={() => resetFilters()}
                      />
                      {!loggedIn && !screenBreakpoint && (
                        <DomLink
                          to={{
                            pathname: "/login",
                            state: { sendTo: sendTo.pathname },
                          }}
                        >
                          <Button
                            variant="contained"
                            color="error"
                            size="small"
                            sx={{ marginTop: "unset" }}
                          >
                            Login For pricing
                          </Button>
                        </DomLink>
                      )}
                    </Stack>
                  </Typography>
                </Grid>
              </Grid>
              {!resultsLoaded ? (
                <Typography variant="h1" sx={{ width: "100%" }}>
                  <Skeleton animation="wave" />
                  <Skeleton animation="wave" />
                  <Skeleton animation="wave" />
                  <Skeleton animation="wave" />
                  <Skeleton animation="wave" />
                </Typography>
              ) : (
                <MeleeDiamondTable loggedIn={loggedIn} />
              )}
              <div>
                <Stack
                  direction="row"
                  alignItems="center"
                  className="float-right"
                  spacing={3}
                  sx={{ marginTop: "0.5rem" }}
                >
                  {searchQuery.page > 1 && (
                    <ChevronLeftIcon
                      onClick={() => prevPage()}
                      className="pointer"
                    />
                  )}
                  <Typography variant="body3">
                    Page {searchQuery.page}
                  </Typography>
                  {diamondCount > searchQuery.page * searchQuery.size && (
                    <ChevronRightIcon
                      onClick={() => nextPage()}
                      className="pointer"
                    />
                  )}
                  <Divider orientation="vertical" flexItem />
                  <Typography variant="body3">Results Per Page: </Typography>
                  <MUISelect
                    inputProps={{ MenuProps: { disableScrollLock: true } }}
                    size="small"
                    value={searchQuery.size}
                    onChange={(event) =>
                      meleeSearchDispatch({
                        type: "updateSize",
                        payload: event.target.value,
                      })
                    }
                  >
                    <MenuItem value={50}>50</MenuItem>
                    <MenuItem value={100}>100</MenuItem>
                    <MenuItem value={150}>150</MenuItem>
                  </MUISelect>
                </Stack>
              </div>
            </Stack>
          ) : (
            <Grid container justifyContent="center">
              <Box width="75%">
                <Paper variant="outlined" square>
                  <Stack justifyContent="center" spacing={2}>
                    <Typography
                      variant="subtitle1"
                      align="center"
                      sx={{
                        paddingLeft: "30rem",
                        paddingRight: "30rem",
                        paddingTop: "5rem",
                      }}
                    >
                      <ReactMarkdown remarkGfm={[remarkGfm]}>
                        {strapiBody}
                      </ReactMarkdown>
                    </Typography>
                    <a
                      href={strapiImage.data.attributes.alternativeText}
                      target="_blank"
                      rel="noreferrer"
                      className="center"
                    >
                      <img
                        src={`${frontImageUrl}${strapiImage.data.attributes.url}`}
                        alt={strapiImage.title}
                      />
                    </a>
                  </Stack>
                </Paper>
              </Box>
            </Grid>
          )}
        </Stack>
      )}
      <Modal
        title="Contact Us"
        onClose={() => setShowContactModal(false)}
        show={showContactModal}
      />
      {openCartPreview && <MeleeAddToCartPreview />}
    </MeleeSearchContext.Provider>
  );
}
