// Built-in modules
import qs from "qs";

// Third-party libraries
import React, { useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import {
  Container,
  Typography,
  Stack,
  Backdrop,
  CircularProgress,
} from "@mui/material";

// Local modules
import { Mobile, Default } from "../../../utils/deviceHelpers";
import api from "../../../utils/api";
import { constants } from "../../../utils";
import LandingFooter from "./LandingFooter";

export default function LinksPage(props) {
  const [isLoaded, setIsLoaded] = useState(false);
  const [title, setTitle] = useState();
  const [banner, setBanner] = useState();
  const [body, setBody] = useState();
  const [bottomgreen, setBottomGreen] = useState();
  const [icons, setIcons] = useState();

  const frontImageUrl = constants.CMS_FRONT_IMAGE_URL;

  const page =
    props.match.params.PageType === "Articles-And-Press-Releases"
      ? constants.ARTICLES_AND_PRESS_RELEASES
      : props.match.params.PageType === "Press-Release-2015-10-19"
      ? constants.PRESS_RELEASE_2015_10_19
      : props.match.params.PageType === "Holiday-And-Shutdown-Dates"
      ? constants.HOLIDAY_AND_SHUTDOWN_DATES
      : props.match.params.PageType === "Price-Increase-Notice-2-7-22"
      ? constants.PRICE_INCREASE_NOTICE_2_7_22
      : props.match.params.PageType === "Privacy-Policy"
      ? constants.PRIVACY_POLICY
      : props.match.params.PageType === "Diamond-Info-And-Forms"
      ? constants.DIAMOND_INFO_AND_FORMS
      : props.match.params.PageType === "SCS-Certification"
      ? constants.SCS_CERTIFICATION
      : props.match.params.PageType === "Hoover-And-Strong-Metals-Chart"
      ? constants.HOOVER_AND_STRONG_METALS_CHART
      : props.match.params.PageType === "Legal-Information"
      ? constants.LEGAL_INFORMATION
      : props.match.params.PageType === "Company-Policies"
      ? constants.COMPANY_POLICIES
      : props.match.params.PageType === "Non-Recycled-Items"
      ? constants.NON_RECYCLED_ITEMS
      : props.match.params.PageType === "Machine-Set-Inside-Out-Earrings"
      ? constants.MACHINE_SET_INSIDE_OUT_EARRINGS
      : props.match.params.PageType === "Required-Forms"
      ? constants.REQUIRED_FORMS
      : props.match.params.PageType === "Stone-Policy-And-Order-Information"
      ? constants.STONE_POLICY_AND_ORDER_INFORMATION
      : props.match.params.PageType === "How-The-Metal-Market-Prices-Are-Set"
      ? constants.HOW_THE_METAL_MARKET_PRICES_ARE_SET
      : props.match.params.PageType === "Old-And-New-Style-Name-Reference-List"
      ? constants.OLD_AND_NEW_STYLE_NAME_REFERENCE_LIST
      : props.match.params.PageType === "Forms"
      ? constants.FORMS_AND_CATALOGS
      : props.match.params.PageType === "Virginia-Residents"
      ? constants.VIRGINIA_RESIDENTS
      : props.match.params.PageType === "Refining-Payment-Terms"
      ? constants.REFINING_PAYMENT_TERMS
      : props.match.params.PageType === "Laser-Engraving"
      ? constants.LASER_ENGRAVING
      : props.match.params.PageType === "Breakage-And-Loss-Policy"
      ? constants.BREAKAGE_AND_LOSS_POLICY
      : props.match.params.PageType === "Responsible-Business-Practices-Policy"
      ? constants.RESPONSIBLE_BUSINESS_PRACTICES_POLICY
      : props.match.params.PageType === "Environmental-Policy"
      ? constants.ENVIRONMENTAL_POLICY
      : props.match.params.PageType === "Compliance-And-Responsibility"
      ? constants.COMPLIANCE_AND_RESPONSIBILITY
      : props.match.params.PageType === "Provenance-Management-Statement"
      ? constants.PROVENANCE_MANAGEMENT_STATEMENT
      : props.match.params.PageType === "Human-Rights-Policy"
      ? constants.HUMAN_RIGHTS_POLICY
      : props.match.params.PageType === "Metal-Purchases-Policy"
      ? constants.METAL_PURCHASES_POLICY
      : props.match.params.PageType === "Grievance-Policy"
      ? constants.GRIEVANCE_POLICY
      : props.match.params.PageType === "Anti-Money-Laundering-Statement"
      ? constants.ANTI_MONEY_LAUNDERING_STATEMENT
      : props.match.params.PageType === "Material-Specification-Sheets"
      ? constants.MATERIAL_SPECIFICATIONS_SHEETS
      : props.match.params.PageType === "Safety-Data-Sheets"
      ? constants.SAFETY_DATA_SHEETS
      : props.match.params.PageType === "Diamond-Conversion-Charts"
      ? constants.DIAMOND_CONVERSION_CHARTS
      : props.match.params.PageType === "Glossary"
      ? constants.GLOSSARY
      : props.match.params.PageType === "FAQ-For-Private-Individuals"
      ? constants.FAQ_FOR_PRIVATE_INDIVIDUALS
      : props.match.params.PageType === "Hoover-And-Strong-Milestones"
      ? constants.HOOVER_AND_STRONG_MILESTONES
      : props.match.params.PageType === "Press-Release-2015-08-05"
      ? constants.PRESS_RELEASE_2015_08_05
      : props.match.params.PageType === "Press-Release-2015-08-04"
      ? constants.PRESS_RELEASE_2015_08_04
      : props.match.params.PageType === "Press-Release-2015-03-09"
      ? constants.PRESS_RELEASE_2015_03_09
      : props.match.params.PageType === "Press-Release-2014-10-23"
      ? constants.PRESS_RELEASE_2014_10_23
      : props.match.params.PageType === "Press-Release-2013-09-04"
      ? constants.PRESS_RELEASE_2013_09_04
      : props.match.params.PageType === "Press-Release-2010-10-25"
      ? constants.PRESS_RELEASE_2010_10_25
      : props.match.params.PageType === "Press-Release-2010-09-27"
      ? constants.PRESS_RELEASE_2010_09_27
      : props.match.params.PageType === "Press-Release-2010-01-13"
      ? constants.PRESS_RELEASE_2010_01_13
      : props.match.params.PageType === "Press-Release-2009-08-04"
      ? constants.PRESS_RELEASE_2009_08_04
      : props.match.params.PageType === "Arizona-Jewelers"
      ? constants.ARIZONA_JEWELERS
      : props.match.params.PageType === "Catalogs-And-Brochures"
      ? constants.CATALOGS_AND_BROCHURES
      : props.match.params.PageType === "Promo-Details"
      ? constants.PROMO_DETAILS
      : 1;

  useEffect(() => {
    window.scrollTo(0, 0);
    const query = qs.stringify({
      populate: {
        title: {
          populate: "*",
        },
        banner: {
          populate: "*",
        },
        body: {
          populate: "*",
        },
        bottomgreen: {
          populate: "*",
        },
        icons: {
          populate: "*",
        },
      },
    });
    api.fetchStrapi(`/links-pages/${page}?${query}`).then((response) => {
      setTitle(response.data.data.attributes.title);
      setBanner(
        response.data.data.attributes.banner.data
          ? response.data.data.attributes.banner.data.attributes
          : null
      );
      setBody(response.data.data.attributes.body);
      setBottomGreen(response.data.data.attributes.bottomgreen);
      setIcons(response.data.data.attributes.icons);
      setIsLoaded(true);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.match.params.PageType]);

  return (
    <>
      {!isLoaded ? (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={true}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : (
        <Container maxWidth="xl" className="careers-text">
          <Mobile>
            <Stack spacing={3} alignItems="center">
              {banner && (
                <img
                  src={`${frontImageUrl}${banner.url}`}
                  width="100%"
                  alt="banner"
                />
              )}
              <Typography
                variant="articleTitle"
                align="center"
                sx={{ paddingTop: "2rem" }}
              >
                {title}
              </Typography>
              {body && (
                <Typography variant="staticText">
                  <ReactMarkdown remarkPlugins={[remarkGfm]}>
                    {body}
                  </ReactMarkdown>
                </Typography>
              )}
              <Typography
                variant="greenRectangle"
                align="center"
                sx={{ paddingLeft: "1rem", paddingRight: "1rem" }}
              >
                {bottomgreen.text}
              </Typography>
              <LandingFooter icons={icons} />
            </Stack>
          </Mobile>
          <Default>
            <Stack spacing={3} alignItems="center">
              {banner && (
                <img
                  src={`${frontImageUrl}${banner.url}`}
                  width="100%"
                  alt="banner"
                />
              )}
              <Typography
                variant="articleTitle"
                align="center"
                sx={{ paddingTop: "2rem" }}
              >
                {title}
              </Typography>
              {body && (
                <Typography
                  variant="staticText"
                  sx={{ paddingLeft: "6rem", paddingRight: "6rem" }}
                >
                  <ReactMarkdown remarkPlugins={[remarkGfm]}>
                    {body}
                  </ReactMarkdown>
                </Typography>
              )}
              <Typography variant="greenRectangle" align="center">
                {bottomgreen.text}
              </Typography>
              <LandingFooter icons={icons} />
            </Stack>
          </Default>
        </Container>
      )}
    </>
  );
}
