import React from "react";

const TrillionProfile = (props) => {
  const viewBoxWidth = 85.7;
  const viewBoxHeight = 86.1;

  const lineColor = "#4a4a4a";
  const strokeWidth2 = "0.5";

  const x = (percentX) => percentX * viewBoxWidth;
  const y = (percentY) => percentY * viewBoxHeight;

  const table = Number(props.diamond.Table).toFixed(0);
  const depthPercent = Number(props.diamond.Depth).toFixed(1);
  const girdle = props.diamond.Girdle || "";
  const girdleSpaceIndex = girdle.indexOf(" ");
  const girdle1 =
    girdleSpaceIndex > 0 ? girdle.slice(0, girdleSpaceIndex) : girdle;
  const girdle2 = girdleSpaceIndex > 0 ? girdle.slice(girdleSpaceIndex) : "";

  const diagramStrokeWidth = "0.01";
  const diagramFontSize = 0.046;

  return (
    <svg
      xmlns="http//www.w3.org/2000/svg"
      viewBox={"0 0 " + viewBoxWidth + " " + viewBoxHeight}
      width={props.width}
      height={props.height}
      preserveAspectRatio={props.preserveAspectRatio}
    >
      <polyline
        fill="none"
        stroke={props.color}
        strokeWidth={props.stroke_width}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        points="2.7,34.5 17.4,27.2 71.6,25.6 84,33.7 83.8,35.7 			"
      />
      <line
        fill="none"
        stroke={props.color}
        strokeWidth={props.stroke_width}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        x1="2.9"
        y1="36.6"
        x2="83.8"
        y2="35.7"
      />
      <line
        fill="none"
        stroke={props.color}
        strokeWidth={props.stroke_width}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        x1="2.7"
        y1="34.6"
        x2="83.6"
        y2="33.8"
      />
      <polyline
        fill="none"
        stroke={props.color}
        strokeWidth={props.stroke_width}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        points="2.6,36.6 49.5,66.8 83.8,35.7 			"
      />
      <line
        fill="none"
        stroke={props.color}
        strokeWidth={props.stroke_width}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        x1="2.6"
        y1="34.6"
        x2="2.5"
        y2="36.5"
      />
      <line
        fill="none"
        stroke={props.color}
        strokeWidth={props.stroke_width}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        x1="12.7"
        y1="34.5"
        x2="12.7"
        y2="36.4"
      />
      <line
        fill="none"
        stroke={props.color}
        strokeWidth={props.stroke_width}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        x1="36.5"
        y1="34.5"
        x2="36.5"
        y2="36.3"
      />
      <line
        fill="none"
        stroke={props.color}
        strokeWidth={props.stroke_width}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        x1="66.3"
        y1="34"
        x2="66.3"
        y2="35.8"
      />
      <line
        fill="none"
        stroke={props.color}
        strokeWidth={props.stroke_width}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        x1="74.1"
        y1="33.9"
        x2="74.1"
        y2="35.7"
      />
      <line
        fill="none"
        stroke={props.color}
        strokeWidth={props.stroke_width}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        x1="80.5"
        y1="33.8"
        x2="80.5"
        y2="35.7"
      />
      <polyline
        fill="none"
        stroke={props.color}
        strokeWidth={props.stroke_width}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        points="12.6,34.5 17.4,27.4 36.3,34 71.3,25.6 			"
      />
      <line
        fill="none"
        stroke={props.color}
        strokeWidth={props.stroke_width}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        x1="12.8"
        y1="36.6"
        x2="40.5"
        y2="60.9"
      />
      <line
        fill="none"
        stroke={props.color}
        strokeWidth={props.stroke_width}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        x1="36.4"
        y1="36.1"
        x2="40.2"
        y2="60.5"
      />
      <line
        fill="none"
        stroke={props.color}
        strokeWidth={props.stroke_width}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        x1="56.4"
        y1="60.5"
        x2="36.5"
        y2="36.3"
      />
      <line
        fill="none"
        stroke={props.color}
        strokeWidth={props.stroke_width}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        x1="66.2"
        y1="35.9"
        x2="56.5"
        y2="60.3"
      />
      <path
        fill="none"
        stroke={props.color}
        strokeWidth={props.stroke_width}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="M74.1,35.7C74,35.9,56.7,60.1,56.7,60.1"
      />
      <line
        fill="none"
        stroke={props.color}
        strokeWidth={props.stroke_width}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        x1="80.3"
        y1="35.7"
        x2="57.8"
        y2="58.8"
      />
      <polyline
        fill="none"
        stroke={props.color}
        strokeWidth={props.stroke_width}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        points="66.4,33.7 67.1,26.7 74.3,33.7 71.6,25.9 80.6,33.7 			"
      />
      {props.show_diagram && (
        <>
          {/* Table */}
          <line
            x1={x(0.18)}
            x2={x(0.88)}
            y1={y(0.28)}
            y2={y(0.28)}
            stroke={lineColor}
            strokeWidth={strokeWidth2}
          ></line>
          <line
            x1={x(0.18)}
            x2={x(0.18)}
            y1={y(0.26)}
            y2={y(0.3)}
            stroke={lineColor}
            strokeWidth={strokeWidth2}
          ></line>
          <line
            x1={x(0.88)}
            x2={x(0.88)}
            y1={y(0.26)}
            y2={y(0.3)}
            stroke={lineColor}
            strokeWidth={strokeWidth2}
          ></line>

          <text
            x={x(0.37)}
            y={y(0.25)}
            fontSize={y(diagramFontSize)}
            stroke={lineColor}
            strokeWidth={diagramStrokeWidth}
          >
            Table: {table}%
          </text>

          {/* Left Depth */}
          <line
            x1={x(0.06)}
            x2={x(0.06)}
            y1={y(0.31)}
            y2={y(0.78)}
            stroke={lineColor}
            strokeWidth={strokeWidth2}
          ></line>
          <line
            x1={x(0.04)}
            x2={x(0.08)}
            y1={y(0.31)}
            y2={y(0.31)}
            stroke={lineColor}
            strokeWidth={strokeWidth2}
          ></line>
          <line
            x1={x(0.04)}
            x2={x(0.08)}
            y1={y(0.78)}
            y2={y(0.78)}
            stroke={lineColor}
            strokeWidth={strokeWidth2}
          ></line>

          <rect
            x={x(0.005)}
            y={y(0.43)}
            width={x(0.22)}
            height={y(0.135)}
            rx="0.8"
            fill="white"
          ></rect>
          <text
            x={x(0.03)}
            y={y(0.475)}
            fontSize={y(diagramFontSize)}
            stroke={lineColor}
            strokeWidth={diagramStrokeWidth}
          >
            Depth:
          </text>
          <text
            x={x(0.02)}
            y={y(0.542)}
            fontSize={y(diagramFontSize)}
            stroke={lineColor}
            strokeWidth={diagramStrokeWidth}
          >
            {props.diamond.Measurement3}
          </text>

          {/* Right Depth */}
          <line
            x1={x(0.94)}
            x2={x(0.94)}
            y1={y(0.31)}
            y2={y(0.78)}
            stroke={lineColor}
            strokeWidth={strokeWidth2}
          ></line>
          <line
            x1={x(0.92)}
            x2={x(0.96)}
            y1={y(0.31)}
            y2={y(0.31)}
            stroke={lineColor}
            strokeWidth={strokeWidth2}
          ></line>
          <line
            x1={x(0.92)}
            x2={x(0.96)}
            y1={y(0.78)}
            y2={y(0.78)}
            stroke={lineColor}
            strokeWidth={strokeWidth2}
          ></line>

          <rect
            x={x(0.8)}
            y={y(0.43)}
            width={x(0.19)}
            height={y(0.135)}
            rx="0.8"
            fill="white"
          ></rect>
          <text
            x={x(0.81)}
            y={y(0.475)}
            fontSize={y(diagramFontSize)}
            stroke={lineColor}
            strokeWidth={diagramStrokeWidth}
          >
            Depth:
          </text>
          <text
            x={x(0.81)}
            y={y(0.542)}
            fontSize={y(diagramFontSize)}
            stroke={lineColor}
            strokeWidth={diagramStrokeWidth}
          >
            {depthPercent}%
          </text>

          {props.Culet && (
            <>
              {/* Culet */}
              <circle
                cx={x(0.575)}
                cy={y(0.78)}
                r={y(0.03)}
                fillOpacity="0"
                stroke={lineColor}
                strokeWidth={strokeWidth2}
              />
              <line
                x1={x(0.575)}
                x2={x(0.575)}
                y1={y(0.81)}
                y2={y(0.89)}
                stroke={lineColor}
                strokeWidth={strokeWidth2}
              ></line>

              <text
                x={x(0.55)}
                y={y(0.93)}
                fontSize={y(diagramFontSize)}
                stroke={lineColor}
                strokeWidth={diagramStrokeWidth}
              >
                Culet: {props.diamond.Culet}
              </text>
            </>
          )}

          {/* Girdle */}
          {girdle1.length > 0 && (
            <>
              <circle
                cx={x(0.3)}
                cy={y(0.41)}
                r={y(0.03)}
                fillOpacity="0"
                stroke={lineColor}
                strokeWidth={strokeWidth2}
              />
              <line
                x1={x(0.3)}
                x2={x(0.3)}
                y1={y(0.44)}
                y2={y(0.78)}
                stroke={lineColor}
                strokeWidth={strokeWidth2}
              ></line>

              <text
                x={x(0.1)}
                y={y(0.82)}
                fontSize={y(diagramFontSize)}
                stroke={lineColor}
                strokeWidth={diagramStrokeWidth}
              >
                Girdle: {girdle1}
              </text>
              <text
                x={x(0.1)}
                y={y(0.88)}
                fontSize={y(diagramFontSize)}
                stroke={lineColor}
                strokeWidth={diagramStrokeWidth}
              >
                {girdle2}
              </text>
            </>
          )}
        </>
      )}
    </svg>
  );
};

export default TrillionProfile;
