import React from "react";
import { Link as DomLink } from "react-router-dom";
import { Typography, Card, CardHeader, CardContent, Grid } from "@mui/material";

function PageResults(props) {
  const pageResults = props.pageResults;
  return (
    <div>
      {pageResults.length ? (
        <>
          <Grid container spacing={2}>
            {pageResults.map((result) => (
              <Grid item xs={12} md={6}>
                <DomLink to={result.url} target="_blank" key={result.id}>
                  <Card
                    sx={{
                      backgroundColor: "#f0f0f0",
                      borderRadius: "4px",
                      minHeight: "200px",
                    }}
                  >
                    <CardHeader
                      title={result.PageTitle || result.title}
                      subheader={result.topgreen ? result.topgreen.text : null}
                      titleTypographyProps={{
                        sx: { mb: 1, textTransform: "capitalize" },
                      }}
                    />
                    <CardContent>
                      <Typography>{result.subtitle}</Typography>
                    </CardContent>
                  </Card>
                </DomLink>
              </Grid>
            ))}
          </Grid>
        </>
      ) : (
        <Typography sx={{ margin: "0.5rem" }}>
          No results found - Try another search
        </Typography>
      )}
    </div>
  );
}

export default PageResults;
