import Quantity from "../../components/productFields/Quantity";
import Dimension from "../../components/productFields/Dimension";
import CaratSize from "../../components/productFields/CaratSize";
import FingerSize from "../../components/productFields/FingerSize";
import OuterDiameter from "../../components/productFields/OuterDiameter";
import InnerDiameter from "../../components/productFields/InnerDiameter";
import Length from "../../components/productFields/Length";
import Width from "../../components/productFields/Width";
import Thickness from "../../components/productFields/Thickness";
import QuantityPieces from "../../components/productFields/QuantityPieces";
import QuantityLength from "../../components/productFields/QuantityLength";
import Material from "../../components/productFields/Material";

function RenderField({ field, isMill, uniqueKey }) {
  if (field.name === "material") {
    return <Material field={field} key={uniqueKey} isMill={isMill} />;
  } else if (field.name === "dimension") {
    return <Dimension field={field} key={uniqueKey} isMill={isMill} />;
  } else if (field.name === "carat_size") {
    return <CaratSize field={field} key={uniqueKey} isMill={isMill} />;
  } else if (field.name === "finger_size") {
    return <FingerSize field={field} key={uniqueKey} isMill={isMill} />;
  } else if (field.name === "outer_diameter") {
    return <OuterDiameter field={field} key={uniqueKey} isMill={isMill} />;
  } else if (field.name === "inner_diameter") {
    return <InnerDiameter field={field} key={uniqueKey} isMill={isMill} />;
  } else if (field.name === "quantity") {
    return <Quantity field={field} key={uniqueKey} isMill={isMill} />;
  } else if (field.name === "length") {
    return <Length field={field} key={uniqueKey} isMill={isMill} />;
  } else if (field.name === "width") {
    return <Width field={field} key={uniqueKey} isMill={isMill} />;
  } else if (field.name === "thickness") {
    return <Thickness field={field} key={uniqueKey} isMill={isMill} />;
  } else if (field.name === "quantity.pieces") {
    return <QuantityPieces field={field} key={uniqueKey} isMill={isMill} />;
  } else if (field.name === "quantity.length") {
    return <QuantityLength field={field} key={uniqueKey} isMill={isMill} />;
  }

  return null;
}

export default RenderField;
