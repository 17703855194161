// Third-party libraries
import React, { useContext, useState, useEffect } from "react";
import {
  Select,
  Stack,
  Typography,
  MenuItem,
  TextField,
  Grid,
  FormControl,
  FormHelperText,
} from "@mui/material";

// Local modules
import { ProductsContext } from "../../../../utils/stateHandlers/contexts";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

export default function Quantity(props) {
  const { productState, productDispatch } = useContext(ProductsContext);
  const { priceLoaded, productInfo, style, validationErrors } = productState;
  const [localInput, setLocalInput] = useState(
    props.field.default === 0 ? 1 : props.field.default
  );
  const [localUnit, setLocalUnit] = useState(props.field.default_unit);
  const field = props.field;

  const handleInputChange = (e) => {
    setLocalInput(e.target.value);
    if (localUnit === "EA" && !Number.isInteger(Number(e.target.value))) {
      productDispatch({
        type: "setValidationErrors",
        payload: [...validationErrors, "quantity"],
      });
    } else {
      const remove = validationErrors.filter((error) => error === "dimension");

      productDispatch({ type: "setValidationErrors", payload: remove });
    }
  };

  const handleUnitInputChange = (e) => {
    setLocalUnit(e.target.value);
    if (e.target.value === "EA" && !Number.isInteger(Number(e.target.value))) {
      productDispatch({
        type: "setValidationErrors",
        payload: [...validationErrors, "quantity"],
      });
    } else {
      const remove = validationErrors.filter((error) => error === "dimension");
      productDispatch({ type: "setValidationErrors", payload: remove });
    }
  };

  function extractAndConvertOZ(data) {
    const quantityPattern = /(\d+(?:\.\d+)?\/\d+|\d+(?:\.\d+)?)/;
    const match = data.match(quantityPattern);
    if (match) {
      const split = match[0].split("/");
      if (split.length > 1) {
        return split[0] / split[1];
      } else {
        return split[0];
      }
    }
    return null;
  }

  useEffect(() => {
    if (localInput !== props.field.default) {
      const timeoutId = setTimeout(
        () =>
          productDispatch({
            type: "updateFields",
            field: field.name,
            payload: localInput,
          }),
        1000
      );
      return () => clearTimeout(timeoutId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localInput]);

  useEffect(() => {
    if (localUnit !== productInfo.quantity_unit) {
      productDispatch({
        type: "updateFields",
        field: "quantity_unit",
        payload: localUnit,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localUnit]);

  useEffect(() => {
    //setLocalInput(field.default);
    if (style === "SD") {
      setLocalUnit(field.default_unit);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [field]);

  useEffect(() => {
    if (style.startsWith("CHM")) {
      setLocalInput(field.default);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [style]);

  useEffect(() => {
    if (style === "COINS BULLION") {
      productDispatch({
        type: "setProductDefaults",
        field: "quantity",
        payload: localInput,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productInfo.quantity]);

  return (
    <Grid container key={field.name}>
      <Grid item xs={8}>
        <Stack>
          <FormControl>
            <Typography
              sx={{
                fontWeight: "700",
                paddingBottom: ".3rem",
              }}
            >
              Quantity
            </Typography>
            <TextField
              color="primary"
              error={
                localUnit === "EA" && !Number.isInteger(Number(localInput))
                  ? true
                  : false
              }
              disabled={!priceLoaded}
              onChange={(e) => {
                handleInputChange(e);
              }}
              size="small"
              sx={{
                width: "100%",
                margin: "unset",
              }}
              value={localInput}
            />
            <FormHelperText>
              {localUnit === "EA" && !Number.isInteger(Number(localInput))
                ? "Please Enter A Whole Number."
                : ""}
            </FormHelperText>
          </FormControl>
        </Stack>
      </Grid>
      <Grid item xs={1} />
      <Grid item xs={3}>
        <Stack>
          <Typography
            sx={{
              fontWeight: "700",
              paddingBottom: ".3rem",
            }}
          >
            Units
          </Typography>
          <Select
            value={localUnit === null ? field.default_unit : localUnit}
            sx={{ width: "100%" }}
            size="small"
            disabled={!priceLoaded || (field.units && field.units.length === 1)}
            onChange={(e) => {
              productDispatch({
                type: "updateFields",
                field: "quantity_unit",
                payload: e.target.value,
              });
              handleUnitInputChange(e);
            }}
            MenuProps={{
              PaperProps: {
                sx: {
                  border: "1px solid #939598",
                  borderRadius: "0",
                  maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                },
              },
            }}
          >
            <MenuItem value="None">
              <em>Select...</em>
            </MenuItem>
            {field.units &&
              field.units.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
          </Select>
        </Stack>
      </Grid>
      <Grid item xs={12} marginTop={"1rem"}>
        {productInfo.quantity && style === "COINS BULLION" ? (
          <Typography>
            Total Weight:{" "}
            {productInfo.quantity *
              extractAndConvertOZ(productInfo.description || "")}{" "}
            oz
          </Typography>
        ) : null}
      </Grid>
    </Grid>
  );
}
