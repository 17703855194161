// Third-party libraries
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Typography, Stack, Tab, Tabs, Box } from "@mui/material";

// Local modules
import { api } from "../../utils";
import { isBand } from "../../utils/styleCodes";
import PageRoutes from "./search/routes/PageRoutes";
import ProductRoutes from "./search/routes/ProductRoutes";
import ProductResults from "./search/ProductResults";
import TechnicalResults from "./search/TechnicalResults";
import PageResults from "./search/PageResults";
import TabPanel from "./TabPanel";
import useMediaQueryUtils from "../../utils/mediaQueryUtils";
import LoadingIndicator from "../products/productLanding/LoadingIndicator";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function SitewideSearch(props) {
  const { SearchTerm, SearchTermPart2, SearchTermPart3 } = useParams();
  const [value, setValue] = useState(0);
  const [resultsLoaded, setResultsLoaded] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [productPage, setProductPage] = useState([]);
  const [pageResults, setPageResults] = useState([]);
  const [strapiResults, setStrapiResults] = useState([]);
  const category = props.Category;
  const productCode = SearchTerm.toUpperCase();
  const twoPartStyleCode = `${SearchTerm}/${SearchTermPart2}`.toUpperCase();
  const threePartStyleCode =
    `${SearchTerm}/${SearchTermPart2}/${SearchTermPart3}`.toUpperCase();
  const isMdScreen = useMediaQueryUtils("md");

  const strapiPaths = [
    "/technical-articles?populate=*&pagination[page]=1&pagination[pageSize]=100",
    "/links-pages?populate=*&pagination[page]=1&pagination[pageSize]=100",
    "/sub-landing-pages?populate=*&pagination[page]=1&pagination[pageSize]=100",
    "/landing-pages?populate=*&pagination[page]=1&pagination[pageSize]=100",
    "/accordian-landing-pages?populate=*&pagination[page]=1&pagination[pageSize]=100",
  ];

  /* Query CMS for keywords*/
  function queryStrapi(array, query) {
    const newArray = array
      .filter((content) => {
        if (query) {
          return (
            content.attributes.tag &&
            content.attributes.tag.some(
              (tag) => tag && tag.toLowerCase().includes(query.toLowerCase())
            )
          );
        }
        return true;
      })
      .map((obj) => {
        return {
          ...obj.attributes,
          id: obj.id,
        };
      });
    return newArray;
  }

  function performSearch(query, file, routes) {
    query = query.toLowerCase();
    const filteredRoutes = file.filter((route) =>
      route.keywords.some((keyword) => keyword.includes(query))
    );
    if (routes === "pageRoutes") {
      setPageResults((prevResults) => [...prevResults, ...filteredRoutes]);
    } else {
      setProductPage(filteredRoutes);
    }
  }

  const handleChange = (e, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    const query = SearchTerm;
    strapiPaths.map((path) =>
      api.fetchStrapi(path).then((response) => {
        const filteredResults = queryStrapi(response.data.data, query);
        let url = path.split("?")[0];
        if (
          url === "/landing-pages" ||
          url === "/sub-landing-pages" ||
          url === "/accordion-landing" ||
          url === "/links-pages"
        ) {
          setPageResults((prevResults) => [...prevResults, ...filteredResults]);
        }
        filteredResults.map((items) => {
          if (url === "/technical-articles") {
            items["url"] = `/technicalarticles/${items.id}`;
            setStrapiResults(filteredResults);
          }
          return items;
        });
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [SearchTerm]);

  useEffect(() => {
    api
      .fetch(
        `search?q=${
          props.match.params.SearchTermPart3
            ? threePartStyleCode
            : props.match.params.SearchTermPart2
            ? twoPartStyleCode
            : productCode
        }`
      )
      .then((response) => {
        performSearch(props.match.params.SearchTerm, PageRoutes, "pageRoutes");
        performSearch(
          props.match.params.SearchTerm,
          ProductRoutes,
          "productRoutes"
        );
        if (response.data !== undefined) {
          const filteredResults = response.data.results.filter(function (
            result
          ) {
            return result.style;
          });
          const diamondResult = response.data.results.find(function (result) {
            return result.type === "Diamond";
          });
          const searchTermIsBand = isBand(productCode);
          const searchTermIsCharm = productCode.startsWith("CHM");

          if (filteredResults.length === 0 && diamondResult) {
            const url =
              window.location.protocol +
              "//" +
              window.location.host +
              "/diamond/" +
              SearchTerm;
            window.location.href = url;
          } else if (searchTermIsBand && !searchTermIsCharm) {
            setSearchResults(filteredResults);
            setResultsLoaded(true);
          } else if (!searchTermIsBand && searchTermIsCharm) {
            const url =
              window.location.protocol +
              "//" +
              window.location.host +
              "/product/CHM";
            window.location.href = url;
          } else {
            setSearchResults(filteredResults);
            setResultsLoaded(true);
          }
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [SearchTerm]);

  return (
    <React.Fragment>
      <Stack direction="column" alignItems="center">
        <Typography variant="h1" textAlign="center" mx="auto" marginTop="2rem">
          Search Results for "
          {props.match.params.SearchTermPart3
            ? threePartStyleCode
            : props.match.params.SearchTermPart2
            ? twoPartStyleCode
            : productCode}
          "
        </Typography>
        <Box
          sx={{
            width: !isMdScreen ? "80%" : "100%",
            minWidth: "50%",
            mx: "auto",
            paddingTop: "2rem",
          }}
        >
          <Box sx={{ borderBottom: 2, borderColor: "divider" }}>
            <Tabs value={value} onChange={handleChange}>
              <Tab
                label={`Products (${searchResults.length})`}
                className={`tab ${value === 0 ? "selected" : ""}`}
                {...a11yProps(0)}
              />
              <Tab
                label={`Technical Articles (${strapiResults.length})`}
                className={`tab ${value === 1 ? "selected" : ""}`}
                {...a11yProps(1)}
              />
              <Tab
                label={`Pages (${pageResults.length})`}
                className={`tab ${value === 2 ? "selected" : ""}`}
                {...a11yProps(2)}
              />
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            {!resultsLoaded ? (
              <LoadingIndicator />
            ) : (
              <ProductResults
                searchResults={searchResults}
                category={category}
                searchTerm={SearchTerm}
                productPage={productPage}
              />
            )}
          </TabPanel>
          <TabPanel value={value} index={1}>
            {!resultsLoaded ? (
              <LoadingIndicator />
            ) : (
              <TechnicalResults
                strapiResults={strapiResults}
                searchTerm={SearchTerm}
                isSearch={true}
              />
            )}
          </TabPanel>
          <TabPanel value={value} index={2}>
            {!resultsLoaded ? (
              <LoadingIndicator />
            ) : (
              <PageResults pageResults={pageResults} />
            )}
          </TabPanel>
        </Box>
      </Stack>
    </React.Fragment>
  );
}
