// Third-party libraries
import React, { useContext, useState } from "react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import InfoIcon from "@mui/icons-material/InfoOutlined";
import { Select, Typography, MenuItem } from "@mui/material";

// Local modules
import { ProductsContext } from "../../../utils/stateHandlers/contexts";
import Modal from "../../diamonds/controls/Modal";
import { constants } from "../../../utils";

export default function TreatmentField(props) {
  const { productState, productDispatch } = useContext(ProductsContext);
  const { style, productInfo, priceLoaded, bubbles } = productState;
  const [showHelp, setShowHelp] = useState(false);
  const [helpTitle, setHelpTitle] = useState("");
  const [helpText, setHelpText] = useState();
  const [helpImage, setHelpImage] = useState(null);
  const [localTreatment, setLocalTreatment] = useState("None");
  const field = props.field;

  const frontImageUrl = constants.CMS_FRONT_IMAGE_URL;

  function updateTreatments(treatmentType, event) {
    setLocalTreatment(event.target.value);
    const treatmentToUpdate = (productInfo.treatments || []).find(
      (treatment) => treatment.name === treatmentType
    );

    if (treatmentToUpdate && event.target.value === "None") {
      const removeTreatment = productInfo.treatments.filter(function (
        treatment
      ) {
        return treatment.name !== treatmentToUpdate.name;
      });
      productDispatch({
        type: "updateTreatments",
        payload: removeTreatment === [] ? "" : removeTreatment,
      });
    } else if (treatmentToUpdate && event.target.value !== "None") {
      treatmentToUpdate.value = event.target.value;
      productDispatch({
        type: "updateTreatments",
        payload: productInfo.treatments,
      });
    } else {
      productDispatch({
        type: "updateTreatments",
        payload: [
          ...productInfo.treatments,
          { name: treatmentType, value: event.target.value },
        ],
      });
    }
  }

  // useEffect(() => {
  //   if (clear) {
  //     productDispatch({ type: "setClear", payload: false });
  //     setLocalTreatment("None");
  //     productDispatch({
  //       type: "updateTreatments",
  //       payload: [],
  //     });
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [clear]);

  return (
    <span key={field.label}>
      <Typography sx={{ fontWeight: "700", paddingBottom: ".3rem" }}>
        {field.label}
        {props.isMill && style === "FS" && field.type === "FORM" && (
          <InfoIcon
            sx={{ marginLeft: "0.5rem" }}
            className="pointer"
            color="primary"
            onClick={() => {
              setShowHelp(true);
              setHelpTitle("Flat Sheet Finished Form");
              setHelpText(
                "Copy here about Exact Cuts and Slit Strip - to come from Stewart Or, this might just be deleted. TBD."
              );
            }}
          />
        )}
        {props.isMill && style === "FS" && field.type === "TEMPER" && (
          <InfoIcon
            sx={{ marginLeft: "0.5rem" }}
            className="pointer"
            color="primary"
            onClick={() => {
              setShowHelp(true);
              setHelpTitle("Flat Sheet Temper");
              setHelpText(
                "Standard temper gold sheet is annealed at approximately 0.002” - 0.010” before the final thickness is reached, depending on the thickness of the sheet. This is our standard temper which we provide unless otherwise requested. Soft temper gold sheet is annealed after the final thickness is reached, making it dead soft. We will provide this temper upon request. Hard temper gold sheet is annealed approximately 0.004” - 0.015” before the final thickness is reached. We stock sterling silver sheet in our standard temper and will provide hard temper sheet for an additional $10.50, upon request."
              );
            }}
          />
        )}
        {props.isMill &&
          style === "RW" &&
          field.type === "TEMPER" &&
          bubbles &&
          bubbles.map((bubble, index) => (
            <React.Fragment key={index}>
              {bubble.bubblefield === "TEMPER" && (
                <InfoIcon
                  sx={{ marginLeft: "0.5rem" }}
                  className="pointer"
                  color="primary"
                  onClick={() => {
                    setShowHelp(true);
                    setHelpTitle(bubble.bubbletitle);
                    setHelpText(bubble.bubbletext);
                    setHelpImage(
                      bubble.bubbleimage && bubble.bubbleimage.data !== null
                        ? bubble.bubbleimage.data.attributes
                        : null
                    );
                  }}
                />
              )}
            </React.Fragment>
          ))}
        {props.isMill &&
          style === "RW" &&
          field.type === "FORM" &&
          bubbles &&
          bubbles.map((bubble, index) => (
            <React.Fragment key={index}>
              {bubble.bubblefield === "FORM" && (
                <InfoIcon
                  sx={{ marginLeft: "0.5rem" }}
                  className="pointer"
                  color="primary"
                  onClick={() => {
                    setShowHelp(true);
                    setHelpTitle(bubble.bubbletitle);
                    setHelpText(bubble.bubbletext);
                    setHelpImage(
                      bubble.bubbleimage && bubble.bubbleimage.data !== null
                        ? bubble.bubbleimage.data.attributes
                        : null
                    );
                  }}
                />
              )}
            </React.Fragment>
          ))}
        {props.isMill &&
          style === "RW" &&
          field.type === "CUTTING" &&
          bubbles &&
          bubbles.map((bubble, index) => (
            <React.Fragment key={index}>
              {bubble.bubblefield === "CUTTING" && (
                <InfoIcon
                  sx={{ marginLeft: "0.5rem" }}
                  className="pointer"
                  color="primary"
                  onClick={() => {
                    setShowHelp(true);
                    setHelpTitle(bubble.bubbletitle);
                    setHelpText(bubble.bubbletext);
                    setHelpImage(
                      bubble.bubbleimage && bubble.bubbleimage.data !== null
                        ? bubble.bubbleimage.data.attributes
                        : null
                    );
                  }}
                />
              )}
            </React.Fragment>
          ))}
      </Typography>
      <Select
        key={field.name}
        value={localTreatment === null ? field.default : localTreatment}
        size="small"
        sx={{ width: "100%" }}
        disabled={!priceLoaded}
        onChange={(e) => {
          updateTreatments(field.type, e);
        }}
        MenuProps={{
          PaperProps: {
            sx: {
              border: "1px solid #939598",
              borderRadius: "0",
            },
          },
        }}
      >
        <MenuItem value="None">
          <em>Select...</em>
        </MenuItem>
        {field.values.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
      <Modal
        title={helpTitle}
        onClose={() => setShowHelp(false)}
        show={showHelp}
      >
        {helpImage !== null && (
          <img
            src={`${frontImageUrl}${helpImage.url}`}
            width="100%"
            alt="helpImage"
          />
        )}
        <Typography variant="staticText">
          <ReactMarkdown children={helpText} remarkPlugins={[remarkGfm]} />
        </Typography>
      </Modal>
    </span>
  );
}
