// Third-party libraries
import React, { useEffect, useReducer } from "react";
import * as yup from "yup";
// Local modules
import { api } from "../../../utils";
import { formReducer } from "../../../utils/stateHandlers/reducers";
import states from "../../../utils/stateHandlers/initialStates";
import { FormContext } from "../../../utils/stateHandlers/contexts";
import Form from "./components/Form";
import InputField from "./components/InputField";

const schema = {
  Metal: yup.array().min(1).required("You must select at least one option."),
  Mill: yup.array().min(1).required("You must select at least one option."),
  Findings: yup.array().min(1).required("You must select at least one option."),
  Casting: yup.array().min(1).required("You must select at least one option."),
  Discounts: yup
    .array()
    .min(1)
    .required("You must select at least one option."),
  AnnualPurchases: yup
    .string()
    .required("Please enter an annual precious metal purchasing amount."),
};

export default function MarketingForm() {
  const [formState, dispatch] = useReducer(formReducer, states);
  const { fields } = formState;
  const onSubmit = () => {};

  useEffect(() => {
    api.fetch("forms/marketing/fields").then((response) => {
      dispatch({
        type: "setFields",
        payload: response.data.fields,
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FormContext.Provider value={{ formState, dispatch }}>
      <Form onSubmit={onSubmit} validationObject={schema}>
        {fields &&
          fields.map((field) => (
            <InputField name={field.name} field={field} />
            // <React.Fragment key={field.name}>
            //   {field.template === "text" ? (
            //     <InputField name={field.name} field={field} />
            //   ) : field.template === "dropdown" ? (
            //     <SelectField name={field.name} field={field} />
            //   ) : (
            //     <InputField name={field.name} field={field} />
            //   )}
            // </React.Fragment>
          ))}
      </Form>
    </FormContext.Provider>
  );
}
