import { useMediaQuery } from "react-responsive";

//This is where the reload is coming from when we resize the browser.
//We are actually unmounting and reloading components based on the
//size of the browser viewport.

export const Desktop = ({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: 992 });
  return isDesktop ? children : null;
};

export const Mobile = ({ children }) => {
  const isMobile = useMediaQuery({ maxWidth: 991 });
  return isMobile ? children : null;
};

export const Tablet = ({ children }) => {
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
  return isTablet ? children : null;
};

export const Default = ({ children }) => {
  const isNotMobile = useMediaQuery({ minWidth: 992 });
  return isNotMobile ? children : null;
};

export default Object.freeze({
  Desktop,
  Mobile,
  Tablet,
  Default,
});
