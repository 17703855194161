import React from "react";
import { Button } from "@mui/material";

function ButtonLabel({ label }) {
  const buttonWidth = label === "Learn More" ? "20rem" : "30rem";

  return (
    <Button
      color="primary"
      variant="contained"
      size="large"
      sx={{ width: buttonWidth }}
    >
      {label}
    </Button>
  );
}

export default ButtonLabel;
