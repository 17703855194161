// Third-party libraries
import React from "react";
import { useParams } from "react-router-dom";

// Local modules
import ProductLandingPage from "../products/productLanding/ProductLandingPage";

export default function Products(props) {
  const { Category } = useParams();
  const isMill =
    Category === "Wire"
      ? true
      : Category === "Tubing"
      ? true
      : Category === "Solder"
      ? true
      : false;
  return <ProductLandingPage Category={Category} {...props} isMill={isMill} />;
}
