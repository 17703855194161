// Built-in modules
// Third-party libraries
import React, { useState, useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";
import toast from "react-hot-toast";
import {
  Container,
  Card,
  CardHeader,
  CardContent,
  Button,
  Typography,
  TextField,
  Stack,
  Divider,
} from "@mui/material";

// Local modules
import { AppContext } from "../../utils/stateHandlers/contexts";
import { api, storage } from "../../utils";

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export default function UpdatePassword(props) {
  const { user } = useContext(AppContext);
  let query = useQuery();
  const currentUser = JSON.parse(storage.getStorageItem("currentUser"));
  const [newPassword, setNewPassword] = useState();
  const [confirmPassword, setConfirmPassword] = useState();
  const [passwordErrors, setPasswordErrors] = useState([]);

  const checkPasswordValidity = () => {
    const errors = [];

    if (newPassword !== confirmPassword) {
      errors.push("Passwords must match.");
    }

    const isNonWhiteSpace = /^\S*$/;
    if (!isNonWhiteSpace.test(newPassword)) {
      errors.push("Password cannot contain spaces.");
    }

    // const isContainsUppercase = /^(?=.*[A-Z]).*$/;
    // if (!isContainsUppercase.test(newPassword)) {
    //   errors.push("Password must have at least one Uppercase Character.");
    // }

    // const isContainsLowercase = /^(?=.*[a-z]).*$/;
    // if (!isContainsLowercase.test(newPassword)) {
    //   errors.push("Password must have at least one Lowercase Character.");
    // }

    // const isContainsNumber = /^(?=.*[0-9]).*$/;
    // if (!isContainsNumber.test(newPassword)) {
    //   errors.push("Password must contain at least one number.");
    // }

    // const isContainsSymbol =
    //   /^(?=.*[~`!@#$%^&*()--+={}\[\]|\\:;"'<>,.?/_₹]).*$/;
    // if (!isContainsSymbol.test(newPassword)) {
    //   errors.push("Password must contain at least one Special Character.");
    // }

    const isValidLength = /^.{8,}$/;
    if (!isValidLength.test(newPassword)) {
      errors.push("Password must be 8 Characters Long.");
    }

    setPasswordErrors(errors);
    return errors.length > 0 ? false : true;
  };

  function updatePassword() {
    const validPassword = checkPasswordValidity();
    const updatePasswordBody = {
      email:
        query.get("email") !== null ? query.get("email") : currentUser.Email,
      resetToken: query.get("resetToken"),
      password: newPassword,
    };
    if (validPassword) {
      api
        .post("account/updatePassword", updatePasswordBody)
        .then((response) => {
          if (response.isAxiosError) {
            const errorMessage = [];
            response.data.errors.forEach(function (error) {
              errorMessage.push(error);
            });
            toast.error(
              `There was an error processing your request. ${errorMessage.toString()}`
            );
          } else {
            toast.success(
              "Your password has been updated successfully. Redirecting you to the Login Screen."
            );
            user.signOut();
            const timeoutId = setTimeout(
              () => (window.location.href = "/login"),
              1000
            );
            return () => clearTimeout(timeoutId);
          }
        });
    }
  }

  useEffect(() => {
    if (query.get("resetToken")) {
      const getAuthTokenBody = {
        username:
          query.get("email") !== null ? query.get("email") : currentUser.Email,
        resetToken: query.get("resetToken"),
      };
      api.post(`account/recover`, getAuthTokenBody).then((response) => {
        if (response.isAxiosError) {
          const errorMessage = [];
          response.data.errors.forEach(function (error) {
            errorMessage.push(error);
          });
          toast.error(
            `There was an error processing your request. ${errorMessage.toString()}`
          );
        } else {
          user.signIn(response.data, response.data.token);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container maxWidth="sm">
      <Card>
        <CardHeader
          title={
            <Typography variant="articleTitle">Update Password</Typography>
          }
        />
        <CardContent>
          <Stack spacing={3}>
            <Typography variant="articleText">
              Please enter a new password.
            </Typography>
            <Divider />
            <TextField
              required
              color="primary"
              type="password"
              onChange={(e) => {
                setNewPassword(e.target.value);
              }}
              label={<Typography variant="navText">New Password</Typography>}
              onBlur={() => checkPasswordValidity()}
            />
            <TextField
              required
              color="primary"
              type="password"
              onChange={(e) => {
                setConfirmPassword(e.target.value);
              }}
              label={
                <Typography variant="navText">Confirm Password</Typography>
              }
              onBlur={() => checkPasswordValidity()}
            />
            <Stack spacing={1}>
              {passwordErrors.length === 0 && (
                <Typography variant="subtitle2">
                  New Password must be at least 8 characters long.
                </Typography>
              )}
              {passwordErrors.map((error) => (
                <Typography key={error} variant="subtitle1">
                  {error}
                </Typography>
              ))}
            </Stack>
            <Button
              size="small"
              color="success"
              variant="contained"
              onClick={() => updatePassword()}
              disabled={passwordErrors.length > 0 ? true : false}
            >
              Update Password
            </Button>
          </Stack>
        </CardContent>
      </Card>
    </Container>
  );
}
