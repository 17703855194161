// Third-party libraries
import React, { useContext } from "react";
import { Typography } from "@mui/material";

// Local modules
import { MeleeSearchContext } from "../../utils/stateHandlers/contexts";
import Shape from "./shapes/Shape";
function ShapeFilter(props) {
  const { meleeState, meleeSearchDispatch } = useContext(MeleeSearchContext);
  const { filters, searchQuery, matchedPairs } = meleeState;
  const shapeFilter = filters.find(function (filter) {
    return filter.name === "Shape";
  });

  function isActive(shapeName) {
    const selectedValue = searchQuery.fields.find(function (field) {
      return field.name === "Shape";
    });
    if (selectedValue) {
      const bool = selectedValue.values.find(function (value) {
        return value.name === shapeName ? true : false;
      });
      return bool;
    }
  }

  function selectShape(shapeName) {
    if (matchedPairs) {
      return;
    }
    const searchField = searchQuery.fields.find(function (field) {
      return field.name === "Shape";
    });
    if (searchField) {
      const shapeFound = searchField.values.find(function (value) {
        return value.name === shapeName;
      });
      if (shapeFound) {
        const removeSelected = searchField.values.filter(
          (value) => value.name !== shapeName
        );
        searchField.values = removeSelected;
        meleeSearchDispatch({
          type: "updateSearchQuery",
          payload: searchQuery.fields,
        });
      } else {
        const newSelectedShapes = [...searchField.values, { name: shapeName }];
        searchField.values = newSelectedShapes;
        meleeSearchDispatch({
          type: "updateSearchQuery",
          payload: searchQuery.fields,
        });
      }
    } else {
      const newSearchFields = [
        ...searchQuery.fields,
        { name: "Shape", values: [{ name: shapeName }] },
      ];
      meleeSearchDispatch({
        type: "updateSearchQuery",
        payload: newSearchFields,
      });
    }
  }

  return (
    <div className="filter filter--shape">
      {shapeFilter.values.map((value) => (
        <div
          key={value.name}
          onClick={() => selectShape(value.name)}
          className="shape-value"
        >
          <div className="shape-icon pointer">
            <Shape
              type={
                value.name !== "Straight Baguette"
                  ? value.name
                  : "StraightBaguette"
              }
              color={
                isActive(value.name)
                  ? "black"
                  : matchedPairs
                  ? "#b2b2b2"
                  : "#4280aa"
              }
              stroke_width={isActive(value.name) ? "1%" : "0.4%"}
              width="40"
            />
          </div>
          <Typography
            variant="body3"
            className={[
              "pointer",
              "shape-label " +
                (isActive(value.name)
                  ? "shape-label--selected"
                  : matchedPairs
                  ? "shape-label--matched"
                  : ""),
            ].join(" ")}
          >
            {value.name}
          </Typography>
        </div>
      ))}
    </div>
  );
}

export default ShapeFilter;
