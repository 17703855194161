import React from "react";

const RadiantProfile = (props) => {
  const viewBoxWidth = 85.7;
  const viewBoxHeight = 86.1;

  const lineColor = "#4a4a4a";
  const strokeWidth2 = "0.5";

  const x = (percentX) => percentX * viewBoxWidth;
  const y = (percentY) => percentY * viewBoxHeight;

  const table = Number(props.diamond.Table).toFixed(0);
  const depthPercent = Number(props.diamond.Depth).toFixed(1);
  const girdle = props.diamond.Girdle || "";
  const girdleSpaceIndex = girdle.indexOf(" ");
  const girdle1 =
    girdleSpaceIndex > 0 ? girdle.slice(0, girdleSpaceIndex) : girdle;
  const girdle2 = girdleSpaceIndex > 0 ? girdle.slice(girdleSpaceIndex) : "";

  const diagramStrokeWidth = "0.01";
  const diagramFontSize = 0.046;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox={"0 0 " + viewBoxWidth + " " + viewBoxHeight}
      width={props.width}
      height={props.height}
      preserveAspectRatio={props.preserveAspectRatio}
    >
      <line
        fill="none"
        stroke={props.color}
        strokeWidth={props.stroke_width}
        strokeMiterlimit="10"
        x1="16.5"
        y1="27.2"
        x2="8.6"
        y2="38"
      />
      <line
        fill="none"
        stroke={props.color}
        strokeWidth={props.stroke_width}
        strokeMiterlimit="10"
        x1="70"
        y1="27.3"
        x2="78"
        y2="38"
      />
      <line
        fill="none"
        stroke={props.color}
        strokeWidth={props.stroke_width}
        strokeMiterlimit="10"
        x1="8.8"
        y1="39.8"
        x2="31.1"
        y2="61.7"
      />
      <line
        fill="none"
        stroke={props.color}
        strokeWidth={props.stroke_width}
        strokeMiterlimit="10"
        x1="78.2"
        y1="39.6"
        x2="55.6"
        y2="61.7"
      />
      <path
        fill="none"
        stroke={props.color}
        strokeWidth={props.stroke_width}
        strokeMiterlimit="10"
        d="M13.1,27.3c1.1-0.2,60.9,0,60.9,0l5.3,4.4l3.1,3.8l2.2,2.7V40L78,46.9l-10.5,8.2l-11.8,6.5H31.1l-8.5-4.5
			l-3.5-2.2l-8.4-6.5l-4.9-4.9l-3.3-3.3V38c0,0,3.5-5.3,4-5.5c0.5-0.2,3.3-2.9,3.3-2.9L13.1,27.3z"
      />
      <line
        fill="none"
        stroke={props.color}
        strokeWidth={props.stroke_width}
        strokeMiterlimit="10"
        x1="8.6"
        y1="39.5"
        x2="8.6"
        y2="38.3"
      />
      <line
        fill="none"
        stroke={props.color}
        strokeWidth={props.stroke_width}
        strokeMiterlimit="10"
        x1="78"
        y1="39.4"
        x2="78"
        y2="38.2"
      />
      <line
        fill="none"
        stroke={props.color}
        strokeWidth={props.stroke_width}
        strokeMiterlimit="10"
        x1="8.4"
        y1="30.9"
        x2="78.5"
        y2="30.9"
      />
      <line
        fill="none"
        stroke={props.color}
        strokeWidth={props.stroke_width}
        strokeMiterlimit="10"
        x1="4.8"
        y1="34.8"
        x2="81.8"
        y2="34.8"
      />
      <line
        fill="none"
        stroke={props.color}
        strokeWidth={props.stroke_width}
        strokeMiterlimit="10"
        x1="9"
        y1="46.5"
        x2="78"
        y2="46.6"
      />
      <line
        fill="none"
        stroke={props.color}
        strokeWidth={props.stroke_width}
        strokeMiterlimit="10"
        x1="20.1"
        y1="55.5"
        x2="66.8"
        y2="55.6"
      />
      <line
        fill="none"
        stroke={props.color}
        strokeWidth={props.stroke_width}
        strokeMiterlimit="10"
        x1="2.4"
        y1="39.7"
        x2="84.5"
        y2="39.7"
      />
      <line
        fill="none"
        stroke={props.color}
        strokeWidth={props.stroke_width}
        strokeMiterlimit="10"
        x1="2.7"
        y1="38"
        x2="84.5"
        y2="38"
      />
      {props.show_diagram && (
        <>
          {/* Table */}
          <line
            x1={x(0.12)}
            x2={x(0.89)}
            y1={y(0.28)}
            y2={y(0.28)}
            stroke={lineColor}
            strokeWidth={strokeWidth2}
          ></line>
          <line
            x1={x(0.12)}
            x2={x(0.12)}
            y1={y(0.26)}
            y2={y(0.3)}
            stroke={lineColor}
            strokeWidth={strokeWidth2}
          ></line>
          <line
            x1={x(0.89)}
            x2={x(0.89)}
            y1={y(0.26)}
            y2={y(0.3)}
            stroke={lineColor}
            strokeWidth={strokeWidth2}
          ></line>

          <text
            x={x(0.37)}
            y={y(0.26)}
            fontSize={y(diagramFontSize)}
            stroke={lineColor}
            strokeWidth={diagramStrokeWidth}
          >
            Table: {table}%
          </text>

          {/* Left Depth */}
          <line
            x1={x(0.06)}
            x2={x(0.06)}
            y1={y(0.31)}
            y2={y(0.74)}
            stroke={lineColor}
            strokeWidth={strokeWidth2}
          ></line>
          <line
            x1={x(0.04)}
            x2={x(0.08)}
            y1={y(0.31)}
            y2={y(0.31)}
            stroke={lineColor}
            strokeWidth={strokeWidth2}
          ></line>
          <line
            x1={x(0.04)}
            x2={x(0.08)}
            y1={y(0.74)}
            y2={y(0.74)}
            stroke={lineColor}
            strokeWidth={strokeWidth2}
          ></line>

          <rect
            x={x(0.005)}
            y={y(0.43)}
            width={x(0.22)}
            height={y(0.135)}
            rx="0.8"
            fill="white"
          ></rect>
          <text
            x={x(0.03)}
            y={y(0.475)}
            fontSize={y(diagramFontSize)}
            stroke={lineColor}
            strokeWidth={diagramStrokeWidth}
          >
            Depth:
          </text>
          <text
            x={x(0.02)}
            y={y(0.542)}
            fontSize={y(diagramFontSize)}
            stroke={lineColor}
            strokeWidth={diagramStrokeWidth}
          >
            {props.diamond.Measurement3}
          </text>

          {/* Right Depth */}
          <line
            x1={x(0.94)}
            x2={x(0.94)}
            y1={y(0.31)}
            y2={y(0.74)}
            stroke={lineColor}
            strokeWidth={strokeWidth2}
          ></line>
          <line
            x1={x(0.92)}
            x2={x(0.96)}
            y1={y(0.31)}
            y2={y(0.31)}
            stroke={lineColor}
            strokeWidth={strokeWidth2}
          ></line>
          <line
            x1={x(0.92)}
            x2={x(0.96)}
            y1={y(0.74)}
            y2={y(0.74)}
            stroke={lineColor}
            strokeWidth={strokeWidth2}
          ></line>

          <rect
            x={x(0.8)}
            y={y(0.43)}
            width={x(0.19)}
            height={y(0.135)}
            rx="0.8"
            fill="white"
          ></rect>
          <text
            x={x(0.81)}
            y={y(0.475)}
            fontSize={y(diagramFontSize)}
            stroke={lineColor}
            strokeWidth={diagramStrokeWidth}
          >
            Depth:
          </text>
          <text
            x={x(0.81)}
            y={y(0.542)}
            fontSize={y(diagramFontSize)}
            stroke={lineColor}
            strokeWidth={diagramStrokeWidth}
          >
            {depthPercent}%
          </text>

          {/* Culet */}
          {props.diamond.Culet.length > 0 && (
            <>
              <circle
                cx={x(0.5)}
                cy={y(0.72)}
                r={y(0.03)}
                fillOpacity="0"
                stroke={lineColor}
                strokeWidth={strokeWidth2}
              />
              <line
                x1={x(0.5)}
                x2={x(0.502)}
                y1={y(0.75)}
                y2={y(0.79)}
                stroke={lineColor}
                strokeWidth={strokeWidth2}
              ></line>
              <line
                x1={x(0.502)}
                x2={x(0.54)}
                y1={y(0.79)}
                y2={y(0.79)}
                stroke={lineColor}
                strokeWidth={strokeWidth2}
              ></line>

              <text
                x={x(0.55)}
                y={y(0.8)}
                fontSize={y(diagramFontSize)}
                stroke={lineColor}
                strokeWidth={diagramStrokeWidth}
              >
                Culet: {props.diamond.Culet}
              </text>
            </>
          )}
          {/* Girdle */}
          {girdle1.length > 0 && (
            <>
              <circle
                cx={x(0.4)}
                cy={y(0.45)}
                r={y(0.03)}
                fillOpacity="0"
                stroke={lineColor}
                strokeWidth={strokeWidth2}
              />
              <line
                x1={x(0.4)}
                x2={x(0.4)}
                y1={y(0.48)}
                y2={y(0.83)}
                stroke={lineColor}
                strokeWidth={strokeWidth2}
              ></line>
              <line
                x1={x(0.4)}
                x2={x(0.38)}
                y1={y(0.83)}
                y2={y(0.83)}
                stroke={lineColor}
                strokeWidth={strokeWidth2}
              ></line>

              <text
                x={x(0.0)}
                y={y(0.82)}
                fontSize={y(diagramFontSize)}
                stroke={lineColor}
                strokeWidth={diagramStrokeWidth}
              >
                Girdle: {girdle1}
              </text>
              <text
                x={x(0.0)}
                y={y(0.88)}
                fontSize={y(diagramFontSize)}
                stroke={lineColor}
                strokeWidth={diagramStrokeWidth}
              >
                {girdle2}
              </text>
            </>
          )}
        </>
      )}
    </svg>
  );
};

export default RadiantProfile;
