// Third-party libraries
import React, { useState, useEffect } from "react";
import { Typography, Slider } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  root: (props) => ({
    "& .MuiSlider-rail": {
      backgroundImage: `linear-gradient(to right, 
        #ffffff 0%, 
        ${props.color === "success" ? "#7fa04d" : "#124760"} 100% 
      )`,
      opacity: 1,
    },
    "& .MuiSlider-track": {
      background: "transparent",
    },
  }),
});

export default function RangeNominal({
  filter,
  resetFilters,
  updateNominalRange,
  color,
}) {
  const classes = useStyles({ color });
  const max = filter.values.length;

  // This stores state redundantly, we are using this to represent
  // current value on the slider handles.  These are out of sync with the
  // shared `search` state while the user is dragging one of the handles.
  const [sliderValue, setSliderValue] = useState([0, max]);

  // This, then, keeps this component's internal value in sync when it is
  // updated outside of this component.
  useEffect(() => {
    setSliderValue([0, max]);
  }, [filter.values, max, resetFilters]); //Needs to respond to change in state triggered by reset button.

  let marks = [];
  if (!!filter.values) {
    marks = filter.values.map((value, index) => ({
      value: index,
      label: value.name,
    }));
    marks.push({ value: filter.values.length, label: "" });
  }

  const handleChange = (e, data) => {
    setSliderValue(data);
  };

  const updateSearch = () => {
    updateNominalRange(filter.name, marks, sliderValue);
  };

  const markClicked = (m) => {
    new Promise((resolve, reject) => {
      setSliderValue([m.value, m.value + 1]);
      resolve();
    }).then(() => {
      updateNominalRange(filter.name, [m], sliderValue);
    });
  };

  return (
    <div>
      <Slider
        value={sliderValue}
        aria-labelledby="track-inverted-range-slider"
        marks={marks}
        onChangeCommitted={updateSearch}
        onChange={handleChange}
        min={0}
        max={max}
        classes={{
          root: filter.name === "FancyColorIntensity" ? classes.root : null,
        }}
        color={color}
        sx={{ marginBottom: "unset" }}
      />
      <div className="marks">
        {marks.map((m) => (
          <Typography
            variant="body3"
            className="mark"
            key={m.value}
            onClick={() => {
              markClicked(m);
            }}
          >
            {m.label}
          </Typography>
        ))}
      </div>
    </div>
  );
}
