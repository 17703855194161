// Third-party libraries
import React, { useContext, useState } from "react";

// Local modules
import { storage } from "../../utils";
import { DiamondSearchContext } from "../../utils/stateHandlers/contexts";
import AccordionDiamondFilter from "./AccordionDiamondFilter";
import DiamondFilter from "./DiamondFilter";
import useMediaQueryUtils from "../../utils/mediaQueryUtils";

export default function CertDiamondFilters() {
  const isMdScreen = useMediaQueryUtils("md");
  const { diamondState, dispatch } = useContext(DiamondSearchContext);
  const { searchQuery } = diamondState;
  const [localFcIntensity, setLocalFcIntensity] = useState(["None"]);
  const loggedIn = storage.getStorageItem("authToken");

  const valuesForColor = searchQuery.fields.find(function (field) {
    return field.name === "MinedOrLab";
  });

  const valueForColor = valuesForColor && valuesForColor.values[0].name;

  function updateFilters(filterName, filterValues) {
    const filterToUpdate = searchQuery.fields.find(function (field) {
      return field.name === filterName;
    });
    const rangeOrValues =
      filterName === "HooverPrice" ||
      filterName === "Carat" ||
      filterName === "LWRatio" ||
      filterName === "Table" ||
      filterName === "Depth"
        ? "range"
        : "values";
    if (filterName === "MinedOrLab") {
      clearFilters();
    }
    if (filterToUpdate) {
      if (rangeOrValues === "range") {
        filterToUpdate.range = filterValues;
        dispatch({ type: "updateSearchQuery", payload: searchQuery.fields });
      } else {
        filterToUpdate.values = filterValues;
        dispatch({ type: "updateSearchQuery", payload: searchQuery.fields });
      }
    } else {
      if (rangeOrValues === "range") {
        const fields = searchQuery.fields.concat({
          name: filterName,
          range: filterValues,
        });
        dispatch({ type: "updateSearchQuery", payload: fields });
      } else {
        const fields = searchQuery.fields.concat({
          name: filterName,
          values: filterValues,
        });
        dispatch({ type: "updateSearchQuery", payload: fields });
      }
    }
  }

  function clearFilters(minedOrLab) {
    dispatch({
      type: "clearFilters",
      payload: [minedOrLab],
    });
  }

  function updateCheckedBoxes(filterName, values) {
    if (
      filterName === "AsGrown" ||
      filterName === "ScsGrower" ||
      filterName === "ShipsSameDay" ||
      filterName === "ImageVideoAvailable"
    ) {
      if (values.checked === "N") {
        const newSearchQuery = searchQuery.fields.filter(function (queryField) {
          return queryField.name !== filterName;
        });
        dispatch({ type: "updateSearchQuery", payload: newSearchQuery });
      } else {
        updateFilters(filterName, [{ name: values.checked }]);
      }
    } else {
      const searchValues = values
        .filter(function (value) {
          return value.checked === true;
        })
        .map(function (value) {
          return { name: value.name };
        });
      updateFilters(filterName, searchValues);
    }
  }

  function updateRange(filterName, range) {
    updateFilters(filterName, range);
  }

  function updateNominalRange(filterName, marks, sliderValue) {
    const newArray = marks
      .filter(
        (m) => m.value + 0.1 > sliderValue[0] && m.value + 0.1 < sliderValue[1]
      )
      .map((m) => {
        return { name: m.label };
      });
    updateFilters(filterName, newArray);
  }

  function switchToFancyColors(color) {
    dispatch({
      type: "setShowFancyColorFilter",
      payload: color !== "White",
    });

    if (color !== "White") {
      updateFilters("Color", [{ name: "" }]);
    } else {
      const removeColorFilter = searchQuery.fields.find(function (field) {
        return field.name !== "Color";
      });
      dispatch({ type: "updateSearchQuery", payload: removeColorFilter });
      const minedOrLab = searchQuery.fields.find(
        (field) => field.name === "MinedOrLab"
      ).values[0].name;

      clearFilters({
        name: "MinedOrLab",
        values: [{ name: minedOrLab }],
      });
    }
  }
  return (
    <>
      {isMdScreen ? (
        <AccordionDiamondFilter
          updateCheckedBoxes={updateCheckedBoxes}
          localFcIntensity={localFcIntensity}
          setLocalFcIntensity={setLocalFcIntensity}
          switchToFancyColors={switchToFancyColors}
          updateNominalRange={updateNominalRange}
          valueForColor={valueForColor}
          updateRange={updateRange}
          loggedIn={loggedIn}
          clearFilters={clearFilters}
        />
      ) : (
        <DiamondFilter
          updateCheckedBoxes={updateCheckedBoxes}
          localFcIntensity={localFcIntensity}
          setLocalFcIntensity={setLocalFcIntensity}
          switchToFancyColors={switchToFancyColors}
          updateNominalRange={updateNominalRange}
          valueForColor={valueForColor}
          updateRange={updateRange}
          loggedIn={loggedIn}
          clearFilters={clearFilters}
        />
      )}
    </>
  );
}
