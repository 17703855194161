// Third-party libraries
import React from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

export default function Form({
  defaultValues,
  children,
  onSubmit,
  validationObject,
}) {
  const methods = useForm({ defaultValues });
  const schema = yup.object().shape(validationObject);
  const {
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {React.Children.map(children, (child) => {
        return child.props.name
          ? React.createElement(child.type, {
              ...{
                ...child.props,
                register: methods.register,
                key: child.props.name,
                errors: errors,
                field: child.props.field,
                setValue: methods.setValue,
              },
            })
          : child;
      })}
    </form>
  );
}
