// Third-party libraries
import React from "react";
import { useLocation } from "react-router-dom";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";

export default function GAButton(props) {
  const location = useLocation();
  //variant, color, size, sx, event name, onclick function through props
  function buttonClick(e) {
    window.gtag("event", props.gaEventName, {
      env: process.env.NODE_ENV,
      page_path: location.pathname + location.search + location.hash,
    });
    props.onClickFunction(e);
  }

  return (
    <React.Fragment>
      {props.loadingButton ? (
        <LoadingButton
          variant={props.variant}
          color={props.color}
          sx={props.styleInfo}
          onClick={() => buttonClick()}
          loading={props.loading}
          loadingIndicator={props.loadingIndicator}
          disabled={props.disabled}
        >
          {props.buttonText}
        </LoadingButton>
      ) : (
        <Button
          variant={props.variant}
          color={props.color}
          size={props.size}
          sx={props.styleInfo}
          onClick={(e) => {
            buttonClick(e);
          }}
        >
          {props.buttonText}
        </Button>
      )}
    </React.Fragment>
  );
}
