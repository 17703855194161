import React from "react";
import { Select, MenuItem, Grid } from "@mui/material";

const AuctionSelect = ({ tab, setTab }) => {
  const handleChange = (event) => {
    setTab(event.target.value);
  };

  return (
    <Grid container spacing={1} sx={{ marginTop: "1rem" }}>
      <Grid item xs={12}>
        <Select
          value={tab}
          onChange={handleChange}
          fullWidth
          variant="outlined"
          MenuProps={{
            disableScrollLock: true,
          }}
        >
          <MenuItem value="Main">General</MenuItem>
          <MenuItem value="Sell">Sell Diamonds</MenuItem>
          <MenuItem value="Buy">Buy Diamonds</MenuItem>
          <MenuItem value="myAuction">myAuction</MenuItem>
        </Select>
      </Grid>
    </Grid>
  );
};

export default AuctionSelect;
