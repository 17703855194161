// Built-in modules
import qs from "qs";

// Third-party libraries
import React, { useEffect, useState } from "react";
import { Link as DomLink } from "react-router-dom";
import {
  Backdrop,
  Divider,
  Stack,
  Typography,
  Container,
  CircularProgress,
} from "@mui/material";

// Local modules
import api from "../../../../utils/api";
import { constants } from "../../../../utils";
import LandingFooter from "../../../pages/static/LandingFooter";
import useMediaQueryUtils from "../../../../utils/mediaQueryUtils";
import AuctionSelect from "./AuctionSelect";
import AuctionChip from "./AuctionChip";
import General from "./tabs/General";
import Sell from "./tabs/Sell";
import Buy from "./tabs/Buy";
import MyAuction from "./tabs/MyAuction";

export default function AuctionHomePage(props) {
  const isMdScreen = useMediaQueryUtils("md");
  const initialTab =
    props.match.params.Tab === "sell"
      ? "Sell"
      : props.match.params.Tab === "buy"
      ? "Buy"
      : props.match.params.Tab === "myauction"
      ? "myAuction"
      : "Main";
  const [isLoaded, setIsLoaded] = useState(false);
  const [tab, setTab] = useState(initialTab);
  const [Banner, setBanner] = useState();
  const [UpcomingAuctions, setUpcomingAuctions] = useState();
  const [MainTop, setMainTop] = useState();
  const [HowItWorksImage, setHowItWorksImage] = useState();
  const [AuctionVideos, setAuctionVideos] = useState();
  const [MainSellText, setMainSellText] = useState();
  const [MainBuyText, setMainBuyText] = useState();
  const [MyAuctionPlugIn, setMyAuctionPlugIn] = useState();
  const [SellImageTextTop, setSellImageTextTop] = useState();
  const [SellImageTextMiddle, setSellImageTextMiddle] = useState();
  const [SellImageTextFAQ, setSellImageTextFAQ] = useState();
  const [BuyImageTextTop, setBuyImageTextTop] = useState();
  const [BuyImageTextMiddle, setBuyImageTextMiddle] = useState();
  const [BuyImageTextFAQ, setBuyImageTextFAQ] = useState();
  const [myAuctionTextTop, setmyAuctionTextTop] = useState();
  const [myAuctionHowItWorksImage, setmyAuctionHowItWorksImage] = useState();
  const [myAuctionFAQ, setmyAuctionFAQ] = useState();
  const [GreenRectangle, setGreenRectangle] = useState();
  const [FooterIcons, setFooterIcons] = useState();
  const strapiURL = constants.CMS_FRONT_IMAGE_URL;

  useEffect(() => {
    window.scrollTo(0, 0);
    const query = qs.stringify({
      populate: {
        Banner: {
          populate: "*",
        },
        UpcomingAuctions: {
          populate: "*",
        },
        MainTop: {
          populate: "*",
        },
        HowItWorksImage: {
          populate: "*",
        },
        MainSellText: {
          populate: "*",
        },
        MainBuyText: {
          populate: "*",
        },
        MyAuctionPlugIn: {
          populate: "*",
        },
        SellImageTextTop: {
          populate: "*",
        },
        SellImageTextMiddle: {
          populate: "*",
        },
        SellImageTextFAQ: {
          populate: "*",
        },
        BuyImageTextTop: {
          populate: "*",
        },
        BuyImageTextMiddle: {
          populate: "*",
        },
        BuyImageTextFAQ: {
          populate: "*",
        },
        myAuctionTextTop: {
          populate: "*",
        },
        myAuctionHowItWorksImage: {
          populate: "*",
        },
        myAuctionFAQ: {
          populate: "*",
        },
        GreenRectangle: {
          populate: "*",
        },
        FooterIcons: {
          populate: "*",
        },
        AuctionVideos: {
          populate: "*",
        },
      },
    });
    api.fetchStrapi(`/diamond-auction?${query}`).then((response) => {
      setBanner(response.data.data.attributes.Banner.data.attributes);
      setUpcomingAuctions(response.data.data.attributes.UpcomingAuctions);
      setMainTop(response.data.data.attributes.MainTop);
      setHowItWorksImage(
        response.data.data.attributes.HowItWorksImage.data.attributes
      );
      setMainSellText(response.data.data.attributes.MainSellText);
      setMainBuyText(response.data.data.attributes.MainBuyText);
      setMyAuctionPlugIn(response.data.data.attributes.MyAuctionPlugIn);
      setSellImageTextTop(response.data.data.attributes.SellImageTextTop);
      setSellImageTextMiddle(response.data.data.attributes.SellImageTextMiddle);
      setSellImageTextFAQ(response.data.data.attributes.SellImageTextFAQ);
      setBuyImageTextTop(response.data.data.attributes.BuyImageTextTop);
      setBuyImageTextMiddle(response.data.data.attributes.BuyImageTextMiddle);
      setBuyImageTextFAQ(response.data.data.attributes.BuyImageTextFAQ);
      setmyAuctionTextTop(response.data.data.attributes.myAuctionTextTop);
      setmyAuctionHowItWorksImage(
        response.data.data.attributes.myAuctionHowItWorksImage.data.attributes
      );
      setmyAuctionFAQ(response.data.data.attributes.myAuctionFAQ);
      setGreenRectangle(response.data.data.attributes.GreenRectangle);
      setFooterIcons(response.data.data.attributes.FooterIcons);
      setIsLoaded(true);
      setAuctionVideos(response.data.data.attributes.AuctionVideos);
    });
  }, []);
  return (
    <>
      {!isLoaded ? (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={true}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : (
        <Container maxWidth="xl" className="careers-text">
          <Stack spacing={3} alignItems="center">
            {Banner && tab === "Main" && (
              <img
                src={`${strapiURL}${Banner.url}`}
                width="100%"
                alt="banner"
              />
            )}
            {isMdScreen ? (
              <AuctionSelect tab={tab} setTab={setTab} />
            ) : (
              <AuctionChip tab={tab} setTab={setTab} />
            )}
            {tab === "Main" && (
              <General
                AuctionVideos={AuctionVideos}
                UpcomingAuctions={UpcomingAuctions}
                MainTop={MainTop}
                HowItWorksImage={HowItWorksImage}
                MainBuyText={MainBuyText}
                MainSellText={MainSellText}
                MyAuctionPlugIn={MyAuctionPlugIn}
                setTab={setTab}
              />
            )}
            {tab === "Sell" && (
              <Sell
                SellImageTextFAQ={SellImageTextFAQ}
                SellImageTextMiddle={SellImageTextMiddle}
                UpcomingAuctions={UpcomingAuctions}
                SellImageTextTop={SellImageTextTop}
              />
            )}
            {tab === "Buy" && (
              <Buy
                BuyImageTextFAQ={BuyImageTextFAQ}
                BuyImageTextMiddle={BuyImageTextMiddle}
                BuyImageTextTop={BuyImageTextTop}
                UpcomingAuctions={UpcomingAuctions}
              />
            )}
            {tab === "myAuction" && (
              <MyAuction
                myAuctionFaqValue={myAuctionFAQ}
                myAuctionHowItWorksImageValue={myAuctionHowItWorksImage}
                myAuctionTextTopValue={myAuctionTextTop}
              />
            )}
            <Divider flexItem />
            <Stack spacing={1} alignItems="center" justifyContent="center">
              <Typography variant="articleTitle">Questions?</Typography>

              <DomLink
                to={{
                  pathname: "/faq/Frequently-Asked-Questions",
                }}
                className="center"
              >
                <Typography
                  variant="diamondTabFont"
                  sx={{
                    textDecoration: "underline",
                  }}
                >
                  CLICK HERE
                </Typography>
              </DomLink>
              <Typography variant="diamondTabFont" textAlign={"center"}>
                for our frequently asked questions or contact one of our
                specialists: <br />
                auction@hooverandstrong.com <br />
                1-800-759-9997 ext. 485
              </Typography>
            </Stack>
            <Divider flexItem />
            <Typography
              variant={isMdScreen ? "mobileGreenRectangle" : "greenRectangle"}
              align="center"
            >
              {GreenRectangle.text}
            </Typography>
            <LandingFooter icons={FooterIcons} />
          </Stack>
        </Container>
      )}
    </>
  );
}
