import React from "react";

const EmeraldProfile = (props) => {
  const viewBoxWidth = 85.7;
  const viewBoxHeight = 86.1;

  const lineColor = "#4a4a4a";
  const strokeWidth2 = "0.5";

  const x = (percentX) => percentX * viewBoxWidth;
  const y = (percentY) => percentY * viewBoxHeight;

  const table = Number(props.diamond.Table).toFixed(0);
  const depthPercent = Number(props.diamond.Depth).toFixed(1);
  const girdle = props.diamond.Girdle || "";
  const girdleSpaceIndex = girdle.indexOf(" ");
  const girdle1 =
    girdleSpaceIndex > 0 ? girdle.slice(0, girdleSpaceIndex) : girdle;
  const girdle2 = girdleSpaceIndex > 0 ? girdle.slice(girdleSpaceIndex) : "";

  const diagramStrokeWidth = "0.01";
  const diagramFontSize = 0.046;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox={"0 0 " + viewBoxWidth + " " + viewBoxHeight}
      width={props.width}
      height={props.height}
      preserveAspectRatio={props.preserveAspectRatio}
    >
      <g id="Layer_1">
        <line
          fill="none"
          stroke={props.color}
          strokeWidth={props.stroke_width}
          strokeMiterlimit="10"
          x1="16"
          y1="25.4"
          x2="8.1"
          y2="36.2"
        />
        <line
          fill="none"
          stroke={props.color}
          strokeWidth={props.stroke_width}
          strokeMiterlimit="10"
          x1="69.4"
          y1="25.5"
          x2="77.4"
          y2="36.3"
        />
        <line
          fill="none"
          stroke={props.color}
          strokeWidth={props.stroke_width}
          strokeMiterlimit="10"
          x1="8.2"
          y1="38"
          x2="30.5"
          y2="59.9"
        />
        <line
          fill="none"
          stroke={props.color}
          strokeWidth={props.stroke_width}
          strokeMiterlimit="10"
          x1="77.6"
          y1="37.9"
          x2="55.1"
          y2="59.9"
        />
      </g>
      <g id="Layer_2">
        <path
          fill="none"
          stroke={props.color}
          strokeWidth={props.stroke_width}
          strokeMiterlimit="10"
          d="M12.5,25.5c1.1-0.2,60.9,0,60.9,0l5.3,4.4l3.1,3.8l2.2,2.7v1.8l-6.5,6.9l-10.5,8.2l-11.8,6.5H30.5L22,55.3
		l-3.5-2.2l-8.4-6.5l-4.9-4.9L2,38.4v-2.2c0,0,3.5-5.3,4-5.5s3.3-2.9,3.3-2.9L12.5,25.5z"
        />
        <line
          fill="none"
          stroke={props.color}
          strokeWidth={props.stroke_width}
          strokeMiterlimit="10"
          x1="8"
          y1="37.7"
          x2="8"
          y2="36.5"
        />
        <line
          fill="none"
          stroke={props.color}
          strokeWidth={props.stroke_width}
          strokeMiterlimit="10"
          x1="77.4"
          y1="37.7"
          x2="77.4"
          y2="36.5"
        />
      </g>
      <g id="Layer_3">
        <line
          fill="none"
          stroke={props.color}
          strokeWidth={props.stroke_width}
          strokeMiterlimit="10"
          x1="7.9"
          y1="29.2"
          x2="77.9"
          y2="29.2"
        />
        <line
          fill="none"
          stroke={props.color}
          strokeWidth={props.stroke_width}
          strokeMiterlimit="10"
          x1="4.3"
          y1="33"
          x2="81.3"
          y2="33"
        />
        <line
          fill="none"
          stroke={props.color}
          strokeWidth={props.stroke_width}
          strokeMiterlimit="10"
          x1="8.4"
          y1="44.7"
          x2="77.4"
          y2="44.8"
        />
        <line
          fill="none"
          stroke={props.color}
          strokeWidth={props.stroke_width}
          strokeMiterlimit="10"
          x1="19.6"
          y1="53.7"
          x2="66.2"
          y2="53.8"
        />
        <line
          fill="none"
          stroke={props.color}
          strokeWidth={props.stroke_width}
          strokeMiterlimit="10"
          x1="1.9"
          y1="37.9"
          x2="84"
          y2="37.9"
        />
        <line
          fill="none"
          stroke={props.color}
          strokeWidth={props.stroke_width}
          strokeMiterlimit="10"
          x1="2.2"
          y1="36.3"
          x2="84"
          y2="36.3"
        />
      </g>
      {props.show_diagram && (
        <>
          {/* Table */}
          <line
            x1={x(0.1)}
            x2={x(0.9)}
            y1={y(0.25)}
            y2={y(0.25)}
            stroke={lineColor}
            strokeWidth={strokeWidth2}
          ></line>
          <line
            x1={x(0.1)}
            x2={x(0.1)}
            y1={y(0.23)}
            y2={y(0.27)}
            stroke={lineColor}
            strokeWidth={strokeWidth2}
          ></line>
          <line
            x1={x(0.9)}
            x2={x(0.9)}
            y1={y(0.23)}
            y2={y(0.27)}
            stroke={lineColor}
            strokeWidth={strokeWidth2}
          ></line>

          <text
            x={x(0.37)}
            y={y(0.23)}
            fontSize={y(diagramFontSize)}
            stroke={lineColor}
            strokeWidth={diagramStrokeWidth}
          >
            Table: {table}%
          </text>

          {/* Left Depth */}
          <line
            x1={x(0.06)}
            x2={x(0.06)}
            y1={y(0.3)}
            y2={y(0.73)}
            stroke={lineColor}
            strokeWidth={strokeWidth2}
          ></line>
          <line
            x1={x(0.04)}
            x2={x(0.08)}
            y1={y(0.3)}
            y2={y(0.3)}
            stroke={lineColor}
            strokeWidth={strokeWidth2}
          ></line>
          <line
            x1={x(0.04)}
            x2={x(0.08)}
            y1={y(0.73)}
            y2={y(0.73)}
            stroke={lineColor}
            strokeWidth={strokeWidth2}
          ></line>

          <rect
            x={x(0.005)}
            y={y(0.43)}
            width={x(0.22)}
            height={y(0.135)}
            rx="0.8"
            fill="white"
          ></rect>
          <text
            x={x(0.03)}
            y={y(0.475)}
            fontSize={y(diagramFontSize)}
            stroke={lineColor}
            strokeWidth={diagramStrokeWidth}
          >
            Depth:
          </text>
          <text
            x={x(0.02)}
            y={y(0.542)}
            fontSize={y(diagramFontSize)}
            stroke={lineColor}
            strokeWidth={diagramStrokeWidth}
          >
            {props.diamond.Measurement3}
          </text>

          {/* Right Depth */}
          <line
            x1={x(0.94)}
            x2={x(0.94)}
            y1={y(0.3)}
            y2={y(0.73)}
            stroke={lineColor}
            strokeWidth={strokeWidth2}
          ></line>
          <line
            x1={x(0.92)}
            x2={x(0.96)}
            y1={y(0.3)}
            y2={y(0.3)}
            stroke={lineColor}
            strokeWidth={strokeWidth2}
          ></line>
          <line
            x1={x(0.92)}
            x2={x(0.96)}
            y1={y(0.73)}
            y2={y(0.73)}
            stroke={lineColor}
            strokeWidth={strokeWidth2}
          ></line>

          <rect
            x={x(0.8)}
            y={y(0.43)}
            width={x(0.19)}
            height={y(0.135)}
            rx="0.8"
            fill="white"
          ></rect>
          <text
            x={x(0.81)}
            y={y(0.475)}
            fontSize={y(diagramFontSize)}
            stroke={lineColor}
            strokeWidth={diagramStrokeWidth}
          >
            Depth:
          </text>
          <text
            x={x(0.81)}
            y={y(0.542)}
            fontSize={y(diagramFontSize)}
            stroke={lineColor}
            strokeWidth={diagramStrokeWidth}
          >
            {depthPercent}%
          </text>

          {/* Culet */}
          <circle
            cx={x(0.5)}
            cy={y(0.7)}
            r={y(0.03)}
            fillOpacity="0"
            stroke={lineColor}
            strokeWidth={strokeWidth2}
          />
          <line
            x1={x(0.5)}
            x2={x(0.502)}
            y1={y(0.73)}
            y2={y(0.79)}
            stroke={lineColor}
            strokeWidth={strokeWidth2}
          ></line>
          <line
            x1={x(0.502)}
            x2={x(0.54)}
            y1={y(0.79)}
            y2={y(0.79)}
            stroke={lineColor}
            strokeWidth={strokeWidth2}
          ></line>

          <text
            x={x(0.55)}
            y={y(0.8)}
            fontSize={y(diagramFontSize)}
            stroke={lineColor}
            strokeWidth={diagramStrokeWidth}
          >
            Culet: {props.diamond.Culet}
          </text>

          {/* Girdle */}
          {girdle1.length > 0 && (
            <>
              <circle
                cx={x(0.4)}
                cy={y(0.42)}
                r={y(0.03)}
                fillOpacity="0"
                stroke={lineColor}
                strokeWidth={strokeWidth2}
              />
              <line
                x1={x(0.4)}
                x2={x(0.4)}
                y1={y(0.455)}
                y2={y(0.83)}
                stroke={lineColor}
                strokeWidth={strokeWidth2}
              ></line>
              <line
                x1={x(0.4)}
                x2={x(0.38)}
                y1={y(0.83)}
                y2={y(0.83)}
                stroke={lineColor}
                strokeWidth={strokeWidth2}
              ></line>

              <text
                x={x(0.0)}
                y={y(0.82)}
                fontSize={y(diagramFontSize)}
                stroke={lineColor}
                strokeWidth={diagramStrokeWidth}
              >
                Girdle: {girdle1}
              </text>
              <text
                x={x(0.0)}
                y={y(0.88)}
                fontSize={y(diagramFontSize)}
                stroke={lineColor}
                strokeWidth={diagramStrokeWidth}
              >
                {girdle2}
              </text>
            </>
          )}
        </>
      )}
    </svg>
  );
};

export default EmeraldProfile;
