const PageRoutes = [
  /* --- USER ---*/
  {
    title: "account",
    banner: "",
    url: "/account",
    subtitle: "View your account",
    keywords: ["login", "signin", "log in", " sign in", "new account"],
  },
  {
    title: "login",
    banner: "",
    url: "/login",
    subtitle: "Login to your account",
    keywords: ["login", "signin", "log in", " sign in", "new account"],
  },
  {
    title: "register",
    banner: "",
    url: "/create-account",
    subtitle: "Register for an account",
    keywords: ["register", "signup", "new account"],
  },
  /* --- DIAMOND ---*/
  {
    title: "harmony diamond auctions",
    banner: "",
    url: "/harmony-diamond-auctions",
    subtitle:
      "Our cutting-edge online auction platform connects motivated diamond sellers with qualified buyers all over the world! Combining ground breaking technology and extensive expertise provides you with the best way to buy and sell recycled diamonds in the marketplace.",
    keywords: ["harmony diamond auctions"],
  },
  {
    title: "diamond services",
    banner: "",
    subtitle:
      "We will buy your Diamonds and Precious Metals and we’ll offer the BEST PRICES! No matter what the quality or the quantity! We buy breakouts, melees, all stone sizes, any shape, quality, chipped or broken. AND, we will pay the highest prices for your stones.",
    url: "/diamond-services",
    keywords: ["diamond services"],
  },
  /* --- FINISHED JEWELRY ---*/
  {
    title: "finished diamond jewelry",
    banner: "",
    subtitle:
      "Discover our newest finished jewelry collection, set with high quality lab-grown diamonds, made with 100% recycled Harmony Metals and competitively priced for maximum profit! Collection includes Lab-Grown Diamond Earrings, Pendants, Bands and Tennis Bracelets.",
    url: "/finished-diamond-jewelry",
    keywords: ["finished diamond jewelry"],
  },
  /* --- CONTRACT CASTING ---*/
  {
    title: "casting calculator",
    banner: "",
    subtitle:
      "Online calculator is only an estimate for your work piece for standard simple jewelry items, such as simple rings, simple earrings and simple pendants. Final pricing of the actual piece may vary based on design difficultly, actual weight and other finishing options.",
    url: "/casting-calculator",
    keywords: ["casting calculator"],
  },
  /* --- REFINING ---*/
  {
    title: "refining calculator",
    banner: "",
    subtitle:
      "This is an estimate only based on the information provided and can change subject to refining, assay and/or assessment of actual materials received.",
    url: "/refining-calculator",
    keywords: ["refining calculator"],
  },
];

export default PageRoutes;
