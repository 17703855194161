// Built-in modules
import qs from "qs";

// Third-party libraries
import React, { useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Container,
  Stack,
  Typography,
} from "@mui/material";

// Local modules
import api from "../../../utils/api";

export default function Faq(props) {
  const [categories, setCategories] = useState();
  const [title, setTitle] = useState();
  const [text, setText] = useState();

  const faqType = props.match.params.PageType === "Website-Help" ? 2 : 1;

  const query = qs.stringify({
    populate: {
      categories: {
        populate: "*",
      },
    },
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    api.fetchStrapi(`/faq-pages/${faqType}?${query}`).then((response) => {
      setCategories(response.data.data.attributes.categories);
      setTitle(response.data.data.attributes.title);
      setText(response.data.data.attributes.text);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.match.params.PageType]);

  return (
    <Container>
      <Stack spacing={2} sx={{ marginBottom: "2rem" }}>
        <Typography variant="articleTitle" align="center">
          {title}
        </Typography>
        <Typography variant="articleText" align="center">
          <ReactMarkdown children={text} remarkPlugins={[remarkGfm]} />
        </Typography>
      </Stack>
      {categories &&
        categories.map((category) => (
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              sx={{ backgroundColor: "#cfdde9" }}
            >
              <Typography variant="accordianTitle">
                {category.category}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Stack spacing={2}>
                {category.questions.map((question) => (
                  <Stack>
                    <Typography
                      variant="articleText"
                      sx={{ fontWeight: "800" }}
                    >
                      {`${question.subcategory} - ${question.question}`}
                    </Typography>
                    <Typography variant="articleText">
                      <ReactMarkdown
                        children={question.answer}
                        remarkPlugins={[remarkGfm]}
                      />
                    </Typography>
                  </Stack>
                ))}
              </Stack>
            </AccordionDetails>
          </Accordion>
        ))}
    </Container>
  );
}
