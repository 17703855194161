// Third-party libraries
import React, { useEffect } from "react";
import JotformEmbed from "react-jotform-embed";
import CloseIcon from "@mui/icons-material/Close";
import {
  IconButton,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";

export default function Modal(props) {
  useEffect(() => {
    const closeOnEscapeKeyDown = (e) => {
      if ((e.charCode || e.keyCode) === 27) {
        props.onClose();
      }
    };
    document.body.addEventListener("keydown", closeOnEscapeKeyDown);
    return function cleanup() {
      document.body.removeEventListener("keydown", closeOnEscapeKeyDown);
    };
  }, [props]);

  return (
    <Dialog
      onClose={props.onClose}
      onBackdropClick={props.onClose}
      aria-labelledby="customized-dialog-title"
      open={props.show}
      maxWidth={props.title === "Contact Us" ? "xl" : "md"}
    >
      <DialogTitle
        id="customized-dialog-title"
        onClose={props.onClose}
        sx={{ fontSize: "1.6rem", fontWeight: "bold" }}
      >
        {props.title}
        <IconButton
          aria-label="close"
          onClick={props.onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[800],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        {props.title === "Contact Us" ? (
          <div className="contact-us-jot-form">
            <JotformEmbed
              src="https://form.jotform.com/211792115518454"
              scrolling={true}
            />
          </div>
        ) : props.title === "Loose Diamond Memo Policy:" ? (
          <List dense={true} disablePadding>
            <ListItem>
              <ListItemText
                primary={`• Any single diamond priced at over $500 can be ordered on
                consignment for review ("on memo") in the US only.`}
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={`• Customers are limited to not more than three diamonds on memo at
                any given time.`}
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={`• Diamonds will be shipped on memo only upon request and subject
                to prior approval of the credit department. A credit card
                deposit may be required and will be at the sole discretion of
                Hoover & Strong.`}
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={`• Diamonds on memo will not be shipped with the original
                certificate.`}
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={`• Diamonds on memo must be shipped back within 5 business days of
                receipt. Diamonds must be shipped overnight or second day, in a
                box marked "attention department 291".`}
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={`• Diamonds not received back within 7 business days will be billed
                to the customer and the original certificate will be sent to the
                customer.`}
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={`• The customer is responsible for all shipping and
                handling charges.`}
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={`• The acceptance of a return of a memo diamond after 7 business
                days and the fees involved will be at the sole discretion of
                Hoover & Strong.`}
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={`• A $25.00 minimum charge will be applied to any account not
                returning diamonds on memo in their original packaging.`}
              />
            </ListItem>
          </List>
        ) : (
          <Typography gutterBottom sx={{ fontSize: "1.4rem" }}>
            {props.children}
          </Typography>
        )}
      </DialogContent>
    </Dialog>
  );
}
