// Built-in modules
import qs from "qs";

// Third-party libraries
import React, { useEffect, useState } from "react";
import { Link as DomLink } from "react-router-dom";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import {
  Button,
  Container,
  Card,
  CardMedia,
  Typography,
  Stack,
  Backdrop,
  CircularProgress,
} from "@mui/material";

// Local modules
import api from "../../../utils/api";
import { constants } from "../../../utils";
import Carousel from "./Carousel";
import LandingFooter from "./LandingFooter";
import RightCopyBox from "./styles/RightCopyBox";
import LeftCopyBox from "./styles/LeftCopyBox";
import useMediaQueryUtils from "../../../utils/mediaQueryUtils";

const frontImageUrl = constants.CMS_FRONT_IMAGE_URL;

export default function HomeContent(props) {
  const [isLoaded, setIsLoaded] = useState(false);
  const [carousel, setCarousel] = useState();
  const [topgreen, setTopGreen] = useState();
  const [threeimages, setThreeImages] = useState();
  const [buttontextblock, setButtonTextBlock] = useState();
  const [imageblock, setImageBlock] = useState();
  const [bottomgreen, setBottomGreen] = useState();
  const [icons, setIcons] = useState();
  const isMdScreen = useMediaQueryUtils("md");

  useEffect(() => {
    window.scrollTo(0, 0);
    const query = qs.stringify({
      populate: {
        Carousel: {
          populate: "*",
        },
        topgreen: {
          populate: "*",
        },
        threeimages: {
          populate: "*",
        },
        buttontextblock: {
          populate: "*",
        },
        imageblock: {
          populate: "*",
        },
        bottomgreen: {
          populate: "*",
        },
        icons: {
          populate: "*",
        },
      },
    });
    api.fetchStrapi(`/home-page?${query}`).then((response) => {
      setCarousel(response.data.data.attributes.Carousel.data);
      setTopGreen(response.data.data.attributes.topgreen);
      setThreeImages(response.data.data.attributes.threeimages);
      setButtonTextBlock(response.data.data.attributes.buttontextblock);
      setImageBlock(response.data.data.attributes.imageblock);
      setBottomGreen(response.data.data.attributes.bottomgreen);
      setIcons(response.data.data.attributes.icons);
      setIsLoaded(true);
    });
  }, []);

  return (
    <>
      {!isLoaded ? (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={true}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : (
        <Container maxWidth="xl" className="careers-text">
          {carousel &&
            topgreen &&
            threeimages &&
            buttontextblock &&
            imageblock &&
            bottomgreen && (
              <Stack spacing={3} alignItems="center">
                <Carousel images={carousel} />
                <Typography variant="greenRectangle" align="center">
                  {topgreen.text}
                </Typography>
                {threeimages.map((threeimages) => (
                  <Stack
                    direction={isMdScreen ? "column" : "row"}
                    key={threeimages.id}
                    spacing={2}
                  >
                    <DomLink
                      to={{
                        pathname: `${threeimages.image1.data.attributes.alternativeText}`,
                      }}
                    >
                      <Card
                        className="pointer"
                        sx={{ backgroundColor: "#e3e3e3" }}
                      >
                        <CardMedia
                          component="img"
                          image={`${frontImageUrl}${threeimages.image1.data.attributes.url}`}
                        />
                        <Typography
                          variant="imageCaption"
                          align="center"
                          className="center"
                        >
                          {threeimages.image1.data.attributes.caption}
                        </Typography>
                      </Card>
                    </DomLink>
                    <DomLink
                      to={{
                        pathname: `${threeimages.image2.data.attributes.alternativeText}`,
                      }}
                    >
                      <Card
                        className="pointer"
                        sx={{ backgroundColor: "#e3e3e3" }}
                      >
                        <CardMedia
                          component="img"
                          image={`${frontImageUrl}${threeimages.image2.data.attributes.url}`}
                        />
                        <Typography variant="imageCaption" className="center">
                          {threeimages.image2.data.attributes.caption}
                        </Typography>
                      </Card>
                    </DomLink>
                    <DomLink
                      to={{
                        pathname: `${threeimages.image3.data.attributes.alternativeText}`,
                      }}
                    >
                      <Card
                        className="pointer"
                        sx={{ backgroundColor: "#e3e3e3" }}
                      >
                        <CardMedia
                          component="img"
                          image={`${frontImageUrl}${threeimages.image3.data.attributes.url}`}
                        />
                        <Typography variant="imageCaption" className="center">
                          {threeimages.image3.data.attributes.caption}
                        </Typography>
                      </Card>
                    </DomLink>
                  </Stack>
                ))}
                <Stack
                  spacing={3}
                  sx={
                    buttontextblock.background === "Blue"
                      ? {
                          backgroundColor: "#cfdde9",
                          width: "100%",
                          paddingBottom: "2rem",
                        }
                      : {
                          backgroundColor: "inherit",
                          width: "100%",
                          paddingBottom: "2rem",
                        }
                  }
                >
                  {buttontextblock.optionalbannerimage && (
                    <img
                      src={`${frontImageUrl}${buttontextblock.optionalbannerimage.data.attributes.formats.large.url}`}
                      alt={buttontextblock.title}
                    />
                  )}
                  <Typography variant="articleTitle" align="center">
                    {buttontextblock.title}
                  </Typography>
                  <Typography
                    variant="staticText"
                    align="center"
                    sx={{ paddingLeft: "3rem", paddingRight: "3rem" }}
                  >
                    <ReactMarkdown remarkPlugins={[remarkGfm]}>
                      {buttontextblock.body}
                    </ReactMarkdown>
                  </Typography>
                  <a href={`${buttontextblock.button.to}`} className="center">
                    <Button variant="contained">
                      {buttontextblock.button.text}
                    </Button>
                  </a>
                </Stack>
                {imageblock.map((copyBox) => (
                  <React.Fragment key={copyBox.id}>
                    {copyBox.alignment === "left"
                      ? LeftCopyBox(copyBox)
                      : RightCopyBox(copyBox)}
                  </React.Fragment>
                ))}
                <Typography variant="greenRectangle" align="center">
                  {bottomgreen.text}
                </Typography>
                <LandingFooter icons={icons} />
              </Stack>
            )}
        </Container>
      )}
    </>
  );
}
