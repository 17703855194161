// Third-party libraries
import React, { useEffect } from "react";
import Container from "@mui/material/Container";

// Local modules
import { Mobile, Desktop } from "../../../../utils/deviceHelpers";

export default function AuctionsPage(props) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Container maxWidth="xl">
      <Mobile>
        <iframe
          src="https://auction.hooverandstrong.com/buy/customer/auction/auction-buyer.php"
          width="100%"
          height="900px"
          title="mobile"
        />
      </Mobile>
      <Desktop>
        <iframe
          src="https://auction.hooverandstrong.com/buy/customer/auction/auction-buyer.php"
          width="100%"
          height="900px"
          title="default"
        />
      </Desktop>
    </Container>
  );
}
