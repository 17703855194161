const stackBtnSx = {
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-around",
};

const customStackSx = {
  padding: "2rem",
  width: "100%",
  height: "67rem",
  border: "3px solid #D2B48C",
  justifyContent: "space-between",
  alignItems: "center",
};

const stackPageSx = {
  padding: "3rem",
  width: "100%",
  alignItems: "center",
  justifyContent: "center",
};

const boxGridSx = {
  display: "grid",
  gridTemplateColumns: {
    sm: "repeat(1, 1fr)",
    md: "repeat(2, 1fr)",
    lg: "repeat(2, 1fr)",
    xl: "repeat(2, 1fr)",
  },
  gridColumnGap: "3rem",
};

export { customStackSx, stackPageSx, boxGridSx, stackBtnSx };
