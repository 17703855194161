// Third-party libraries
import React, { useContext, useState } from "react";
import { Link as DomLink } from "react-router-dom";
import * as yup from "yup";
import toast from "react-hot-toast";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Button,
  Typography,
  TextField,
  Stack,
  Dialog,
  DialogContent,
  DialogTitle,
} from "@mui/material";

// Local modules
import {
  AppContext,
  ProductsContext,
} from "../../utils/stateHandlers/contexts";
import api from "../../utils/api";

const schema = yup.object().shape({
  email: yup
    .string()
    .test("email-not-entered", "Email is required.", (value) => value !== "")
    .required("Email is required."),
  password: yup
    .string()
    .test(
      "password-not-entered",
      "Password is required.",
      (value) => value !== ""
    )
    .required("Password is required."),
});

export default function LoginModal(props) {
  const { user } = useContext(AppContext);
  const { productState, productDispatch } = useContext(ProductsContext);
  const { openLoginModal } = productState;
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const handleClose = () => {
    productDispatch({ type: "setOpenLoginModal", payload: false });
  };

  function login() {
    const credentials = {
      username: email,
      password: password,
    };
    api.post("account/login", credentials).then((res) => {
      if (res.isAxiosError) {
        const errorMessage = [];
        if (res.response.data) {
          const value = Object.values(res.response.data)[0];
          value.forEach((error) => {
            errorMessage.push(error);
          });
        }
        toast.error(
          `There was an error processing your request. ${errorMessage.join(
            ", "
          )}`
        );
      } else {
        user.signIn(res.data, res.data.token);
        //handleClose();
      }
    });
  }

  return (
    <Dialog
      open={openLoginModal}
      onClose={handleClose}
      maxWidth="sm"
      fullWidth={true}
    >
      <DialogTitle>Login</DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit(login)}>
          <Stack spacing={2}>
            <Stack>
              <Typography variant="navText">Email</Typography>
              <TextField
                {...register("email")}
                error={errors["email"] ? true : false}
                helperText={errors["email"] ? errors["email"].message : ""}
                required
                autoFocus
                color="primary"
                onChange={(e) => {
                  setEmail(e.target.value);
                  setValue("email", e.target.value, {
                    shouldValidate: true,
                  });
                }}
                onKeyDown={(e) => e.key === "Enter" && login()}
                size="small"
                sx={{
                  width: "100%",
                  margin: "unset",
                }}
              />
            </Stack>
            <Stack>
              <Typography variant="navText">Password</Typography>
              <TextField
                {...register("password")}
                error={errors["password"] ? true : false}
                helperText={
                  errors["password"] ? errors["password"].message : ""
                }
                required
                color="primary"
                type="password"
                onChange={(e) => {
                  setPassword(e.target.value);
                  setValue("password", e.target.value, {
                    shouldValidate: true,
                  });
                }}
                onKeyDown={(e) => e.key === "Enter" && login()}
                size="small"
                sx={{
                  width: "100%",
                  margin: "unset",
                }}
              />
            </Stack>
            <Stack spacing={1}>
              <Typography variant="h6">
                <DomLink to={{ pathname: "/create-account" }}>
                  Don't have an account? Click HERE.
                </DomLink>
              </Typography>
              <Typography variant="h6">
                <DomLink to={{ pathname: "/forgot-password" }}>
                  Forgot Password?
                </DomLink>
              </Typography>
            </Stack>
            <Button
              size="small"
              color="primary"
              variant="contained"
              type="submit"
            >
              Log in
            </Button>
          </Stack>
        </form>
      </DialogContent>
    </Dialog>
  );
}
