// Third-party libraries
import React, { useState } from "react";
import { Link as DomLink } from "react-router-dom";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Tooltip,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Stack,
} from "@mui/material";

// Local modules
import { Mobile, Default } from "../../utils/deviceHelpers";

export default function Navigation(props) {
  const [expanded, setExpanded] = useState("");
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const MillLinks = () => (
    <Stack spacing={1}>
      <DomLink
        to={{
          pathname: "/landing/Mill-Products",
        }}
        onClick={() => props.setShowMobileMenu(false)}
      >
        Mill Home
      </DomLink>
      <DomLink
        to={{
          pathname: "/product/GR",
        }}
        onClick={() => props.setShowMobileMenu(false)}
      >
        Casting Grain
      </DomLink>
      <DomLink
        to={{
          pathname: "/product/ALL",
        }}
        onClick={() => props.setShowMobileMenu(false)}
      >
        Casting Alloy
      </DomLink>
      <DomLink
        to={{
          pathname: "/product/FS",
        }}
        onClick={() => props.setShowMobileMenu(false)}
      >
        Flat Sheet
      </DomLink>
      <DomLink
        to={{
          pathname: "/products/Wire",
        }}
        onClick={() => props.setShowMobileMenu(false)}
      >
        Wire
      </DomLink>
      <DomLink
        to={{
          pathname: "/products/Tubing",
        }}
        onClick={() => props.setShowMobileMenu(false)}
      >
        Tubing
      </DomLink>
      <DomLink
        to={{
          pathname: "/group/SOLDER",
        }}
        onClick={() => props.setShowMobileMenu(false)}
      >
        Solder
      </DomLink>
      <DomLink
        to={{
          pathname: "/product/COINS BULLION",
        }}
        onClick={() => props.setShowMobileMenu(false)}
      >
        Coins &amp; Bullion
      </DomLink>
      <DomLink
        to={{
          pathname: "/links/Material-Specification-Sheets",
        }}
        onClick={() => props.setShowMobileMenu(false)}
      >
        Material Spec Sheets
      </DomLink>
      <DomLink
        to={{
          pathname: "/links/Safety-Data-Sheets",
        }}
        onClick={() => props.setShowMobileMenu(false)}
      >
        Safety Data Sheets
      </DomLink>
      <DomLink
        to={{
          pathname: "/links/Hoover-And-Strong-Metals-Chart",
        }}
        onClick={() => props.setShowMobileMenu(false)}
      >
        Metal Charts
      </DomLink>
    </Stack>
  );

  const FindingsLinks = () => (
    <Stack spacing={1}>
      <DomLink
        to={{
          pathname: "/landing/Findings",
        }}
        onClick={() => props.setShowMobileMenu(false)}
      >
        Findings Home
      </DomLink>
      <DomLink
        to={{
          pathname: "/products/All Shanks",
        }}
        onClick={() => props.setShowMobileMenu(false)}
      >
        Shanks
      </DomLink>
      <DomLink
        to={{
          pathname: "/products/All Settings",
        }}
        onClick={() => props.setShowMobileMenu(false)}
      >
        Settings
      </DomLink>
      <DomLink
        to={{
          pathname: "/products/Earrings",
        }}
        onClick={() => props.setShowMobileMenu(false)}
      >
        Earrings
      </DomLink>
      <DomLink
        to={{
          pathname: "/products/Pendants",
        }}
        onClick={() => props.setShowMobileMenu(false)}
      >
        Pendants
      </DomLink>

      <DomLink
        to={{
          pathname: "/products/Earring and Pendant Components",
        }}
        onClick={() => props.setShowMobileMenu(false)}
      >
        Earring and Pendant Components
      </DomLink>
      <DomLink
        to={{
          pathname: "/products/Chain and Chain Components",
        }}
        onClick={() => props.setShowMobileMenu(false)}
      >
        Chain and Chain Components
      </DomLink>
      <DomLink
        to={{
          pathname: "/products/CIRCLES",
        }}
        onClick={() => props.setShowMobileMenu(false)}
      >
        Circles
      </DomLink>
      <DomLink
        to={{
          pathname: "/products/Alphabets",
        }}
        onClick={() => props.setShowMobileMenu(false)}
      >
        Alphabets
      </DomLink>
      <DomLink
        to={{
          pathname: "/product/WAS",
        }}
        onClick={() => props.setShowMobileMenu(false)}
      >
        Washers
      </DomLink>
      <DomLink
        to={{
          pathname: "/product/CHM",
        }}
        onClick={() => props.setShowMobileMenu(false)}
      >
        Charms
      </DomLink>
      <DomLink
        to={{
          pathname: "/product/WRB",
        }}
        onClick={() => props.setShowMobileMenu(false)}
      >
        Ring Blanks
      </DomLink>
    </Stack>
  );

  const BandsLinks = () => (
    <Stack spacing={1}>
      <DomLink
        to={{
          pathname: "/landing/Bands",
        }}
        onClick={() => props.setShowMobileMenu(false)}
      >
        Bands Home
      </DomLink>
      <DomLink
        to={{
          pathname: "/products/Bands",
        }}
        onClick={() => props.setShowMobileMenu(false)}
      >
        All Bands
      </DomLink>
    </Stack>
  );

  const BridalLinks = () => (
    <Stack spacing={1}>
      <DomLink
        to={{
          pathname: "/landing/Bridal-Jewelry",
        }}
        onClick={() => props.setShowMobileMenu(false)}
      >
        Bridal Jewelry Home
      </DomLink>
      <DomLink
        to={{
          pathname: "/products/Solitaires",
        }}
        onClick={() => props.setShowMobileMenu(false)}
      >
        Solitaires
      </DomLink>
      <DomLink
        to={{
          pathname: "/products/All Engagement Rings",
        }}
        onClick={() => props.setShowMobileMenu(false)}
      >
        Engagement Rings
      </DomLink>
      <DomLink
        to={{
          pathname: "/ring/build",
        }}
        onClick={() => props.setShowMobileMenu(false)}
      >
        Build A Ring
      </DomLink>
    </Stack>
  );

  const JewelryLinks = () => (
    <Stack spacing={1}>
      <DomLink
        to={{
          pathname: "/landing/Finished-Jewelry",
        }}
        onClick={() => props.setShowMobileMenu(false)}
      >
        Finished Jewelry Home
      </DomLink>
      <DomLink
        to={{
          pathname: "/products/Signet Rings",
        }}
        onClick={() => props.setShowMobileMenu(false)}
      >
        Signet Rings
      </DomLink>
      <DomLink
        to={{
          pathname: "/finished-diamond-jewelry",
        }}
        onClick={() => props.setShowMobileMenu(false)}
      >
        Finished Diamond Jewelry
      </DomLink>
      <DomLink
        to={{
          pathname: "/products/Bracelets",
        }}
        onClick={() => props.setShowMobileMenu(false)}
      >
        Bracelets
      </DomLink>
      <DomLink
        to={{
          pathname: "/products/Men's Fashion Jewelry",
        }}
        onClick={() => props.setShowMobileMenu(false)}
      >
        Fashion Rings
      </DomLink>
      <a
        href="https://outlet.hooverandstrong.com"
        target="_blank"
        rel="noreferrer"
      >
        Outlet Store
      </a>
    </Stack>
  );

  const DiamondLinks = () => (
    <Stack spacing={1}>
      <DomLink
        to={{
          pathname: "/landing/Diamonds",
        }}
        onClick={() => props.setShowMobileMenu(false)}
      >
        Diamonds Home
      </DomLink>
      <DomLink
        to={{
          pathname: "/diamonds",
        }}
        onClick={() => props.setShowMobileMenu(false)}
      >
        Certified Diamond Search
      </DomLink>
      <DomLink
        to={{
          pathname: "/melee-diamonds",
        }}
        onClick={() => props.setShowMobileMenu(false)}
      >
        Melee Diamond Search
      </DomLink>
      <DomLink
        to={{
          pathname: "/harmony-diamond-auctions",
        }}
        onClick={() => props.setShowMobileMenu(false)}
      >
        Harmony Diamond Auctions
      </DomLink>
      <DomLink
        to={{
          pathname: "/diamond-services",
        }}
        onClick={() => props.setShowMobileMenu(false)}
      >
        Diamond Services
      </DomLink>
      <DomLink
        to={{
          pathname: "/links/Diamond-Info-And-Forms",
        }}
        onClick={() => props.setShowMobileMenu(false)}
      >
        Diamond Info and Forms
      </DomLink>
      <DomLink
        to={{
          pathname: "/sub-landing/Gemstones",
        }}
        onClick={() => props.setShowMobileMenu(false)}
      >
        Gemstones
      </DomLink>
    </Stack>
  );

  const CastingLinks = () => (
    <Stack spacing={1}>
      <DomLink
        to={{
          pathname: "/accordion-landing/Contract-Casting",
        }}
        onClick={() => props.setShowMobileMenu(false)}
      >
        Contract Casting Home
      </DomLink>
      <a
        href="http://api2.hooverandstrong.com/pdfs/ContractCastingForm-10-22-19.pdf"
        target="_blank"
        rel="noreferrer"
      >
        Contract Casting Form
      </a>
      <a
        href="http://api2.hooverandstrong.com/pdfs/FedExWaiver.pdf"
        target="_blank"
        rel="noreferrer"
      >
        FedEx Waiver
      </a>
      <DomLink
        to={{
          pathname: "/casting-calculator",
        }}
        onClick={() => props.setShowMobileMenu(false)}
      >
        Online Calculator
      </DomLink>
    </Stack>
  );

  const RefiningLinks = () => (
    <Stack spacing={1}>
      <DomLink
        to={{
          pathname: "/landing/Refining",
        }}
        onClick={() => props.setShowMobileMenu(false)}
      >
        Refining Home
      </DomLink>
    </Stack>
  );

  const HarmonyLinks = () => (
    <Stack spacing={1}>
      <DomLink
        to={{
          pathname: "/landing/Harmony",
        }}
        onClick={() => props.setShowMobileMenu(false)}
      >
        Harmony Home
      </DomLink>
    </Stack>
  );

  return (
    <>
      <Mobile>
        <span className="search-field-container search-options">
          <Accordion
            square={true}
            disableGutters={true}
            sx={{ width: "100%", minHeight: "unset", paddingRight: "1rem" }}
            expanded={expanded === "mill"}
            onChange={handleChange("mill")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography variant="h6">MILL</Typography>
            </AccordionSummary>
            <AccordionDetails>{MillLinks()}</AccordionDetails>
          </Accordion>
          <Accordion
            square={true}
            disableGutters={true}
            sx={{ width: "100%", minHeight: "unset", paddingRight: "1rem" }}
            expanded={expanded === "findings"}
            onChange={handleChange("findings")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <Typography variant="h6">FINDINGS</Typography>
            </AccordionSummary>
            <AccordionDetails>{FindingsLinks()}</AccordionDetails>
          </Accordion>
          <Accordion
            square={true}
            disableGutters={true}
            sx={{ width: "100%", minHeight: "unset", paddingRight: "1rem" }}
            expanded={expanded === "bands"}
            onChange={handleChange("bands")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <Typography variant="h6">BANDS</Typography>
            </AccordionSummary>
            <AccordionDetails>{BandsLinks()}</AccordionDetails>
          </Accordion>
          <Accordion
            square={true}
            disableGutters={true}
            sx={{ width: "100%", minHeight: "unset", paddingRight: "1rem" }}
            expanded={expanded === "bridal"}
            onChange={handleChange("bridal")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <Typography variant="h6">BRIDAL</Typography>
            </AccordionSummary>
            <AccordionDetails>{BridalLinks()}</AccordionDetails>
          </Accordion>
          <Accordion
            square={true}
            disableGutters={true}
            sx={{ width: "100%", minHeight: "unset", paddingRight: "1rem" }}
            expanded={expanded === "finished"}
            onChange={handleChange("finished")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <Typography variant="h6">FINISHED JEWELRY</Typography>
            </AccordionSummary>
            <AccordionDetails>{JewelryLinks()}</AccordionDetails>
          </Accordion>
          <Accordion
            square={true}
            disableGutters={true}
            sx={{ width: "100%", minHeight: "unset", paddingRight: "1rem" }}
            expanded={expanded === "diamonds"}
            onChange={handleChange("diamonds")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <Typography variant="h6">DIAMONDS AND GEMSTONES</Typography>
            </AccordionSummary>
            <AccordionDetails>{DiamondLinks()}</AccordionDetails>
          </Accordion>
          <Accordion
            square={true}
            disableGutters={true}
            sx={{ width: "100%", minHeight: "unset", paddingRight: "1rem" }}
            expanded={expanded === "casting"}
            onChange={handleChange("casting")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <Typography variant="h6">CONTRACT CASTING</Typography>
            </AccordionSummary>
            <AccordionDetails>{CastingLinks()}</AccordionDetails>
          </Accordion>
          <Accordion
            square={true}
            disableGutters={true}
            sx={{ width: "100%", minHeight: "unset", paddingRight: "1rem" }}
            expanded={expanded === "refining"}
            onChange={handleChange("refining")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <Typography variant="h6">REFINING</Typography>
            </AccordionSummary>
            <AccordionDetails>{RefiningLinks()}</AccordionDetails>
          </Accordion>
          <Accordion
            square={true}
            disableGutters={true}
            sx={{ width: "100%", minHeight: "unset", paddingRight: "1rem" }}
            expanded={expanded === "harmony"}
            onChange={handleChange("harmony")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <Typography variant="h6">HARMONY</Typography>
            </AccordionSummary>
            <AccordionDetails>{HarmonyLinks()}</AccordionDetails>
          </Accordion>
          <Typography>
            <DomLink
              to={{
                pathname: "/links/How-The-Metal-Market-Prices-Are-Set",
              }}
              onClick={() => props.setShowMobileMenu(false)}
            >
              Learn More About the Metal Markets
            </DomLink>
          </Typography>
        </span>
      </Mobile>
      <Default>
        <ul className="navbar">
          <li className="parent">
            <Tooltip title="Mill Products" placement="top">
              <DomLink
                to={{
                  pathname: "/landing/Mill-Products",
                }}
              >
                <Typography variant="navText">Mill</Typography>
              </DomLink>
            </Tooltip>
            <ul>
              <li>
                <DomLink
                  to={{
                    pathname: "/product/GR",
                  }}
                >
                  Casting Grain
                </DomLink>
              </li>
              <li>
                <DomLink
                  to={{
                    pathname: "/product/ALL",
                  }}
                >
                  Casting Alloy
                </DomLink>
              </li>
              <li>
                <DomLink
                  to={{
                    pathname: "/product/FS",
                  }}
                >
                  Flat Sheet
                </DomLink>
              </li>
              <li>
                <DomLink
                  to={{
                    pathname: "/products/Wire",
                  }}
                >
                  Wire
                </DomLink>
              </li>
              <li>
                <DomLink
                  to={{
                    pathname: "/products/Tubing",
                  }}
                >
                  Tubing
                </DomLink>
              </li>
              <li>
                <DomLink
                  to={{
                    pathname: "/group/SOLDER",
                  }}
                >
                  Solder
                </DomLink>
              </li>
              <li>
                <DomLink
                  to={{
                    pathname: "/product/COINS BULLION",
                  }}
                >
                  Coins &amp; Bullion
                </DomLink>
              </li>
              <li>
                <DomLink
                  to={{
                    pathname: "/links/Material-Specification-Sheets",
                  }}
                >
                  Material Spec Sheets
                </DomLink>
              </li>
              <li>
                <DomLink
                  to={{
                    pathname: "/links/Safety-Data-Sheets",
                  }}
                >
                  Safety Data Sheets
                </DomLink>
              </li>
              <li>
                <DomLink
                  to={{
                    pathname: "/links/Hoover-And-Strong-Metals-Chart",
                  }}
                >
                  Metals Chart
                </DomLink>
              </li>
            </ul>
          </li>
          <li className="parent">
            <Tooltip title="Jewelry Findings" placement="top">
              <DomLink
                to={{
                  pathname: "/landing/Findings",
                }}
              >
                <Typography variant="navText">Findings</Typography>
              </DomLink>
            </Tooltip>
            <ul>
              <li>
                <DomLink
                  to={{
                    pathname: "/products/All Shanks",
                  }}
                >
                  Shanks
                </DomLink>
              </li>
              <li>
                <DomLink
                  to={{
                    pathname: "/products/All Settings",
                  }}
                >
                  Settings
                </DomLink>
              </li>
              <li>
                <DomLink
                  to={{
                    pathname: "/products/Earrings",
                  }}
                >
                  Earrings
                </DomLink>
              </li>
              <li>
                <DomLink
                  to={{
                    pathname: "/products/Pendants",
                  }}
                >
                  Pendants
                </DomLink>
              </li>
              <li>
                <DomLink
                  to={{
                    pathname: "/products/Earring and Pendant Components",
                  }}
                >
                  Earring and Pendant Components
                </DomLink>
              </li>
              <li>
                <DomLink
                  to={{
                    pathname: "/products/Chain and Chain Components",
                  }}
                >
                  Chain and Chain Components
                </DomLink>
              </li>
              <li>
                <DomLink
                  to={{
                    pathname: "/product/CIRCLES",
                  }}
                >
                  Circles
                </DomLink>
              </li>
              <li>
                <DomLink
                  to={{
                    pathname: "/products/Alphabets",
                  }}
                >
                  Alphabets
                </DomLink>
              </li>
              <li>
                <DomLink
                  to={{
                    pathname: "/product/WAS",
                  }}
                >
                  Washers
                </DomLink>
              </li>
              <li>
                <DomLink
                  to={{
                    pathname: "/product/CHM",
                  }}
                >
                  Charms
                </DomLink>
              </li>
              <li>
                <DomLink
                  to={{
                    pathname: "/product/WRB",
                  }}
                >
                  Ring Blanks
                </DomLink>
              </li>
            </ul>
          </li>
          <li className="parent">
            <DomLink
              to={{
                pathname: "/landing/Bands",
              }}
            >
              <Typography variant="navText">Bands</Typography>
            </DomLink>
            <ul>
              <li>
                <DomLink
                  to={{
                    pathname: "/products/Bands",
                  }}
                >
                  All Bands
                </DomLink>
              </li>
            </ul>
          </li>
          <li className="parent">
            <Tooltip title="Bridal Jewelry" placement="top">
              <DomLink
                to={{
                  pathname: "/landing/Bridal-Jewelry",
                }}
              >
                <Typography variant="navText">Bridal</Typography>
              </DomLink>
            </Tooltip>
            <ul>
              <li>
                <DomLink
                  to={{
                    pathname: "/products/Solitaires",
                  }}
                >
                  Solitaires
                </DomLink>
              </li>
              <li>
                <DomLink
                  to={{
                    pathname: "/products/All Engagement Rings",
                  }}
                >
                  Engagement Rings
                </DomLink>
              </li>
              <li>
                <DomLink
                  to={{
                    pathname: "/ring/build",
                  }}
                >
                  Build-A-Ring
                </DomLink>
              </li>
              {/* <li>
            <DomLink
              to={{
                pathname: "/products/Wedding Sets",
              }}
            >
              Wedding Sets
            </DomLink>
          </li> */}
            </ul>
          </li>
          <li className="parent">
            <DomLink
              to={{
                pathname: "/landing/Finished-Jewelry",
              }}
            >
              <Typography variant="navText">Finished Jewelry</Typography>
            </DomLink>
            <ul>
              <li>
                <DomLink
                  to={{
                    pathname: "/products/Signet Rings",
                  }}
                >
                  Signet Rings
                </DomLink>
              </li>
              <li>
                <DomLink
                  to={{
                    pathname: "/finished-diamond-jewelry",
                  }}
                >
                  Finished Diamond Jewelry
                </DomLink>
              </li>
              <li>
                <DomLink
                  to={{
                    pathname: "/products/Bracelets",
                  }}
                >
                  Bracelets
                </DomLink>
              </li>
              <li>
                <DomLink
                  to={{
                    pathname: "/products/Men's Fashion Jewelry",
                  }}
                >
                  Fashion Rings
                </DomLink>
              </li>
              <li>
                <DomLink
                  to={{
                    pathname: "/links/Machine-Set-Inside-Out-Earrings",
                  }}
                >
                  Machine Set Inside Out Earrings
                </DomLink>
              </li>
              <li>
                <a
                  href="https://outlet.hooverandstrong.com"
                  target="_blank"
                  rel="noreferrer"
                >
                  Outlet Store
                </a>
              </li>
            </ul>
          </li>
          <li className="parent">
            <DomLink
              to={{
                pathname: "/landing/Diamonds",
              }}
            >
              <Typography variant="navText">Diamonds and Gemstones</Typography>
            </DomLink>
            <ul>
              <li>
                <DomLink
                  to={{
                    pathname: "/diamonds",
                  }}
                >
                  Certified Diamond Search
                </DomLink>
              </li>
              <li>
                <DomLink
                  to={{
                    pathname: "/melee-diamonds",
                  }}
                >
                  Melee Diamond Search
                </DomLink>
              </li>
              <li>
                <DomLink
                  to={{
                    pathname: "/harmony-diamond-auctions",
                  }}
                >
                  Harmony Diamond Auctions
                </DomLink>
              </li>
              <li>
                <DomLink
                  to={{
                    pathname: "/diamond-services",
                  }}
                >
                  Diamond Services
                </DomLink>
              </li>
              <li>
                <DomLink
                  to={{
                    pathname: "/links/Diamond-Info-And-Forms",
                  }}
                >
                  Diamond Info and Forms
                </DomLink>
              </li>
              <li>
                <DomLink
                  to={{
                    pathname: "/sub-landing/Gemstones",
                  }}
                >
                  Gemstones
                </DomLink>
              </li>
            </ul>
          </li>
          <li className="parent">
            <DomLink
              to={{
                pathname: "/accordion-landing/Contract-Casting",
              }}
            >
              <Typography variant="navText">Contract Casting</Typography>
            </DomLink>
            <ul>
              <li>
                <Tooltip title="Includes Terms & Conditions" placement="left">
                  <a
                    href="http://api2.hooverandstrong.com/pdfs/ContractCastingForm-10-22-19.pdf"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Contract Casting Form
                  </a>
                </Tooltip>
              </li>
              <li>
                <a
                  href="http://api2.hooverandstrong.com/pdfs/FedExWaiver.pdf"
                  target="_blank"
                  rel="noreferrer"
                >
                  FedEx Waiver
                </a>
              </li>
              <li>
                <DomLink
                  to={{
                    pathname: "/casting-calculator",
                  }}
                >
                  Online Calculator
                </DomLink>
              </li>
            </ul>
          </li>
          <li>
            <DomLink
              to={{
                pathname: "/landing/Refining",
              }}
            >
              <Typography variant="navText">Refining</Typography>
            </DomLink>
          </li>
          <li>
            <DomLink
              to={{
                pathname: "/landing/Harmony",
              }}
            >
              <Typography variant="navText">Harmony</Typography>
            </DomLink>
          </li>
        </ul>
      </Default>
    </>
  );
}
