// Third-party libraries
import React, { useContext, useEffect, useState } from "react";
import { useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";

// Local modules
import { MeleeSearchContext } from "../../utils/stateHandlers/contexts";
import AccordionMeleeFilter from "./AccordionMeleeFilter";
import MeleeFilter from "./MeleeFilter";
export default function MeleeDiamondSearchFilters(props) {
  const theme = useTheme();
  const screenBreakpoint = useMediaQuery(theme.breakpoints.down("md"));
  const { meleeState, meleeSearchDispatch } = useContext(MeleeSearchContext);
  const { searchQuery } = meleeState;
  const [buttons, setButtons] = useState([]);
  const [caratSelect, setCaratSelect] = useState(null);
  const [mmSelect, setMmSelect] = useState(null);

  function clickButton(button, signature) {
    const alreadySelected = buttons.find(function (b) {
      return b === button.value;
    })
      ? true
      : false;
    if (alreadySelected) {
      const remove = buttons.filter((b) => b !== button.value);
      setButtons(remove);
    } else {
      setButtons([...buttons, button.value]);
    }
    const qualityFilter = searchQuery.fields.find(function (field) {
      return field.name === "Quality";
    });
    if (qualityFilter) {
      const valueFound = qualityFilter.values.find(function (value) {
        return value.name === button.value;
      });
      if (valueFound) {
        updateFilters(
          "Quality",
          qualityFilter.values.filter(
            (filterValue) => filterValue.name !== button.value
          )
        );
      } else {
        updateFilters("Quality", [
          ...qualityFilter.values,
          { name: button.value, collection: signature },
        ]);
      }
    } else {
      updateFilters("Quality", [{ name: button.value, collection: signature }]);
    }
  }

  function updateFilters(filterName, filterValues) {
    const filterToUpdate = searchQuery.fields.find(function (field) {
      return field.name === filterName;
    });
    if (filterToUpdate) {
      filterToUpdate.values =
        filterName !== "Quality" ? [filterValues] : filterValues;
      meleeSearchDispatch({
        type: "updateSearchQuery",
        payload: searchQuery.fields,
      });
    } else {
      const fields = [
        ...searchQuery.fields,
        {
          name: filterName,
          values: filterName !== "Quality" ? [filterValues] : filterValues,
        },
      ];
      meleeSearchDispatch({ type: "updateSearchQuery", payload: fields });
    }
  }

  function updateDropdownFilters(filterName, event) {
    const filterToUpdate = searchQuery.fields.find(
      (field) => field.name === filterName
    );
    if (filterToUpdate) {
      filterToUpdate.values = event === null ? [] : [{ name: event.value }];
      meleeSearchDispatch({
        type: "updateSearchQuery",
        payload: searchQuery.fields,
      });
    } else {
      const fields = [
        ...searchQuery.fields,
        {
          name: filterName,
          values: event === null ? [] : [{ name: event.value }],
        },
      ];
      meleeSearchDispatch({ type: "updateSearchQuery", payload: fields });
    }
  }

  useEffect(() => {
    const buttonFilter = searchQuery.fields.find(
      (field) => field.name === "Quality"
    );
    if (buttonFilter === undefined) {
      setButtons([]);
    }
    if (searchQuery.fields.length === 0) {
      setCaratSelect(null);
      setMmSelect(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery]);

  return (
    <>
      {screenBreakpoint ? (
        <AccordionMeleeFilter
          setCaratSelect={setCaratSelect}
          caratSelect={caratSelect}
          updateDropdownFilters={updateDropdownFilters}
          setMmSelect={setMmSelect}
          mmSelect={mmSelect}
          clickButton={clickButton}
          setButtons={setButtons}
          buttons={buttons}
        />
      ) : (
        <MeleeFilter
          setCaratSelect={setCaratSelect}
          caratSelect={caratSelect}
          updateDropdownFilters={updateDropdownFilters}
          setMmSelect={setMmSelect}
          mmSelect={mmSelect}
          clickButton={clickButton}
          setButtons={setButtons}
          buttons={buttons}
        />
      )}
    </>
  );
}
