// Third-party libraries
import React, { useEffect, useState } from "react";
import {
  Typography,
  Checkbox,
  FormControl,
  FormGroup,
  FormControlLabel,
  Stack,
} from "@mui/material";

// Local modules
import StringContants from "../../../../utils/constants";
import {
  USFlagIcon,
  CanadaIcon,
  RecycleIcon,
  ASIcon,
  ConflictFreeIcon,
  SCSIcon,
} from "../../../Icons";

export default function Checkboxes({
  search,
  filter,
  resetFilters,
  updateCheckedBoxes,
}) {
  const [checkboxes, setCheckboxes] = useState([]);

  useEffect(() => {
    const mapped = [];
    filter.values.forEach(function (v) {
      const newValue = { ...v, checked: false };
      if (v.name === "Harmony Lab Grown") {
        return;
      }
      mapped.push(newValue);
    });
    setCheckboxes(mapped);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter.values, resetFilters]);

  function toggleAsGrown(checked) {
    updateCheckedBoxes("AsGrown", {
      name: "AsGrown",
      checked: checked ? "Y" : "N",
    });
  }

  function toggleScsGrower(checked) {
    updateCheckedBoxes("ScsGrower", {
      name: "ScsGrower",
      checked: checked ? "Y" : "N",
    });
  }

  function handleChange(event) {
    const checkboxToUpdate = checkboxes.find(function (checkbox) {
      return checkbox.name === event.target.name;
    });
    checkboxToUpdate.checked = event.target.checked;
    updateCheckedBoxes(filter.name, checkboxes);
  }

  function getCheckboxName(text) {
    return text
      .replace("Harmony Canadian", "Canadian")
      .replace("All", "Conflict-Free")
      .replace("Harmony US Lab Grown", "US Lab-Grown")
      .trim();
  }

  return (
    <>
      <FormControl component="fieldset" variant="standard">
        <FormGroup>
          <Stack
            direction="row"
            spacing={1}
            sx={{ marginBottom: "2rem", marginTop: "2rem" }}
          >
            {checkboxes.map((checkbox) => (
              <FormControlLabel
                key={checkbox.name}
                className={[
                  "checkbox-item",
                  (checkbox.name.includes("Grow") &&
                    search.fields.find(
                      (f) => f.name === StringContants.FIELD_NAME_MINED_OR_LAB
                    ).values[0].name === "M") ||
                  (checkbox.name.includes("DFC") &&
                    search.fields.find(
                      (f) => f.name === StringContants.FIELD_NAME_MINED_OR_LAB
                    ).values[0].name === "M") ||
                  (checkbox.name.includes("GCAL") &&
                    search.fields.find(
                      (f) => f.name === StringContants.FIELD_NAME_MINED_OR_LAB
                    ).values[0].name === "M") ||
                  (checkbox.name.includes("AGS") &&
                    search.fields.find(
                      (f) => f.name === StringContants.FIELD_NAME_MINED_OR_LAB
                    ).values[0].name === "L") ||
                  (checkbox.name.includes("EGL") &&
                    search.fields.find(
                      (f) => f.name === StringContants.FIELD_NAME_MINED_OR_LAB
                    ).values[0].name === "L") ||
                  (checkbox.name.includes("All") &&
                    search.fields.find(
                      (f) => f.name === StringContants.FIELD_NAME_MINED_OR_LAB
                    ).values[0].name === "L") ||
                  (checkbox.name.includes("Canad") &&
                    search.fields.find(
                      (f) => f.name === StringContants.FIELD_NAME_MINED_OR_LAB
                    ).values[0].name === "L") ||
                  (checkbox.name.includes("Recycled") &&
                    search.fields.find(
                      (f) => f.name === StringContants.FIELD_NAME_MINED_OR_LAB
                    ).values[0].name === "L") ||
                  (checkbox.name.includes("Lab") &&
                    search.fields.find(
                      (f) => f.name === StringContants.FIELD_NAME_MINED_OR_LAB
                    ).values[0].name === "M")
                    ? "hidden-icon"
                    : "",
                ].join(" ")}
                control={
                  <Checkbox
                    checked={checkbox.checked}
                    onChange={handleChange}
                    name={checkbox.name}
                  />
                }
                label={
                  <Typography variant="body3">
                    {getCheckboxName(checkbox.name)}
                    &nbsp;
                    <div
                      className={[
                        !checkbox.name.includes("USA") &&
                        checkbox.name.includes("US")
                          ? "inline"
                          : "hidden-icon",
                      ].join(" ")}
                    >
                      <USFlagIcon />
                    </div>
                    <div
                      className={[
                        checkbox.name.includes("Recycle")
                          ? "inline"
                          : "hidden-icon",
                      ].join(" ")}
                    >
                      <RecycleIcon />
                    </div>
                    <div
                      className={[
                        checkbox.name.includes("Canad")
                          ? "inline"
                          : "hidden-icon",
                      ].join(" ")}
                    >
                      <CanadaIcon />
                    </div>
                    <div
                      className={[
                        checkbox.name.includes("All")
                          ? "inline"
                          : "hidden-icon",
                      ].join(" ")}
                    >
                      <ConflictFreeIcon />
                    </div>
                  </Typography>
                }
              />
            ))}
            {search.fields.find(
              (f) => f.name === StringContants.FIELD_NAME_MINED_OR_LAB
            ).values[0].name === "L" &&
              filter.name === "DiamondSource" && (
                <>
                  <FormControlLabel
                    control={
                      <Checkbox
                        id="AsGrown"
                        type="checkbox"
                        className="input-checkbox"
                        onClick={(event) => toggleAsGrown(event.target.checked)}
                      />
                    }
                    label={
                      <Typography variant="body3">
                        As Grown <ASIcon />
                      </Typography>
                    }
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        id="ScsGrower"
                        type="checkbox"
                        className="input-checkbox"
                        onClick={(event) =>
                          toggleScsGrower(event.target.checked)
                        }
                      />
                    }
                    label={
                      <Typography variant="body3">
                        SCS Certified <SCSIcon />
                      </Typography>
                    }
                  />
                </>
              )}
          </Stack>
        </FormGroup>
      </FormControl>
    </>
  );
}
