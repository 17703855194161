// Third-party libraries
import React, { useContext } from "react";
import Typography from "@mui/material/Typography";

// Local modules
import { DiamondSearchContext } from "../../../../utils/stateHandlers/contexts.jsx";
import Shape from "../../shapes/Shape.jsx";

export default function ShapeFilter() {
  const { diamondState, dispatch } = useContext(DiamondSearchContext);
  const { filters, searchQuery } = diamondState;
  const shapeFilter = filters.find(function (filter) {
    return filter.name === "Shape";
  });

  function isActive(shapeName) {
    const selectedValue = searchQuery.fields.find(function (field) {
      return field.name === "Shape";
    });
    if (selectedValue) {
      const bool = selectedValue.values.find(function (value) {
        return value.name === shapeName ? true : false;
      });
      return bool;
    }
  }

  function selectShape(shapeName) {
    const searchField = searchQuery.fields.find(function (field) {
      return field.name === "Shape";
    });
    if (searchField) {
      const shapeFound = searchField.values.find(function (value) {
        return value.name === shapeName;
      });
      if (shapeFound) {
        const removeSelected = searchField.values.filter(
          (value) => value.name !== shapeName
        );
        searchField.values = removeSelected;
        dispatch({ type: "updateSearchQuery", payload: searchQuery.fields });
      } else {
        const newSelectedShapes = [...searchField.values, { name: shapeName }];

        searchField.values = newSelectedShapes;
        dispatch({ type: "updateSearchQuery", payload: searchQuery.fields });
      }
    } else {
      const newSearchFields = [
        ...searchQuery.fields,
        {
          name: "Shape",
          values: [
            {
              name: shapeName,
            },
          ],
        },
      ];
      dispatch({ type: "updateSearchQuery", payload: newSearchFields });
    }
  }

  return (
    <div className="filter filter--shape">
      {shapeFilter.values.map((value) => (
        <div
          key={value.name}
          onClick={() => selectShape(value.name)}
          className="shape-value"
        >
          <div className="shape-icon pointer">
            <Shape
              type={value.name}
              color={isActive(value.name) ? "black" : "#4280aa"}
              stroke_width={isActive(value.name) ? "1%" : "0.4%"}
              width="40"
            />
          </div>
          <Typography
            variant="body3"
            className={[
              "pointer",
              "shape-label " +
                (isActive(value.name) ? "shape-label--selected" : ""),
            ].join(" ")}
          >
            {value.name}
          </Typography>
        </div>
      ))}
    </div>
  );
}
