// Third-party libraries
import React, { useState, useEffect } from "react";
import { Link as DomLink, useLocation } from "react-router-dom";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import HelpIcon from "@mui/icons-material/Help";
import LanguageIcon from "@mui/icons-material/Language";
import InfoIcon from "@mui/icons-material/Info";
import EmailIcon from "@mui/icons-material/Email";
import {
  Typography,
  Grid,
  Stack,
  Divider,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Link,
  Button,
} from "@mui/material";

// Local modules
import { Mobile, Default } from "../../utils/deviceHelpers";
import MSJAImage from "../../assets/images/memberlogo-MJSA.gif";
import AGTAImage from "../../assets/images/memberlogo-AGTA.gif";
import JBTImage from "../../assets/images/memberlogo-JBT.gif";
import NPAImage from "../../assets/images/memberlogo-NPA.gif";
import PolygonImage from "../../assets/images/memberlogo-polygon.gif";
import CareersFooter from "../pages/careers/CareersFooter";
import NewsletterSignupModal from "../user/NewsletterSignupModal.jsx";

let todayDate = new Date();
let year = todayDate.getFullYear();

export default function Footer(props) {
  let location = useLocation();
  const [showCareersFooter, setShowCareersFooter] = useState(false);
  const [openNewsletterModal, setOpenNewsletterModal] = useState(false);

  useEffect(() => {
    if (location.pathname === "/careers") {
      setShowCareersFooter(true);
    } else {
      setShowCareersFooter(false);
    }
  }, [location]);

  const helpfulInfo = () => {
    return (
      <Typography variant="footerText">
        <Stack>
          <DomLink to="/faq/Website-Help">Website Help</DomLink>
          <DomLink to="/faq/Frequently-Asked-Questions">
            Frequently Asked Questions
          </DomLink>
          <DomLink to="/links/Forms">Forms</DomLink>
          <DomLink to="/links/Catalogs-And-Brochures">
            Catalogs and Brochures
          </DomLink>
          <DomLink to="/technicalarticles">Technical Articles</DomLink>
          <DomLink to="/links/How-The-Metal-Market-Prices-Are-Set">
            How The Metal Market Prices Are Set
          </DomLink>
          <DomLink to="/links/Old-And-New-Style-Name-Reference-List">
            Old and New Style Name Reference List
          </DomLink>
        </Stack>
      </Typography>
    );
  };

  const about = () => {
    return (
      <Typography variant="footerText">
        <Stack>
          <DomLink to="/sub-landing/Our-History">Our History</DomLink>
          <DomLink to="/sub-landing/The-Hoover-And-Strong-Experts">
            The H&S Experts
          </DomLink>
          <DomLink to="/sub-landing/Customer-Service-Team">
            Customer Service Team
          </DomLink>
          <DomLink to="/links/Articles-And-Press-Releases">
            Press Releases
          </DomLink>
          <DomLink to="/sub-landing/Charities-And-Sponsorships">
            Charities and Sponsorships
          </DomLink>
          <DomLink to="/careers">Careers</DomLink>
        </Stack>
      </Typography>
    );
  };

  const customers = () => {
    return (
      <Typography variant="footerText">
        <Stack>
          <DomLink to="/create-account">Create An Account</DomLink>
          <DomLink to="/sub-landing/Payment-Information">
            Payment Information
          </DomLink>
          {/* <DomLink to="form/credit">Credit Application</DomLink> */}
          <DomLink to="/sub-landing/Shipping-Options">Shipping Options</DomLink>
          <DomLink to="/sub-landing/Volume-Discount-Programs">
            Volume Discount Programs
          </DomLink>
          <DomLink to="/links/Privacy-Policy">Privacy Policy</DomLink>
          <DomLink to="/links/Holiday-And-Shutdown-Dates">
            Holiday & Shutdown Dates
          </DomLink>
        </Stack>
      </Typography>
    );
  };

  return (
    <footer
      style={
        showCareersFooter
          ? { marginTop: "2rem", backgroundColor: "#dfe8ed" }
          : { backgroundColor: "#f0f0f0" }
      }
    >
      {showCareersFooter ? (
        <CareersFooter />
      ) : (
        <>
          <Mobile>
            <Divider flexItem />
            <Stack
              direction="row"
              spacing={2}
              className="search-field-container"
            >
              <DomLink to="/contact">
                <EmailIcon sx={{ verticalAlign: "middle" }} /> EMAIL
              </DomLink>
              <DomLink to="/faq/Website-Help">
                <InfoIcon sx={{ verticalAlign: "middle" }} /> HELP
              </DomLink>
              <DomLink to="/faq/Frequently-Asked-Questions">
                <HelpIcon sx={{ verticalAlign: "middle" }} /> FAQ
              </DomLink>
              <DomLink to="/careers">
                <LanguageIcon sx={{ verticalAlign: "middle" }} /> CAREERS
              </DomLink>
            </Stack>
            <span className="search-field-container search-options">
              <Accordion
                square={true}
                disableGutters={true}
                sx={{ width: "100%", minHeight: "unset", paddingRight: "1rem" }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography variant="h6">HELPFUL INFO</Typography>
                </AccordionSummary>
                <AccordionDetails>{helpfulInfo()}</AccordionDetails>
              </Accordion>
              <Accordion
                square={true}
                disableGutters={true}
                sx={{ width: "100%", minHeight: "unset", paddingRight: "1rem" }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  <Typography variant="h6">ABOUT HOOVER & STRONG</Typography>
                </AccordionSummary>
                <AccordionDetails>{about()}</AccordionDetails>
              </Accordion>
              <Accordion
                square={true}
                disableGutters={true}
                sx={{ width: "100%", minHeight: "unset", paddingRight: "1rem" }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel3a-content"
                  id="panel3a-header"
                >
                  <Typography variant="h6">FOR OUR CUSTOMERS</Typography>
                </AccordionSummary>
                <AccordionDetails>{customers()}</AccordionDetails>
              </Accordion>
            </span>
            <Grid container className="search-field-container">
              <Grid item xs={12}>
                <Stack>
                  <Typography variant="footerText" sx={{ textAlign: "center" }}>
                    <span className="text-bold">
                      Hoover & Strong is a wholesale manufacturer.
                    </span>{" "}
                  </Typography>
                  <Typography variant="footerText" sx={{ textAlign: "center" }}>
                    A state or city jewelry business license is required to do
                    business with Hoover & Strong. If you are a consumer and are
                    interested in purchasing our jewelry products, please
                    contact your local retail jewelry store.
                  </Typography>
                  <Typography variant="footerText" sx={{ textAlign: "center" }}>
                    We accept VISA, MasterCard, American Express and Discover
                    cards.
                  </Typography>
                  <Typography variant="footerText" sx={{ textAlign: "center" }}>
                    Hoover & Strong is a Better Business Bureau® Accredited
                    Business and a member of these associations: MJSA, JBT,
                    AGTA, NPA, and POLYGON.
                  </Typography>
                </Stack>
              </Grid>
            </Grid>
            <Divider />
            <Grid container sx={{ marginBottom: "6rem" }}>
              <Grid item xs={12}>
                <Stack>
                  <Typography variant="footerText" sx={{ textAlign: "center" }}>
                    @{year} Hoover & Strong, Inc. All Rights Reserved. |{" "}
                    <DomLink to="/links/Company-Policies">
                      Company Policies
                    </DomLink>{" "}
                    |{" "}
                    <DomLink to="/sub-landing/Responsibility-Statements-And-Policies">
                      Compliance and Responsibility
                    </DomLink>{" "}
                    |{" "}
                    <DomLink to="/links/Legal-Information">Legal Info</DomLink>
                  </Typography>
                </Stack>
              </Grid>
            </Grid>
            <NewsletterSignupModal
              open={openNewsletterModal}
              close={setOpenNewsletterModal}
            />
          </Mobile>
          <Default>
            <Divider
              flexItem
              sx={{ marginBottom: "2rem", marginTop: "2rem" }}
            />
            <Grid container sx={{ padding: "2rem" }}>
              <Grid item xs={3}>
                <Stack>
                  <Typography variant="h3">HELPFUL INFO</Typography>
                  {helpfulInfo()}
                </Stack>
              </Grid>
              <Grid item xs={3}>
                <Stack>
                  <Typography variant="h3">ABOUT HOOVER & STRONG</Typography>
                  {about()}
                </Stack>
              </Grid>
              <Grid item xs={3}>
                <Stack>
                  <Typography variant="h3">FOR OUR CUSTOMERS</Typography>
                  {customers()}
                </Stack>
              </Grid>
              <Grid item xs={3}>
                <Stack>
                  <Typography variant="h3">SIGN UP FOR OUR EMAILS</Typography>
                  <Typography variant="footerText">
                    Send me Hoover & Strong news, updates, and offers.
                  </Typography>
                  <Button
                    variant="contained"
                    onClick={() => setOpenNewsletterModal(true)}
                  >
                    Subscribe
                  </Button>
                  {/* <TextField></TextField> */}
                  {/* <div>
              <form
                className="validate"
                action="https://hooverandstrong.us2.list-manage.com/subscribe/post?u=a84676575358f6c6855acaba5&amp;id=6d5969c4ea"
                method="post"
              >
                <div>
                  <div className="mc-field-group">
                    <label htmlFor="mce-EMAIL">Email Address </label>
                    <input
                      className="required email"
                      name="EMAIL"
                      type="email"
                      placeholder="Your Email Address Here"
                    />
                    <input
                      className="button"
                      name="subscribe"
                      type="submit"
                      value="&gt;"
                    />
                  </div>

                  <div style={{ position: "absolute", left: "-5000px" }}>
                    <input
                      name="b_a84676575358f6c6855acaba5_6d5969c4ea"
                      type="text"
                      tabIndex="-1"
                    />
                  </div>
                </div>
              </form>
            </div> */}
                </Stack>
              </Grid>
            </Grid>
            {/* <Grid container className="social-media-links">
              <Stack direction="row">
                <a
                  href="https://www.facebook.com/Hoover-Strong-Inc-502196379863715/"
                  target="blank"
                  rel="noreferrer"
                >
                  <FacebookIcon fontSize="large" />
                </a>
                <a
                  href="https://www.youtube.com/watch?v=BEE3GMSGr38"
                  target="blank"
                  rel="noreferrer"
                >
                  {" "}
                  <YouTubeIcon fontSize="large" />
                </a>
                <a
                  href="https://www.linkedin.com/company/hoover-strong-inc/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <LinkedInIcon fontSize="large" />
                </a>
              </Stack>
            </Grid> */}
            <Divider />
            <Grid container className="search-field-container">
              <Grid item xs={12}>
                <Stack>
                  <Typography variant="footerText" sx={{ textAlign: "center" }}>
                    <span className="text-bold">
                      Hoover & Strong is a wholesale manufacturer.
                    </span>{" "}
                  </Typography>
                  <Typography variant="footerText" sx={{ textAlign: "center" }}>
                    A state or city jewelry business license is required to do
                    business with Hoover & Strong. If you are a consumer and are
                    interested in purchasing our jewelry products, please
                    contact your local retail jewelry store.
                  </Typography>
                  <Typography variant="footerText" sx={{ textAlign: "center" }}>
                    We accept VISA, MasterCard, American Express and Discover
                    cards.
                  </Typography>
                </Stack>
              </Grid>
            </Grid>
            <Grid
              container
              className="search-field-container"
              sx={{ backgroundColor: "white" }}
            >
              <Grid item xs={12}>
                <Stack alignItems="center" justifyContent="center" spacing={2}>
                  <Typography
                    variant="footerText"
                    sx={{ textAlign: "center", marginTop: "1rem" }}
                  >
                    Hoover & Strong is a Better Business Bureau® Accredited
                    Business and a member of these associations:
                  </Typography>
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="center"
                    spacing={4}
                  >
                    <Link
                      href="https://www.mjsa.org/"
                      className="center"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img src={MSJAImage} alt="MSJAImage" />
                    </Link>
                    <Link
                      href="https://www.jewelersboard.com/"
                      className="center"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img src={JBTImage} alt="JBTImage" />
                    </Link>

                    <Link
                      href="https://agta.org/welcome/"
                      className="center"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img src={AGTAImage} alt="AGTAImage" />
                    </Link>

                    <Link
                      href="https://www.polygon.net/jwl/public/home-en.jsp"
                      className="center"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img src={PolygonImage} alt="PolygonImage" />
                    </Link>
                  </Stack>
                  <Link
                    href="https://www.nationalpawnbrokers.org"
                    className="center"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={NPAImage} alt="NPAImage" />
                  </Link>
                  <Typography variant="footerText" sx={{ textAlign: "center" }}>
                    @{year} Hoover & Strong, Inc. All Rights Reserved. |{" "}
                    <DomLink to="/links/Company-Policies">
                      Company Policies
                    </DomLink>{" "}
                    |{" "}
                    <DomLink to="/sub-landing/Responsibility-Statements-And-Policies">
                      Compliance and Responsibility
                    </DomLink>{" "}
                    |{" "}
                    <DomLink to="/links/Legal-Information">Legal Info</DomLink>
                  </Typography>
                </Stack>
              </Grid>
            </Grid>
            <NewsletterSignupModal
              open={openNewsletterModal}
              close={setOpenNewsletterModal}
            />
          </Default>
        </>
      )}
    </footer>
  );
}
