import React from "react";

const Trillion = (props) => {
  const viewBoxWidth = 85.7;
  const viewBoxHeight = 86.1;

  const lengthXPrcnt = 0.3;
  const lengthYPrcnt = 0.3;

  const widthXPrcnt = 0.58;
  const widthYPrcnt = 0.75;

  const lengthX = lengthXPrcnt * viewBoxWidth;
  const lengthY = lengthYPrcnt * viewBoxHeight;

  const widthX = widthXPrcnt * viewBoxWidth;
  const widthY = widthYPrcnt * viewBoxHeight;

  const x = (percentX) => percentX * viewBoxWidth;
  const y = (percentY) => percentY * viewBoxHeight;

  const lineColor = "#4a4a4a";

  const adustedStrokeWidth = props.stroke_width * 3 || "1";

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox={"0 0 " + viewBoxWidth + " " + viewBoxHeight}
      width={props.width}
      height={props.height}
      preserveAspectRatio={props.preserveAspectRatio}
    >
      <path
        fill="none"
        stroke={props.color}
        strokeWidth={adustedStrokeWidth}
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="M66.6,38.5C49.7,9.3,42.8,5.2,42.8,5.2S35.9,9.3,19,38.5C2.1,67.7,2.1,75.8,2.1,75.8s7,4,40.7,4
			c33.7,0,40.7-4,40.7-4S83.5,67.7,66.6,38.5z"
      />
      <path
        fill="none"
        stroke={props.color}
        strokeWidth={adustedStrokeWidth}
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="M66.6,38.5C49.7,9.3,42.8,5.2,42.8,5.2S35.9,9.3,19,38.5C2.1,67.7,2.1,75.8,2.1,75.8s7,4,40.7,4
			c33.7,0,40.7-4,40.7-4S83.5,67.7,66.6,38.5z"
      />
      <path
        fill="none"
        stroke={props.color}
        strokeWidth={adustedStrokeWidth}
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="M2.1,75.8c0,0,22.9-24.9,26.5-31.1c3.6-6.1,14.2-39.5,14.2-39.5S35.9,9.3,19,38.5C2.1,67.7,2.1,75.8,2.1,75.8
			z"
      />
      <path
        fill="none"
        stroke={props.color}
        strokeWidth={adustedStrokeWidth}
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="M42.8,5.2c0,0,10.7,33.3,14.2,39.5c2,3.4,10,12.7,16.7,20.2c5.3,6,9.8,10.8,9.8,10.8s0-3.7-5.3-15.1
			c-2.5-5.5-6.2-12.7-11.7-22.2C49.7,9.3,42.8,5.2,42.8,5.2z"
      />
      <polygon
        fill="none"
        stroke={props.color}
        strokeWidth={adustedStrokeWidth}
        strokeLinejoin="round"
        strokeMiterlimit="10"
        points="73.7,64.9 66.6,38.5 48.2,21.6 42.8,24.8 67.2,67 		"
      />
      <polygon
        fill="none"
        stroke={props.color}
        strokeWidth={adustedStrokeWidth}
        strokeLinejoin="round"
        strokeMiterlimit="10"
        points="17.2,73.1 42.8,79.7 67.4,72.9 67.4,66.6 18.6,66.7 		"
      />
      <path
        fill="none"
        stroke={props.color}
        strokeWidth={adustedStrokeWidth}
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="M73.7,64.9c5.3,6,9.8,10.8,9.8,10.8s0-3.7-5.3-15.1L73.7,64.9z"
      />
      <path
        fill="none"
        stroke={props.color}
        strokeWidth={adustedStrokeWidth}
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="M11.9,64.9c-5.3,6-9.8,10.8-9.8,10.8s0-3.7,5.3-15.1L11.9,64.9z"
      />
      <path
        fill="none"
        stroke={props.color}
        strokeWidth={adustedStrokeWidth}
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="M53.9,18.3C46.2,7.2,42.8,5.2,42.8,5.2s-3.4,2-11.1,13.1l11.1,6.5L53.9,18.3z"
      />
      <polygon
        fill="none"
        stroke={props.color}
        strokeWidth={adustedStrokeWidth}
        strokeLinejoin="round"
        strokeMiterlimit="10"
        points="11.9,64.9 19,38.5 37.4,21.6 42.8,24.8 18.4,67 		"
      />
      <path
        fill="none"
        stroke={props.color}
        strokeWidth={adustedStrokeWidth}
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="M57,44.7C46.9,27.2,42.8,24.8,42.8,24.8s-4.1,2.4-14.2,19.9C18.5,62.2,18.4,67,18.4,67s4.2,2.4,24.4,2.4
			C63,69.4,67.2,67,67.2,67S67.1,62.2,57,44.7z"
      />
      <path
        fill="none"
        stroke={props.color}
        strokeWidth={adustedStrokeWidth}
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="M83.5,75.8c0,0-33.6-6.4-40.7-6.4c-7.1,0-40.7,6.4-40.7,6.4s7,4,40.7,4C76.5,79.7,83.5,75.8,83.5,75.8z"
      />
      <line
        fill="none"
        stroke={props.color}
        strokeWidth={adustedStrokeWidth}
        strokeLinejoin="round"
        strokeMiterlimit="10"
        x1="67.4"
        y1="72.9"
        x2="66.5"
        y2="78.9"
      />
      <line
        fill="none"
        stroke={props.color}
        strokeWidth={adustedStrokeWidth}
        strokeLinejoin="round"
        strokeMiterlimit="10"
        x1="17.3"
        y1="72.8"
        x2="18.2"
        y2="78.7"
      />
      {props.show_diagram && (
        <>
          <line
            x1={x(0.5)}
            y1={y(0.05)}
            x2={x(0.5)}
            y2={y(0.94)}
            stroke={lineColor}
            strokeWidth=".5"
          ></line>
          <line
            x1={x(0.48)}
            y1={y(0.05)}
            x2={x(0.52)}
            y2={y(0.05)}
            stroke={lineColor}
            strokeWidth=".5"
          ></line>
          <line
            x1={x(0.48)}
            y1={y(0.94)}
            x2={x(0.52)}
            y2={y(0.94)}
            stroke={lineColor}
            strokeWidth=".5"
          ></line>
          <rect
            x={lengthX}
            y={lengthY}
            width="20"
            height="11"
            rx="0.8"
            fill="white"
          ></rect>
          <text
            x={lengthX + 2}
            y={lengthY + 4}
            fontSize=".4rem"
            stroke={lineColor}
            strokeWidth="0.01"
          >
            Length:
          </text>
          <text
            x={lengthX + 1}
            y={lengthY + 10}
            fontSize=".4rem"
            stroke={lineColor}
            strokeWidth="0.01"
          >
            {props.diamond.Measurement1}
          </text>

          <line
            x1={x(0.01)}
            y1={y(0.9)}
            x2={x(0.985)}
            y2={y(0.9)}
            stroke={lineColor}
            strokeWidth=".5"
          ></line>
          <line
            x1={x(0.01)}
            y1={y(0.88)}
            x2={x(0.01)}
            y2={y(0.92)}
            stroke={lineColor}
            strokeWidth=".5"
          ></line>
          <line
            x1={x(0.985)}
            y1={y(0.88)}
            x2={x(0.985)}
            y2={y(0.92)}
            stroke={lineColor}
            strokeWidth=".5"
          ></line>

          <rect
            x={widthX}
            y={widthY}
            width="19"
            height="11"
            rx="0.8"
            fill="white"
          ></rect>
          <text
            x={widthX + 2}
            y={widthY + 4}
            fontSize=".4rem"
            stroke={lineColor}
            strokeWidth="0.01"
          >
            Width:
          </text>
          <text
            x={widthX + 1}
            y={widthY + 10}
            fontSize=".4rem"
            stroke={lineColor}
            strokeWidth="0.01"
          >
            {props.diamond.Measurement2}
          </text>
        </>
      )}
    </svg>
  );
};

export default Trillion;
