import React from "react";
import { Button, Divider, Stack, Typography, Grid } from "@mui/material";
import { Link as DomLink } from "react-router-dom";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";
import remarkGfm from "remark-gfm";
import { stackPageSx } from "../../styles/AuctionHouseSx";
import { constants } from "../../../../../utils";
import useMediaQueryUtils from "../../../../../utils/mediaQueryUtils";

const strapiURL = constants.CMS_FRONT_IMAGE_URL;

function MyAuction(props) {
  const isMdScreen = useMediaQueryUtils("md");
  const {
    myAuctionTextTopValue,
    myAuctionHowItWorksImageValue,
    myAuctionFaqValue,
  } = props;
  return (
    <>
      <Grid container spacing={2}>
        {myAuctionTextTopValue && (
          <>
            <Grid item xs={12} md={6}>
              <Stack spacing={1}>
                <Typography variant="h3">
                  {myAuctionTextTopValue.title}
                </Typography>
                <Typography variant="staticText">
                  <ReactMarkdown remarkGfm={[remarkGfm]}>
                    {myAuctionTextTopValue.body}
                  </ReactMarkdown>
                </Typography>
                <Stack direction={isMdScreen ? "column" : "row"} spacing={1}>
                  <DomLink
                    to={{
                      pathname: "/harmony-auction-signup",
                    }}
                    className="center"
                  >
                    <Button color="success" variant="contained" size="small">
                      Get Free Plug-In
                    </Button>
                  </DomLink>
                  <DomLink
                    to={{
                      pathname: "/harmony-auction-signup",
                    }}
                    className="center"
                  >
                    <Button color="success" variant="outlined" size="small">
                      Get A Demo
                    </Button>
                  </DomLink>
                </Stack>
              </Stack>
            </Grid>
            <Grid item xs={12} md={6} marginTop={"3rem"} marginBottom={"3rem"}>
              <div>
                <iframe
                  width={!isMdScreen ? "560px" : "100%"}
                  height={!isMdScreen ? "315px" : "100%"}
                  src="https://www.youtube.com/embed/mpC8A53x1mo"
                  title="YouTube video player"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </div>
            </Grid>
          </>
        )}
        <Grid item xs={12}>
          <Divider flexItem sx={{ marginTop: "3rem", marginBottom: "3rem" }} />
          {myAuctionHowItWorksImageValue && (
            <img
              src={`${strapiURL}${myAuctionHowItWorksImageValue.url}`}
              width="100%"
              alt="banner"
            />
          )}
          <Divider flexItem sx={{ marginTop: "3rem" }} />
        </Grid>
        <Grid item xs={12}>
          {myAuctionFaqValue && (
            <Stack sx={stackPageSx}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <div>
                    <img
                      width="100%"
                      src={`${strapiURL}${myAuctionFaqValue.image.data.attributes.url}`}
                      alt="banner"
                    />
                  </div>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Stack spacing={1}>
                    <Typography variant="h3">
                      {myAuctionFaqValue.title}
                    </Typography>
                    <Typography variant="staticText">
                      <ReactMarkdown remarkGfm={[remarkGfm]}>
                        {myAuctionFaqValue.body}
                      </ReactMarkdown>
                    </Typography>
                  </Stack>
                </Grid>
              </Grid>
            </Stack>
          )}
        </Grid>
      </Grid>
    </>
  );
}

export default MyAuction;
