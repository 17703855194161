// Third-party libraries
import React from "react";
import Tooltip from "@mui/material/Tooltip";
// Local modules
import StringConstants from "../utils/constants";
import FacebookIcon from "../assets/images/icons/icon-FB.png";
import LinkedInIcon from "../assets/images/icons/icon-LinkedIn.png";
import YouTubeIcon from "../assets/images/icons/icon-YouTube.png";
import ConflictFree from "../assets/images/icons/conflictfree.svg";
import ConflictFreeReversed from "../assets/images/icons/conflictfree-reversed.svg";
import Details from "../assets/images/icons/details.svg";
import DetailsReversed from "../assets/images/icons/details-reversed.svg";
import DiestruckIcon from "../assets/images/icons/icon-diestruck.svg";
import BestSellerIcon from "../assets/images/icons/icon-best-seller.svg";
import CastIcon from "../assets/images/icons/icon-cast.svg";
import HarmonyIcon from "../assets/images/icons/icon-harmony.svg";
import NewIcon from "../assets/images/icons/icon-new.svg";
import TruseatIcon from "../assets/images/icons/icon-truseat.svg";
import LabGrown from "../assets/images/icons/labgrown.svg";
import Mined from "../assets/images/icons/mined.svg";
import Canadian from "../assets/images/icons/canada-hs.svg";
import Recycled from "../assets/images/icons/recycled-hs.svg";
import USFlag from "../assets/images/icons/flag-hs.svg";
import Diamond360 from "../assets/images/icons/diamond-360.svg";
import NonHarmonyIcon from "../assets/images/icons/icon-non-harmony.svg";
import PolishedIcon from "../assets/images/icons/icon-polished.svg";
import Beaker1 from "../assets/images/icons/Icon_LabGrownLG.png";
import Signature from "../assets/images/icons/icon-HS-sig.png";
import MetalMoldIcon from "../assets/images/icons/icon-metalmold.svg";
import GetItFastIcon from "../assets/images/icons/icon-GetItFast.svg";
import AS from "../assets/images/icons/ag.svg";
import SCS from "../assets/images/icons/SCS-icon-8-2-22.svg";
import EZFitIcon from "../assets/images/icons/icon-ezfit.svg";

const medium = StringConstants.SIZE_MEDIUM;

export function SpaceIcon() {
  return <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>;
}

//Settings Icons
export function Diestruck() {
  return <img alt="Diestruck" src={DiestruckIcon} className="settingsIcons" />;
}

export function Bestseller() {
  return (
    <img alt="Best Seller" src={BestSellerIcon} className="settingsIcons" />
  );
}

export function Cast() {
  return <img alt="Cast" src={CastIcon} className="settingsIcons" />;
}

export function GetItFast() {
  return (
    <img alt="Get It Fast" src={GetItFastIcon} className="settingsIcons" />
  );
}

export function Harmony() {
  return <img alt="Harmony" src={HarmonyIcon} className="settingsIcons" />;
}

export function New() {
  return <img alt="New" src={NewIcon} className="settingsIcons" />;
}

export function Truseat() {
  return <img alt="Truseat" src={TruseatIcon} className="settingsIcons" />;
}

export function NonHarmony() {
  return (
    <img alt="Non Harmony" src={NonHarmonyIcon} className="settingsIcons" />
  );
}

export function Polished() {
  return <img alt="Polished" src={PolishedIcon} className="settingsIcons" />;
}

export function EZFit() {
  return <img alt="EZ Fit" src={EZFitIcon} className="settingsIcons" />;
}

export function MetalMold() {
  return <img alt="Metal Mold" src={MetalMoldIcon} className="settingsIcons" />;
}

export function Facebook() {
  return (
    <Tooltip title="Facebook" placement="top">
      <img
        alt="Facebook"
        src={FacebookIcon}
        className="vertical-align-middle"
      />
    </Tooltip>
  );
}

export function LinkedIn() {
  return (
    <Tooltip title="LinkedIn" placement="top">
      <img
        alt="LinkedIn"
        src={LinkedInIcon}
        className="vertical-align-middle"
      />
    </Tooltip>
  );
}

export function YouTube() {
  return (
    <Tooltip title="YouTube" placement="top">
      <img alt="YouTube" src={YouTubeIcon} className="vertical-align-middle" />
    </Tooltip>
  );
}

export function ConflictFreeReversedIcon() {
  return (
    <Tooltip title="Conflict Free" placement="top-start">
      <img
        alt="CF"
        src={ConflictFreeReversed}
        className="vertical-align-middle width25px"
      />
    </Tooltip>
  );
}

export function DetailsIcon() {
  return (
    <Tooltip title="Details" placement="top-start">
      <img
        alt="Details"
        src={Details}
        className="vertical-align-middle width20px"
      />
    </Tooltip>
  );
}

export function DetailsReversedIcon() {
  return (
    <Tooltip title="Details" placement="top-start">
      <img
        alt="Details"
        src={DetailsReversed}
        className="vertical-align-middle width20px"
      />
    </Tooltip>
  );
}

export function LabGrownIcon(props) {
  const iconWidth = props.size === medium ? "30px" : "20px";
  return (
    <Tooltip title="Lab Grown" placement="top-start">
      <img
        alt="LG"
        src={LabGrown}
        width={iconWidth}
        className="vertical-align-middle width20px"
      />
    </Tooltip>
  );
}

export function MinedIcon(props) {
  return (
    <Tooltip title="Mined" placement="top-start">
      <img
        alt="M"
        src={Mined}
        className="vertical-align-middle width20px"
        width={props.size === medium ? "30px" : "20px"}
      />
    </Tooltip>
  );
}

export function Diamond360Icon(props) {
  const iconWidth = props.size === "regular" ? "48px" : "25px";
  return (
    <Tooltip title="360" placement="top-start">
      <img
        alt="360"
        src={Diamond360}
        width={iconWidth}
        className="vertical-align-middle"
      />
    </Tooltip>
  );
}

export function RecycleIcon(props) {
  const iconWidth = props.size === medium ? "30px" : "20px";
  const cssContainerClass =
    props.size === medium
      ? "icon-container icon-container--medium"
      : "icon-container  vertical-align-middle";
  const cssIconClass = props.size === medium ? "icon icon--medium" : "icon ";
  const icon = (
    <Tooltip title="Harmony Recycled" placement="top-start">
      <img
        alt="Harmony Recycled"
        src={Recycled}
        width={iconWidth}
        className=" vertical-align-middle"
      />
    </Tooltip>
  );
  if (props.show_text) {
    return (
      <div className={cssContainerClass}>
        <div className={cssIconClass}>{icon}</div>
        <div className="icon-text icon-text--green font-size-regular">
          Harmony Recycled
        </div>
      </div>
    );
  } else {
  }
  return icon;
}

export function BeakerIcon(props) {
  const iconWidth = props.size === medium ? "50px" : "20px";
  const cssContainerClass =
    props.size === medium
      ? "icon-container icon-container--medium"
      : "icon-container  vertical-align-middle";
  const cssIconClass = props.size === medium ? "icon icon--medium" : "icon ";
  const icon = (
    <Tooltip title="Lab Grown" placement="top-start">
      <img
        alt="Lab Grown"
        src={Beaker1}
        width={iconWidth}
        className=" vertical-align-middle"
      />
    </Tooltip>
  );
  if (props.show_text) {
    return (
      <div className={cssContainerClass}>
        <div className={cssIconClass}>{icon}</div>
        <div className="icon-text icon-text--green font-size-regular">
          Lab Grown
        </div>
      </div>
    );
  } else {
  }
  return icon;
}

export function SignatureIcon(props) {
  const iconWidth = props.size === medium ? "35px" : "20px";
  const cssContainerClass =
    props.size === medium
      ? "icon-container icon-container--medium"
      : "icon-container  vertical-align-middle";
  const cssIconClass = props.size === medium ? "icon icon--medium" : "icon ";
  const icon = (
    <Tooltip title="Signature" placement="top-start">
      <img
        alt="Signature"
        src={Signature}
        width={iconWidth}
        className=" vertical-align-middle"
      />
    </Tooltip>
  );
  if (props.show_text) {
    return (
      <div className={cssContainerClass}>
        <div className={cssIconClass}>{icon}</div>
        <div className="icon-text icon-text--green font-size-regular">
          Signature
        </div>
      </div>
    );
  } else {
  }
  return icon;
}

export function ConflictFreeIcon(props) {
  const iconWidth = props.size === medium ? "30px" : "25px";
  const cssContainerClass =
    props.size === medium
      ? "icon-container icon-container--medium"
      : "icon-container  vertical-align-middle";
  const cssIconClass = props.size === medium ? "icon icon--medium" : "icon ";
  const icon = (
    <Tooltip title="Conflict Free" placement="top-start">
      <img
        alt="CF"
        src={ConflictFree}
        width={iconWidth}
        className=" vertical-align-middle"
      />
    </Tooltip>
  );
  if (props.show_text) {
    return (
      <div className={cssContainerClass}>
        <div className={cssIconClass}>{icon}</div>
        <div className="icon-text font-size-regular">Conflict Free</div>
      </div>
    );
  } else {
  }
  return icon;
}

export function CanadaIcon(props) {
  const iconWidth = props.size === medium ? "30px" : "20px";
  const cssContainerClass =
    props.size === medium
      ? "icon-container icon-container--medium"
      : "icon-container  vertical-align-middle";
  const cssIconClass = props.size === medium ? "icon icon--medium" : "icon ";
  const icon = (
    <Tooltip title="Canadian" placement="top-start">
      <img
        alt="Canada"
        src={Canadian}
        width={iconWidth}
        className=" vertical-align-middle"
      />
    </Tooltip>
  );
  if (props.show_text) {
    return (
      <div className={cssContainerClass}>
        <div className={cssIconClass}>{icon}</div>
        <div className="icon-text font-size-regular">Canadian</div>
      </div>
    );
  } else {
  }
  return icon;
}

export function USFlagIcon(props) {
  const flagWidth = props.size === medium ? "42px" : "20px";
  const cssClass =
    props.size === medium ? "us-flag us-flag--medium" : "us-flag ";
  const flag = (
    <Tooltip title="U.S. Grown" placement="top-start">
      <img
        alt="U.S."
        src={USFlag}
        width={flagWidth}
        className=" vertical-align-middle"
      />
    </Tooltip>
  );
  if (props.show_text) {
    return (
      <div className={cssClass}>
        {flag}
        <div className="grown-in-usa font-size-regular">Grown in the USA</div>
      </div>
    );
  } else {
    return flag;
  }
}

export function ASIcon(props) {
  const iconWidth = props.size === medium ? "30px" : "20px";
  const cssContainerClass =
    props.size === medium
      ? "icon-container icon-container--medium"
      : "icon-container  vertical-align-middle";
  const cssIconClass = props.size === medium ? "icon icon--medium" : "icon ";
  const icon = (
    <Tooltip title="As Grown (Untreated)" placement="top-start">
      <img
        alt="AS"
        src={AS}
        width={iconWidth}
        className=" vertical-align-middle"
      />
    </Tooltip>
  );
  if (props.show_text) {
    return (
      <div className={cssContainerClass}>
        <div className={cssIconClass}>{icon}</div>
        <div className="icon-text icon-text--green font-size-regular">
          As Grown (Untreated)
        </div>
      </div>
    );
  } else {
  }
  return icon;
}

export function SCSIcon(props) {
  const iconWidth = props.size === medium ? "30px" : "20px";
  const cssContainerClass =
    props.size === medium
      ? "icon-container icon-container--medium"
      : "icon-container";
  const cssIconClass = props.size === medium ? "icon icon--medium" : "icon";
  const icon = (
    <Tooltip title="SCS Certified Grower" placement="top-start">
      <img
        alt="SCS"
        src={SCS}
        width={iconWidth}
        className="vertical-align-middle"
      />
    </Tooltip>
  );
  if (props.show_text) {
    return (
      <div className={cssContainerClass}>
        <div className={cssIconClass}>{icon}</div>
        <div className="icon-text icon-text--green font-size-regular">
          SCS Certified Grower
        </div>
      </div>
    );
  } else {
  }
  return icon;
}
