import { createContext } from "react";

const AppContext = createContext();

const CartContext = createContext();

const CreateAccountContext = createContext();

const FormContext = createContext();

const ProductLandingContext = createContext();

const BuildARingContext = createContext();

const DiamondSearchContext = createContext();

const MeleeSearchContext = createContext();

const ProductsContext = createContext();

const BandsContext = createContext();

export {
  AppContext,
  CartContext,
  CreateAccountContext,
  FormContext,
  ProductLandingContext,
  BuildARingContext,
  DiamondSearchContext,
  MeleeSearchContext,
  ProductsContext,
  BandsContext,
};
