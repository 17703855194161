import React from "react";

const RoseProfile = (props) => {
  const viewBoxWidth = 85.7;
  const viewBoxHeight = 86.1;

  const x = (percentX) => percentX * viewBoxWidth;
  const y = (percentY) => percentY * viewBoxHeight;

  const lineColor = "#4a4a4a";

  const depthPercent = Number(props.diamond.Depth).toFixed(1);
  const girdle = props.diamond.Girdle || "";
  const girdleSpaceIndex = girdle.indexOf(" ");
  const girdle1 =
    girdleSpaceIndex > 0 ? girdle.slice(0, girdleSpaceIndex) : girdle;
  const girdle2 = girdleSpaceIndex > 0 ? girdle.slice(girdleSpaceIndex) : "";

  const diagramStrokeWidth = "0.01";
  const diagramFontSize = 0.046;
  const strokeWidth2 = "0.5";

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox={"0 0 " + viewBoxWidth + " " + viewBoxHeight}
      width={props.width}
      height={props.height}
      preserveAspectRatio={props.preserveAspectRatio}
    >
      <path
        fill={props.color}
        stroke={props.color}
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="0.0"
        d="M43.4,26.2L43.4,26.2C43.4,26.2,43.4,26.2,43.4,26.2L43.4,26.2c-0.1,0-0.1,0-0.1,0.1l0,0l0,0
			c-0.1,0-0.1,0-0.1,0h-0.1l0,0c-0.1,0-0.1,0.1-0.1,0.1l0,0l0,0c-0.1,0-0.1,0-0.1,0l0,0l0,0L23.2,39.9h-0.1l0,0l0,0l0,0l0,0V40l0,0
			c0,0,0,0-0.1,0l0,0l0,0l0,0l-0.2,0.2L3,57.2l0,0l0,0c0,0,0,0-0.1,0.1c0,0,0,0,0,0.1H2.8l0,0c0,0.1,0,0.1-0.1,0.1v0.1
			c0,0,0,0,0,0.1l0,0c0,0,0,0,0,0.1c-0.1,0-0.1,0-0.1,0.1c0,0,0,0,0,0.1l0,0l0,0v0.1l0,0v2.7c0,0.4,0.4,0.7,0.7,0.7h5.4h14.6h20h20
			h14.6h5.4c0.4,0,0.7-0.3,0.7-0.7v-2.7l0,0v-0.1c0,0,0,0,0-0.1l0,0c0,0,0,0-0.1-0.1l0,0c0,0,0,0,0-0.1l0,0c0-0.1,0-0.1,0-0.1l0,0
			l-0.1-0.1l0,0v-0.1c0,0-0.1,0-0.1-0.1l0,0l0,0c0,0-0.1,0-0.1-0.1l0,0l0,0l0,0h-0.1L63.8,40l0,0l0,0l0,0l0,0c-0.1,0-0.1,0-0.1,0
			l0,0v-0.1l0,0l0,0l0,0l0,0c0,0,0,0-0.1,0l0,0l0,0L43.8,26.3l0,0l0,0l0,0h-0.1c0-0.1-0.1-0.1-0.1-0.1s0,0-0.1,0l0,0
			c0,0-0.1,0-0.1-0.1l0,0c0,0,0,0-0.1,0l0,0l0,0C43.5,26.2,43.5,26.2,43.4,26.2L43.4,26.2L43.4,26.2z M42.8,28.3v11.5H25.9
			L42.8,28.3z M44.1,28.3L61,39.8H44.1V28.3z M24.3,41.3h17.2L24.2,56.1L24.3,41.3z M45.4,41.3h17.2l0.1,14.9L45.4,41.3z M42.8,42.1
			V57H25.4L42.8,42.1z M44.1,42.1L61.5,57H44.1V42.1z M22.9,42.5L22.7,57H10.3L22.9,42.5z M64,42.5L76.5,57H64.2L64,42.5z M17,47.1
			L8.5,57H5.4L17,47.1z M69.9,47.1L81.5,57h-3.1L69.9,47.1z M4.2,58.4h3.9v1.3H4.2V58.4z M9.5,58.4h13.2v1.3H9.5V58.4z M24.1,58.4
			h18.6v1.3H24.1V58.4z M44.1,58.4h18.6v1.3H44.1V58.4z M64.2,58.4h13.2v1.3H64.2V58.4z M78.8,58.4h3.9v1.3h-3.9V58.4z"
      />
      {props.show_diagram && (
        <>
          {" "}
          {/* Left Depth */}
          <line
            x1={x(0.06)}
            x2={x(0.06)}
            y1={y(0.32)}
            y2={y(0.72)}
            stroke={lineColor}
            strokeWidth={strokeWidth2}
          ></line>
          <line
            x1={x(0.04)}
            x2={x(0.08)}
            y1={y(0.32)}
            y2={y(0.32)}
            stroke={lineColor}
            strokeWidth={strokeWidth2}
          ></line>
          <line
            x1={x(0.04)}
            x2={x(0.08)}
            y1={y(0.72)}
            y2={y(0.72)}
            stroke={lineColor}
            strokeWidth={strokeWidth2}
          ></line>
          <rect
            x={x(0.005)}
            y={y(0.43)}
            width={x(0.22)}
            height={y(0.13)}
            rx="0.8"
            fill="white"
          ></rect>
          <text
            x={x(0.03)}
            y={y(0.475)}
            fontSize={y(diagramFontSize)}
            stroke={lineColor}
            strokeWidth={diagramStrokeWidth}
          >
            Depth:
          </text>
          <text
            x={x(0.02)}
            y={y(0.542)}
            fontSize={y(diagramFontSize)}
            stroke={lineColor}
            strokeWidth={diagramStrokeWidth}
          >
            {props.diamond.Measurement3}
          </text>
          {/* Right Depth */}
          <line
            x1={x(0.94)}
            x2={x(0.94)}
            y1={y(0.32)}
            y2={y(0.72)}
            stroke={lineColor}
            strokeWidth={strokeWidth2}
          ></line>
          <line
            x1={x(0.92)}
            x2={x(0.96)}
            y1={y(0.32)}
            y2={y(0.32)}
            stroke={lineColor}
            strokeWidth={strokeWidth2}
          ></line>
          <line
            x1={x(0.92)}
            x2={x(0.96)}
            y1={y(0.72)}
            y2={y(0.72)}
            stroke={lineColor}
            strokeWidth={strokeWidth2}
          ></line>
          <rect
            x={x(0.8)}
            y={y(0.43)}
            width={x(0.19)}
            height={y(0.13)}
            rx="0.8"
            fill="white"
          ></rect>
          <text
            x={x(0.81)}
            y={y(0.475)}
            fontSize={y(diagramFontSize)}
            stroke={lineColor}
            strokeWidth={diagramStrokeWidth}
          >
            Depth:
          </text>
          <text
            x={x(0.81)}
            y={y(0.542)}
            fontSize={y(diagramFontSize)}
            stroke={lineColor}
            strokeWidth={diagramStrokeWidth}
          >
            {depthPercent}%
          </text>
          {/* Culet */}
          {props.Culet > 0 && (
            <>
              <circle
                cx={x(0.51)}
                cy={y(0.32)}
                r={y(0.03)}
                fillOpacity="0"
                stroke={lineColor}
                strokeWidth={strokeWidth2}
              />
              <line
                x1={x(0.51)}
                x2={x(0.51)}
                y1={y(0.25)}
                y2={y(0.29)}
                stroke={lineColor}
                strokeWidth={strokeWidth2}
              ></line>
              <line
                x1={x(0.51)}
                x2={x(0.55)}
                y1={y(0.25)}
                y2={y(0.25)}
                stroke={lineColor}
                strokeWidth={strokeWidth2}
              ></line>

              <text
                x={x(0.56)}
                y={y(0.26)}
                fontSize={y(diagramFontSize)}
                stroke={lineColor}
                strokeWidth={diagramStrokeWidth}
              >
                Culet: {props.diamond.Culet}
              </text>
            </>
          )}
          {/* Girdle */}
          {girdle1.length > 0 && (
            <>
              <circle
                cx={x(0.4)}
                cy={y(0.69)}
                r={y(0.03)}
                fillOpacity="0"
                stroke={lineColor}
                strokeWidth={strokeWidth2}
              />
              <line
                x1={x(0.4)}
                x2={x(0.4)}
                y1={y(0.72)}
                y2={y(0.83)}
                stroke={lineColor}
                strokeWidth={strokeWidth2}
              ></line>
              <line
                x1={x(0.4)}
                x2={x(0.38)}
                y1={y(0.83)}
                y2={y(0.83)}
                stroke={lineColor}
                strokeWidth={strokeWidth2}
              ></line>

              <text
                x={x(0.0)}
                y={y(0.82)}
                fontSize={y(diagramFontSize)}
                stroke={lineColor}
                strokeWidth={diagramStrokeWidth}
              >
                Girdle: {girdle1}
              </text>
              <text
                x={x(0.0)}
                y={y(0.88)}
                fontSize={y(diagramFontSize)}
                stroke={lineColor}
                strokeWidth={diagramStrokeWidth}
              >
                {girdle2}
              </text>
            </>
          )}
        </>
      )}
    </svg>
  );
};

export default RoseProfile;
