// Third-party libraries
import React, { useEffect, useState, useRef } from "react";
import { Link as DomLink } from "react-router-dom";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { IconButton, Stack, Tooltip, Typography } from "@mui/material";

// Local modules
import { storage } from "../../../utils";
import styleCodes from "../../../utils/styleCodes";

export default function HorizontalScrollList(props) {
  let scroll = useRef(null);
  const [scrollX, setScrollX] = useState(0);
  const [scrollEnd, setScrollEnd] = useState(false);
  const [styles, setStyles] = useState([]);
  // const coinBullionProduct = (product) => {
  //   return _.includes(["ML", "BUL", "DSK", "AE"], product);
  // };

  //Slide click
  const slide = (shift) => {
    scroll.current.scrollLeft += shift;
    setScrollX(scrollX + shift);

    if (
      Math.floor(scroll.current.scrollWidth - scroll.current.scrollLeft) <=
      scroll.current.offsetWidth
    ) {
      setScrollEnd(true);
    } else {
      setScrollEnd(false);
    }
  };

  const scrollCheck = () => {
    setScrollX(scroll.current.scrollLeft);
    if (
      Math.floor(scroll.current.scrollWidth - scroll.current.scrollLeft) <=
      scroll.current.offsetWidth
    ) {
      setScrollEnd(true);
    } else {
      setScrollEnd(false);
    }
  };

  function isMill(style) {
    return styleCodes.millProducts.includes(style);
  }

  useEffect(() => {
    const stylesToStore = props.loadedStyles.filter(function (style) {
      return style !== null;
    });
    setStyles(stylesToStore);
  }, [props.compatible, props.loadedStyles, props.related]);

  return (
    <Stack direction="row">
      {scrollX !== 0 && (
        <IconButton sx={{ borderRadius: 0 }} onClick={() => slide(-100)}>
          <ChevronLeftIcon sx={{ verticalAlign: "middle" }} />
        </IconButton>
      )}
      <ul
        className="horizontal-list pointer"
        ref={scroll}
        onScroll={scrollCheck}
      >
        {styles &&
        styles !== null &&
        styles !== undefined &&
        styles.length !== 0 ? (
          styles.map((style) => (
            <Tooltip
              title={`${style.description}`}
              placement="top"
              key={style.style}
            >
              <DomLink
                to={{
                  pathname:
                    style.style === "SOLDER"
                      ? `/group/SOLDER`
                      : //: coinBullionProduct(style.style)
                        //? `/product/COINS BULLION`
                        `/product/${style.style}`,
                  state: {
                    productGroup: props.loadedStyles.productGroup,
                    recentlyViewed: JSON.parse(
                      storage.getSessionStorageItem("hsRecentlyViewedProducts")
                    ),
                  },
                }}
              >
                <li variant="outlined" className="items">
                  {style.images && (
                    <img
                      className="related-items"
                      alt="Related Product"
                      src={style.images[0]}
                      width="100"
                      height="100"
                    />
                  )}
                  <Typography className="style-text center">{`${
                    isMill(style.style) ? style.description : style.style
                  }`}</Typography>
                </li>
              </DomLink>
            </Tooltip>
          ))
        ) : (
          <Typography sx={{ marginLeft: "1rem" }}>No items to show!</Typography>
        )}
      </ul>
      {!scrollEnd && styles !== undefined && styles !== null ? (
        <IconButton sx={{ borderRadius: 0 }} onClick={() => slide(+100)}>
          <ChevronRightIcon sx={{ verticalAlign: "middle" }} />
        </IconButton>
      ) : (
        <></>
      )}
    </Stack>
  );
}
