import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { Link as DomLink } from "react-router-dom";
import { constants } from "../../../../utils";
import { storage } from "../../../../utils";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Button,
  Card,
  CardMedia,
  CardContent,
  Typography,
  Stack,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";

const frontImageUrl = constants.CMS_FRONT_IMAGE_URL;

function LeftCopyBox(leftCopyBox, accordian) {
  return (
    <Card sx={{ backgroundColor: "#e3e3e3", width: "100%" }}>
      <Grid container alignItems="center">
        <Grid item xs={12} md={6}>
          <CardMedia
            sx={{ width: "100%", height: "100%" }}
            component="img"
            image={`${frontImageUrl}${leftCopyBox.image.data.attributes.url}`}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <CardContent sx={{ width: "100%" }}>
            <Stack spacing={3} sx={{ margin: "1rem" }}>
              <Typography variant="articleTitle" align="center">
                {leftCopyBox.title}
              </Typography>
              {accordian ? (
                <Stack spacing={3}>
                  <ReactMarkdown remarkPlugins={[remarkGfm]}>
                    {leftCopyBox.body}
                  </ReactMarkdown>
                  <Stack>
                    {leftCopyBox.accordians &&
                      leftCopyBox.accordians.map((accordian, index) => (
                        <Accordion key={index}>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            sx={{ backgroundColor: "#f0f0f0" }}
                          >
                            <Typography variant="landingAccordianTitle">
                              {accordian.title}
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Stack>
                              <Typography variant="articleText">
                                <ReactMarkdown remarkPlugins={[remarkGfm]}>
                                  {accordian.body}
                                </ReactMarkdown>
                              </Typography>
                              {accordian.image && (
                                <img
                                  src={`${frontImageUrl}${accordian.image.data.attributes.url}`}
                                  width="100%"
                                  alt="banner"
                                />
                              )}
                            </Stack>
                          </AccordionDetails>
                        </Accordion>
                      ))}
                  </Stack>
                </Stack>
              ) : (
                <>
                  <Typography variant="staticText" align="center">
                    <ReactMarkdown remarkGfm={[remarkGfm]}>
                      {leftCopyBox.body}
                    </ReactMarkdown>
                  </Typography>
                  {leftCopyBox.button && (
                    <DomLink
                      to={{
                        pathname: `${leftCopyBox.button.to}`,
                        state: {
                          recentlyViewed: JSON.parse(
                            storage.getSessionStorageItem(
                              "hsRecentlyViewedProducts"
                            )
                          ),
                        },
                      }}
                      className="center"
                    >
                      <Button variant="contained">
                        {leftCopyBox.button.text}
                      </Button>
                    </DomLink>
                  )}
                </>
              )}
            </Stack>
          </CardContent>
        </Grid>
      </Grid>
    </Card>
  );
}

export default LeftCopyBox;
