// Third-party libraries
import React, { useContext } from "react";
import Select from "react-select";
import { Box, Grid, Stack, Typography, Paper, Button } from "@mui/material";

// Local modules
import { MeleeSearchContext } from "../../utils/stateHandlers/contexts";
import {
  CanadaIcon,
  RecycleIcon,
  LabGrownIcon,
  MinedIcon,
  BeakerIcon,
  SignatureIcon,
} from "../Icons";
import ShapeFilter from "./MeleeShapeFilter";

function MeleeFilter(props) {
  const { meleeState, meleeSearchDispatch } = useContext(MeleeSearchContext);
  const { searchQuery, collections, matchedPairs, filters } = meleeState;

  function updateFilters(filterName, filterValues) {
    const filterToUpdate = searchQuery.fields.find(function (field) {
      return field.name === filterName;
    });
    if (filterToUpdate) {
      filterToUpdate.values =
        filterName !== "Quality" ? [filterValues] : filterValues;
      meleeSearchDispatch({
        type: "updateSearchQuery",
        payload: searchQuery.fields,
      });
    } else {
      const fields = [
        ...searchQuery.fields,
        {
          name: filterName,
          values: filterName !== "Quality" ? [filterValues] : filterValues,
        },
      ];
      meleeSearchDispatch({ type: "updateSearchQuery", payload: fields });
    }
  }

  return (
    <>
      <Grid container justifyContent="center" sx={{ paddingTop: "2rem" }}>
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: {
              sm: "repeat(1, 1fr)",
              md: "repeat(3, 1fr)",
              lg: "repeat(3, 1fr)",
              xl: "repeat(3, 1fr)",
            },
          }}
        >
          {filters.map((filter, index) => (
            <Stack
              key={index}
              direction="row"
              spacing={2}
              sx={{ marginLeft: "2rem" }}
            >
              {filter.name === "ShapeCode" ? (
                <>
                  <Typography sx={{ width: "12%", textAlign: "center" }}>
                    {filter.label}
                  </Typography>
                  <Box
                    component="span"
                    sx={{
                      paddingLeft: "3rem",
                      paddingRight: "3rem",
                      width: "93%",
                    }}
                  >
                    {filter.type === "options" && filter.label === "Shape" ? (
                      <ShapeFilter updateFilters={updateFilters} />
                    ) : null}
                  </Box>
                </>
              ) : filter.name === "CaratRange" ? (
                <>
                  <Typography sx={{ width: "15%" }}>{filter.label}</Typography>
                  <div style={{ width: "75%" }}>
                    <Select
                      key={filter.name}
                      value={props.caratSelect}
                      onChange={(e) => {
                        props.setCaratSelect(e);
                        props.updateDropdownFilters(filter.name, e);
                      }}
                      options={filter.values}
                      //isClearable
                      isDisabled={props.mmSelect === null ? false : true}
                      theme={(theme) => ({
                        ...theme,
                        borderRadius: 0,
                        colors: {
                          ...theme.colors,
                          primary25: "#cfdde9",
                          primary: "#124760",
                        },
                      })}
                    />
                  </div>
                </>
              ) : filter.name === "MmRange" ? (
                <>
                  <Typography sx={{ width: "17%" }}>{filter.label}</Typography>
                  <div style={{ width: "75%" }}>
                    <Select
                      key={filter.name}
                      value={props.mmSelect}
                      onChange={(e) => {
                        props.setMmSelect(e);
                        props.updateDropdownFilters(filter.name, e);
                      }}
                      options={filter.values}
                      //isClearable
                      isDisabled={props.caratSelect === null ? false : true}
                      theme={(theme) => ({
                        ...theme,
                        borderRadius: 0,
                        colors: {
                          ...theme.colors,
                          primary25: "#cfdde9",
                          primary: "#124760",
                        },
                      })}
                    />
                  </div>
                </>
              ) : null}
            </Stack>
          ))}
        </Box>
      </Grid>
      <Grid container justifyContent="center" sx={{ paddingTop: "1rem" }}>
        <Stack direction="row" spacing={2}>
          <Box width="80%">
            <Paper
              variant="outlined"
              square
              sx={{ padding: "2rem", minHeight: "33rem" }}
            >
              <Stack justifyContent="center" alignItems="center" spacing={2}>
                <Typography variant="mediumHeader">
                  <MinedIcon size="medium" /> Mined Diamonds
                </Typography>
              </Stack>
              <Stack direction="row" spacing={2} sx={{ marginTop: "2rem" }}>
                <Grid item xs={5}>
                  <Stack spacing={2}>
                    <Stack direction="row" spacing={1} alignItems="center">
                      <Typography>
                        <SignatureIcon size="medium" />
                      </Typography>
                      <Typography
                        variant="headerFont"
                        sx={{ width: "100%", lineHeight: "1.8rem" }}
                      >
                        Signature Melee
                      </Typography>
                    </Stack>
                    <Typography variant="cellFont" sx={{ width: "100%" }}>
                      Conflict-free, premium cut melee, strictly sorted and
                      graded, calibrated to 0.10mm
                    </Typography>
                  </Stack>
                </Grid>
                <Grid item xs={7}>
                  <Stack>
                    {collections.Signature &&
                      collections.Signature.map((signature, index) => (
                        <Button
                          key={index}
                          variant={
                            props.buttons.find(
                              (button) => button === signature.value
                            )
                              ? "contained"
                              : "outlined"
                          }
                          sx={{ width: "125px" }}
                          onClick={() =>
                            props.clickButton(signature, "Signature")
                          }
                        >
                          {signature.label}
                        </Button>
                      ))}
                  </Stack>
                </Grid>

                <Grid item xs={5}>
                  <Stack spacing={2}>
                    <Stack direction="row" spacing={1} alignItems="center">
                      <Typography>
                        <CanadaIcon size="medium" />
                      </Typography>
                      <Typography
                        variant="headerFont"
                        sx={{ width: "100%", lineHeight: "1.8rem" }}
                      >
                        Canadian Melee
                      </Typography>
                    </Stack>
                    <Typography variant="cellFont" sx={{ width: "100%" }}>
                      Canadian origin melee diamonds mined in accordane with the
                      highest environmental standards in the world; calibrated
                      to 0.10mm
                    </Typography>
                  </Stack>
                </Grid>
                <Grid item xs={7}>
                  <Stack>
                    {collections.Canadian &&
                      collections.Canadian.map((canadian, index) => (
                        <Button
                          key={index}
                          variant={
                            props.buttons.find(
                              (button) => button === canadian.value
                            )
                              ? "contained"
                              : "outlined"
                          }
                          sx={{ width: "125px" }}
                          onClick={() =>
                            props.clickButton(canadian, "Canadian")
                          }
                        >
                          {canadian.label}
                        </Button>
                      ))}
                  </Stack>
                </Grid>

                <Grid item xs={5}>
                  <Stack spacing={2}>
                    <Stack direction="row" spacing={1} alignItems="center">
                      <Typography>
                        <RecycleIcon size="medium" />
                      </Typography>
                      <Typography
                        variant="headerFont"
                        sx={{ width: "100%", lineHeight: "1.8rem" }}
                      >
                        Harmony Melee
                      </Typography>
                    </Stack>
                    {matchedPairs ? (
                      <Typography variant="cellFont" sx={{ width: "100%" }}>
                        Call to confirm availability of Harmony matched pairs.
                      </Typography>
                    ) : (
                      <Typography variant="cellFont" sx={{ width: "100%" }}>
                        Recycled melee diamonds, the most eco-friendly natural
                        diamonds available offered at discounted prices and
                        calibrated to 0.10mm
                      </Typography>
                    )}
                  </Stack>
                </Grid>
                <Grid item xs={7}>
                  <Stack>
                    {collections.Harmony &&
                      collections.Harmony.map((harmony, index) => (
                        <Button
                          key={index}
                          disabled={matchedPairs ? true : false}
                          variant={
                            props.buttons.find(
                              (button) => button === harmony.value
                            )
                              ? "contained"
                              : "outlined"
                          }
                          sx={{ width: "125px" }}
                          onClick={() => props.clickButton(harmony, "Harmony")}
                        >
                          {harmony.label}
                        </Button>
                      ))}
                  </Stack>
                </Grid>
              </Stack>
            </Paper>
          </Box>
          <Box width="40%">
            <Paper
              variant="outlined"
              square
              sx={{ padding: "2rem", minHeight: "33rem" }}
            >
              <Stack justifyContent="center" alignItems="center" spacing={1}>
                <Typography variant="mediumHeader">
                  <LabGrownIcon /> Lab-Grown Diamonds
                </Typography>
              </Stack>
              <Stack
                direction="row"
                justifyContent="center"
                sx={{ marginTop: "2rem" }}
                spacing={3}
              >
                <Grid item xs={4}>
                  <Stack spacing={2}>
                    <Stack direction="row" spacing={1} alignItems="center">
                      <Typography>
                        <BeakerIcon size="medium" />
                      </Typography>
                      <Typography
                        variant="headerFont"
                        sx={{ width: "100%", lineHeight: "1.8rem" }}
                      >
                        Lab-Grown Melee
                      </Typography>
                    </Stack>
                    <Typography variant="cellFont" sx={{ width: "100%" }}>
                      Lab grown diamonds, made using the most technologically
                      advanced and sustainable alternative HPHT process.
                    </Typography>
                  </Stack>
                </Grid>
                <Grid item xs={5}>
                  <Stack>
                    {collections.Lab &&
                      collections.Lab.map((lab, index) => (
                        <Button
                          key={index}
                          variant={
                            props.buttons.find((button) => button === lab.value)
                              ? "contained"
                              : "outlined"
                          }
                          sx={{ width: "125px" }}
                          onClick={() => props.clickButton(lab, "Lab")}
                        >
                          {lab.label}
                        </Button>
                      ))}
                  </Stack>
                </Grid>
              </Stack>
            </Paper>
          </Box>
        </Stack>
      </Grid>
    </>
  );
}

export default MeleeFilter;
