import { Stack, Typography } from "@mui/material";
import FancyColorButton from "./FancyColorButton";

export const DiamondColors = (props) => {
  const { switchToFancyColors } = props;
  return (
    <Stack direction="row" justifyContent={"flex-end"} marginBottom={"1rem"}>
      <FancyColorButton
        color={"White"}
        switchToFancyColors={switchToFancyColors}
      />
      <Typography
        sx={{
          alignContent: "center",
          margin: "1rem",
          fontWeight: "bold",
        }}
      >
        OR
      </Typography>
      <FancyColorButton
        color={"Yellow"}
        switchToFancyColors={switchToFancyColors}
      />
      <FancyColorButton
        color={"Pink"}
        switchToFancyColors={switchToFancyColors}
      />
      <FancyColorButton
        color={"Blue"}
        switchToFancyColors={switchToFancyColors}
      />
      <FancyColorButton
        color={"Green"}
        switchToFancyColors={switchToFancyColors}
      />
      <FancyColorButton
        color={"Brown"}
        switchToFancyColors={switchToFancyColors}
      />
    </Stack>
  );
};
