// Third-party libraries
import React from "react";
import { Typography } from "@mui/material";

// Local modules
import { storage } from "../../../utils";
import HorizontalScrollList from "../components/HorizontalScrollList";

function RecentlyView() {
  const RecentlyViewed = JSON.parse(
    storage.getSessionStorageItem("hsRecentlyViewedProducts")
  );
  return (
    <>
      <Typography variant="h2" sx={{ marginTop: "2rem" }}>
        Recently Viewed
      </Typography>
      {RecentlyViewed && <HorizontalScrollList loadedStyles={RecentlyViewed} />}
    </>
  );
}

export default RecentlyView;
