import React from "react";

const OldEuroProfile = (props) => {
  const viewBoxWidth = 85.7;
  const viewBoxHeight = 86.1;

  const lineColor = "#4a4a4a";
  const strokeWidth2 = "0.5";

  const x = (percentX) => percentX * viewBoxWidth;
  const y = (percentY) => percentY * viewBoxHeight;

  const table = Number(props.diamond.Table).toFixed(0);
  const depthPercent = Number(props.diamond.Depth).toFixed(1);
  const girdle = props.diamond.Girdle || "";
  const girdleSpaceIndex = girdle.indexOf(" ");
  const girdle1 =
    girdleSpaceIndex > 0 ? girdle.slice(0, girdleSpaceIndex) : girdle;
  const girdle2 = girdleSpaceIndex > 0 ? girdle.slice(girdleSpaceIndex) : "";

  const diagramStrokeWidth = "0.01";
  const diagramFontSize = 0.04;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox={"0 0 " + viewBoxWidth + " " + viewBoxHeight}
      width={props.width}
      height={props.height}
      preserveAspectRatio={props.preserveAspectRatio}
    >
      <path
        fill={props.color}
        strokeWidth={props.stroke_width}
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="M22.8,19c-0.1,0-0.3,0.1-0.4,0.2l-7,5.6l0,0l0,0v0.1l-13,10.4c-0.2,0.1-0.3,0.4-0.3,0.5v0.4l0,0l0,0v2.2l0,0l0,0v0.4
		c0,0.2,0.1,0.4,0.3,0.5l17.2,14.4l0,0l20.9,17.6c0.1,0.1,0.3,0.1,0.5,0.1h1.2l0,0h1.6h0.1H45c0.2,0,0.3,0,0.4-0.1c0,0,0,0,0.1,0
		l0,0c0,0,0,0,0-0.1l20.9-17.5v-0.1l17.2-14.4c0.1-0.1,0.2-0.3,0.2-0.5v-0.4l0,0l0,0v-2.2l0,0v-0.4c0-0.2-0.1-0.4-0.2-0.5l-13-10.5
		l-7-5.6C63.3,19.1,63.2,19,63,19h-5.9l0,0H43c0,0,0,0-0.1,0l0,0H28.8h-0.1l0,0H22.8z M39.9,20.5L32,24.4l-2.1-3.9H39.9z M46,20.5
		h9.9l-2.1,3.9L46,20.5z M23.1,20.5h2.2l-4.9,2.1L23.1,20.5z M60.6,20.5h2.2l0,0l2.6,2.1L60.6,20.5z M43,20.6l9.9,5L43,34.9l-10-9.3
		L43,20.6z M28.4,20.7l2.4,4.4l-15.3,9.3l1-8.5L27,21.3L28.4,20.7z M57.5,20.7l11.9,5.2l1,8.5l-15.3-9.3l0,0L57.5,20.7z M32,26.6
		l9.5,8.8l-0.5,0.1l0,0L39,35.7L37,35.9l0,0h-3.8l-3.7-0.4l0,0l0,0l0,0l-0.9-0.2L32,26.6z M53.9,26.6l3.5,8.7l-1,0.2l0,0l0,0
		l-1.5,0.1l-2.2,0.2h-3.8L45,35.5l-0.5-0.1L53.9,26.6z M30.3,27.1l-3.2,8.1l-1.4,0.3l-3.4,0.4l0,0h-3.2l-2.7-0.4L30.3,27.1z
		 M55.6,27.1l13.8,8.4l-0.4,0.1l-2.3,0.3h-3.2l-3.4-0.4l-1.5-0.3L55.6,27.1z M14.9,27.5l-1,7.8l-0.2,0.1l-0.6,0.2l-0.8,0.1l-1.6,0.2
		l0,0H8.6l-1.5-0.3l0,0L14.9,27.5z M71,27.5l7.7,8.1l-1.4,0.3l0,0h-2.1l-2.4-0.4L72,35.3L71,27.5z M78,32.7l3.9,3.2L81,35.5l0,0
		l-0.6-0.3L78,32.7z M7.9,32.7l-2.5,2.5l-0.5,0.3H4.8L4,35.8L7.9,32.7z M43,36.6l1.2,0.2v1L43,38.1l-1.3-0.2v-1L43,36.6z M27.6,36.6
		l1.2,0.2v1l-1.2,0.2l-1.1-0.2v-1L27.6,36.6z M58.3,36.6l1.1,0.2v1l-1.1,0.2l-1.1-0.2v-1L58.3,36.6z M14.6,36.6l0.7,0.2v1.2
		l-0.7,0.2l-0.7-0.2v-1.2L14.6,36.6z M71.3,36.6l0.7,0.2v1.2l-0.7,0.2l-0.7-0.2v-1.2L71.3,36.6z M5.9,36.6L5.9,36.6L5.9,38H5.9l0,0
		l0,0V36.6L5.9,36.6L5.9,36.6z M80,36.6L80,36.6V38l0,0H80l0,0L80,36.6L80,36.6L80,36.6z M40.2,37v0.7l-2.5-0.2v-0.2L40.2,37z
		 M45.6,37l2.5,0.2v0.2l-2.5,0.2V37z M55.8,37v0.7l-2.4-0.2v-0.2L55.8,37z M30.1,37l2.4,0.2v0.2l-2.4,0.2V37z M25.1,37v0.7L23,37.4
		v-0.2L25.1,37z M60.8,37l2.1,0.2v0.2l-2.1,0.2V37z M16.7,37l1.6,0.2l0,0v0.2l-1.6,0.2V37z M69.2,37v0.6l-1.6-0.2v-0.2L69.2,37z
		 M12.5,37v0.5l-1-0.1v-0.2L12.5,37z M73.4,37l1.1,0.2v0.2l-1.1,0.1V37z M7.4,37.1l0.4,0.1v0.3l-0.4,0.1V37.1z M78.6,37.1v0.5
		l-0.4-0.1v-0.3L78.6,37.1z M19.8,37.3h1.8v0.1L19.8,37.3L19.8,37.3z M36.4,37.3L36.4,37.3l-2.5,0.1v-0.1H36.4z M49.6,37.3H52v0.1
		L49.6,37.3L49.6,37.3z M9.2,37.3H10v0.1L9.2,37.3L9.2,37.3z M64.3,37.3h1.8v0.1L64.3,37.3L64.3,37.3z M75.9,37.3h0.8v0.1L75.9,37.3
		L75.9,37.3z M8.6,38.8h2.1l0,0l2.4,0.4l0,0l1,0.2l4.1,10.9L7.1,39L8.6,38.8z M22.3,38.8L22.3,38.8l3.4,0.4l1.4,0.2L31.8,51
		L16.2,39.1l2.9-0.3l0,0H22.3z M33.2,38.8L33.2,38.8H37l3.9,0.4l0,0l0.8,0.1l-8.2,12.4l-5-12.4l0.9-0.2l2.4-0.2L33.2,38.8z
		 M52.7,38.8L52.7,38.8l3.7,0.4l1,0.2l-5,12.4l0,0l-8.2-12.4l0.9-0.1l0,0l3.8-0.4H52.7z M63.6,38.8L63.6,38.8h3.2l2.8,0.3L54.1,51
		l4.7-11.6l0.7-0.1l0.7-0.1l0,0l2.1-0.2L63.6,38.8z M75.2,38.8L75.2,38.8h2.1l0,0l1.5,0.2l-11,11.3l4.1-10.9l1-0.2l1-0.2L75.2,38.8z
		 M4,38.8l0.9,0.3l0.3,0.2l0.2,0.1l0,0l3.8,3.8L4,38.8z M82,38.8l-5.3,4.4l3.8-3.8l0.2-0.1l0.4-0.2l0,0l0.7-0.2L82,38.8z M43,40.1
		l8.7,13.2L43.3,70h-0.8l-2.8-5.8l-5.4-11L43,40.1z M16.1,40.9l16.8,12.9l7.9,16L20.7,52.8L16.1,40.9z M69.8,40.9l-4.5,11.9l0,0
		L45.1,69.7l7.9-16L69.8,40.9z"
      />
      {props.show_diagram && (
        <>
          {/* Table */}
          <line
            x1={x(0.26)}
            x2={x(0.75)}
            y1={y(0.2)}
            y2={y(0.2)}
            stroke={lineColor}
            strokeWidth={strokeWidth2}
          ></line>
          <line
            x1={x(0.26)}
            x2={x(0.26)}
            y1={y(0.18)}
            y2={y(0.22)}
            stroke={lineColor}
            strokeWidth={strokeWidth2}
          ></line>
          <line
            x1={x(0.75)}
            x2={x(0.75)}
            y1={y(0.18)}
            y2={y(0.22)}
            stroke={lineColor}
            strokeWidth={strokeWidth2}
          ></line>

          <text
            x={x(0.37)}
            y={y(0.18)}
            fontSize={y(diagramFontSize)}
            stroke={lineColor}
            strokeWidth={diagramStrokeWidth}
          >
            Table: {table}%
          </text>

          {/* Left Depth */}
          <line
            x1={x(0.06)}
            x2={x(0.06)}
            y1={y(0.24)}
            y2={y(0.82)}
            stroke={lineColor}
            strokeWidth={strokeWidth2}
          ></line>
          <line
            x1={x(0.04)}
            x2={x(0.08)}
            y1={y(0.24)}
            y2={y(0.24)}
            stroke={lineColor}
            strokeWidth={strokeWidth2}
          ></line>
          <line
            x1={x(0.04)}
            x2={x(0.08)}
            y1={y(0.82)}
            y2={y(0.82)}
            stroke={lineColor}
            strokeWidth={strokeWidth2}
          ></line>

          <rect
            x={x(0.005)}
            y={y(0.43)}
            width={x(0.2)}
            height={y(0.13)}
            rx="0.8"
            fill="white"
          ></rect>
          <text
            x={x(0.03)}
            y={y(0.475)}
            fontSize={y(diagramFontSize)}
            stroke={lineColor}
            strokeWidth={diagramStrokeWidth}
          >
            Depth:
          </text>
          <text
            x={x(0.02)}
            y={y(0.542)}
            fontSize={y(diagramFontSize)}
            stroke={lineColor}
            strokeWidth={diagramStrokeWidth}
          >
            {props.diamond.Measurement3}
          </text>

          {/* Right Depth */}
          <line
            x1={x(0.94)}
            x2={x(0.94)}
            y1={y(0.24)}
            y2={y(0.82)}
            stroke={lineColor}
            strokeWidth={strokeWidth2}
          ></line>
          <line
            x1={x(0.92)}
            x2={x(0.96)}
            y1={y(0.24)}
            y2={y(0.24)}
            stroke={lineColor}
            strokeWidth={strokeWidth2}
          ></line>
          <line
            x1={x(0.92)}
            x2={x(0.96)}
            y1={y(0.82)}
            y2={y(0.82)}
            stroke={lineColor}
            strokeWidth={strokeWidth2}
          ></line>

          <rect
            x={x(0.8)}
            y={y(0.43)}
            width={x(0.19)}
            height={y(0.13)}
            rx="0.8"
            fill="white"
          ></rect>
          <text
            x={x(0.81)}
            y={y(0.475)}
            fontSize={y(diagramFontSize)}
            stroke={lineColor}
            strokeWidth={diagramStrokeWidth}
          >
            Depth:
          </text>
          <text
            x={x(0.81)}
            y={y(0.542)}
            fontSize={y(diagramFontSize)}
            stroke={lineColor}
            strokeWidth={diagramStrokeWidth}
          >
            {depthPercent}%
          </text>

          {/* Culet */}
          <circle
            cx={x(0.5)}
            cy={y(0.82)}
            r={y(0.03)}
            fillOpacity="0"
            stroke={lineColor}
            strokeWidth={strokeWidth2}
          />
          <line
            x1={x(0.5)}
            x2={x(0.5)}
            y1={y(0.85)}
            y2={y(0.89)}
            stroke={lineColor}
            strokeWidth={strokeWidth2}
          ></line>
          <line
            x1={x(0.5)}
            x2={x(0.53)}
            y1={y(0.89)}
            y2={y(0.89)}
            stroke={lineColor}
            strokeWidth={strokeWidth2}
          ></line>

          <text
            x={x(0.54)}
            y={y(0.9)}
            fontSize={y(diagramFontSize)}
            stroke={lineColor}
            strokeWidth={diagramStrokeWidth}
          >
            Culet: {props.diamond.Culet}
          </text>

          {/* Girdle */}
          <circle
            cx={x(0.4)}
            cy={y(0.43)}
            r={y(0.03)}
            fillOpacity="0"
            stroke={lineColor}
            strokeWidth={strokeWidth2}
          />
          <line
            x1={x(0.4)}
            y1={y(0.465)}
            x2={x(0.4)}
            y2={y(0.87)}
            stroke={lineColor}
            strokeWidth={strokeWidth2}
          ></line>
          <line
            x1={x(0.4)}
            y1={y(0.87)}
            x2={x(0.38)}
            y2={y(0.87)}
            stroke={lineColor}
            strokeWidth={strokeWidth2}
          ></line>

          <text
            x={x(0.0)}
            y={y(0.88)}
            fontSize={y(diagramFontSize)}
            stroke={lineColor}
            strokeWidth={diagramStrokeWidth}
          >
            Girdle: {girdle1}
          </text>
          <text
            x={x(0.0)}
            y={y(0.94)}
            fontSize={y(diagramFontSize)}
            stroke={lineColor}
            strokeWidth={diagramStrokeWidth}
          >
            {girdle2}
          </text>
        </>
      )}
    </svg>
  );
};

export default OldEuroProfile;
