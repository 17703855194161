import React from "react";

const PricessProfile = (props) => {
  const viewBoxWidth = 85.7;
  const viewBoxHeight = 86.1;

  const lineColor = "#4a4a4a";
  const strokeWidth2 = "0.5";

  const x = (percentX) => percentX * viewBoxWidth;
  const y = (percentY) => percentY * viewBoxHeight;

  const table = Number(props.diamond.Table).toFixed(0);
  const depthPercent = Number(props.diamond.Depth).toFixed(1);
  const girdle = props.diamond.Girdle || "";
  const girdleSpaceIndex = girdle.indexOf(" ");
  const girdle1 =
    girdleSpaceIndex > 0 ? girdle.slice(0, girdleSpaceIndex) : girdle;
  const girdle2 = girdleSpaceIndex > 0 ? girdle.slice(girdleSpaceIndex) : "";

  const diagramStrokeWidth = "0.01";
  const diagramFontSize = 0.046;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox={"0 0 " + viewBoxWidth + " " + viewBoxHeight}
      width={props.width}
      height={props.height}
      preserveAspectRatio={props.preserveAspectRatio}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill={props.color}
        stroke={props.color}
        strokeWidth="0.25"
        strokeMiterlimit="10"
        d="M78.5,23l5.9,5.5c0.1,0.1,0.1,0.2,0.1,0.2h0v3.1c0,0.1,0,0.2-0.1,0.2L64.2,57.7c0,0,0,0,0,0l0,0l-9.2,7.9
		c0,0,0,0-0.1,0l0,0l-11.9,6.7c-0.1,0.1-0.2,0.1-0.3,0l-11.9-6.7c0,0,0,0-0.1-0.1l-9.2-7.9c0,0,0,0-0.1-0.1L1,32.1
		c0-0.1-0.1-0.1-0.1-0.2h0v-3.1c0-0.1,0-0.2,0.1-0.3L7,23c0,0,0,0,0,0l4.1-3.2c0.1,0,0.1-0.1,0.2-0.1l2.9-0.3c0,0,0,0,0,0v0h57.2
		c0,0,0,0,0.1,0l2.8,0.3c0.1,0,0.1,0,0.2,0.1L78.5,23C78.5,23,78.5,23,78.5,23L78.5,23z M83.9,28.9L78,23.5l-4-3.2L71.3,20H14.1
		l-2.7,0.3l-4,3.2l-5.8,5.4v2.9l20.2,25.5l9.2,7.8l11.8,6.6l11.8-6.6l9.2-7.8l20.2-25.5V28.9z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill={props.color}
        stroke={props.color}
        strokeWidth="0.25"
        strokeMiterlimit="10"
        d="M1.2,28.6h83c0.1,0,0.1,0,0.1,0.1v3.1c0,0.1,0,0.1-0.1,0.1h-83c-0.1,0-0.1,0-0.1-0.1v-3.1
		C1.1,28.7,1.2,28.6,1.2,28.6L1.2,28.6z M84.1,28.8H1.4v2.9h82.7V28.8z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill={props.color}
        stroke={props.color}
        strokeWidth="0.25"
        strokeMiterlimit="10"
        d="M14.7,24.1l28,4.5l28-4.5H14.7L14.7,24.1z M42.7,28.8l-29.3-4.7c-0.1,0-0.1-0.1-0.1-0.1c0-0.1,0-0.1,0.1-0.1
		h58.7v0c0.1,0,0.1,0,0.1,0.1c0,0.1,0,0.1-0.1,0.1L42.7,28.8C42.7,28.8,42.7,28.8,42.7,28.8z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill={props.color}
        stroke={props.color}
        strokeWidth="0.25"
        strokeMiterlimit="10"
        d="M13.4,23.9l29.3-4.1c0,0,0,0,0,0l29.3,4.1c0.1,0,0.1,0.1,0.1,0.1c0,0.1-0.1,0.1-0.1,0.1l-58.7,0
		c-0.1,0-0.1,0-0.1-0.1C13.3,23.9,13.3,23.9,13.4,23.9L13.4,23.9z M42.7,20L15,23.9h55.5L42.7,20z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill={props.color}
        stroke={props.color}
        strokeWidth="0.25"
        strokeMiterlimit="10"
        d="M1.2,28.9l5.9-5.7c0,0,0,0,0,0l6.9-3.6c0.1,0,0.1,0,0.1,0c0,0,0,0,0,0.1L13.5,24c0,0,0,0.1-0.1,0.1l-12.1,5
		c0,0,0,0,0,0l0,0c0,0-0.1,0-0.1,0C1.1,29,1.1,28.9,1.2,28.9L1.2,28.9L1.2,28.9z M7.3,23.3l-5.5,5.3l11.6-4.7l0.7-4.1L7.3,23.3z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill={props.color}
        stroke={props.color}
        strokeWidth="0.25"
        strokeMiterlimit="10"
        d="M42.7,19.6c0.1,0,0.1,0,0.1,0.1c0,0.1,0,0.1-0.1,0.1H14.2c-0.1,0-0.1,0-0.1-0.1c0-0.1,0-0.1,0.1-0.1H42.7z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill={props.color}
        stroke={props.color}
        strokeWidth="0.25"
        strokeMiterlimit="10"
        d="M83.7,28.6l-5.5-5.3l-6.7-3.5l0.7,4.1L83.7,28.6L83.7,28.6z M78.3,23.1l5.9,5.7c0,0,0,0.1,0,0.1
		c0,0-0.1,0-0.1,0l0,0c0,0,0,0,0,0l-12.1-5c0,0-0.1,0-0.1-0.1l-0.7-4.3c0,0,0,0,0-0.1c0-0.1,0.1-0.1,0.1,0L78.3,23.1
		C78.3,23.1,78.3,23.1,78.3,23.1z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill={props.color}
        stroke={props.color}
        strokeWidth="0.25"
        strokeMiterlimit="10"
        d="M42.7,19.8c-0.1,0-0.1,0-0.1-0.1c0-0.1,0-0.1,0.1-0.1h28.5c0.1,0,0.1,0,0.1,0.1c0,0.1,0,0.1-0.1,0.1H42.7z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill={props.color}
        stroke={props.color}
        strokeWidth="0.25"
        strokeMiterlimit="10"
        d="M83.9,32H1.5l41.2,37.2L83.9,32L83.9,32z M1.2,31.7h83v0c0,0,0.1,0,0.1,0c0,0,0,0.1,0,0.2L42.8,69.4
		c0,0-0.1,0-0.1,0L1.2,31.9c0,0,0-0.1,0-0.1C1.1,31.8,1.2,31.7,1.2,31.7z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill={props.color}
        stroke={props.color}
        strokeWidth="0.25"
        strokeMiterlimit="10"
        d="M83.7,32h-82l41,18.9L83.7,32L83.7,32z M1.2,31.7h83c0,0,0.1,0,0.1,0.1c0,0.1,0,0.1-0.1,0.1L42.8,51.1
		c0,0-0.1,0-0.1,0L1.2,32c0,0-0.1-0.1-0.1-0.1C1.1,31.8,1.2,31.7,1.2,31.7z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill={props.color}
        stroke={props.color}
        strokeWidth="0.25"
        strokeMiterlimit="10"
        d="M83.8,32H1.6l41.1,25L83.8,32L83.8,32z M1.2,31.7h83v0c0,0,0.1,0,0.1,0.1c0,0.1,0,0.1,0,0.1L42.8,57.2
		c0,0-0.1,0-0.1,0L1.2,31.9c0,0-0.1-0.1-0.1-0.1C1.1,31.8,1.2,31.7,1.2,31.7z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill={props.color}
        stroke={props.color}
        strokeWidth="0.25"
        strokeMiterlimit="10"
        d="M83.9,32H1.6l41.2,32.3L83.9,32L83.9,32z M1.2,31.7h83c0,0,0.1,0,0.1,0c0,0,0,0.1,0,0.2L42.8,64.5
		c0,0-0.1,0-0.1,0L1.2,31.9c0,0,0-0.1,0-0.1C1.1,31.8,1.2,31.7,1.2,31.7z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill={props.color}
        stroke={props.color}
        strokeWidth="0.25"
        strokeMiterlimit="10"
        d="M42.6,51c0-0.1,0-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0.1v21.1c0,0.1,0,0.1-0.1,0.1c0,0,0,0-0.1,0l-11.9-6.7
		c0,0,0,0,0,0L1.2,31.9c0,0,0-0.1,0-0.2c0,0,0.1,0,0.2,0l29.5,33.5l11.8,6.6V51z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill={props.color}
        stroke={props.color}
        strokeWidth="0.25"
        strokeMiterlimit="10"
        d="M30.9,65.3c0,0,0.1,0.1,0,0.2c0,0-0.1,0.1-0.2,0l-9.2-7.9c0,0,0,0,0,0L1.2,31.9c0,0,0-0.1,0-0.2
		c0,0,0.1,0,0.1,0L37.5,69c0,0,0,0.1,0,0.2s-0.1,0-0.2,0L2.7,33.5l19,24L30.9,65.3z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill={props.color}
        stroke={props.color}
        strokeWidth="0.25"
        strokeMiterlimit="10"
        d="M42.6,51c0-0.1,0-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0.1v20.9l11.8-6.6l29.5-33.5c0,0,0.1,0,0.2,0c0,0,0,0.1,0,0.2
		L54.8,65.5c0,0,0,0,0,0l-11.9,6.7c0,0,0,0-0.1,0c-0.1,0-0.1,0-0.1-0.1V51z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill={props.color}
        stroke={props.color}
        strokeWidth="0.25"
        strokeMiterlimit="10"
        d="M54.7,65.5c0,0-0.1,0-0.2,0c0,0,0-0.1,0-0.2l9.2-7.9l19-24L48.1,69.2c0,0-0.1,0-0.2,0s0-0.1,0-0.2l36.2-37.3
		c0,0,0.1,0,0.1,0c0,0,0.1,0.1,0,0.2L64,57.6c0,0,0,0,0,0L54.7,65.5z"
      />
      {props.show_diagram && (
        <>
          {/* Table */}
          <line
            x1={x(0.12)}
            x2={x(0.88)}
            y1={y(0.2)}
            y2={y(0.2)}
            stroke={lineColor}
            strokeWidth={strokeWidth2}
          ></line>
          <line
            x1={x(0.12)}
            x2={x(0.12)}
            y1={y(0.18)}
            y2={y(0.22)}
            stroke={lineColor}
            strokeWidth={strokeWidth2}
          ></line>
          <line
            x1={x(0.88)}
            x2={x(0.88)}
            y1={y(0.18)}
            y2={y(0.22)}
            stroke={lineColor}
            strokeWidth={strokeWidth2}
          ></line>

          <text
            x={x(0.37)}
            y={y(0.16)}
            fontSize={y(diagramFontSize)}
            stroke={lineColor}
            strokeWidth={diagramStrokeWidth}
          >
            Table: {table}%
          </text>

          {/* Left Depth */}
          <line
            x1={x(0.06)}
            x2={x(0.06)}
            y1={y(0.225)}
            y2={y(0.82)}
            stroke={lineColor}
            strokeWidth={strokeWidth2}
          ></line>
          <line
            x1={x(0.04)}
            x2={x(0.08)}
            y1={y(0.225)}
            y2={y(0.225)}
            stroke={lineColor}
            strokeWidth={strokeWidth2}
          ></line>
          <line
            x1={x(0.04)}
            x2={x(0.08)}
            y1={y(0.82)}
            y2={y(0.82)}
            stroke={lineColor}
            strokeWidth={strokeWidth2}
          ></line>

          <rect
            x={x(0.005)}
            y={y(0.43)}
            width={x(0.22)}
            height={y(0.135)}
            rx="0.8"
            fill="white"
          ></rect>
          <text
            x={x(0.03)}
            y={y(0.475)}
            fontSize={y(diagramFontSize)}
            stroke={lineColor}
            strokeWidth={diagramStrokeWidth}
          >
            Depth:
          </text>
          <text
            x={x(0.02)}
            y={y(0.542)}
            fontSize={y(diagramFontSize)}
            stroke={lineColor}
            strokeWidth={diagramStrokeWidth}
          >
            {props.diamond.Measurement3}
          </text>

          {/* Right Depth */}
          <line
            x1={x(0.94)}
            x2={x(0.94)}
            y1={y(0.225)}
            y2={y(0.82)}
            stroke={lineColor}
            strokeWidth={strokeWidth2}
          ></line>
          <line
            x1={x(0.92)}
            x2={x(0.96)}
            y1={y(0.225)}
            y2={y(0.225)}
            stroke={lineColor}
            strokeWidth={strokeWidth2}
          ></line>
          <line
            x1={x(0.92)}
            x2={x(0.96)}
            y1={y(0.82)}
            y2={y(0.82)}
            stroke={lineColor}
            strokeWidth={strokeWidth2}
          ></line>

          <rect
            x={x(0.8)}
            y={y(0.43)}
            width={x(0.19)}
            height={y(0.135)}
            rx="0.8"
            fill="white"
          ></rect>
          <text
            x={x(0.81)}
            y={y(0.475)}
            fontSize={y(diagramFontSize)}
            stroke={lineColor}
            strokeWidth={diagramStrokeWidth}
          >
            Depth:
          </text>
          <text
            x={x(0.81)}
            y={y(0.542)}
            fontSize={y(diagramFontSize)}
            stroke={lineColor}
            strokeWidth={diagramStrokeWidth}
          >
            {depthPercent}%
          </text>

          {/* Culet */}
          <circle
            cx={x(0.5)}
            cy={y(0.83)}
            r={y(0.03)}
            fillOpacity="0"
            stroke={lineColor}
            strokeWidth={strokeWidth2}
          />
          <line
            x1={x(0.5)}
            x2={x(0.5)}
            y1={y(0.86)}
            y2={y(0.89)}
            stroke={lineColor}
            strokeWidth={strokeWidth2}
          ></line>
          <line
            x1={x(0.5)}
            x2={x(0.54)}
            y1={y(0.89)}
            y2={y(0.89)}
            stroke={lineColor}
            strokeWidth={strokeWidth2}
          ></line>

          <text
            x={x(0.55)}
            y={y(0.9)}
            fontSize={y(diagramFontSize)}
            stroke={lineColor}
            strokeWidth={diagramStrokeWidth}
          >
            Culet: {props.diamond.Culet}
          </text>

          {/* Girdle */}
          {girdle1.length > 0 && (
            <>
              <circle
                cx={x(0.3)}
                cy={y(0.35)}
                r={y(0.03)}
                fillOpacity="0"
                stroke={lineColor}
                strokeWidth={strokeWidth2}
              />
              <line
                x1={x(0.3)}
                x2={x(0.3)}
                y1={y(0.38)}
                y2={y(0.84)}
                stroke={lineColor}
                strokeWidth={strokeWidth2}
              ></line>

              <text
                x={x(0.06)}
                y={y(0.88)}
                fontSize={y(diagramFontSize)}
                stroke={lineColor}
                strokeWidth={diagramStrokeWidth}
              >
                Girdle: {girdle1}
              </text>
              <text
                x={x(0.06)}
                y={y(0.94)}
                fontSize={y(diagramFontSize)}
                stroke={lineColor}
                strokeWidth={diagramStrokeWidth}
              >
                {girdle2}
              </text>
            </>
          )}
        </>
      )}
    </svg>
  );
};

export default PricessProfile;
