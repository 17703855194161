import { Stack, styled } from "@mui/material";

const StyledStackCenter = styled(Stack)(({ theme }) => ({
  alignItems: "center",
  gap: theme.spacing(3),
}));

const StyledStackRow = styled(Stack)(({ theme }) => ({
  flexDirection: "row",
  alignItems: "center",
  gap: theme.spacing(3),
}));

const StyledStackContent = styled(Stack)(({ theme }) => ({
  alignItems: "center",
  justifyContent: "center",
  gap: theme.spacing(2),
}));

const StyledImageButton = styled("span")(({ theme }) => ({
  position: "relative",
  height: 500,
  [theme.breakpoints.down("sm")]: {
    width: "100% !important", // Overrides inline-style
    height: 100,
  },
}));

const StyledImageSrc = styled("span")({
  position: "absolute",
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  backgroundSize: "cover",
  backgroundPosition: "center 40%",
});

const StyledImage = styled("span")(({ theme }) => ({
  position: "absolute",
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: theme.palette.common.white,
}));

const StyledImageBackdrop = styled("span")(({ theme }) => ({
  position: "absolute",
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  backgroundColor: theme.palette.common.black,
  opacity: 0.15,
  transition: theme.transitions.create("opacity"),
}));

export {
  StyledImage,
  StyledImageBackdrop,
  StyledImageSrc,
  StyledImageButton,
  StyledStackRow,
  StyledStackCenter,
  StyledStackContent,
};
