// Third-party libraries
import React, { useEffect, useState } from "react";

// Local modules
import { constants } from "../../../utils";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel as Gallery } from "react-responsive-carousel";

export default function Carousel(props) {
  const [images, setImages] = useState(props.images);
  const frontImageUrl = constants.CMS_FRONT_IMAGE_URL;

  function formatImages() {
    const formattedImages = [];
    props.images.forEach(function (image) {
      const newImage = {
        original: frontImageUrl + image.attributes.url,
        thumbnail: frontImageUrl + image.attributes.formats.small.url,
        linkTo: image.attributes.alternativeText,
      };
      formattedImages.push(newImage);
    });
    setImages(formattedImages);
  }

  function imageClick(image) {
    window.location.href = image.linkTo;
  }

  useEffect(() => {
    formatImages();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.images]);

  return (
    <Gallery
      autoPlay={true}
      interval={8000}
      infiniteLoop={true}
      showThumbs={false}
      showStatus={false}
    >
      {images &&
        images.map((image, index) => (
          <div onClick={() => imageClick(image)} key={index}>
            <img src={image.original} alt={image.original} />
          </div>
        ))}
    </Gallery>
  );
}
