// Third-party libraries
import React, { useContext, useState } from "react";
import {
  Select,
  Stack,
  Typography,
  MenuItem,
  FormControl,
  FormHelperText,
} from "@mui/material";

// Local modules
import { ProductsContext } from "../../../../utils/stateHandlers/contexts";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

export default function InnerDiameter(props) {
  const { productState, productDispatch } = useContext(ProductsContext);
  const { validationErrors, priceLoaded } = productState;
  const [localInnerDiameter, setLocalInnerDiameter] = useState(null);
  const field = props.field;

  function selectInnerDiameter(inner_diameter) {
    if (inner_diameter === "None") {
      productDispatch({
        type: "setValidationErrors",
        payload: [...validationErrors, "inner_diameter"],
      });
    } else {
      const remove = validationErrors.filter(function (error) {
        return error !== "inner_diameter";
      });
      productDispatch({ type: "setValidationErrors", payload: remove });
    }
    productDispatch({ type: "setClear", payload: false });
    setLocalInnerDiameter(inner_diameter);
    productDispatch({
      type: "updateFields",
      field: "inner_diameter",
      payload: inner_diameter,
    });
  }

  // useEffect(() => {
  //   if (clear) {
  //     productDispatch({ type: "setClear", payload: false });
  //     productDispatch({
  //       type: "setValidationErrors",
  //       payload: _.concat(validationErrors, "inner_diameter"),
  //     });
  //     setLocalInnerDiameter("None");
  //     productDispatch({
  //       type: "updateFields",
  //       field: "inner_diameter",
  //       payload: "None",
  //     });
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [clear]);

  return (
    <Stack>
      <Typography sx={{ fontWeight: "700", paddingBottom: ".3rem" }}>
        {field.label}
      </Typography>
      <FormControl error={localInnerDiameter === "None" ? true : false}>
        <Select
          value={
            localInnerDiameter === null ? field.default : localInnerDiameter
          }
          size="small"
          sx={{ width: "100%" }}
          disabled={!priceLoaded || (field.values && field.values.length === 1)}
          onChange={(e) => {
            e.target.value !== localInnerDiameter &&
              selectInnerDiameter(e.target.value);
          }}
          MenuProps={{
            PaperProps: {
              sx: {
                border: "1px solid #939598",
                borderRadius: "0",
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
              },
            },
          }}
        >
          <MenuItem value="None">
            <em>Select...</em>
          </MenuItem>
          {field.values &&
            field.values.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
        </Select>
        <FormHelperText>
          {localInnerDiameter === "None" ? "Please Make A Selection" : ""}
        </FormHelperText>
      </FormControl>
    </Stack>
  );
}
