// Third-party libraries
import React from "react";
import { Grid, Typography } from "@mui/material";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";

// Local modules
import FillerImage from "../../../assets/images/Filler-Harmony-8-1-22.gif";
import { constants } from "../../../utils";

function StrapiChart(props) {
  const frontImageUrl = constants.CMS_FRONT_IMAGE_URL;
  return (
    <>
      <Typography variant="h2" sx={{ marginTop: "2rem" }}>
        Product Series
      </Typography>
      {props.TopText && (
        <Typography variant="staticText">
          <ReactMarkdown remarkPlugins={[remarkGfm]}>
            {props.TopText}
          </ReactMarkdown>
        </Typography>
      )}
      {props.ChartSizeSmall ? (
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item xs={12} md={6} className="center">
            <img
              src={`${frontImageUrl}${props.strapiChart.data.attributes.url}`}
              width="100%"
              alt="banner"
            />
          </Grid>
          <Grid item xs={12} md={6} className="center">
            <img src={FillerImage} width="100%" alt="filler for small table" />
          </Grid>
        </Grid>
      ) : (
        <img
          src={`${frontImageUrl}${props.strapiChart.data.attributes.url}`}
          width="100%"
          alt="banner"
        />
      )}
      {props.BottomText && (
        <Grid>
          <Grid item xs={12} md={12} className="center">
            <Typography variant="staticText">
              <ReactMarkdown remarkPlugins={[remarkGfm]}>
                {props.BottomText}
              </ReactMarkdown>
            </Typography>
          </Grid>
        </Grid>
      )}
    </>
  );
}

export default StrapiChart;
