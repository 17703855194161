// Third-party libraries
import React, { useContext, useState, useEffect } from "react";
import {
  Select,
  Typography,
  Grid,
  Stack,
  TextField,
  MenuItem,
  FormControl,
  FormHelperText,
} from "@mui/material";

// Local modules
import { BandsContext } from "../../../../utils/stateHandlers/contexts";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

export default function BandsFields(props) {
  const { bandsState, bandsDispatch } = useContext(BandsContext);
  const { clear, validationErrors, priceLoaded } = bandsState;
  const [localSelection, setLocalSelection] = useState(null);
  const [localInput, setLocalInput] = useState(props.field.default);
  const [localUnit, setLocalUnit] = useState(null);
  const fieldType = props.field.template;

  const handleInputChange = (e) => {
    setLocalInput(e.target.value);
  };

  useEffect(() => {
    if (localInput !== props.field.default) {
      const timeoutId = setTimeout(
        () =>
          bandsDispatch({
            type: "updateFields",
            field: props.field.name,
            payload: localInput,
          }),
        1000
      );
      return () => clearTimeout(timeoutId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localInput]);

  function selectValue(value, field_name) {
    if (
      field_name === "complex[finger_size]" ||
      field_name === "complex[1][band][width]" ||
      field_name === "complex[2][band][width]" ||
      field_name === "complex[3][band][width]"
    ) {
      if (value === "None") {
        bandsDispatch({
          type: "setValidationErrors",
          payload: [...validationErrors, field_name],
        });
      } else {
        const remove = validationErrors.filter(function (error) {
          return error !== "field_name";
        });
        bandsDispatch({ type: "setValidationErrors", payload: remove });
      }
      bandsDispatch({ type: "setClear", payload: false });
      setLocalSelection(value);
      bandsDispatch({
        type: "updateFields",
        field: field_name,
        payload: value,
      });
    } else {
      bandsDispatch({ type: "setClear", payload: false });
      setLocalSelection(value);
      bandsDispatch({
        type: "updateFields",
        field: field_name,
        payload: value,
      });
    }
  }

  const SelectField = (field) => (
    <Stack key={field.name}>
      <Typography sx={{ fontWeight: "700", paddingBottom: ".3rem" }}>
        {field.label}
      </Typography>
      <FormControl error={localSelection === "None" ? true : false}>
        <Select
          key={field.name}
          value={
            localSelection === null
              ? field.default === ""
                ? "None"
                : field.default
              : localSelection
          }
          size="small"
          sx={{ width: "100%" }}
          disabled={!priceLoaded}
          onChange={(e) => {
            selectValue(e.target.value, field.name);
          }}
          MenuProps={{
            PaperProps: {
              sx: {
                border: "1px solid #939598",
                borderRadius: "0",
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
              },
            },
          }}
        >
          <MenuItem value="None">
            <em>Select...</em>
          </MenuItem>
          {field.values &&
            field.values.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
        </Select>
        <FormHelperText>
          {localSelection === "None" ? "Please Make A Selection" : ""}
        </FormHelperText>
      </FormControl>
    </Stack>
  );

  const QuantityField = (field) => (
    <Grid container key={field.name}>
      <Grid item xs={8}>
        <Stack>
          <Typography
            sx={{
              fontWeight: "700",
              paddingBottom: ".3rem",
            }}
          >
            Quantity
          </Typography>
          <TextField
            color="primary"
            disabled={!priceLoaded}
            onChange={(e) => {
              handleInputChange(e);
            }}
            type="number"
            size="small"
            sx={{
              width: "100%",
              margin: "unset",
            }}
            value={localInput}
          />
        </Stack>
      </Grid>
      <Grid item xs={1} />
      <Grid item xs={3}>
        <Stack>
          <Typography
            sx={{
              fontWeight: "700",
              paddingBottom: ".3rem",
            }}
          >
            Units
          </Typography>
          <Select
            value={localUnit === null ? "EA" : localUnit}
            sx={{ width: "100%" }}
            size="small"
            disabled={!priceLoaded || (field.units && field.units.length === 1)}
            onChange={(e) => {
              bandsDispatch({
                type: "updateFields",
                field: "builder[quantity_unit]",
                payload: e.target.value,
              });
              setLocalUnit(e.target.value);
            }}
            MenuProps={{
              PaperProps: {
                sx: {
                  border: "1px solid #939598",
                  borderRadius: "0",
                  maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                },
              },
            }}
          >
            <MenuItem value="None">
              <em>Select...</em>
            </MenuItem>
            {field.units &&
              field.units.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
          </Select>
        </Stack>
      </Grid>
    </Grid>
  );

  const InputSelectField = (field) => (
    <Grid container key={field.name}>
      <Grid item xs={8}>
        <Stack>
          <Typography
            sx={{
              fontWeight: "700",
              paddingBottom: ".3rem",
            }}
          >
            {field.label}
          </Typography>
          <TextField
            color="primary"
            disabled={!priceLoaded}
            onChange={(e) => {
              handleInputChange(e);
            }}
            type="number"
            size="small"
            sx={{
              width: "100%",
              margin: "unset",
            }}
            value={localInput}
          />
        </Stack>
      </Grid>
      <Grid item xs={1} />
      <Grid item xs={3}>
        <Stack>
          <Typography
            sx={{
              fontWeight: "700",
              paddingBottom: ".3rem",
            }}
          >
            Units
          </Typography>
          <Select
            value={localUnit === null ? field.default_unit : localUnit}
            sx={{ width: "100%" }}
            size="small"
            disabled={!priceLoaded}
            onChange={(e) => {
              bandsDispatch({
                type: "updateFields",
                field: field.name,
                payload: e.target.value,
              });
              setLocalUnit(e.target.value);
            }}
            MenuProps={{
              PaperProps: {
                sx: {
                  border: "1px solid #939598",
                  borderRadius: "0",
                  maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                },
              },
            }}
          >
            <MenuItem value="None">
              <em>Select...</em>
            </MenuItem>
            {field.units &&
              field.units.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
          </Select>
        </Stack>
      </Grid>
    </Grid>
  );

  const field =
    props.field.name === "builder[quantity]"
      ? QuantityField(props.field)
      : fieldType === "dropdown"
      ? SelectField(props.field)
      : fieldType === "field_with_units"
      ? InputSelectField(props.field)
      : "";

  useEffect(() => {
    if (clear) {
      bandsDispatch({ type: "setClear", payload: false });
      bandsDispatch({
        type: "setValidationErrors",
        payload: [...validationErrors, props.field.name],
      });
      setLocalSelection("None");
      bandsDispatch({
        type: "updateFields",
        field: props.field.name,
        payload: "None",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clear]);

  return <>{field}</>;
}
