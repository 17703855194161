let inMemoryStorage = {};

const isSupported = () => {
  try {
    const testKey = "testlocalstorage";
    localStorage.setItem(testKey, testKey);
    localStorage.removeItem(testKey);
    return true;
  } catch (e) {
    return false;
  }
};

const isSessionSupported = () => {
  try {
    const testKey = "testsessionstorage";
    sessionStorage.setItem(testKey, testKey);
    sessionStorage.removeItem(testKey);
    return true;
  } catch (e) {
    return false;
  }
};

const clearStorage = () => {
  if (isSupported()) {
    localStorage.clear();
  } else {
    inMemoryStorage = {};
  }
};

const clearSessionStorage = () => {
  if (isSessionSupported()) {
    sessionStorage.clear();
  } else {
    inMemoryStorage = {};
  }
};

const getStorageItem = (key) => {
  if (isSupported()) {
    return localStorage.getItem(key);
  }
  if (inMemoryStorage.hasOwnProperty(key)) {
    return inMemoryStorage[key];
  }
};

const getSessionStorageItem = (key) => {
  if (isSessionSupported()) {
    return sessionStorage.getItem(key);
  }
  if (inMemoryStorage.hasOwnProperty(key)) {
    return inMemoryStorage[key];
  }
};

const removeStorageItem = (key) => {
  if (isSupported()) {
    localStorage.removeItem(key);
  } else {
    delete inMemoryStorage[key];
  }
};

const removeSessionStorageItem = (key) => {
  if (isSessionSupported()) {
    sessionStorage.removeItem(key);
  } else {
    delete inMemoryStorage[key];
  }
};

const setStorageItem = (key, value) => {
  if (isSupported()) {
    localStorage.setItem(key, value);
  } else {
    inMemoryStorage[key] = String(value);
  }
};

const setSessionStorageItem = (key, value) => {
  if (isSupported()) {
    sessionStorage.setItem(key, value);
  } else {
    inMemoryStorage[key] = String(value);
  }
};

const storage = {
  getStorageItem: getStorageItem,
  getSessionStorageItem: getSessionStorageItem,
  removeStorageItem: removeStorageItem,
  removeSessionStorageItem: removeSessionStorageItem,
  setStorageItem: setStorageItem,
  setSessionStorageItem: setSessionStorageItem,
  clearStorage: clearStorage,
  clearSessionStorage: clearSessionStorage,
};

export default storage;
