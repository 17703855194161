// Third-party libraries
import React, { useContext, useState, useEffect } from "react";
import {
  Select,
  Typography,
  Stack,
  MenuItem,
  FormControl,
  FormHelperText,
} from "@mui/material";

// Local modules
import { BuildARingContext } from "../../../utils/stateHandlers/contexts";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

export default function BarTreatments(props) {
  const { barState, barDispatch } = useContext(BuildARingContext);
  const { clear, validationErrors, priceLoaded, setting, shank } = barState;
  const [localTreatment, setLocalTreatment] = useState("None");
  const field = props.field;
  const isShank = props.shank;

  function updateTreatments(treatmentType, event) {
    if (isShank) {
      const treatmentToUpdate = shank.shankInfo.treatments.find(function (
        treatment
      ) {
        return treatment.name === treatmentType;
      });
      if (treatmentToUpdate && event.target.value === "None") {
        const removeTreatment = shank.shankInfo.treatments.filter(function (
          treatment
        ) {
          return treatment.name !== treatmentToUpdate.name;
        });
        barDispatch({
          type: "updateShankTreatment",
          payload: removeTreatment === [] ? "" : removeTreatment,
        });
      } else if (treatmentToUpdate && event.target.value !== "None") {
        treatmentToUpdate.value = event.target.value;
        barDispatch({
          type: "updateShankTreatment",
          payload: shank.shankInfo.treatments,
        });
      } else {
        barDispatch({
          type: "updateShankTreatment",
          payload: [
            ...shank.shankInfo.treatments,
            { name: treatmentType, value: event.target.value },
          ],
        });
      }
    } else {
      const treatmentToUpdate = setting.settingInfo.treatments.find(function (
        treatment
      ) {
        return treatment.name === treatmentType;
      });
      if (treatmentToUpdate && event.target.value === "None") {
        const removeTreatment = setting.settingInfo.treatments.filter(function (
          treatment
        ) {
          return treatment.name !== treatmentToUpdate.name;
        });
        barDispatch({
          type: "updateSettingTreatment",
          payload: removeTreatment === [] ? "" : removeTreatment,
        });
      } else if (treatmentToUpdate && event.target.value !== "None") {
        treatmentToUpdate.value = event.target.value;
        barDispatch({
          type: "updateSettingTreatment",
          payload: setting.settingInfo.treatments,
        });
      } else {
        barDispatch({
          type: "updateSettingTreatment",
          payload: [
            ...setting.settingInfo.treatments,
            { name: treatmentType, value: event.target.value },
          ],
        });
      }
    }
    setLocalTreatment(event.target.value);
  }

  useEffect(() => {
    if (clear) {
      barDispatch({ type: "setClear", payload: false });
      barDispatch({
        type: "setValidationErrors",
        payload: [...validationErrors, props.field.name],
      });
      setLocalTreatment("None");
      barDispatch({
        type: "updateFields",
        field: props.field.name,
        payload: "None",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clear]);

  return (
    <Stack key={field.name}>
      <Typography sx={{ fontWeight: "700", paddingBottom: ".3rem" }}>
        {field.label}
      </Typography>
      <FormControl error={localTreatment === "None" ? true : false}>
        <Select
          key={field.name}
          value={
            localTreatment === null
              ? field.default === ""
                ? "None"
                : field.default
              : localTreatment
          }
          size="small"
          sx={{ width: "100%" }}
          disabled={!priceLoaded}
          onChange={(e) => {
            updateTreatments(field.type, e);
          }}
          MenuProps={{
            PaperProps: {
              sx: {
                border: "1px solid #939598",
                borderRadius: "0",
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
              },
            },
          }}
        >
          <MenuItem value="None">
            <em>Select...</em>
          </MenuItem>
          {field.values &&
            field.values.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
        </Select>
        <FormHelperText>
          {localTreatment === "None" ? "Please Make A Selection" : ""}
        </FormHelperText>
      </FormControl>
    </Stack>
  );
}
