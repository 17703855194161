// Third-party libraries
import React from "react";
import { Link as DomLink } from "react-router-dom";
import { Stack, Paper, Typography } from "@mui/material";

const bands = {
  title1: "Hoover Azure® Anniversary Bands",
  link1: "/technicalarticles/",
  title2: "Setting Hoover & Strong Anniversary Bands",
  link2: "/technicalarticles/",
  title3: "Poor Alternatives",
  link3: "/technicalarticles/",
};

export default function BandsTechnicalArticles(props) {
  const ArticlesList = (list) => (
    <Stack spacing={1}>
      <Paper elevation={0} className="first-article">
        <DomLink
          to={{
            pathname: list.link1,
          }}
          target="_blank"
          className="article-title-text"
        >
          {list.title1}
        </DomLink>
      </Paper>
      <Paper elevation={0} className="second-article">
        <DomLink
          to={{
            pathname: list.link2,
          }}
          target="_blank"
          className="article-title-text"
        >
          {list.title2}
        </DomLink>
      </Paper>
      <Paper elevation={0} className="third-article">
        <DomLink
          to={{
            pathname: list.link3,
          }}
          target="_blank"
          className="article-title-text"
        >
          {list.title3}
        </DomLink>
      </Paper>
    </Stack>
  );

  return (
    <Stack>
      <Typography variant="h2">
        Technical Articles
        <DomLink
          to={{
            pathname: "/technicalarticles",
          }}
          target="_blank"
          className="header-link"
        >
          Search All Technical Articles
        </DomLink>
      </Typography>
      {ArticlesList(bands)}
    </Stack>
  );
}
