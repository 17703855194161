import React from "react";

const MarquiseProfile = (props) => {
  const viewBoxWidth = 85.7;
  const viewBoxHeight = 86.1;

  const lineColor = "#4a4a4a";
  const strokeWidth2 = "0.5";

  const x = (percentX) => percentX * viewBoxWidth;
  const y = (percentY) => percentY * viewBoxHeight;

  const table = Number(props.diamond.Table).toFixed(0);
  const depthPercent = Number(props.diamond.Depth).toFixed(1);
  const girdle = props.diamond.Girdle || "";
  const girdleSpaceIndex = girdle.indexOf(" ");
  const girdle1 =
    girdleSpaceIndex > 0 ? girdle.slice(0, girdleSpaceIndex) : girdle;
  const girdle2 = girdleSpaceIndex > 0 ? girdle.slice(girdleSpaceIndex) : "";

  const diagramStrokeWidth = "0.01";
  const diagramFontSize = 0.046;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox={"0 0 " + viewBoxWidth + " " + viewBoxHeight}
      width={props.width}
      height={props.height}
      preserveAspectRatio={props.preserveAspectRatio}
    >
      <g>
        <polyline
          fill="none"
          stroke={props.color}
          strokeWidth={props.stroke_width}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          points="42.9,30.2 66.8,30.6 83.4,36.8 83.5,38.3 43.2,58.6 			"
        />
        <polyline
          fill="none"
          stroke={props.color}
          strokeWidth={props.stroke_width}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          points="42.7,37 49.1,37.3 54.8,36.8 60.1,37.4 66,37 71.3,37.4 75.4,36.7 79.7,37.3 82.9,37 			"
        />
        <polyline
          fill="none"
          stroke={props.color}
          strokeWidth={props.stroke_width}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          points="43.1,38.8 49,38 55,38.6 60.5,38.3 65.7,38.6 71.3,38 75.6,38.4 79.7,38.4 82.9,38.5 			"
        />
        <polyline
          fill="none"
          stroke={props.color}
          strokeWidth={props.stroke_width}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          points="43.2,36.5 51.8,32.2 65,36.7 71,32.4 75.8,36.7 			"
        />
        <path
          fill="none"
          stroke={props.color}
          strokeWidth={props.stroke_width}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="M43.1,30.6c0.2,0.1,8.7,1.4,8.7,1.4l3.6-1.5"
        />
        <polyline
          fill="none"
          stroke={props.color}
          strokeWidth={props.stroke_width}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          points="43.1,38.8 45.9,53.9 65.6,38.8 52.3,53.8 			"
        />
        <line
          fill="none"
          stroke={props.color}
          strokeWidth={props.stroke_width}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          x1="54.9"
          y1="38.8"
          x2="43.2"
          y2="58.6"
        />
        <line
          fill="none"
          stroke={props.color}
          strokeWidth={props.stroke_width}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          x1="52"
          y1="32.3"
          x2="54.9"
          y2="36.6"
        />
        <line
          fill="none"
          stroke={props.color}
          strokeWidth={props.stroke_width}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          x1="76.2"
          y1="38.6"
          x2="52.3"
          y2="53.8"
        />
      </g>
      <g>
        <polyline
          fill="none"
          stroke={props.color}
          strokeWidth={props.stroke_width}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          points="43.3,30.2 19.3,30.6 2.7,36.8 2.7,38.3 43,58.6 			"
        />
        <polyline
          fill="none"
          stroke={props.color}
          strokeWidth={props.stroke_width}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          points="43.4,37 37.1,37.3 31.4,36.8 26.1,37.4 20.2,37 14.8,37.4 10.8,36.7 6.5,37.3 3.2,37 			"
        />
        <polyline
          fill="none"
          stroke={props.color}
          strokeWidth={props.stroke_width}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          points="43,38.8 37.1,38 31.2,38.6 25.7,38.3 20.5,38.6 14.8,38 10.6,38.4 6.5,38.4 3.2,38.5 			"
        />
        <polyline
          fill="none"
          stroke={props.color}
          strokeWidth={props.stroke_width}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          points="43,36.5 34.4,32.2 21.2,36.7 15.1,32.4 10.3,36.7 			"
        />
        <path
          fill="none"
          stroke={props.color}
          strokeWidth={props.stroke_width}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="M43.1,30.6c-0.2,0.1-8.7,1.4-8.7,1.4l-3.6-1.5"
        />
        <polyline
          fill="none"
          stroke={props.color}
          strokeWidth={props.stroke_width}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          points="43,39 40.3,53.9 20.5,38.8 33.9,53.8 			"
        />
        <line
          fill="none"
          stroke={props.color}
          strokeWidth={props.stroke_width}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          x1="31.2"
          y1="38.8"
          x2="43"
          y2="58.6"
        />
        <line
          fill="none"
          stroke={props.color}
          strokeWidth={props.stroke_width}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          x1="34.2"
          y1="32.3"
          x2="31.2"
          y2="36.6"
        />
        <line
          fill="none"
          stroke={props.color}
          strokeWidth={props.stroke_width}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          x1="10"
          y1="38.6"
          x2="33.8"
          y2="53.8"
        />
        {props.show_diagram && (
          <>
            {/* Table */}
            <line
              x1={x(0.2)}
              x2={x(0.8)}
              y1={y(0.32)}
              y2={y(0.32)}
              stroke={lineColor}
              strokeWidth={strokeWidth2}
            ></line>
            <line
              x1={x(0.2)}
              x2={x(0.2)}
              y1={y(0.3)}
              y2={y(0.34)}
              stroke={lineColor}
              strokeWidth={strokeWidth2}
            ></line>
            <line
              x1={x(0.8)}
              x2={x(0.8)}
              y1={y(0.3)}
              y2={y(0.34)}
              stroke={lineColor}
              strokeWidth={strokeWidth2}
            ></line>
            <text
              x={x(0.37)}
              y={y(0.28)}
              fontSize={y(diagramFontSize)}
              stroke={lineColor}
              strokeWidth={diagramStrokeWidth}
            >
              Table: {table}%
            </text>
            {/* Left Depth */}
            <line
              x1={x(0.06)}
              x2={x(0.06)}
              y1={y(0.35)}
              y2={y(0.7)}
              stroke={lineColor}
              strokeWidth={strokeWidth2}
            ></line>
            <line
              x1={x(0.04)}
              x2={x(0.08)}
              y1={y(0.35)}
              y2={y(0.35)}
              stroke={lineColor}
              strokeWidth={strokeWidth2}
            ></line>
            <line
              x1={x(0.04)}
              x2={x(0.08)}
              y1={y(0.7)}
              y2={y(0.7)}
              stroke={lineColor}
              strokeWidth={strokeWidth2}
            ></line>
            <rect
              x={x(0.005)}
              y={y(0.43)}
              width={x(0.22)}
              height={y(0.135)}
              rx="0.8"
              fill="white"
            ></rect>
            <text
              x={x(0.03)}
              y={y(0.475)}
              fontSize={y(diagramFontSize)}
              stroke={lineColor}
              strokeWidth={diagramStrokeWidth}
            >
              Depth:
            </text>
            <text
              x={x(0.02)}
              y={y(0.542)}
              fontSize={y(diagramFontSize)}
              stroke={lineColor}
              strokeWidth={diagramStrokeWidth}
            >
              {props.diamond.Measurement3}
            </text>
            {/* Right Depth */}
            <line
              x1={x(0.94)}
              x2={x(0.94)}
              y1={y(0.35)}
              y2={y(0.7)}
              stroke={lineColor}
              strokeWidth={strokeWidth2}
            ></line>
            <line
              x1={x(0.92)}
              x2={x(0.96)}
              y1={y(0.35)}
              y2={y(0.35)}
              stroke={lineColor}
              strokeWidth={strokeWidth2}
            ></line>
            <line
              x1={x(0.92)}
              x2={x(0.96)}
              y1={y(0.7)}
              y2={y(0.7)}
              stroke={lineColor}
              strokeWidth={strokeWidth2}
            ></line>
            <rect
              x={x(0.8)}
              y={y(0.43)}
              width={x(0.19)}
              height={y(0.135)}
              rx="0.8"
              fill="white"
            ></rect>
            <text
              x={x(0.81)}
              y={y(0.475)}
              fontSize={y(diagramFontSize)}
              stroke={lineColor}
              strokeWidth={diagramStrokeWidth}
            >
              Depth:
            </text>
            <text
              x={x(0.81)}
              y={y(0.542)}
              fontSize={y(diagramFontSize)}
              stroke={lineColor}
              strokeWidth={diagramStrokeWidth}
            >
              {depthPercent}%
            </text>
            {/* Culet */}
            <circle
              cx={x(0.5)}
              cy={y(0.68)}
              r={y(0.03)}
              fillOpacity="0"
              stroke={lineColor}
              strokeWidth={strokeWidth2}
            />
            <line
              x1={x(0.5)}
              x2={x(0.502)}
              y1={y(0.71)}
              y2={y(0.79)}
              stroke={lineColor}
              strokeWidth={strokeWidth2}
            ></line>
            <line
              x1={x(0.502)}
              x2={x(0.54)}
              y1={y(0.79)}
              y2={y(0.79)}
              stroke={lineColor}
              strokeWidth={strokeWidth2}
            ></line>
            <text
              x={x(0.55)}
              y={y(0.8)}
              fontSize={y(diagramFontSize)}
              stroke={lineColor}
              strokeWidth={diagramStrokeWidth}
            >
              Culet: {props.diamond.Culet}
            </text>
            {/* Girdle */}
            {girdle1.length > 0 && (
              <>
                <circle
                  cx={x(0.4)}
                  cy={y(0.42)}
                  r={y(0.03)}
                  fillOpacity="0"
                  stroke={lineColor}
                  strokeWidth={strokeWidth2}
                />
                <line
                  x1={x(0.4)}
                  x2={x(0.4)}
                  y1={y(0.455)}
                  y2={y(0.83)}
                  stroke={lineColor}
                  strokeWidth={strokeWidth2}
                ></line>
                <line
                  x1={x(0.4)}
                  x2={x(0.38)}
                  y1={y(0.83)}
                  y2={y(0.83)}
                  stroke={lineColor}
                  strokeWidth={strokeWidth2}
                ></line>

                <text
                  x={x(0.0)}
                  y={y(0.82)}
                  fontSize={y(diagramFontSize)}
                  stroke={lineColor}
                  strokeWidth={diagramStrokeWidth}
                >
                  Girdle: {girdle1}
                </text>
                <text
                  x={x(0.0)}
                  y={y(0.88)}
                  fontSize={y(diagramFontSize)}
                  stroke={lineColor}
                  strokeWidth={diagramStrokeWidth}
                >
                  {girdle2}
                </text>
              </>
            )}
          </>
        )}
      </g>
    </svg>
  );
};

export default MarquiseProfile;
