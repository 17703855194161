// Third-party libraries
import React, { useContext, useEffect, useState } from "react";
import { Link as DomLink } from "react-router-dom";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import remarkGfm from "remark-gfm";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Container,
  Card,
  CardHeader,
  CardContent,
  Button,
  Typography,
  TextField,
  Stack,
  Divider,
} from "@mui/material";

// Local modules
import { AppContext } from "../../utils/stateHandlers/contexts";
import api from "../../utils/api";

const schema = yup.object().shape({
  email: yup
    .string()
    .test("email-not-entered", "Email is required.", (value) => value !== "")
    .required("Email is required."),
  password: yup
    .string()
    .test(
      "password-not-entered",
      "Password is required.",
      (value) => value !== ""
    )
    .required("Password is required."),
});

export default function Login(props) {
  const { user } = useContext(AppContext);
  const [text, setText] = useState();
  const [title, setTitle] = useState();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  function login() {
    const credentials = {
      username: email,
      password: password,
    };
    api.post("account/login", credentials).then((res) => {
      if (res.isAxiosError) {
        const errorMessage = [];
        if (res.response.data) {
          const value = Object.values(res.response.data)[0];
          value.forEach((error) => {
            errorMessage.push(error);
          });
        }
        toast.error(
          `There was an error processing your request. ${errorMessage.join(
            ", "
          )}`
        );
      } else {
        user.signIn(res.data, res.data.token);
      }
    });
  }

  useEffect(() => {
    api.fetchStrapi("/log-in?populate=*").then((response) => {
      setText(response.data.data.attributes.text);
      setTitle(response.data.data.attributes.title);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container maxWidth="md">
      <Card>
        <CardHeader
          title={<Typography variant="articleTitle">{title}</Typography>}
        />
        <CardContent>
          <form onSubmit={handleSubmit(login)}>
            <Stack spacing={3}>
              <Typography variant="articleText" sx={{ marginBottom: "1rem" }}>
                <ReactMarkdown children={text} remarkPlugins={[remarkGfm]} />
              </Typography>
              <Divider />
              <Typography>
                <strong>Don't have an account?</strong> Click the button below.
              </Typography>
              <DomLink
                to={{
                  pathname: `/create-account`,
                }}
              >
                <Button variant="contained" color="success">
                  Create New Account
                </Button>
              </DomLink>
              <Divider />
              <Stack>
                <Typography variant="navText">Email</Typography>
                <TextField
                  {...register("email")}
                  error={errors["email"] ? true : false}
                  helperText={errors["email"] ? errors["email"].message : ""}
                  required
                  autoFocus
                  color="primary"
                  onChange={(e) => {
                    setEmail(e.target.value);
                    setValue("email", e.target.value, {
                      shouldValidate: true,
                    });
                  }}
                  onKeyDown={(e) => e.key === "Enter" && login()}
                  size="small"
                  sx={{
                    width: "100%",
                    margin: "unset",
                  }}
                />
              </Stack>
              <Stack>
                <Typography variant="navText">Password</Typography>
                <TextField
                  {...register("password")}
                  error={errors["password"] ? true : false}
                  helperText={
                    errors["password"] ? errors["password"].message : ""
                  }
                  required
                  color="primary"
                  type="password"
                  onChange={(e) => {
                    setPassword(e.target.value);
                    setValue("password", e.target.value, {
                      shouldValidate: true,
                    });
                  }}
                  onKeyDown={(e) => e.key === "Enter" && login()}
                  size="small"
                  sx={{
                    width: "100%",
                    margin: "unset",
                  }}
                />
              </Stack>
              <Stack spacing={1}>
                <Typography variant="subtitle2">
                  <DomLink to={{ pathname: "/forgot-password" }}>
                    Forgot Password?
                  </DomLink>
                </Typography>
              </Stack>
            </Stack>
            <Button
              size="small"
              color="primary"
              variant="contained"
              type="submit"
            >
              Log in
            </Button>
          </form>
        </CardContent>
      </Card>
    </Container>
  );
}
