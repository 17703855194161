// Third-party libraries
import React, { useState, useEffect, useContext } from "react";
import {
  Grid,
  Container,
  Stack,
  Divider,
  CircularProgress,
  Card,
  CardContent,
  Backdrop,
  Typography,
  Box,
} from "@mui/material";

// Local modules
import { AppContext } from "../../utils/stateHandlers/contexts";
import api from "../../utils/api";
import formatting from "../../utils/formatHelpers";
import StringConstants from "../../utils/constants";
import { Mobile, Default } from "../../utils/deviceHelpers";
import MediaPanel from "./controls/MediaPanel";

export default function BlankDiamondDetails(props) {
  const { setShowFooter, setShowHeader } = useContext(AppContext);
  const [pageLoaded, setPageLoaded] = useState(false);
  const [diamond, setDiamond] = useState({});
  const [fields, setFields] = useState({});
  const mined = StringConstants.DIAMOND_MINED_OR_LAB_MINED_DESC;
  const lab = StringConstants.DIAMOND_MINED_OR_LAB_LAB_DESC;

  useEffect(() => {
    api
      .fetch(`diamonds/detail/${props.match.params.HooverStockId}`)
      .then((res) => {
        setDiamond(res.data);
      })
      .then(
        api.fetch("diamonds/fields").then((res) => {
          setFields(res.data);
          setPageLoaded(true);
        })
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setShowFooter(false);
    setShowHeader(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const specificationsBlock = () => (
    <Stack>
      <Typography variant="h5">Specifications:</Typography>
      <Divider />
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: {
            sm: "repeat(1, 1fr)",
            md: "repeat(2, 1fr)",
            lg: "repeat(2, 1fr)",
            xl: "repeat(2, 1fr)",
          },
        }}
      >
        <Typography variant="body3" sx={{ padding: ".5rem" }}>
          {diamond.Carat}ct {diamond.Shape} Shape
        </Typography>

        {/* TODO REMOVE HACK to hide IMAGELINK */}
        {fields
          .filter((f) => f.details_order !== null)
          .filter((f) => f.name !== "ImageLink")
          .filter((f) =>
            diamond.Shape === "Round"
              ? f.name !== "Girdle"
              : diamond.Shape === "Heart"
              ? f.name !== "Girdle"
              : diamond.Girdle === ""
              ? f.name !== "Girdle"
              : f
          )
          .filter((f) =>
            diamond.Shape === "Round"
              ? f.name !== "Culet"
              : diamond.Shape === "Heart"
              ? f.name !== "Culet"
              : diamond.Culet === ""
              ? f.name !== "Culet"
              : f
          )
          .sort((f1, f2) => f1.details_order - f2.details_order)
          .map((f, i) => (
            <Typography
              key={"specs_" + i}
              variant="body3"
              sx={{ padding: ".5rem" }}
            >
              {f.label}:{" "}
              {f.label === "Report" &&
              diamond.CertificationLocation.length > 0 ? (
                <>
                  {diamond[f.name]}&nbsp;&nbsp;
                  <a
                    href={diamond.CertificationLocation}
                    className="underline"
                    target="_blank"
                    rel="noreferrer"
                  >
                    View
                  </a>
                </>
              ) : (
                (formatting[f.format] &&
                  formatting[f.format](diamond[f.name])) ||
                diamond[f.name]
              )}
            </Typography>
          ))}
      </Box>
    </Stack>
  );

  return (
    <>
      {!pageLoaded ? (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={true}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : (
        <Container maxWidth="md">
          <Mobile>
            <Stack spacing={1}>
              <h2 className="text-align-center">
                {`${diamond.MinedOrLab === "L" ? lab : mined} ${
                  diamond.StoneType
                }`}
                <span className="sku">SKU: {diamond.HooverStockId}</span>
              </h2>
              <h2 className="text-align-center">
                {`${diamond.Carat}ct ${diamond.Shape} ${diamond.Color}
                    ${diamond.Clarity} ${diamond.HooverCertification} Certified`}
              </h2>
              <Divider orientation="horizontal" flexitem="true" />
              <span style={{ justifyContent: "center" }}>
                <MediaPanel
                  diamond={diamond}
                  popover={props.popover}
                  context="side_panel"
                />
              </span>
            </Stack>
          </Mobile>
          <Default>
            <Stack justifyContent="center">
              <Typography variant="articleTitle">
                {`${diamond.MinedOrLab === "L" ? lab : mined} ${
                  diamond.StoneType
                }`}
                <Typography variant="h3" className="sku">
                  SKU: {diamond.HooverStockId}
                </Typography>
              </Typography>
              <Typography variant="h6">
                {`${diamond.Carat}ct ${diamond.Shape} ${diamond.Color}
                    ${diamond.Clarity} ${diamond.HooverCertification} Certified`}
              </Typography>
              <Divider
                flexItem
                sx={{ marginBottom: "2rem", marginTop: "2rem" }}
              />
              <Stack direction="row" justifyContent="center" spacing={3}>
                <Stack>
                  <MediaPanel diamond={diamond} popover={props.popover} />
                </Stack>
              </Stack>
            </Stack>
          </Default>
          <Grid container rowSpacing={2} justifyContent="center">
            <Grid item className="details-container">
              <Card variant="outlined">
                <CardContent className="additional-info">
                  {specificationsBlock()}
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Container>
      )}
    </>
  );
}
