// Third-party libraries
import React, { useState } from "react";
import { Link as DomLink } from "react-router-dom";
import { Typography, Stack } from "@mui/material";

// Local modules
import { storage } from "../../../utils";
import placeHolder from "../../../assets/images/placeHolder.png";
import formatting from "../../../utils/formatHelpers";
import {
  Bestseller,
  Cast,
  Diestruck,
  New,
  Truseat,
  NonHarmony,
  Polished,
  EZFit,
} from "../../Icons";

export default function ProductTiles(props) {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  function getIcons(icons) {
    switch (icons) {
      case "DIESTRCK":
        return <Diestruck />;
      case "BEST":
        return <Bestseller />;
      case "CAST":
        return <Cast />;
      case "NEW":
        return <New />;
      case "TRUSEAT":
        return <Truseat />;
      case "POLISHED":
        return <Polished />;
      case "EZFIT":
        return <EZFit />;
      case "NONHARMONY":
        return <NonHarmony />;
      default:
        break;
    }
  }

  function storeRecentlyViewed(style) {
    const previous = JSON.parse(
      storage.getSessionStorageItem("hsRecentlyViewedProducts")
    );
    if (previous !== null) {
      const viewedArray = [...previous, style];
      const uniqueViewArray = viewedArray.filter((item, index, array) => {
        return index === array.findIndex((obj) => obj.style === item.style);
      });
      storage.setSessionStorageItem(
        "hsRecentlyViewedProducts",
        JSON.stringify(uniqueViewArray)
      );
    } else if (previous === null) {
      storage.setSessionStorageItem(
        "hsRecentlyViewedProducts",
        JSON.stringify([style])
      );
    }
  }

  return (
    <div
      className="all-tiles"
      key={props.data.style}
      onClick={() => storeRecentlyViewed(props.data)}
    >
      <DomLink
        to={{
          pathname: `/product/${props.data.style}`,
          state: {
            isMill:
              props.category === "Solder" ||
              props.category === "Wire" ||
              props.category === "Tubing"
                ? true
                : false,
            showBAR:
              props.category === "All Settings" ||
              props.category === "All Shanks"
                ? true
                : false,
            productGroup: props.category,
            mtoConfiguratorProduct: props.data.mtoConfiguratorProduct,
            recentlyViewed: JSON.parse(
              storage.getSessionStorageItem("hsRecentlyViewedProducts")
            ),
          },
        }}
      >
        {props.data.images.length === 0 ? (
          <img
            className="mainImg"
            readOnly={true}
            src={placeHolder}
            alt=""
            onError={(e) => {
              e.target.src = placeHolder;
            }}
          />
        ) : (
          <>
            <img
              className="mainImg"
              readOnly={true}
              src={props.data.images[currentImageIndex]}
              alt=""
              onError={(e) => {
                e.target.src = placeHolder;
              }}
            />
            {props.data.images.length > 1 && (
              <>
                <div
                  className="emptyHoverDiv1"
                  onMouseOver={(e) => setCurrentImageIndex(0)}
                ></div>
                <div
                  className="emptyHoverDiv2"
                  onMouseOver={(e) => setCurrentImageIndex(1)}
                  onMouseOut={(e) => setCurrentImageIndex(0)}
                ></div>
              </>
            )}
            {props.data.images[2] !== undefined && (
              <div
                className="emptyHoverDiv3"
                onMouseOver={(e) => setCurrentImageIndex(2)}
                onMouseOut={(e) => setCurrentImageIndex(0)}
              ></div>
            )}
          </>
        )}
        <br />
        <Stack>
          <Typography
            className="style-text"
            variant="body2"
            sx={{ fontSize: "1.6rem", fontWeight: "800" }}
          >
            {props.data.style}
          </Typography>
          <Typography className="style-text">
            {formatting["decodeHtml"](props.data.description)}
          </Typography>
          <Stack className="icon-div" spacing={1} direction="row">
            {props.data.icons.length === 0 ? (
              <Typography className="icons" />
            ) : (
              props.data.icons.map((icons, key) => {
                return (
                  <Typography key={icons} className="icons">
                    {getIcons(icons)}
                  </Typography>
                );
              })
            )}
            {}
          </Stack>
        </Stack>
      </DomLink>
    </div>
  );
}
