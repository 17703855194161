import React, { useState, useEffect } from "react";
import PrintCatalogPage from "./Button-Print-Catalog-Page.png";

const AssetBase = process.env.REACT_APP_PHP_SITE_URL + "pdfs/";
//const AssetBase = "https://api2.hooverandstrong.com/pdfs/";

export default function CatalogPageButton({ productStyle }) {
  const [exists, setExists] = useState(false);
  const style = cleanStyle(productStyle);
  const AssetUrl = AssetBase + style + "-Catalog.pdf";
  useEffect(() => {
    if (style) {
      fetch(AssetUrl, { method: "HEAD" })
        .then((response) => {
          setExists(true);
        })
        .catch((error) => {
          setExists(false);
        });
    }
  }, [style, AssetUrl]);
  if (style && exists) {
    return (
      <a
        className="catalog-page-button"
        href={AssetUrl}
        target="_blank"
        rel="noreferrer"
      >
        <img src={PrintCatalogPage} alt="Print Catalog Page" />
      </a>
    );
  }
  return <></>;
}

function cleanStyle(style) {
  if (!style) {
    return "";
  }
  return style.replace(/\//, "-");
}
