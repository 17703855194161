import React from "react";

const StraightBaguette = (props) => {
  const viewBoxWidth = 96;
  const viewBoxHeight = 86;

  const lengthXPrcnt = 0.3;
  const lengthYPrcnt = 0.3;

  const widthXPrcnt = 0.58;
  const widthYPrcnt = 0.75;

  const lengthX = lengthXPrcnt * viewBoxWidth;
  const lengthY = lengthYPrcnt * viewBoxHeight;

  const widthX = widthXPrcnt * viewBoxWidth;
  const widthY = widthYPrcnt * viewBoxHeight;

  const x = (percentX) => percentX * viewBoxWidth;
  const y = (percentY) => percentY * viewBoxHeight;

  const lineColor = "#4a4a4a";

  const adustedStrokeWidth = props.stroke_width * 3 || "1";

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox={"0 0 " + viewBoxWidth + " " + viewBoxHeight}
      width={props.width}
      height={props.height}
      preserveAspectRatio={props.preserveAspectRatio}
    >
      <rect
        x="22.5"
        y="1.1"
        className="st0"
        width="39.6"
        height="83.8"
        fill="none"
        stroke={props.color}
        strokeWidth={adustedStrokeWidth}
        strokeLinejoin="round"
        strokeMiterlimit="10"
      />
      <rect
        x="22.5"
        y="1.1"
        className="st0"
        width="39.6"
        height="83.8"
        fill="none"
        stroke={props.color}
        strokeWidth={adustedStrokeWidth}
        strokeLinejoin="round"
        strokeMiterlimit="10"
      />
      <line
        fill="none"
        stroke={props.color}
        strokeWidth={adustedStrokeWidth}
        strokeLinejoin="round"
        strokeMiterlimit="10"
        className="st0"
        x1="22.5"
        y1="84.9"
        x2="22.5"
        y2="1.1"
      />
      <line
        fill="none"
        stroke={props.color}
        strokeWidth={adustedStrokeWidth}
        strokeLinejoin="round"
        strokeMiterlimit="10"
        className="st0"
        x1="62.1"
        y1="84.9"
        x2="62.1"
        y2="1.1"
      />
      <rect
        x="22.5"
        y="1.1"
        className="st0"
        width="39.6"
        height="83.8"
        fill="none"
        stroke={props.color}
        strokeWidth={adustedStrokeWidth}
        strokeLinejoin="round"
        strokeMiterlimit="10"
      />
      <rect
        x="9.6"
        y="32.4"
        transform="matrix(-1.836970e-16 1 -1 -1.836970e-16 85.2979 0.7297)"
        className="st0"
        width="65.3"
        height="21.1"
        fill="none"
        stroke={props.color}
        strokeWidth={adustedStrokeWidth}
        strokeLinejoin="round"
        strokeMiterlimit="10"
      />
      <rect
        x="16.5"
        y="39.4"
        transform="matrix(-1.836970e-16 1 -1 -1.836970e-16 85.2979 0.7297)"
        className="st0"
        width="51.5"
        height="7.3"
        fill="none"
        stroke={props.color}
        strokeWidth={adustedStrokeWidth}
        strokeLinejoin="round"
        strokeMiterlimit="10"
      />
      <line
        fill="none"
        stroke={props.color}
        strokeWidth={adustedStrokeWidth}
        strokeLinejoin="round"
        strokeMiterlimit="10"
        className="st0"
        x1="62.1"
        y1="84.9"
        x2="62.1"
        y2="1.1"
      />
      <line
        fill="none"
        stroke={props.color}
        strokeWidth={adustedStrokeWidth}
        strokeLinejoin="round"
        strokeMiterlimit="10"
        className="st0"
        x1="45.9"
        y1="68.8"
        x2="62.1"
        y2="84.9"
      />
      <line
        fill="none"
        stroke={props.color}
        strokeWidth={adustedStrokeWidth}
        strokeLinejoin="round"
        strokeMiterlimit="10"
        className="st0"
        x1="22.5"
        y1="84.9"
        x2="38.6"
        y2="68.8"
      />
      <line
        fill="none"
        stroke={props.color}
        strokeWidth={adustedStrokeWidth}
        strokeLinejoin="round"
        strokeMiterlimit="10"
        className="st0"
        x1="38.6"
        y1="17.3"
        x2="22.5"
        y2="1.1"
      />
      <polyline
        fill="none"
        stroke={props.color}
        strokeWidth={adustedStrokeWidth}
        strokeLinejoin="round"
        strokeMiterlimit="10"
        className="st0"
        points="62.1,1.1 42.3,20.9 22.5,1.1 	"
      />
      <polyline
        fill="none"
        stroke={props.color}
        strokeWidth={adustedStrokeWidth}
        strokeLinejoin="round"
        strokeMiterlimit="10"
        className="st0"
        points="22.5,84.9 42.3,65.1 62.1,84.9 	"
      />
      <line
        fill="none"
        stroke={props.color}
        strokeWidth={adustedStrokeWidth}
        strokeLinejoin="round"
        strokeMiterlimit="10"
        className="st0"
        x1="42.3"
        y1="20.9"
        x2="42.3"
        y2="65.1"
      />
      {props.show_diagram && (
        <>
          <line
            x1={x(0.5)}
            y1={y(0.05)}
            x2={x(0.5)}
            y2={y(0.94)}
            stroke={lineColor}
            strokeWidth=".5"
          ></line>
          <line
            x1={x(0.48)}
            y1={y(0.05)}
            x2={x(0.52)}
            y2={y(0.05)}
            stroke={lineColor}
            strokeWidth=".5"
          ></line>
          <line
            x1={x(0.48)}
            y1={y(0.94)}
            x2={x(0.52)}
            y2={y(0.94)}
            stroke={lineColor}
            strokeWidth=".5"
          ></line>
          <rect
            x={lengthX}
            y={lengthY}
            width="20"
            height="11"
            rx="0.8"
            fill="white"
          ></rect>
          <text
            x={lengthX + 2}
            y={lengthY + 4}
            fontSize=".4rem"
            stroke={lineColor}
            strokeWidth="0.01"
          >
            Length:
          </text>
          <text
            x={lengthX + 1}
            y={lengthY + 10}
            fontSize=".4rem"
            stroke={lineColor}
            strokeWidth="0.01"
          >
            {props.diamond.Measurement1}
          </text>

          <line
            x1={x(0.01)}
            y1={y(0.9)}
            x2={x(0.985)}
            y2={y(0.9)}
            stroke={lineColor}
            strokeWidth=".5"
          ></line>
          <line
            x1={x(0.01)}
            y1={y(0.88)}
            x2={x(0.01)}
            y2={y(0.92)}
            stroke={lineColor}
            strokeWidth=".5"
          ></line>
          <line
            x1={x(0.985)}
            y1={y(0.88)}
            x2={x(0.985)}
            y2={y(0.92)}
            stroke={lineColor}
            strokeWidth=".5"
          ></line>

          <rect
            x={widthX}
            y={widthY}
            width="19"
            height="11"
            rx="0.8"
            fill="white"
          ></rect>
          <text
            x={widthX + 2}
            y={widthY + 4}
            fontSize=".4rem"
            stroke={lineColor}
            strokeWidth="0.01"
          >
            Width:
          </text>
          <text
            x={widthX + 1}
            y={widthY + 10}
            fontSize=".4rem"
            stroke={lineColor}
            strokeWidth="0.01"
          >
            {props.diamond.Measurement2}
          </text>
        </>
      )}
    </svg>
  );
};

export default StraightBaguette;
