function filterAds(query) {
  const excludedProperties = ["createdAt", "publishedAt", "updatedAt"];

  const filteredAdList = Object.keys(query)
    .filter((adKey) => !excludedProperties.includes(adKey))
    .filter((adKey) => query[adKey].data !== null)
    .map((adKey) => ({ [adKey]: query[adKey] }));

  const randomNum = Math.floor(Math.random() * filteredAdList.length);
  const firstObject = Object.values(filteredAdList[randomNum])[0];

  return firstObject.data.attributes;
}

export default filterAds;
